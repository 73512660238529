import { TargetResultData } from './../shared/interfaces/';

export const learningTargetResults: TargetResultData[] = [
  {
    id: 'test01',
    imagePath: '/assets/learning-target/diamond.svg',
    totalCorrect: '3',
    totalAssessed: '5',
    description: "This is unit 1's description",
  },
  {
    id: 'test02',
    imagePath: '/assets/learning-target/diamond.svg',
    totalCorrect: '1',
    totalAssessed: '5',
    description: "This is unit 2's description",
  },
  {
    id: 'test03',
    imagePath: '/assets/learning-target/diamond.svg',
    totalCorrect: '2',
    totalAssessed: '5',
    description: "This is unit 3's description",
  },
];
