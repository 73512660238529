import {
  KATEX_END_DELIM,
  KATEX_START_DELIM,
} from '../modules/math-text/utils/math-text-utils';
import { PopulationDragAndDrop } from '../shared/interfaces';
import { TEST_CHOICE_TYPES } from './choice-types';
import { TestAssociatedSuccessCriteria } from './test-associated-success-criteria.enum';

export const staticVerticalSequencing: {
  [key: string]: PopulationDragAndDrop;
} = {
  [TEST_CHOICE_TYPES.TEST_R_DND_VERT_SEQ_SHORT]: {
    question: { text: 'Sequence the events of this question...' },
    labels: [{ text: '1' }, { text: '2' }, { text: '3' }],
    answerChoices: [
      {
        id: 'correct_1',
        choice: {
          texts: [
            {
              text: 'correct 1',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for correct 1' },
          secondIncorrect: { content: 'This is second feedback for correct 1' },
        },
      },
      {
        id: 'correct_2',
        choice: {
          texts: [
            {
              text: 'correct 2',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for correct 2' },
          secondIncorrect: { content: 'This is second feedback for correct 2' },
        },
      },
      {
        id: 'correct_3',
        choice: {
          texts: [
            {
              text: 'correct 3',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for correct 3' },
          secondIncorrect: { content: 'This is second feedback for correct 3' },
        },
      },
    ],
    answers: ['correct_1', 'correct_2', 'correct_3'],
    associatedSuccessCriteria: [TestAssociatedSuccessCriteria.DRAG_AND_DROP],
  },
  [TEST_CHOICE_TYPES.TEST_R_DND_VERT_SEQ_LONG]: {
    question: {
      text: 'Sequence the events of this very long question that I will now ask you at this current moment in time...',
    },
    labels: [
      { text: '1' },
      { text: '2' },
      { text: '3' },
      { text: '4' },
      { text: '5' },
      { text: '6' },
    ],
    answerChoices: [
      {
        id: 'correct_1',
        choice: {
          texts: [
            {
              text: 'correct 1',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for correct 1' },
          secondIncorrect: { content: 'This is second feedback for correct 1' },
        },
      },
      {
        id: 'correct_2',
        choice: {
          texts: [
            {
              text: 'correct 2',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for correct 2' },
          secondIncorrect: { content: 'This is second feedback for correct 2' },
        },
      },
      {
        id: 'correct_3',
        choice: {
          texts: [
            {
              text: 'correct 3',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for correct 3' },
          secondIncorrect: { content: 'This is second feedback for correct 3' },
        },
      },
      {
        id: 'correct_4',
        choice: {
          texts: [
            {
              text: 'correct 4',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for correct 4' },
          secondIncorrect: { content: 'This is second feedback for correct 4' },
        },
      },
      {
        id: 'correct_5',
        choice: {
          texts: [
            {
              text: 'correct 5',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for correct 5' },
          secondIncorrect: { content: 'This is second feedback for correct 5' },
        },
      },
      {
        id: 'correct_6',
        choice: {
          texts: [
            {
              text: 'correct 6',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for correct 6' },
          secondIncorrect: { content: 'This is second feedback for correct 6' },
        },
      },
      {
        id: 'incorrect_1',
        choice: {
          texts: [
            {
              text: 'incorrect 1',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for incorrect 1' },
          secondIncorrect: {
            content: 'This is second feedback for incorrect 1',
          },
        },
      },
    ],
    answers: [
      'correct_1',
      'correct_2',
      'correct_3',
      'correct_4',
      'correct_5',
      'correct_6',
    ],
    associatedSuccessCriteria: [TestAssociatedSuccessCriteria.DRAG_AND_DROP],
  },
  test1: {
    question: { text: 'Complete each addition problem.' },
    labels: [
      { text: '4+1=' },
      { text: '2+1=' },
      { text: '2+4=' },
      { text: '5+2=' },
      { text: '2+2=' },
    ],
    answerChoices: [
      {
        id: 'abc123',
        choice: {
          texts: [
            {
              text: '8',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "8"' },
          secondIncorrect: { content: 'This is second feedback for "8"' },
        },
      },
      {
        id: 'abc456',
        choice: {
          texts: [
            {
              text: '7',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "7"' },
          secondIncorrect: { content: 'This is second feedback for "7"' },
        },
      },
      {
        id: 'abc789',
        choice: {
          texts: [
            {
              text: '6',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "6"' },
          secondIncorrect: { content: 'This is second feedback for "6"' },
        },
      },
      {
        id: 'abc10',
        choice: {
          texts: [
            {
              text: '3',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "3"' },
          secondIncorrect: { content: 'This is second feedback for "3"' },
        },
      },
      {
        id: 'abc11',
        choice: {
          texts: [
            {
              text: '4',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "4"' },
          secondIncorrect: { content: 'This is second feedback for "4"' },
        },
      },
      {
        id: 'abc12',
        choice: {
          texts: [
            {
              text: '5',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "5"' },
          secondIncorrect: { content: 'This is second feedback for "5"' },
        },
      },
    ],
    answers: ['abc12', 'abc10', 'abc789', 'abc456', 'abc11'],
    associatedSuccessCriteria: [TestAssociatedSuccessCriteria.DRAG_AND_DROP],
  },
  [TEST_CHOICE_TYPES.TEST_R_DND_SEQ_STATIC_VERT_KATEX]: {
    question: {
      text: `What if I asked you about ${KATEX_START_DELIM}\frac{a}{b}${KATEX_END_DELIM}?`,
    },
    labels: [
      {
        text: `Fraction Label ${KATEX_START_DELIM}\frac{a}{b}${KATEX_END_DELIM}`,
      },
      { text: '2' },
      { text: '3' },
    ],
    answerChoices: [
      {
        id: 'correct_1',
        choice: {
          texts: [
            {
              text: `correct 1 ${KATEX_START_DELIM}\frac{a}{b}${KATEX_END_DELIM}`,
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for correct 1' },
          secondIncorrect: { content: 'This is second feedback for correct 1' },
        },
      },
      {
        id: 'correct_2',
        choice: {
          texts: [
            {
              text: 'correct 2',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for correct 2' },
          secondIncorrect: { content: 'This is second feedback for correct 2' },
        },
      },
      {
        id: 'correct_3',
        choice: {
          texts: [
            {
              text: 'correct 3',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for correct 3' },
          secondIncorrect: { content: 'This is second feedback for correct 3' },
        },
      },
      {
        id: 'incorrect_1',
        choice: {
          texts: [
            {
              text: 'incorrect 1',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for incorrect 1' },
          secondIncorrect: {
            content: 'This is second feedback for incorrect 1',
          },
        },
      },
    ],
    answers: ['correct_1', 'correct_2', 'correct_3'],
    associatedSuccessCriteria: [TestAssociatedSuccessCriteria.DRAG_AND_DROP],
  },
};
