import { animate, transition, trigger } from '@angular/animations';
import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ITool } from 'src/app/modules/tools/interfaces/tool.interface';
import { AudioDescriptor } from 'src/app/shared/enums/audio-descriptor';
import { AudioType } from 'src/app/shared/enums/audio-type';
import { SettingsService } from 'src/app/shared/services/settings/settings.service';

@Component({
  selector: 'htc-read-aloud-toggle',
  templateUrl: './read-aloud-toggle.component.html',
  styleUrls: ['./read-aloud-toggle.component.scss'],
  animations: [
    trigger('toggle-icon', [
      transition('false => true', animate('0s 130ms')),
      transition('true => false', animate('0s 130ms')),
    ]),
  ],
})
export class ReadAloudToggleComponent implements ITool, OnDestroy {
  readonly path = '/assets/toolbar-icons/';

  id = 0;
  toolName = 'Read Aloud';
  icon = 'readaloud_off.svg';
  toggledIcon = 'readaloud_on.svg';
  testId = 'htc-read-aloud-toggle-switch';

  isChecked = false;
  currentIcon = this.icon;
  subscription = new Subscription();
  clickDescriptor = AudioDescriptor.SELECT_MINOR;
  clickType = AudioType.audio_SfxClick;

  constructor(private settingsService: SettingsService) {
    this.subscription.add(
      settingsService.settings.subscribe(settings => {
        this.isChecked = settings.readAloud ?? false;
      })
    );
  }

  onClick(): void {
    console.log('speechSynthesis exists?', !!window.speechSynthesis);
    this.isChecked = !this.isChecked;
    this.settingsService.saveSettings({ readAloud: this.isChecked });
  }

  switchIcon(): void {
    if (this.isChecked) {
      this.currentIcon = this.toggledIcon;
    } else {
      this.currentIcon = this.icon;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
