import {
  Component,
  Input,
  OnChanges,
  ViewChild,
  SimpleChanges,
  ElementRef,
} from '@angular/core';
import { ReadAloudPlayerType } from '../../enums/read-aloud-player-type';
import { LottiePlayerComponent } from 'src/app/modules/lottie-player/components/lottie-player/lottie-player.component';

@Component({
  selector: 'htc-read-aloud-controls',
  templateUrl: './read-aloud-controls.component.html',
  styleUrls: ['./read-aloud-controls.component.scss'],
})
export class ReadAloudControlsComponent implements OnChanges {
  @Input() showPlayPause = false;
  @Input() type!: string; //Apply question class or not?
  @Input() isPlaying = false;
  @Input() isPaused = false;
  @Input() isLoading = false;
  @ViewChild(LottiePlayerComponent) lottiePlayer!: LottiePlayerComponent;
  @ViewChild('controls') controls!: ElementRef<HTMLButtonElement>;
  playerType = ReadAloudPlayerType;
  preventTabTargeting = true;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.showPlayPause) {
      return;
    }

    if (!this.isPlaying && !this.isPaused) {
      this.preventTabTargeting = true;
    }
    if (changes.isPlaying && this.isPlaying && !changes.isPaused) {
      // Only when it opens, not just whenever it plays
      this.preventTabTargeting = false;
      this.controls.nativeElement.focus();
    }

    if (changes.isPlaying || changes.isPaused) {
      this.changeVisualizer();
    }
  }

  changeVisualizer(): void {
    if (this.lottiePlayer) {
      this.lottiePlayer.cancelAutoplayLoop();
      if (this.isPlaying && !this.isPaused) {
        this.lottiePlayer.playSegment('waveform-loop');
      } else {
        this.lottiePlayer.playSegment('waveform-idle');
      }
    }
  }

  // To prevent parent's handleClick from getting called twice
  // Only happens with space, not with click nor enter. idk why
  stopSpaceEvent(event: Event): void {
    event.stopPropagation();
  }
}
