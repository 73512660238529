import {
  KATEX_END_DELIM,
  KATEX_START_DELIM,
} from '../modules/math-text/utils/math-text-utils';
import { PopulationMultipleChoice } from '../shared/interfaces';
import { TEST_CHOICE_TYPES } from './choice-types';
import { TestAssociatedSuccessCriteria } from './test-associated-success-criteria.enum';

export const multiChoiceQuestions: { [key: number]: PopulationMultipleChoice } =
  {
    [TEST_CHOICE_TYPES.TEST_R_MC_SHORT]: {
      question: {
        text: 'This is the reading multi choice short question?',
      },
      answerChoices: [
        {
          id: '0',
          choice: {
            texts: [
              {
                text: 'correct',
              },
            ],
          },
        },
        {
          id: '1',
          choice: {
            texts: [
              {
                text: 'incorrect 1',
              },
            ],
          },
          targetedFeedback: {
            firstIncorrect: {
              content:
                'This is incorrect 1 - first incorrect targeted feedback',
            },
            secondIncorrect: {
              content:
                'This is incorrect 1 - second incorrect targeted feedback',
            },
          },
        },
        {
          id: '2',
          choice: {
            texts: [
              {
                text: 'incorrect 2',
              },
            ],
          },
          targetedFeedback: {
            firstIncorrect: {
              content:
                'This is incorrect 2 - first incorrect targeted feedback',
            },
            secondIncorrect: {
              content:
                'This is incorrect 2 - second incorrect targeted feedback',
            },
          },
        },
      ],
      answers: ['0'],
      associatedSuccessCriteria: [TestAssociatedSuccessCriteria.MULTI_CHOICE],
    },
    [TEST_CHOICE_TYPES.TEST_R_MC_MEDIUM]: {
      question: {
        text: 'This is the reading multi choice medium question? Added question text for medium length.',
      },
      answerChoices: [
        {
          id: '0',
          choice: {
            texts: [
              {
                text: 'This is a correct answer medium length sentence.',
              },
            ],
          },
        },
        {
          id: '1',
          choice: {
            texts: [
              {
                text: 'This is a incorrect 1 answer medium length sentence.',
              },
            ],
          },
          targetedFeedback: {
            firstIncorrect: {
              content:
                'This is incorrect 1 - first incorrect targeted feedback',
            },
            secondIncorrect: {
              content:
                'This is incorrect 1 - second incorrect targeted feedback',
            },
          },
        },
        {
          id: '2',
          choice: {
            texts: [
              {
                text: 'This is a incorrect 2 answer medium length sentence.',
              },
            ],
          },
          targetedFeedback: {
            firstIncorrect: {
              content:
                'This is incorrect 2 - first incorrect targeted feedback',
            },
            secondIncorrect: {
              content:
                'This is incorrect 2 - second incorrect targeted feedback',
            },
          },
        },
      ],
      answers: ['0'],
      associatedSuccessCriteria: [TestAssociatedSuccessCriteria.MULTI_CHOICE],
    },
    [TEST_CHOICE_TYPES.TEST_R_MC_LONG]: {
      question: {
        text: 'This is the reading multi choice long question? Added text to the question in order to add longer question. More question text added here for length.',
      },
      answerChoices: [
        {
          id: '0',
          choice: {
            texts: [
              {
                text: 'This is a correct answer long length sentence. This is a correct answer long length sentence.',
              },
            ],
          },
        },
        {
          id: '1',
          choice: {
            texts: [
              {
                text: 'This is a incorrect 1 answer long length sentence. This is a incorrect 1 answer long length sentence.',
              },
            ],
          },
          targetedFeedback: {
            firstIncorrect: {
              content:
                'This is incorrect 1 - first incorrect targeted feedback',
            },
            secondIncorrect: {
              content:
                'This is incorrect 1 - second incorrect targeted feedback',
            },
          },
        },
        {
          id: '2',
          choice: {
            texts: [
              {
                text: 'This is a incorrect 2 answer long length sentence. This is a incorrect 2 answer long length sentence.',
              },
            ],
          },
          targetedFeedback: {
            firstIncorrect: {
              content:
                'This is incorrect 2 - first incorrect targeted feedback',
            },
            secondIncorrect: {
              content:
                'This is incorrect 2 - second incorrect targeted feedback',
            },
          },
        },
      ],
      answers: ['0'],
      associatedSuccessCriteria: [TestAssociatedSuccessCriteria.MULTI_CHOICE],
    },
    [TEST_CHOICE_TYPES.TEST_R_MC_TRUE_FALSE]: {
      question: {
        text: `This is the question for the true or false question type?`,
      },
      answerChoices: [
        {
          id: '0',
          choice: {
            texts: [{ text: 'True' }],
          },
        },
        {
          id: '1',
          choice: {
            texts: [{ text: 'False' }],
          },
        },
      ],
      answers: ['0'],
      associatedSuccessCriteria: [TestAssociatedSuccessCriteria.MULTI_CHOICE],
    },
    [TEST_CHOICE_TYPES.TEST_S_MC_SHORT]: {
      question: {
        text: '¿Esta es la pregunta corta de opción múltiple de lectura?',
      },
      answerChoices: [
        {
          id: '0',
          choice: {
            texts: [
              {
                text: 'correcta',
              },
            ],
          },
        },
        {
          id: '1',
          choice: {
            texts: [
              {
                text: 'Incorrecta 1',
              },
            ],
          },
          targetedFeedback: {
            firstIncorrect: {
              content:
                'Esto es incorrecto 1: primer comentario dirigido incorrecto',
            },
            secondIncorrect: {
              content:
                'Esto es incorrecto 1 segundo de comentario dirigido incorrecto',
            },
          },
        },
        {
          id: '2',
          choice: {
            texts: [
              {
                text: 'Incorrecta 2',
              },
            ],
          },
          targetedFeedback: {
            firstIncorrect: {
              content:
                'Esto es incorrecto 2: primer comentario dirigido incorrecto',
            },
            secondIncorrect: {
              content:
                'Esto es incorrecto 2 segundos de comentarios dirigidos incorrectos',
            },
          },
        },
      ],
      answers: ['0'],
      associatedSuccessCriteria: [TestAssociatedSuccessCriteria.MULTI_CHOICE],
    },
    [TEST_CHOICE_TYPES.TEST_S_MC_MEDIUM]: {
      question: {
        text: '¿Esta es la pregunta del medio de opción múltiple de lectura? Se agregó texto de pregunta para longitud media.',
      },
      answerChoices: [
        {
          id: '0',
          choice: {
            texts: [
              {
                text: 'Esta es una oración de longitud media con respuesta correcta.',
              },
            ],
          },
        },
        {
          id: '1',
          choice: {
            texts: [
              {
                text: 'Esta es una oración de longitud media de 1 respuesta incorrecta.',
              },
            ],
          },
          targetedFeedback: {
            firstIncorrect: {
              content:
                'Esto es incorrecto 1: primer comentario dirigido incorrecto',
            },
            secondIncorrect: {
              content:
                'Esto es incorrecto 1 segundo de comentario dirigido incorrecto',
            },
          },
        },
        {
          id: '2',
          choice: {
            texts: [
              {
                text: 'Esta es una oración de longitud media de 2 respuestas incorrecta.',
              },
            ],
          },
          targetedFeedback: {
            firstIncorrect: {
              content:
                'Esto es incorrecto 2: primer comentario dirigido incorrecto',
            },
            secondIncorrect: {
              content:
                'Esto es incorrecto 2 segundos de comentarios dirigidos incorrectos',
            },
          },
        },
      ],
      answers: ['0'],
      associatedSuccessCriteria: [TestAssociatedSuccessCriteria.MULTI_CHOICE],
    },
    [TEST_CHOICE_TYPES.TEST_S_MC_LONG]: {
      question: {
        text: '¿Esta es la pregunta larga de opción múltiple de lectura? Se agregó texto a la pregunta para agregar una pregunta más larga. Se agregó más texto de pregunta aquí para mayor extensión.',
      },
      answerChoices: [
        {
          id: '0',
          choice: {
            texts: [
              {
                text: 'Esta es una oración larga y de respuesta correcta. Esta es una oración larga y de respuesta correcta.',
              },
            ],
          },
        },
        {
          id: '1',
          choice: {
            texts: [
              {
                text: 'Esta es una oración larga y incorrecta de 1 respuesta. Esta es una oración larga y incorrecta de 1 respuesta.',
              },
            ],
          },
          targetedFeedback: {
            firstIncorrect: {
              content:
                'Esto es incorrecto 1: primer comentario dirigido incorrecto',
            },
            secondIncorrect: {
              content:
                'Esto es incorrecto 1 segundo de comentario dirigido incorrecto',
            },
          },
        },
        {
          id: '2',
          choice: {
            texts: [
              {
                text: 'Esta es una oración larga incorrecta de 2 respuestas. Esta es una oración larga incorrecta de 2 respuestas.',
              },
            ],
          },
          targetedFeedback: {
            firstIncorrect: {
              content:
                'Esto es incorrecto 2: primer comentario dirigido incorrecto',
            },
            secondIncorrect: {
              content:
                'Esto es incorrecto 2 segundos de comentarios dirigidos incorrectos',
            },
          },
        },
      ],
      answers: ['0'],
      associatedSuccessCriteria: [TestAssociatedSuccessCriteria.MULTI_CHOICE],
    },
    [TEST_CHOICE_TYPES.TEST_S_MC_TRUE_FALSE]: {
      question: {
        text: `¿Esta es la pregunta del tipo de pregunta verdadero o falso?`,
      },
      answerChoices: [
        {
          id: '0',
          choice: {
            texts: [{ text: 'Verdadero' }],
          },
        },
        {
          id: '1',
          choice: {
            texts: [{ text: 'Falso' }],
          },
        },
      ],
      answers: ['0'],
      associatedSuccessCriteria: [TestAssociatedSuccessCriteria.MULTI_CHOICE],
    },
    [TEST_CHOICE_TYPES.TEST_R_MC_KATEX]: {
      question: {
        text: `What if I asked you about ${KATEX_START_DELIM}\frac{a}{b}${KATEX_END_DELIM}?`,
      },
      answerChoices: [
        {
          id: '0',
          choice: {
            texts: [
              {
                text: `correct ${KATEX_START_DELIM}\frac{a}{b}${KATEX_END_DELIM}`,
              },
            ],
          },
        },
        {
          id: '1',
          choice: {
            texts: [
              {
                text: `incorrect ${KATEX_START_DELIM}\frac{a}{b}${KATEX_END_DELIM}`,
              },
            ],
          },
          targetedFeedback: {
            firstIncorrect: {
              content: `This is incorrect 1 - first incorrect targeted feedback ${KATEX_START_DELIM}\frac{a}{b}${KATEX_END_DELIM}`,
            },
            secondIncorrect: {
              content:
                'This is incorrect 1 - second incorrect targeted feedback',
            },
          },
        },
        {
          id: '2',
          choice: {
            texts: [
              {
                text: 'incorrect 2',
              },
            ],
          },
          targetedFeedback: {
            firstIncorrect: {
              content:
                'This is incorrect 2 - first incorrect targeted feedback',
            },
            secondIncorrect: {
              content:
                'This is incorrect 2 - second incorrect targeted feedback',
            },
          },
        },
      ],
      answers: ['0'],
      associatedSuccessCriteria: [TestAssociatedSuccessCriteria.MULTI_CHOICE],
    },
  };
