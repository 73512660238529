/**
 * A Synthetic API Service to intercept requests until a suitable API exists
 */
import { Injectable } from '@angular/core';
import { InMemoryDbService } from 'angular-in-memory-web-api';

import {
  activities,
  releaseActivities,
  sandboxActivities,
} from '../../../data/';
import { Activity } from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class SimulacrumService implements InMemoryDbService {
  createDb(): { activities: Activity[] } {
    return {
      activities: [...activities, ...releaseActivities, ...sandboxActivities],
    };
  }
}
