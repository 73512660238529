import { inject, Injectable, ProviderToken } from '@angular/core';
import {
  KnowledgeBaseModules,
  KnowledgeBaseValue,
} from '../../types/knowledge-base.interface';
import { KnowledgeBaseAPI } from '../../classes/knowledge-base/knowledge-base-api';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { MessageService } from 'src/app/modules/message/services/message/message.service';
import { MessageCodes } from 'src/app/shared/enums';

const getKbFacade = (): KnowledgeBaseAPI => {
  const injectionPoint: ProviderToken<KnowledgeBaseAPI> = KnowledgeBaseAPI;
  const facade = inject(injectionPoint);
  return facade;
};

@Injectable()
export class KnowledgeBaseService {
  public kb = getKbFacade();

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private messageService: MessageService
  ) {}

  async checkForAccessToken(): Promise<boolean> {
    if (this.kb.authToken) {
      return true;
    }
    this.oidcSecurityService.getAccessToken().subscribe({
      next: token => {
        if (token) {
          this.kb.setAuthToken(token);
        }
      },
      error: err => {
        console.error(
          'we had issues getting an access token for kb service',
          err
        );
        this.messageService.showMessage(MessageCodes.HTTP_AUTH_REQUIRED);
      },
    });
    return true;
  }

  setCcoVersion(ccoVersion: number): void {
    this.kb.setCcoVersion(ccoVersion);
  }

  async getAll(): Promise<KnowledgeBaseModules | null | undefined> {
    await this.checkForAccessToken();
    return this.kb.getAll();
  }

  deleteAll(): void {
    return this.kb.deleteAll();
  }

  get(section: string, key: string): Promise<KnowledgeBaseValue> {
    return this.kb.get(section, key);
  }

  set(section: string, key: string, value: KnowledgeBaseValue): void {
    this.kb.set(section, key, value);
  }

  delete(section: string, key: string): void {
    this.kb.delete(section, key);
  }

  set userId(value: number) {
    this.kb.user_id = value;
  }

  async setAndSync(
    section: string,
    key: string,
    value: KnowledgeBaseValue
  ): Promise<void> {
    await this.kb.setAndSync(section, key, value);
  }

  async sync(): Promise<void> {
    await this.kb.sync();
  }
}
