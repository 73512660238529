import { Chart } from '../shared/interfaces';

export enum CHART_TYPES {
  SAMPLE_WITH_TITLE,
  SAMPLE_WITHOUT_TITLE,
  EXPERIMENTAL,
}

export const charts: { [key: number]: Chart } = {
  [CHART_TYPES.SAMPLE_WITH_TITLE]: {
    title: '3rd Grade Informative/Explanatory Writing Rubric',
    data: '/assets/tables/sample.lexical',
    isUrl: true,
  },
  [CHART_TYPES.SAMPLE_WITHOUT_TITLE]: {
    data: '/assets/tables/sample.lexical',
    isUrl: true,
  },
  [CHART_TYPES.EXPERIMENTAL]: {
    data: '/assets/tables/two.lexical',
    isUrl: true,
  },
};
