import { animate, style, transition, trigger } from '@angular/animations';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
  ElementRef,
  HostListener,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { SpeechRuleEngineService } from 'src/app/modules/math-text/services/speech-rule-engine.service';
import { AudioDescriptor } from 'src/app/shared/enums/audio-descriptor';
import { AudioType } from 'src/app/shared/enums/audio-type';
import { DropdownOption } from 'src/app/shared/interfaces/dropdown-option';

/**
 * @isWrongAnswer - when is true, a user still have one more try to select an option
 * @isCorrectAnswer - when is true, check icon will be shown and a user is unable to select another option
 *
 * When @isWrongAnswer=true and @isCorrectAnswer=true that means a user tried to answer 2 times and correct answer will be shown
 */

@Component({
  selector: 'htc-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition('false => true', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class DropdownComponent implements AfterViewInit, OnChanges {
  @Input() uniqueId: string | number = 'dropdown';
  @Input() index = -1;
  @Input() options: DropdownOption[] = [];
  @Input() placeholder? = 'Choose';
  @Input() isWrongAnswer? = false;
  @Input() isCorrectAnswer? = false;
  @Input() correctAnswer?: DropdownOption;
  @Input() selectedAnswer?: DropdownOption;
  @Input() clickDescriptor = AudioDescriptor.CLICK_GENERAL;
  @Input() clickType = AudioType.audio_Undefined;
  @Output() inputChange = new EventEmitter<DropdownOption>();
  @ViewChild('optionsRef', { static: true, read: ElementRef })
  optionsRef!: ElementRef<HTMLElement>;

  isDropdownMenuOpen = false;
  showCorrectAnswer = false;
  elementWidth = 120;
  iconName = 'short-arrow';
  private defaultValue = { id: -1, name: '' };
  private currentIndex = -1;
  private mappedOptions: boolean[] = [];
  private isPreselectedCurrentIndex = false;

  currentSelection: DropdownOption = this.defaultValue;

  constructor(
    private dropdownRef: ElementRef,
    private cdRef: ChangeDetectorRef,
    public sres: SpeechRuleEngineService
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.elementWidth = Math.max(
        this.elementWidth,
        this.optionsRef.nativeElement.clientWidth
      );
      this.cdRef.detectChanges();
      this.optionsRef.nativeElement.style.width = '100%';
    }, 0);

    if (this.selectedAnswer) {
      this.currentSelection = this.selectedAnswer;
    } else {
      this.currentSelection =
        this.correctAnswer && this.correctAnswer?.id !== -1
          ? (this.correctAnswer as DropdownOption)
          : this.defaultValue;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedAnswer && this.selectedAnswer) {
      this.currentSelection = this.selectedAnswer;
    }

    // if correct answer then display check
    if (
      this.currentSelection.id === this.correctAnswer?.id &&
      this.isCorrectAnswer
    ) {
      this.iconName = 'check_enabled';
      this.showCorrectAnswer = false;
    } else if (this.index !== -1) {
      this.isCorrectAnswer = true;
      this.isWrongAnswer = true;
      setTimeout(() => {
        this.showCorrectAnswer = true;
      }, this.index * 150);
    }
    this.currentIndex = -1;
    this.mappedOptions = this.options.map(item => item.disabled || false);
  }

  setCurrentSelection(option: DropdownOption): void {
    if (!option.disabled && this.correctAnswer?.id === -1) {
      this.currentSelection = option;
      this.inputChange.emit(option);
      this.removeTargetClass();
      this.toggleDropdown();
    }
  }

  toggleDropdown(event?: Event): void {
    if (event && this.isCorrectAnswer && !this.isWrongAnswer) {
      event.preventDefault();
    } else {
      this.isDropdownMenuOpen = !this.isDropdownMenuOpen;
    }
  }

  selectByIndex(i: number): void {
    const value = this.options[i];
    // trigger click to play SFX
    this.dropdownRef.nativeElement.querySelectorAll('li')[i].click(value);
  }

  removeTargetClass(): void {
    this.dropdownRef.nativeElement
      .querySelector('button')
      .classList.remove('tab-opened');
  }

  onClickEvent(event: Event): void {
    event.stopPropagation();
  }

  @HostListener('document:click', ['$event'])
  clickOut(event: Event): void {
    if (
      !this.dropdownRef.nativeElement.contains(event.target) &&
      this.isDropdownMenuOpen
    ) {
      this.removeTargetClass();
      this.toggleDropdown();
    }
  }

  handleKeyboardEvents(event: KeyboardEvent): void {
    if (this.isDropdownMenuOpen) {
      event.preventDefault();
    } else {
      if (event.code === 'ArrowDown') {
        event.preventDefault();
        // open options
        this.handleOpenOptions();
      }
      return;
    }
    if (event.code === 'Escape') {
      this.handleEscapeKey();
    }

    if (this.areAllOptionsDisabled()) {
      return;
    }

    this.presetCurrentIndex(event);

    if (event.code === 'ArrowUp') {
      this.handleArrowUpKey();
    } else if (event.code === 'ArrowDown') {
      this.handleArrowDownKey();
    } else if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      this.handleEnterKey();
    }
  }

  private areAllOptionsDisabled(): boolean {
    return this.mappedOptions.filter(o => !o).length === 0;
  }

  private handleOpenOptions(): void {
    if (
      document.activeElement ===
      this.dropdownRef.nativeElement.querySelector('button')
    ) {
      this.toggleDropdown();
      this.currentIndex =
        this.currentIndex < 0
          ? this.mappedOptions.indexOf(false)
          : this.currentIndex;
      this.dropdownRef.nativeElement
        .querySelector('button')
        .classList.add('tab-opened');

      if (this.currentIndex < 0) {
        return;
      }

      setTimeout(() => {
        this.dropdownRef.nativeElement
          .querySelectorAll('li.option')
          .item(this.currentIndex)
          .focus();
      }, 100);
    }
  }

  private presetCurrentIndex(event: KeyboardEvent): void {
    this.isPreselectedCurrentIndex = false;
    if (event.code === 'ArrowUp' || event.code === 'ArrowDown') {
      if (this.currentIndex < 0) {
        if (this.mappedOptions[0] === false) {
          // set to the first option if it's not disabled
          this.currentIndex = 0;
        } else if (this.mappedOptions.filter(o => !o).length !== 0) {
          // set to any first option if it's enabled
          this.currentIndex = this.mappedOptions.indexOf(false);
        } else {
          // otherwise all options are disabled
          return;
        }
        this.isPreselectedCurrentIndex = true;
      }
    }
  }

  private handleArrowUpKey(): void {
    if (
      !this.isPreselectedCurrentIndex &&
      this.currentIndex > 0 &&
      this.mappedOptions.lastIndexOf(false, this.currentIndex - 1) !== -1
    ) {
      this.currentIndex = this.mappedOptions.lastIndexOf(
        false,
        this.currentIndex - 1
      );
    }
    this.dropdownRef.nativeElement
      .querySelectorAll('li.option')
      .item(this.currentIndex)
      .focus();
  }

  private handleArrowDownKey(): void {
    if (
      !this.isPreselectedCurrentIndex &&
      this.currentIndex < this.options.length - 1 &&
      this.mappedOptions.indexOf(false, this.currentIndex + 1) !== -1
    ) {
      this.currentIndex = this.mappedOptions.indexOf(
        false,
        this.currentIndex + 1
      );
    }
    this.dropdownRef.nativeElement
      .querySelectorAll('li.option')
      .item(this.currentIndex)
      .focus();
  }

  private handleEnterKey(): void {
    if (this.currentIndex >= 0 && this.correctAnswer?.id === -1) {
      this.selectByIndex(this.currentIndex);
      this.removeTargetClass();

      this.dropdownRef.nativeElement.querySelector('button').focus();
    }
  }

  private handleEscapeKey(): void {
    this.removeTargetClass();
    this.toggleDropdown();
    this.dropdownRef.nativeElement.querySelector('button').focus();
    this.currentIndex = -1;
  }
}
