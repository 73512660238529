import { FeatureFlagService } from '../../services/feature-flag/feature-flag.service';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagGuard {
  constructor(
    private featureFlagService: FeatureFlagService,
    private router: Router,
    private location: Location
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    //check if we have the flag, if we don't check if we have a redirect in data,
    //if we do, return the redirect path, if we don't, return false.
    let expectedFlagsEnabled = true;
    if (route.data.requiredEnabledFlags) {
      expectedFlagsEnabled = this.featureFlagService.isFlagEnabled(
        route.data.requiredEnabledFlags
      );
    }

    let expectedFlagsDisabled = true;
    if (route.data.requiredDisabledFlags) {
      expectedFlagsDisabled = this.featureFlagService.areFlagsDisabled(
        route.data.requiredDisabledFlags
      );
    }

    if (expectedFlagsEnabled && expectedFlagsDisabled) {
      return true;
    } else {
      if (route.data.featureFlagRedirect) {
        this.router.navigate([route.data.featureFlagRedirect]);
        this.location.replaceState(route.data.featureFlagRedirect);
      }
      return false;
    }
  }
}
