import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ReadAloudComponent } from '../components/';
import { AudioData } from 'src/app/shared/interfaces';
import { ReadAloudPlayerType } from '../enums/read-aloud-player-type';
import { ReadAloudTheme } from '../enums/read-aloud-theme';

@Directive({
  selector: '[htcReadAloud]',
})
export class ReadAloudDirective implements OnInit, OnChanges {
  static CLICK_DELIM = '<CLICK>';
  component!: ReadAloudComponent;

  constructor(
    private view: ViewContainerRef,
    private template: TemplateRef<unknown>
  ) {}

  private _audio: AudioData = { id: '' };
  @Input()
  set htcReadAloud(value: AudioData | undefined) {
    if (value) {
      this._audio = value;
    }
  }

  private _type: string = ReadAloudPlayerType.WITH_PAUSE as string;
  @Input()
  set htcReadAloudType(value: string) {
    this._type = value;
  }

  private _theme: string = ReadAloudTheme.LIGHT as string;
  @Input()
  set htcReadAloudTheme(value: string) {
    this._theme = value;
  }

  private _preventTabTargeting = false;
  @Input()
  set htcReadAloudPreventTabTargeting(value: boolean) {
    this._preventTabTargeting = value;
  }

  // For buttons to send click to read aloud
  // outside of the read aloud overlay
  private _clickListenForStr!: string;
  @Input()
  set htcReadAloudClickListenForStr(value: string) {
    this._clickListenForStr = value;
  }

  private _clickEventStr!: string;
  @Input()
  set htcReadAloudClickEventStr(value: string) {
    this._clickEventStr = value;
  }

  private _forceHide = false;
  @Input()
  set htcReadAloudForceHide(value: boolean) {
    this._forceHide = value;
  }

  private _customTextColor!: string;
  @Input()
  set htcReadAloudCustomTextColor(value: string) {
    this._customTextColor = value;
  }
  // For _clickEventStr.
  // The given value is unique to the button,
  // but this function makes the value unique for every _click_ too
  // so ngOnChanges gets triggered every click.
  static generateUniqueClickValue(value: string): string {
    return value + ReadAloudDirective.CLICK_DELIM + Date.now();
  }

  ngOnInit(): void {
    this.component =
      this.view.createComponent<ReadAloudComponent>(
        ReadAloudComponent
      ).instance;
    this.component.template = this.template;
    this.component.type = this._type;
    this.component.theme = this._theme;
    this.component.preventTabTargeting = this._preventTabTargeting;
    this.component.audioData = this._audio;
    this.component.forceHide = this._forceHide;
    this.component.customTextColor = this._customTextColor;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this._clickListenForStr &&
      changes.htcReadAloudClickEventStr &&
      this._clickEventStr &&
      this.doesClickEventStrMatch()
    ) {
      this.component.handleClick();
    } else if (changes.htcReadAloud && this.component) {
      this.component.audioData = this._audio;
    }
    if (changes.htcReadAloudForceHide && this.component) {
      this.component.forceHide = this._forceHide;
    }
  }

  private doesClickEventStrMatch(): boolean {
    const clickEventStrWithoutExcess = this._clickEventStr.split(
      ReadAloudDirective.CLICK_DELIM
    )[0];
    return clickEventStrWithoutExcess === this._clickListenForStr;
  }
}
