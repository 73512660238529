import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SpeechSynthesisService } from 'src/app/modules/speech-synthesis/services/speech-synthesis/speech-synthesis.service';

@Injectable({
  providedIn: 'root',
})
export class StopSpeechSynthesisGuard {
  constructor(private speechSynthesisService: SpeechSynthesisService) {}
  canDeactivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.speechSynthesisService.stop();
    return true;
  }
}
