import { Injectable } from '@angular/core';
import { QuestionState } from '../../enums/question-log';
import { ClickstreamService } from '../clickstream/clickstream.service';
import { ClickstreamEventTypeName } from '../../enums/clickstream-events';
import { FeatureFlagService } from 'src/app/modules/feature-flags/services/feature-flag/feature-flag.service';
import { FeatureFlags } from 'src/app/shared/enums/feature-flags';

@Injectable({
  providedIn: 'root',
})
export class QuestionLogService {
  readonly STRING_INNER_DELIMITER = '|';

  question = '';
  correct: string | string[] = '';
  discrims: string[] = [];
  isPractice = false;
  teiType = 0;
  questionStartTime = 0;
  shouldSort = true;
  state = QuestionState.initial;

  private preventClickstream = false;

  constructor(
    private clickstreamService: ClickstreamService,
    private featureFlagService: FeatureFlagService
  ) {
    this.preventClickstream = !this.featureFlagService.isFlagEnabled(
      FeatureFlags.USE_CLICKSTREAM
    );
    this.featureFlagService.flagsChanged.subscribe(() => {
      this.preventClickstream = !this.featureFlagService.isFlagEnabled(
        FeatureFlags.USE_CLICKSTREAM
      );
    });
  }

  askOpenEndedQuestion(
    questionId: string,
    question: string,
    templateId: number,
    rubric?: string,
    wordCount?: number
  ): void {
    if (this.preventClickstream) {
      console.warn(
        `clickstream flag is off, we are not using the question log`
      );
      return;
    }
    this.question = question;
    this.state = QuestionState.askQuestion;
    this.questionStartTime = Date.now();
    const data = [question, templateId];
    if (rubric) {
      data.push(rubric);
    }
    if (wordCount) {
      data.push(wordCount.toString());
    }
    this.clickstreamService.addEventFromProperties(
      ClickstreamEventTypeName.Event,
      'Open Ended Question[' + questionId + ']',
      data
    );
  }

  askTextEntryQuestion(
    questionId: string,
    question: string,
    answers: string[],
    templateId: number
  ): void {
    if (this.preventClickstream) {
      console.warn(
        `clickstream flag is off, we are not using the question log`
      );
      return;
    }
    this.question = question;
    this.state = QuestionState.askQuestion;
    this.questionStartTime = Date.now();
    this.discrims = answers;
    this.clickstreamService.addEventFromProperties(
      ClickstreamEventTypeName.Event,
      'Text Entry Question[' + questionId + ']',
      [question, templateId, answers]
    );
  }

  askQuestion(
    question: string,
    correct: string | string[],
    discrims: string[],
    isPractice: boolean,
    teiType: number,
    shouldSort = true
  ): void {
    if (this.preventClickstream) {
      console.warn(
        `clickstream flag is off, we are not using the question log`
      );
      return;
    }
    if (this.state === QuestionState.initial) {
      let sortedCorrect = '';
      if (shouldSort) {
        if (Array.isArray(correct)) {
          sortedCorrect = (this.sortAnswers(correct) as string[]).join(
            this.STRING_INNER_DELIMITER
          );
        } else {
          sortedCorrect = this.sortAnswers(correct) as string;
        }
      } else {
        sortedCorrect = Array.isArray(correct)
          ? correct.join(this.STRING_INNER_DELIMITER)
          : (correct as string);
      }

      this.question = question;
      this.correct = sortedCorrect;
      this.discrims = discrims;
      this.isPractice = isPractice;
      this.questionStartTime = Date.now();
      this.state = QuestionState.askQuestion;

      this.clickstreamService.addEventFromProperties(
        ClickstreamEventTypeName.AskQuestion,
        sortedCorrect,
        [
          2, //event data format version
          !isPractice, //should this count towards the activity score?
          question,
          discrims,
          teiType.toString(),
        ]
      );
    } else if (
      this.state === QuestionState.answerQuestion ||
      this.state === QuestionState.reAnswerQuestion
    ) {
      this.state = QuestionState.reAskQuestion;
      this.questionStartTime = Date.now();
      this.clickstreamService.addEventFromProperties(
        ClickstreamEventTypeName.Event,
        'ReAskQuestion',
        this.correct
      );
    }
  }

  reAskQuestion(): void {
    this.askQuestion(
      this.question,
      this.correct,
      this.discrims,
      this.isPractice,
      this.teiType,
      this.shouldSort
    );
  }

  answerOpenEndedQuestion(questionId: string, response: string): void {
    if (this.preventClickstream) {
      console.warn(
        `clickstream flag is off, we are not using the question log`
      );
      return;
    }
    const timeElapsed = Date.now() - this.questionStartTime;
    this.state = QuestionState.initial;
    this.clickstreamService.addEventFromProperties(
      ClickstreamEventTypeName.Event,
      'Open Ended Answer[' + questionId + ']',
      [response, timeElapsed.toString()]
    );
  }

  answerTextEntryQuestion(questionId: string, response: string[]): void {
    if (this.preventClickstream) {
      console.warn(
        `clickstream flag is off, we are not using the question log`
      );
      return;
    }
    const timeElapsed = Date.now() - this.questionStartTime;
    this.state = QuestionState.initial;
    this.clickstreamService.addEventFromProperties(
      ClickstreamEventTypeName.Event,
      'Text Entry Answer[' + questionId + ']',
      [response, timeElapsed.toString()]
    );
  }

  // eslint-disable-next-line complexity
  answerQuestion(response: string | string[], shouldSort = true): void {
    if (this.preventClickstream) {
      console.warn(
        `clickstream flag is off, we are not using the question log`
      );
      return;
    }
    const timeElapsed = Date.now() - this.questionStartTime;

    let sortedResponse: string | string[] = '';
    if (shouldSort) {
      sortedResponse = this.sortAnswers(response);
    } else {
      sortedResponse = response;
    }

    let correct = sortedResponse === this.correct;

    if (Array.isArray(sortedResponse)) {
      correct =
        sortedResponse.length === this.correct.length &&
        sortedResponse.every((value, index) => value === this.correct[index]);
    }

    if (this.state === QuestionState.askQuestion) {
      this.state = correct
        ? QuestionState.initial
        : QuestionState.answerQuestion;

      this.clickstreamService.addEventFromProperties(
        correct
          ? ClickstreamEventTypeName.AnswerCorrect
          : ClickstreamEventTypeName.AnswerIncorrect,
        Array.isArray(sortedResponse)
          ? sortedResponse.join(this.STRING_INNER_DELIMITER)
          : sortedResponse,
        [
          '2', //event data format version
          timeElapsed.toString(),
        ]
      );
    } else if (this.state === QuestionState.reAskQuestion) {
      this.state = correct
        ? QuestionState.initial
        : QuestionState.reAnswerQuestion;

      this.clickstreamService.addEventFromProperties(
        ClickstreamEventTypeName.Event,
        'ReAnswerQuestion',
        [
          '2',
          timeElapsed.toString(),
          Array.isArray(sortedResponse)
            ? sortedResponse.join(this.STRING_INNER_DELIMITER)
            : sortedResponse,
        ]
      );
    }
  }

  recordSelfRating(prompt: string, rating: string, timeElapsed: number): void {
    if (this.preventClickstream) {
      console.warn(
        `clickstream flag is off, we are not using the question log`
      );
      return;
    }
    this.clickstreamService.addEventFromProperties(
      ClickstreamEventTypeName.Event,
      'SelfRating',
      [prompt, rating, timeElapsed.toString()]
    );
  }

  resetState(): void {
    this.state = QuestionState.initial;
  }

  private sortAnswers(answers: string | string[]): string | string[] {
    if (Array.isArray(answers)) {
      answers.forEach((answer, index) => {
        answers[index] = this.sortAnswers(answer) as string;
      });
      return answers.sort((a, b) => a.localeCompare(b));
    } else {
      if (answers.includes(this.STRING_INNER_DELIMITER)) {
        return answers
          .split(this.STRING_INNER_DELIMITER)
          .sort((a, b) => a.localeCompare(b))
          .join(this.STRING_INNER_DELIMITER);
      } else {
        return answers;
      }
    }
  }
}
