import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MessageService } from 'src/app/modules/message/services/message/message.service';
import { SpeechSynthesisService } from 'src/app/modules/speech-synthesis/services/speech-synthesis/speech-synthesis.service';
import { MessageCodes } from 'src/app/shared/enums';
import { AudioData } from 'src/app/shared/interfaces';
import { SettingsService } from 'src/app/shared/services/settings/settings.service';
import { TrackingService } from '../../../tracking/services/tracking.service';
import { speechSynthesisErrorMessages } from '../../data/error-messages';

/**
 * Service to interface between the read aloud service and the speech synthesis service
 */
@Injectable({
  providedIn: 'root',
})
export class SpeechSynthesisReadAloudService {
  playbackEndId: Subject<string> = new Subject<string>();
  playbackStartId: Subject<string> = new Subject<string>();
  currentId = '';
  currentlyPaused = false;
  currentTryCount = 0;
  retryInterval = 50; // milliseconds
  maxTries = 3;
  currentStarted = false;
  currentlyRetrying = false;
  retryTimeout!: NodeJS.Timeout;

  constructor(
    private speechSynthesisService: SpeechSynthesisService,
    private settingsService: SettingsService,
    private messageService: MessageService,
    private trackingService: TrackingService
  ) {}

  play(audioData: AudioData): void {
    if (!audioData.text || this.currentlyRetrying) return;
    if (this.currentId !== audioData.id) {
      this.currentId = audioData.id;
      this.currentTryCount = 1;
      this.currentStarted = false;
      this.currentlyRetrying = false;
    } else {
      if (this.currentTryCount < this.maxTries) {
        this.currentTryCount++;
      }
    }

    this.speechSynthesisService.speak(
      this.replaceSpecialCharacters(audioData.text),
      {
        onStartCallback: (): void => {
          this.playbackStartId.next(this.currentId);
          this.currentStarted = true;
        },
        onEndCallback: (): void => {
          if (!this.currentStarted) {
            console.error(
              'SpeechSynthesis onEndCallback was called before onStartCallback, assume tab is muted.'
            );
            this.retryPlayOrError(audioData, { error: 'tab-muted' });
          } else {
            this.sendPlaybackEndId();
          }
        },
        onErrorCallback: (event: SpeechSynthesisErrorEvent): void => {
          if (this.shouldIgnoreError(event.error)) return;
          console.error('SpeechSynthesisErrorEvent', event);
          this.retryPlayOrError(audioData, event);
        },
      }
    );
  }

  retryPlayOrError(
    audioData: AudioData,
    event: SpeechSynthesisErrorEvent | { error: string }
  ): void {
    if (this.currentTryCount < 3) {
      this.currentlyRetrying = true;
      if (this.retryTimeout) clearTimeout(this.retryTimeout);
      this.retryTimeout = setTimeout(() => {
        this.currentlyRetrying = false;
        this.play(audioData);
      }, this.retryInterval);
    } else {
      this.handleError(event);
    }
  }

  handleError(event: SpeechSynthesisErrorEvent | { error: string }): void {
    this.sendPlaybackEndId();
    const messageToShow = speechSynthesisErrorMessages[event.error];
    if (messageToShow) {
      this.messageService.showMessage(messageToShow, () => {
        if (messageToShow !== MessageCodes.SPEECH_SYNTHESIS_TEXT_TOO_LONG)
          this.settingsService.saveSettings({ readAloud: false }); // toggle off ReadAloud
      });
    } else {
      console.error(
        `Speech Synthesis Read Aloud error doesn't have a message to show ;_;`,
        event
      );
    }
    this.trackError(event.error);
  }

  // Certain errors are expected behavior
  shouldIgnoreError(error: string): boolean {
    switch (error) {
      case 'canceled':
      case 'interrupted':
        return true;
      default:
        return false;
    }
  }

  trackError(error: string): void {
    this.trackingService.push({
      event: 'Read Aloud Error',
      error: error,
    });
  }

  sendPlaybackEndId(): void {
    this.playbackEndId.next(this.currentId);
    this.currentId = '';
  }

  stop(): void {
    this.speechSynthesisService.stop();
    this.currentlyPaused = false;
    this.sendPlaybackEndId();
  }

  pause(): void {
    this.speechSynthesisService.pause();
    this.currentlyPaused = !this.currentlyPaused;
  }

  togglePause(): void {
    if (this.currentlyPaused) {
      this.resume();
    } else {
      this.pause();
    }
  }

  resume(): void {
    this.speechSynthesisService.resume();
    this.currentlyPaused = !this.currentlyPaused;
  }

  private replaceSpecialCharacters(text: string): string {
    const specialCharacters: { [key: string]: string } = {
      '<': 'less than',
      '>': 'greater than',
    };
    let newSting = text;
    Object.keys(specialCharacters).forEach((key: string) => {
      newSting = newSting
        .replace(new RegExp(key, 'g'), `${specialCharacters[key]} `)
        .toString();
    });

    return newSting;
  }
}
