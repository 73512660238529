import { PopulationTextEntry } from '../shared/interfaces/population-text-entry';
import { TEST_CHOICE_TYPES } from './choice-types';
import { TestAssociatedSuccessCriteria } from './test-associated-success-criteria.enum';

export const textEntryQuestions: { [key: number]: PopulationTextEntry } = {
  [TEST_CHOICE_TYPES.TEST_R_TE_SHORT]: {
    text: [
      {
        // text: 'This is the reading text entry (correct | Correct) {WORD_TOKEN} short question (1000 | 1,000) {NUMBER_TOKEN}?',
        text: 'This is the reading text entry short question (1000 | 1,000) {NUMBER_TOKEN}?',
      },
    ],
    answers: [
      // ['correct', 'Correct'],
      [1000, '1,000'],
    ],
    associatedSuccessCriteria: [TestAssociatedSuccessCriteria.TEXT_ENTRY],
    targetedFeedback: [
      {
        firstIncorrect: {
          content: 'This is incorrect 1 - first incorrect targeted feedback',
        },
        secondIncorrect: {
          content: 'This is incorrect 1 - second incorrect targeted feedback',
        },
      },
      {
        firstIncorrect: {
          content: 'This is incorrect 2 - first incorrect targeted feedback',
        },
        secondIncorrect: {
          content: 'This is incorrect 2 - second incorrect targeted feedback',
        },
      },
    ],
  },
  [TEST_CHOICE_TYPES.TEST_R_TE_MEDIUM]: {
    text: [
      {
        // text: 'This is the reading text entry (correct | Correct) {WORD_TOKEN} medium question (correct | Correct) {WORD_TOKEN}? Added question text for (1) {NUMBER_TOKEN} medium length.',
        text: 'This is the reading text entry (correct | Correct) {WORD_TOKEN} medium question? Added question text for medium length.',
      },
    ],
    answers: [
      ['correct', 'Correct'],
      // ['correct', 'Correct'], [1]
    ],
    associatedSuccessCriteria: [TestAssociatedSuccessCriteria.TEXT_ENTRY],
    targetedFeedback: [
      {
        firstIncorrect: {
          content: 'This is incorrect 1 - first incorrect targeted feedback',
        },
        secondIncorrect: {
          content: 'This is incorrect 1 - second incorrect targeted feedback',
        },
      },
      {
        firstIncorrect: {
          content: 'This is incorrect 2 - first incorrect targeted feedback',
        },
        secondIncorrect: {
          content: 'This is incorrect 2 - second incorrect targeted feedback',
        },
      },
      {
        firstIncorrect: {
          content: 'This is incorrect 3 - first incorrect targeted feedback',
        },
        secondIncorrect: {
          content: 'This is incorrect 3 - second incorrect targeted feedback',
        },
      },
    ],
  },
  [TEST_CHOICE_TYPES.TEST_R_TE_LONG]: {
    text: [
      {
        // text: 'This is the reading text entry (correct | Correct) {WORD_TOKEN} long question? Added (correct | Correct) {WORD_TOKEN} text to the question in order to add longer question (1) {NUMBER_TOKEN}. More question text added here for (1) {NUMBER_TOKEN} length.',
        text: 'This is the reading text entry (correct | Correct) {WORD_TOKEN} long question? Added text to the question in order to add longer question. More question text added here for length.',
      },
    ],
    answers: [
      ['correct', 'Correct'],
      // ['correct', 'Correct'], [1], [1]
    ],
    associatedSuccessCriteria: [TestAssociatedSuccessCriteria.TEXT_ENTRY],
    targetedFeedback: [
      {
        firstIncorrect: {
          content: 'This is incorrect 1 - first incorrect targeted feedback',
        },
        secondIncorrect: {
          content: 'This is incorrect 1 - second incorrect targeted feedback',
        },
      },
      {
        firstIncorrect: {
          content: 'This is incorrect 2 - first incorrect targeted feedback',
        },
        secondIncorrect: {
          content: 'This is incorrect 2 - second incorrect targeted feedback',
        },
      },
      {
        firstIncorrect: {
          content: 'This is incorrect 3 - first incorrect targeted feedback',
        },
        secondIncorrect: {
          content: 'This is incorrect 3 - second incorrect targeted feedback',
        },
      },
      {
        firstIncorrect: {
          content: 'This is incorrect 4 - first incorrect targeted feedback',
        },
        secondIncorrect: {
          content: 'This is incorrect 4 - second incorrect targeted feedback',
        },
      },
    ],
  },
  [TEST_CHOICE_TYPES.TEST_S_TE_SHORT]: {
    text: [
      {
        // text: '¿Esta es la entrada de texto de lectura (correcta | Correcta) {WORD_TOKEN} pregunta corta (1) {NUMBER_TOKEN}?',
        text: '¿Esta es la entrada de texto de lectura (correcta | Correcta) {WORD_TOKEN} pregunta corta?',
      },
    ],
    answers: [
      ['correcta', 'Correcta'],
      // [1]
    ],
    associatedSuccessCriteria: [TestAssociatedSuccessCriteria.TEXT_ENTRY],
    targetedFeedback: [
      {
        firstIncorrect: {
          content:
            'Esto es incorrecto 1: primer comentario dirigido incorrecto',
        },
        secondIncorrect: {
          content:
            'Esto es incorrecto 1 segundo de comentario dirigido incorrecto',
        },
      },
      {
        firstIncorrect: {
          content:
            'Esto es incorrecto 2: primer comentario dirigido incorrecto',
        },
        secondIncorrect: {
          content:
            'Esto es incorrecto 2 segundos de comentarios dirigidos incorrectos',
        },
      },
    ],
  },
  [TEST_CHOICE_TYPES.TEST_S_TE_MEDIUM]: {
    text: [
      {
        // text: '¿Esta es la entrada de texto de lectura (correcta | Correcta) {WORD_TOKEN} pregunta mediana (correcta | Correcta) {WORD_TOKEN}? Se agregó texto de pregunta de longitud media para (1) {NUMBER_TOKEN}.',
        text: '¿Esta es la entrada de texto de lectura (correcta | Correcta) {WORD_TOKEN} pregunta mediana? Se agregó texto de pregunta de longitud media para.',
      },
    ],
    answers: [
      ['correcta', 'Correcta'],
      // ['correcta', 'Correcta'], [1]
    ],
    associatedSuccessCriteria: [TestAssociatedSuccessCriteria.TEXT_ENTRY],
    targetedFeedback: [
      {
        firstIncorrect: {
          content:
            'Esto es incorrecto 1: primer comentario dirigido incorrecto',
        },
        secondIncorrect: {
          content:
            'Esto es incorrecto 1 segundo de comentario dirigido incorrecto',
        },
      },
      {
        firstIncorrect: {
          content:
            'Esto es incorrecto 2: primer comentario dirigido incorrecto',
        },
        secondIncorrect: {
          content:
            'Esto es incorrecto 2 segundos de comentarios dirigidos incorrectos',
        },
      },
      {
        firstIncorrect: {
          content:
            'Esto es incorrecto 3: primer comentario dirigido incorrecto',
        },
        secondIncorrect: {
          content:
            'Esto es incorrecto. Comentarios dirigidos incorrectos de 3 segundos.',
        },
      },
    ],
  },
  [TEST_CHOICE_TYPES.TEST_S_TE_LONG]: {
    text: [
      {
        // text: '¿Esta es la pregunta larga de la entrada de texto de lectura (correcta | Correcta) {WORD_TOKEN}? Se agregó texto (correcta | Correcta) {WORD_TOKEN} a la pregunta para agregar una pregunta más larga (1) {NUMBER_TOKEN}. Se agregó más texto de pregunta aquí con una longitud de (1) {NUMBER_TOKEN}.',
        text: '¿Esta es la pregunta larga de la entrada de texto de lectura (correcta | Correcta) {WORD_TOKEN}? Se agregó texto a la pregunta para agregar una pregunta más larga. Se agregó más texto de pregunta aquí con una longitud de.',
      },
    ],
    answers: [
      ['correcta', 'Correcta'],
      // ['correcta', 'Correcta'], [1], [1]
    ],
    associatedSuccessCriteria: [TestAssociatedSuccessCriteria.TEXT_ENTRY],
    targetedFeedback: [
      {
        firstIncorrect: {
          content:
            'Esto es incorrecto 1: primer comentario dirigido incorrecto',
        },
        secondIncorrect: {
          content:
            'Esto es incorrecto 1 segundo de comentario dirigido incorrecto',
        },
      },
      {
        firstIncorrect: {
          content:
            'Esto es incorrecto 2: primer comentario dirigido incorrecto',
        },
        secondIncorrect: {
          content:
            'Esto es incorrecto 2 segundos de comentarios dirigidos incorrectos',
        },
      },
      {
        firstIncorrect: {
          content:
            'Esto es incorrecto 3: primer comentario dirigido incorrecto',
        },
        secondIncorrect: {
          content:
            'Esto es incorrecto. Comentarios dirigidos incorrectos de 3 segundos.',
        },
      },
      {
        firstIncorrect: {
          content:
            'Esto es incorrecto 4: primer comentario dirigido incorrecto',
        },
        secondIncorrect: {
          content:
            'Esto es incorrecto. Comentarios dirigidos incorrectos de 4 segundos.',
        },
      },
    ],
  },
};
