import { Activity } from 'src/app/shared/interfaces';

export const readingPrep7: Activity = {
  id: '018ea019-0748-7288-9a2a-d17a3e143768',
  product: 'Reading',
  language: 'en',
  skillName: 'Prepositional Phrases',
  thumbnail: {
    id: 'recMfeTDTjBq0OBwH',
    url: '/helix/UX_Images/Lesson_Posters/Prepositions_G5V1_Poster.jpg',
    altText: [
      {
        language: 'en',
        altText:
          'Axis and CAT talk in the cockpit after landing on a new planet.',
      },
      {
        language: 'es',
        altText:
          'Axis y G.A.T.A. hablan en la cabina de mando después de aterrizar en un nuevo planeta.',
      },
    ],
  },
  instructionsImage: {
    id: 'recShqii4tO2JCQyf',
    url: '/helix/UX_Images/Characters/Axis/Axis_Chibi_Head_Happy_Mouth_Open.svg',
    altText: [
      {
        language: 'en',
        altText: 'Axis is smiling.',
      },
      {
        language: 'es',
        altText: 'Axis está sonriendo.',
      },
    ],
  },
  characterLeanout: {
    id: 'recBBGySIgxHLnC6k',
    url: '/helix/TEI/Leanouts/Axis_Leanout_01.svg',
    altText: [
      {
        language: 'en',
        altText: 'Axis leans out from the side of the screen.',
      },
      {
        language: 'es',
        altText: 'Axis se asoma por el lado de la pantalla.',
      },
    ],
  },
  description:
    'When The Cosmic Explorer runs out of fuel, Axis and C.A.T. use a map and prepositional phrases to find fuel geodes at the edge of a space jungle.',
  domain: {
    text: 'Parts of Speech',
  },
  seriesName: 'Preposition Island',
  episodeNumber: '1',
  episodeTitle: 'Jungle Geodes',
  videos: [
    {
      id: 'recbaL2Neql5tQQQV',
      url: '/helix/Reading/Grade_5/Prepositions/Episode_01/hls_streams/R_G5_Prepositions_EP01_Video.m3u8',
      posterURL: '/helix/UX_Images/Lesson_Posters/Prepositions_G5V1_Poster.jpg',
      captions: [
        {
          src: '/helix/Reading/Grade_5/Prepositions/Episode_01/R_G5_Prepositions_EP01_Video.vtt',
          srclang: 'en',
          mode: 'hidden',
          kind: 'captions',
        },
      ],
    },
  ],
  startScreenData: {
    learningTarget: [
      {
        id: '22366.A',
        learningTarget: {
          text: 'how prepositions and prepositional phrases function in sentences in a variety of positions',
        },
        successCriteria: [
          {
            id: '22366.A.1',
            text: 'explain how prepositions show location, time, agency, and direction',
          },
          {
            id: '22366.A.2',
            text: 'create sentences using them in a variety of positions',
          },
        ],
      },
    ],
    background_leftImage: {
      id: 'recMfeTDTjBq0OBwH',
      url: '/helix/UX_Images/Lesson_Posters/Prepositions_G5V1_Poster.jpg',
      altText: [
        {
          language: 'en',
          altText:
            'Axis and CAT talk in the cockpit after landing on a new planet.',
        },
        {
          language: 'es',
          altText:
            'Axis y G.A.T.A. hablan en la cabina de mando después de aterrizar en un nuevo planeta.',
        },
      ],
    },
    background_rightImage: {
      id: 'recxjC353VMav5ybu',
      url: '/helix/UX_Images/Start_Screen_Blobs/Nova-Scouts_space-sky.svg',
      altText: [
        {
          language: 'en',
          altText: 'Stars in space.',
        },
        {
          language: 'es',
          altText: 'Estrellas en el espacio.',
        },
      ],
    },
    foreground: {
      id: 'recJFZiQMxFozF9ks',
      url: '/helix/UX_Images/Start_Screen_Characters/Axis3.svg',
      altText: [
        {
          language: 'en',
          altText: 'Axis is pointing at something.',
        },
        {
          language: 'es',
          altText: 'Axis está señalando algo.',
        },
      ],
    },
  },
  populations: [
    {
      id: '22366.A.2.1',
      templateID: 1,
      complexity: 'Synthesis',
      hint: [
        {
          id: '22366.A.2.1.0',
          type: 'BasicSlide',
          data: {
            title:
              'Prepositions give information about location, direction, time, or agency.\n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Use the correct preposition to create a sentence that matches the image. \nWhile on vacation in Florida, Andy relaxed on the beach under the coconut tree all {CHOICE_TOKEN} himself.\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '22366.A.2.1_1',
                  text: 'by\n',
                },
                {
                  id: '22366.A.2.1_2',
                  text: 'without\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'The preposition _without_and the object _himself_ form a prepositional phrase; however, that phrase does not match the image.\n',
                    },
                  },
                },
                {
                  id: '22366.A.2.1_3',
                  text: 'not\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        '_Not_ is an adverb. We need a preposition to complete the prepositional phrase.\n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['22366.A.2.1_1'],
        associatedSuccessCriteria: ['22366.A.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'The preposition _by_and the object _himself_ form a correct prepositional phrase that matches the image.\n',
      },
      image: {
        id: 'recaG0kdzb6WWd1bl',
        url: '/helix/TEI/SVGs/TEI_prepositions_boyonbeach_01.svg',
        altText: [
          {
            language: 'en',
            altText: 'A man sits on a chair at the beach.',
          },
          {
            language: 'es',
            altText: 'Un hombre se sienta en una silla en la playa.',
          },
        ],
      },
    },
    {
      id: '22366.A.1.2',
      templateID: 0,
      complexity: 'Analysis',
      hint: [
        {
          id: '22366.A.1.2.0',
          type: 'BasicSlide',
          data: {
            title: '\n',
            imgData: {
              id: 'rec2BtqMq9YZXGM1t',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_04.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A chart displays that the four types of prepositions are Time, Location, Direction, and Agency.',
                },
                {
                  language: 'es',
                  altText:
                    'Una gráfica muestra las cuatro clases diferentes de proposiciones. ',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: 'Based on its use in the sentence, identify the type of preposition that is bolded.\nWhile on vacation in Florida, Andi relaxed on the beach under the coconut tree all **by herself**.\n',
        },
        answerChoices: [
          {
            id: '22366.A.1.2_1',
            choice: {
              texts: [
                {
                  text: 'agency\n',
                },
              ],
            },
          },
          {
            id: '22366.A.1.2_2',
            choice: {
              texts: [
                {
                  text: 'location\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'The preposition _by_ can be used to indicate location, but in this sentence it does not answer the question “where?”\n',
              },
            },
          },
          {
            id: '22366.A.1.2_3',
            choice: {
              texts: [
                {
                  text: 'direction\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'The preposition _by_ can be used to indicate direction, but in this sentence it does not answer the question “how?”\n',
              },
            },
          },
        ],
        answers: ['22366.A.1.2_1'],
        associatedSuccessCriteria: ['22366.A.1'],
      },
      feedbackSecondIncorrect: {
        content:
          '_By_ can be used as a preposition of time, location, or agency. In this sentence it is a preposition of agency and shows who is doing the action.\n',
      },
    },
    {
      id: '22366.A.2.3',
      templateID: 1,
      complexity: 'Synthesis',
      hint: [
        {
          id: '22366.A.2.3.0',
          type: 'BasicSlide',
          data: {
            title:
              'Prepositions give information about location, direction, time, or agency.\n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Use the correct preposition to create a sentence that matches the image.\nDuring math class, a mischievous student launched a paper airplane {CHOICE_TOKEN} the air and over the teacher’s head.\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '22366.A.2.3_1',
                  text: 'through\n',
                },
                {
                  id: '22366.A.2.3_2',
                  text: 'under\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'The preposition _under_and the object _the air_ form a prepositional phrase; however, that does not match the image.\n',
                    },
                  },
                },
                {
                  id: '22366.A.2.3_3',
                  text: 'flying\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content: '_Flying_ is a verb, not a preposition.\n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['22366.A.2.3_1'],
        associatedSuccessCriteria: ['22366.A.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'The preposition _through_and the object _the air_ form a correct prepositional phrase that matches the image.\n',
      },
      image: {
        id: 'recmHi6x41zocFXK8',
        url: '/helix/TEI/SVGs/TEI_prepositons_mathclassroom_01.svg',
        altText: [
          {
            language: 'en',
            altText:
              'A teacher stands at the whiteboard in front of students while a paper airplane flies toward them.',
          },
          {
            language: 'es',
            altText:
              'Un profesor está parado junto al pizarrón en frente de los estudiantes mientras un avioncito de papel vuela hacia ellos.',
          },
        ],
      },
    },
    {
      id: '22366.A.1.4',
      templateID: 0,
      complexity: 'Analysis',
      hint: [
        {
          id: '22366.A.1.4.0',
          type: 'BasicSlide',
          data: {
            title: '\n',
            imgData: {
              id: 'rec2BtqMq9YZXGM1t',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_04.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A chart displays that the four types of prepositions are Time, Location, Direction, and Agency.',
                },
                {
                  language: 'es',
                  altText:
                    'Una gráfica muestra las cuatro clases diferentes de proposiciones. ',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: "Based on its use in the sentence, identify the type of preposition that is bolded.\nDuring math class, a mischievous student launched a paper airplane **through the air** and over the teacher's head.\n",
        },
        answerChoices: [
          {
            id: '22366.A.1.4_1',
            choice: {
              texts: [
                {
                  text: 'direction\n',
                },
              ],
            },
          },
          {
            id: '22366.A.1.4_2',
            choice: {
              texts: [
                {
                  text: 'time\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'The preposition _through_ can be used to indicate time, but in this sentence it does not answer the question “when?”\n',
              },
            },
          },
          {
            id: '22366.A.1.4_3',
            choice: {
              texts: [
                {
                  text: 'agency\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'The preposition _through_ cannot be used to indicate agency, as it does not answer the question “who?”\n',
              },
            },
          },
        ],
        answers: ['22366.A.1.4_1'],
        associatedSuccessCriteria: ['22366.A.1'],
      },
      feedbackSecondIncorrect: {
        content:
          '_Through_ can be used as a preposition of time or direction. In this sentence, it is a preposition of direction and shows how the airplane flies.\n',
      },
    },
    {
      id: '22366.A.2.5',
      templateID: 1,
      complexity: 'Synthesis',
      hint: [
        {
          id: '22366.A.2.5.0',
          type: 'BasicSlide',
          data: {
            title:
              'A prepositional phrase consists of a preposition and an object to give information about time, location, direction, or agency.\n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Use the correct prepositional phrase to create a sentence that matches the image.\nComfortably seated {CHOICE_TOKEN}, the boy watched TV until his mother came in, turned it off, and told him to clean up his room.\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '22366.A.2.5_1',
                  text: 'in the chair\n',
                },
                {
                  id: '22366.A.2.5_2',
                  text: 'across the couch\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'The preposition _across_and the object _the couch_ form a correct prepositional phrase; however, that does not match the image.\n',
                    },
                  },
                },
                {
                  id: '22366.A.2.5_3',
                  text: 'all alone\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        '_All alone_ is an adverb phrase, not a prepositional phrase.\n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['22366.A.2.5_1'],
        associatedSuccessCriteria: ['22366.A.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'The preposition _in_and the object _the chair_ form a correct prepositional phrase that matches the image.\n',
      },
      image: {
        id: 'rec2kxij7dxFQl1cK',
        url: '/helix/TEI/SVGs/TEI_prepositons_turnofftv_01.svg',
        altText: [
          {
            language: 'en',
            altText:
              'An adult stands beside a child who is sitting on the couch watching TV.',
          },
          {
            language: 'es',
            altText:
              'Un adulto está parado al lado de un niño que mira televisión en un sofá.',
          },
        ],
      },
    },
    {
      id: '22366.A.1.6',
      templateID: 0,
      complexity: 'Analysis',
      hint: [
        {
          id: '22366.A.1.6.0',
          type: 'BasicSlide',
          data: {
            title: '\n',
            imgData: {
              id: 'rec2BtqMq9YZXGM1t',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_04.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A chart displays that the four types of prepositions are Time, Location, Direction, and Agency.',
                },
                {
                  language: 'es',
                  altText:
                    'Una gráfica muestra las cuatro clases diferentes de proposiciones. ',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: 'Based on its use in the sentence, identify the type of preposition that is bolded.\nComfortably seated **in the chair**, the boy watched TV until his mother came in, turned it off, and told him to clean up his room.\n',
        },
        answerChoices: [
          {
            id: '22366.A.1.6_1',
            choice: {
              texts: [
                {
                  text: 'location\n',
                },
              ],
            },
          },
          {
            id: '22366.A.1.6_2',
            choice: {
              texts: [
                {
                  text: 'direction\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'The preposition _in_, when used with the object _the chair_, cannot be used to indicate direction, as it does not answer the question “how?”\n',
              },
            },
          },
          {
            id: '22366.A.1.6_3',
            choice: {
              texts: [
                {
                  text: 'time\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'The preposition _in_, when used with the object _the chair_, cannot be used to indicate time, as it does not answer the question “when?”\n',
              },
            },
          },
        ],
        answers: ['22366.A.1.6_1'],
        associatedSuccessCriteria: ['22366.A.1'],
      },
      feedbackSecondIncorrect: {
        content:
          '_In_ can be used as a preposition of location or time. In this sentence, it is a preposition of location and shows where the boy sat.\n',
      },
    },
    {
      id: '22366.A.2.7',
      templateID: 1,
      complexity: 'Synthesis',
      hint: [
        {
          id: '22366.A.2.7.0',
          type: 'BasicSlide',
          data: {
            title:
              'A prepositional phrase consists of a preposition and an object to give information about time, location, direction, or agency.\n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Use the correct prepositional phrase to create a sentence that matches the image.\n{CHOICE_TOKEN}, the man fell asleep, causing the woman next to him to become quite annoyed.\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '22366.A.2.7_1',
                  text: 'During the opera\n',
                },
                {
                  id: '22366.A.2.7_2',
                  text: 'After the concert\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'The preposition _after_and the object _the opera_ form a correct prepositional phrase; however, that does not match the image.\n',
                    },
                  },
                },
                {
                  id: '22366.A.2.7_3',
                  text: 'Not surprisingly\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        '_Not surprisingly_ is an adverb phrase, not a prepositional phrase.\n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['22366.A.2.7_1'],
        associatedSuccessCriteria: ['22366.A.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'The preposition _during_and the object _the opera_ form a correct prepositional phrase that matches the image.\n',
      },
      image: {
        id: 'recUIx9uMATFmsszO',
        url: '/helix/TEI/SVGs/TEI_prepositions_asleepattheopera_01.svg',
        altText: [
          {
            language: 'en',
            altText:
              'An old woman, a young woman, and a sleeping man watch an opera singer on stage.',
          },
          {
            language: 'es',
            altText:
              'Una mujer mayor, una mujer joven y un hombre que duerme se encuentran en un teatro mientras una cantante de ópera está en el escenario.',
          },
        ],
      },
    },
    {
      id: '22366.A.1.8',
      templateID: 0,
      complexity: 'Analysis',
      hint: [
        {
          id: '22366.A.1.8.0',
          type: 'BasicSlide',
          data: {
            title: '\n',
            imgData: {
              id: 'rec2BtqMq9YZXGM1t',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_04.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A chart displays that the four types of prepositions are Time, Location, Direction, and Agency.',
                },
                {
                  language: 'es',
                  altText:
                    'Una gráfica muestra las cuatro clases diferentes de proposiciones. ',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: 'Based on its use in the sentence, identify the type of preposition that is bolded.\n**During the opera**, the man fell asleep, causing the woman next to him to become quite annoyed.\n',
        },
        answerChoices: [
          {
            id: '22366.A.1.8_1',
            choice: {
              texts: [
                {
                  text: 'time\n',
                },
              ],
            },
          },
          {
            id: '22366.A.1.8_2',
            choice: {
              texts: [
                {
                  text: 'location\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'The preposition _during_ cannot be used to indicate location, as it does not answer the question “where?”\n',
              },
            },
          },
          {
            id: '22366.A.1.8_3',
            choice: {
              texts: [
                {
                  text: 'direction\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'The preposition _during_ cannot be used to indicate direction, as it does not answer the question “how?”\n',
              },
            },
          },
        ],
        answers: ['22366.A.1.8_1'],
        associatedSuccessCriteria: ['22366.A.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'The preposition _during_ indicates time since it answers the question “When did the man fall asleep?”\n',
      },
    },
    {
      id: '22366.A.2.9',
      templateID: 1,
      complexity: 'Synthesis',
      hint: [
        {
          id: '22366.A.2.9.0',
          type: 'BasicSlide',
          data: {
            title:
              'A prepositional phrase consists of a preposition and an object to give information about time, location, direction, or agency.\n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: "Use the correct prepositional phrase to create a sentence that matches the image.\nOn Mother's Day, Sam attempted to make breakfast all by herself but ended up making a gigantic mess {CHOICE_TOKEN}.\n",
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '22366.A.2.9_1',
                  text: 'throughout the kitchen\n',
                },
                {
                  id: '22366.A.2.9_2',
                  text: 'beyond the kitchen\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'The preposition _beyond_and the object _the kitchen_ form a correct prepositional phrase; however, that does not match the image.\n',
                    },
                  },
                },
                {
                  id: '22366.A.2.9_3',
                  text: 'breakfast casserole\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        '_Breakfast casserol_ e is a noun, not a prepositional phrase.\n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['22366.A.2.9_1'],
        associatedSuccessCriteria: ['22366.A.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'The preposition _throughout_and the object _the kitchen_ form a correct prepositional phrase that matches the image.\n',
      },
      image: {
        id: 'rec2r0wrD1xJHfPh0',
        url: '/helix/TEI/SVGs/TEI_prepositions_kidinkitchen_01.svg',
        altText: [
          {
            language: 'en',
            altText: 'A child cooks in a messy kitchen.',
          },
          {
            language: 'es',
            altText: 'Un niño cocina en una cocina desordenada.',
          },
        ],
      },
    },
    {
      id: '22366.A.1.10',
      templateID: 0,
      complexity: 'Analysis',
      hint: [
        {
          id: '22366.A.1.10.0',
          type: 'BasicSlide',
          data: {
            title: '\n',
            imgData: {
              id: 'rec2BtqMq9YZXGM1t',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_04.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A chart displays that the four types of prepositions are Time, Location, Direction, and Agency.',
                },
                {
                  language: 'es',
                  altText:
                    'Una gráfica muestra las cuatro clases diferentes de proposiciones. ',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: "Based on its use in the sentence, identify the type of preposition that is bolded.\nOn Mother's Day, Sam attempted to make breakfast all by himself but ended up making a gigantic mess **throughout the kitchen**.\n",
        },
        answerChoices: [
          {
            id: '22366.A.1.10_1',
            choice: {
              texts: [
                {
                  text: 'location\n',
                },
              ],
            },
          },
          {
            id: '22366.A.1.10_2',
            choice: {
              texts: [
                {
                  text: 'time\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'The preposition _throughout_ is not used to indicate time in this phrase, as it does not answer the question “when?”\n',
              },
            },
          },
          {
            id: '22366.A.1.10_3',
            choice: {
              texts: [
                {
                  text: 'direction\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'The preposition _throughout_ cannot be used to indicate direction, as it does not answer the question “how?”\n',
              },
            },
          },
        ],
        answers: ['22366.A.1.10_1'],
        associatedSuccessCriteria: ['22366.A.1'],
      },
      feedbackSecondIncorrect: {
        content:
          '_Throughout_ can be used as a preposition of location or time. In this sentence, it is a preposition of location and shows where the mess was.\n',
      },
    },
  ],
  activityManifest: [
    {
      type: 'START_SCREEN',
    },
    {
      type: 'ENTRY_SURVEY',
    },
    {
      type: 'VIDEO',
      id: 'recbaL2Neql5tQQQV',
    },
    {
      type: 'QUESTION',
      id: '22366.A.2.1',
    },
    {
      type: 'QUESTION',
      id: '22366.A.1.2',
    },
    {
      type: 'QUESTION',
      id: '22366.A.2.3',
    },
    {
      type: 'QUESTION',
      id: '22366.A.1.4',
    },
    {
      type: 'QUESTION',
      id: '22366.A.2.5',
    },
    {
      type: 'QUESTION',
      id: '22366.A.1.6',
    },
    {
      type: 'QUESTION',
      id: '22366.A.2.7',
    },
    {
      type: 'QUESTION',
      id: '22366.A.1.8',
    },
    {
      type: 'QUESTION',
      id: '22366.A.2.9',
    },
    {
      type: 'QUESTION',
      id: '22366.A.1.10',
    },
    {
      type: 'EXIT_SURVEY',
    },
    {
      type: 'RESULTS_SCREEN',
    },
  ],
  version: '812350559',
};
