import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ProgressNodeComponent } from './components/progress-node/progress-node.component';
import { VideoNodeComponent } from './components/video-node/video-node.component';
import { DinkusNodeComponent } from './components/dinkus-node/dinkus-node.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { StatusPerfectComponent } from './components/status-perfect/status-perfect.component';
import { StatusWrongComponent } from './components/status-wrong/status-wrong.component';
import { StatusCorrectComponent } from './components/status-correct/status-correct.component';
import { TranslateModule } from '@ngx-translate/core';
import { StatusCompleteComponent } from './components/status-complete/status-complete.component';

@NgModule({
  declarations: [
    ProgressBarComponent,
    ProgressNodeComponent,
    VideoNodeComponent,
    DinkusNodeComponent,
    StatusPerfectComponent,
    StatusWrongComponent,
    StatusCorrectComponent,
    StatusCompleteComponent,
  ],
  imports: [CommonModule, SharedModule, TranslateModule],
  exports: [ProgressBarComponent],
})
export class ProgressBarModule {}
