import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TimeElapsedService } from 'src/app/shared/services/time-elapsed/time-elapsed.service';

@Component({
  selector: 'htc-time-elapsed',
  templateUrl: './time-elapsed.component.html',
  styleUrls: ['./time-elapsed.component.scss'],
})
export class TimeElapsedComponent implements OnInit, OnDestroy {
  time = '00:00';
  private subscription = new Subscription();
  constructor(private timeElapsedService: TimeElapsedService) {}

  ngOnInit(): void {
    this.subscription = this.timeElapsedService.time.subscribe(item => {
      this.time = item;
    });
  }

  ngOnDestroy(): void {
    this.timeElapsedService.stopTimer();
    this.subscription.unsubscribe();
  }
}
