import { Component, Input } from '@angular/core';

@Component({
  selector: 'htc-nav-stars',
  templateUrl: './nav-stars.component.html',
  styleUrls: ['./nav-stars.component.scss'],
})
export class NavStarsComponent {
  @Input() numStars!: number; // star count value to display

  // speed (milliseconds) of the star counter animation
  @Input() starCountDuration = 0;

  constructor() {}
}
