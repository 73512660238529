<div
  class="video-container-component flex-container flex-col flex-justify-center"
  [ngClass]="{ 'video-container-popup': isPopup }"
  #container
  (contextmenu)="overrideRightClick()"
  data-testid="htc-video-container">
  <htc-video-js-player
    data-testid="htc-video-js-player"
    [ngClass]="{ 'reduced-video-size': showTranscript }"
    [showTranscript]="showTranscript"
    [showCaptions]="showCaptions"
    [autofocus]="autofocus"
    [isPopup]="isPopup"></htc-video-js-player>
  <htc-auto-scroll-transcript
    data-testid="htc-auto-scroll-transcript"
    *ngIf="showTranscript"></htc-auto-scroll-transcript>
</div>
