import { Injectable } from '@angular/core';
import { UAParser } from 'ua-parser-js';
import { FeatureFlagService } from 'src/app/modules/feature-flags/services/feature-flag/feature-flag.service';

type ScreenLegacy = Screen & {
  mozOrientation?: OrientationType;
  msOrientation?: OrientationType;
};

export const MIN_SCREEN_DIMENSIONS = [800, 600];
export const MIN_WINDOW_DIMENSIONS = [1024, 768]; // Currently not in use

export type UrlParams = { [index: string]: string };
@Injectable({
  providedIn: 'root',
})
export class CompatibilityService {
  readonly paramLocation = 'lastParams';

  readonly supportedBrowsers: { [index: string]: number } = {
    chrome: 88,
    edge: 96,
    safari: 16,
    'mobile safari': 16,
  };

  readonly experimentalWebBrowsers: { [index: string]: number } = {
    chrome: 88,
    edge: 96,
    firefox: 1,
  };

  readonly supportedOS = ['Chromium OS', 'Windows', 'Mac OS', 'Android'];

  readonly experimentalOS = [
    'Chromium OS',
    'Windows',
    'Mac OS',
    'iOS',
    'Android',
  ];

  browserName: string;
  browserVersionNumber: number;
  osName: string;

  constructor(private featureFlagService: FeatureFlagService) {
    const parser = this.getParser();
    this.browserName = (parser.getBrowser().name || '').toLowerCase();
    this.browserVersionNumber = ((v: string): number =>
      parseInt(v.split('.')[0], 10))(parser.getBrowser().version || '');
    this.osName = parser.getOS().name || '';
  }

  isLandscape(): boolean {
    const orientation =
      this.screen.msOrientation ||
      this.screen.mozOrientation ||
      this.screen.orientation.type;
    return orientation && orientation.includes('landscape');
  }

  /**
   * Checks the compatibility of the current system
   * @returns boolean
   */
  checkCompatibility(): boolean {
    return this.checkBrowser() && this.checkScreenDimensions();
  }

  /**
   * Check the browser is compatible
   * @returns boolean
   */
  checkBrowser(): boolean {
    let os = this.supportedOS;
    let browsers = this.supportedBrowsers;

    // Check if URL param IgnoreBrowser is set to true
    const { IgnoreBrowser } = this.getParamsFromStorage();
    if (IgnoreBrowser) {
      return true;
    }

    // swap to experimental if feature flag is true
    if (this.featureFlagService.isFlagEnabled('experimentalBrowser')) {
      os = this.experimentalOS;
      browsers = this.experimentalWebBrowsers;
    }

    if (
      os.includes(this.osName) &&
      Object.keys(browsers).includes(this.browserName) &&
      this.browserVersionNumber >= browsers[this.browserName]
    ) {
      return true;
    }
    return false;
  }

  /**
   * Check the dimensions of the computer screen
   * @returns boolean
   */
  checkScreenDimensions(): boolean {
    const { width: screenWidth, height: screenHeight } = this.screen;
    const [minX, minY] = !this.isLandscape()
      ? [...MIN_SCREEN_DIMENSIONS].reverse()
      : MIN_SCREEN_DIMENSIONS;

    return screenWidth > minX && screenHeight > minY;
  }

  /**
   * Get params from session storage
   * @returns UrlParams
   */
  getParamsFromStorage(): UrlParams {
    const currStorage = sessionStorage.getItem(this.paramLocation);
    const parameters = currStorage ? JSON.parse(currStorage) : {};
    return parameters;
  }

  getParser(): UAParser {
    return new UAParser();
  }

  get screen(): ScreenLegacy {
    return window.screen as ScreenLegacy;
  }
}
