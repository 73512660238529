import videojs from 'video.js';
import { DefaultSettingsMenuData } from '../../data';
import { ICustomMenuButtonOptions } from '../../interfaces';
import { CustomMenuButton } from '../custom-menu-button/custom-menu-button.component';

class SettingsMenuButton extends CustomMenuButton {
  constructor(player: videojs.Player, options: ICustomMenuButtonOptions) {
    super(player, {
      ...options,
      myItems: DefaultSettingsMenuData.SettingMenuItems,
      classNames: 'vjs-settings-menu-button',
      dataTestId: 'htc-vp-settings-menu-button',
    });

    this.controlText('Settings');
  }
}

videojs.registerComponent('settingsMenuButton', SettingsMenuButton);
export default SettingsMenuButton;
