<div
  *ngIf="showAnswer"
  class="answer"
  [@fadeInSlide]
  [ngClass]="{ wrong: !isCorrectAnswer }"
  title="{{ value }}"
  [ngStyle]="{ bottom: bottomPosition.length ? bottomPosition : '3rem' }">
  <svg-icon
    src="/assets/component-icons/dropdown-correct-polygon.svg"></svg-icon>
  <span>
    <htc-math-text-wrapper
      data-testid="htc-inline-correct-answer"
      [attr.aria-label]="
        value | htcMathToSpeech: (sres.lastAddedMapKey | async)
      "
      [textInput]="value"></htc-math-text-wrapper>
  </span>
</div>
