import { VideoData } from '../shared/interfaces';
import configJson from 'src/configurations/configuration-dev/configuration.json';

export enum TEST_VIDEO_TYPES {
  PREP,
  PREP_ES,
}

export const videos: { [key: number]: VideoData } = {
  [TEST_VIDEO_TYPES.PREP]: {
    id: 'prep',
    url:
      configJson.assetCdnDomain +
      '/helix/Reading/Grade_3/Prepositions/Episode_01/hls_streams/R_G3_Prepositions_Ep01_Video01.m3u8',
    lang: 'en',
    type: 'application/x-mpegurl',
    posterURL:
      configJson.assetCdnDomain +
      '/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png',
    captions: [
      {
        src:
          configJson.assetCdnDomain +
          '/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Video01.vtt',
        srclang: 'en',
        kind: 'captions',
        mode: 'hidden',
      },
    ],
  },
  [TEST_VIDEO_TYPES.PREP_ES]: {
    id: 'prepES',
    url:
      configJson.assetCdnDomain +
      '/helix/Lectura/Grade_3/Prepositions/Episode_01/hls_streams/L_G3_Prepositions_Ep01_Video01.m3u8',
    lang: 'es',
    type: 'application/x-mpegurl',
    posterURL:
      configJson.assetCdnDomain +
      '/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png',
    captions: [
      {
        src:
          configJson.assetCdnDomain +
          '/helix/Lectura/Grade_3/Prepositions/Episode_01/L_G3_Prepositions_Ep01_Video01.vtt',
        srclang: 'es',
        kind: 'captions',
        mode: 'hidden',
      },
    ],
  },
};
