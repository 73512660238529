import { Howl } from 'howler';
import { AudioData } from '../../../../shared/interfaces/audio-data';

export class HowlerResource implements AudioData {
  id: string;
  url?: string;

  audioLoaded = false;
  sticky = false;
  howl!: Howl;
  paused = false; //Needed because howler.playing() is false if paused, but to stop we need to have
  //                checked the url is a valid audio file, and one way to do that is if we had already
  //                called Howl.playing()

  private refCount = 0;

  constructor(ar: AudioData) {
    this.id = ar.id;
    if (ar.url) this.url = ar.url;
  }

  getRefCount(): number {
    return this.refCount;
  }

  inc(): number {
    return ++this.refCount;
  }

  dec(): number {
    if (this.refCount > 0) {
      return --this.refCount;
    }
    return 0;
  }
}
