import {
  KATEX_END_DELIM,
  KATEX_START_DELIM,
} from '../modules/math-text/utils/math-text-utils';
import { PopulationInlineChoice } from '../shared/interfaces';
import { TEST_CHOICE_TYPES } from './choice-types';
import { TestAssociatedSuccessCriteria } from './test-associated-success-criteria.enum';

export const inlineChoiceQuestions: { [key: number]: PopulationInlineChoice } =
  {
    [TEST_CHOICE_TYPES.TEST_R_IC_SHORT]: {
      text: [
        {
          text: 'This is the reading inline choice {CHOICE_TOKEN} short question?',
        },
      ],
      answerChoices: [
        {
          choice: {
            texts: [
              {
                id: '0',
                text: 'Correct',
              },
              {
                id: '1',
                text: 'Incorrect 1',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'This is incorrect 1 - first incorrect targeted feedback',
                  },
                  secondIncorrect: {
                    content:
                      'This is incorrect 1 - second incorrect targeted feedback',
                  },
                },
              },
              {
                id: '2',
                text: 'Incorrect 2',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'This is incorrect 2 - first incorrect targeted feedback',
                  },
                  secondIncorrect: {
                    content:
                      'This is incorrect 2 - second incorrect targeted feedback',
                  },
                },
              },
              {
                id: '3',
                text: 'Incorrect 3',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'This is incorrect 3 - first incorrect targeted feedback',
                  },
                  secondIncorrect: {
                    content:
                      'This is incorrect 3 - second incorrect targeted feedback',
                  },
                },
              },
            ],
          },
        },
      ],
      answers: ['0'],
      associatedSuccessCriteria: [TestAssociatedSuccessCriteria.INLINE_CHOICE],
    },
    [TEST_CHOICE_TYPES.TEST_R_IC_MEDIUM]: {
      text: [
        {
          // text: 'This is the reading inline choice medium {CHOICE_TOKEN} question? \nAdded question text for {CHOICE_TOKEN} medium length.',
          text: 'This is the reading inline choice medium {CHOICE_TOKEN} question? \nAdded question text for medium length.',
        },
      ],
      answerChoices: [
        {
          choice: {
            texts: [
              {
                id: '0',
                text: 'Correct',
              },
              {
                id: '1',
                text: 'Incorrect 1',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'This is incorrect 1 - first incorrect targeted feedback',
                  },
                  secondIncorrect: {
                    content:
                      'This is incorrect 1 - second incorrect targeted feedback',
                  },
                },
              },
              {
                id: '2',
                text: 'Incorrect 2',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'This is incorrect 2 - first incorrect targeted feedback',
                  },
                  secondIncorrect: {
                    content:
                      'This is incorrect 2 - second incorrect targeted feedback',
                  },
                },
              },
              {
                id: '3',
                text: 'Incorrect 3',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'This is incorrect 3 - first incorrect targeted feedback',
                  },
                  secondIncorrect: {
                    content:
                      'This is incorrect 3 - second incorrect targeted feedback',
                  },
                },
              },
            ],
          },
        },
        {
          choice: {
            texts: [
              {
                id: '0',
                text: 'Incorrect',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'This is incorrect 1 - first incorrect targeted feedback',
                  },
                  secondIncorrect: {
                    content:
                      'This is incorrect 1 - second incorrect targeted feedback',
                  },
                },
              },
              {
                id: '1',
                text: 'Correct',
              },
              {
                id: '2',
                text: 'Incorrect 2',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'This is incorrect 2 - first incorrect targeted feedback',
                  },
                  secondIncorrect: {
                    content:
                      'This is incorrect 2 - second incorrect targeted feedback',
                  },
                },
              },
              {
                id: '3',
                text: 'Incorrect 3',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'This is incorrect 3 - first incorrect targeted feedback',
                  },
                  secondIncorrect: {
                    content:
                      'This is incorrect 3 - second incorrect targeted feedback',
                  },
                },
              },
            ],
          },
        },
      ],
      answers: [
        '0',
        // '1'
      ],
      associatedSuccessCriteria: [TestAssociatedSuccessCriteria.INLINE_CHOICE],
    },
    [TEST_CHOICE_TYPES.TEST_R_IC_LONG]: {
      text: [
        {
          // text: 'This is the reading _inline choice long_ {CHOICE_TOKEN} _question?_ Added text to the question in order to add longer {CHOICE_TOKEN} question. More question text added here {CHOICE_TOKEN} for length.',
          text: 'This is the reading _inline choice long_ {CHOICE_TOKEN} _question?_ Added text to the question in order to add longer question. More question text added here for length.',
        },
      ],
      answerChoices: [
        {
          choice: {
            texts: [
              {
                id: '0',
                text: 'Correct',
              },
              {
                id: '1',
                text: 'Incorrect 1',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'This is incorrect 1 - first incorrect targeted feedback',
                  },
                  secondIncorrect: {
                    content:
                      'This is incorrect 1 - second incorrect targeted feedback',
                  },
                },
              },
              {
                id: '2',
                text: 'Incorrect 2',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'This is incorrect 2 - first incorrect targeted feedback',
                  },
                  secondIncorrect: {
                    content:
                      'This is incorrect 2 - second incorrect targeted feedback',
                  },
                },
              },
              {
                id: '3',
                text: 'Incorrect 3',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'This is incorrect 3 - first incorrect targeted feedback',
                  },
                  secondIncorrect: {
                    content:
                      'This is incorrect 3 - second incorrect targeted feedback',
                  },
                },
              },
            ],
          },
        },
        {
          choice: {
            texts: [
              {
                id: '0',
                text: 'Incorrect',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'This is incorrect 1 - first incorrect targeted feedback',
                  },
                  secondIncorrect: {
                    content:
                      'This is incorrect 1 - second incorrect targeted feedback',
                  },
                },
              },
              {
                id: '1',
                text: 'Correct',
              },
              {
                id: '2',
                text: 'Incorrect 2',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'This is incorrect 2 - first incorrect targeted feedback',
                  },
                  secondIncorrect: {
                    content:
                      'This is incorrect 2 - second incorrect targeted feedback',
                  },
                },
              },
              {
                id: '3',
                text: 'Incorrect 3',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'This is incorrect 3 - first incorrect targeted feedback',
                  },
                  secondIncorrect: {
                    content:
                      'This is incorrect 3 - second incorrect targeted feedback',
                  },
                },
              },
            ],
          },
        },
        {
          choice: {
            texts: [
              {
                id: '0',
                text: 'Incorrect',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'This is incorrect 1 - first incorrect targeted feedback',
                  },
                  secondIncorrect: {
                    content:
                      'This is incorrect 1 - second incorrect targeted feedback',
                  },
                },
              },
              {
                id: '1',
                text: 'Correct',
              },
              {
                id: '2',
                text: 'Incorrect 2',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'This is incorrect 2 - first incorrect targeted feedback',
                  },
                  secondIncorrect: {
                    content:
                      'This is incorrect 2 - second incorrect targeted feedback',
                  },
                },
              },
              {
                id: '3',
                text: 'Incorrect 3',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'This is incorrect 3 - first incorrect targeted feedback',
                  },
                  secondIncorrect: {
                    content:
                      'This is incorrect 3 - second incorrect targeted feedback',
                  },
                },
              },
            ],
          },
        },
      ],
      answers: [
        '0',
        // '1', '1'
      ],
      associatedSuccessCriteria: [TestAssociatedSuccessCriteria.INLINE_CHOICE],
    },
    [TEST_CHOICE_TYPES.TEST_S_IC_SHORT]: {
      text: [
        {
          text: '¿Esta es la pregunta breve sobre la opción de lectura en línea {CHOICE_TOKEN}?',
        },
      ],
      answerChoices: [
        {
          choice: {
            texts: [
              {
                id: '0',
                text: 'Correcto',
              },
              {
                id: '1',
                text: 'Incorrecto 1',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'Esto es incorrecto 1: primer comentario dirigido incorrecto',
                  },
                  secondIncorrect: {
                    content:
                      'Esto es incorrecto 1 segundo de comentario dirigido incorrecto',
                  },
                },
              },
              {
                id: '2',
                text: 'Incorrecto 2',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'Esto es incorrecto 2: primer comentario dirigido incorrecto',
                  },
                  secondIncorrect: {
                    content:
                      'Esto es incorrecto 2 segundos de comentarios dirigidos incorrectos',
                  },
                },
              },
              {
                id: '3',
                text: 'Incorrecto 3',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'Esto es incorrecto 3: primer comentario dirigido incorrecto',
                  },
                  secondIncorrect: {
                    content:
                      'Esto es incorrecto. Comentarios dirigidos incorrectos de 3 segundos.',
                  },
                },
              },
            ],
          },
        },
      ],
      answers: ['0'],
      associatedSuccessCriteria: [TestAssociatedSuccessCriteria.INLINE_CHOICE],
    },
    [TEST_CHOICE_TYPES.TEST_S_IC_MEDIUM]: {
      text: [
        {
          // text: 'This is the reading inline choice medium {CHOICE_TOKEN} question? Added question text for {CHOICE_TOKEN} medium length.',
          text: 'This is the reading inline choice medium {CHOICE_TOKEN} question? Added question text for medium length.',
        },
      ],
      answerChoices: [
        {
          choice: {
            texts: [
              {
                id: '0',
                text: 'Correcto',
              },
              {
                id: '1',
                text: 'Incorrecto 1',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'Esto es incorrecto 1: primer comentario dirigido incorrecto',
                  },
                  secondIncorrect: {
                    content:
                      'Esto es incorrecto 1 segundo de comentario dirigido incorrecto',
                  },
                },
              },
              {
                id: '2',
                text: 'Incorrecto 2',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'Esto es incorrecto 2: primer comentario dirigido incorrecto',
                  },
                  secondIncorrect: {
                    content:
                      'Esto es incorrecto 2 segundos de comentarios dirigidos incorrectos',
                  },
                },
              },
              {
                id: '3',
                text: 'Incorrecto 3',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'Esto es incorrecto 3: primer comentario dirigido incorrecto',
                  },
                  secondIncorrect: {
                    content:
                      'Esto es incorrecto. Comentarios dirigidos incorrectos de 3 segundos.',
                  },
                },
              },
            ],
          },
        },
        {
          choice: {
            texts: [
              {
                id: '0',
                text: 'Incorrect',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'Esto es incorrecto 1: primer comentario dirigido incorrecto',
                  },
                  secondIncorrect: {
                    content:
                      'Esto es incorrecto 1 segundo de comentario dirigido incorrecto',
                  },
                },
              },
              {
                id: '1',
                text: 'Correcto',
              },
              {
                id: '2',
                text: 'Incorrecto 2',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'Esto es incorrecto 2: primer comentario dirigido incorrecto',
                  },
                  secondIncorrect: {
                    content:
                      'Esto es incorrecto 2 segundos de comentarios dirigidos incorrectos',
                  },
                },
              },
              {
                id: '3',
                text: 'Incorrecto 3',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'Esto es incorrecto 3: primer comentario dirigido incorrecto',
                  },
                  secondIncorrect: {
                    content:
                      'Esto es incorrecto. Comentarios dirigidos incorrectos de 3 segundos.',
                  },
                },
              },
            ],
          },
        },
      ],
      answers: [
        '0',
        // '1'
      ],
      associatedSuccessCriteria: [TestAssociatedSuccessCriteria.INLINE_CHOICE],
    },
    [TEST_CHOICE_TYPES.TEST_S_IC_LONG]: {
      text: [
        {
          // text: 'This is the reading inline choice long {CHOICE_TOKEN} question? Added text to the question in order to add longer {CHOICE_TOKEN} question. More question text added here {CHOICE_TOKEN} for length.',
          text: 'This is the reading inline choice long {CHOICE_TOKEN} question? Added text to the question in order to add longer question. More question text added here for length.',
        },
      ],
      answerChoices: [
        {
          choice: {
            texts: [
              {
                id: '0',
                text: 'Correcto',
              },
              {
                id: '1',
                text: 'Incorrecto 1',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'Esto es incorrecto 1: primer comentario dirigido incorrecto',
                  },
                  secondIncorrect: {
                    content:
                      'Esto es incorrecto 1 segundo de comentario dirigido incorrecto',
                  },
                },
              },
              {
                id: '2',
                text: 'Incorrecto 2',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'Esto es incorrecto 2: primer comentario dirigido incorrecto',
                  },
                  secondIncorrect: {
                    content:
                      'Esto es incorrecto 2 segundos de comentarios dirigidos incorrectos',
                  },
                },
              },
              {
                id: '3',
                text: 'Incorrecto 3',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'Esto es incorrecto 3: primer comentario dirigido incorrecto',
                  },
                  secondIncorrect: {
                    content:
                      'Esto es incorrecto. Comentarios dirigidos incorrectos de 3 segundos.',
                  },
                },
              },
            ],
          },
        },
        {
          choice: {
            texts: [
              {
                id: '0',
                text: 'Incorrecto 1',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'Esto es incorrecto 1: primer comentario dirigido incorrecto',
                  },
                  secondIncorrect: {
                    content:
                      'Esto es incorrecto 1 segundo de comentario dirigido incorrecto',
                  },
                },
              },
              {
                id: '1',
                text: 'Correcto',
              },
              {
                id: '2',
                text: 'Incorrecto 2',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'Esto es incorrecto 2: primer comentario dirigido incorrecto',
                  },
                  secondIncorrect: {
                    content:
                      'Esto es incorrecto 2 segundos de comentarios dirigidos incorrectos',
                  },
                },
              },
              {
                id: '3',
                text: 'Incorrecto 3',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'Esto es incorrecto 3: primer comentario dirigido incorrecto',
                  },
                  secondIncorrect: {
                    content:
                      'Esto es incorrecto. Comentarios dirigidos incorrectos de 3 segundos.',
                  },
                },
              },
            ],
          },
        },
        {
          choice: {
            texts: [
              {
                id: '0',
                text: 'Incorrecto 1',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'Esto es incorrecto 1: primer comentario dirigido incorrecto',
                  },
                  secondIncorrect: {
                    content:
                      'Esto es incorrecto 1 segundo de comentario dirigido incorrecto',
                  },
                },
              },
              {
                id: '1',
                text: 'Correcto',
              },
              {
                id: '2',
                text: 'Incorrecto 2',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'Esto es incorrecto 2: primer comentario dirigido incorrecto',
                  },
                  secondIncorrect: {
                    content:
                      'Esto es incorrecto 2 segundos de comentarios dirigidos incorrectos',
                  },
                },
              },
              {
                id: '3',
                text: 'Incorrecto 3',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'Esto es incorrecto 3: primer comentario dirigido incorrecto',
                  },
                  secondIncorrect: {
                    content:
                      'Esto es incorrecto. Comentarios dirigidos incorrectos de 3 segundos.',
                  },
                },
              },
            ],
          },
        },
      ],
      answers: [
        '0',
        //  '1', '1'
      ],
      associatedSuccessCriteria: [TestAssociatedSuccessCriteria.INLINE_CHOICE],
    },
    [TEST_CHOICE_TYPES.TEST_R_IC_KATEX]: {
      text: [
        {
          text: `Start of a cool equation here: ${KATEX_START_DELIM}\\begin{aligned}124.578 \\\\ \\underbar{+ 12.320}\\end{aligned}${KATEX_END_DELIM}\n {CHOICE_TOKEN} ${KATEX_START_DELIM}\\frac{a}{b}${KATEX_END_DELIM}?\n ${KATEX_START_DELIM}\\begin{aligned}33 \\\\ \\underbar{* 18}\\end{aligned}${KATEX_END_DELIM}`,
        },
      ],
      answerChoices: [
        {
          choice: {
            texts: [
              {
                id: '0',
                text: `Correct ${KATEX_START_DELIM}\frac{a}{b}${KATEX_END_DELIM}`,
              },
              {
                id: '1',
                text: `Incorrect 1 ${KATEX_START_DELIM}\frac{a}{b}${KATEX_END_DELIM}`,
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'This is incorrect 1 - first incorrect targeted feedback',
                  },
                  secondIncorrect: {
                    content:
                      'This is incorrect 1 - second incorrect targeted feedback',
                  },
                },
              },
              {
                id: '2',
                text: 'Incorrect 2',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'This is incorrect 2 - first incorrect targeted feedback',
                  },
                  secondIncorrect: {
                    content:
                      'This is incorrect 2 - second incorrect targeted feedback',
                  },
                },
              },
              {
                id: '3',
                text: 'Incorrect 3',
                targetedFeedback: {
                  firstIncorrect: {
                    content:
                      'This is incorrect 3 - first incorrect targeted feedback',
                  },
                  secondIncorrect: {
                    content:
                      'This is incorrect 3 - second incorrect targeted feedback',
                  },
                },
              },
            ],
          },
        },
      ],
      answers: ['0'],
      associatedSuccessCriteria: [TestAssociatedSuccessCriteria.INLINE_CHOICE],
    },
  };
