import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { MessageCodes } from 'src/app/shared/enums';
import { MessageService } from '../message/services/message/message.service';

@Injectable()
export class HTTPInterceptorService implements HttpInterceptor {
  constructor(private messageService: MessageService) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status !== 404) {
          if (Object.values(MessageCodes).includes(error.status)) {
            this.messageService.showMessage(error.status);
          } else {
            this.messageService.showMessage(MessageCodes.APP_UNKNOWN_ERROR);
          }
        }

        return throwError(() => error);
      })
    );
  }
}
