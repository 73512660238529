import { Injectable } from '@angular/core';
import { ConfigurationService } from 'src/app/modules/configuration/services/configuration/configuration.service';
import { LanguageCodes } from 'src/app/shared/enums';

/**
 * Generic Service for interacting with the Speech Synthesis API
 */
@Injectable({ providedIn: 'root' })
export class SpeechSynthesisService {
  initialized = false;
  speechSpeed = 0.75;
  speechPitch = 1;
  volume = 10;
  preferredVoice?: SpeechSynthesisVoice;
  currentUtterance?: SpeechSynthesisUtterance;

  private _lang = 'en-US';
  get lang(): string {
    return this._lang;
  }
  set lang(value: string) {
    this._lang = value;
    this.determinePreferredVoice();
  }

  constructor(private configService: ConfigurationService) {
    if (speechSynthesis.onvoiceschanged !== undefined) {
      speechSynthesis.onvoiceschanged = (): void => {
        this.determinePreferredVoice();
      };
    }

    //stop speaking if we reload the page
    window.addEventListener('beforeunload', function (): void {
      speechSynthesis.cancel();
    });
  }

  /**
   * Determine if the Speech Synthesis API is supported by the user's browser
   * @returns true if the Speech Synthesis API is supported, false if it is not
   */
  isSpeechSynthesisSupported(): boolean {
    return 'speechSynthesis' in window;
  }

  /**
   * Speaks the given text and will call the callback functions if they are provided
   * The Speech Synthesis API is limited to one 'utterance' at a time, they're queued up and play in order of being added
   * @param text The text to be spoken, this can utilize SSML https://cloud.google.com/text-to-speech/docs/ssml
   * @param options Object containing callbacks for various events
   */
  speak(
    text: string,
    options?: {
      wordBoundryCallback?: (event: SpeechSynthesisEvent) => void;
      onStartCallback?: (event: SpeechSynthesisEvent) => void;
      onEndCallback?: (event: SpeechSynthesisEvent) => void;
      onErrorCallback?: (event: SpeechSynthesisErrorEvent) => void;
      onPauseCallback?: (event: SpeechSynthesisEvent) => void;
      onResumeCallback?: (event: SpeechSynthesisEvent) => void;
    }
  ): void {
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.rate = this.speechSpeed;
    utterance.pitch = this.speechPitch;
    utterance.volume = this.volume;
    utterance.lang = this.lang;
    utterance.voice = this.preferredVoice ?? null;

    if (options) {
      if (options.onErrorCallback) utterance.onerror = options.onErrorCallback;
      if (options.onStartCallback) utterance.onstart = options.onStartCallback;
      if (options.onPauseCallback) utterance.onpause = options.onPauseCallback;
      if (options.onResumeCallback)
        utterance.onresume = options.onResumeCallback;
      if (options.onEndCallback) utterance.onend = options.onEndCallback;
      if (options.wordBoundryCallback)
        utterance.onboundary = options.wordBoundryCallback;
    }
    this.currentUtterance = utterance;
    speechSynthesis.speak(utterance);
  }

  /**
   * Puts the Speech Synthesis API into a paused state
   * This will pause the current utterance being spoken,
   * or if the queue is empty, it will prevent any utterances from being spoken until resume() is called
   */
  pause(): void {
    speechSynthesis.pause();
  }

  /**
   * Puts the Speech Synthesis API into a non-paused state
   * If an utterance was being spoken when put into a paused state, it will resume from where it was paused
   */
  resume(): void {
    speechSynthesis.resume();
  }

  /**
   * Stops the utterance being spoken, and clears the utterance queue
   */
  stop(): void {
    speechSynthesis.cancel();
  }

  /**
   * Determines if the Speech Synthesis API is currently paused
   * @returns true if the Speech Synthesis API is currently paused, false if it is not
   */
  isPaused(): boolean {
    return speechSynthesis.paused;
  }

  /**
   * Determines if the Speech Synthesis API is currently speaking
   * @returns true if the Speech Synthesis API is currently speaking, false if it is not
   */
  isSpeaking(): boolean {
    return speechSynthesis.speaking;
  }

  /**
   * Go through the list of voices in configuration and find the highest priority voice that is available
   * If no voice is found, the default voice will be used (just not setting the voice)
   */
  private determinePreferredVoice(): void {
    let preferredVoices: string[] = [];
    if (this._lang === LanguageCodes.ENGLISH_EXTENDED) {
      this.speechSpeed = 0.9;
      preferredVoices = this.configService.config.preferredVoicesEn;
    } else if (this._lang === LanguageCodes.SPANISH_EXTENDED) {
      this.speechSpeed = 0.8;
      preferredVoices = this.configService.config.preferredVoicesEs;
    } else {
      return;
    }

    const voices = speechSynthesis.getVoices();
    for (let i = 0; i < preferredVoices.length; i++) {
      const foundVoice = voices.find(
        voice => voice.name === preferredVoices[i]
      );
      if (foundVoice) {
        this.preferredVoice = foundVoice;
        break;
      }
    }
  }
}
