import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';
import { MessageService } from 'src/app/modules/message/services/message/message.service';
import { TriggerActionTypes } from 'src/app/shared/enums';

@Injectable({
  providedIn: 'root',
})
export class InactivityService {
  readonly inactivityWarning = 900000; // 15 minutes
  readonly heartbeatFrequency = 30000; // 30 seconds
  readonly cooldownFrequency = 10000; // 10 seconds

  inactivityEvent$ = new Subject<number>();

  heartbeatInterval!: number;
  reportInteraction = false;
  idleWarned = false;
  activeStart = Date.now();
  cooldownActive = true;
  isPaused = false;

  messageIdleTimer = 0;
  activityIdleTimer = 0;

  recordInteractionFunc = this.recordInteraction.bind(this);

  constructor(
    private messageService: MessageService,
    private ngZone: NgZone
  ) {}

  getCurrentInactiveTime(): number {
    if (!this.cooldownActive) {
      this.incrementTotalIdleTime(Date.now() - this.activeStart);
    }
    return this.activityIdleTimer;
  }

  /**
   * Instatiate timers and start cooldown timer
   */
  startInactivityTracking(): void {
    this.messageIdleTimer = 0;
    this.activityIdleTimer = 0;
    this.activeStart = Date.now();
    this.heartbeatInterval = window.setInterval(() => {
      this.cooldown();
    }, this.cooldownFrequency);
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('click', this.recordInteractionFunc);
      window.addEventListener('keypress', this.recordInteractionFunc);
    });
  }

  /**
   * Pause cooldown/heartbeat sequence, add accumulated idle time
   */
  pauseInactivityTracking(): void {
    console.log('Pause inactivity triggered');
    window.clearInterval(this.heartbeatInterval);
    if (!this.cooldownActive) {
      this.incrementTotalIdleTime(Date.now() - this.activeStart);
      this.cooldownActive = true;
    }
    this.isPaused = true;
  }

  /**
   * Resume idle time tracking by reactivating cooldown sequence
   */
  resumeInactivityTracking(): void {
    console.log('Resume inactivity triggered');
    this.activeStart = Date.now();
    this.cooldownActive = true;
    this.isPaused = false;
    this.ngZone.runOutsideAngular(() => {
      this.heartbeatInterval = window.setInterval(() => {
        this.cooldown();
      }, this.cooldownFrequency);
    });
  }

  /**
   * Stop timers and record final total inactivity time
   */
  endInactivityTracking(): void {
    this.idleWarned = false;
    window.clearInterval(this.heartbeatInterval);
    window.removeEventListener('click', this.recordInteractionFunc);
    window.removeEventListener('keypress', this.recordInteractionFunc);
    if (!this.cooldownActive) {
      this.incrementTotalIdleTime(Date.now() - this.activeStart);
    }
    console.log('Final total inactivity time (ms): ' + this.activityIdleTimer);
  }

  /**
   * Interaction catch function; currently works on click events
   */
  recordInteraction(): void {
    if (this.isPaused) {
      console.log('Currently paused, ignoring interaction');
      return;
    }

    console.log('Click event recorded');
    this.reportInteraction = true;

    const deltaTime = Date.now() - this.activeStart;

    // Increment idle time if not in cooldown period
    if (!this.cooldownActive) {
      this.incrementTotalIdleTime(deltaTime);
      this.inactivityEvent$.next(deltaTime);
    }

    if (this.idleWarned) {
      this.messageService.sendTrigger(TriggerActionTypes.CANCEL);
      this.idleWarned = false;
    }

    // Stop heartbeat routine and perform cooldown routine
    window.clearInterval(this.heartbeatInterval);
    this.cooldownActive = true;
    this.ngZone.runOutsideAngular(() => {
      this.heartbeatInterval = window.setInterval(() => {
        this.cooldown();
      }, this.cooldownFrequency);
    });
  }

  /**
   * Once called, end cooldown period and start heartbeat periodic checks
   */
  cooldown(): void {
    if (this.isPaused) {
      console.log('Currently paused, repeat cooldown');
      return;
    }
    console.log('Cooldown completed, resuming heartbeat');
    window.clearInterval(this.heartbeatInterval);
    this.cooldownActive = false;
    this.resetInactivityTimer();
    this.ngZone.runOutsideAngular(() => {
      this.heartbeatInterval = window.setInterval(() => {
        this.heartbeat();
      }, this.heartbeatFrequency);
    });
  }

  /**
   * Periodic inactivity check, show inactivity message box at inactivityWarning time
   */
  heartbeat(): void {
    console.log('Heartbeat function called, increment timer');
    const deltaTime = Date.now() - this.activeStart;
    this.messageIdleTimer += deltaTime;
    this.incrementTotalIdleTime(deltaTime);
    console.log('Inactive timer value: ' + this.messageIdleTimer);
    if (this.messageIdleTimer >= this.inactivityWarning && !this.idleWarned) {
      this.messageIdleTimer = this.inactivityWarning;
      this.messageService.showMessage(1003);
      this.idleWarned = true;
      console.log('Inactivity window brought up');
    }
  }

  /**
   * Reset inactivity timer and associated variables
   */
  resetInactivityTimer(): void {
    this.reportInteraction = false;
    this.messageIdleTimer = 0;
    this.activeStart = Date.now();
    this.idleWarned = false;
  }

  incrementTotalIdleTime(deltaTime: number): void {
    this.activityIdleTimer += deltaTime;
    this.activeStart = Date.now();
    console.log(
      'Current total inactivity time (ms): ' + this.activityIdleTimer
    );
  }

  getActivityTotalIdleTime(): number {
    return this.activityIdleTimer;
  }
}
