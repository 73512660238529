import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ActivityValidGuard } from './shared/guards/activityValidGuard';
import { OptionalLoginGuard } from './shared/guards/optionalLoginGuard';
import { FeatureFlagGuard } from './modules/feature-flags/guards/feature-flag/feature-flag.guard';
import { StopSpeechSynthesisGuard } from './shared/guards/stop-speech-synthesis/stop-speech-synthesis.guard';
import { EarlyAccessGuard } from './shared/guards/early-access.guard';
import {
  ComponentTestFeatureFlagsRoute,
  EarlyAccessFeatureFlagRoute,
  SandboxFeatureFlagsRoute,
} from './data/app-routing-feature-flags';
import { ArbitraryLaunchGuard } from './shared/guards/arbitrary-launch.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/pages/home/home.module').then(m => m.HomePageModule),
    canActivate: [EarlyAccessGuard, OptionalLoginGuard],
  },
  {
    path: 'demo-login',
    loadChildren: () =>
      import('./modules/early-access/pages/demo-login/demo-login.module').then(
        m => m.DemoLoginModule
      ),
    data: EarlyAccessFeatureFlagRoute,
    canActivate: [FeatureFlagGuard],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'start',
    loadChildren: () =>
      import('./modules/pages/start/start.module').then(m => m.StartPageModule),
    canActivate: [ActivityValidGuard],
    canDeactivate: [StopSpeechSynthesisGuard],
  },
  {
    path: 'self-rating',
    loadChildren: () =>
      import('./modules/pages/self-rating/self-rating.module').then(
        m => m.SelfRatingPageModule
      ),
    canActivate: [ActivityValidGuard],
  },
  {
    path: 'video',
    loadChildren: () =>
      import('./modules/pages/video/video.module').then(m => m.VideoPageModule),
    canActivate: [ActivityValidGuard],
  },
  {
    path: 'tei',
    loadChildren: () =>
      import('./modules/pages/tei/tei.module').then(m => m.TeiPageModule),
    canActivate: [ActivityValidGuard],
  },
  {
    path: 'results',
    loadChildren: () =>
      import('./modules/pages/results/results-page.module').then(
        m => m.ResultsPageModule
      ),
    canActivate: [ActivityValidGuard],
  },
  {
    path: 'survey',
    loadChildren: () =>
      import('./modules/pages/survey/survey-page.module').then(
        m => m.SurveyPageModule
      ),
    canActivate: [ActivityValidGuard],
  },
  {
    path: 'activity',
    loadChildren: () =>
      import('./modules/pages/activity/activity.module').then(
        m => m.ActivityModule
      ),
    canActivate: [EarlyAccessGuard, OptionalLoginGuard, ArbitraryLaunchGuard],
  },
  {
    path: 'assignment',
    loadChildren: () =>
      import('./modules/pages/activity/assignment.module').then(
        m => m.AssignmentModule
      ),
    canActivate: [EarlyAccessGuard, OptionalLoginGuard, ArbitraryLaunchGuard],
  },
  {
    path: 'component-test',
    loadChildren: () =>
      import('./modules/component-test/component-test.module').then(
        m => m.ComponentTestModule
      ),
    data: ComponentTestFeatureFlagsRoute,
    canActivate: [OptionalLoginGuard, FeatureFlagGuard],
  },
  {
    path: 'sandbox',
    loadChildren: () =>
      import('./modules/pages/sandbox/sandbox-page.module').then(
        m => m.SandboxPageModule
      ),
    data: SandboxFeatureFlagsRoute,
    canActivate: [EarlyAccessGuard, OptionalLoginGuard, FeatureFlagGuard],
  },
  {
    path: '**',
    loadChildren: () =>
      import('./modules/pages/page-not-found/page-not-found.module').then(
        m => m.PageNotFoundModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
