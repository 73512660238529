import { ActivityObjectTypes } from 'src/app/shared/enums/activity-object-types';
import { Activity } from 'src/app/shared/interfaces';
import { constActivityManifestEnd } from './activity-manifest-end';
import { TEST_POPULATION_TYPES } from '../population-types';
import { populationsReadingMC } from '../populations';
import { constMultipleVideosParams } from './multiple-videos-params';
import { TEST_VIDEO_TYPES, videos } from '../videos';
import { ActivityManifestObject } from 'src/app/shared/interfaces/activity-manifest-object';

const getManifest = (): ActivityManifestObject[] => {
  const manifest = [
    {
      type: ActivityObjectTypes.VIDEO,
      id: videos[TEST_VIDEO_TYPES.PREP].id,
    },
    {
      type: ActivityObjectTypes.VIDEO,
      id: videos[TEST_VIDEO_TYPES.PREP].id,
    },
    {
      type: ActivityObjectTypes.QUESTION,
      id: `${TEST_POPULATION_TYPES.TEST_R_MC_SHORT}`,
    },
    {
      type: ActivityObjectTypes.QUESTION,
      id: `${TEST_POPULATION_TYPES.TEST_R_MC_MEDIUM}`,
    },
    {
      type: ActivityObjectTypes.QUESTION,
      id: `${TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_TRUE_FALSE}`,
    },
  ].sort(() => Math.random() - 0.5);

  return [
    {
      type: ActivityObjectTypes.START_SCREEN,
    },
    {
      type: ActivityObjectTypes.ENTRY_SURVEY,
    },
    ...manifest,
    ...constActivityManifestEnd,
  ];
};

const manifest = [...getManifest()];

export const testRandomVideoActivities: { [key: string]: Activity } = {
  'TEST.R.V.R': {
    id: `TEST.R.V.R`,
    ...constMultipleVideosParams,
    seriesName: `Testing Videos`,
    episodeNumber: '1',
    episodeTitle: "Random Multiple Videos (resume won't work)",
    description: 'MC_SHORT',
    startScreenData: constMultipleVideosParams.startScreenData,
    populations: [
      ...manifest
        .filter(m => m.type === ActivityObjectTypes.QUESTION)
        .map(m => populationsReadingMC[Number(m.id)]),
    ],
    activityManifest: [...manifest],
  },
};
