import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToolItem } from '../classes/tool-item';

@Injectable({
  providedIn: 'root',
})
export class ToolsService {
  private tools: ToolItem[] = [];
  private activatedIds = new Array<number>();
  private activatedToolIds$ = new BehaviorSubject<number[]>([]);
  animOutEvent = new EventEmitter<void>();
  iconMap = new Map<number, string>();

  constructor() {}

  getTools(): ToolItem[] {
    return this.tools;
  }

  getActivatedTools(): Observable<number[]> {
    return this.activatedToolIds$.asObservable();
  }

  getToolsSize(): number {
    return this.tools.length;
  }

  addTool(toolItem: ToolItem): void {
    if (this.doesToolExist(toolItem)) {
      return;
    }
    this.tools.push(toolItem);
    this.iconMap.set(toolItem.data.id, toolItem.data.icon);
  }

  addToolAt(toolItem: ToolItem, position: number): void {
    if (
      position < 0 ||
      position > this.getToolsSize() ||
      this.doesToolExist(toolItem)
    ) {
      return;
    }
    this.tools.splice(position, 0, toolItem);
  }

  removeTool(position: number): void {
    this.tools.splice(position, 1);
  }

  removeToolById(id: number): void {
    const matchedTool = this.tools.find(tool => tool.data.id === id);
    if (matchedTool) {
      const index = this.tools.indexOf(matchedTool);
      this.tools.splice(index, 1);
    }
  }

  clearTools(): void {
    this.tools = [];
  }

  activateTool(id: number): void {
    // push a tool only once
    if (this.doesToolExistById(id) && this.activatedIds.indexOf(id) === -1) {
      this.activatedIds = [id];
      this.activatedToolIds$.next(this.activatedIds);
    }
  }

  deactivateTool(id: number): void {
    const idx = this.activatedIds.indexOf(id);
    // if tool is found then remove it
    if (idx !== -1) {
      this.activatedIds.splice(idx, 1);
      this.activatedToolIds$.next(this.activatedIds);
    }
  }

  deactivateAllTools(): void {
    this.tools.forEach(tool => {
      this.deactivateTool(tool.data.id);
    });
  }

  doesToolExistById(id: number): boolean {
    return this.tools.find(tool => tool.data.id === id) !== undefined;
  }

  doesToolExist(toolItem: ToolItem): boolean {
    return (
      this.tools.find(tool => tool.data.id === toolItem.data.id) !== undefined
    );
  }

  animOut(): void {
    this.animOutEvent.emit();
  }

  setIcon(toolItem: ToolItem, icon: string): void {
    this.iconMap.set(toolItem.data.id, icon);
  }
}
