<main
  [@.disabled]="!useAnimation"
  [ngClass]="{ 'prefers-reduced-motion': !useAnimation }">
  <div class="stack">
    <htc-header
      data-testid="htc-header"
      *ngIf="(activityService.showHeader | async) ?? false"
      [exitButtonText]="exitButtonText | translate"
      [exitButtonIcon]="exitButtonIcon"
      [showExitButton]="(activityService.showExitButton | async) ?? false"
      [showRewards]="
        (activityService.showRewards | async) ?? false
      "></htc-header>
    <div
      #htcPageContentContainer
      data-testid="htc-main-content-container"
      class="content-container reset-scrollbar {{
        activityService.activityState === ActivityStateEnum.QUESTION ||
        activityService.activityState === ActivityStateEnum.VIDEO
          ? pageTransitionService.pageTransition +
            ' ' +
            'content-container-with-bg'
          : pageTransitionService.pageTransition + ' '
      }}"
      [ngClass]="{
        'hide-scrollbar': scrollbarService.isScrollbarHidden$ | async
      }">
      <div
        class="content-absolute-bg"
        *ngIf="(activityService.showSecondaryHeader | async) ?? false"></div>
      <htc-subheader
        data-testid="htc-subheader"
        *ngIf="(activityService.showSecondaryHeader | async) ?? false"
        [episode]="(activityService.episodeNumber | async) ?? '0'"
        [showProgressBar]="(activityService.showProgressBar | async) ?? false"
        [showElapsedTime]="(activityService.showElapsedTime | async) ?? false"
        [progressBarEnabled]="authSessionService.isTeacher"
        (clickedEvent)="onProgressNodeClick($event)"></htc-subheader>
      <div>
        <router-outlet></router-outlet>
      </div>
    </div>
    <htc-tools *ngIf="footerService.show"></htc-tools>
    <htc-footer *ngIf="footerService.show"></htc-footer>
    <htc-chart-modal
      *ngIf="chartOpened"
      [chart]="
        (chartService.getChart() | async) ?? { data: '', isUrl: false }
      "></htc-chart-modal>
  </div>
  <htc-message-popup></htc-message-popup>
  <htc-debug-menu></htc-debug-menu>
  <htc-feedback
    *ngIf="!platformSupported"
    id="feedbackPlatformSupport"
    [showAlert]="!platformSupported"
    alertClasses="feedback incorrect platform-warning"
    alertHeaderText="Warning!"
    feedbackText="This platform is not currently supported. You can still complete lessons, but some functionality may be missing."
    [showDiamond]="false"
    alertType="default"
    [incorrectAttempts]="0"
    data-testid="htc-platform-not-supported-feedback"></htc-feedback>
  @if (loadingScreenService.loadingScreenState() > 0) {
    <htc-loading></htc-loading>
  }
</main>
