import { Component, Input, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';

@Component({
  selector: 'htc-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  tableData: string[] = [];
  indexTracker = 0;
  currentMaxLength = 0;
  @Input() dataSrc!: string;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.http
      .get(this.dataSrc, { responseType: 'text' })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          console.log(err.message);
          return throwError(() => new Error(err.message));
        })
      )
      .subscribe(data => {
        const list = data.split('\n');
        list.forEach(e => {
          this.tableData.push(e);
        });
      });

    this.indexTracker = 0;
  }

  getDataRowArray(data: string): string[] {
    return data.split(',');
  }

  getNextDataInfo(data: string): string {
    return data.split(',')[this.getDataSectionIndex(data)];
  }

  getDataSectionIndex(data: string): number {
    const returnIndex = this.indexTracker;
    this.currentMaxLength = data.split(',').length;

    this.indexTracker++;
    if (this.indexTracker >= this.currentMaxLength) {
      this.indexTracker = 0;
    }

    return returnIndex;
  }
}
