import {
  KATEX_END_DELIM,
  KATEX_START_DELIM,
} from '../modules/math-text/utils/math-text-utils';
import { PopulationInlineChoice } from '../shared/interfaces';
import { TEST_CHOICE_TYPES } from './choice-types';
import { TestAssociatedSuccessCriteria } from './test-associated-success-criteria.enum';

export const fillInTheBlankTileText: { [key: string]: PopulationInlineChoice } =
  {
    [TEST_CHOICE_TYPES.TEST_R_DND_BLANK_SHORT]: {
      text: [
        {
          text: '{DND_TOKEN} & {DND_TOKEN}.',
        },
      ],
      answerChoices: [
        {
          id: 'correct_1',
          choice: {
            texts: [
              {
                text: 'C1',
              },
            ],
          },
        },
        {
          id: 'correct_2',
          choice: {
            texts: [
              {
                text: 'C2',
              },
            ],
          },
        },
        {
          id: 'incorrect_1',
          choice: {
            texts: [
              {
                text: 'W1',
              },
            ],
          },
        },
        {
          id: 'incorrect_2',
          choice: {
            texts: [
              {
                text: 'W2',
              },
            ],
          },
        },
        {
          id: 'incorrect_3',
          choice: {
            texts: [
              {
                text: 'W3',
              },
            ],
          },
        },
      ],
      answers: ['correct_1', 'correct_2'],
      associatedSuccessCriteria: [TestAssociatedSuccessCriteria.DRAG_AND_DROP],
    },
    [TEST_CHOICE_TYPES.TEST_R_DND_BLANK_LONG]: {
      text: [
        {
          text: 'Long {DND_TOKEN} DnD. {DND_TOKEN} {DND_TOKEN}.',
        },
      ],
      answerChoices: [
        {
          id: 'correct_1',
          choice: {
            texts: [
              {
                text: 'C1',
              },
            ],
          },
        },
        {
          id: 'correct_2',
          choice: {
            texts: [
              {
                text: 'C2',
              },
            ],
          },
        },
        {
          id: 'correct_3',
          choice: {
            texts: [
              {
                text: 'C3',
              },
            ],
          },
        },
        {
          id: 'incorrect_1',
          choice: {
            texts: [
              {
                text: 'W1',
              },
            ],
          },
        },
        {
          id: 'incorrect_2',
          choice: {
            texts: [
              {
                text: 'W2',
              },
            ],
          },
        },
      ],
      answers: ['correct_1', 'correct_2', 'correct_3'],
      associatedSuccessCriteria: [TestAssociatedSuccessCriteria.DRAG_AND_DROP],
    },
    test1: {
      text: [
        {
          text: '12 {DND_TOKEN} {DND_TOKEN}1 = 132',
        },
      ],
      answerChoices: [
        {
          id: 'abc123',
          choice: {
            texts: [
              {
                text: '+',
              },
            ],
          },
        },
        {
          id: 'abc456',
          choice: {
            texts: [
              {
                text: '÷',
              },
            ],
          },
        },
        {
          id: 'abc789',
          choice: {
            texts: [
              {
                text: '×',
              },
            ],
          },
        },
        {
          id: 'abc10',
          choice: {
            texts: [
              {
                text: '2',
              },
            ],
          },
        },
        {
          id: 'abc11',
          choice: {
            texts: [
              {
                text: '1',
              },
            ],
          },
        },
      ],
      answers: ['abc789', 'abc11'],
      associatedSuccessCriteria: [TestAssociatedSuccessCriteria.DRAG_AND_DROP],
    },

    test2: {
      text: [
        {
          text: '568 {DND_TOKEN} 492',
        },
      ],
      answerChoices: [
        {
          id: 'abc123',
          choice: {
            texts: [
              {
                text: '<',
              },
            ],
          },
        },
        {
          id: 'abc456',
          choice: {
            texts: [
              {
                text: '=',
              },
            ],
          },
        },
        {
          id: 'abc789',
          choice: {
            texts: [
              {
                text: '>',
              },
            ],
          },
        },
      ],
      answers: ['abc789'],
      associatedSuccessCriteria: [TestAssociatedSuccessCriteria.DRAG_AND_DROP],
    },

    test3: {
      text: [
        {
          text: '12 {DND_TOKEN} 11 = 132',
        },
      ],
      answerChoices: [
        {
          id: 'abc123',
          choice: {
            texts: [
              {
                text: '+',
              },
            ],
          },
        },
        {
          id: 'abc456',
          choice: {
            texts: [
              {
                text: '÷',
              },
            ],
          },
        },
        {
          id: 'abc789',
          choice: {
            texts: [
              {
                text: '×',
              },
            ],
          },
        },
      ],
      answers: ['abc789'],
      associatedSuccessCriteria: [TestAssociatedSuccessCriteria.DRAG_AND_DROP],
    },

    test4: {
      text: [
        {
          text: 'ach {DND_TOKEN} {DND_TOKEN} ve',
        },
      ],
      answerChoices: [
        {
          id: 'abc123',
          choice: {
            texts: [
              {
                text: 'a',
              },
            ],
          },
        },
        {
          id: 'abc456',
          choice: {
            texts: [
              {
                text: 'e',
              },
            ],
          },
        },
        {
          id: 'abc789',
          choice: {
            texts: [
              {
                text: 'i',
              },
            ],
          },
        },
      ],
      answers: ['abc789', 'abc456'],
      associatedSuccessCriteria: [TestAssociatedSuccessCriteria.DRAG_AND_DROP],
    },

    test5: {
      text: [
        {
          text: '2, 4, 6, 8, {DND_TOKEN}, 12, 14, 16',
        },
      ],
      answerChoices: [
        {
          id: 'abc123',
          choice: {
            texts: [
              {
                text: '11',
              },
            ],
          },
        },
        {
          id: 'abc456',
          choice: {
            texts: [
              {
                text: '9',
              },
            ],
          },
        },
        {
          id: 'abc789',
          choice: {
            texts: [
              {
                text: '10',
              },
            ],
          },
        },
        {
          id: 'abc10',
          choice: {
            texts: [
              {
                text: '8.5',
              },
            ],
          },
        },
      ],
      answers: ['abc789'],
      associatedSuccessCriteria: [TestAssociatedSuccessCriteria.DRAG_AND_DROP],
    },
    [TEST_CHOICE_TYPES.TEST_R_DND_BLANK_KATEX]: {
      text: [
        {
          text: `{DND_TOKEN} ${KATEX_START_DELIM}\frac{a}{b}${KATEX_END_DELIM} {DND_TOKEN}.`,
        },
      ],
      answerChoices: [
        {
          id: 'correct_1',
          choice: {
            texts: [
              {
                text: `C1 ${KATEX_START_DELIM}\frac{a}{b}${KATEX_END_DELIM}`,
              },
            ],
          },
        },
        {
          id: 'correct_2',
          choice: {
            texts: [
              {
                text: 'C2',
              },
            ],
          },
        },
        {
          id: 'incorrect_1',
          choice: {
            texts: [
              {
                text: 'W1',
              },
            ],
          },
        },
        {
          id: 'incorrect_2',
          choice: {
            texts: [
              {
                text: 'W2',
              },
            ],
          },
        },
        {
          id: 'incorrect_3',
          choice: {
            texts: [
              {
                text: 'W3',
              },
            ],
          },
        },
      ],
      answers: ['correct_1', 'correct_2'],
      associatedSuccessCriteria: [TestAssociatedSuccessCriteria.DRAG_AND_DROP],
    },
  };
