import { extendedConstructedResponseOptions } from '../modules/tei/data/constructed-response-options';
import { PopulationConstructedResponse } from '../shared/interfaces/population-constructed-response';
import { TEST_CHOICE_TYPES } from './choice-types';
import { charts, CHART_TYPES } from './charts';
import { TestAssociatedSuccessCriteria } from './test-associated-success-criteria.enum';

export const extendedConstructedResponseQuestions: {
  [key: number]: PopulationConstructedResponse;
} = {
  [TEST_CHOICE_TYPES.TEST_R_ECR_SHORT]: {
    text: {
      text: 'This is the reading extended constructed response short question?',
    },
    associatedSuccessCriteria: [
      TestAssociatedSuccessCriteria.EXTENDED_CONST_RESPONSE,
    ],
    options: extendedConstructedResponseOptions,
  },
  [TEST_CHOICE_TYPES.TEST_R_ECR_MEDIUM]: {
    text: {
      text: 'This is the reading extended constructed response medium question? Added question text for medium length.',
    },
    associatedSuccessCriteria: [
      TestAssociatedSuccessCriteria.EXTENDED_CONST_RESPONSE,
    ],
    options: extendedConstructedResponseOptions,
  },
  [TEST_CHOICE_TYPES.TEST_R_ECR_LONG]: {
    text: {
      text: 'This is the reading extended constructed response long question? Added text to the question in order to add longer question. More question text added here for length.',
    },
    associatedSuccessCriteria: [
      TestAssociatedSuccessCriteria.EXTENDED_CONST_RESPONSE,
    ],
    options: extendedConstructedResponseOptions,
  },
  [TEST_CHOICE_TYPES.TEST_R_ECRR_SHORT]: {
    text: {
      text: 'This is the reading extended constructed response with rubric short question?',
    },
    chart: charts[CHART_TYPES.SAMPLE_WITH_TITLE],
    associatedSuccessCriteria: [
      TestAssociatedSuccessCriteria.EXTENDED_CONST_RESPONSE_RUBRIC,
    ],
    options: extendedConstructedResponseOptions,
  },
  [TEST_CHOICE_TYPES.TEST_R_ECRR_MEDIUM]: {
    text: {
      text: 'This is the reading extended constructed response with rubric medium question? Added question text for medium length.',
    },
    chart: charts[CHART_TYPES.SAMPLE_WITH_TITLE],
    associatedSuccessCriteria: [
      TestAssociatedSuccessCriteria.EXTENDED_CONST_RESPONSE_RUBRIC,
    ],
    options: extendedConstructedResponseOptions,
  },
  [TEST_CHOICE_TYPES.TEST_R_ECRR_LONG]: {
    text: {
      text: 'This is the reading extended constructed response with rubric long question? Added text to the question in order to add longer question. More question text added here for length.',
    },
    chart: charts[CHART_TYPES.SAMPLE_WITHOUT_TITLE],
    associatedSuccessCriteria: [
      TestAssociatedSuccessCriteria.EXTENDED_CONST_RESPONSE_RUBRIC,
    ],
    options: extendedConstructedResponseOptions,
  },
  [TEST_CHOICE_TYPES.TEST_S_ECR_SHORT]: {
    text: {
      text: '¿Esta es la pregunta corta de respuesta construida de la actividad?',
    },
    associatedSuccessCriteria: [
      TestAssociatedSuccessCriteria.EXTENDED_CONST_RESPONSE,
    ],
    options: extendedConstructedResponseOptions,
  },
  [TEST_CHOICE_TYPES.TEST_S_ECR_MEDIUM]: {
    text: {
      text: '¿Esta es la pregunta media de respuesta construida de actividad? Se agregó texto de pregunta para longitud media.',
    },
    associatedSuccessCriteria: [
      TestAssociatedSuccessCriteria.EXTENDED_CONST_RESPONSE,
    ],
    options: extendedConstructedResponseOptions,
  },
  [TEST_CHOICE_TYPES.TEST_S_ECR_LONG]: {
    text: {
      text: '¿Esta es la pregunta larga de respuesta construida de la actividad? Se agregó texto a la pregunta para agregar una pregunta más larga. Se agregó más texto de pregunta aquí para mayor extensión.',
    },
    associatedSuccessCriteria: [
      TestAssociatedSuccessCriteria.EXTENDED_CONST_RESPONSE,
    ],
    options: extendedConstructedResponseOptions,
  },
  [TEST_CHOICE_TYPES.TEST_S_ECRR_SHORT]: {
    text: {
      text: '¿Esta es la respuesta construida de la actividad con rúbrica de pregunta corta?',
    },
    chart: charts[CHART_TYPES.EXPERIMENTAL],
    associatedSuccessCriteria: [
      TestAssociatedSuccessCriteria.EXTENDED_CONST_RESPONSE_RUBRIC,
    ],
    options: extendedConstructedResponseOptions,
  },
  [TEST_CHOICE_TYPES.TEST_S_ECRR_MEDIUM]: {
    text: {
      text: '¿Esta es la respuesta construida de la actividad con pregunta media de rúbrica? Se agregó texto de pregunta para longitud media.',
    },
    chart: charts[CHART_TYPES.SAMPLE_WITH_TITLE],
    associatedSuccessCriteria: [
      TestAssociatedSuccessCriteria.EXTENDED_CONST_RESPONSE_RUBRIC,
    ],
    options: extendedConstructedResponseOptions,
  },
  [TEST_CHOICE_TYPES.TEST_S_ECRR_LONG]: {
    text: {
      text: '¿Esta es la respuesta construida de la actividad con una rúbrica de pregunta larga? Se agregó texto a la pregunta para agregar una pregunta más larga. Se agregó más texto de pregunta aquí para mayor extensión.',
    },
    chart: charts[CHART_TYPES.SAMPLE_WITH_TITLE],
    associatedSuccessCriteria: [
      TestAssociatedSuccessCriteria.EXTENDED_CONST_RESPONSE_RUBRIC,
    ],
    options: extendedConstructedResponseOptions,
  },
};
