import { Component } from '@angular/core';

@Component({
  selector: 'htc-status-wrong',
  templateUrl: './status-wrong.component.html',
  styleUrls: ['../progress-node/progress-node.component.scss'],
})
export class StatusWrongComponent {
  readonly path = '/assets/progress-node-icons/';
  readonly imageUrl = 'x.svg';

  constructor() {}
}
