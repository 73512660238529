import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  constructor() {}
  previousActiveElement: HTMLElement | null = null;

  setActiveElement(element: HTMLElement | null): void {
    this.previousActiveElement = element;
  }

  focusActiveElement(): void {
    this.previousActiveElement?.focus();
    this.setActiveElement(null);
  }
}
