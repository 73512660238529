import {
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AudioService } from 'src/app/modules/audio/services/audio/audio.service';
import { UserService } from '../../services';
import { AudioType } from '../../enums/audio-type';
import { Themes } from '../../enums/themes';
import { SettingsService } from '../../services/settings/settings.service';
import { Subscription } from 'rxjs';

export enum AudioDragType {
  PICKUP,
  PLACE,
}

export interface AudioDragSFX {
  theme: null | Themes;
  type: null | AudioType;
  desc: string;
  idSound: string;
  retryCount: number;
}

@Directive({
  selector: '[htcAudioDrag]',
})
export class AudioDragDirective implements OnInit, OnDestroy {
  sfxData: {
    [key: number]: AudioDragSFX;
  } = {
    [AudioDragType.PICKUP]: {
      theme: null,
      type: null,
      desc: '',
      idSound: '',
      retryCount: 0,
    },
    [AudioDragType.PLACE]: {
      theme: null,
      type: null,
      desc: '',
      idSound: '',
      retryCount: 0,
    },
  };
  sub = new Subscription();
  muteSfx!: boolean;
  dragEventEmitter!: EventEmitter<AudioDragType>;

  @Input()
  set pickupAudioType(val: AudioType) {
    if (val) {
      this.sfxData[AudioDragType.PICKUP].type = val;
    }
  }
  @Input()
  set pickupDescriptor(val: string) {
    if (val) {
      this.sfxData[AudioDragType.PICKUP].desc = val;
    }
  }
  @Input()
  set placeAudioType(val: AudioType) {
    if (val) {
      this.sfxData[AudioDragType.PLACE].type = val;
    }
  }
  @Input()
  set placeDescriptor(val: string) {
    if (val) {
      this.sfxData[AudioDragType.PLACE].desc = val;
    }
  }
  @Input()
  set dragEvent(val: EventEmitter<AudioDragType>) {
    if (val && !this.dragEventEmitter) {
      this.dragEventEmitter = val;
      this.sub.add(
        this.dragEventEmitter.subscribe(e => {
          switch (e) {
            case AudioDragType.PLACE:
              this.onPlace();
              break;
            default:
              this.onPickup();
              break;
          }
        })
      );
    }
  }

  constructor(
    private audioService: AudioService,
    private userService: UserService,
    private settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    this.userService.theme.subscribe(str => {
      const theme = str as Themes;
      this.setupSFX(this.sfxData[AudioDragType.PICKUP], theme);
      this.setupSFX(this.sfxData[AudioDragType.PLACE], theme);
    });

    this.sub.add(
      this.settingsService.settings.subscribe(settings => {
        this.muteSfx = settings.muteSfx ?? false;
      })
    );
  }

  ngOnDestroy(): void {
    this.audioService.release(this.sfxData[AudioDragType.PICKUP].idSound);
    this.audioService.release(this.sfxData[AudioDragType.PLACE].idSound);
    this.sub.unsubscribe();
  }

  setupSFX(sfx: AudioDragSFX, theme: Themes): void {
    if (theme !== sfx.theme) {
      if (sfx.idSound !== '') {
        this.audioService.release(sfx.idSound);
        sfx.idSound = '';
      }
      sfx.theme = theme;
      if (sfx.type) {
        sfx.idSound = this.audioService.initSFXAudio(
          sfx.type,
          sfx.desc,
          sfx.theme
        ).id;
      }
    }
  }

  onPickup(): void {
    this.playSFX(this.sfxData[AudioDragType.PICKUP]);
  }

  onPlace(): void {
    this.playSFX(this.sfxData[AudioDragType.PLACE]);
  }

  playSFX(sfx: AudioDragSFX): void {
    const { state } = Howler.ctx;
    if (state === 'suspended' && sfx.retryCount === 0) {
      sfx.retryCount += 1;
      // on initial load of the page Howler has state "suspended".
      // to change that state, a user needs to make an interaction with the web page
      // (click on any element or use any keypress) before we can
      // use Howler. So if user clicks on an element with SFX, an audio won't be played
      // we need to retrigger click event if a user clicks on the element with SFX
      // to play the sound
      Howler.ctx.resume().then(() => {
        console.log('Retrying audio click event');
        this.playSFX(sfx);
      });
    } else {
      sfx.retryCount = 0;
    }
    if (
      sfx.idSound != undefined &&
      this.audioService.isSoundLoaded(sfx.idSound) &&
      !this.muteSfx
    ) {
      this.audioService.playSound({ id: sfx.idSound }, true);
    } else {
      console.log('Click audio is not loaded: ' + sfx.type + ', ' + sfx.desc);
    }
  }
}
