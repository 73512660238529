import { Pipe, PipeTransform } from '@angular/core';
import { SuccessCriteria } from '../../interfaces/success-criteria';
import { TextData } from '../../interfaces';

@Pipe({
  name: 'criteriaTextData',
})
export class CriteriaTextDataPipe implements PipeTransform {
  transform(value: SuccessCriteria[]): TextData[] {
    return value.map(criteria => criteria.text);
  }
}
