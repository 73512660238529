import { Injectable } from '@angular/core';

import { APIHandler } from '../api-handler/api-handler';

import { ConfigurationService } from 'src/app/modules/configuration/services/configuration/configuration.service';

import {
  ClickstreamActivityData,
  ClickstreamStore,
  ClickstreamEventData,
} from '../../types';
import { ClickstreamEventTypeName } from '../../enums/clickstream-events';

@Injectable()
export class ClickstreamAPI {
  readonly pathBase = `clickstream`;
  readonly clickstreamData = 'clickstream-data';

  userId!: number;
  schoolYear = new Date().getFullYear();
  sessionId = '';
  authToken = '';

  private startTime = 0;
  private apiHandler: APIHandler;
  private activityData: ClickstreamActivityData = {
    activityName: '',
    category: '',
    difficulty: 0,
    scoreCriteria: '3',
  };
  private clickstreamCache?: ClickstreamStore;

  constructor(private configService: ConfigurationService) {
    this.apiHandler = new APIHandler({
      baseUrl: this.configService.config.olpApiDomain,
      defaultHeaders: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  }

  get currentActivity(): ClickstreamActivityData {
    return this.activityData;
  }

  setAuthToken(authToken: string): void {
    this.authToken = authToken;
    this.configureHandler();
  }

  setActivityData(activity: ClickstreamActivityData): void {
    this.activityData = activity;
    if (this.clickstreamCache) {
      this.clickstreamCache.activity = activity;
    }
  }

  configureHandler(): void {
    this.apiHandler.configure({
      baseUrl: this.configService.config.olpApiDomain,
      defaultHeaders: {
        Authorization: 'Bearer ' + this.authToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  }

  initializeClickstreamCache(
    activityData: ClickstreamActivityData,
    scoreActivity: boolean
  ): void {
    this.startTime = Date.now();
    this.setActivityData(activityData);
    this.clickstreamCache = {
      schoolYear: this.schoolYear,
      sessionId: this.sessionId,
      scoreActivity: scoreActivity,
      startTime: new Date(this.startTime).toISOString(),
      activity: this.activityData,
      events: [],
    };
  }

  initializeActivity(
    activityData: ClickstreamActivityData,
    scoreActivity: boolean
  ): void {
    this.initializeClickstreamCache(activityData, scoreActivity);
    this.addEvent({
      eventType: ClickstreamEventTypeName.ActivityBegin,
      key: activityData.activityName,
      offset: 0,
      data: activityData.activityName,
    });
  }

  completeActivity(score: number, numStars: number): void {
    this.addEvent({
      eventType: ClickstreamEventTypeName.ActivityComplete,
      key: this.activityData.activityName,
      offset: this.getOffset(),
      data: [this.activityData.activityName, numStars, score],
    });
  }

  getOffset(): number {
    return Date.now() - this.startTime;
  }

  addEvent(event: ClickstreamEventData): void {
    const eventType = event.eventType;
    if (
      Object.keys(ClickstreamEventTypeName).includes(eventType) &&
      this.clickstreamCache !== undefined
    ) {
      this.clickstreamCache.events.push(event);
    }
  }

  clearEvents(): void {
    if (this.clickstreamCache) {
      this.clickstreamCache.events = [];
    }
  }

  clearCache(): void {
    this.clickstreamCache = undefined;
  }

  saveClickstreamData(): void {
    if (this.clickstreamCache && this.clickstreamCache.events.length > 0) {
      this.apiHandler.post(this.buildPath(), {
        data: { data: this.clickstreamCache },
      });
    } else {
      console.error(
        "We tried to save clickstream data, but we don't have a valid clickstream cache or events.",
        this.clickstreamCache
      );
    }
  }

  private buildPath(): string {
    return `${this.pathBase}/${this.userId}`;
  }
  /**
   * This is for debug purposes only!
   */
  get clickstreamCacheData(): ClickstreamStore | undefined {
    return this.clickstreamCache;
  }
}
