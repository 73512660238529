import { Activity } from 'src/app/shared/interfaces';

export const lectura1: Activity = {
  id: '018ea019-0420-7f67-b665-fbfeb764deee',
  product: 'Lectura',
  language: 'es',
  skillName: 'Preposiciones',
  thumbnail: {
    id: 'recSkH6hmMMvnBbyL',
    url: '/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png',
    altText: [
      {
        language: 'en',
        altText:
          'Axis has a video call with Estrella in the cockpit of his ship.',
      },
      {
        language: 'es',
        altText:
          'Axis tiene una videollamada con Estrella en la cabina de su nave.',
      },
    ],
  },
  description:
    'El explorador Axis y su leal compañera G.A.T.A. van a comenzar una aventura extraordinaria para conseguir sus insignias de mérito terrestres. Su misión consistirá en dominar el uso de las preposiciones y locuciones o frases preposicionales que usan los humanos para comunicarse en el idioma español.  ',
  domain: {
    text: 'Parts of Speech',
  },
  seriesName: 'Misión terrestre',
  episodeNumber: '1',
  episodeTitle: 'De los planetas a las estrellas',
  videos: [
    {
      id: 'recYStMYn0PCgidwH',
      url: '/helix/Lectura/Grade_3/Prepositions/Episode_01/hls_streams/L_G3_Prepositions_Ep01_Video01.m3u8',
      posterURL:
        '/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png',
      captions: [
        {
          src: '/helix/Lectura/Grade_3/Prepositions/Episode_01/L_G3_Prepositions_Ep01_Video01.vtt',
          srclang: 'es',
          mode: 'hidden',
          kind: 'captions',
        },
      ],
    },
  ],
  startScreenData: {
    learningTarget: [
      {
        id: '32597.A',
        learningTarget: {
          text: 'a definir qué es preposición y locución o frase preposicional',
        },
        successCriteria: [
          {
            id: '32597.A.1',
            text: 'definir el concepto de preposición',
          },
          {
            id: '32597.A.2',
            text: 'definir el concepto de locución o frase preposicional',
          },
          {
            id: '32597.A.3',
            text: 'usar objetos o palabras para decir la función de una preposición',
          },
        ],
      },
    ],
    background_leftImage: {
      id: 'recogPPnqdeKi1m58',
      url: '/helix/UX_Images/Start_Screen_Blobs/Prepositions_G3V1_Poster.jpg',
      altText: [
        {
          language: 'en',
          altText:
            'Axis has a video call with Estrella in the cockpit of his ship.',
        },
        {
          language: 'es',
          altText:
            'Axis tiene una videollamada con Estrella en la cabina de su nave.',
        },
      ],
    },
    background_rightImage: {
      id: 'recxjC353VMav5ybu',
      url: '/helix/UX_Images/Start_Screen_Blobs/Nova-Scouts_space-sky.svg',
      altText: [
        { language: 'en', altText: 'Stars in space.' },
        { language: 'es', altText: 'Estrellas en el espacio.' },
      ],
    },
    foreground: {
      id: 'recnQirQF1onHfeSJ',
      url: '/helix/UX_Images/Start_Screen_Characters/Estrella5.svg',
      altText: [
        { language: 'en', altText: 'Estrella is waving.' },
        { language: 'es', altText: 'Estrella está saludando.' },
      ],
    },
  },
  populations: [
    {
      id: '32597.A.2.2',
      templateID: 0,
      complexity: 'Knowledge',
      hint: [
        {
          id: '32597.A.2.2.0',
          type: 'BasicSlide',
          data: {
            title:
              'Piensa en cómo se llaman las expresionas formadas por dos o más palabras, y que cuando se escriben solas no tienen sentido. \n',
          },
        },
        {
          id: '32597.A.2.2.1',
          type: 'BasicSlide',
          data: {
            title:
              'Piensa en el grupo de palabras que tienen la misma función que una preposición. \n',
          },
        },
      ],
      populationData: {
        question: {
          text: '¿Cuál es la definición correcta de locución o frase preposicional?\n',
        },
        answerChoices: [
          {
            id: '32597.A.2.2_1',
            choice: {
              texts: [
                {
                  text: 'Son conjuntos de dos o más palabras que funcionan como una preposición. \n',
                },
              ],
            },
          },
          {
            id: '32597.A.2.2_2',
            choice: {
              texts: [
                {
                  text: 'Son conjuntos de dos o más palabras que funcionan como un verbo.\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Recuerda que una locución preposicional no tiene verbo. \n',
              },
            },
          },
          {
            id: '32597.A.2.2_3',
            choice: {
              texts: [
                {
                  text: 'Son conjuntos de dos o más palabras que funcionan como un sustantivo.\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Una locución preposicional no se forma con un sustantivo. \n',
              },
            },
          },
        ],
        answers: ['32597.A.2.2_1'],
        associatedSuccessCriteria: ['32597.A.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'Las locuciones o frases preposicionales hacen la misma función que una preposición al unir palabras en una oración. \n',
      },
    },
    {
      id: '32597.A.1.3',
      templateID: 1,
      complexity: 'Knowledge',
      hint: [
        {
          id: '32597.A.1.3.0',
          type: 'BasicSlide',
          data: {
            title:
              'Piensa en cómo se llaman las palabras que no tienen ni género ni número, y que cuando se escriben solas no tienen sentido. \n',
          },
        },
        {
          id: '32597.A.1.3.1',
          type: 'BasicSlide',
          data: {
            title:
              'Algunas palabras que no tienen género ni número son: a, bajo, entre, por. \n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Las preposiciones son palabras {CHOICE_TOKEN} que se utilizan para unir palabras o grupos de palabras que se relacionan entre sí. \n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '32597.A.1.3_1',
                  text: 'invariables\n',
                },
                {
                  id: '32597.A.1.3_2',
                  text: 'variables\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'Las palabras variables tienen género y número, como la palabra niño.\n',
                    },
                  },
                },
                {
                  id: '32597.A.1.3_3',
                  text: 'iguales \n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'Recuerda que en español existen 23 preposiciones diferentes. «Iguales» no es una preposición.\n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['32597.A.1.3_1'],
        associatedSuccessCriteria: ['32597.A.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'Las preposiciones no tienen ni género ni número y eso las hacen palabras invariables.\n',
      },
    },
    {
      id: '32597.A.2.4',
      templateID: 1,
      complexity: 'Knowledge',
      hint: [
        {
          id: '32597.A.2.4.0',
          type: 'BasicSlide',
          data: {
            title:
              'Piensa qué se necesita para formar una locución o frase preposicional. \n',
          },
        },
        {
          id: '32597.A.2.4.1',
          type: 'BasicSlide',
          data: {
            title:
              'Recuerda cómo escribimos una locución o frase preposicional. \n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Una locución o frase preposicional es un conjunto de dos o más {CHOICE_TOKEN} que funcionan como una preposición.\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '32597.A.2.4_1',
                  text: 'palabras\n',
                },
                {
                  id: '32597.A.2.4_2',
                  text: 'oraciones\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'Las oraciones están formadas por la unión de un sujeto y un predicado. Recuerda cómo se forma una locución o frase preposicional.  \n',
                    },
                  },
                },
                {
                  id: '32597.A.2.4_3',
                  text: 'sílabas\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'Las sílabas son un conjunto de sonidos, y no forman una locución o frase preposicional.  \n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['32597.A.2.4_1'],
        associatedSuccessCriteria: ['32597.A.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'Para formar una locución o frase preposicional siempre necesitas dos o más palabras.\n',
      },
    },
    {
      id: '32597.A.3.5',
      templateID: 4,
      complexity: 'Comprehension',
      hint: [
        {
          id: '32597.A.3.5.0',
          type: 'BasicSlide',
          data: {
            title:
              'Una locución o frase preposicional da información sobre el lugar en donde se encuentra un objeto o persona. \n',
          },
        },
        {
          id: '32597.A.3.5.1',
          type: 'BasicSlide',
          data: {
            title:
              'Una locución o frase preposicional te dice dónde está una persona, animal u objeto.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Elige la imagen que coincida con la locución o frase preposicional «debajo de».\n',
        },
        answerChoices: [
          {
            id: '32597.A.3.5_1',
            choice: {
              images: [
                {
                  id: 'recURFARAsVKGCuGa',
                  url: '/helix/TEI/SVGs/TEI_prepositions_catundertable_01.svg',
                  altText: [
                    { language: 'en', altText: 'A cat sits below a table.' },
                    {
                      language: 'es',
                      altText: 'El gato se siente debajo de la mesa.',
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '32597.A.3.5_2',
            choice: {
              images: [
                {
                  id: 'recOMYtMhQPm5AKji',
                  url: '/helix/TEI/SVGs/TEI_prepositions_catnextotable_01.svg',
                  altText: [
                    { language: 'en', altText: 'A cat sits beside a table.' },
                    {
                      language: 'es',
                      altText: 'El gato se sienta al lado de la mesa.',
                    },
                  ],
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Aquí vemos al gato al lado de la mesa. Recuerda que «debajo de» significa «en un lugar inferior». \n',
              },
            },
          },
          {
            id: '32597.A.3.5_3',
            choice: {
              images: [
                {
                  id: 'recyc3ccnJo7mc5uk',
                  url: '/helix/TEI/SVGs/TEI_prepositions_catontable_01.svg',
                  altText: [
                    { language: 'en', altText: 'A cat sleeps on a table.' },
                    {
                      language: 'es',
                      altText: 'El gato se duerme sobre la mesa.',
                    },
                  ],
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Aquí vemos al gato sobre la mesa. Recuerda que «debajo de» significa en un lugar inferior. \n',
              },
            },
          },
        ],
        answers: ['32597.A.3.5_1'],
        associatedSuccessCriteria: ['32597.A.3'],
      },
      feedbackSecondIncorrect: {
        content: '«Debajo de» significa en un lugar o puesto inferior.\n',
      },
    },
    {
      id: '32597.A.3.6',
      templateID: 4,
      complexity: 'Comprehension',
      hint: [
        {
          id: '32597.A.3.6.0',
          type: 'BasicSlide',
          data: {
            title:
              'Una locución o frase preposicional da información sobre el lugar en donde se encuentra un objeto o persona. \n',
          },
        },
        {
          id: '32597.A.3.6.1',
          type: 'BasicSlide',
          data: {
            title:
              'Una locución o frase preposicional te dice dónde está una persona, animal u objeto.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Elige la imagen que coincida con la locución o frase preposicional «en medio de».\n',
        },
        answerChoices: [
          {
            id: '32597.A.3.6_1',
            choice: {
              images: [
                {
                  id: 'recoRL4irk6j0z9RT',
                  url: '/helix/TEI/SVGs/TEI_prepositions_dogbetweentree_01.svg',
                  altText: [
                    {
                      language: 'en',
                      altText: 'A dog sits between two trees.',
                    },
                    {
                      language: 'es',
                      altText: 'El perro se sienta entre dos árboles.',
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '32597.A.3.6_2',
            choice: {
              images: [
                {
                  id: 'recXNVhND03ij3EUy',
                  url: '/helix/TEI/SVGs/TEI_prepositions_doginfrontoftree_01.svg',
                  altText: [
                    {
                      language: 'en',
                      altText: 'A dog sits in front of a tree.',
                    },
                    {
                      language: 'es',
                      altText: 'El perro se sienta frente al árbol.',
                    },
                  ],
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Parece que el perro no está en medio de los árboles. Recuerda que «en medio de» significa «en medio de dos o más objetos».\n',
              },
            },
          },
          {
            id: '32597.A.3.6_3',
            choice: {
              images: [
                {
                  id: 'rec7jtmrWtlHwjfbX',
                  url: '/helix/TEI/SVGs/TEI_prepositions_dogbehindtree_01.svg',
                  altText: [
                    { language: 'en', altText: 'A dog sits behind a tree.' },
                    {
                      language: 'es',
                      altText: 'El perro se sienta detrás del árbol.',
                    },
                  ],
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'En esta imagen el perro se encuentra detrás del árbol. Recuerda que «en medio de» significa en medio de dos o más objetos. \n',
              },
            },
          },
        ],
        answers: ['32597.A.3.6_1'],
        associatedSuccessCriteria: ['32597.A.3'],
      },
      feedbackSecondIncorrect: {
        content: '«En medio de» significa en medio de dos objetos.\n',
      },
    },
    {
      id: '32597.A.3.7',
      templateID: 0,
      complexity: 'Comprehension',
      hint: [
        {
          id: '32597.A.3.7.0',
          type: 'BasicSlide',
          data: {
            title:
              'Recuerda que una preposición de lugar nos dice dónde está un objeto, animal o persona. \n',
          },
        },
        {
          id: '32597.A.3.7.1',
          type: 'BasicSlide',
          data: {
            title:
              'La preposición de lugar nos dice «dónde» se encuentra un objeto. \n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Elige la preposición que coincida con la imagen. \n',
        },
        answerChoices: [
          {
            id: '32597.A.3.7_1',
            choice: {
              texts: [
                {
                  text: 'en\n',
                },
              ],
            },
          },
          {
            id: '32597.A.3.7_2',
            choice: {
              texts: [
                {
                  text: 'tras \n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Recuerda que la preposición «tras» significa «detrás de».\n',
              },
            },
          },
          {
            id: '32597.A.3.7_3',
            choice: {
              texts: [
                {
                  text: 'bajo \n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Recuerda que bajo significa «debajo de» en un lugar inferior. \n',
              },
            },
          },
        ],
        answers: ['32597.A.3.7_1'],
        associatedSuccessCriteria: ['32597.A.3'],
      },
      feedbackSecondIncorrect: {
        content: '«En» indica dónde está el zorro, la caja sería el lugar. \n',
      },
      image: {
        id: 'recUnmYAgpbIWajkS',
        url: '/helix/TEI/SVGs/TEI_prepositions_foxinbox_01.svg',
        altText: [
          { language: 'en', altText: 'A fox sits inside a cardboard box.' },
          {
            language: 'es',
            altText: 'El zorro se sienta dentro de la caja de cartón.',
          },
        ],
      },
    },
    {
      id: '32597.A.3.8',
      templateID: 0,
      complexity: 'Comprehension',
      hint: [
        {
          id: '32597.A.3.8.0',
          type: 'BasicSlide',
          data: {
            title:
              'Recuerda que una preposición de lugar nos dice dónde está un objeto, animal o persona. \n',
          },
        },
        {
          id: '32597.A.3.8.1',
          type: 'BasicSlide',
          data: {
            title:
              'La preposición de lugar nos dice «dónde» se encuentra un objeto. \n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Elige la preposición que coincida con la imagen. \n',
        },
        answerChoices: [
          {
            id: '32597.A.3.8_1',
            choice: {
              texts: [
                {
                  text: 'sobre\n',
                },
              ],
            },
          },
          {
            id: '32597.A.3.8_2',
            choice: {
              texts: [
                {
                  text: 'entre\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: 'Recuerda que «sobre» significa «encima de». \n',
              },
            },
          },
          {
            id: '32597.A.3.8_3',
            choice: {
              texts: [
                {
                  text: 'en \n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: 'La preposición «sobre» quiere decir «encima de». \n',
              },
            },
          },
        ],
        answers: ['32597.A.3.8_1'],
        associatedSuccessCriteria: ['32597.A.3'],
      },
      feedbackSecondIncorrect: { content: 'El pájaro está sobre la caja.\n' },
      image: {
        id: 'recGdNetFtqNKuEjf',
        url: '/helix/TEI/SVGs/TEI_prepositions_birdonbox_01.svg',
        altText: [
          { language: 'en', altText: 'A bird sits on a box.' },
          { language: 'es', altText: 'El pájaro se para sobre la caja.' },
        ],
      },
    },
    {
      id: '32597.A.3.9',
      templateID: 0,
      complexity: 'Comprehension',
      hint: [
        {
          id: '32597.A.3.9.0',
          type: 'BasicSlide',
          data: {
            title:
              'Recuerda que una preposición de lugar nos dice dónde está un objeto, animal o persona. \n',
          },
        },
        {
          id: '32597.A.3.9.1',
          type: 'BasicSlide',
          data: {
            title:
              'La preposición de lugar nos dice «dónde» se encuentra un objeto. \n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Elige la preposición que coincida con la imagen. \n',
        },
        answerChoices: [
          {
            id: '32597.A.3.9_1',
            choice: {
              texts: [
                {
                  text: 'bajo\n',
                },
              ],
            },
          },
          {
            id: '32597.A.3.9_2',
            choice: {
              texts: [
                {
                  text: 'sobre \n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Cuando un objeto está sobre algo, quiere decir que se encuentra «encima de» otro objeto. \n',
              },
            },
          },
          {
            id: '32597.A.3.9_3',
            choice: {
              texts: [
                {
                  text: 'entre\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Necesitas dos o más objetos para que la pelota esté «entre» los objetos. \n',
              },
            },
          },
        ],
        answers: ['32597.A.3.9_1'],
        associatedSuccessCriteria: ['32597.A.3'],
      },
      feedbackSecondIncorrect: {
        content:
          '«Bajo» indica el lugar en dónde se encuentra el objeto, que en este caso sería bajo la mesa. \n',
      },
      image: {
        id: 'reczsbMN6bR9pKlaB',
        url: '/helix/TEI/SVGs/TEI_prepositions_ballundertable_01.svg',
        altText: [
          { language: 'en', altText: 'A soccer ball sits under a table.' },
          {
            language: 'es',
            altText: ' La pelota de fútbol está debajo de la mesa.',
          },
        ],
      },
    },
    {
      id: '32597.A.3.10',
      templateID: 5,
      complexity: 'Comprehension',
      hint: [
        {
          id: '32597.A.3.10.0',
          type: 'BasicSlide',
          data: {
            title: 'Piensa qué unen las preposiciones en una oración. \n',
          },
        },
        {
          id: '32597.A.3.10.1',
          type: 'BasicSlide',
          data: {
            title: 'Las preposiciones están formadas por una sola palabra. \n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Las preposiciones son... (Elige 3.)\n',
        },
        answerChoices: [
          {
            id: '32597.A.3.10_1',
            choice: {
              texts: [
                {
                  text: 'palabras invariables\n',
                },
              ],
            },
          },
          {
            id: '32597.A.3.10_2',
            choice: {
              texts: [
                {
                  text: 'se utilizan para unir palabras o grupos de palabras\n',
                },
              ],
            },
          },
          {
            id: '32597.A.3.10_3',
            choice: {
              texts: [
                {
                  text: 'se utilizan en las locuciones o frases preposicionales\n',
                },
              ],
            },
          },
          {
            id: '32597.A.3.10_4',
            choice: {
              texts: [
                {
                  text: 'palabras variables\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Recuerda que las preposiciones no tienen género ni número, es decir, no son palabras variables. \n',
              },
            },
          },
          {
            id: '32597.A.3.10_5',
            choice: {
              texts: [
                {
                  text: 'se utilizan para unir dos oraciones \n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Las preposiciones sirven para unir palabras o grupos de palabras en una oración. \n',
              },
            },
          },
        ],
        answers: ['32597.A.3.10_1', '32597.A.3.10_2', '32597.A.3.10_3'],
        associatedSuccessCriteria: ['32597.A.3'],
      },
      feedbackSecondIncorrect: {
        content: 'Todas las opciones definen el concepto de preposición. \n',
      },
    },
    {
      id: '32597.A.1.1',
      templateID: 0,
      complexity: 'Knowledge',
      hint: [
        {
          id: '32597.A.1.1.0',
          type: 'BasicSlide',
          data: {
            title:
              'Piensa para qué se utilizan las preposiciones cuando escribimos una oración.\n',
          },
        },
        {
          id: '32597.A.1.1.1',
          type: 'BasicSlide',
          data: {
            title:
              'Piensa en la función de una preposición en una oración para que tenga sentido.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: '¿Cuál es la definición correcta de preposición?\n',
        },
        answerChoices: [
          {
            id: '32597.A.1.1_1',
            choice: {
              texts: [
                {
                  text: 'Son palabras invariables que se utilizan para unir palabras o grupos de palabras relacionadas entre sí.\n',
                },
              ],
            },
          },
          {
            id: '32597.A.1.1_2',
            choice: {
              texts: [
                {
                  text: 'Son palabras variables que se utilizan para unir palabras o grupos de palabras relacionadas entre sí.\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Las preposiciones son palabras invariables que no tienen ni género ni número.\n',
              },
            },
          },
          {
            id: '32597.A.1.1_3',
            choice: {
              texts: [
                {
                  text: 'Son palabras invariables que se utilizan para describir un sustantivo.\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Piensa otra vez, ¿cómo funcionan las preposiciones en una oración? \n',
              },
            },
          },
        ],
        answers: ['32597.A.1.1_1'],
        associatedSuccessCriteria: ['32597.A.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'Las preposiciones son palabras que no tienen género ni número y se utilizan para unir palabras o grupos de palabras en una oración.\n',
      },
    },
  ],
  activityManifest: [
    {
      type: 'START_SCREEN',
    },
    {
      type: 'ENTRY_SURVEY',
    },
    {
      type: 'VIDEO',
      id: 'recYStMYn0PCgidwH',
    },
    {
      type: 'QUESTION',
      id: '32597.A.2.2',
    },
    {
      type: 'QUESTION',
      id: '32597.A.1.3',
    },
    {
      type: 'QUESTION',
      id: '32597.A.2.4',
    },
    {
      type: 'QUESTION',
      id: '32597.A.3.5',
    },
    {
      type: 'QUESTION',
      id: '32597.A.3.6',
    },
    {
      type: 'QUESTION',
      id: '32597.A.3.7',
    },
    {
      type: 'QUESTION',
      id: '32597.A.3.8',
    },
    {
      type: 'QUESTION',
      id: '32597.A.3.9',
    },
    {
      type: 'QUESTION',
      id: '32597.A.3.10',
    },
    {
      type: 'QUESTION',
      id: '32597.A.1.1',
    },
    {
      type: 'EXIT_SURVEY',
    },
    {
      type: 'RESULTS_SCREEN',
    },
  ],
  version: '-355190951',
  nextUp: {
    nextActivityId: '018ea019-04a4-72ba-bdbf-64a85e6f9bd5',
    nextActivityName: '¡Cometa a la vista!',
    nextActivityThumbnail: {
      id: 'recygZeNHb67g46Je',
      url: '/helix/Reading/Grade_3/Prepositions/Episode_02/R_G3_Prepositions_EP02_Poster.png',
      altText: [
        {
          language: 'en',
          altText: 'Axis floats in space outside his spaceship.',
        },
        {
          language: 'es',
          altText: 'Axis flota en el espacio afuera de su nave espacial.',
        },
      ],
    },
  },
  instructionsImage: {
    id: 'recUzD6BEnXxRd8Wh',
    url: '/helix/UX_Images/Characters/Estrella/Estrella_Chibi_Head_Happy_Eyes_Open_Mouth_Open.svg',
    altText: [
      { language: 'en', altText: 'Estrella is smiling.' },
      { language: 'es', altText: 'Estrella está sonriendo.' },
    ],
  },
  characterLeanout: {
    id: 'svvjeewfjhHFEFgef',
    url: '/helix/TEI/Leanouts/Estrella_Leanout_01.svg',
    altText: [
      {
        language: 'en',
        altText: 'Estrella leans out from the side of the screen.',
      },
      {
        language: 'es',
        altText: 'Estrella se asoma por un lado de la pantalla.',
      },
    ],
  },
};
