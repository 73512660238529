import { FeatureFlags } from '../shared/enums/feature-flags';

export const FakeFeatureFlagEnabledRoute = {
  requiredEnabledFlags: 'fake-enabled-flag',
  featureFlagRedirect: '/',
};

export const FakeFeatureFlagDisabledRoute = {
  requiredDisabledFlags: 'fake-disabled-flag',
  featureFlagRedirect: '/',
};

export const EarlyAccessFeatureFlagRoute = {
  requiredEnabledFlags: FeatureFlags.EARLY_ACCESS,
  featureFlagRedirect: '/',
};

export const SandboxFeatureFlagsRoute = {
  requiredEnabledFlags: FeatureFlags.SANDBOX,
  featureFlagRedirect: '/',
};

export const ComponentTestFeatureFlagsRoute = {
  requiredEnabledFlags: FeatureFlags.COMPONENT_TEST_PAGE,
  featureFlagRedirect: '/',
};
