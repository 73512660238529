import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Themes } from '../../enums/themes';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  readonly internalPath = 'hideInternal';
  firstName$ = new BehaviorSubject<string>('');
  lastName$ = new BehaviorSubject<string>('');
  avatar$ = new BehaviorSubject<number>(1);
  stars$ = new BehaviorSubject<number | undefined>(undefined);
  grade$ = new BehaviorSubject<number>(3);
  showInternalWarning$ = new BehaviorSubject<boolean>(true);
  theme = new BehaviorSubject<string>(Themes.DEFAULT);
  _schoolYear = 0;

  constructor() {
    this.showInternalWarning$.next(this.getInternalFlag());
    this.showInternalWarning$.subscribe(show => {
      if (this.getInternalFlag() !== show) {
        this.saveInternalFlag(show);
      }
    });
  }

  private getInternalFlag(): boolean {
    const storedValue = localStorage.getItem(this.internalPath);
    return storedValue !== null ? storedValue === 'true' : true;
  }

  private saveInternalFlag(flag: boolean): void {
    localStorage.setItem(this.internalPath, flag.toString());
  }

  get schoolYear(): number {
    return this._schoolYear;
  }

  /**
   * Set the school year based on the start date of the school year if a Date object is passed in.
   * If a number is passed in, it is assumed to be the year of the school year.
   */
  set schoolYear(nextStart: Date | number) {
    if (nextStart instanceof Date) {
      const today = new Date();
      const isLastYear =
        today.getMonth() < nextStart.getMonth() ||
        (today.getMonth() === nextStart.getMonth() &&
          today.getDay() < nextStart.getDay());
      this._schoolYear = today.getFullYear() - (isLastYear ? 1 : 0);
    } else {
      this._schoolYear = nextStart;
    }
  }
}
