import {
  KATEX_END_DELIM,
  KATEX_START_DELIM,
} from '../modules/math-text/utils/math-text-utils';
import { PopulationDragAndDrop } from '../shared/interfaces';
import { TEST_CHOICE_TYPES } from './choice-types';
import { TestAssociatedSuccessCriteria } from './test-associated-success-criteria.enum';

export const staticHorizontalSequencing: {
  [key: string]: PopulationDragAndDrop;
} = {
  [TEST_CHOICE_TYPES.TEST_R_DND_HORZ_SEQ_SHORT]: {
    question: { text: 'Sequence the events of this question...' },
    labels: [{ text: '1' }, { text: '2' }, { text: '3' }],
    answerChoices: [
      {
        id: 'correct_1',
        choice: {
          texts: [
            {
              text: 'correct 1',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for correct 1' },
          secondIncorrect: { content: 'This is second feedback for correct 1' },
        },
      },
      {
        id: 'correct_2',
        choice: {
          texts: [
            {
              text: 'correct 2',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for correct 2' },
          secondIncorrect: { content: 'This is second feedback for correct 2' },
        },
      },
      {
        id: 'correct_3',
        choice: {
          texts: [
            {
              text: 'correct 3',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for correct 3' },
          secondIncorrect: { content: 'This is second feedback for correct 3' },
        },
      },
      {
        id: 'incorrect_1',
        choice: {
          texts: [
            {
              text: 'incorrect 1',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for incorrect 1' },
          secondIncorrect: {
            content: 'This is second feedback for incorrect 1',
          },
        },
      },
    ],
    answers: ['correct_1', 'correct_2', 'correct_3'],
    associatedSuccessCriteria: [TestAssociatedSuccessCriteria.DRAG_AND_DROP],
  },
  [TEST_CHOICE_TYPES.TEST_R_DND_HORZ_SEQ_LONG]: {
    question: {
      text: 'Sequence the events of this very long question that I will now ask you at this current moment in time...',
    },
    labels: [
      { text: '1' },
      { text: '2' },
      { text: '3' },
      { text: '4' },
      { text: '5' },
      { text: '6' },
    ],
    answerChoices: [
      {
        id: 'correct_1',
        choice: {
          texts: [
            {
              text: 'correct 1',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for correct 1' },
          secondIncorrect: { content: 'This is second feedback for correct 1' },
        },
      },
      {
        id: 'correct_2',
        choice: {
          texts: [
            {
              text: 'correct 2',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for correct 2' },
          secondIncorrect: { content: 'This is second feedback for correct 2' },
        },
      },
      {
        id: 'correct_3',
        choice: {
          texts: [
            {
              text: 'correct 3',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for correct 3' },
          secondIncorrect: { content: 'This is second feedback for correct 3' },
        },
      },
      {
        id: 'correct_4',
        choice: {
          texts: [
            {
              text: 'correct 4',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for correct 4' },
          secondIncorrect: { content: 'This is second feedback for correct 4' },
        },
      },
      {
        id: 'correct_5',
        choice: {
          texts: [
            {
              text: 'correct 5',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for correct 5' },
          secondIncorrect: { content: 'This is second feedback for correct 5' },
        },
      },
      {
        id: 'correct_6',
        choice: {
          texts: [
            {
              text: 'correct 6',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for correct 6' },
          secondIncorrect: { content: 'This is second feedback for correct 6' },
        },
      },
    ],
    answers: [
      'correct_1',
      'correct_2',
      'correct_3',
      'correct_4',
      'correct_5',
      'correct_6',
    ],
    associatedSuccessCriteria: [TestAssociatedSuccessCriteria.DRAG_AND_DROP],
  },
  test1: {
    question: { text: 'Sequence the events of "Goldilocks."' },
    labels: [
      { text: '1' },
      { text: '2' },
      { text: '3' },
      { text: '4' },
      { text: '5' },
      { text: '6' },
    ],
    answerChoices: [
      {
        id: 'abc123',
        choice: {
          texts: [
            {
              text: 'Goldilocks sleeps in the beds.',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: {
            content:
              'This is first feedback for "Goldilocks sleeps in the beds."',
          },
          secondIncorrect: {
            content:
              'This is second feedback for "Goldilocks sleeps in the beds."',
          },
        },
      },
      {
        id: 'abc456',
        choice: {
          texts: [
            {
              text: 'The three bears see Goldilocks.',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: {
            content:
              'This is first feedback for "The three bears see Goldilocks."',
          },
          secondIncorrect: {
            content:
              'This is second feedback for "The three bears see Goldilocks."',
          },
        },
      },
      {
        id: 'abc789',
        choice: {
          texts: [
            {
              text: 'Goldilocks runs away.',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: {
            content: 'This is first feedback for "Goldilocks runs away."',
          },
          secondIncorrect: {
            content: 'This is second feedback for "Goldilocks runs away."',
          },
        },
      },
      {
        id: 'abc10',
        choice: {
          texts: [
            {
              text: "Goldilocks goes into the bears' house.",
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: {
            content:
              'This is first feedback for "Goldilocks goes into the bears\' house."',
          },
          secondIncorrect: {
            content:
              'This is second feedback for "Goldilocks goes into the bears\' house."',
          },
        },
      },
      {
        id: 'abc11',
        choice: {
          texts: [
            {
              text: 'Goldilocks eats the porridge.',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: {
            content:
              'This is first feedback for "Goldilocks eats the porridge."',
          },
          secondIncorrect: {
            content:
              'This is second feedback for "Goldilocks eats the porridge."',
          },
        },
      },
      {
        id: 'abc12',
        choice: {
          texts: [
            {
              text: 'Goldilocks sits in the chairs.',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: {
            content:
              'This is first feedback for "Goldilocks sits in the chairs."',
          },
          secondIncorrect: {
            content:
              'This is second feedback for "Goldilocks sits in the chairs."',
          },
        },
      },
    ],
    answers: ['abc10', 'abc11', 'abc12', 'abc123', 'abc456', 'abc789'],
    associatedSuccessCriteria: [TestAssociatedSuccessCriteria.DRAG_AND_DROP],
  },
  [TEST_CHOICE_TYPES.TEST_R_DND_SEQ_STATIC_HORZ_KATEX]: {
    question: {
      text: `What if I asked you about ${KATEX_START_DELIM}\frac{a}{b}${KATEX_END_DELIM}?`,
    },
    labels: [
      {
        text: `Fraction Label ${KATEX_START_DELIM}\frac{a}{b}${KATEX_END_DELIM}`,
      },
      { text: '2' },
      { text: '3' },
    ],
    answerChoices: [
      {
        id: 'correct_1',
        choice: {
          texts: [
            {
              text: `correct 1 ${KATEX_START_DELIM}\frac{a}{b}${KATEX_END_DELIM}`,
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for correct 1' },
          secondIncorrect: { content: 'This is second feedback for correct 1' },
        },
      },
      {
        id: 'correct_2',
        choice: {
          texts: [
            {
              text: 'correct 2',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for correct 2' },
          secondIncorrect: { content: 'This is second feedback for correct 2' },
        },
      },
      {
        id: 'correct_3',
        choice: {
          texts: [
            {
              text: 'correct 3',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for correct 3' },
          secondIncorrect: { content: 'This is second feedback for correct 3' },
        },
      },
      {
        id: 'incorrect_1',
        choice: {
          texts: [
            {
              text: 'incorrect 1',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for incorrect 1' },
          secondIncorrect: {
            content: 'This is second feedback for incorrect 1',
          },
        },
      },
    ],
    answers: ['correct_1', 'correct_2', 'correct_3'],
    associatedSuccessCriteria: [TestAssociatedSuccessCriteria.DRAG_AND_DROP],
  },
};
