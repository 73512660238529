import { PopulationMultipleChoice } from '../shared/interfaces';
import { TEST_IMAGE_TYPES, images } from '../data/images';
import { TEST_CHOICE_TYPES } from './choice-types';
import { TestAssociatedSuccessCriteria } from './test-associated-success-criteria.enum';

export const multiSelectImageQuestions: {
  [key: number]: PopulationMultipleChoice;
} = {
  [TEST_CHOICE_TYPES.TEST_R_MSI_SHORT]: {
    question: {
      text: 'This is the reading multi select image short question?',
    },
    answerChoices: [
      {
        id: '0',
        choice: {
          images: [images[TEST_IMAGE_TYPES.CORRECT]],
        },
      },
      {
        id: '1',
        choice: {
          images: [images[TEST_IMAGE_TYPES.CORRECT]],
        },
      },
      {
        id: '2',
        choice: {
          images: [images[TEST_IMAGE_TYPES.CORRECT]],
        },
      },
      {
        id: '3',
        choice: {
          images: [images[TEST_IMAGE_TYPES.INCORRECT]],
        },
        targetedFeedback: {
          firstIncorrect: {
            chartUrl: '/assets/tables/sample.lexical',
            title: 'Heres a chart for you!',
          },
          secondIncorrect: {
            content: 'This is incorrect 1 - second incorrect targeted feedback',
          },
        },
      },
    ],
    answers: ['0', '1', '2'],
    associatedSuccessCriteria: [
      TestAssociatedSuccessCriteria.MULTI_SELECT_IMAGE,
    ],
  },
  [TEST_CHOICE_TYPES.TEST_R_MSI_MEDIUM]: {
    question: {
      text: 'This is the reading multi select image medium question? Added question text for medium length.',
    },
    answerChoices: [
      {
        id: '0',
        choice: {
          images: [images[TEST_IMAGE_TYPES.CORRECT]],
        },
      },
      {
        id: '1',
        choice: {
          images: [images[TEST_IMAGE_TYPES.CORRECT]],
        },
      },
      {
        id: '2',
        choice: {
          images: [images[TEST_IMAGE_TYPES.INCORRECT]],
        },
        targetedFeedback: {
          firstIncorrect: {
            chartUrl: '/assets/tables/sample.lexical',
          },
          secondIncorrect: {
            content: 'This is incorrect 1 - second incorrect targeted feedback',
          },
        },
      },
      {
        id: '3',
        choice: {
          images: [images[TEST_IMAGE_TYPES.INCORRECT]],
        },
        targetedFeedback: {
          firstIncorrect: {
            content: 'This is incorrect 2 - first incorrect targeted feedback',
          },
          secondIncorrect: {
            content: 'This is incorrect 2 - second incorrect targeted feedback',
          },
        },
      },
    ],
    answers: ['0', '1'],
    associatedSuccessCriteria: [
      TestAssociatedSuccessCriteria.MULTI_SELECT_IMAGE,
    ],
  },
  [TEST_CHOICE_TYPES.TEST_R_MSI_LONG]: {
    question: {
      text: 'This is the reading multi select image long question? Added text to the question in order to add longer question. More question text added here for length.',
    },
    answerChoices: [
      {
        id: '0',
        choice: {
          images: [images[TEST_IMAGE_TYPES.CORRECT]],
        },
      },
      {
        id: '1',
        choice: {
          images: [images[TEST_IMAGE_TYPES.CORRECT]],
        },
      },
      {
        id: '2',
        choice: {
          images: [images[TEST_IMAGE_TYPES.INCORRECT]],
        },
        targetedFeedback: {
          firstIncorrect: {
            content: 'This is incorrect 1 - first incorrect targeted feedback',
          },
          secondIncorrect: {
            content: 'This is incorrect 1 - second incorrect targeted feedback',
          },
        },
      },
      {
        id: '3',
        choice: {
          images: [images[TEST_IMAGE_TYPES.INCORRECT]],
        },
        targetedFeedback: {
          firstIncorrect: {
            content: 'This is incorrect 2 - first incorrect targeted feedback',
          },
          secondIncorrect: {
            content: 'This is incorrect 2 - second incorrect targeted feedback',
          },
        },
      },
      {
        id: '4',
        choice: {
          images: [images[TEST_IMAGE_TYPES.CORRECT]],
        },
      },
      {
        id: '5',
        choice: {
          images: [images[TEST_IMAGE_TYPES.CORRECT]],
        },
      },
    ],
    answers: ['0', '1', '4', '5'],
    associatedSuccessCriteria: [
      TestAssociatedSuccessCriteria.MULTI_SELECT_IMAGE,
    ],
  },
  [TEST_CHOICE_TYPES.TEST_S_MSI_SHORT]: {
    question: {
      text: 'Esto es incorrecto 2 segundos de comentarios dirigidos incorrectos',
    },
    answerChoices: [
      {
        id: '0',
        choice: {
          images: [images[TEST_IMAGE_TYPES.CORRECT]],
        },
      },
      {
        id: '1',
        choice: {
          images: [images[TEST_IMAGE_TYPES.CORRECT]],
        },
      },
      {
        id: '2',
        choice: {
          images: [images[TEST_IMAGE_TYPES.CORRECT]],
        },
      },
      {
        id: '3',
        choice: {
          images: [images[TEST_IMAGE_TYPES.INCORRECT]],
        },
        targetedFeedback: {
          firstIncorrect: {
            content:
              'Esto es incorrecto 1: primer comentario dirigido incorrecto',
          },
          secondIncorrect: {
            content:
              'Esto es incorrecto 1 segundo de comentario dirigido incorrecto',
          },
        },
      },
    ],
    answers: ['0', '1', '2'],
    associatedSuccessCriteria: [
      TestAssociatedSuccessCriteria.MULTI_SELECT_IMAGE,
    ],
  },
  [TEST_CHOICE_TYPES.TEST_S_MSI_MEDIUM]: {
    question: {
      text: '¿Esta es la pregunta del medio de lectura de imágenes de selección múltiple? Se agregó texto de pregunta para longitud media.',
    },
    answerChoices: [
      {
        id: '0',
        choice: {
          images: [images[TEST_IMAGE_TYPES.CORRECT]],
        },
      },
      {
        id: '1',
        choice: {
          images: [images[TEST_IMAGE_TYPES.CORRECT]],
        },
      },
      {
        id: '2',
        choice: {
          images: [images[TEST_IMAGE_TYPES.INCORRECT]],
        },
        targetedFeedback: {
          firstIncorrect: {
            content:
              'Esto es incorrecto 1: primer comentario dirigido incorrecto',
          },
          secondIncorrect: {
            content:
              'Esto es incorrecto 1 segundo de comentario dirigido incorrecto',
          },
        },
      },
      {
        id: '3',
        choice: {
          images: [images[TEST_IMAGE_TYPES.INCORRECT]],
        },
        targetedFeedback: {
          firstIncorrect: {
            content:
              'Esto es incorrecto 2: primer comentario dirigido incorrecto',
          },
          secondIncorrect: {
            content:
              'Esto es incorrecto 2 segundos de comentarios dirigidos incorrectos',
          },
        },
      },
    ],
    answers: ['0', '1'],
    associatedSuccessCriteria: [
      TestAssociatedSuccessCriteria.MULTI_SELECT_IMAGE,
    ],
  },
  [TEST_CHOICE_TYPES.TEST_S_MSI_LONG]: {
    question: {
      text: '¿Esta es la pregunta larga sobre lectura de imágenes de selección múltiple? Se agregó texto a la pregunta para agregar una pregunta más larga. Se agregó más texto de pregunta aquí para mayor extensión.',
    },
    answerChoices: [
      {
        id: '0',
        choice: {
          images: [images[TEST_IMAGE_TYPES.CORRECT]],
        },
      },
      {
        id: '1',
        choice: {
          images: [images[TEST_IMAGE_TYPES.CORRECT]],
        },
      },
      {
        id: '2',
        choice: {
          images: [images[TEST_IMAGE_TYPES.INCORRECT]],
        },
        targetedFeedback: {
          firstIncorrect: {
            content:
              'Esto es incorrecto 1: primer comentario dirigido incorrecto',
          },
          secondIncorrect: {
            content:
              'Esto es incorrecto 1 segundo de comentario dirigido incorrecto',
          },
        },
      },
      {
        id: '3',
        choice: {
          images: [images[TEST_IMAGE_TYPES.INCORRECT]],
        },
        targetedFeedback: {
          firstIncorrect: {
            content:
              'Esto es incorrecto 2: primer comentario dirigido incorrecto',
          },
          secondIncorrect: {
            content:
              'Esto es incorrecto 2 segundos de comentarios dirigidos incorrectos',
          },
        },
      },
      {
        id: '4',
        choice: {
          images: [images[TEST_IMAGE_TYPES.CORRECT]],
        },
      },
      {
        id: '5',
        choice: {
          images: [images[TEST_IMAGE_TYPES.CORRECT]],
        },
      },
    ],
    answers: ['0', '1', '4', '5'],
    associatedSuccessCriteria: [
      TestAssociatedSuccessCriteria.MULTI_SELECT_IMAGE,
    ],
  },
};
