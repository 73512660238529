import { NgModule } from '@angular/core';
import {
  APOLLO_NAMED_OPTIONS,
  ApolloModule,
  NamedOptions,
} from 'apollo-angular';
import { HttpClientModule } from '@angular/common/http';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { ConfigurationService } from '../../configuration/services/configuration/configuration.service';

@NgModule({
  imports: [HttpClientModule, ApolloModule],
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory(c: ConfigurationService, httpLink: HttpLink): NamedOptions {
        return {
          contentMetadata: {
            cache: new InMemoryCache(),
            link: httpLink.create({
              uri: c.config.contentMetadataDomain + 'graphql/',
            }),
          },
        };
      },
      deps: [ConfigurationService, HttpLink],
    },
  ],
})
export class GraphqlModule {}
