import { Component, EventEmitter, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IProgressNodeCompData } from '../../interfaces/progress-node-comp-data.interface';
import { IProgressNodeComponent } from '../../interfaces/progress-node-comp.interface';
import { IProgressNodeData } from '../../interfaces/progress-node-data.interface';

@Component({
  selector: 'htc-base-node',
  template: ``,
})
export class BaseNodeComponent implements IProgressNodeComponent {
  type = '';
  @Input() index = 0;
  @Input() focus$ = new BehaviorSubject<number | undefined>(undefined);
  @Input() status$ = new BehaviorSubject<number | undefined>(undefined);
  @Input() data: IProgressNodeCompData = {};
  @Input() clickedEmitter!: EventEmitter<IProgressNodeData>;
  @Input() disabled = true;
}
