import { Injectable } from '@angular/core';
import { VideoData } from 'src/app/shared/interfaces';
import videojs from 'video.js';
import ClosedCaptionsMenuButton from '../components/closed-captions-menu-button/closed-captions-menu-button.component';
import { CustomMenuButton } from '../components/custom-menu-button/custom-menu-button.component';
import FullscreenButtonComponent from '../components/fullscreen-button/fullscreen-button.component';
import JumpAheadComponent from '../components/jump-ahead/jump-ahead.component';
import JumpBackComponent from '../components/jump-back/jump-back.component';
import JumpToStartComponent from '../components/jump-to-start/jump-to-start.component';
import SettingsMenuButton from '../components/settings-menu-button/settings-menu-button.component';
import TranscriptToggleComponent from '../components/transcript-toggle/transcript-toggle.component';
import WatchTimeComponent from '../components/watch-time/watch-time.component';
import {
  CaptionsService,
  TranscriptService,
  VideoService,
  VolumeService,
  KeyboardShortcutService,
  SettingsPopupsService,
  FullscreenService,
  UtilsService,
} from '.';

@Injectable({
  providedIn: 'root',
})
export class PlayerReadyCallbackService {
  constructor(
    private captionService: CaptionsService,
    private transcriptService: TranscriptService,
    private videoService: VideoService,
    private volumeService: VolumeService,
    private keyboardShortcutService: KeyboardShortcutService,
    private settingsService: SettingsPopupsService,
    private fullscreenService: FullscreenService,
    private utilsService: UtilsService
  ) {}

  defaultReadyCallback(player: videojs.Player, videoData: VideoData): void {
    videojs.registerComponent('watchTime', WatchTimeComponent);
    videojs.registerComponent('jumpToStart', JumpToStartComponent);
    videojs.registerComponent('jumpBack', JumpBackComponent);
    videojs.registerComponent('jumpAhead', JumpAheadComponent);
    videojs.registerComponent(
      'closedCaptionsMenuButton',
      ClosedCaptionsMenuButton
    );
    videojs.registerComponent('transcriptToggle', TranscriptToggleComponent);
    videojs.registerComponent('settingsMenuButton', SettingsMenuButton);
    videojs.registerComponent(
      'customFullscreenToggle',
      FullscreenButtonComponent
    );

    if (videoData.captions) {
      this.addCaptionAndTranscript(videoData.captions, player);
      this.setTranscriptToggle(player);
    }
    this.setupEventListeners(player);
    this.overrideProgressBar(player);
    this.setCustomComponents(player);
    this.setMenus(player);
    this.setFullscreenToggle(player);
    this.setupBigPlayButton(player);
  }

  addCaptionAndTranscript(
    captions: videojs.TextTrackOptions[],
    player: videojs.Player
  ): void {
    this.captionService.setTextTracks(captions);

    this.videoService.captionMenu = player
      .getChild('ControlBar')
      ?.getChild('closedCaptionsMenuButton') as CustomMenuButton;
    this.videoService.settingsMenu = player
      .getChild('ControlBar')
      ?.getChild('settingsMenuButton') as CustomMenuButton;

    this.transcriptService.transcript = [];
    captions.forEach((caption: videojs.TextTrackOptions) => {
      if (caption.src) {
        this.transcriptService.syncReadFile(caption.src);
        this.transcriptService.init();
      }
    });
  }

  setupEventListeners(player: videojs.Player): void {
    player.on('timeupdate', () => {
      this.videoService.onTimeChange();
    });
    player.on('volumechange', () => {
      this.volumeService.onVolumeChange();
    });
  }

  overrideProgressBar(player: videojs.Player): void {
    const progControl = player
      ?.getChild('controlBar')
      ?.getChild('progressControl');
    const seeker = progControl?.getChild('seekBar');
    seeker?.setAttribute('tabindex', '-1');
    // Override default behaviors
    if (seeker) {
      (<videojs.ProgressControl>progControl).handleMouseDown =
        this.overrideHandleMouseDown;
      (<videojs.SeekBar>seeker).handleMouseDown = this.overrideHandleMouseDown;
      (<videojs.SeekBar>seeker).handleKeyPress = this.overrideHandleKeyPress;

      const watchTimeComp = <WatchTimeComponent>seeker?.getChild('watchTime');
      watchTimeComp.watchTime$.next(this.videoService.watchTime$.getValue());
      this.videoService.setWatchTimeComp(watchTimeComp);
    }

    if (this.videoService.watchTimeComp) {
      this.videoService.watchTimeComp.keyboardShortcutService =
        this.keyboardShortcutService;
    }
  }

  setCustomComponents(player: videojs.Player): void {
    const controlBar = player?.getChild('controlBar');

    const jumpToStartComponent = <JumpToStartComponent>(
      controlBar?.getChild('jumpToStart')
    );
    const jumpBackComponent = <JumpBackComponent>(
      controlBar?.getChild('jumpBack')
    );
    const jumpAheadComponent = <JumpAheadComponent>(
      controlBar?.getChild('jumpAhead')
    );

    if (jumpToStartComponent) {
      jumpToStartComponent.videoService = this.videoService;
    }
    if (jumpBackComponent) {
      jumpBackComponent.videoService = this.videoService;
    }
    if (jumpAheadComponent) {
      jumpAheadComponent.videoService = this.videoService;
    }
  }

  setTranscriptToggle(player: videojs.Player): void {
    const controlBar = player?.getChild('controlBar');

    const transcriptToggleComponent = <TranscriptToggleComponent>(
      controlBar?.getChild('transcriptToggle')
    );

    if (transcriptToggleComponent) {
      transcriptToggleComponent.setup(
        this.transcriptService,
        this.settingsService
      );
    }
  }

  setFullscreenToggle(player: videojs.Player): void {
    const controlBar = player?.getChild('controlBar');

    const fullscreenButtonComponent = <FullscreenButtonComponent>(
      controlBar?.getChild('customFullscreenToggle')
    );

    if (fullscreenButtonComponent) {
      fullscreenButtonComponent.setup(this.fullscreenService);
    }
  }

  setMenus(player: videojs.Player): void {
    const controlBar = player?.getChild('controlBar');

    const closedCaptionsMenuButton = <ClosedCaptionsMenuButton>(
      controlBar?.getChild('closedCaptionsMenuButton')
    );
    const settingsMenuButton = <SettingsMenuButton>(
      controlBar?.getChild('settingsMenuButton')
    );

    if (closedCaptionsMenuButton) {
      closedCaptionsMenuButton.setup(this.settingsService, this.utilsService);
    }
    if (settingsMenuButton) {
      settingsMenuButton.setup(this.settingsService, this.utilsService);
    }
  }

  setupBigPlayButton(player: videojs.Player): void {
    const bigPlayButton = player.getChild('BigPlayButton');

    if (bigPlayButton) {
      bigPlayButton.controlText(player.localize('Play'));
      player.on('play', () => {
        bigPlayButton.setAttribute('tabIndex', '-1');
        bigPlayButton.addClass('vjs-big-play-button-animation');
        bigPlayButton.removeClass('vjs-big-pause-button-animation');
      });
      player.on('pause', () => {
        bigPlayButton.removeClass('vjs-big-play-button-animation');
        bigPlayButton.addClass('vjs-big-pause-button-animation');
      });
    }
  }

  /* eslint-disable */
  overrideHandleMouseDown(): void {}
  overrideHandleKeyPress(): void {}
  /* eslint-enable */
}
