import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { AudioDescriptor } from 'src/app/shared/enums/audio-descriptor';
import { AudioType } from 'src/app/shared/enums/audio-type';
import { PopupService } from 'src/app/shared/services/popup/popup.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'htc-contact-support',
  templateUrl: './contact-support.component.html',
  styleUrls: ['./contact-support.component.scss'],
})
export class ContactSupportComponent {
  readonly logMessage = 'LogRecorded';

  @Input() showPopup = false;
  @Output() showPopupChange = new EventEmitter<boolean>();

  version = environment.version;
  clickDescriptor = AudioDescriptor.CLICK_GENERAL;
  clickType = AudioType.audio_SfxClick;

  constructor(private popupService: PopupService) {}

  openPopup(): void {
    this.showPopup = true;
  }

  @HostListener('keydown.escape', ['$event'])
  @HostListener('keydown.esc', ['$event']) // For IE11 coverage
  closePopup(): void {
    this.showPopup = false;
    this.popupService.focusActiveElement();
    this.showPopupChange.emit(this.showPopup);
  }
}
