import { LanguageCodes } from 'src/app/shared/enums';
import { images, TEST_IMAGE_TYPES } from '../images';
import {
  learningTargets,
  TEST_LEARNING_TARGET_TYPES,
} from '../learning-targets';
import { surveys, TEST_SURVEY_TYPES } from '../surveys';
import { videos, TEST_VIDEO_TYPES } from '../videos';
import { ActivityObjectTypes } from 'src/app/shared/enums/activity-object-types';

export const constReadingParams = {
  product: `Reading`,
  language: LanguageCodes.ENGLISH,
  skillName: 'Test Skill',
  thumbnail: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
  domain: { text: 'reading domain' },
  standard: { text: 'reading standard' },
  startScreenData: {
    learningTarget: [learningTargets[TEST_LEARNING_TARGET_TYPES.MULTI_CHOICE]],
    background_leftImage: images[TEST_IMAGE_TYPES.BG_CLOUD_LEFT],
    background_rightImage: images[TEST_IMAGE_TYPES.BG_CLOUD_RIGHT],
    foreground: images[TEST_IMAGE_TYPES.FG_CAT],
  },
  entrySurvey: [
    surveys[TEST_SURVEY_TYPES.SHORT],
    surveys[TEST_SURVEY_TYPES.MEDIUM],
    surveys[TEST_SURVEY_TYPES.LONG],
  ],
  videos: [videos[TEST_VIDEO_TYPES.PREP]],
  instructionsImage: images[TEST_IMAGE_TYPES.INSTRUCTION_1],
  activityManifest: [
    {
      type: ActivityObjectTypes.START_SCREEN,
    },
    {
      type: ActivityObjectTypes.ENTRY_SURVEY,
    },
    {
      type: ActivityObjectTypes.VIDEO,
      id: videos[TEST_VIDEO_TYPES.PREP].id,
    },
  ],
  characterLeanout: images[TEST_IMAGE_TYPES.CHARACTER_LEANOUT],
};
