import {
  KATEX_END_DELIM,
  KATEX_START_DELIM,
} from '../modules/math-text/utils/math-text-utils';
import { PopulationDragAndDrop } from '../shared/interfaces';
import { TEST_CHOICE_TYPES } from './choice-types';
import { TestAssociatedSuccessCriteria } from './test-associated-success-criteria.enum';

export const sortingBuckets: {
  [key: string]: PopulationDragAndDrop;
} = {
  [TEST_CHOICE_TYPES.TEST_R_DND_SORT_BUCKETS_SHORT]: {
    question: {
      text: 'Sort the numbers',
    },
    labels: [{ text: 'C1-C2' }, { text: 'C3-C4' }],
    answerChoices: [
      {
        id: 'c1',
        choice: {
          texts: [
            {
              text: 'C1',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "c1"' },
          secondIncorrect: { content: 'This is second feedback for "c1"' },
        },
      },
      {
        id: 'c2',
        choice: {
          texts: [
            {
              text: 'C2',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "c2"' },
          secondIncorrect: { content: 'This is second feedback for "c2"' },
        },
      },
      {
        id: 'c3',
        choice: {
          texts: [
            {
              text: 'C3',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "c3"' },
          secondIncorrect: { content: 'This is second feedback for "c3"' },
        },
      },
      {
        id: 'c4',
        choice: {
          texts: [
            {
              text: 'C4',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "c4"' },
          secondIncorrect: { content: 'This is second feedback for "c4"' },
        },
      },
      {
        id: 'w1',
        choice: {
          texts: [
            {
              text: 'W1',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "w1"' },
          secondIncorrect: { content: 'This is second feedback for "w1"' },
        },
      },
      {
        id: 'w2',
        choice: {
          texts: [
            {
              text: 'W2',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "w2"' },
          secondIncorrect: { content: 'This is second feedback for "w2"' },
        },
      },
    ],
    answers: [
      ['c1', 'c2'],
      ['c3', 'c4'],
    ],
    associatedSuccessCriteria: [TestAssociatedSuccessCriteria.DRAG_AND_DROP],
  },
  [TEST_CHOICE_TYPES.TEST_R_DND_SORT_BUCKETS_LONG]: {
    question: {
      text: 'Sort the numbers',
    },
    labels: [{ text: 'C1' }, { text: 'C2-C5' }, { text: 'C6-C7' }],
    answerChoices: [
      {
        id: 'c1',
        choice: {
          texts: [
            {
              text: 'C1',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "c1"' },
          secondIncorrect: { content: 'This is second feedback for "c1"' },
        },
      },
      {
        id: 'c2',
        choice: {
          texts: [
            {
              text: 'C2',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "c2"' },
          secondIncorrect: { content: 'This is second feedback for "c2"' },
        },
      },
      {
        id: 'c3',
        choice: {
          texts: [
            {
              text: 'C3',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "c3"' },
          secondIncorrect: { content: 'This is second feedback for "c3"' },
        },
      },
      {
        id: 'c4',
        choice: {
          texts: [
            {
              text: 'C4',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "c4"' },
          secondIncorrect: { content: 'This is second feedback for "c4"' },
        },
      },
      {
        id: 'c5',
        choice: {
          texts: [
            {
              text: 'C5',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "C5"' },
          secondIncorrect: { content: 'This is second feedback for "C5"' },
        },
      },
      {
        id: 'c6',
        choice: {
          texts: [
            {
              text: 'C6',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "C6"' },
          secondIncorrect: { content: 'This is second feedback for "C6"' },
        },
      },
      {
        id: 'c7',
        choice: {
          texts: [
            {
              text: 'C7',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "C7"' },
          secondIncorrect: { content: 'This is second feedback for "C7"' },
        },
      },
      {
        id: 'w1',
        choice: {
          texts: [
            {
              text: 'W1',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "W1"' },
          secondIncorrect: { content: 'This is second feedback for "W1"' },
        },
      },
    ],
    answers: [['c1'], ['c2', 'c3', 'c4', 'c5'], ['c6', 'c7']],
    associatedSuccessCriteria: [TestAssociatedSuccessCriteria.DRAG_AND_DROP],
  },
  test1: {
    question: {
      text: 'Sort the numbers',
    },
    labels: [{ text: 'Ones' }, { text: 'Tens' }],
    answerChoices: [
      {
        id: 'abc123',
        choice: {
          texts: [
            {
              text: 'two',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "two"' },
          secondIncorrect: { content: 'This is second feedback for "two"' },
        },
      },
      {
        id: 'abc456',
        choice: {
          texts: [
            {
              text: '123',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "123"' },
          secondIncorrect: { content: 'This is second feedback for "123"' },
        },
      },
      {
        id: 'abc789',
        choice: {
          texts: [
            {
              text: '26',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "26"' },
          secondIncorrect: { content: 'This is second feedback for "26"' },
        },
      },
      {
        id: 'abc10',
        choice: {
          texts: [
            {
              text: '999',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "999"' },
          secondIncorrect: { content: 'This is second feedback for "999"' },
        },
      },
      {
        id: 'abc17',
        choice: {
          texts: [
            {
              text: '123,456',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "123,456"' },
          secondIncorrect: { content: 'This is second feedback for "123,456"' },
        },
      },
      {
        id: 'abc11',
        choice: {
          texts: [
            {
              text: 'four',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "four"' },
          secondIncorrect: { content: 'This is second feedback for "four"' },
        },
      },
      {
        id: 'abc12',
        choice: {
          texts: [
            {
              text: '1,000,000',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "1,000,000"' },
          secondIncorrect: {
            content: 'This is second feedback for "1,000,000"',
          },
        },
      },
      {
        id: 'abc13',
        choice: {
          texts: [
            {
              text: '99',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "99"' },
          secondIncorrect: { content: 'This is second feedback for "99"' },
        },
      },
      {
        id: 'abc14',
        choice: {
          texts: [
            {
              text: '9',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "9"' },
          secondIncorrect: { content: 'This is second feedback for "9"' },
        },
      },
      {
        id: 'abc15',
        choice: {
          texts: [
            {
              text: '1,234,567',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "1,234,567"' },
          secondIncorrect: {
            content: 'This is second feedback for "1,234,567"',
          },
        },
      },
      {
        id: 'abc16',
        choice: {
          texts: [
            {
              text: '1,123',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "1,123"' },
          secondIncorrect: { content: 'This is second feedback for "1,123"' },
        },
      },
    ],
    answers: [
      ['abc123', 'abc11', 'abc14'],
      ['abc789', 'abc13'],
    ],
    associatedSuccessCriteria: [TestAssociatedSuccessCriteria.DRAG_AND_DROP],
  },
  test2: {
    question: {
      text: 'Sort the numbers',
    },
    labels: [{ text: 'Ones' }, { text: 'Tens' }, { text: 'Hundreds' }],
    answerChoices: [
      {
        id: 'abc123',
        choice: {
          texts: [
            {
              text: 'two',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "two"' },
          secondIncorrect: { content: 'This is second feedback for "two"' },
        },
      },
      {
        id: 'abc456',
        choice: {
          texts: [
            {
              text: '123',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "123"' },
          secondIncorrect: { content: 'This is second feedback for "123"' },
        },
      },
      {
        id: 'abc789',
        choice: {
          texts: [
            {
              text: '26',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "26"' },
          secondIncorrect: { content: 'This is second feedback for "26"' },
        },
      },
      {
        id: 'abc10',
        choice: {
          texts: [
            {
              text: '999',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "999"' },
          secondIncorrect: { content: 'This is second feedback for "999"' },
        },
      },
      {
        id: 'abc17',
        choice: {
          texts: [
            {
              text: '123,456',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "123,456"' },
          secondIncorrect: { content: 'This is second feedback for "123,456"' },
        },
      },
      {
        id: 'abc11',
        choice: {
          texts: [
            {
              text: 'four',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "four"' },
          secondIncorrect: { content: 'This is second feedback for "four"' },
        },
      },
      {
        id: 'abc12',
        choice: {
          texts: [
            {
              text: '1,000,000',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "1,000,000"' },
          secondIncorrect: {
            content: 'This is second feedback for "1,000,000"',
          },
        },
      },
      {
        id: 'abc13',
        choice: {
          texts: [
            {
              text: '99',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "99"' },
          secondIncorrect: { content: 'This is second feedback for "99"' },
        },
      },
      {
        id: 'abc14',
        choice: {
          texts: [
            {
              text: '9',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "9"' },
          secondIncorrect: { content: 'This is second feedback for "9"' },
        },
      },
      {
        id: 'abc15',
        choice: {
          texts: [
            {
              text: '1,234,567',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "1,234,567"' },
          secondIncorrect: {
            content: 'This is second feedback for "1,234,567"',
          },
        },
      },
      {
        id: 'abc16',
        choice: {
          texts: [
            {
              text: '1,123',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "1,123"' },
          secondIncorrect: { content: 'This is second feedback for "1,123"' },
        },
      },
    ],
    answers: [
      ['abc123', 'abc11', 'abc14'],
      ['abc789', 'abc13'],
      ['abc456', 'abc10'],
    ],
    associatedSuccessCriteria: [TestAssociatedSuccessCriteria.DRAG_AND_DROP],
  },
  test3: {
    question: {
      text: 'Sort the numbers',
    },
    labels: [
      { text: 'Ones' },
      { text: 'Tens' },
      { text: 'Hundreds' },
      { text: 'Thousands' },
    ],
    answerChoices: [
      {
        id: 'abc123',
        choice: {
          texts: [
            {
              text: 'two',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "two"' },
          secondIncorrect: { content: 'This is second feedback for "two"' },
        },
      },
      {
        id: 'abc456',
        choice: {
          texts: [
            {
              text: '123',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "123"' },
          secondIncorrect: { content: 'This is second feedback for "123"' },
        },
      },
      {
        id: 'abc789',
        choice: {
          texts: [
            {
              text: '26',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "26"' },
          secondIncorrect: { content: 'This is second feedback for "26"' },
        },
      },
      {
        id: 'abc10',
        choice: {
          texts: [
            {
              text: '999',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "999"' },
          secondIncorrect: { content: 'This is second feedback for "999"' },
        },
      },
      {
        id: 'abc17',
        choice: {
          texts: [
            {
              text: '123,456',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "123,456"' },
          secondIncorrect: { content: 'This is second feedback for "123,456"' },
        },
      },
      {
        id: 'abc11',
        choice: {
          texts: [
            {
              text: 'four',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "four"' },
          secondIncorrect: { content: 'This is second feedback for "four"' },
        },
      },
      {
        id: 'abc12',
        choice: {
          texts: [
            {
              text: '1,000,000',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "1,000,000"' },
          secondIncorrect: {
            content: 'This is second feedback for "1,000,000"',
          },
        },
      },
      {
        id: 'abc13',
        choice: {
          texts: [
            {
              text: '99',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "99"' },
          secondIncorrect: { content: 'This is second feedback for "99"' },
        },
      },
      {
        id: 'abc14',
        choice: {
          texts: [
            {
              text: '9',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "9"' },
          secondIncorrect: { content: 'This is second feedback for "9"' },
        },
      },
      {
        id: 'abc15',
        choice: {
          texts: [
            {
              text: '1,234,567',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "1,234,567"' },
          secondIncorrect: {
            content: 'This is second feedback for "1,234,567"',
          },
        },
      },
      {
        id: 'abc16',
        choice: {
          texts: [
            {
              text: '1,123',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "1,123"' },
          secondIncorrect: { content: 'This is second feedback for "1,123"' },
        },
      },
    ],
    answers: [
      ['abc123', 'abc11', 'abc14'],
      ['abc789', 'abc13'],
      ['abc456', 'abc10'],
      ['abc16', 'abc17'],
    ],
    associatedSuccessCriteria: [TestAssociatedSuccessCriteria.DRAG_AND_DROP],
  },
  [TEST_CHOICE_TYPES.TEST_R_DND_SORT_BUCKETS_KATEX]: {
    question: {
      text: `What if I asked you about ${KATEX_START_DELIM}\frac{a}{b}${KATEX_END_DELIM}?`,
    },
    labels: [
      {
        text: `C1-C2 with fraction: ${KATEX_START_DELIM}\frac{a}{b}${KATEX_END_DELIM}`,
      },
      { text: 'C3-C4' },
    ],
    answerChoices: [
      {
        id: 'c1',
        choice: {
          texts: [
            {
              text: `C1 ${KATEX_START_DELIM}\frac{a}{b}${KATEX_END_DELIM}`,
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "c1"' },
          secondIncorrect: { content: 'This is second feedback for "c1"' },
        },
      },
      {
        id: 'c2',
        choice: {
          texts: [
            {
              text: 'C2',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "c2"' },
          secondIncorrect: { content: 'This is second feedback for "c2"' },
        },
      },
      {
        id: 'c3',
        choice: {
          texts: [
            {
              text: 'C3',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "c3"' },
          secondIncorrect: { content: 'This is second feedback for "c3"' },
        },
      },
      {
        id: 'c4',
        choice: {
          texts: [
            {
              text: 'C4',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "c4"' },
          secondIncorrect: { content: 'This is second feedback for "c4"' },
        },
      },
      {
        id: 'w1',
        choice: {
          texts: [
            {
              text: 'W1',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "w1"' },
          secondIncorrect: { content: 'This is second feedback for "w1"' },
        },
      },
      {
        id: 'w2',
        choice: {
          texts: [
            {
              text: 'W2',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "w2"' },
          secondIncorrect: { content: 'This is second feedback for "w2"' },
        },
      },
    ],
    answers: [
      ['c1', 'c2'],
      ['c3', 'c4'],
    ],
    associatedSuccessCriteria: [TestAssociatedSuccessCriteria.DRAG_AND_DROP],
  },
};
