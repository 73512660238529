import { Component, ElementRef, ViewChild } from '@angular/core';
import { IncompleteActivityState } from 'src/app/modules/activity-save-state';

import { DebugService } from 'src/app/modules/debug/services/debug/debug.service';
import { ActivityService } from 'src/app/shared/services';

@Component({
  selector: 'htc-incomplete-save-data-export-import',
  templateUrl: './incomplete-save-data-export-import.component.html',
  styleUrls: ['./incomplete-save-data-export-import.component.scss'],
})
export class IncompleteSaveDataExportImportComponent {
  exportToClipboard = false;
  importText = '';
  importFile?: File;
  importedActivityState?: IncompleteActivityState;
  parseError = '';
  launchingActivity = false;

  @ViewChild('uploadSaveDataJson', { static: true })
  uploadInput!: ElementRef;

  constructor(
    public debugService: DebugService,
    private activityService: ActivityService
  ) {}

  onTextAreaChange(): void {
    if (!this.importFile) {
      this.importedActivityState = undefined;
    }
  }

  clearFile(): void {
    if (this.uploadInput) {
      this.uploadInput.nativeElement.value = null;
    }
    this.importFile = undefined;
    this.importText = '';
    this.importedActivityState = undefined;
  }

  onFileSelected(event: Event): void {
    this.importText = '';
    this.importedActivityState = undefined;
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];
    this.importFile = file;
    this.import();
  }

  async import(): Promise<void> {
    if (this.importFile && this.importText.length > 0) {
      alert(
        `You've both selected a file as well as entered something in the import text area. Please remove the text, or clear the file before proceeding.`
      );
      return;
    } else if (this.importFile) {
      this.importText = await this.importFile.text();
    }
    console.log('about to parse', this.importText);
    let parsed;
    try {
      parsed = JSON.parse(this.importText);
    } catch (err) {
      alert('failed to parse the import text');
      return;
    }
    if (this.isActivityState(parsed)) {
      this.importedActivityState = parsed as IncompleteActivityState;
    } else {
      alert('The passed in json data is invalid');
      this.clearFile();
      this.importText = '';
    }
  }

  launch(): void {
    if (!this.importedActivityState || this.launchingActivity) {
      return;
    }
    this.launchingActivity = true;
    this.activityService
      .fetchActivity(this.importedActivityState.id)
      .subscribe({
        next: () => {
          console.log('launching activity');
          this.activityService.resumeActivityFromState(
            this.importedActivityState as IncompleteActivityState
          );
          this.debugService.showDebugMenu.next(false);
          this.debugService.showSaveDataImportMenu.next(false);
          this.launchingActivity = false;
        },
        error: err => {
          alert(
            'couldnt find activity associated with the save data provided' + err
          );
        },
      });
  }

  export(): void {
    if (!this.activityService.currActivity) {
      alert('No activity in progress to save');
      return;
    }
    const activityState = this.activityService.exportIncompleteActivityState();
    this.debugService.exportActivityState(
      this.exportToClipboard,
      activityState
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private isActivityState(activityState: any): boolean {
    const activityKeys = [
      'id',
      'currentStep',
      'numPerfect',
      'numCorrect',
      'numIncorrect',
      'questionNumber',
      'answerStates',
      'populationResponses',
      'progressNodes',
      'elapsedTime',
    ];
    let valid = true;
    const keysInState = Object.keys(activityState);

    activityKeys.forEach(key => {
      if (!keysInState.includes(key)) {
        console.log('keysInState doesnt have', key); //left for debug purposes in dev
        valid = false;
      }
    });
    return valid;
  }
}
