import {
  KATEX_END_DELIM,
  KATEX_START_DELIM,
} from '../modules/math-text/utils/math-text-utils';
import { LearningTarget } from './../shared/interfaces/';
import { TestAssociatedSuccessCriteria } from './test-associated-success-criteria.enum';

export enum TEST_LEARNING_TARGET_TYPES {
  MULTI_CHOICE,
  MULTI_SELECT,
  INLINE_CHOICE,
  TEXT_ENTRY,
  SHORT_CONSTRUCTED_RESPONSE,
  SHORT_CONSTRUCTED_RESPONSE_WITH_RUBRIC,
  EXTENDED_CONSTRUCTED_RESPONSE,
  EXTENDED_CONSTRUCTED_RESPONSE_WITH_RUBRIC,
  DRAG_AND_DROP,
  MIX_1,
  ES_MULTI_CHOICE,
  ES_MULTI_SELECT,
  ES_INLINE_CHOICE,
  ES_TEXT_ENTRY,
  ES_SHORT_CONSTRUCTED_RESPONSE,
  ES_SHORT_CONSTRUCTED_RESPONSE_WITH_RUBRIC,
  ES_EXTENDED_CONSTRUCTED_RESPONSE,
  ES_EXTENDED_CONSTRUCTED_RESPONSE_WITH_RUBRIC,
  ES_DRAG_AND_DROP,
  ES_MIX_1,
  MATH_SHORT_CONSTRUCTED_RESPONSE,
  MATH_SHORT_CONSTRUCTED_RESPONSE_WITH_RUBRIC,
  MATH_EXTENDED_CONSTRUCTED_RESPONSE,
  MATH_EXTENDED_CONSTRUCTED_RESPONSE_WITH_RUBRIC,
  MULTIPLE_VIDEOS,
  MULTIPLE_TRIES,
}

export const learningTargets: { [key: number]: LearningTarget } = {
  [TEST_LEARNING_TARGET_TYPES.MULTI_CHOICE]: {
    id: TestAssociatedSuccessCriteria.MULTI_CHOICE,
    learningTarget: {
      text: 'to test fully the multichoice variants',
    },
    successCriteria: [
      {
        id: TestAssociatedSuccessCriteria.MULTI_CHOICE,
        text: 'test fully the multichoice without images variants',
      },
      {
        id: TestAssociatedSuccessCriteria.MULTI_CHOICE_IMAGE,
        text: 'test fully the multichoice with images variants',
      },
    ],
  },
  [TEST_LEARNING_TARGET_TYPES.MULTI_SELECT]: {
    id: TestAssociatedSuccessCriteria.MULTI_SELECT,
    learningTarget: {
      text: 'to test fully the multiselect variants',
    },
    successCriteria: [
      {
        id: TestAssociatedSuccessCriteria.MULTI_SELECT,
        text: 'test fully the multiselect without images variants',
      },
      {
        id: TestAssociatedSuccessCriteria.MULTI_SELECT_IMAGE,
        text: 'test fully the multiselect with images variants',
      },
      {
        id: 'blah',
        text: 'extra success criteria that should have 0 questions associated with it cause just for testing',
      },
    ],
  },
  [TEST_LEARNING_TARGET_TYPES.TEXT_ENTRY]: {
    id: TestAssociatedSuccessCriteria.TEXT_ENTRY,
    learningTarget: {
      text: 'to test fully the text entry variants learning target',
    },
    successCriteria: [
      {
        id: TestAssociatedSuccessCriteria.TEXT_ENTRY,
        text: 'test fully the text entry variants success criteria',
      },
    ],
  },
  [TEST_LEARNING_TARGET_TYPES.INLINE_CHOICE]: {
    id: TestAssociatedSuccessCriteria.INLINE_CHOICE,
    learningTarget: {
      text: 'to test fully the inline choice variants learning target',
    },
    successCriteria: [
      {
        id: TestAssociatedSuccessCriteria.INLINE_CHOICE,
        text: 'test fully the inline choice variants success criteria',
      },
    ],
  },
  [TEST_LEARNING_TARGET_TYPES.SHORT_CONSTRUCTED_RESPONSE]: {
    id: TestAssociatedSuccessCriteria.SHORT_CONST_RESPONSE,
    learningTarget: {
      text: 'to test fully the short constructed response learning target',
    },
    successCriteria: [
      {
        id: TestAssociatedSuccessCriteria.SHORT_CONST_RESPONSE,
        text: 'test fully the short constructed response variants success criteria',
      },
    ],
  },
  [TEST_LEARNING_TARGET_TYPES.SHORT_CONSTRUCTED_RESPONSE_WITH_RUBRIC]: {
    id: TestAssociatedSuccessCriteria.SHORT_CONST_RESPONSE_RUBRIC,
    learningTarget: {
      text: 'to test fully the short constructed response rubric variants learning target',
    },
    successCriteria: [
      {
        id: TestAssociatedSuccessCriteria.SHORT_CONST_RESPONSE_RUBRIC,
        text: 'test fully the short constructed response rubric variants success criteria',
      },
    ],
  },
  [TEST_LEARNING_TARGET_TYPES.EXTENDED_CONSTRUCTED_RESPONSE]: {
    id: TestAssociatedSuccessCriteria.EXTENDED_CONST_RESPONSE,
    learningTarget: {
      text: 'to test fully the extended constructed response learning target',
    },
    successCriteria: [
      {
        id: TestAssociatedSuccessCriteria.EXTENDED_CONST_RESPONSE,
        text: 'test fully the extended constructed response variants success criteria',
      },
    ],
  },
  [TEST_LEARNING_TARGET_TYPES.EXTENDED_CONSTRUCTED_RESPONSE_WITH_RUBRIC]: {
    id: TestAssociatedSuccessCriteria.EXTENDED_CONST_RESPONSE_RUBRIC,
    learningTarget: {
      text: 'to test fully the extended constructed response rubric variants learning target',
    },
    successCriteria: [
      {
        id: TestAssociatedSuccessCriteria.EXTENDED_CONST_RESPONSE_RUBRIC,
        text: 'test fully the extended constructed response rubric variants success criteria',
      },
    ],
  },
  [TEST_LEARNING_TARGET_TYPES.DRAG_AND_DROP]: {
    id: TestAssociatedSuccessCriteria.DRAG_AND_DROP,
    learningTarget: {
      text: 'to test fully the drag and drop learning target',
    },
    successCriteria: [
      {
        id: TestAssociatedSuccessCriteria.DRAG_AND_DROP,
        text: 'test fully the drag and drop success criteria',
      },
    ],
  },
  [TEST_LEARNING_TARGET_TYPES.MIX_1]: {
    id: 'mix_1',
    learningTarget: {
      text: `to test fully an activity with a variety ${KATEX_START_DELIM}\frac{a}{b}${KATEX_END_DELIM} of 10 populations`,
    },
    successCriteria: [
      {
        id: TestAssociatedSuccessCriteria.MULTI_CHOICE,
        text: 'test fully the multichoice without images variants',
      },
      {
        id: TestAssociatedSuccessCriteria.MULTI_CHOICE_IMAGE,
        text: 'test fully the multichoice with images variants',
      },
      {
        id: TestAssociatedSuccessCriteria.MULTI_SELECT,
        text: 'test fully the multiselect without images variants',
      },
      {
        id: TestAssociatedSuccessCriteria.MULTI_SELECT_IMAGE,
        text: 'test fully the multiselect with images variants',
      },
      {
        id: TestAssociatedSuccessCriteria.INLINE_CHOICE,
        text: 'test fully the inline choice variants success criteria',
      },
      {
        id: TestAssociatedSuccessCriteria.TEXT_ENTRY,
        text: 'test fully the text entry variants success criteria',
      },
      {
        id: TestAssociatedSuccessCriteria.SHORT_CONST_RESPONSE_RUBRIC,
        text: 'test fully the short constructed response rubric variants success criteria',
      },
    ],
  },
  [TEST_LEARNING_TARGET_TYPES.ES_MULTI_CHOICE]: {
    id: 'es_multichoice',
    learningTarget: {
      text: 'para probar completamente las variantes de opción múltiple',
    },
    successCriteria: [
      {
        id: TestAssociatedSuccessCriteria.MULTI_CHOICE,
        text: `pruebe completamente la opción múltiple sin variantes de imágenes ${KATEX_START_DELIM}\frac{2}{3}kxe${KATEX_END_DELIM}`,
      },
      {
        id: TestAssociatedSuccessCriteria.MULTI_CHOICE_IMAGE,
        text: 'pruebe completamente la opción múltiple con variantes de imágenes',
      },
    ],
  },
  [TEST_LEARNING_TARGET_TYPES.ES_MULTI_SELECT]: {
    id: 'es_multiselect',
    learningTarget: {
      text: 'para probar completamente las variantes multiselección',
    },
    successCriteria: [
      {
        id: TestAssociatedSuccessCriteria.MULTI_SELECT,
        text: 'prueba completamente la multiselección sin variantes de imágenes',
      },
      {
        id: TestAssociatedSuccessCriteria.MULTI_SELECT_IMAGE,
        text: 'pruebe completamente la selección múltiple con variantes de imágenes',
      },
      {
        id: 'blah',
        text: 'criterios de éxito adicionales que deberían tener 0 preguntas asociadas porque son solo para pruebas',
      },
    ],
  },
  [TEST_LEARNING_TARGET_TYPES.ES_TEXT_ENTRY]: {
    id: 'es_textentry',
    learningTarget: {
      text: 'probar completamente el objetivo de aprendizaje de las variantes de entrada de texto',
    },
    successCriteria: [
      {
        id: TestAssociatedSuccessCriteria.TEXT_ENTRY,
        text: 'probar completamente los criterios de éxito de las variantes de entrada de texto',
      },
    ],
  },
  [TEST_LEARNING_TARGET_TYPES.ES_INLINE_CHOICE]: {
    id: 'es_inlinechoice',
    learningTarget: {
      text: 'probar completamente el objetivo de aprendizaje de variantes de elección en línea',
    },
    successCriteria: [
      {
        id: TestAssociatedSuccessCriteria.INLINE_CHOICE,
        text: 'probar completamente los criterios de éxito de las variantes de elección en línea',
      },
    ],
  },
  [TEST_LEARNING_TARGET_TYPES.ES_SHORT_CONSTRUCTED_RESPONSE]: {
    id: 'es_shortconstructedresponse',
    learningTarget: {
      text: 'probar completamente el objetivo de aprendizaje de respuesta corta construida',
    },
    successCriteria: [
      {
        id: TestAssociatedSuccessCriteria.SHORT_CONST_RESPONSE,
        text: 'probar completamente los criterios de éxito de las variantes de respuesta cortas construidas',
      },
    ],
  },
  [TEST_LEARNING_TARGET_TYPES.ES_SHORT_CONSTRUCTED_RESPONSE_WITH_RUBRIC]: {
    id: 'es_shortconstructedresponserubric',
    learningTarget: {
      text: 'probar completamente las variantes de la rúbrica de respuesta corta construida objetivo de aprendizaje',
    },
    successCriteria: [
      {
        id: TestAssociatedSuccessCriteria.SHORT_CONST_RESPONSE_RUBRIC,
        text: 'probar completamente los criterios de éxito de las variantes de la rúbrica de respuesta corta construida',
      },
    ],
  },
  [TEST_LEARNING_TARGET_TYPES.ES_EXTENDED_CONSTRUCTED_RESPONSE]: {
    id: 'es_extendedconstructedresponse',
    learningTarget: {
      text: 'probar completamente el objetivo de aprendizaje de respuesta construida extendida',
    },
    successCriteria: [
      {
        id: TestAssociatedSuccessCriteria.EXTENDED_CONST_RESPONSE,
        text: 'probar completamente los criterios de éxito de las variantes de respuesta construida extendida',
      },
    ],
  },
  [TEST_LEARNING_TARGET_TYPES.ES_EXTENDED_CONSTRUCTED_RESPONSE_WITH_RUBRIC]: {
    id: 'es_extendedconstructedresponserubric',
    learningTarget: {
      text: 'probar completamente el objetivo de aprendizaje de las variantes de la rúbrica de respuesta construida extendida',
    },
    successCriteria: [
      {
        id: TestAssociatedSuccessCriteria.EXTENDED_CONST_RESPONSE_RUBRIC,
        text: 'probar completamente los criterios de éxito de las variantes de la rúbrica de respuesta construida extendida',
      },
    ],
  },
  [TEST_LEARNING_TARGET_TYPES.ES_DRAG_AND_DROP]: {
    id: 'es_draganddrop',
    learningTarget: {
      text: 'para probar completamente el objetivo de aprendizaje de arrastrar y soltar',
    },
    successCriteria: [
      {
        id: 'es_draganddrop',
        text: 'pruebe completamente los criterios de éxito de arrastrar y soltar.',
      },
    ],
  },
  [TEST_LEARNING_TARGET_TYPES.ES_MIX_1]: {
    id: 'es_mix_1',
    learningTarget: {
      text: 'probar completamente una actividad con una variedad de 10 poblaciones',
    },
    successCriteria: [
      {
        id: TestAssociatedSuccessCriteria.MULTI_CHOICE,
        text: 'pruebe completamente la opción múltiple sin variantes de imágenes',
      },
      {
        id: TestAssociatedSuccessCriteria.MULTI_CHOICE_IMAGE,
        text: 'pruebe completamente la opción múltiple con variantes de imágenes',
      },
      {
        id: TestAssociatedSuccessCriteria.MULTI_SELECT,
        text: 'prueba completamente la multiselección sin variantes de imágenes',
      },
      {
        id: TestAssociatedSuccessCriteria.MULTI_SELECT_IMAGE,
        text: 'pruebe completamente la selección múltiple con variantes de imágenes',
      },
      {
        id: TestAssociatedSuccessCriteria.INLINE_CHOICE,
        text: 'probar completamente los criterios de éxito de las variantes de elección en línea',
      },
      {
        id: TestAssociatedSuccessCriteria.TEXT_ENTRY,
        text: 'probar completamente los criterios de éxito de las variantes de entrada de texto',
      },
    ],
  },
  [TEST_LEARNING_TARGET_TYPES.MATH_SHORT_CONSTRUCTED_RESPONSE]: {
    id: TestAssociatedSuccessCriteria.MATH_SHORT_CONSTRUCTED_RESPONSE,
    learningTarget: {
      text: 'to test fully the math short constructed response learning target',
    },
    successCriteria: [
      {
        id: TestAssociatedSuccessCriteria.MATH_SHORT_CONSTRUCTED_RESPONSE,
        text: 'test fully the math short constructed response variants success criteria',
      },
    ],
  },
  [TEST_LEARNING_TARGET_TYPES.MATH_SHORT_CONSTRUCTED_RESPONSE_WITH_RUBRIC]: {
    id: TestAssociatedSuccessCriteria.MATH_SHORT_CONSTRUCTED_RESPONSE_WITH_RUBRIC,
    learningTarget: {
      text: 'to test fully the math short constructed response rubric variants learning target',
    },
    successCriteria: [
      {
        id: TestAssociatedSuccessCriteria.MATH_SHORT_CONSTRUCTED_RESPONSE_WITH_RUBRIC,
        text: 'test fully the math short constructed response rubric variants success criteria',
      },
    ],
  },
  [TEST_LEARNING_TARGET_TYPES.MATH_EXTENDED_CONSTRUCTED_RESPONSE]: {
    id: TestAssociatedSuccessCriteria.MATH_EXTENDED_CONSTRUCTED_RESPONSE,
    learningTarget: {
      text: 'to test fully the math extended constructed response learning target',
    },
    successCriteria: [
      {
        id: TestAssociatedSuccessCriteria.MATH_EXTENDED_CONSTRUCTED_RESPONSE,
        text: 'test fully the math extended constructed response variants success criteria',
      },
    ],
  },
  [TEST_LEARNING_TARGET_TYPES.MATH_EXTENDED_CONSTRUCTED_RESPONSE_WITH_RUBRIC]: {
    id: TestAssociatedSuccessCriteria.MATH_EXTENDED_CONSTRUCTED_RESPONSE_WITH_RUBRIC,
    learningTarget: {
      text: 'to test fully the math extended constructed response rubric variants learning target',
    },
    successCriteria: [
      {
        id: TestAssociatedSuccessCriteria.MATH_EXTENDED_CONSTRUCTED_RESPONSE_WITH_RUBRIC,
        text: 'test fully the math extended constructed response rubric variants success criteria',
      },
    ],
  },
  [TEST_LEARNING_TARGET_TYPES.MULTIPLE_VIDEOS]: {
    id: TestAssociatedSuccessCriteria.MULTIPLE_VIDEOS,
    learningTarget: {
      text: 'to test multiple videos in one activity',
    },
    successCriteria: [
      {
        id: TestAssociatedSuccessCriteria.MULTIPLE_VIDEOS,
        text: 'test fully the multiple videos in one activity',
      },
    ],
  },
  [TEST_LEARNING_TARGET_TYPES.MULTIPLE_TRIES]: {
    id: TestAssociatedSuccessCriteria.MULTIPLE_TRIES,
    learningTarget: {
      text: 'to test fully the 3 attempt variants',
    },
    successCriteria: [
      {
        id: TestAssociatedSuccessCriteria.MULTI_CHOICE,
        text: 'fail to answer questions correctly more than once',
      },
    ],
  },
};
