import {
  Component,
  Input,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PopulationService } from '../../services/population/population.service';
import { CarouselComponent } from 'src/app/modules/carousel/components/carousel/carousel.component';
import { SlideTypeEnum } from 'src/app/modules/carousel/enums/slide-type';
import { ISlideData } from 'src/app/modules/carousel/interfaces';
import { Subscription } from 'rxjs';
import { Feedback } from './feedback.interface';

@Component({
  selector: 'htc-feedback',
  templateUrl: './feedback.component.html',
})
export class FeedbackComponent implements OnDestroy {
  @Input() showAlert = false;
  @Input() showDiamond = false;
  @Input() alertHeaderText = '';
  @Input() incorrectAttempts = 0;
  @Input() feedbackText = '';
  @Input() alertType = 'default';
  @Input() alertClasses = 'feedback';
  @ViewChild('feedback', { static: true, read: ViewContainerRef })
  feedbackViewContainer!: ViewContainerRef;

  content: ISlideData[] = [];
  sub = new Subscription();

  constructor(
    private translate: TranslateService,
    private populationService: PopulationService
  ) {}

  setHeaderText(text: string): void {
    this.sub.add(
      this.translate.get(text).subscribe((translated: string) => {
        this.alertHeaderText = translated;
      })
    );
  }

  setFeedbackText(text: string): void {
    this.sub.add(
      this.translate.get(text).subscribe((translated: string) => {
        this.feedbackText = translated;
      })
    );
  }

  loadComponents(): void {
    const feedbackCompRef =
      this.feedbackViewContainer.createComponent(CarouselComponent);
    feedbackCompRef.setInput('content', this.content);
  }

  setShowAlert(show: boolean): void {
    this.showAlert = show;
  }

  showCompleteAlert(): void {
    this.setFeedbackText('FEEDBACK.FEEDBACK_COMPLETE');
    this.setHeaderText('FEEDBACK.HEADER_COMPLETE');
    this.setFeedbackContent([]);
    this.setAlertClass(true);
    this.handleShowAlert('default', true);
  }

  showPerfectAlert(): void {
    this.setFeedbackText('FEEDBACK.FEEDBACK_CORRECT');
    this.setHeaderText('FEEDBACK.HEADER_CORRECT');
    this.setFeedbackContent([]);
    this.setAlertClass(true);
    this.handleShowAlert('default', true);
  }

  showFirstIncorrectAlert(): void {
    const feedbacks = this.populationService.getFirstIncorrectFeedback();
    this.setHeaderText('FEEDBACK.HEADER_TRY_AGAIN');
    this.setFeedbackContent(feedbacks);
    this.setAlertClass(false);
    this.handleShowAlert();
  }

  showSecondIncorrectAlert(): void {
    const feedbacks = this.populationService.getSecondIncorrectFeedback();
    this.setHeaderText('FEEDBACK.HEADER_INCORRECT');
    this.setFeedbackContent(feedbacks);
    this.setAlertClass(false);
    this.handleShowAlert();
  }

  showIncompleteAlert(): void {
    this.setFeedbackText('FEEDBACK.INCOMPLETE_ANSWER');
    this.setHeaderText('FEEDBACK.HEADER_TRY_AGAIN');
    this.setFeedbackContent([]);
    this.setAlertClass(false);
    this.handleShowAlert();
  }

  resetFeedback(): void {
    this.showAlert = false;
    this.showDiamond = false;
    this.alertHeaderText = '';
    this.incorrectAttempts = 0;
    this.feedbackText = '';
    this.alertType = 'default';
  }

  setAlertClass(isCorrect: boolean): void {
    // do this way to reset animation
    this.alertClasses = 'feedback ';
    this.alertClasses += `${isCorrect ? 'correct' : 'incorrect'}`;
  }

  private setFeedbackContent(feedbacks: Feedback[]): void {
    if (
      feedbacks.every(
        feedback =>
          (feedback.content === undefined || feedback.content === '') &&
          (feedback.chartUrl === undefined || feedback.chartUrl === '')
      ) ||
      feedbacks.length === 0
    ) {
      this.content = [];
    } else {
      this.content = feedbacks.map((feedback, i) => {
        if (feedback.chartUrl) {
          return {
            id: i.toString(),
            data: {
              title: feedback.title,
              chartData: {
                data: feedback.chartUrl,
                isUrl: true,
              },
            },
            type: SlideTypeEnum.chartSlide,
          };
        } else {
          return {
            id: i.toString(),
            data: {
              title: feedback.content,
            },
            type: SlideTypeEnum.basicSlide,
          };
        }
      });
    }
  }

  private handleShowAlert(alertType = 'error', showDiamond = false): void {
    this.alertType = alertType;
    this.toggleAlert();
    this.showDiamond = showDiamond;
  }

  private toggleAlert(): void {
    this.feedbackViewContainer.clear();

    if (this.content.length > 0) {
      this.loadComponents();
    }

    if (this.feedbackText !== '' || this.content.length > 0) {
      this.showAlert = true;
    } else {
      this.showAlert = false;
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
