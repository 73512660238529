<div id="transcript-container">
  <p
    id="transcript-title"
    #transcriptTitle
    data-testid="htc-vp-transcript-title">
    <b>{{ transcriptPrompt }}</b>
  </p>
  <ol
    id="transcript-text"
    #transcriptText
    data-testid="transcript-scroller"
    (scroll)="onScroll()"
    tabindex="0"
    [attr.aria-labelledby]="'TRANSCRIPT.LISTBOX_TRANSCRIPT' | translate"
    role="listbox"
    [attr.aria-activedescendant]="
      'listbox-transcript-' + (focusedTranscriptIndex + 1)
    "
    data-testid="htc-vp-transcript-ol">
    <li
      *ngFor="let line of transcript; let i = index"
      class="transcript-line"
      (click)="onTranLine(line)"
      [ngClass]="
        focusedTranscriptIndex === i
          ? 'focused'
          : maxTranscriptIndex < i
            ? 'disabled'
            : ''
      "
      [id]="'listbox-transcript-' + (i + 1)"
      [attr.aria-selected]="focusedTranscriptIndex === i"
      [attr.disabled]="maxTranscriptIndex < i ? true : null"
      [attr.aria-disabled]="maxTranscriptIndex < i"
      role="option"
      [attr.data-testid]="'htc-vp-transcript-li-' + i">
      @for (text of line.texts; track $index) {
        <htc-math-text-wrapper
          [attr.aria-label]="
            text | htcMathToSpeech: (sres.lastAddedMapKey | async)
          "
          [textInput]="
            line.texts.length > 1 ? text + ' ' : text
          "></htc-math-text-wrapper>
      }
    </li>
  </ol>
</div>
