import {
  Component,
  ElementRef,
  Input,
  AfterViewInit,
  ViewChild,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import { VideoService } from '../../services/video.service';
import { FullscreenService } from '../../services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'htc-video-js-player',
  templateUrl: './video-js-player.component.html',
  styleUrls: ['./video-js-player.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VideoJsPlayerComponent implements AfterViewInit, OnDestroy {
  @ViewChild('videoTarget', { static: true }) videoTarget?: ElementRef;
  @Input() showTranscript = false;
  @Input() showCaptions = false;
  @Input() autofocus = true;
  @Input() isPopup = false;
  iconPath = '/assets/video-control-icons/';
  sub = new Subscription();

  constructor(
    private videoService: VideoService,
    private fullscreenService: FullscreenService
  ) {}

  ngAfterViewInit(): void {
    if (this.videoTarget) {
      this.videoService.Target = this.videoTarget.nativeElement;
    }

    this.sub.add(
      this.fullscreenService.fullscreen$.subscribe(isFullscreen => {
        this.handleFullscreenClass(isFullscreen ?? false);
      })
    );
  }

  handleFullscreenClass(isFullscreen: boolean): void {
    if (this.videoTarget) {
      const el = <Element>this.videoTarget.nativeElement;
      const hasFullscreenClass = el.classList.contains('fullscreen');
      if (isFullscreen && !hasFullscreenClass) {
        el.classList.add('fullscreen');
      } else if (!isFullscreen && hasFullscreenClass) {
        el.classList.remove('fullscreen');
      }
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
