import { Component } from '@angular/core';
import { SlideBaseComponent } from '../slide-base/slide-base.component';
import { SlideTypeEnum } from '../../enums/slide-type';
import { ChartService } from 'src/app/modules/chart/services/chart.service';
import { SpeechRuleEngineService } from 'src/app/modules/math-text/services/speech-rule-engine.service';

@Component({
  selector: 'htc-slide-chart',
  templateUrl: './slide-chart.component.html',
  styleUrls: ['./slide-chart.component.scss'],
})
export class SlideChartComponent extends SlideBaseComponent {
  isChartOpen = false;
  type = SlideTypeEnum.chartSlide;

  constructor(
    private chartService: ChartService,
    public sres: SpeechRuleEngineService
  ) {
    super();
  }

  openChart(): void {
    if (this.data.chartData) {
      this.chartService.openChart(this.data.chartData);
    }
  }

  onKeyPressed(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ' ') {
      event.stopPropagation();
      this.openChart();
    }
  }
}
