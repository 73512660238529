import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ISlideData } from 'src/app/modules/carousel/interfaces';
import { PopulationService } from 'src/app/shared/services/population/population.service';
import { ITool } from '../../interfaces/tool.interface';
import { ToolsService } from '../../services/tools.service';
import { ActivityService } from 'src/app/shared/services';

@Component({
  selector: 'htc-hint-popup',
  templateUrl: './hint-popup.component.html',
})
export class HintPopupComponent implements OnInit, OnDestroy, ITool {
  @Input() id!: number;
  @Input() toolName!: string;
  @Input() icon!: string;
  @Input() toggledIcon?: string | undefined;
  @Input() testId = '';
  hintHeaderText = '';
  content: ISlideData[] = [];
  isHidden = true;

  constructor(
    private activityService: ActivityService,
    private toolsService: ToolsService,
    private populationService: PopulationService
  ) {}

  ngOnInit(): void {
    const newContent = this.populationService.getCurrentPopulationHints();
    if (newContent.length !== 0) {
      this.content = newContent;
    }
    this.isHidden = false;
  }

  onNewHint(): void {
    this.activityService.submitActivityData(true);
  }

  onAllHintsSeen(): void {
    if (!this.populationService.isAllHintsSeen.getValue()) {
      this.populationService.isAllHintsSeen.next(true);
    }
  }

  onClose(): void {
    this.toolsService.deactivateTool(this.id);
  }

  ngOnDestroy(): void {
    this.isHidden = true;
  }
}
