import { Injectable } from '@angular/core';
import { APIHandler } from '../api-handler/api-handler';
import { ConfigurationService } from 'src/app/modules/configuration/services/configuration/configuration.service';
import { SelfSelectedAssignmentData, UpdateAssignmentData } from '../../types';

@Injectable()
export class AssignmentAPI {
  readonly pathBase = `api/v1/assignment`;
  authToken = '';
  private apiHandler: APIHandler;

  constructor(private configService: ConfigurationService) {
    this.apiHandler = new APIHandler({
      baseUrl: this.configService.config.contentMetadataDomain,
      defaultHeaders: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  }

  async createNewRecord(
    record: SelfSelectedAssignmentData[]
  ): Promise<string | number | undefined> {
    try {
      const response = await this.apiHandler.post(this.pathBase, {
        data: record,
      });
      console.log('Return the ID from the response', response);
      // TODO: replace return ID from the response
      return record[0].contentId;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  async updateRecord(record: UpdateAssignmentData[]): Promise<void> {
    this.apiHandler
      .patch(this.pathBase, {
        data: record,
      })
      .then(response => {
        console.log('Updated an assignment data', response);
      })
      .catch(e => {
        console.error(e);
      });
  }

  setAuthToken(authToken: string): void {
    this.authToken = authToken;
    this.configureHandler();
  }

  configureHandler(): void {
    this.apiHandler.configure({
      baseUrl: this.configService.config.contentMetadataDomain,
      defaultHeaders: {
        Authorization: 'Bearer ' + this.authToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  }
}
