import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const sre = require('speech-rule-engine');

export interface SpeechRuleEngineConfig {
  locale?: string; // 'en', 'es', etc.
  domain?: string; // clearspeak or mathspeak
  modality?: string; // speech, braille, etc.
  style?: string;
}

export interface SpeechQueueObj {
  mml: string;
  tex: string;
  config?: SpeechRuleEngineConfig;
}

@Injectable({
  providedIn: 'root',
})
export class SpeechRuleEngineService {
  readonly mmlTag = 'math';
  readonly defaultConfig: SpeechRuleEngineConfig = {
    locale: 'en', // 'en', 'es', etc.
    domain: 'clearspeak', // clearspeak or mathspeak
    modality: 'speech',
    style: 'default',
  };
  private isEngineReady = false;
  config = { ...this.defaultConfig };
  speechMap: { [key: string]: string } = {};
  toSpeechQueue: SpeechQueueObj[] = [];
  lastAddedMapKey = new BehaviorSubject<string>('');
  isRunning = false;

  pushQueue(v: SpeechQueueObj): void {
    this.toSpeechQueue.push(v);

    if (!this.isRunning) {
      this.shiftQueue();
    }
  }

  shiftQueue(): void {
    // queue finished early out
    if (this.toSpeechQueue.length === 0) {
      this.isRunning = false;
      return;
    }

    this.isRunning = true;
    const v = this.toSpeechQueue.shift();
    if (v) {
      let locale = this.config.locale ?? 'en';
      if (v.config && v.config.locale) {
        locale = v.config.locale;
      }
      const mapKey = this.mapKey(locale, v.tex);

      // does the speech map already contain value for this math?
      if (Object.prototype.hasOwnProperty.call(this.speechMap, mapKey)) {
        this.goNextQueue(mapKey);
      } else {
        this.handleAddingToMap(v, mapKey);
      }
    } else {
      this.shiftQueue();
    }
  }

  async toSpeech(
    mml: string,
    tex: string,
    config?: SpeechRuleEngineConfig
  ): Promise<string> {
    const mapKey = this.mapKey(this.config.locale ?? 'en', tex);
    if (Object.hasOwn(this.speechMap, mapKey)) {
      return this.speechMap[mapKey];
    }

    if (config && this.diffCurrentConfig(config)) {
      this.config = { ...this.defaultConfig, ...config };
      this.isEngineReady = false;
    }

    if (!this.isEngineReady) {
      await this.setupEngine(this.config);
      this.isEngineReady = true;
    }

    return sre
      .toSpeech(mml)
      .replaceAll('blank', '')
      .replaceAll('  ', ' ')
      .replaceAll('&nbsp;', ' ');
  }

  mapKey(locale: string, tex: string): string {
    return locale + '-' + tex;
  }

  setupEngine(config: SpeechRuleEngineConfig): Promise<void> {
    return sre.setupEngine(config);
  }

  private handleAddingToMap(v: SpeechQueueObj, mapKey: string): void {
    this.toSpeech(v.mml, v.tex, v?.config)
      .then(val => {
        // add to map
        this.mapValue(mapKey, val);
        this.goNextQueue(mapKey);
      })
      .catch(err => {
        // log error
        console.log('SRE error:', err);

        // attempt to run toSpeech on object again
        this.pushQueue(v);
        this.goNextQueue(mapKey);
      });
  }

  private goNextQueue(lastAddedMapKey: string): void {
    setTimeout(() => {
      // update behavior subject for those subscribed
      this.lastAddedMapKey.next(lastAddedMapKey);
    }, 0);

    // call shiftQueue again
    this.shiftQueue();
  }

  private mapValue(mapKey: string, value: string): void {
    this.speechMap[mapKey] = value;
  }

  private diffCurrentConfig(config: SpeechRuleEngineConfig): boolean {
    const conKeys = Object.keys(config);

    for (let i = 0; i < conKeys.length; i++) {
      const key = conKeys[i];
      if (Object.hasOwn(this.config, key) && Object.hasOwn(config, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const oldProp = (this.config as any)[key];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newProp = (config as any)[key];
        if (oldProp !== newProp) {
          return true;
        }
      } else {
        return true;
      }
    }

    return false;
  }
}
