/**
 * @enum {string}
 */
export enum AudioDescriptor {
  // click sfx
  SELECT_MAJOR_HI = 'select_major_hi',
  SELECT_MAJOR_LO = 'select_major_lo',
  SELECT_MINOR = 'select_minor',

  // answers sfx
  ANSWER_CORRECT = 'answer_correct',
  ANSWER_INCORRECT = 'answer_incorrect',

  // general sfx
  CLICK_GENERAL = 'general_click',

  // anim sfx
  ANIM_RESULTS = 'anim_results',
  ANIM_CHEST = 'anim_chest',

  // drag and drop sfx
  PICKUP_TILE = 'pickup_tile',
  PLACE_TILE = 'place_tile',
}
