import { TEST_IMAGE_TYPES, images } from './images';
import { multiChoiceQuestions } from './multi-choice-questions';
import { LanguageCodes, TEIComplexity, TemplateID } from '../shared/enums';
import {
  TEST_TEXTS_TYPES,
  TEST_TITLE_TEXTS_TYPES,
  texts,
  titles,
} from './texts';
import {
  TEST_LEARNING_TARGET_TYPES,
  learningTargets,
} from './learning-targets';
import { inlineChoiceQuestions } from './inline-choice-questions';
import { textEntryQuestions } from './text-entry-questions';
import { multiChoiceImageQuestions } from './multi-choice-image-questions';
import { multiSelectQuestions } from './multi-select-questions';
import { multiSelectImageQuestions } from './multi-select-image-questions';
import { TEST_HINT_TYPES, chartHints, hints } from './hints';
import { TEST_CHOICE_TYPES } from './choice-types';
import { TEST_POPULATION_TYPES } from './population-types';
import { Population } from '../shared/interfaces';
import { ISlideData } from '../modules/carousel/interfaces';
import { dynamicHorizontalSequencing } from './dynamic-horizontal-sequencing';
import { staticHorizontalSequencing } from './static-horizontal-sequencing';
import { staticVerticalSequencing } from './static-vertical-sequencing';
import { fillInTheBlankTileText } from './fill-in-the-blank-tile-text-questions';
import { constructedResponseQuestions } from './constructed-response-questions';
import { TestAssociatedSuccessCriteria } from './test-associated-success-criteria.enum';
import { extendedConstructedResponseQuestions } from './extended-constructed-response-questions';
import { sortingBuckets } from './sorting-buckets';
import { sortingBucketsImages } from './sorting-buckets-images';

/*****************************************************************/
/* READING POPULATIONS                                           */
/*****************************************************************/

const copyHints = (hints: ISlideData[]): ISlideData[] =>
  hints.map((h: ISlideData) => h);

const constReadingPopParams = {
  product: 'Reading',
  language: LanguageCodes.ENGLISH,
};

const constReadingPopParamsMC = {
  templateID: TemplateID.MULTIPLE_CHOICE,
  complexity: 'Application' as TEIComplexity,
  associatedSuccessCriteria: [TestAssociatedSuccessCriteria.MULTI_CHOICE],
  domain: { text: 'Multichoice domain' },
  standard: { text: 'Multichoice standard' },
  learningTarget: [learningTargets[TEST_LEARNING_TARGET_TYPES.MULTI_CHOICE]],
  feedbackFirstIncorrect: {
    content: 'Insert first incorrect feedback - Test.R.MC',
  },
  feedbackSecondIncorrect: {
    content: 'Insert second incorrect feedback - Test.R.MC',
  },
  ...constReadingPopParams,
};

export const populationsReadingMC: { [key: number]: Population } = {
  [TEST_POPULATION_TYPES.TEST_R_MC_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MC_SHORT}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_SHORT]),
    populationData: multiChoiceQuestions[TEST_CHOICE_TYPES.TEST_R_MC_SHORT],
    ...constReadingPopParamsMC,
  },
  [TEST_POPULATION_TYPES.TEST_R_MC_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MC_MEDIUM}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_MEDIUM]),
    populationData: multiChoiceQuestions[TEST_CHOICE_TYPES.TEST_R_MC_MEDIUM],
    ...constReadingPopParamsMC,
  },
  [TEST_POPULATION_TYPES.TEST_R_MC_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MC_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_LONG]),
    populationData: multiChoiceQuestions[TEST_CHOICE_TYPES.TEST_R_MC_LONG],
    ...constReadingPopParamsMC,
  },
  [TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_SHORT}`,
    populationData: multiChoiceQuestions[TEST_CHOICE_TYPES.TEST_R_MC_SHORT],
    hint: copyHints(hints[TEST_HINT_TYPES.DOUBLE_MEDIUM]),
    passage: texts[TEST_TEXTS_TYPES.SHORT],
    ...constReadingPopParamsMC,
  },
  [TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_MEDIUM}`,
    populationData: multiChoiceQuestions[TEST_CHOICE_TYPES.TEST_R_MC_MEDIUM],
    hint: copyHints(hints[TEST_HINT_TYPES.MULTIPLE_MEDIUM]),
    passage: texts[TEST_TEXTS_TYPES.MEDIUM],
    ...constReadingPopParamsMC,
  },
  [TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_LONG}`,
    instructions: {
      text: 'This passage has instructions about something that you should read about here.',
    },
    populationData: multiChoiceQuestions[TEST_CHOICE_TYPES.TEST_R_MC_LONG],
    hint: copyHints(hints[TEST_HINT_TYPES.MULTIPLE_MEDIUM]),
    image: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
    passage: texts[TEST_TEXTS_TYPES.LONG],
    ...constReadingPopParamsMC,
  },
  [TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_SHORT_WITH_TITLE]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_SHORT_WITH_TITLE}`,
    populationData: multiChoiceQuestions[TEST_CHOICE_TYPES.TEST_R_MC_SHORT],
    hint: copyHints(hints[TEST_HINT_TYPES.DOUBLE_MEDIUM]),
    passage: texts[TEST_TEXTS_TYPES.SHORT],
    passageTitle: titles[TEST_TITLE_TEXTS_TYPES.SHORT],
    ...constReadingPopParamsMC,
  },
  [TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_MEDIUM_WITH_TITLE]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_MEDIUM_WITH_TITLE}`,
    populationData: multiChoiceQuestions[TEST_CHOICE_TYPES.TEST_R_MC_MEDIUM],
    hint: copyHints(hints[TEST_HINT_TYPES.MULTIPLE_MEDIUM]),
    passage: texts[TEST_TEXTS_TYPES.MEDIUM],
    passageTitle: titles[TEST_TITLE_TEXTS_TYPES.MEDIUM],
    ...constReadingPopParamsMC,
  },
  [TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_LONG_WITH_TITLE]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_LONG_WITH_TITLE}`,
    instructions: {
      text: 'This passage has instructions about something that you should read about here.',
    },
    populationData: multiChoiceQuestions[TEST_CHOICE_TYPES.TEST_R_MC_LONG],
    hint: copyHints(hints[TEST_HINT_TYPES.MULTIPLE_MEDIUM]),
    image: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
    passage: texts[TEST_TEXTS_TYPES.LONG],
    passageTitle: titles[TEST_TITLE_TEXTS_TYPES.LONG],
    ...constReadingPopParamsMC,
  },
  [TEST_POPULATION_TYPES.TEST_R_MC_TITLE_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MC_TITLE_SHORT}`,
    populationData: {
      ...multiChoiceQuestions[TEST_CHOICE_TYPES.TEST_R_MC_SHORT],
      question: {
        text: 'This TEI has a passage title, but does not have a passage, so it will not be displayed',
      },
    },
    hint: copyHints(hints[TEST_HINT_TYPES.DOUBLE_MEDIUM]),
    passageTitle: titles[TEST_TITLE_TEXTS_TYPES.SHORT],
    ...constReadingPopParamsMC,
  },
  [TEST_POPULATION_TYPES.TEST_R_MC_TITLE_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MC_TITLE_MEDIUM}`,
    populationData: {
      ...multiChoiceQuestions[TEST_CHOICE_TYPES.TEST_R_MC_MEDIUM],
      question: {
        text: 'This TEI has a passage title, but does not have a passage, so it will not be displayed',
      },
    },
    hint: copyHints(hints[TEST_HINT_TYPES.MULTIPLE_MEDIUM]),
    passageTitle: titles[TEST_TITLE_TEXTS_TYPES.MEDIUM],
    ...constReadingPopParamsMC,
  },
  [TEST_POPULATION_TYPES.TEST_R_MC_TITLE_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MC_TITLE_LONG}`,
    populationData: {
      ...multiChoiceQuestions[TEST_CHOICE_TYPES.TEST_R_MC_LONG],
      question: {
        text: 'This TEI has a passage title, but does not have a passage, so it will not be displayed',
      },
    },
    hint: copyHints(hints[TEST_HINT_TYPES.MULTIPLE_MEDIUM]),
    image: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
    passageTitle: titles[TEST_TITLE_TEXTS_TYPES.LONG],
    ...constReadingPopParamsMC,
  },
  [TEST_POPULATION_TYPES.TEST_R_MC_TRUE_FALSE]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MC_TRUE_FALSE}`,
    hint: copyHints(hints[TEST_HINT_TYPES.MULTIPLE_MEDIUM]),
    instructions: {
      text: 'This is instruction text for the true or false question type.',
    },
    populationData:
      multiChoiceQuestions[TEST_CHOICE_TYPES.TEST_R_MC_TRUE_FALSE],
    ...constReadingPopParamsMC,
    templateID: TemplateID.TRUE_FALSE_CHOICE,
  },
  [TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_TRUE_FALSE]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_TRUE_FALSE}`,
    hint: copyHints(hints[TEST_HINT_TYPES.MULTIPLE_MEDIUM]),
    passage: texts[TEST_TEXTS_TYPES.LONG],
    image: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
    instructions: {
      text: 'This is instruction text for the true or false question type.',
    },
    populationData:
      multiChoiceQuestions[TEST_CHOICE_TYPES.TEST_R_MC_TRUE_FALSE],
    ...constReadingPopParamsMC,
    templateID: TemplateID.TRUE_FALSE_CHOICE,
  },
};

const constReadingPopParamsMCI = {
  templateID: TemplateID.MULTIPLE_CHOICE_IMAGE,
  complexity: 'Application' as TEIComplexity,
  associatedSuccessCriteria: [TestAssociatedSuccessCriteria.MULTI_CHOICE_IMAGE],
  domain: { text: 'Multichoice image domain' },
  standard: { text: 'Multichoice image standard' },
  learningTarget: [learningTargets[TEST_LEARNING_TARGET_TYPES.MULTI_CHOICE]],
  feedbackFirstIncorrect: {
    content: 'Insert first incorrect feedback - Test.R.MCI',
  },
  feedbackSecondIncorrect: {
    content: 'Insert second incorrect feedback - Test.R.MCI',
  },
  ...constReadingPopParams,
};

export const populationsReadingMCI: { [key: number]: Population } = {
  [TEST_POPULATION_TYPES.TEST_R_MCI_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MCI_SHORT}`,
    populationData:
      multiChoiceImageQuestions[TEST_CHOICE_TYPES.TEST_R_MCI_SHORT],
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_SHORT]),
    ...constReadingPopParamsMCI,
  },
  [TEST_POPULATION_TYPES.TEST_R_MCI_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MCI_MEDIUM}`,
    populationData:
      multiChoiceImageQuestions[TEST_CHOICE_TYPES.TEST_R_MCI_MEDIUM],
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_MEDIUM]),
    ...constReadingPopParamsMCI,
  },
  [TEST_POPULATION_TYPES.TEST_R_MCI_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MCI_LONG}`,
    populationData:
      multiChoiceImageQuestions[TEST_CHOICE_TYPES.TEST_R_MCI_LONG],
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_LONG]),
    ...constReadingPopParamsMCI,
  },
  [TEST_POPULATION_TYPES.TEST_R_MCI_PASSAGE_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MCI_PASSAGE_SHORT}`,
    populationData:
      multiChoiceImageQuestions[TEST_CHOICE_TYPES.TEST_R_MCI_SHORT],
    hint: copyHints(hints[TEST_HINT_TYPES.DOUBLE_MEDIUM]),
    passage: texts[TEST_TEXTS_TYPES.SHORT],
    ...constReadingPopParamsMCI,
  },
  [TEST_POPULATION_TYPES.TEST_R_MCI_PASSAGE_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MCI_PASSAGE_MEDIUM}`,
    populationData:
      multiChoiceImageQuestions[TEST_CHOICE_TYPES.TEST_R_MCI_MEDIUM],
    hint: copyHints(hints[TEST_HINT_TYPES.MULTIPLE_MEDIUM]),
    passage: texts[TEST_TEXTS_TYPES.MEDIUM],
    ...constReadingPopParamsMCI,
  },
  [TEST_POPULATION_TYPES.TEST_R_MCI_PASSAGE_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MCI_PASSAGE_LONG}`,
    instructions: {
      text: 'This passage has instructions about something that you should read about here.',
    },
    populationData:
      multiChoiceImageQuestions[TEST_CHOICE_TYPES.TEST_R_MCI_LONG],
    hint: copyHints(hints[TEST_HINT_TYPES.MULTIPLE_MEDIUM]),
    passage: texts[TEST_TEXTS_TYPES.LONG],
    image: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
    ...constReadingPopParamsMCI,
  },
};

const constReadingPopParamsMS = {
  templateID: TemplateID.MULTI_SELECT,
  complexity: 'Application' as TEIComplexity,
  associatedSuccessCriteria: [TestAssociatedSuccessCriteria.MULTI_SELECT],
  domain: { text: 'Multiselect domain' },
  standard: { text: 'Multiselect standard' },
  learningTarget: [learningTargets[TEST_LEARNING_TARGET_TYPES.MULTI_SELECT]],
  feedbackFirstIncorrect: {
    content: 'Insert first incorrect feedback - Test.R.MS',
  },
  feedbackSecondIncorrect: {
    content: 'Insert second incorrect feedback - Test.R.MS',
  },
  ...constReadingPopParams,
};

export const populationsReadingMS: { [key: number]: Population } = {
  [TEST_POPULATION_TYPES.TEST_R_MS_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MS_SHORT}`,
    populationData: multiSelectQuestions[TEST_CHOICE_TYPES.TEST_R_MS_SHORT],
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_SHORT]),
    ...constReadingPopParamsMS,
  },
  [TEST_POPULATION_TYPES.TEST_R_MS_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MS_MEDIUM}`,
    populationData: multiSelectQuestions[TEST_CHOICE_TYPES.TEST_R_MS_MEDIUM],
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_MEDIUM]),
    ...constReadingPopParamsMS,
  },
  [TEST_POPULATION_TYPES.TEST_R_MS_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MS_LONG}`,
    instructions: {
      text: 'This passage has instructions about something that you should read about here.',
    },
    populationData: multiSelectQuestions[TEST_CHOICE_TYPES.TEST_R_MS_LONG],
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_LONG]),
    ...constReadingPopParamsMS,
  },
  [TEST_POPULATION_TYPES.TEST_R_MS_PASSAGE_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MS_PASSAGE_SHORT}`,
    populationData: multiSelectQuestions[TEST_CHOICE_TYPES.TEST_R_MS_SHORT],
    hint: copyHints(hints[TEST_HINT_TYPES.DOUBLE_MEDIUM]),
    passage: texts[TEST_TEXTS_TYPES.SHORT],
    ...constReadingPopParamsMS,
  },
  [TEST_POPULATION_TYPES.TEST_R_MS_PASSAGE_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MS_PASSAGE_MEDIUM}`,
    populationData: multiSelectQuestions[TEST_CHOICE_TYPES.TEST_R_MS_MEDIUM],
    hint: copyHints(hints[TEST_HINT_TYPES.MULTIPLE_MEDIUM]),
    passage: texts[TEST_TEXTS_TYPES.MEDIUM],
    ...constReadingPopParamsMS,
  },
  [TEST_POPULATION_TYPES.TEST_R_MS_PASSAGE_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MS_PASSAGE_LONG}`,
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    populationData: multiSelectQuestions[TEST_CHOICE_TYPES.TEST_R_MS_LONG],
    hint: copyHints(hints[TEST_HINT_TYPES.MULTIPLE_MEDIUM]),
    passage: texts[TEST_TEXTS_TYPES.LONG],
    image: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
    ...constReadingPopParamsMS,
  },
};

const constReadingPopParamsMSI = {
  templateID: TemplateID.MULTI_SELECT_IMAGE,
  complexity: 'Application' as TEIComplexity,
  associatedSuccessCriteria: [TestAssociatedSuccessCriteria.MULTI_SELECT_IMAGE],
  domain: { text: 'Multiselect image domain' },
  standard: { text: 'Multiselect image standard' },
  learningTarget: [learningTargets[TEST_LEARNING_TARGET_TYPES.MULTI_SELECT]],
  feedbackFirstIncorrect: {
    content: 'Insert first incorrect feedback - Test.R.MSI',
  },
  feedbackSecondIncorrect: {
    content: 'Insert second incorrect feedback - Test.R.MSI',
  },
  ...constReadingPopParams,
};

export const populationsReadingMSI: { [key: number]: Population } = {
  [TEST_POPULATION_TYPES.TEST_R_MSI_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MSI_SHORT}`,
    populationData:
      multiSelectImageQuestions[TEST_CHOICE_TYPES.TEST_R_MSI_SHORT],
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_SHORT]),
    ...constReadingPopParamsMSI,
  },
  [TEST_POPULATION_TYPES.TEST_R_MSI_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MSI_MEDIUM}`,
    populationData:
      multiSelectImageQuestions[TEST_CHOICE_TYPES.TEST_R_MSI_MEDIUM],
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_MEDIUM]),
    ...constReadingPopParamsMSI,
  },
  [TEST_POPULATION_TYPES.TEST_R_MSI_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MSI_LONG}`,
    populationData:
      multiSelectImageQuestions[TEST_CHOICE_TYPES.TEST_R_MSI_LONG],
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_LONG]),
    ...constReadingPopParamsMSI,
  },
  [TEST_POPULATION_TYPES.TEST_R_MSI_PASSAGE_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MSI_PASSAGE_SHORT}`,
    populationData:
      multiSelectImageQuestions[TEST_CHOICE_TYPES.TEST_R_MSI_SHORT],
    hint: copyHints(hints[TEST_HINT_TYPES.DOUBLE_MEDIUM]),
    passage: texts[TEST_TEXTS_TYPES.SHORT],
    ...constReadingPopParamsMSI,
  },
  [TEST_POPULATION_TYPES.TEST_R_MSI_PASSAGE_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MSI_PASSAGE_MEDIUM}`,
    populationData:
      multiSelectImageQuestions[TEST_CHOICE_TYPES.TEST_R_MSI_MEDIUM],
    hint: copyHints(hints[TEST_HINT_TYPES.MULTIPLE_MEDIUM]),
    passage: texts[TEST_TEXTS_TYPES.MEDIUM],
    ...constReadingPopParamsMSI,
  },
  [TEST_POPULATION_TYPES.TEST_R_MSI_PASSAGE_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MSI_PASSAGE_LONG}`,
    populationData:
      multiSelectImageQuestions[TEST_CHOICE_TYPES.TEST_R_MSI_LONG],
    hint: copyHints(hints[TEST_HINT_TYPES.MULTIPLE_MEDIUM]),
    passage: texts[TEST_TEXTS_TYPES.LONG],
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    image: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
    ...constReadingPopParamsMSI,
  },
};

const constReadingPopParamsIC = {
  templateID: TemplateID.INLINE_CHOICE,
  complexity: 'Application' as TEIComplexity,
  associatedSuccessCriteria: [TestAssociatedSuccessCriteria.INLINE_CHOICE],
  domain: { text: 'Inline choice domain' },
  standard: { text: 'Inline choice standard' },
  learningTarget: [learningTargets[TEST_LEARNING_TARGET_TYPES.INLINE_CHOICE]],
  feedbackFirstIncorrect: {
    content: 'Insert first incorrect feedback - Test.R.IC',
  },
  feedbackSecondIncorrect: {
    content: 'Insert second incorrect feedback - Test.R.IC',
  },
  ...constReadingPopParams,
};

export const populationsReadingIC: { [key: number]: Population } = {
  [TEST_POPULATION_TYPES.TEST_R_IC_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_IC_SHORT}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_SHORT]),
    populationData: inlineChoiceQuestions[TEST_CHOICE_TYPES.TEST_R_IC_SHORT],
    ...constReadingPopParamsIC,
  },
  [TEST_POPULATION_TYPES.TEST_R_IC_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_IC_MEDIUM}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_MEDIUM]),
    populationData: inlineChoiceQuestions[TEST_CHOICE_TYPES.TEST_R_IC_MEDIUM],
    ...constReadingPopParamsIC,
  },
  [TEST_POPULATION_TYPES.TEST_R_IC_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_IC_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_LONG]),
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    populationData: inlineChoiceQuestions[TEST_CHOICE_TYPES.TEST_R_IC_LONG],
    image: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
    ...constReadingPopParamsIC,
  },
};

const constReadingPopParamsTE = {
  templateID: TemplateID.TEXT_ENTRY,
  complexity: 'Application' as TEIComplexity,
  associatedSuccessCriteria: [TestAssociatedSuccessCriteria.TEXT_ENTRY],
  domain: { text: 'Text entry domain' },
  standard: { text: 'Text entry standard' },
  learningTarget: [learningTargets[TEST_LEARNING_TARGET_TYPES.TEXT_ENTRY]],
  feedbackFirstIncorrect: {
    content: 'Insert first incorrect feedback - Test.R.TE',
  },
  feedbackSecondIncorrect: {
    content: 'Insert second incorrect feedback - Test.R.TE',
  },
  ...constReadingPopParams,
};

export const populationsReadingTE: { [key: number]: Population } = {
  [TEST_POPULATION_TYPES.TEST_R_TE_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_TE_SHORT}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_SHORT]),
    populationData: textEntryQuestions[TEST_CHOICE_TYPES.TEST_R_TE_SHORT],
    ...constReadingPopParamsTE,
  },
  [TEST_POPULATION_TYPES.TEST_R_TE_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_TE_MEDIUM}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_MEDIUM]),
    populationData: textEntryQuestions[TEST_CHOICE_TYPES.TEST_R_TE_MEDIUM],
    ...constReadingPopParamsTE,
  },
  [TEST_POPULATION_TYPES.TEST_R_TE_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_TE_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_LONG]),
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    populationData: textEntryQuestions[TEST_CHOICE_TYPES.TEST_R_TE_LONG],
    image: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
    ...constReadingPopParamsTE,
  },
};

const constReadingPopParamsSCR = {
  templateID: TemplateID.CONSTRUCTED_RESPONSE,
  complexity: 'Application' as TEIComplexity,
  associatedSuccessCriteria: [
    TestAssociatedSuccessCriteria.SHORT_CONST_RESPONSE,
  ],
  domain: { text: 'Short Constructed Response domain' },
  standard: { text: 'Short Constructed Response standard' },
  learningTarget: [
    learningTargets[TEST_LEARNING_TARGET_TYPES.SHORT_CONSTRUCTED_RESPONSE],
  ],
  ...constReadingPopParams,
};

const constReadingPopParamsSCRR = {
  templateID: TemplateID.CONSTRUCTED_RESPONSE_WITH_RUBRIC,
  complexity: 'Application' as TEIComplexity,
  associatedSuccessCriteria: [
    TestAssociatedSuccessCriteria.SHORT_CONST_RESPONSE_RUBRIC,
  ],
  domain: { text: 'Short Constructed Response Rubric domain' },
  standard: { text: 'Short Constructed Response Rubric standard' },
  learningTarget: [
    learningTargets[
      TEST_LEARNING_TARGET_TYPES.SHORT_CONSTRUCTED_RESPONSE_WITH_RUBRIC
    ],
  ],
  ...constReadingPopParams,
};

export const populationsReadingSCR: { [key: number]: Population } = {
  [TEST_POPULATION_TYPES.TEST_R_SCR_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_SCR_SHORT}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_SHORT]),
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_SCR_SHORT],
    ...constReadingPopParamsSCR,
  },
  [TEST_POPULATION_TYPES.TEST_R_SCR_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_SCR_MEDIUM}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_MEDIUM]),
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_SCR_MEDIUM],
    ...constReadingPopParamsSCR,
  },
  [TEST_POPULATION_TYPES.TEST_R_SCR_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_SCR_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_LONG]),
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_SCR_LONG],
    ...constReadingPopParamsSCR,
  },
  [TEST_POPULATION_TYPES.TEST_R_SCR_PASSAGE_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_SCR_PASSAGE_SHORT}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_SHORT]),
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_SCR_SHORT],
    passage: texts[TEST_TEXTS_TYPES.SHORT],
    ...constReadingPopParamsSCR,
  },
  [TEST_POPULATION_TYPES.TEST_R_SCR_PASSAGE_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_SCR_PASSAGE_MEDIUM}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_MEDIUM]),
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_SCR_MEDIUM],
    passage: texts[TEST_TEXTS_TYPES.MEDIUM],
    ...constReadingPopParamsSCR,
  },
  [TEST_POPULATION_TYPES.TEST_R_SCR_PASSAGE_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_SCR_PASSAGE_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_LONG]),
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_SCRR_LONG],
    passage: texts[TEST_TEXTS_TYPES.LONG],
    ...constReadingPopParamsSCR,
  },
  [TEST_POPULATION_TYPES.TEST_R_SCRR_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_SCRR_SHORT}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_SHORT]),
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_SCRR_SHORT],
    ...constReadingPopParamsSCRR,
  },
  [TEST_POPULATION_TYPES.TEST_R_SCRR_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_SCRR_MEDIUM}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_MEDIUM]),
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_SCRR_MEDIUM],
    ...constReadingPopParamsSCRR,
  },
  [TEST_POPULATION_TYPES.TEST_R_SCRR_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_SCRR_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_LONG]),
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_SCRR_LONG],
    ...constReadingPopParamsSCRR,
  },
  [TEST_POPULATION_TYPES.TEST_R_SCRR_PASSAGE_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_SCRR_PASSAGE_SHORT}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_SHORT]),
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_SCRR_SHORT],
    passage: texts[TEST_TEXTS_TYPES.SHORT],
    ...constReadingPopParamsSCRR,
  },
  [TEST_POPULATION_TYPES.TEST_R_SCRR_PASSAGE_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_SCRR_PASSAGE_MEDIUM}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_MEDIUM]),
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_SCRR_MEDIUM],
    passage: texts[TEST_TEXTS_TYPES.MEDIUM],
    ...constReadingPopParamsSCRR,
  },
  [TEST_POPULATION_TYPES.TEST_R_SCRR_PASSAGE_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_SCRR_PASSAGE_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_LONG]),
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_SCRR_LONG],
    passage: texts[TEST_TEXTS_TYPES.LONG],
    image: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
    ...constReadingPopParamsSCRR,
  },
};

const constReadingPopParamsECR = {
  templateID: TemplateID.EXTENDED_CONSTRUCTED_RESPONSE,
  complexity: 'Application' as TEIComplexity,
  associatedSuccessCriteria: ['extendedconstructedresponse'],
  domain: { text: 'Extended Constructed Response domain' },
  standard: { text: 'Extended Constructed Response standard' },
  learningTarget: [
    learningTargets[TEST_LEARNING_TARGET_TYPES.EXTENDED_CONSTRUCTED_RESPONSE],
  ],
  ...constReadingPopParams,
};

const constReadingPopParamsECRR = {
  templateID: TemplateID.EXTENDED_CONSTRUCTED_RESPONSE_WITH_RUBRIC,
  complexity: 'Application' as TEIComplexity,
  associatedSuccessCriteria: ['extendedconstructedresponserubric'],
  domain: { text: 'Extended Constructed Response Rubric domain' },
  standard: { text: 'Extended Constructed Response Rubric standard' },
  learningTarget: [
    learningTargets[
      TEST_LEARNING_TARGET_TYPES.EXTENDED_CONSTRUCTED_RESPONSE_WITH_RUBRIC
    ],
  ],
  ...constReadingPopParams,
};

export const populationsReadingECR: { [key: number]: Population } = {
  [TEST_POPULATION_TYPES.TEST_R_ECR_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_ECR_SHORT}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_SHORT]),
    populationData:
      extendedConstructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_ECR_SHORT],
    ...constReadingPopParamsECR,
  },
  [TEST_POPULATION_TYPES.TEST_R_ECR_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_ECR_MEDIUM}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_MEDIUM]),
    populationData:
      extendedConstructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_ECR_MEDIUM],
    ...constReadingPopParamsECR,
  },
  [TEST_POPULATION_TYPES.TEST_R_ECR_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_ECR_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_LONG]),
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    populationData:
      extendedConstructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_ECR_LONG],
    ...constReadingPopParamsECR,
  },
  [TEST_POPULATION_TYPES.TEST_R_ECR_PASSAGE_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_ECR_PASSAGE_SHORT}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_SHORT]),
    populationData:
      extendedConstructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_ECR_SHORT],
    passage: texts[TEST_TEXTS_TYPES.SHORT],
    ...constReadingPopParamsECR,
  },
  [TEST_POPULATION_TYPES.TEST_R_ECR_PASSAGE_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_ECR_PASSAGE_MEDIUM}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_MEDIUM]),
    populationData:
      extendedConstructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_ECR_MEDIUM],
    passage: texts[TEST_TEXTS_TYPES.MEDIUM],
    ...constReadingPopParamsECR,
  },
  [TEST_POPULATION_TYPES.TEST_R_ECR_PASSAGE_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_ECR_PASSAGE_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_LONG]),
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    populationData:
      extendedConstructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_ECR_LONG],
    passage: texts[TEST_TEXTS_TYPES.LONG],
    ...constReadingPopParamsECR,
  },
  [TEST_POPULATION_TYPES.TEST_R_ECRR_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_ECRR_SHORT}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_SHORT]),
    populationData:
      extendedConstructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_ECRR_SHORT],
    ...constReadingPopParamsECRR,
  },
  [TEST_POPULATION_TYPES.TEST_R_ECRR_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_ECRR_MEDIUM}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_MEDIUM]),
    populationData:
      extendedConstructedResponseQuestions[
        TEST_CHOICE_TYPES.TEST_R_ECRR_MEDIUM
      ],
    ...constReadingPopParamsECRR,
  },
  [TEST_POPULATION_TYPES.TEST_R_ECRR_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_ECRR_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_LONG]),
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    populationData:
      extendedConstructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_ECRR_LONG],
    ...constReadingPopParamsECRR,
  },
  [TEST_POPULATION_TYPES.TEST_R_ECRR_PASSAGE_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_ECRR_PASSAGE_SHORT}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_SHORT]),
    populationData:
      extendedConstructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_ECRR_SHORT],
    passage: texts[TEST_TEXTS_TYPES.SHORT],
    ...constReadingPopParamsECRR,
  },
  [TEST_POPULATION_TYPES.TEST_R_ECRR_PASSAGE_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_ECRR_PASSAGE_MEDIUM}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_MEDIUM]),
    populationData:
      extendedConstructedResponseQuestions[
        TEST_CHOICE_TYPES.TEST_R_ECRR_MEDIUM
      ],
    passage: texts[TEST_TEXTS_TYPES.MEDIUM],
    ...constReadingPopParamsECRR,
  },
  [TEST_POPULATION_TYPES.TEST_R_ECRR_PASSAGE_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_ECRR_PASSAGE_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_LONG]),
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    populationData:
      extendedConstructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_ECRR_LONG],
    passage: texts[TEST_TEXTS_TYPES.LONG],
    image: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
    ...constReadingPopParamsECRR,
  },
};

export const populationsReadingDNDSandbox: { [key: number]: Population } = {
  [TEST_POPULATION_TYPES.TEST_M_DND_STATIC_HORIZ_SEQ]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_DND_STATIC_HORIZ_SEQ}`,
    language: LanguageCodes.ENGLISH,
    product: 'Reading',
    associatedSuccessCriteria: ['tag1', 'tag2'],
    complexity: 'Comprehension',
    templateID: TemplateID.STATIC_HORIZONTAL_SEQUENCING,
    hint: [...hints[TEST_HINT_TYPES.SINGLE_LONG]],
    instructions: {
      text: 'Drag the items into the correct order.',
    },
    populationData: staticHorizontalSequencing['test1'],
    feedbackFirstIncorrect: { content: 'You have 1 more try.' },
    feedbackSecondIncorrect: { content: 'The correct answer is...' },
  },
  [TEST_POPULATION_TYPES.TEST_M_DND_DYNAMIC_HORIZ_SEQ]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_DND_DYNAMIC_HORIZ_SEQ}`,
    language: LanguageCodes.ENGLISH,
    product: 'Reading',
    associatedSuccessCriteria: ['tag1', 'tag2'],
    complexity: 'Comprehension',
    templateID: TemplateID.DYNAMIC_HORIZONTAL_SEQUENCING,
    hint: [...hints[TEST_HINT_TYPES.SINGLE_LONG]],
    instructions: {
      text: 'Drag the items into the correct order.',
    },
    populationData: dynamicHorizontalSequencing['test1'],
    feedbackFirstIncorrect: { content: 'You have 1 more try.' },
    feedbackSecondIncorrect: { content: 'The correct answer is...' },
  },
  [TEST_POPULATION_TYPES.TEST_M_DND_STATIC_VERT_SEQ]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_DND_STATIC_VERT_SEQ}`,
    language: LanguageCodes.ENGLISH,
    product: 'Reading',
    associatedSuccessCriteria: ['tag1', 'tag2'],
    complexity: 'Comprehension',
    templateID: TemplateID.STATIC_VERTICAL_SEQUENCING,
    hint: [...hints[TEST_HINT_TYPES.SINGLE_LONG]],
    instructions: {
      text: 'Drag the items into the correct order.',
    },
    populationData: staticVerticalSequencing['test1'],
    feedbackFirstIncorrect: { content: 'You have 1 more try.' },
    feedbackSecondIncorrect: { content: 'The correct answer is...' },
  },
};

const constReadingPopParamsDND = {
  complexity: 'Application' as TEIComplexity,
  associatedSuccessCriteria: [TestAssociatedSuccessCriteria.DRAG_AND_DROP],
  domain: { text: 'Drag and Drop domain' },
  standard: { text: 'Drag and Drop standard' },
  learningTarget: [learningTargets[TEST_LEARNING_TARGET_TYPES.DRAG_AND_DROP]],
  feedbackFirstIncorrect: { content: 'Incorrect first!!!' },
  feedbackSecondIncorrect: { content: 'Incorrect second!!!' },
  ...constReadingPopParams,
};

export const populationsReadingDND: { [key: number]: Population } = {
  [TEST_POPULATION_TYPES.TEST_R_DND_BLANK_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_DND_BLANK_SHORT}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_LONG]),
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    populationData:
      fillInTheBlankTileText[TEST_CHOICE_TYPES.TEST_R_DND_BLANK_SHORT],
    templateID: TemplateID.FILL_IN_THE_BLANK_TILE_TEXT,
    ...constReadingPopParamsDND,
  },
  [TEST_POPULATION_TYPES.TEST_R_DND_BLANK_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_DND_BLANK_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_SHORT]),
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    populationData:
      fillInTheBlankTileText[TEST_CHOICE_TYPES.TEST_R_DND_BLANK_LONG],
    templateID: TemplateID.FILL_IN_THE_BLANK_TILE_TEXT,
    ...constReadingPopParamsDND,
  },
  [TEST_POPULATION_TYPES.TEST_R_DND_SEQ_STATIC_HORZ_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_DND_SEQ_STATIC_HORZ_SHORT}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_MEDIUM]),
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    populationData:
      staticHorizontalSequencing[TEST_CHOICE_TYPES.TEST_R_DND_HORZ_SEQ_SHORT],
    templateID: TemplateID.STATIC_HORIZONTAL_SEQUENCING,
    ...constReadingPopParamsDND,
  },
  [TEST_POPULATION_TYPES.TEST_R_DND_SEQ_STATIC_HORZ_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_DND_SEQ_STATIC_HORZ_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.DOUBLE_MEDIUM]),
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    populationData:
      staticHorizontalSequencing[TEST_CHOICE_TYPES.TEST_R_DND_HORZ_SEQ_LONG],
    templateID: TemplateID.STATIC_HORIZONTAL_SEQUENCING,
    ...constReadingPopParamsDND,
  },
  [TEST_POPULATION_TYPES.TEST_R_DND_SEQ_STATIC_VERT_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_DND_SEQ_STATIC_VERT_SHORT}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_MEDIUM]),
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    populationData:
      staticVerticalSequencing[TEST_CHOICE_TYPES.TEST_R_DND_VERT_SEQ_SHORT],
    templateID: TemplateID.STATIC_VERTICAL_SEQUENCING,
    ...constReadingPopParamsDND,
  },
  [TEST_POPULATION_TYPES.TEST_R_DND_SEQ_STATIC_VERT_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_DND_SEQ_STATIC_VERT_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.DOUBLE_MEDIUM]),
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    populationData:
      staticVerticalSequencing[TEST_CHOICE_TYPES.TEST_R_DND_VERT_SEQ_LONG],
    templateID: TemplateID.STATIC_VERTICAL_SEQUENCING,
    ...constReadingPopParamsDND,
  },
  [TEST_POPULATION_TYPES.TEST_R_DND_SORT_BUCKETS_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_DND_SORT_BUCKETS_SHORT}`,
    hint: copyHints(hints[TEST_HINT_TYPES.DOUBLE_MEDIUM]),
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    populationData:
      sortingBuckets[TEST_CHOICE_TYPES.TEST_R_DND_SORT_BUCKETS_SHORT],
    templateID: TemplateID.SORTING_BUCKETS,
    ...constReadingPopParamsDND,
  },
  [TEST_POPULATION_TYPES.TEST_R_DND_SORT_BUCKETS_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_DND_SORT_BUCKETS_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.DOUBLE_MEDIUM]),
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    populationData:
      sortingBuckets[TEST_CHOICE_TYPES.TEST_R_DND_SORT_BUCKETS_LONG],
    templateID: TemplateID.SORTING_BUCKETS,
    ...constReadingPopParamsDND,
  },
};

/*****************************************************************/
/* SPANISH POPULATIONS                                           */
/*****************************************************************/

const constSpanishPopParams = {
  product: 'Lectura',
  language: LanguageCodes.SPANISH,
};

const constSpanishPopParamsMC = {
  templateID: TemplateID.MULTIPLE_CHOICE,
  complexity: 'Application' as TEIComplexity,
  associatedSuccessCriteria: ['multichoice'],
  domain: { text: 'Multichoice domain' },
  standard: { text: 'Multichoice standard' },
  learningTarget: [learningTargets[TEST_LEARNING_TARGET_TYPES.MULTI_CHOICE]],
  feedbackFirstIncorrect: {
    content: 'Insert first incorrect feedback - Test.R.MC',
  },
  feedbackSecondIncorrect: {
    content: 'Insert second incorrect feedback - Test.R.MC',
  },
  ...constSpanishPopParams,
};

export const populationsSpanishMC: { [key: number]: Population } = {
  [TEST_POPULATION_TYPES.TEST_S_MC_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_MC_SHORT}`,
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_SHORT]),
    populationData: multiChoiceQuestions[TEST_CHOICE_TYPES.TEST_S_MC_SHORT],
    ...constSpanishPopParamsMC,
  },
  [TEST_POPULATION_TYPES.TEST_S_MC_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_MC_MEDIUM}`,
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_MEDIUM]),
    populationData: multiChoiceQuestions[TEST_CHOICE_TYPES.TEST_S_MC_MEDIUM],
    ...constSpanishPopParamsMC,
  },
  [TEST_POPULATION_TYPES.TEST_S_MC_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_MC_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_LONG]),
    populationData: multiChoiceQuestions[TEST_CHOICE_TYPES.TEST_S_MC_LONG],
    ...constSpanishPopParamsMC,
  },
  [TEST_POPULATION_TYPES.TEST_S_MC_PASSAGE_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_MC_PASSAGE_SHORT}`,
    populationData: multiChoiceQuestions[TEST_CHOICE_TYPES.TEST_S_MC_SHORT],
    hint: copyHints(hints[TEST_HINT_TYPES.ES_DOUBLE_MEDIUM]),
    passage: texts[TEST_TEXTS_TYPES.SHORT],
    ...constSpanishPopParamsMC,
  },
  [TEST_POPULATION_TYPES.TEST_S_MC_PASSAGE_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_MC_PASSAGE_MEDIUM}`,
    populationData: multiChoiceQuestions[TEST_CHOICE_TYPES.TEST_S_MC_MEDIUM],
    hint: copyHints(hints[TEST_HINT_TYPES.ES_MULTIPLE_MEDIUM]),
    passage: texts[TEST_TEXTS_TYPES.MEDIUM],
    ...constSpanishPopParamsMC,
  },
  [TEST_POPULATION_TYPES.TEST_S_MC_PASSAGE_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_MC_PASSAGE_LONG}`,
    instructions: {
      text: 'This passage has instructions about something that you should read about here.',
    },
    populationData: multiChoiceQuestions[TEST_CHOICE_TYPES.TEST_S_MC_LONG],
    hint: copyHints(hints[TEST_HINT_TYPES.ES_MULTIPLE_MEDIUM]),
    image: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
    passage: texts[TEST_TEXTS_TYPES.LONG],
    ...constSpanishPopParamsMC,
  },
  [TEST_POPULATION_TYPES.TEST_S_MC_TRUE_FALSE]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_MC_TRUE_FALSE}`,
    hint: copyHints(hints[TEST_HINT_TYPES.ES_MULTIPLE_MEDIUM]),
    instructions: {
      text: 'This is instruction text for the true or false question type.',
    },
    populationData:
      multiChoiceQuestions[TEST_CHOICE_TYPES.TEST_S_MC_TRUE_FALSE],
    ...constSpanishPopParamsMC,
    templateID: TemplateID.TRUE_FALSE_CHOICE,
  },
  [TEST_POPULATION_TYPES.TEST_S_MC_PASSAGE_TRUE_FALSE]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_MC_PASSAGE_TRUE_FALSE}`,
    hint: copyHints(hints[TEST_HINT_TYPES.ES_MULTIPLE_MEDIUM]),
    passage: texts[TEST_TEXTS_TYPES.LONG],
    image: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
    instructions: {
      text: 'This is instruction text for the true or false question type.',
    },
    populationData:
      multiChoiceQuestions[TEST_CHOICE_TYPES.TEST_S_MC_TRUE_FALSE],
    ...constSpanishPopParamsMC,
    templateID: TemplateID.TRUE_FALSE_CHOICE,
  },
};

const constSpanishPopParamsMCI = {
  templateID: TemplateID.MULTIPLE_CHOICE_IMAGE,
  complexity: 'Application' as TEIComplexity,
  associatedSuccessCriteria: ['multichoice-image'],
  domain: { text: 'Multichoice image domain' },
  standard: { text: 'Multichoice image standard' },
  learningTarget: [learningTargets[TEST_LEARNING_TARGET_TYPES.MULTI_CHOICE]],
  feedbackFirstIncorrect: {
    content: 'Insert first incorrect feedback - Test.R.MCI',
  },
  feedbackSecondIncorrect: {
    content: 'Insert second incorrect feedback - Test.R.MCI',
  },
  ...constSpanishPopParams,
};

export const populationsSpanishMCI: { [key: number]: Population } = {
  [TEST_POPULATION_TYPES.TEST_S_MCI_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_MCI_SHORT}`,
    populationData:
      multiChoiceImageQuestions[TEST_CHOICE_TYPES.TEST_S_MCI_SHORT],
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_SHORT]),
    ...constSpanishPopParamsMCI,
  },
  [TEST_POPULATION_TYPES.TEST_S_MCI_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_MCI_MEDIUM}`,
    populationData:
      multiChoiceImageQuestions[TEST_CHOICE_TYPES.TEST_S_MCI_MEDIUM],
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_MEDIUM]),
    ...constSpanishPopParamsMCI,
  },
  [TEST_POPULATION_TYPES.TEST_S_MCI_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_MCI_LONG}`,
    populationData:
      multiChoiceImageQuestions[TEST_CHOICE_TYPES.TEST_S_MCI_LONG],
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_LONG]),
    ...constSpanishPopParamsMCI,
  },
  [TEST_POPULATION_TYPES.TEST_S_MCI_PASSAGE_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_MCI_PASSAGE_SHORT}`,
    populationData:
      multiChoiceImageQuestions[TEST_CHOICE_TYPES.TEST_S_MCI_SHORT],
    hint: copyHints(hints[TEST_HINT_TYPES.ES_DOUBLE_MEDIUM]),
    passage: texts[TEST_TEXTS_TYPES.SHORT],
    ...constSpanishPopParamsMCI,
  },
  [TEST_POPULATION_TYPES.TEST_S_MCI_PASSAGE_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_MCI_PASSAGE_MEDIUM}`,
    populationData:
      multiChoiceImageQuestions[TEST_CHOICE_TYPES.TEST_S_MCI_MEDIUM],
    hint: copyHints(hints[TEST_HINT_TYPES.ES_MULTIPLE_MEDIUM]),
    passage: texts[TEST_TEXTS_TYPES.MEDIUM],
    ...constSpanishPopParamsMCI,
  },
  [TEST_POPULATION_TYPES.TEST_S_MCI_PASSAGE_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_MCI_PASSAGE_LONG}`,
    instructions: {
      text: 'This passage has instructions about something that you should read about here.',
    },
    populationData:
      multiChoiceImageQuestions[TEST_CHOICE_TYPES.TEST_S_MCI_LONG],
    hint: copyHints(hints[TEST_HINT_TYPES.ES_MULTIPLE_MEDIUM]),
    passage: texts[TEST_TEXTS_TYPES.LONG],
    image: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
    ...constSpanishPopParamsMCI,
  },
};

const constSpanishPopParamsMS = {
  templateID: TemplateID.MULTI_SELECT,
  complexity: 'Application' as TEIComplexity,
  associatedSuccessCriteria: ['multiselect'],
  domain: { text: 'Multiselect domain' },
  standard: { text: 'Multiselect standard' },
  learningTarget: [learningTargets[TEST_LEARNING_TARGET_TYPES.MULTI_SELECT]],
  feedbackFirstIncorrect: {
    content: 'Insert first incorrect feedback - Test.R.MS',
  },
  feedbackSecondIncorrect: {
    content: 'Insert second incorrect feedback - Test.R.MS',
  },
  ...constSpanishPopParams,
};

export const populationsSpanishMS: { [key: number]: Population } = {
  [TEST_POPULATION_TYPES.TEST_S_MS_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_MS_SHORT}`,
    populationData: multiSelectQuestions[TEST_CHOICE_TYPES.TEST_S_MS_SHORT],
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_SHORT]),
    ...constSpanishPopParamsMS,
  },
  [TEST_POPULATION_TYPES.TEST_S_MS_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_MS_MEDIUM}`,
    populationData: multiSelectQuestions[TEST_CHOICE_TYPES.TEST_S_MS_MEDIUM],
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_MEDIUM]),
    ...constSpanishPopParamsMS,
  },
  [TEST_POPULATION_TYPES.TEST_S_MS_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_MS_LONG}`,
    instructions: {
      text: 'This passage has instructions about something that you should read about here.',
    },
    populationData: multiSelectQuestions[TEST_CHOICE_TYPES.TEST_S_MS_LONG],
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_LONG]),
    ...constSpanishPopParamsMS,
  },
  [TEST_POPULATION_TYPES.TEST_S_MS_PASSAGE_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_MS_PASSAGE_SHORT}`,
    populationData: multiSelectQuestions[TEST_CHOICE_TYPES.TEST_S_MS_SHORT],
    hint: copyHints(hints[TEST_HINT_TYPES.ES_DOUBLE_MEDIUM]),
    passage: texts[TEST_TEXTS_TYPES.SHORT],
    ...constSpanishPopParamsMS,
  },
  [TEST_POPULATION_TYPES.TEST_S_MS_PASSAGE_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_MS_PASSAGE_MEDIUM}`,
    populationData: multiSelectQuestions[TEST_CHOICE_TYPES.TEST_S_MS_MEDIUM],
    hint: copyHints(hints[TEST_HINT_TYPES.ES_MULTIPLE_MEDIUM]),
    passage: texts[TEST_TEXTS_TYPES.MEDIUM],
    ...constSpanishPopParamsMS,
  },
  [TEST_POPULATION_TYPES.TEST_S_MS_PASSAGE_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_MS_PASSAGE_LONG}`,
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    populationData: multiSelectQuestions[TEST_CHOICE_TYPES.TEST_S_MS_LONG],
    hint: copyHints(hints[TEST_HINT_TYPES.ES_MULTIPLE_MEDIUM]),
    passage: texts[TEST_TEXTS_TYPES.LONG],
    image: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
    ...constSpanishPopParamsMS,
  },
};

const constSpanishPopParamsMSI = {
  templateID: TemplateID.MULTI_SELECT_IMAGE,
  complexity: 'Application' as TEIComplexity,
  associatedSuccessCriteria: ['multiselectimage'],
  domain: { text: 'Multiselect image domain' },
  standard: { text: 'Multiselect image standard' },
  learningTarget: [learningTargets[TEST_LEARNING_TARGET_TYPES.MULTI_SELECT]],
  feedbackFirstIncorrect: {
    content: 'Insert first incorrect feedback - Test.R.MSI',
  },
  feedbackSecondIncorrect: {
    content: 'Insert second incorrect feedback - Test.R.MSI',
  },
  ...constSpanishPopParams,
};

export const populationsSpanishMSI: { [key: number]: Population } = {
  [TEST_POPULATION_TYPES.TEST_S_MSI_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_MSI_SHORT}`,
    populationData:
      multiSelectImageQuestions[TEST_CHOICE_TYPES.TEST_S_MSI_SHORT],
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_SHORT]),
    ...constSpanishPopParamsMSI,
  },
  [TEST_POPULATION_TYPES.TEST_S_MSI_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_MSI_MEDIUM}`,
    populationData:
      multiSelectImageQuestions[TEST_CHOICE_TYPES.TEST_S_MSI_MEDIUM],
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_MEDIUM]),
    ...constSpanishPopParamsMSI,
  },
  [TEST_POPULATION_TYPES.TEST_S_MSI_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_MSI_LONG}`,
    populationData:
      multiSelectImageQuestions[TEST_CHOICE_TYPES.TEST_S_MSI_LONG],
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_LONG]),
    ...constSpanishPopParamsMSI,
  },
  [TEST_POPULATION_TYPES.TEST_S_MSI_PASSAGE_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_MSI_PASSAGE_SHORT}`,
    populationData:
      multiSelectImageQuestions[TEST_CHOICE_TYPES.TEST_S_MSI_SHORT],
    hint: copyHints(hints[TEST_HINT_TYPES.ES_DOUBLE_MEDIUM]),
    passage: texts[TEST_TEXTS_TYPES.SHORT],
    ...constSpanishPopParamsMSI,
  },
  [TEST_POPULATION_TYPES.TEST_S_MSI_PASSAGE_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_MSI_PASSAGE_MEDIUM}`,
    populationData:
      multiSelectImageQuestions[TEST_CHOICE_TYPES.TEST_S_MSI_MEDIUM],
    hint: copyHints(hints[TEST_HINT_TYPES.ES_MULTIPLE_MEDIUM]),
    passage: texts[TEST_TEXTS_TYPES.MEDIUM],
    ...constSpanishPopParamsMSI,
  },
  [TEST_POPULATION_TYPES.TEST_S_MSI_PASSAGE_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_MSI_PASSAGE_LONG}`,
    populationData:
      multiSelectImageQuestions[TEST_CHOICE_TYPES.TEST_S_MSI_LONG],
    hint: copyHints(hints[TEST_HINT_TYPES.ES_MULTIPLE_MEDIUM]),
    passage: texts[TEST_TEXTS_TYPES.LONG],
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    image: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
    ...constSpanishPopParamsMSI,
  },
};

const constSpanishPopParamsIC = {
  templateID: TemplateID.INLINE_CHOICE,
  complexity: 'Application' as TEIComplexity,
  associatedSuccessCriteria: ['inlinechoice'],
  domain: { text: 'Inline choice domain' },
  standard: { text: 'Inline choice standard' },
  learningTarget: [learningTargets[TEST_LEARNING_TARGET_TYPES.INLINE_CHOICE]],
  feedbackFirstIncorrect: {
    content: 'Insert first incorrect feedback - Test.R.IC',
  },
  feedbackSecondIncorrect: {
    content: 'Insert second incorrect feedback - Test.R.IC',
  },
  ...constSpanishPopParams,
};

export const populationsSpanishIC: { [key: number]: Population } = {
  [TEST_POPULATION_TYPES.TEST_S_IC_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_IC_SHORT}`,
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_SHORT]),
    populationData: inlineChoiceQuestions[TEST_CHOICE_TYPES.TEST_S_IC_SHORT],
    ...constSpanishPopParamsIC,
  },
  [TEST_POPULATION_TYPES.TEST_S_IC_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_IC_MEDIUM}`,
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_MEDIUM]),
    populationData: inlineChoiceQuestions[TEST_CHOICE_TYPES.TEST_S_IC_MEDIUM],
    ...constSpanishPopParamsIC,
  },
  [TEST_POPULATION_TYPES.TEST_S_IC_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_IC_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_LONG]),
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    populationData: inlineChoiceQuestions[TEST_CHOICE_TYPES.TEST_S_IC_LONG],
    image: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
    ...constSpanishPopParamsIC,
  },
};

const constSpanishPopParamsTE = {
  templateID: TemplateID.TEXT_ENTRY,
  complexity: 'Application' as TEIComplexity,
  associatedSuccessCriteria: ['textentry'],
  domain: { text: 'Text entry domain' },
  standard: { text: 'Text entry standard' },
  learningTarget: [learningTargets[TEST_LEARNING_TARGET_TYPES.TEXT_ENTRY]],
  feedbackFirstIncorrect: {
    content: 'Insert first incorrect feedback - Test.R.TE',
  },
  feedbackSecondIncorrect: {
    content: 'Insert second incorrect feedback - Test.R.TE',
  },
  ...constSpanishPopParams,
};

export const populationsSpanishTE: { [key: number]: Population } = {
  [TEST_POPULATION_TYPES.TEST_S_TE_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_TE_SHORT}`,
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_SHORT]),
    populationData: textEntryQuestions[TEST_CHOICE_TYPES.TEST_S_TE_SHORT],
    ...constSpanishPopParamsTE,
  },
  [TEST_POPULATION_TYPES.TEST_S_TE_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_TE_MEDIUM}`,
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_MEDIUM]),
    populationData: textEntryQuestions[TEST_CHOICE_TYPES.TEST_S_TE_MEDIUM],
    ...constSpanishPopParamsTE,
  },
  [TEST_POPULATION_TYPES.TEST_S_TE_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_TE_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_LONG]),
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    populationData: textEntryQuestions[TEST_CHOICE_TYPES.TEST_S_TE_LONG],
    image: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
    ...constSpanishPopParamsTE,
  },
};

const constSpanishPopParamsSCR = {
  templateID: TemplateID.CONSTRUCTED_RESPONSE,
  complexity: 'Application' as TEIComplexity,
  associatedSuccessCriteria: [
    TestAssociatedSuccessCriteria.SHORT_CONST_RESPONSE,
  ],
  domain: { text: 'Dominio de respuesta corta construida' },
  standard: { text: 'Estándar de respuesta corta construida' },
  learningTarget: [
    learningTargets[TEST_LEARNING_TARGET_TYPES.SHORT_CONSTRUCTED_RESPONSE],
  ],
  ...constSpanishPopParams,
};

const constSpanishPopParamsECR = {
  templateID: TemplateID.EXTENDED_CONSTRUCTED_RESPONSE,
  complexity: 'Application' as TEIComplexity,
  associatedSuccessCriteria: [
    TestAssociatedSuccessCriteria.EXTENDED_CONST_RESPONSE,
  ],
  domain: { text: 'Dominio de respuesta construida extendida' },
  standard: { text: 'Estándar de respuesta construida extendida' },
  learningTarget: [
    learningTargets[TEST_LEARNING_TARGET_TYPES.EXTENDED_CONSTRUCTED_RESPONSE],
  ],
  ...constSpanishPopParams,
};

const constSpanishPopParamsSCRR = {
  templateID: TemplateID.CONSTRUCTED_RESPONSE_WITH_RUBRIC,
  complexity: 'Application' as TEIComplexity,
  associatedSuccessCriteria: [
    TestAssociatedSuccessCriteria.SHORT_CONST_RESPONSE_RUBRIC,
  ],
  domain: { text: 'Dominio de la rúbrica de respuesta corta construida' },
  standard: { text: 'Estándar de rúbrica de respuesta corta construida' },
  learningTarget: [
    learningTargets[
      TEST_LEARNING_TARGET_TYPES.SHORT_CONSTRUCTED_RESPONSE_WITH_RUBRIC
    ],
  ],
  ...constSpanishPopParams,
};

const constSpanishPopParamsECRR = {
  templateID: TemplateID.EXTENDED_CONSTRUCTED_RESPONSE_WITH_RUBRIC,
  complexity: 'Application' as TEIComplexity,
  associatedSuccessCriteria: [
    TestAssociatedSuccessCriteria.EXTENDED_CONST_RESPONSE_RUBRIC,
  ],
  domain: { text: 'Dominio de la rúbrica de respuesta construida extendida' },
  standard: { text: 'Estándar de rúbrica de respuesta construida extendida' },
  learningTarget: [
    learningTargets[
      TEST_LEARNING_TARGET_TYPES.EXTENDED_CONSTRUCTED_RESPONSE_WITH_RUBRIC
    ],
  ],
  ...constSpanishPopParams,
};

export const populationsSpanishSCR: { [key: number]: Population } = {
  [TEST_POPULATION_TYPES.TEST_S_SCR_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_SCR_SHORT}`,
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_SHORT]),
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_S_SCR_SHORT],
    ...constSpanishPopParamsSCR,
  },
  [TEST_POPULATION_TYPES.TEST_S_SCR_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_SCR_MEDIUM}`,
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_MEDIUM]),
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_S_SCR_MEDIUM],
    ...constSpanishPopParamsSCR,
  },
  [TEST_POPULATION_TYPES.TEST_S_SCR_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_SCR_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_LONG]),
    instructions: {
      text: 'Esto tiene instrucciones sobre algo que deberías leer aquí.',
    },
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_S_SCR_LONG],
    ...constSpanishPopParamsSCR,
  },
  [TEST_POPULATION_TYPES.TEST_S_SCR_PASSAGE_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_SCR_PASSAGE_SHORT}`,
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_SHORT]),
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_S_SCR_SHORT],
    passage: texts[TEST_TEXTS_TYPES.SHORT],
    ...constSpanishPopParamsSCR,
  },
  [TEST_POPULATION_TYPES.TEST_S_SCR_PASSAGE_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_SCR_PASSAGE_MEDIUM}`,
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_MEDIUM]),
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_S_SCR_MEDIUM],
    passage: texts[TEST_TEXTS_TYPES.MEDIUM],
    ...constSpanishPopParamsSCR,
  },
  [TEST_POPULATION_TYPES.TEST_S_SCR_PASSAGE_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_SCR_PASSAGE_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_LONG]),
    instructions: {
      text: 'Esto tiene instrucciones sobre algo que deberías leer aquí.',
    },
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_S_SCRR_LONG],
    passage: texts[TEST_TEXTS_TYPES.LONG],
    ...constSpanishPopParamsSCR,
  },
  [TEST_POPULATION_TYPES.TEST_S_SCRR_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_SCRR_SHORT}`,
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_SHORT]),
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_S_SCRR_SHORT],
    ...constSpanishPopParamsSCRR,
  },
  [TEST_POPULATION_TYPES.TEST_S_SCRR_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_SCRR_MEDIUM}`,
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_MEDIUM]),
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_S_SCRR_MEDIUM],
    ...constSpanishPopParamsSCRR,
  },
  [TEST_POPULATION_TYPES.TEST_S_SCRR_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_SCRR_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_LONG]),
    instructions: {
      text: 'Esto tiene instrucciones sobre algo que deberías leer aquí.',
    },
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_S_SCRR_LONG],
    ...constSpanishPopParamsSCRR,
  },
  [TEST_POPULATION_TYPES.TEST_S_SCRR_PASSAGE_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_SCRR_PASSAGE_SHORT}`,
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_SHORT]),
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_S_SCRR_SHORT],
    passage: texts[TEST_TEXTS_TYPES.SHORT],
    ...constSpanishPopParamsSCRR,
  },
  [TEST_POPULATION_TYPES.TEST_S_SCRR_PASSAGE_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_SCRR_PASSAGE_MEDIUM}`,
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_MEDIUM]),
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_S_SCRR_MEDIUM],
    passage: texts[TEST_TEXTS_TYPES.MEDIUM],
    ...constSpanishPopParamsSCRR,
  },
  [TEST_POPULATION_TYPES.TEST_S_SCRR_PASSAGE_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_SCRR_PASSAGE_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_LONG]),
    instructions: {
      text: 'Esto tiene instrucciones sobre algo que deberías leer aquí.',
    },
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_S_SCRR_LONG],
    passage: texts[TEST_TEXTS_TYPES.LONG],
    image: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
    ...constSpanishPopParamsSCRR,
  },
};

export const populationsSpanishECR: { [key: number]: Population } = {
  [TEST_POPULATION_TYPES.TEST_S_ECR_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_ECR_SHORT}`,
    hint: copyHints(chartHints[TEST_HINT_TYPES.ES_SINGLE_SHORT]),
    populationData:
      extendedConstructedResponseQuestions[TEST_CHOICE_TYPES.TEST_S_ECR_SHORT],
    ...constSpanishPopParamsECR,
  },
  [TEST_POPULATION_TYPES.TEST_S_ECR_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_ECR_MEDIUM}`,
    hint: copyHints(chartHints[TEST_HINT_TYPES.ES_SINGLE_MEDIUM]),
    populationData:
      extendedConstructedResponseQuestions[TEST_CHOICE_TYPES.TEST_S_ECR_MEDIUM],
    ...constSpanishPopParamsECR,
  },
  [TEST_POPULATION_TYPES.TEST_S_ECR_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_ECR_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_LONG]),
    instructions: {
      text: 'Esto tiene instrucciones sobre algo que deberías leer aquí.',
    },
    populationData:
      extendedConstructedResponseQuestions[TEST_CHOICE_TYPES.TEST_S_ECR_LONG],
    ...constSpanishPopParamsECR,
  },
  [TEST_POPULATION_TYPES.TEST_S_ECR_PASSAGE_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_ECR_PASSAGE_SHORT}`,
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_SHORT]),
    populationData:
      extendedConstructedResponseQuestions[TEST_CHOICE_TYPES.TEST_S_ECR_SHORT],
    passage: texts[TEST_TEXTS_TYPES.SHORT],
    ...constSpanishPopParamsECR,
  },
  [TEST_POPULATION_TYPES.TEST_S_ECR_PASSAGE_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_ECR_PASSAGE_MEDIUM}`,
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_MEDIUM]),
    populationData:
      extendedConstructedResponseQuestions[TEST_CHOICE_TYPES.TEST_S_ECR_MEDIUM],
    passage: texts[TEST_TEXTS_TYPES.MEDIUM],
    ...constSpanishPopParamsECR,
  },
  [TEST_POPULATION_TYPES.TEST_S_ECR_PASSAGE_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_ECR_PASSAGE_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_LONG]),
    instructions: {
      text: 'Esto tiene instrucciones sobre algo que deberías leer aquí.',
    },
    populationData:
      extendedConstructedResponseQuestions[TEST_CHOICE_TYPES.TEST_S_ECRR_LONG],
    passage: texts[TEST_TEXTS_TYPES.LONG],
    ...constSpanishPopParamsECR,
  },
  [TEST_POPULATION_TYPES.TEST_S_ECRR_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_ECRR_SHORT}`,
    hint: copyHints(chartHints[TEST_HINT_TYPES.ES_SINGLE_SHORT]),
    populationData:
      extendedConstructedResponseQuestions[TEST_CHOICE_TYPES.TEST_S_ECRR_SHORT],
    ...constSpanishPopParamsECRR,
  },
  [TEST_POPULATION_TYPES.TEST_S_ECRR_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_ECRR_MEDIUM}`,
    hint: copyHints(chartHints[TEST_HINT_TYPES.ES_SINGLE_MEDIUM]),
    populationData:
      extendedConstructedResponseQuestions[
        TEST_CHOICE_TYPES.TEST_S_ECRR_MEDIUM
      ],
    ...constSpanishPopParamsECRR,
  },
  [TEST_POPULATION_TYPES.TEST_S_ECRR_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_ECRR_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_LONG]),
    instructions: {
      text: 'Esto tiene instrucciones sobre algo que deberías leer aquí.',
    },
    populationData:
      extendedConstructedResponseQuestions[TEST_CHOICE_TYPES.TEST_S_ECRR_LONG],
    ...constSpanishPopParamsECRR,
  },
  [TEST_POPULATION_TYPES.TEST_S_ECRR_PASSAGE_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_ECRR_PASSAGE_SHORT}`,
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_SHORT]),
    populationData:
      extendedConstructedResponseQuestions[TEST_CHOICE_TYPES.TEST_S_ECRR_SHORT],
    passage: texts[TEST_TEXTS_TYPES.SHORT],
    ...constSpanishPopParamsECRR,
  },
  [TEST_POPULATION_TYPES.TEST_S_ECRR_PASSAGE_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_ECRR_PASSAGE_MEDIUM}`,
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_MEDIUM]),
    populationData:
      extendedConstructedResponseQuestions[
        TEST_CHOICE_TYPES.TEST_S_ECRR_MEDIUM
      ],
    passage: texts[TEST_TEXTS_TYPES.MEDIUM],
    ...constSpanishPopParamsECRR,
  },
  [TEST_POPULATION_TYPES.TEST_S_ECRR_PASSAGE_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_S_ECRR_PASSAGE_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.ES_SINGLE_LONG]),
    instructions: {
      text: 'Esto tiene instrucciones sobre algo que deberías leer aquí.',
    },
    populationData:
      extendedConstructedResponseQuestions[TEST_CHOICE_TYPES.TEST_S_ECRR_LONG],
    passage: texts[TEST_TEXTS_TYPES.LONG],
    image: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
    ...constSpanishPopParamsECRR,
  },
};

/*****************************************************************/
/* MATH POPULATIONS                                           */
/*****************************************************************/

const constMathPopParams = {
  product: 'Math',
  language: LanguageCodes.ENGLISH,
};

const constMathPopParamsSCR = {
  templateID: TemplateID.CONSTRUCTED_RESPONSE,
  complexity: 'Application' as TEIComplexity,
  associatedSuccessCriteria: [
    TestAssociatedSuccessCriteria.MATH_SHORT_CONSTRUCTED_RESPONSE,
  ],
  domain: { text: 'Math Short Constructed Response domain' },
  standard: { text: 'Math Short Constructed Response standard' },
  learningTarget: [
    learningTargets[TEST_LEARNING_TARGET_TYPES.MATH_SHORT_CONSTRUCTED_RESPONSE],
  ],
  ...constMathPopParams,
};

const constMathPopParamsECR = {
  templateID: TemplateID.EXTENDED_CONSTRUCTED_RESPONSE,
  complexity: 'Application' as TEIComplexity,
  associatedSuccessCriteria: [
    TestAssociatedSuccessCriteria.MATH_EXTENDED_CONSTRUCTED_RESPONSE,
  ],
  domain: { text: 'Math Extended Constructed Response domain' },
  standard: { text: 'Math Extended Constructed Response standard' },
  learningTarget: [
    learningTargets[
      TEST_LEARNING_TARGET_TYPES.MATH_EXTENDED_CONSTRUCTED_RESPONSE
    ],
  ],
  ...constMathPopParams,
};

const constMathPopParamsSCRR = {
  templateID: TemplateID.CONSTRUCTED_RESPONSE_WITH_RUBRIC,
  complexity: 'Application' as TEIComplexity,
  associatedSuccessCriteria: [
    TestAssociatedSuccessCriteria.MATH_SHORT_CONSTRUCTED_RESPONSE_WITH_RUBRIC,
  ],
  domain: { text: 'Math Short Constructed Response Rubric domain' },
  standard: { text: 'Math Short Constructed Response Rubric standard' },
  learningTarget: [
    learningTargets[
      TEST_LEARNING_TARGET_TYPES.MATH_SHORT_CONSTRUCTED_RESPONSE_WITH_RUBRIC
    ],
  ],
  ...constMathPopParams,
};

const constMathPopParamsECRR = {
  templateID: TemplateID.EXTENDED_CONSTRUCTED_RESPONSE_WITH_RUBRIC,
  complexity: 'Application' as TEIComplexity,
  associatedSuccessCriteria: [
    TestAssociatedSuccessCriteria.MATH_EXTENDED_CONSTRUCTED_RESPONSE_WITH_RUBRIC,
  ],
  domain: { text: 'Math Extended Constructed Response Rubric domain' },
  standard: { text: 'Math Extended Constructed Response Rubric standard' },
  learningTarget: [
    learningTargets[
      TEST_LEARNING_TARGET_TYPES.MATH_EXTENDED_CONSTRUCTED_RESPONSE_WITH_RUBRIC
    ],
  ],
  ...constMathPopParams,
};

export const populationsMathSCR: { [key: number]: Population } = {
  [TEST_POPULATION_TYPES.TEST_M_SCR_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_SCR_SHORT}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_SHORT]),
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_SCR_SHORT],
    ...constMathPopParamsSCR,
  },
  [TEST_POPULATION_TYPES.TEST_M_SCR_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_SCR_MEDIUM}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_MEDIUM]),
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_SCR_MEDIUM],
    ...constMathPopParamsSCR,
  },
  [TEST_POPULATION_TYPES.TEST_M_SCR_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_SCR_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_LONG]),
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_SCR_LONG],
    ...constMathPopParamsSCR,
  },
  [TEST_POPULATION_TYPES.TEST_M_SCR_PASSAGE_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_SCR_PASSAGE_SHORT}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_SHORT]),
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_SCR_SHORT],
    passage: texts[TEST_TEXTS_TYPES.SHORT],
    ...constMathPopParamsSCR,
  },
  [TEST_POPULATION_TYPES.TEST_M_SCR_PASSAGE_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_SCR_PASSAGE_MEDIUM}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_MEDIUM]),
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_SCR_MEDIUM],
    passage: texts[TEST_TEXTS_TYPES.MEDIUM],
    ...constMathPopParamsSCR,
  },
  [TEST_POPULATION_TYPES.TEST_M_SCR_PASSAGE_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_SCR_PASSAGE_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_LONG]),
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_SCRR_LONG],
    passage: texts[TEST_TEXTS_TYPES.LONG],
    ...constMathPopParamsSCR,
  },
  [TEST_POPULATION_TYPES.TEST_M_SCRR_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_SCRR_SHORT}`,
    hint: copyHints(chartHints[TEST_HINT_TYPES.SINGLE_SHORT]),
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_SCRR_SHORT],
    ...constMathPopParamsSCRR,
  },
  [TEST_POPULATION_TYPES.TEST_M_SCRR_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_SCRR_MEDIUM}`,
    hint: copyHints(chartHints[TEST_HINT_TYPES.SINGLE_MEDIUM]),
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_SCRR_MEDIUM],
    ...constMathPopParamsSCRR,
  },
  [TEST_POPULATION_TYPES.TEST_M_SCRR_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_SCRR_LONG}`,
    hint: copyHints(chartHints[TEST_HINT_TYPES.SINGLE_LONG]),
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_SCRR_LONG],
    ...constMathPopParamsSCRR,
  },
  [TEST_POPULATION_TYPES.TEST_M_SCRR_PASSAGE_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_SCRR_PASSAGE_SHORT}`,
    hint: copyHints(chartHints[TEST_HINT_TYPES.DOUBLE_MEDIUM]),
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_SCRR_SHORT],
    passage: texts[TEST_TEXTS_TYPES.SHORT],
    ...constMathPopParamsSCRR,
  },
  [TEST_POPULATION_TYPES.TEST_M_SCRR_PASSAGE_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_SCRR_PASSAGE_MEDIUM}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_MEDIUM]),
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_SCRR_MEDIUM],
    passage: texts[TEST_TEXTS_TYPES.MEDIUM],
    ...constMathPopParamsSCRR,
  },
  [TEST_POPULATION_TYPES.TEST_M_SCRR_PASSAGE_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_SCRR_PASSAGE_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_LONG]),
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    populationData:
      constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_SCRR_LONG],
    passage: texts[TEST_TEXTS_TYPES.LONG],
    image: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
    ...constMathPopParamsSCRR,
  },
};

export const populationsMathECR: { [key: number]: Population } = {
  [TEST_POPULATION_TYPES.TEST_M_ECR_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_ECR_SHORT}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_SHORT]),
    populationData:
      extendedConstructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_ECR_SHORT],
    ...constMathPopParamsECR,
  },
  [TEST_POPULATION_TYPES.TEST_M_ECR_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_ECR_MEDIUM}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_MEDIUM]),
    populationData:
      extendedConstructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_ECR_MEDIUM],
    ...constMathPopParamsECR,
  },
  [TEST_POPULATION_TYPES.TEST_M_ECR_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_ECR_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_LONG]),
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    populationData:
      extendedConstructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_ECR_LONG],
    ...constMathPopParamsECR,
  },
  [TEST_POPULATION_TYPES.TEST_M_ECR_PASSAGE_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_ECR_PASSAGE_SHORT}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_SHORT]),
    populationData:
      extendedConstructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_ECR_SHORT],
    passage: texts[TEST_TEXTS_TYPES.SHORT],
    ...constMathPopParamsECR,
  },
  [TEST_POPULATION_TYPES.TEST_M_ECR_PASSAGE_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_ECR_PASSAGE_MEDIUM}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_MEDIUM]),
    populationData:
      extendedConstructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_ECR_MEDIUM],
    passage: texts[TEST_TEXTS_TYPES.MEDIUM],
    ...constMathPopParamsECR,
  },
  [TEST_POPULATION_TYPES.TEST_M_ECR_PASSAGE_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_ECR_PASSAGE_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_LONG]),
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    populationData:
      extendedConstructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_ECRR_LONG],
    passage: texts[TEST_TEXTS_TYPES.LONG],
    ...constMathPopParamsECR,
  },
  [TEST_POPULATION_TYPES.TEST_M_ECRR_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_ECRR_SHORT}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_SHORT]),
    populationData:
      extendedConstructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_ECRR_SHORT],
    ...constMathPopParamsECRR,
  },
  [TEST_POPULATION_TYPES.TEST_M_ECRR_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_ECRR_MEDIUM}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_MEDIUM]),
    populationData:
      extendedConstructedResponseQuestions[
        TEST_CHOICE_TYPES.TEST_R_ECRR_MEDIUM
      ],
    ...constMathPopParamsECRR,
  },
  [TEST_POPULATION_TYPES.TEST_M_ECRR_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_ECRR_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_LONG]),
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    populationData:
      extendedConstructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_ECRR_LONG],
    ...constMathPopParamsECRR,
  },
  [TEST_POPULATION_TYPES.TEST_M_ECRR_PASSAGE_SHORT]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_ECRR_PASSAGE_SHORT}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_SHORT]),
    populationData:
      extendedConstructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_ECRR_SHORT],
    passage: texts[TEST_TEXTS_TYPES.SHORT],
    ...constMathPopParamsECRR,
  },
  [TEST_POPULATION_TYPES.TEST_M_ECRR_PASSAGE_MEDIUM]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_ECRR_PASSAGE_MEDIUM}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_MEDIUM]),
    populationData:
      extendedConstructedResponseQuestions[
        TEST_CHOICE_TYPES.TEST_R_ECRR_MEDIUM
      ],
    passage: texts[TEST_TEXTS_TYPES.MEDIUM],
    ...constMathPopParamsECRR,
  },
  [TEST_POPULATION_TYPES.TEST_M_ECRR_PASSAGE_LONG]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_ECRR_PASSAGE_LONG}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_LONG]),
    instructions: {
      text: 'This has instructions about something that you should read about here.',
    },
    populationData:
      extendedConstructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_ECRR_LONG],
    passage: texts[TEST_TEXTS_TYPES.LONG],
    image: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
    ...constMathPopParamsECRR,
  },
};

// export const populationsMathDNDSandbox: {
//   [key: number]: Population;
// } = {
//   [TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_1]: {
//     id: `${TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_1}`,
//     language: LanguageCodes.ENGLISH,
//     product: 'Reading',
//     complexity: 'Comprehension',
//     templateID: TemplateID.FILL_IN_THE_BLANK_TILE_TEXT,
//     instructions: {
//       text: 'Select the correct answer and hit Ok.',
//     },
//     populationData: fillInTheBlankTileText['test1'],
//     hint: [...hints[TEST_HINT_TYPES.SINGLE_LONG]],
//     feedbackFirstIncorrect: { content: 'Incorrect first!!!'},
//     feedbackSecondIncorrect: { content: 'Incorrect second!!!'},
//   },
//   [TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_2]: {
//     id: `${TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_2}`,
//     language: LanguageCodes.ENGLISH,
//     product: 'Reading',
//     complexity: 'Comprehension',
//     templateID: TemplateID.FILL_IN_THE_BLANK_TILE_TEXT,
//     instructions: {
//       text: 'Select the correct answer and hit Ok.',
//     },
//     populationData: fillInTheBlankTileText['test2'],
//     hint: [...hints[TEST_HINT_TYPES.SINGLE_LONG]],
//     feedbackFirstIncorrect: { content: 'Incorrect first!!!'},
//     feedbackSecondIncorrect: { content: 'Incorrect second!!!'},
//   },
//   [TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_3]: {
//     id: `${TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_3}`,
//     language: LanguageCodes.ENGLISH,
//     product: 'Reading',
//     complexity: 'Comprehension',
//     templateID: TemplateID.FILL_IN_THE_BLANK_TILE_TEXT,
//     instructions: {
//       text: 'Select the correct answer and hit Ok.',
//     },
//     populationData: fillInTheBlankTileText['test3'],
//     hint: [...hints[TEST_HINT_TYPES.SINGLE_LONG]],
//     feedbackFirstIncorrect: { content: 'Incorrect first!!!'},
//     feedbackSecondIncorrect: { content: 'Incorrect second!!!'},
//   },
//   [TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_4]: {
//     id: `${TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_4}`,
//     language: LanguageCodes.ENGLISH,
//     product: 'Reading',
//     complexity: 'Comprehension',
//     templateID: TemplateID.FILL_IN_THE_BLANK_TILE_TEXT,
//     instructions: {
//       text: 'Select the correct answer and hit Ok.',
//     },
//     populationData: fillInTheBlankTileText['test4'],
//     hint: [...hints[TEST_HINT_TYPES.SINGLE_LONG]],
//     feedbackFirstIncorrect: { content: 'Incorrect first!!!'},
//     feedbackSecondIncorrect: { content: 'Incorrect second!!!'},
//   },
//   [TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_5]: {
//     id: `${TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_5}`,
//     language: LanguageCodes.ENGLISH,
//     product: 'Reading',
//     complexity: 'Comprehension',
//     templateID: TemplateID.FILL_IN_THE_BLANK_TILE_TEXT,
//     instructions: {
//       text: 'Select the correct answer and hit Ok.',
//     },
//     populationData: fillInTheBlankTileText['test5'],
//     hint: [...hints[TEST_HINT_TYPES.SINGLE_LONG]],
//     feedbackFirstIncorrect: { content: 'Incorrect first!!!'},
//     feedbackSecondIncorrect: { content: 'Incorrect second!!!'},
//   },
// };

const constMathPopParamsDNDFillInBlank = {
  templateID: TemplateID.FILL_IN_THE_BLANK_TILE_TEXT,
  complexity: 'Application' as TEIComplexity,
  associatedSuccessCriteria: [TestAssociatedSuccessCriteria.DRAG_AND_DROP],
  domain: { text: 'Math Drag and Drop Fill In Blank domain' },
  standard: { text: 'Math Drag and Drop Fill In Blank standard' },
  learningTarget: [learningTargets[TEST_LEARNING_TARGET_TYPES.DRAG_AND_DROP]],
  ...constMathPopParams,
};

const constMathPopParamsDNDStaticHorzSeq = {
  templateID: TemplateID.STATIC_HORIZONTAL_SEQUENCING,
  complexity: 'Application' as TEIComplexity,
  associatedSuccessCriteria: [TestAssociatedSuccessCriteria.DRAG_AND_DROP],
  domain: { text: 'Math Drag and Drop Static Horz Seq domain' },
  standard: { text: 'Math Drag and Drop Static Horz Seq standard' },
  learningTarget: [learningTargets[TEST_LEARNING_TARGET_TYPES.DRAG_AND_DROP]],
  ...constMathPopParams,
};

const constMathPopParamsDNDStaticVertSeq = {
  templateID: TemplateID.STATIC_VERTICAL_SEQUENCING,
  complexity: 'Application' as TEIComplexity,
  associatedSuccessCriteria: [TestAssociatedSuccessCriteria.DRAG_AND_DROP],
  domain: { text: 'Math Drag and Drop Static Vert Seq domain' },
  standard: { text: 'Math Drag and Drop Static Vert Seq standard' },
  learningTarget: [learningTargets[TEST_LEARNING_TARGET_TYPES.DRAG_AND_DROP]],
  ...constMathPopParams,
};

const constMathPopParamsDNDSortingBuckets = {
  templateID: TemplateID.SORTING_BUCKETS,
  complexity: 'Application' as TEIComplexity,
  associatedSuccessCriteria: [TestAssociatedSuccessCriteria.DRAG_AND_DROP],
  domain: { text: 'Math Drag and Drop Sorting Buckets domain' },
  standard: { text: 'Math Drag and Drop Sorting Buckets standard' },
  learningTarget: [learningTargets[TEST_LEARNING_TARGET_TYPES.DRAG_AND_DROP]],
  ...constMathPopParams,
};

export const populationsDragAndDropSequencing: { [key: number]: Population } = {
  [TEST_POPULATION_TYPES.TEST_M_DND_STATIC_HORIZ_SEQ]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_DND_STATIC_HORIZ_SEQ}`,
    ...constMathPopParamsDNDStaticHorzSeq,
    hint: [...hints[TEST_HINT_TYPES.SINGLE_LONG]],
    instructions: {
      text: 'Drag the items into the correct order.',
    },
    populationData: staticHorizontalSequencing['test1'],
    feedbackFirstIncorrect: { content: 'You have 1 more try.' },
    feedbackSecondIncorrect: { content: 'The correct answer is...' },
  },
  [TEST_POPULATION_TYPES.TEST_M_DND_DYNAMIC_HORIZ_SEQ]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_DND_DYNAMIC_HORIZ_SEQ}`,
    ...constMathPopParamsDNDStaticHorzSeq,
    hint: [...hints[TEST_HINT_TYPES.SINGLE_LONG]],
    instructions: {
      text: 'Drag the items into the correct order.',
    },
    populationData: dynamicHorizontalSequencing['test1'],
    feedbackFirstIncorrect: { content: 'You have 1 more try.' },
    feedbackSecondIncorrect: { content: 'The correct answer is...' },
  },
  [TEST_POPULATION_TYPES.TEST_M_DND_STATIC_VERT_SEQ]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_DND_STATIC_VERT_SEQ}`,
    ...constMathPopParamsDNDStaticVertSeq,
    hint: [...hints[TEST_HINT_TYPES.SINGLE_LONG]],
    instructions: {
      text: 'Drag the items into the correct order.',
    },
    populationData: staticVerticalSequencing['test1'],
    feedbackFirstIncorrect: { content: 'You have 1 more try.' },
    feedbackSecondIncorrect: { content: 'The correct answer is...' },
  },
};

export const populationsDragAndDropFillInTheBlank: {
  [key: number]: Population;
} = {
  [TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_1]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_1}`,
    ...constMathPopParamsDNDFillInBlank,
    instructions: {
      text: 'Select the correct answer and hit Ok.',
    },
    populationData: fillInTheBlankTileText['test1'],
    hint: [...hints[TEST_HINT_TYPES.SINGLE_LONG]],
    feedbackFirstIncorrect: { content: 'Incorrect first!!!' },
    feedbackSecondIncorrect: { content: 'Incorrect second!!!' },
  },
  [TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_2]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_2}`,
    ...constMathPopParamsDNDFillInBlank,
    instructions: {
      text: 'Select the correct answer and hit Ok.',
    },
    populationData: fillInTheBlankTileText['test2'],
    hint: [...hints[TEST_HINT_TYPES.SINGLE_LONG]],
    feedbackFirstIncorrect: { content: 'Incorrect first!!!' },
    feedbackSecondIncorrect: { content: 'Incorrect second!!!' },
  },
  [TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_3]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_3}`,
    ...constMathPopParamsDNDFillInBlank,
    instructions: {
      text: 'Select the correct answer and hit Ok.',
    },
    populationData: fillInTheBlankTileText['test3'],
    hint: [...hints[TEST_HINT_TYPES.SINGLE_LONG]],
    feedbackFirstIncorrect: { content: 'Incorrect first!!!' },
    feedbackSecondIncorrect: { content: 'Incorrect second!!!' },
  },
  [TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_4]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_4}`,
    ...constMathPopParamsDNDFillInBlank,
    instructions: {
      text: 'Select the correct answer and hit Ok.',
    },
    populationData: fillInTheBlankTileText['test4'],
    hint: [...hints[TEST_HINT_TYPES.SINGLE_LONG]],
    feedbackFirstIncorrect: { content: 'Incorrect first!!!' },
    feedbackSecondIncorrect: { content: 'Incorrect second!!!' },
  },
  [TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_5]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_5}`,
    ...constMathPopParamsDNDFillInBlank,
    instructions: {
      text: 'Select the correct answer and hit Ok.',
    },
    populationData: fillInTheBlankTileText['test5'],
    hint: [...hints[TEST_HINT_TYPES.SINGLE_LONG]],
    feedbackFirstIncorrect: { content: 'Incorrect first!!!' },
    feedbackSecondIncorrect: { content: 'Incorrect second!!!' },
  },
};

export const populationsDragAndDropSortingBuckets: {
  [key: number]: Population;
} = {
  [TEST_POPULATION_TYPES.TEST_M_DND_SORTING_BUCKETS_1]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_DND_SORTING_BUCKETS_1}`,
    ...constMathPopParamsDNDSortingBuckets,
    instructions: {
      text: 'Sort answers into the correct category.',
    },
    populationData: sortingBuckets['test1'],
    hint: [...hints[TEST_HINT_TYPES.SINGLE_LONG]],
    feedbackFirstIncorrect: { content: 'Incorrect first!!!' },
    feedbackSecondIncorrect: { content: 'Incorrect second!!!' },
  },
  [TEST_POPULATION_TYPES.TEST_M_DND_SORTING_BUCKETS_2]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_DND_SORTING_BUCKETS_2}`,
    ...constMathPopParamsDNDSortingBuckets,
    instructions: {
      text: 'Sort answers into the correct category.',
    },
    populationData: sortingBuckets['test2'],
    hint: [...hints[TEST_HINT_TYPES.SINGLE_LONG]],
    feedbackFirstIncorrect: { content: 'Incorrect first!!!' },
    feedbackSecondIncorrect: { content: 'Incorrect second!!!' },
  },
  [TEST_POPULATION_TYPES.TEST_M_DND_SORTING_BUCKETS_3]: {
    id: `${TEST_POPULATION_TYPES.TEST_M_DND_SORTING_BUCKETS_3}`,
    ...constMathPopParamsDNDSortingBuckets,
    instructions: {
      text: 'Sort answers into the correct category.',
    },
    populationData: sortingBuckets['test3'],
    hint: [...hints[TEST_HINT_TYPES.SINGLE_LONG]],
    feedbackFirstIncorrect: { content: 'Incorrect first!!!' },
    feedbackSecondIncorrect: { content: 'Incorrect second!!!' },
  },
};

export const populationsDragAndDropSortingBucketsImages: {
  [key: number]: Population;
} = {
  [TEST_POPULATION_TYPES.TEST_R_DND_SORTING_BUCKETS_IMAGES_1]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_DND_SORTING_BUCKETS_IMAGES_1}`,
    ...constMathPopParamsDNDSortingBuckets,
    templateID: TemplateID.SORTING_BUCKETS_IMAGES,
    instructions: {
      text: 'Sort answers into the correct category.',
    },
    populationData: sortingBucketsImages['test1'],
    hint: [...hints[TEST_HINT_TYPES.SINGLE_LONG]],
    feedbackFirstIncorrect: { content: 'Incorrect first!!!' },
    feedbackSecondIncorrect: { content: 'Incorrect second!!!' },
  },
  [TEST_POPULATION_TYPES.TEST_R_DND_SORTING_BUCKETS_IMAGES_2]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_DND_SORTING_BUCKETS_IMAGES_2}`,
    ...constMathPopParamsDNDSortingBuckets,
    templateID: TemplateID.SORTING_BUCKETS_IMAGES,
    instructions: {
      text: 'Sort answers into the correct category.',
    },
    populationData: sortingBucketsImages['test2'],
    hint: [...hints[TEST_HINT_TYPES.SINGLE_LONG]],
    feedbackFirstIncorrect: { content: 'Incorrect first!!!' },
    feedbackSecondIncorrect: { content: 'Incorrect second!!!' },
  },
};

export const populationsOverridenTries: { [key: number]: Population } = {
  [TEST_POPULATION_TYPES.TEST_R_MC_MEDIUM_1_TRY]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MC_MEDIUM_1_TRY}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_SHORT]),
    populationData: {
      ...multiChoiceQuestions[TEST_CHOICE_TYPES.TEST_R_MC_MEDIUM],
      tries: 1,
    },
    ...constReadingPopParamsMC,
  },
  [TEST_POPULATION_TYPES.TEST_R_MCI_MEDIUM_2_TRY]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MCI_MEDIUM_2_TRY}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_SHORT]),
    populationData: {
      ...multiChoiceImageQuestions[TEST_CHOICE_TYPES.TEST_R_MCI_MEDIUM],
      tries: 2,
    },
    ...constReadingPopParamsMCI,
  },
  [TEST_POPULATION_TYPES.TEST_R_MS_MEDIUM_3_TRY]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MS_MEDIUM_3_TRY}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_SHORT]),
    populationData: {
      ...multiSelectQuestions[TEST_CHOICE_TYPES.TEST_R_MS_MEDIUM],
      tries: 3,
    },
    ...constReadingPopParamsMS,
  },
  [TEST_POPULATION_TYPES.TEST_R_MSI_MEDIUM_1_TRY]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_MSI_MEDIUM_1_TRY}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_SHORT]),
    populationData: {
      ...multiSelectImageQuestions[TEST_CHOICE_TYPES.TEST_R_MSI_MEDIUM],
      tries: 1,
    },
    ...constReadingPopParamsMSI,
  },
  [TEST_POPULATION_TYPES.TEST_R_TE_MEDIUM_2_TRY]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_TE_MEDIUM_2_TRY}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_SHORT]),
    populationData: {
      ...textEntryQuestions[TEST_CHOICE_TYPES.TEST_R_TE_MEDIUM],
      tries: 2,
    },
    ...constReadingPopParamsTE,
  },
  [TEST_POPULATION_TYPES.TEST_R_IC_MEDIUM_3_TRY]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_IC_MEDIUM_3_TRY}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_SHORT]),
    populationData: {
      ...inlineChoiceQuestions[TEST_CHOICE_TYPES.TEST_R_IC_MEDIUM],
      tries: 3,
    },
    ...constReadingPopParamsIC,
  },
  [TEST_POPULATION_TYPES.TEST_R_DND_BLANK_SHORT_1_TRY]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_DND_BLANK_SHORT_1_TRY}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_SHORT]),
    populationData: {
      ...fillInTheBlankTileText[TEST_CHOICE_TYPES.TEST_R_DND_BLANK_SHORT],
      tries: 2,
    },
    templateID: TemplateID.FILL_IN_THE_BLANK_TILE_TEXT,
    ...constReadingPopParamsDND,
  },
  [TEST_POPULATION_TYPES.TEST_R_DND_SORT_BUCKETS_SHORT_4_TRY]: {
    id: `${TEST_POPULATION_TYPES.TEST_R_DND_SORT_BUCKETS_SHORT_4_TRY}`,
    hint: copyHints(hints[TEST_HINT_TYPES.SINGLE_SHORT]),
    populationData: {
      ...sortingBuckets[TEST_CHOICE_TYPES.TEST_R_DND_SORT_BUCKETS_SHORT],
      tries: 4,
    },
    templateID: TemplateID.SORTING_BUCKETS,
    ...constReadingPopParamsDND,
  },
};
