/**
 * This object is meant to give data about stats for an activity, as well as provide an array of more specific per completion data for the activity
 */

import { CompleteActivityState } from './complete-activity-state';

export interface CompletedActivity {
  activityId: string;
  timesCompleted: number;
  maxStars: number;
  fastestTimeInSecs: number;
  completions: CompleteActivityState[];
}
