import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProgressNodeFocusEnum, ProgressNodeTypeEnum } from '../../enums';
import { BaseNodeComponent } from '../base-node/base-node.component';

@Component({
  selector: 'htc-dinkus-node',
  templateUrl: './dinkus-node.component.html',
  styleUrls: ['./dinkus-node.component.scss'],
})
export class DinkusNodeComponent
  extends BaseNodeComponent
  implements OnInit, OnDestroy
{
  type = ProgressNodeTypeEnum.dinkusNodeComp;
  focusClass = '';
  focusSub!: Subscription;

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.focus$) {
      this.focusSub = this.focus$.subscribe((value: number | undefined) => {
        if (value !== undefined) {
          this.onFocusChange(value);
        }
      });
    }
  }

  /**
   * On focus change set the class of progress node element
   * @param  {ProgressNodeFocusEnum} focus state to set focus to
   */
  onFocusChange(focus: ProgressNodeFocusEnum): void {
    this.focusClass = '';
    switch (focus) {
      case ProgressNodeFocusEnum.active:
        this.focusClass = 'active';
        break;
      case ProgressNodeFocusEnum.seen:
        this.focusClass = 'seen';
        break;
      default:
        break;
    }
  }

  ngOnDestroy(): void {
    if (this.focusSub) {
      this.focusSub.unsubscribe();
    }
  }
}
