<div id="accordionGroup" class="accordion">
  <div *ngFor="let section of accordionSections; let i = index">
    <h3>
      <button
        type="button"
        [attr.aria-expanded]="sectionsExpanded[i]"
        class="accordion-trigger"
        [attr.aria-controls]="section"
        [id]="section"
        (click)="onAccordionToggle(i)"
        data-testid="section-button">
        <span class="accordion-title">
          {{ section | titlecase }}
          <span class="accordion-icon"></span>
        </span>
      </button>
    </h3>
    <div
      id="{{ section }}"
      role="region"
      [attr.aria-labelledby]="section"
      class="accordion-panel"
      [hidden]="!sectionsExpanded[i]">
      <div>
        <fieldset>
          <ng-container
            *ngTemplateOutlet="
              sectionTemplate;
              context: { $implicit: section }
            "></ng-container>
        </fieldset>
      </div>
    </div>
  </div>
</div>
