import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { LottiePlayer } from '@lottiefiles/lottie-player';
import { Subscription } from 'rxjs';
import { UseAnimationService } from 'src/app/modules/use-animation/use-animation.service';

@Component({
  selector: 'htc-lottie-player',
  templateUrl: './lottie-player.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LottiePlayerComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  @Input() src = '';
  @Input() background = 'transparent';
  @Input() autoplay = false;
  @Input() loop = true;
  @Input() speed = 1;
  @Input() autoplaySegment = '';
  @Input() loopAutoplaySegment = false;
  @Input() intermission = 0; // duration in ms to pause between each loop
  @Input() altText = '';
  @Input() direction = 1; //-1 to play backwards, 1 to play forwards
  @Input() playOnHover = false;

  @Output() events = new EventEmitter<string>();

  @ViewChild('player') playerElement!: ElementRef;

  player!: LottiePlayer;
  useAnimation = true;
  sub = new Subscription();

  currentSegment = '';
  constructor(private useAnimationService: UseAnimationService) {}

  ngOnInit(): void {
    this.sub.add(
      this.useAnimationService.useAnimation$.subscribe(useAnim => {
        this.useAnimation = useAnim;

        if (this.player) {
          if (!this.useAnimation) {
            this.player.stop();
          } else if (this.autoplay) {
            this.playSegment(this.autoplaySegment);
          }
        }
      })
    );
  }

  ngAfterViewInit(): void {
    // hack to get this code to run one tick after cause lifecycle dumb
    setTimeout(() => {
      this.player = this.playerElement.nativeElement as LottiePlayer;

      this.addEventListenersAndLoad();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.src && this.player) {
      this.loadAndSetupPlayer(changes.src.currentValue);
    }
  }

  ngOnDestroy(): void {
    if (this.player) {
      this.player.stop();
      this.player.removeAllListeners?.();
    }
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  addEventListenersAndLoad(): void {
    if (this.player && this.player.addEventListener) {
      this.player.addEventListener('error', err => {
        console.error('lottie error', err);
        this.events.emit('error');
      });
      this.player.addEventListener('load', () => {
        if (this.autoplaySegment) {
          this.playSegment(this.autoplaySegment);
        }
        this.events.emit('loaded');
      });
      this.player.addEventListener('ready', () => {
        this.events.emit('ready');
      });
      this.player.addEventListener('complete', () => {
        if (this.loopAutoplaySegment) {
          this.playSegment(this.autoplaySegment);
        } else {
          this.events.emit('complete');
        }
      });

      this.loadAndSetupPlayer(this.src);
    }
  }

  async loadAndSetupPlayer(src?: string): Promise<void> {
    if (this.player) {
      this.setPlayerAttributes();
      await this.player.load(src || this.src);
      if (!this.useAnimation) {
        this.player.stop();
        this.player.play = (): void => {
          console.warn('Animation is disabled due to user preference');
        };
      }
    }
  }

  setPlayerAttributes(): void {
    this.player.autoplay = this.autoplay;
    this.player.background = this.background;
    this.player.loop = this.loop;
    this.player.speed = this.speed;
    this.player.description = this.altText;
    this.player.direction = this.direction;
    this.player.hover = this.playOnHover;
  }

  playSegment(segment: string): void {
    this.currentSegment = segment;
    this.player.getLottie().goToAndPlay(segment);

    if (!this.useAnimation) {
      this.player.stop();
    }
  }

  getCurrentSegment(): string {
    return this.currentSegment;
  }

  cancelAutoplayLoop(): void {
    this.autoplaySegment = '';
    this.loopAutoplaySegment = false;
  }
}
