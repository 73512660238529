import { RTETools } from 'src/app/shared/enums/rte-tools';
import { ConstructedResponseOptions } from '../interfaces/constructed-response-options';

export const shortConstructedResponseOptions: ConstructedResponseOptions = {
  tools: [RTETools.TEXT_FORMAT, RTETools.CLIPBOARD, RTETools.HISTORY],
  characterLimit: 475,
};

export const extendedConstructedResponseOptions: ConstructedResponseOptions = {
  tools: [
    RTETools.TEXT_FORMAT,
    RTETools.LIST_FORMAT,
    RTETools.CLIPBOARD,
    RTETools.HISTORY,
  ],
};
