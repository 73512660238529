import { EventEmitter, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivityStateEnum, PageTransitions } from '../../enums';
import { ActivityService } from '../activity/activity.service';
import { UseAnimationService } from 'src/app/modules/use-animation/use-animation.service';
import { ActivityObjectTypes } from '../../enums/activity-object-types';
import { ActivityManifestObject } from '../../interfaces/activity-manifest-object';

@Injectable({
  providedIn: 'root',
})
export class PageTransitionService {
  private _target!: HTMLElement;
  private _pageTransition = PageTransitions.NONE;
  private _previousActivityState = -1;
  private _transitions: {
    animIn: PageTransitions;
    animOut: PageTransitions;
  }[] = [];
  private _isAnimPaneInUpwardsPlayed = false;
  popAnimationInStarted = new EventEmitter<void>();
  animationEnded = new EventEmitter<void>();
  sub = new Subscription();
  dontResetOnEnd = false;
  lastAnimWasOut = false;
  noAnimTimeout!: NodeJS.Timeout;
  isTEITransitioning = false;

  constructor(
    private activityService: ActivityService,
    private useAnimationService: UseAnimationService
  ) {}

  setup(): void {
    this.activityService.popAnimationInStarted = this.popAnimationInStarted;
    this.sub = new Subscription();
    this.sub.add(
      this.activityService.currActivityManifest.subscribe(manifest => {
        this.initAnimations(manifest);
      })
    );
    this.sub.add(
      this.activityService.advanceActivityEvent.subscribe(() => {
        const newActivityState = this.activityService.activityState;
        const currentStep = this.activityService.currStep;
        const prevStep = this.activityService.prevStep;
        let videoContainerAnimName = PageTransitions.SLIDE_IN_TO_RIGHT;
        if (prevStep < currentStep) {
          // if going from a video page to the next video page
          videoContainerAnimName = PageTransitions.SLIDE_IN_FROM_RIGHT;
        }

        // handle the transition when going back to the video page
        if (
          this._previousActivityState > 0 &&
          this._previousActivityState >= newActivityState &&
          (newActivityState === ActivityStateEnum.VIDEO ||
            newActivityState === ActivityStateEnum.QUESTION)
        ) {
          let videoContainer = document.querySelector(
            '.video-container'
          ) as HTMLElement;
          if (videoContainer) {
            // fade out video player when transitioning to the next video page
            videoContainer.style.animation = PageTransitions.FADE_OUT;
            videoContainer.style.animationDuration = '0.4s';
          }
          setTimeout(() => {
            videoContainer = document.querySelector(
              '.video-container'
            ) as HTMLElement;
            if (videoContainer) {
              videoContainer.style.animation = videoContainerAnimName;
              videoContainer.style.animationDuration = '0.4s';
            }
          }, 400);
        } else if (this.useAnimationService.useAnimation$.getValue()) {
          this._pageTransition = this._transitions[currentStep].animIn;

          // after initial transition upwards, change it to NONE
          if (
            !this._isAnimPaneInUpwardsPlayed &&
            this._transitions[currentStep].animIn ===
              PageTransitions.PANE_IN_UPWARDS
          ) {
            this._transitions[currentStep].animIn = PageTransitions.NONE;
            this._isAnimPaneInUpwardsPlayed = true;
          }
        } else {
          // adds stability to transitions cause load times are mucking things up
          this.noAnimTimeout = setTimeout(() => {
            this._pageTransition = this._transitions[currentStep].animIn;
          }, 100);
        }
        this._previousActivityState = newActivityState;
      })
    );
  }

  private initAnimations(manifest: ActivityManifestObject[]): void {
    this._transitions = [];
    this._previousActivityState = -1;
    this._isAnimPaneInUpwardsPlayed = false;
    let firstPaneInIndex = -1;
    for (let i = 0; i < manifest.length; i++) {
      const type = manifest[i].type;

      if (
        type === ActivityObjectTypes.VIDEO ||
        type === ActivityObjectTypes.QUESTION
      ) {
        if (firstPaneInIndex === -1) {
          this._transitions.push({
            animIn: PageTransitions.PANE_IN_UPWARDS,
            animOut: PageTransitions.NONE,
          });
          firstPaneInIndex = i;
        } else {
          this._transitions.push({
            animIn: PageTransitions.NONE,
            animOut: PageTransitions.NONE,
          });
          if (
            manifest[i + 1] &&
            manifest[i + 1].type !== ActivityObjectTypes.VIDEO &&
            manifest[i + 1].type !== ActivityObjectTypes.QUESTION
          ) {
            this._transitions[i].animOut = PageTransitions.PANE_OUT_DOWNWARDS;
          }
        }
      } else {
        this.handleStartExitScreensAmin(type);
      }
    }
  }

  private handleStartExitScreensAmin(type: string): void {
    if (type === ActivityObjectTypes.START_SCREEN) {
      this._transitions.push({
        animIn: PageTransitions.FAST_FADE_IN,
        animOut: PageTransitions.NONE,
      });
    } else if (type === ActivityObjectTypes.ENTRY_SURVEY) {
      this._transitions.push({
        animIn: PageTransitions.NONE,
        animOut: PageTransitions.SLIDE_OUT_TO_LEFT,
      });
    } else if (type === ActivityObjectTypes.EXIT_SURVEY) {
      this._transitions.push({
        animIn: PageTransitions.SLIDE_IN_FROM_RIGHT,
        animOut: PageTransitions.SLIDE_OUT_TO_LEFT,
      });
    } else if (type === ActivityObjectTypes.RESULTS_SCREEN) {
      this._transitions.push({
        animIn: PageTransitions.HIDDEN_TO_UNSET,
        animOut: PageTransitions.FADE_OUT,
      });
    }
  }

  getVideoContainerTransitionClass(): string {
    if (this.activityService.currStep < this.activityService.prevStep) {
      return PageTransitions.SLIDE_IN_TO_RIGHT; // going backwards
    }
    return PageTransitions.SLIDE_IN_FROM_RIGHT; // going forward
  }

  animOut(): void {
    this.lastAnimWasOut = true;
    const currentStep = this.activityService.currStep;
    this._pageTransition = this._transitions[currentStep].animOut;
  }

  get pageTransition(): PageTransitions {
    return this._pageTransition;
  }

  set target(value: HTMLElement) {
    this._target = value;

    this._target.onanimationend = (event): void => {
      if (event.target === this._target) {
        this.animationEnd();
      }
    };
  }

  get target(): HTMLElement {
    return this._target;
  }

  isNextStepDownwards(): boolean {
    return (
      this._transitions[this.activityService.currStep].animOut ===
      PageTransitions.PANE_OUT_DOWNWARDS
    );
  }

  isAnimating(): boolean {
    return (
      this.pageTransition !== PageTransitions.NONE || this.isTEITransitioning
    );
  }

  animationEnd(): void {
    if (!this.dontResetOnEnd) {
      this._pageTransition = PageTransitions.NONE;
    } else {
      this.dontResetOnEnd = false;
    }

    this.animationEnded.emit();
  }

  cleanup(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.noAnimTimeout) {
      clearTimeout(this.noAnimTimeout);
    }
  }
}
