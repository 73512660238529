import { BehaviorSubject } from 'rxjs';
import { EventEmitter, Injectable } from '@angular/core';
import { ConfigurationService } from 'src/app/modules/configuration/services/configuration/configuration.service';
import { environment } from 'src/environments/environment';
import { featureFlagNodeMap } from 'src/app/data';
import { FeatureFlagNode } from 'src/app/shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  readonly overrideStorage = 'featureFlagOverrides';
  hasOverride$ = new BehaviorSubject<boolean>(false);
  flagsChanged = new EventEmitter<undefined>();
  private featureFlags: Map<string, boolean> = new Map<string, boolean>();
  flagNodes: Map<string, FeatureFlagNode> = new Map<string, FeatureFlagNode>();

  constructor(private configService: ConfigurationService) {}

  /**
   * Populate flags from environment file then load overrides if any are saved
   */
  public populateFlags(): void {
    //populate flagNodes
    this.flagNodes = featureFlagNodeMap;

    Object.entries(this.configService.config.featureFlags).forEach(
      ([key, value]) => {
        this.setFeatureFlagViaTree(key, value);
      }
    );
    this.flagsChanged.emit();
    if (!environment.production) {
      this.loadOverrideFlags();
    }
  }

  /**
   * Test if a flag is enabled, or if all flags in a given array are enabled
   * @param flags The flag or flags we're checking
   * @returns true if all flags are enabled, false otherwise
   */
  public isFlagEnabled(flags: string | string[]): boolean {
    if (this.featureFlags) {
      return (Array.isArray(flags) ? flags : [flags]).every(
        flag => this.featureFlags.has(flag) && this.featureFlags.get(flag)
      );
    }
    return false;
  }

  /**
   * Test if ALL flags in a given array are disabled
   * This is NOT the same thing as !(isFlagEnabled) when applied to multiple flags
   * @param flags The flag or flags we're checking
   * @returns true if all flags are disabled, false otherwise
   */
  public areFlagsDisabled(flags: string[]): boolean {
    if (flags.length > 0 && this.featureFlags) {
      return !flags.some(
        flag => this.featureFlags.has(flag) && this.featureFlags.get(flag)
      );
    }
    return false;
  }

  /**
   * DEBUG/TESTING ONLY
   *
   * Set a feature flag to a specific value. This is used for testing purposes.
   * @param flagName The flag we're setting the value of
   * @param active The value we're setting the flag to
   */
  setFlag(flagName: string, active: boolean): void {
    this.setFeatureFlagViaTree(flagName, active);
    this.flagsChanged.emit();
  }

  setFeatureFlagViaTree(name: string, value: boolean): void {
    this.featureFlags.set(name, value); //in the flag state map
    if (!value) {
      const node = this.flagNodes.get(name);
      if (node) {
        node.children.forEach(child => {
          this.setFeatureFlagViaTree(child, value);
        });
      }
    } else {
      const node = this.flagNodes.get(name);
      if (node) {
        const parent = node.parent;
        if (parent) {
          this.setFeatureFlagViaTree(parent, value);
        }
      }
    }
  }

  /**
   * Everything below is for testing purposes, and while not private, should be treated as such.
   */

  /**
   * DEBUG ONLY
   * Get all the currently set feature flags, this is just for the debug menu
   * @returns An map of key(string) value(boolean) pairs
   */
  getFlags(): Map<string, boolean> {
    return this.featureFlags;
  }

  /**
   * DEBUG ONLY
   * Get all the feature flag keys available right now, this is just for the debug menu
   * @returns An array of all the feature flags keys
   */
  getKeys(): string[] {
    if (!this.featureFlags) return [];
    return Array.from(this.featureFlags.keys());
  }

  /**
   * DEBUG ONLY
   * Saves flag overrides to local storage to override at app start
   * @param flags Key value map of flags to override
   */
  saveCurrentAsOverride(): void {
    this.hasOverride$.next(true);
    localStorage.setItem(
      this.overrideStorage,
      JSON.stringify(Array.from(this.featureFlags.entries()))
    );
  }

  /**
   * DEBUG ONLY
   * Gets flag overrides saved in local storage, then sets service flags to those values
   */
  loadOverrideFlags(): void {
    const savedFlags = localStorage.getItem(this.overrideStorage);
    if (savedFlags) {
      console.warn('Feature Flags are currently overridden');
      const flags = new Map<string, boolean>(JSON.parse(savedFlags));
      this.featureFlags = new Map([...this.featureFlags, ...flags]);
      this.hasOverride$.next(true);
    }
  }

  /**
   * DEBUG ONLY
   * Clears local storage of flag overrides
   */
  clearOverrideFlags(): void {
    localStorage.removeItem(this.overrideStorage);
    this.featureFlags = new Map(
      Object.entries(this.configService.config.featureFlags)
    );
    this.flagsChanged.emit();
    this.hasOverride$.next(false);
  }
}
