import { Activity } from 'src/app/shared/interfaces';

export const reading3: Activity = {
  id: '018ea00f-3cba-73c4-a05e-c70a802dc69f',
  product: 'Reading',
  language: 'en',
  skillName: 'Prepositions',
  thumbnail: {
    id: 'recxcEc5etdqMvvWy',
    url: '/helix/Reading/Grade_3/Prepositions/Episode_03/R_G3_Prepositions_EP03_Poster.png',
    altText: [
      { language: 'en', altText: 'Axis and CAT stand on a beach.' },
      { language: 'es', altText: 'Axis y G.A.T.A. estan en una playa.' },
    ],
  },
  description:
    'It was a bumpy landing, and now Axis and C.A.T. must chat with the locals to find the missing pieces of their ship. Understanding how humans use prepositions will make all the difference.',
  domain: {
    text: 'Parts of Speech',
  },
  seriesName: "Axis's Big Break",
  episodeNumber: '3',
  episodeTitle: "Surf's Up, Ship's Down",
  videos: [
    {
      id: 'recg9H3LbyQIpJyq3',
      url: '/helix/Reading/Grade_3/Prepositions/Episode_03/hls_streams/R_G3_Prepositions_EP03_Video01.m3u8',
      posterURL:
        '/helix/Reading/Grade_3/Prepositions/Episode_03/R_G3_Prepositions_EP03_Poster.png',
      captions: [
        {
          src: '/helix/Reading/Grade_3/Prepositions/Episode_03/R_G3_Prepositions_EP03_Video01.vtt',
          srclang: 'en',
          mode: 'hidden',
          kind: 'captions',
        },
      ],
    },
  ],
  startScreenData: {
    learningTarget: [
      {
        id: '33744.C',
        learningTarget: {
          text: 'how to use prepositions',
        },
        successCriteria: [
          {
            id: '33744.C.1',
            text: 'use prepositions to show location, time, or direction',
          },
          {
            id: '33744.C.2',
            text: 'compose sentences with prepositional phrases',
          },
        ],
      },
    ],
    background_leftImage: {
      id: 'recxcEc5etdqMvvWy',
      url: '/helix/Reading/Grade_3/Prepositions/Episode_03/R_G3_Prepositions_EP03_Poster.png',
      altText: [
        { language: 'en', altText: 'Axis and CAT stand on a beach.' },
        { language: 'es', altText: 'Axis y G.A.T.A. estan en una playa.' },
      ],
    },
    background_rightImage: {
      id: 'recxjC353VMav5ybu',
      url: '/helix/UX_Images/Start_Screen_Blobs/Nova-Scouts_space-sky.svg',
      altText: [
        { language: 'en', altText: 'Stars in space.' },
        { language: 'es', altText: 'Estrellas en el espacio.' },
      ],
    },
    foreground: {
      id: 'recOn2Luhc6XMnbRL',
      url: '/helix/UX_Images/Start_Screen_Characters/Cat5.svg',
      altText: [
        { language: 'en', altText: 'CAT is pouncing.' },
        { language: 'es', altText: 'G.A.T.A está saltando.' },
      ],
    },
  },
  populations: [
    {
      id: '33744.C.1.1',
      templateID: 0,
      complexity: 'Application',
      hint: [
        {
          id: '33744.C.1.1.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recGDy62dlFjIAFzn',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_01.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y su propósito.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: 'Determine if the preposition in the sentence is referring to time, a place, or movement.\n\n**I will see you after school.**\n',
        },
        answerChoices: [
          {
            id: '33744.C.1.1_1',
            choice: {
              texts: [
                {
                  text: 'time\n',
                },
              ],
            },
          },
          {
            id: '33744.C.1.1_2',
            choice: {
              texts: [
                {
                  text: 'place\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: 'Look at the full prepositional phrase.\n',
              },
            },
          },
          {
            id: '33744.C.1.1_3',
            choice: {
              texts: [
                {
                  text: 'movement\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Look closely at the preposition _after_ in this sentence. Is it telling you about movement, place, or time?\n',
              },
            },
          },
        ],
        answers: ['33744.C.1.1_1'],
        associatedSuccessCriteria: ['33744.C.1'],
      },
      feedbackSecondIncorrect: {
        content: '_After_ tells us when something happens.\n',
      },
    },
    {
      id: '33744.C.1.2',
      templateID: 0,
      complexity: 'Application',
      hint: [
        {
          id: '33744.C.1.2.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recGDy62dlFjIAFzn',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_01.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y su propósito.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: 'Determine if the preposition in the sentence is referring to time, a place, or movement.\n\n**I like to stay inside during the winter.**\n',
        },
        answerChoices: [
          {
            id: '33744.C.1.2_1',
            choice: {
              texts: [
                {
                  text: 'time\n',
                },
              ],
            },
          },
          {
            id: '33744.C.1.2_2',
            choice: {
              texts: [
                {
                  text: 'place\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  "A place is mentioned, but it's not part of the prepositional phrase.\n",
              },
            },
          },
          {
            id: '33744.C.1.2_3',
            choice: {
              texts: [
                {
                  text: 'movement\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: "I don't see any movement in this sentence.\n",
              },
            },
          },
        ],
        answers: ['33744.C.1.2_1'],
        associatedSuccessCriteria: ['33744.C.1'],
      },
      feedbackSecondIncorrect: {
        content: '_During_ gives us a period of time.\n',
      },
    },
    {
      id: '33744.C.1.3',
      templateID: 0,
      complexity: 'Application',
      hint: [
        {
          id: '33744.C.1.3.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recGDy62dlFjIAFzn',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_01.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y su propósito.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: 'Determine if the preposition in the sentence is referring to time, a place, or movement.\n\n**My cat is hiding behind the bush.**\n',
        },
        answerChoices: [
          {
            id: '33744.C.1.3_1',
            choice: {
              texts: [
                {
                  text: 'place\n',
                },
              ],
            },
          },
          {
            id: '33744.C.1.3_2',
            choice: {
              texts: [
                {
                  text: 'time\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: 'What information do we have about the cat?\n',
              },
            },
          },
          {
            id: '33744.C.1.3_3',
            choice: {
              texts: [
                {
                  text: 'movement\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: { content: 'He is hiding but not moving.\n' },
            },
          },
        ],
        answers: ['33744.C.1.3_1'],
        associatedSuccessCriteria: ['33744.C.1'],
      },
      feedbackSecondIncorrect: {
        content: '_Behind_ tells us where the cat is hiding.\n',
      },
    },
    {
      id: '33744.C.1.4',
      templateID: 0,
      complexity: 'Application',
      hint: [
        {
          id: '33744.C.1.4.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recGDy62dlFjIAFzn',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_01.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y su propósito.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: 'Determine if the preposition in the sentence is referring to time, a place, or movement.\n\n**The water in the pool was very cold.**\n',
        },
        answerChoices: [
          {
            id: '33744.C.1.4_1',
            choice: {
              texts: [
                {
                  text: 'place\n',
                },
              ],
            },
          },
          {
            id: '33744.C.1.4_2',
            choice: {
              texts: [
                {
                  text: 'time\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: "This doesn't say when the water was cold.\n",
              },
            },
          },
          {
            id: '33744.C.1.4_3',
            choice: {
              texts: [
                {
                  text: 'movement\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: { content: 'What do we know about the water?\n' },
            },
          },
        ],
        answers: ['33744.C.1.4_1'],
        associatedSuccessCriteria: ['33744.C.1'],
      },
      feedbackSecondIncorrect: { content: 'In tells us where the water is.\n' },
    },
    {
      id: '33744.C.1.5',
      templateID: 0,
      complexity: 'Application',
      hint: [
        {
          id: '33744.C.1.5.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recGDy62dlFjIAFzn',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_01.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y su propósito.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: 'Determine if the preposition in the sentence is referring to time, a place, or movement.\n\n**He was running toward the door.**\n',
        },
        answerChoices: [
          {
            id: '33744.C.1.5_1',
            choice: {
              texts: [
                {
                  text: 'movement\n',
                },
              ],
            },
          },
          {
            id: '33744.C.1.5_2',
            choice: {
              texts: [
                {
                  text: 'time\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: "I don't know when he was running.\n",
              },
            },
          },
          {
            id: '33744.C.1.5_3',
            choice: {
              texts: [
                {
                  text: 'place\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: { content: 'Look again!\n' },
            },
          },
        ],
        answers: ['33744.C.1.5_1'],
        associatedSuccessCriteria: ['33744.C.1'],
      },
      feedbackSecondIncorrect: {
        content: 'Toward tells us how the person is moving.\n',
      },
    },
    {
      id: '33744.C.1.6',
      templateID: 0,
      complexity: 'Application',
      hint: [
        {
          id: '33744.C.1.6.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recGDy62dlFjIAFzn',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_01.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y su propósito.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: 'Determine if the preposition in the sentence is referring to time, a place, or movement.\n\n**The train went through the tunnel.**\n',
        },
        answerChoices: [
          {
            id: '33744.C.1.6_1',
            choice: {
              texts: [
                {
                  text: 'movement\n',
                },
              ],
            },
          },
          {
            id: '33744.C.1.6_2',
            choice: {
              texts: [
                {
                  text: 'time\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Do you know when the train went through the tunnel?\n',
              },
            },
          },
          {
            id: '33744.C.1.6_3',
            choice: {
              texts: [
                {
                  text: 'place\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: 'What is the train doing in the tunnel?\n',
              },
            },
          },
        ],
        answers: ['33744.C.1.6_1'],
        associatedSuccessCriteria: ['33744.C.1'],
      },
      feedbackSecondIncorrect: {
        content: '_Through_ tell us how the train is moving.\n',
      },
    },
    {
      id: '33744.C.1.7',
      templateID: 5,
      complexity: 'Application',
      hint: [
        {
          id: '33744.C.1.7.0',
          type: 'BasicSlide',
          data: {
            title:
              'Complete the prepositional phrase with an object to help you.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Which prepositions show where an object is? Choose 3.\n',
        },
        answerChoices: [
          {
            id: '33744.C.1.7_1',
            choice: {
              texts: [
                {
                  text: 'next to\n',
                },
              ],
            },
          },
          {
            id: '33744.C.1.7_2',
            choice: {
              texts: [
                {
                  text: 'between\n',
                },
              ],
            },
          },
          {
            id: '33744.C.1.7_3',
            choice: {
              texts: [
                {
                  text: 'under\n',
                },
              ],
            },
          },
          {
            id: '33744.C.1.7_4',
            choice: {
              texts: [
                {
                  text: 'since\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'The preposition _since_ refers to time. We need a preposition for location.\n',
              },
            },
          },
          {
            id: '33744.C.1.7_5',
            choice: {
              texts: [
                {
                  text: 'toward\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: { content: 'This feels like movement!\n' },
            },
          },
          {
            id: '33744.C.1.7_6',
            choice: {
              texts: [
                {
                  text: 'for\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  "Without more information, we can't tell whether this is a preposition or a conjunction.\n",
              },
            },
          },
        ],
        answers: ['33744.C.1.7_1', '33744.C.1.7_2', '33744.C.1.7_3'],
        associatedSuccessCriteria: ['33744.C.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'These prepositions give us the specific location of an object.\n',
      },
    },
    {
      id: '33744.C.1.8',
      templateID: 5,
      complexity: 'Application',
      hint: [
        {
          id: '33744.C.1.8.0',
          type: 'BasicSlide',
          data: {
            title:
              'Complete the prepositional phrase with an object to help you.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Which prepositions show when something occurs?\nChoose 3.\n',
        },
        answerChoices: [
          {
            id: '33744.C.1.8_1',
            choice: {
              texts: [
                {
                  text: 'after\n',
                },
              ],
            },
          },
          {
            id: '33744.C.1.8_2',
            choice: {
              texts: [
                {
                  text: 'during\n',
                },
              ],
            },
          },
          {
            id: '33744.C.1.8_3',
            choice: {
              texts: [
                {
                  text: 'before\n',
                },
              ],
            },
          },
          {
            id: '33744.C.1.8_4',
            choice: {
              texts: [
                {
                  text: 'next to\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: { content: '_Next to_ gives you a location.\n' },
            },
          },
          {
            id: '33744.C.1.8_5',
            choice: {
              texts: [
                {
                  text: 'with\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: "_With_ doesn't really give a time.\n",
              },
            },
          },
          {
            id: '33744.C.1.8_6',
            choice: {
              texts: [
                {
                  text: 'through\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: { content: 'Does this tell you when?\n' },
            },
          },
        ],
        answers: ['33744.C.1.8_1', '33744.C.1.8_2', '33744.C.1.8_3'],
        associatedSuccessCriteria: ['33744.C.1'],
      },
      feedbackSecondIncorrect: {
        content: 'These prepositions tell us when something is happening.\n',
      },
    },
    {
      id: '33744.C.1.9',
      templateID: 5,
      complexity: 'Application',
      hint: [
        {
          id: '33744.C.1.9.0',
          type: 'BasicSlide',
          data: {
            title:
              'Complete the prepositional phrase with an object to help you.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Which prepositions show the movement of an object?\nChoose 3.\n',
        },
        answerChoices: [
          {
            id: '33744.C.1.9_1',
            choice: {
              texts: [
                {
                  text: 'toward\n',
                },
              ],
            },
          },
          {
            id: '33744.C.1.9_2',
            choice: {
              texts: [
                {
                  text: 'into\n',
                },
              ],
            },
          },
          {
            id: '33744.C.1.9_3',
            choice: {
              texts: [
                {
                  text: 'through\n',
                },
              ],
            },
          },
          {
            id: '33744.C.1.9_4',
            choice: {
              texts: [
                {
                  text: 'beneath\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: '_Beneath_ tells you where something is.\n',
              },
            },
          },
          {
            id: '33744.C.1.9_5',
            choice: {
              texts: [
                {
                  text: 'above\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: { content: '_Above_ describes a location.\n' },
            },
          },
          {
            id: '33744.C.1.9_6',
            choice: {
              texts: [
                {
                  text: 'during\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: 'This is to show a length of time.\n',
              },
            },
          },
        ],
        answers: ['33744.C.1.9_1', '33744.C.1.9_2', '33744.C.1.9_3'],
        associatedSuccessCriteria: ['33744.C.1'],
      },
      feedbackSecondIncorrect: {
        content: 'These prepositions show something is moving.\n',
      },
    },
    {
      id: '33744.C.1.10',
      templateID: 5,
      complexity: 'Application',
      hint: [
        {
          id: '33744.C.1.10.0',
          type: 'BasicSlide',
          data: {
            title: 'Look and see if the animals are in motion or not.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Which prepositions apply to the animals in the picture?\nChoose 3.\n',
        },
        answerChoices: [
          {
            id: '33744.C.1.10_1',
            choice: {
              texts: [
                {
                  text: 'under\n',
                },
              ],
            },
          },
          {
            id: '33744.C.1.10_2',
            choice: {
              texts: [
                {
                  text: 'on\n',
                },
              ],
            },
          },
          {
            id: '33744.C.1.10_3',
            choice: {
              texts: [
                {
                  text: 'over\n',
                },
              ],
            },
          },
          {
            id: '33744.C.1.10_4',
            choice: {
              texts: [
                {
                  text: 'during\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '_During_ tells us about **when** something happens. This question is focused on **where** the animals are in the picture.\n',
              },
            },
          },
          {
            id: '33744.C.1.10_5',
            choice: {
              texts: [
                {
                  text: 'since\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '_Since_ tell us about **when** something happens. This question is focused on **where** the animals are in the picture.\n',
              },
            },
          },
        ],
        answers: ['33744.C.1.10_1', '33744.C.1.10_2', '33744.C.1.10_3'],
        associatedSuccessCriteria: ['33744.C.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'These prepositions match the positions or actions of the animals.\n',
      },
      image: {
        id: 'recwc5xKwslTihP23',
        url: '/helix/TEI/SVGs/TEI_prepositions_imagegroup_01.svg',
        altText: [
          {
            language: 'en',
            altText:
              'A turtle under a table, a cat on a bed, and a horse jumping over a fence.',
          },
          {
            language: 'es',
            altText:
              'La tortuga debajo de la mesa, el gato sobre la cama y el caballo saltando sobre la valla.',
          },
        ],
      },
    },
  ],
  activityManifest: [
    {
      type: 'START_SCREEN',
    },
    {
      type: 'ENTRY_SURVEY',
    },
    {
      type: 'VIDEO',
      id: 'recg9H3LbyQIpJyq3',
    },
    {
      type: 'QUESTION',
      id: '33744.C.1.1',
    },
    {
      type: 'QUESTION',
      id: '33744.C.1.2',
    },
    {
      type: 'QUESTION',
      id: '33744.C.1.3',
    },
    {
      type: 'QUESTION',
      id: '33744.C.1.4',
    },
    {
      type: 'QUESTION',
      id: '33744.C.1.5',
    },
    {
      type: 'QUESTION',
      id: '33744.C.1.6',
    },
    {
      type: 'QUESTION',
      id: '33744.C.1.7',
    },
    {
      type: 'QUESTION',
      id: '33744.C.1.8',
    },
    {
      type: 'QUESTION',
      id: '33744.C.1.9',
    },
    {
      type: 'QUESTION',
      id: '33744.C.1.10',
    },
    {
      type: 'EXIT_SURVEY',
    },
    {
      type: 'RESULTS_SCREEN',
    },
  ],
  version: '1562962166',
  instructionsImage: {
    id: 'recqnzfDjMQK7N7kr',
    url: '/helix/UX_Images/Characters/CAT/CAT_Chibi_Happy_Eyes_Open_Mouth_Open.svg',
    altText: [
      { language: 'en', altText: 'CAT is smiling.' },
      { language: 'es', altText: 'G.A.T.A está sonriendo.' },
    ],
  },
  characterLeanout: {
    id: 'svvjeewfjhHFEFgef',
    url: '/helix/TEI/Leanouts/Estrella_Leanout_01.svg',
    altText: [
      {
        language: 'en',
        altText: 'Estrella leans out from the side of the screen.',
      },
      {
        language: 'es',
        altText: 'Estrella se asoma por un lado de la pantalla.',
      },
    ],
  },
};
