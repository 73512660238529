/*
 * Complexity of TEIs(technology enhanced items)
 * From curriculum, something like Blooms, DOK, Hess' Cognitive Rigor
 * ex. Remembering, Understanding, Applying, Analyzing, Evaluating, Creating
 */
export type TEIComplexity =
  | 'Knowledge'
  | 'Comprehension'
  | 'Application'
  | 'Analysis'
  | 'Synthesis'
  | 'Evaluation';
