/**
 * These are states a questions final answered state will be in
 * @enum {number}
 */

export enum AnswerStateEnum {
  INCOMPLETE = -1,
  CORRECT = 0, //The question was answered correctly on the second attempt
  PERFECT = 1, //The question was answered correctly on the first attempt
  INCORRECT = 2, //The question was answered incorrectly
  COMPLETE = 3, // The question was completed but we cannot assess at this time
}
