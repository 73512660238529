<ul
  data-testid="htc-bulleted-list"
  [style.--grid-gap]="gridGap"
  [style.--grid-columns]="gridColumns"
  [style.--bullet-image]="bulletImage"
  [style.--bullet-size]="bulletSize"
  [style.--bullet-padding-left]="bulletPaddingLeft"
  [style.--bullet-position]="bulletPosition"
  [style.--list-item-color]="listItemColor"
  [style.--list-border-radius]="listBorderRadius"
  [style.--bullet-padding]="bulletPadding">
  <li
    data-testid="htc-bulleted-list-item"
    class="bulleted-list-item"
    *ngFor="let item of items">
    <div
      *htcReadAloud="
        {
          id: item.id || '',
          text:
            '' + (item.text | htcMathToSpeech: (sres.lastAddedMapKey | async))
        };
        type: 'NO_PAUSE';
        theme: 'DARK'
      ">
      <htc-math-text-wrapper
        [attr.aria-label]="
          itemToString(item) | htcMathToSpeech: (sres.lastAddedMapKey | async)
        "
        [textInput]="itemToString(item)"></htc-math-text-wrapper>
    </div>
  </li>
</ul>
