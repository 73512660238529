<section
  class="carousel-slide-chart"
  tabindex="0"
  role="button"
  (click)="openChart()"
  (keydown)="onKeyPressed($event)"
  data-testid="htc-slide-chart">
  @if (data.title) {
    <p
      *htcReadAloud="
        {
          id: 'activity-title',
          text: data.title | htcMathToSpeech: (sres.lastAddedMapKey | async)
        };
        type: 'NO_PAUSE';
        theme: 'LIGHT'
      "
      data-testid="title">
      <htc-math-text-wrapper
        [attr.aria-label]="
          data.title | htcMathToSpeech: (sres.lastAddedMapKey | async)
        "
        [textInput]="data.title"></htc-math-text-wrapper>
    </p>
  }

  <htc-chart
    [chartTitle]="data.chartData?.title ?? ''"
    [data]="data.chartData?.data ?? ''"
    [isDataUrl]="data.chartData?.isUrl ?? false"
    [fitToParentElement]="true"></htc-chart>
</section>
