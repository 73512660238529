import { Activity } from 'src/app/shared/interfaces';

export const readingPrep6: Activity = {
  id: '018ea019-06f0-707a-8060-9d56b4a083fd',
  product: 'Reading',
  language: 'en',
  skillName: 'Prepositions',
  thumbnail: {
    id: 'recHVWG9D2HGASZbH',
    url: '/helix/UX_Images/Lesson_Posters/Prepositions_G4V2_Poster.jpg',
    altText: [
      {
        language: 'en',
        altText: 'Axis and CAT look at some geodes on the cockpit screen.',
      },
      {
        language: 'es',
        altText:
          'Axis y G.A.T.A. observan las mismas rocas galácticas en la pantalla de la cabina de mando.',
      },
    ],
  },
  instructionsImage: {
    id: 'recqnzfDjMQK7N7kr',
    url: '/helix/UX_Images/Characters/CAT/CAT_Chibi_Happy_Eyes_Open_Mouth_Open.svg',
    altText: [
      {
        language: 'en',
        altText: 'CAT is smiling.',
      },
      {
        language: 'es',
        altText: 'G.A.T.A. está sonriendo.',
      },
    ],
  },
  characterLeanout: {
    id: 'rec5Rylxn2J2DNIHO',
    url: '/helix/TEI/Leanouts/CAT_Leanout_01.svg',
    altText: [
      {
        language: 'en',
        altText: 'CAT leans out from the side of the screen.',
      },
      {
        language: 'es',
        altText: 'G.A.T.A. se asoma por el lado de la pantalla.',
      },
    ],
  },
  description:
    'Axis uses clues from the mission to review the types of prepositions.',
  domain: {
    text: 'Parts of Speech',
  },
  seriesName: 'Planetary Prep',
  episodeNumber: '2',
  episodeTitle: 'Cosmic Clues',
  videos: [
    {
      id: 'recRakRXA2VjyeiVF',
      url: '/helix/Reading/Grade_4/Prepositions/Episode_02/hls_streams/R_G4_Prepositions_EP02_Video.m3u8',
      posterURL: '/helix/UX_Images/Lesson_Posters/Prepositions_G4V2_Poster.jpg',
      captions: [
        {
          src: '/helix/Reading/Grade_4/Prepositions/Episode_02/R_G4_Prepositions_EP02_Video.vtt',
          srclang: 'en',
          mode: 'hidden',
          kind: 'captions',
        },
      ],
    },
  ],
  startScreenData: {
    learningTarget: [
      {
        id: '22355.B',
        learningTarget: {
          text: 'to use prepositions ',
        },
        successCriteria: [
          {
            id: '22355.B.1',
            text: 'use prepositions to show location, time, agency, and direction',
          },
        ],
      },
    ],
    background_leftImage: {
      id: 'recHVWG9D2HGASZbH',
      url: '/helix/UX_Images/Lesson_Posters/Prepositions_G4V2_Poster.jpg',
      altText: [
        {
          language: 'en',
          altText: 'Axis and CAT look at some geodes on the cockpit screen.',
        },
        {
          language: 'es',
          altText:
            'Axis y G.A.T.A. observan las mismas rocas galácticas en la pantalla de la cabina de mando.',
        },
      ],
    },
    background_rightImage: {
      id: 'recxjC353VMav5ybu',
      url: '/helix/UX_Images/Start_Screen_Blobs/Nova-Scouts_space-sky.svg',
      altText: [
        {
          language: 'en',
          altText: 'Stars in space.',
        },
        {
          language: 'es',
          altText: 'Estrellas en el espacio.',
        },
      ],
    },
    foreground: {
      id: 'recOn2Luhc6XMnbRL',
      url: '/helix/UX_Images/Start_Screen_Characters/Cat5.svg',
      altText: [
        {
          language: 'en',
          altText: 'CAT is pouncing.',
        },
        {
          language: 'es',
          altText: 'G.A.T.A. está saltando.',
        },
      ],
    },
  },
  populations: [
    {
      id: '22355.B.1.1',
      templateID: 0,
      complexity: 'Comprehension',
      hint: [
        {
          id: '22355.B.1.1.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'rec2BtqMq9YZXGM1t',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_04.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A chart displays that the four types of prepositions are Time, Location, Direction, and Agency.',
                },
                {
                  language: 'es',
                  altText:
                    'Una gráfica muestra las cuatro clases diferentes de proposiciones. ',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: 'What type of preposition is used in this sentence? \n\nAt three o’clock, I eat my snack.\n',
        },
        answerChoices: [
          {
            id: '22355.B.1.1_1',
            choice: {
              texts: [
                {
                  text: 'time\n',
                },
              ],
            },
          },
          {
            id: '22355.B.1.1_2',
            choice: {
              texts: [
                {
                  text: 'location\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  "_At three o'clock_ does not provide details about where the snack was eaten.\n",
              },
            },
          },
          {
            id: '22355.B.1.1_3',
            choice: {
              texts: [
                {
                  text: 'direction\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  "_At three o'clock_ does not provide details about about how I eat the snack.\n",
              },
            },
          },
        ],
        answers: ['22355.B.1.1_1'],
        associatedSuccessCriteria: ['22355.B.1'],
      },
      feedbackSecondIncorrect: {
        content: '_At three o’clock_ gives information about time.\n',
      },
    },
    {
      id: '22355.B.1.2',
      templateID: 0,
      complexity: 'Comprehension',
      hint: [
        {
          id: '22355.B.1.2.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'rec2BtqMq9YZXGM1t',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_04.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A chart displays that the four types of prepositions are Time, Location, Direction, and Agency.',
                },
                {
                  language: 'es',
                  altText:
                    'Una gráfica muestra las cuatro clases diferentes de proposiciones. ',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: 'What type of preposition is used in this sentence? \n\nThe school bus picked me up near my house.\n',
        },
        answerChoices: [
          {
            id: '22355.B.1.2_1',
            choice: {
              texts: [
                {
                  text: 'location\n',
                },
              ],
            },
          },
          {
            id: '22355.B.1.2_2',
            choice: {
              texts: [
                {
                  text: 'time\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '_Near my house_ does not give information about when the school bus arrives.\n',
              },
            },
          },
          {
            id: '22355.B.1.2_3',
            choice: {
              texts: [
                {
                  text: 'agency\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '_Near my house_ does not give information about who is doing something.\n',
              },
            },
          },
        ],
        answers: ['22355.B.1.2_1'],
        associatedSuccessCriteria: ['22355.B.1'],
      },
      feedbackSecondIncorrect: {
        content: '_Near my house_ gives information about location.\n',
      },
    },
    {
      id: '22355.B.1.3',
      templateID: 0,
      complexity: 'Comprehension',
      hint: [
        {
          id: '22355.B.1.3.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'rec2BtqMq9YZXGM1t',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_04.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A chart displays that the four types of prepositions are Time, Location, Direction, and Agency.',
                },
                {
                  language: 'es',
                  altText:
                    'Una gráfica muestra las cuatro clases diferentes de proposiciones. ',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: 'What type of preposition is used in this sentence?\n \nThe muffins were made by my sister. \n',
        },
        answerChoices: [
          {
            id: '22355.B.1.3_1',
            choice: {
              texts: [
                {
                  text: 'agency\n',
                },
              ],
            },
          },
          {
            id: '22355.B.1.3_2',
            choice: {
              texts: [
                {
                  text: 'direction\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '_By my sister_ does not provide details about how she made the muffins.\n',
              },
            },
          },
          {
            id: '22355.B.1.3_3',
            choice: {
              texts: [
                {
                  text: 'time\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '_By my sister_ does not give information about when the muffins were made.\n',
              },
            },
          },
        ],
        answers: ['22355.B.1.3_1'],
        associatedSuccessCriteria: ['22355.B.1'],
      },
      feedbackSecondIncorrect: {
        content: '_By my sister_ gives information about agency.\n',
      },
    },
    {
      id: '22355.B.1.4',
      templateID: 0,
      complexity: 'Comprehension',
      hint: [
        {
          id: '22355.B.1.4.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'rec2BtqMq9YZXGM1t',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_04.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A chart displays that the four types of prepositions are Time, Location, Direction, and Agency.',
                },
                {
                  language: 'es',
                  altText:
                    'Una gráfica muestra las cuatro clases diferentes de proposiciones. ',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: 'What type of preposition is used in this sentence? \n\nThe ducks walked toward the lake. \n',
        },
        answerChoices: [
          {
            id: '22355.B.1.4_1',
            choice: {
              texts: [
                {
                  text: 'direction\n',
                },
              ],
            },
          },
          {
            id: '22355.B.1.4_2',
            choice: {
              texts: [
                {
                  text: 'time\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '_Toward the lake_ does not give information about when the ducks walked.\n',
              },
            },
          },
          {
            id: '22355.B.1.4_3',
            choice: {
              texts: [
                {
                  text: 'agency\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '_Toward the lake_ does not give information about who was walking.\n',
              },
            },
          },
        ],
        answers: ['22355.B.1.4_1'],
        associatedSuccessCriteria: ['22355.B.1'],
      },
      feedbackSecondIncorrect: {
        content: '_Toward the lake_ gives information about direction.\n',
      },
    },
    {
      id: '22355.B.1.5',
      templateID: 5,
      complexity: 'Application',
      hint: [
        {
          id: '22355.B.1.5.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'rec2BtqMq9YZXGM1t',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_04.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A chart displays that the four types of prepositions are Time, Location, Direction, and Agency.',
                },
                {
                  language: 'es',
                  altText:
                    'Una gráfica muestra las cuatro clases diferentes de proposiciones. ',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: 'Which prepositions show location? (Pick 3)\n',
        },
        answerChoices: [
          {
            id: '22355.B.1.5_1',
            choice: {
              texts: [
                {
                  text: 'above\n',
                },
              ],
            },
          },
          {
            id: '22355.B.1.5_2',
            choice: {
              texts: [
                {
                  text: 'between\n',
                },
              ],
            },
          },
          {
            id: '22355.B.1.5_3',
            choice: {
              texts: [
                {
                  text: 'outside\n',
                },
              ],
            },
          },
          {
            id: '22355.B.1.5_4',
            choice: {
              texts: [
                {
                  text: 'during\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '_During_ is a preposition of time, not of location.\n',
              },
            },
          },
          {
            id: '22355.B.1.5_5',
            choice: {
              texts: [
                {
                  text: 'with\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '_With_ is a preposition of agency, not of location.\n',
              },
            },
          },
          {
            id: '22355.B.1.5_6',
            choice: {
              texts: [
                {
                  text: 'because\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '_Because_ is a preposition of cause, not of location.\n',
              },
            },
          },
        ],
        answers: ['22355.B.1.5_1', '22355.B.1.5_2', '22355.B.1.5_3'],
        associatedSuccessCriteria: ['22355.B.1'],
      },
      feedbackSecondIncorrect: {
        content:
          '_Above_, _between_, and _outside_ are prepositions of location and provide information about where something is.\n',
      },
    },
    {
      id: '22355.B.1.6',
      templateID: 5,
      complexity: 'Application',
      hint: [
        {
          id: '22355.B.1.6.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'rec2BtqMq9YZXGM1t',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_04.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A chart displays that the four types of prepositions are Time, Location, Direction, and Agency.',
                },
                {
                  language: 'es',
                  altText:
                    'Una gráfica muestra las cuatro clases diferentes de proposiciones. ',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: 'Which prepositions show time? (Pick 3)\n',
        },
        answerChoices: [
          {
            id: '22355.B.1.6_1',
            choice: {
              texts: [
                {
                  text: 'after\n',
                },
              ],
            },
          },
          {
            id: '22355.B.1.6_2',
            choice: {
              texts: [
                {
                  text: 'until\n',
                },
              ],
            },
          },
          {
            id: '22355.B.1.6_3',
            choice: {
              texts: [
                {
                  text: 'during\n',
                },
              ],
            },
          },
          {
            id: '22355.B.1.6_4',
            choice: {
              texts: [
                {
                  text: 'below\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '_Below_ is a preposition of location or direction, not of time.\n',
              },
            },
          },
          {
            id: '22355.B.1.6_5',
            choice: {
              texts: [
                {
                  text: 'with\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: '_With_ is a preposition of agency, not of time.\n',
              },
            },
          },
          {
            id: '22355.B.1.6_6',
            choice: {
              texts: [
                {
                  text: 'toward\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '_Toward_ is a preposition of direction, not of time.\n',
              },
            },
          },
        ],
        answers: ['22355.B.1.6_1', '22355.B.1.6_2', '22355.B.1.6_3'],
        associatedSuccessCriteria: ['22355.B.1'],
      },
      feedbackSecondIncorrect: {
        content:
          '_After_, _until_, and _during_ are prepositions of time and answer the question of “when.”\n',
      },
    },
    {
      id: '22355.B.1.7',
      templateID: 5,
      complexity: 'Application',
      hint: [
        {
          id: '22355.B.1.7.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'rec2BtqMq9YZXGM1t',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_04.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A chart displays that the four types of prepositions are Time, Location, Direction, and Agency.',
                },
                {
                  language: 'es',
                  altText:
                    'Una gráfica muestra las cuatro clases diferentes de proposiciones. ',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: 'Which prepositions show direction? (Pick 3)\n',
        },
        answerChoices: [
          {
            id: '22355.B.1.7_1',
            choice: {
              texts: [
                {
                  text: 'through\n',
                },
              ],
            },
          },
          {
            id: '22355.B.1.7_2',
            choice: {
              texts: [
                {
                  text: 'into\n',
                },
              ],
            },
          },
          {
            id: '22355.B.1.7_3',
            choice: {
              texts: [
                {
                  text: 'toward\n',
                },
              ],
            },
          },
          {
            id: '22355.B.1.7_4',
            choice: {
              texts: [
                {
                  text: 'before\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '_Before_ is a preposition of time, not of direction.\n',
              },
            },
          },
          {
            id: '22355.B.1.7_5',
            choice: {
              texts: [
                {
                  text: 'until\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '_Until_ is a preposition of time, not of direction.\n',
              },
            },
          },
          {
            id: '22355.B.1.7_6',
            choice: {
              texts: [
                {
                  text: 'for\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '_For_ is a preposition of purpose, not of direction.\n',
              },
            },
          },
        ],
        answers: ['22355.B.1.7_1', '22355.B.1.7_2', '22355.B.1.7_3'],
        associatedSuccessCriteria: ['22355.B.1'],
      },
      feedbackSecondIncorrect: {
        content:
          '_Through_, _into_, and _toward_ are prepositions of direction.\n',
      },
    },
    {
      id: '22355.B.1.8',
      templateID: 1,
      complexity: 'Application',
      hint: [
        {
          id: '22355.B.1.8.0',
          type: 'BasicSlide',
          data: {
            title:
              'A prepositional phrase consists of a preposition and an object to give information about time, location, direction, or agency.\n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Choose the BEST prepositional phrase that shows time:\n\nMy mom dropped us off {CHOICE_TOKEN}.\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '22355.B.1.8_1',
                  text: 'around 7:30\n',
                },
                {
                  id: '22355.B.1.8_2',
                  text: 'with my brother\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        '_With my brother_ gives information about agency; it does not provide details about time.\n',
                    },
                  },
                },
                {
                  id: '22355.B.1.8_3',
                  text: 'in front of the movie theater\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        '_In front of the movie theater_ provides details about location; it does not give information about time.\n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['22355.B.1.8_1'],
        associatedSuccessCriteria: ['22355.B.1'],
      },
      feedbackSecondIncorrect: {
        content: '_Around 7:30_ gives information about time.\n',
      },
      image: {
        id: 'recWjgdyBPa7Bepsx',
        url: '/helix/TEI/SVGs/TEI_prepositions_boygirlmovietheater_01.svg',
        altText: [
          {
            language: 'en',
            altText:
              'A boy and girl stand in front of a movie theater while a car drives by.',
          },
          {
            language: 'es',
            altText:
              'Un niño y una niña parados frente a un cine mientras pasa un automóvil.',
          },
        ],
      },
    },
    {
      id: '22355.B.1.9',
      templateID: 1,
      complexity: 'Application',
      hint: [
        {
          id: '22355.B.1.9.0',
          type: 'BasicSlide',
          data: {
            title:
              'A prepositional phrase consists of a preposition and an object to give information about time, location, direction, or agency.\n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Choose the BEST prepositional phrase that shows location:\n\nMy mom dropped us off {CHOICE_TOKEN}.\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '22355.B.1.9_1',
                  text: 'in front of the movie theater\n',
                },
                {
                  id: '22355.B.1.9_2',
                  text: 'with my brother\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        '_With my brother_ gives information about agency; it does not provide details about location.\n',
                    },
                  },
                },
                {
                  id: '22355.B.1.9_3',
                  text: 'around 7:30\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        '_Around 7:30_ provides details about when; it does not give information about location.\n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['22355.B.1.9_1'],
        associatedSuccessCriteria: ['22355.B.1'],
      },
      feedbackSecondIncorrect: {
        content:
          '_In front of the movie theater_ gives information about location.\n',
      },
      image: {
        id: 'recWjgdyBPa7Bepsx',
        url: '/helix/TEI/SVGs/TEI_prepositions_boygirlmovietheater_01.svg',
        altText: [
          {
            language: 'en',
            altText:
              'A boy and girl stand in front of a movie theater while a car drives by.',
          },
          {
            language: 'es',
            altText:
              'Un niño y una niña parados frente a un cine mientras pasa un automóvil.',
          },
        ],
      },
    },
    {
      id: '22355.B.1.10',
      templateID: 1,
      complexity: 'Application',
      hint: [
        {
          id: '22355.B.1.10.0',
          type: 'BasicSlide',
          data: {
            title:
              'A prepositional phrase consists of a preposition and an object to give information about time, location, direction, or agency.\n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Choose the BEST prepositional phrase that shows agency:\n\nMy mom dropped us off {CHOICE_TOKEN}.\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '22355.B.1.10_1',
                  text: 'with my brother\n',
                },
                {
                  id: '22355.B.1.10_2',
                  text: 'around 7:30\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        '_Around 7:30_ provides details about when; it does not give information about agency.\n',
                    },
                  },
                },
                {
                  id: '22355.B.1.10_3',
                  text: 'in front of the movie theater\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        '_In front of the movie theater_ provides details about location; it does not give information about agency.\n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['22355.B.1.10_1'],
        associatedSuccessCriteria: ['22355.B.1'],
      },
      feedbackSecondIncorrect: {
        content: '_With my brother_ gives information about agency.\n',
      },
      image: {
        id: 'recWjgdyBPa7Bepsx',
        url: '/helix/TEI/SVGs/TEI_prepositions_boygirlmovietheater_01.svg',
        altText: [
          {
            language: 'en',
            altText:
              'A boy and girl stand in front of a movie theater while a car drives by.',
          },
          {
            language: 'es',
            altText:
              'Un niño y una niña parados frente a un cine mientras pasa un automóvil.',
          },
        ],
      },
    },
  ],
  activityManifest: [
    {
      type: 'START_SCREEN',
    },
    {
      type: 'ENTRY_SURVEY',
    },
    {
      type: 'VIDEO',
      id: 'recRakRXA2VjyeiVF',
    },
    {
      type: 'QUESTION',
      id: '22355.B.1.1',
    },
    {
      type: 'QUESTION',
      id: '22355.B.1.2',
    },
    {
      type: 'QUESTION',
      id: '22355.B.1.3',
    },
    {
      type: 'QUESTION',
      id: '22355.B.1.4',
    },
    {
      type: 'QUESTION',
      id: '22355.B.1.5',
    },
    {
      type: 'QUESTION',
      id: '22355.B.1.6',
    },
    {
      type: 'QUESTION',
      id: '22355.B.1.7',
    },
    {
      type: 'QUESTION',
      id: '22355.B.1.8',
    },
    {
      type: 'QUESTION',
      id: '22355.B.1.9',
    },
    {
      type: 'QUESTION',
      id: '22355.B.1.10',
    },
    {
      type: 'EXIT_SURVEY',
    },
    {
      type: 'RESULTS_SCREEN',
    },
  ],
  version: '1562962166',
};
