import { UserService } from './../../../../shared/services/user/user.service';
import { MessageService } from './../../../message/services/message/message.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { TrapFocusDirective } from 'src/app/shared/directives/trap-focus/trap-focus.directive';
import { MessageCodes, TriggerActionTypes } from 'src/app/shared/enums';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';
import { PopupService } from 'src/app/shared/services/popup/popup.service';
import { AudioDescriptor } from 'src/app/shared/enums/audio-descriptor';
import { AudioType } from 'src/app/shared/enums/audio-type';
import { FeatureFlagService } from 'src/app/modules/feature-flags/services/feature-flag/feature-flag.service';
import { FeatureFlags } from 'src/app/shared/enums/feature-flags';
import { ReadAloudService } from 'src/app/modules/read-aloud/services';

@Component({
  selector: 'htc-profile-popup',
  templateUrl: './profile-popup.component.html',
  styleUrls: ['./profile-popup.component.scss'],
})
export class ProfilePopupComponent implements AfterViewInit {
  @Input() avatar: number | null = null;
  @Input() name = '';

  @Input() showProfile = false;
  @Output() showProfileChange = new EventEmitter<boolean>();

  @Input() supportMenu = false;
  @Output() supportMenuChange = new EventEmitter<boolean>();

  @Input() settingsMenu = false;
  @Output() settingsMenuChange = new EventEmitter<boolean>();

  @ViewChild('btnAvatar') btnAvatar!: ElementRef;
  @ViewChildren(TrapFocusDirective) focused!: QueryList<TrapFocusDirective>;
  clickDescriptor = AudioDescriptor.CLICK_GENERAL;
  clickType = AudioType.audio_SfxClick;

  triggers: string[] = [];

  version = environment.version;

  readonly featureFlags = FeatureFlags;

  constructor(
    public featureFlagService: FeatureFlagService,
    public userService: UserService,
    private messageService: MessageService,
    private oidcSecurityService: OidcSecurityService,
    private popupService: PopupService,
    private readAloudService: ReadAloudService
  ) {}

  ngAfterViewInit(): void {
    this.focused.map(el => el.trapFocus());
    this.readAloudService.stopCurrent();
  }

  @HostListener('keydown.escape', ['$event'])
  @HostListener('keydown.esc', ['$event']) // For IE11 coverage
  closeProfile(event?: Event): void {
    if (this.showProfile) {
      this.readAloudService.stopCurrent();
      this.showProfile = false;
      this.showProfileChange.emit(this.showProfile);

      // if close profile menu with ESC key, then trigger click to play SFX
      if (event) {
        document.getElementById('profile-menu-close-btn')?.click();
      }
    }
  }

  handleLogout(): void {
    this.oidcSecurityService.logoffAndRevokeTokens().subscribe(g => g);
  }

  logoutPopup(): void {
    this.readAloudService.stopCurrent();
    this.messageService.showMessage(MessageCodes.APP_LOGOUT_CONFIRM);
  }

  returnFocusToAvatarBtn(): void {
    this.btnAvatar.nativeElement.focus();
  }

  handleTrigger(trigger: TriggerActionTypes): void {
    if (trigger === TriggerActionTypes.LOGOUT) {
      this.handleLogout();
    }
  }

  avatarClicked(): void {
    this.showProfile = !this.showProfile;
    this.showProfileChange.emit(this.showProfile);
  }

  handleSupportPopup(event: Event): void {
    this.readAloudService.stopCurrent();
    const toggle = !this.supportMenu;
    this.popupService.setActiveElement(event.currentTarget as HTMLElement);
    this.supportMenuChange.emit(toggle);
  }

  handleSettingsPopup(event: Event): void {
    this.readAloudService.stopCurrent();
    const toggle = !this.settingsMenu;
    this.popupService.setActiveElement(event.currentTarget as HTMLElement);
    this.settingsMenuChange.emit(toggle);
  }
}
