<button
  [attr.data-testid]="testId"
  class="popup-toolbar-button"
  [ngClass]="{
    'popup-toolbar-button-selected': isSelected,
    'popup-toolbar-button-unselected': !isSelected,
    'video-selected': toolName === 'Video',
    hover: isHovering
  }"
  type="button"
  attr.aria-label="{{ toolName }} {{ 'TOOL_BAR.TOGGLE' | translate }}"
  (mouseover)="openTooltip()"
  (mouseout)="closeTooltip()"
  (click)="onClick($event)">
  <div class="float-shadow"></div>
  <div class="tooltip">
    <div class="tooltip-text">{{ toolName }}</div>
  </div>
  <svg-icon
    src="{{ path + (isSelected && toggledIcon ? toggledIcon : icon) }}"
    attr.alt="{{ toolName }} {{ 'TOOL_BAR.TOGGLE' | translate }}"></svg-icon>
</button>
