import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LottiePlayerComponent } from './components/lottie-player/lottie-player.component';

@NgModule({
  declarations: [LottiePlayerComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule],
  exports: [LottiePlayerComponent],
})
export class LottiePlayerModule {}
