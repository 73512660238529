import { TemplateID } from '../shared/enums';
import {
  AnswerChoice,
  PopulationDragAndDrop,
  PopulationInlineChoice,
  PopulationMultipleChoice,
  TargetedFeedback,
} from '../shared/interfaces';
import { PopulationTextEntry } from '../shared/interfaces/population-text-entry';
import { PopulationData } from '../shared/types/population-data';
import { DynamicDnDEvaluator } from './dynamic-dnd-evaluator';
import { InlineChoiceEvaluator } from './inline-choice-evaluator';
import { MultiChoiceEvaluator } from './multi-choice-evaluator';
import { TextEntryEvaluator } from './text-entry-evaluator';

const multiChoiceTypes = [
  TemplateID.MULTIPLE_CHOICE,
  TemplateID.MULTIPLE_CHOICE_IMAGE,
  TemplateID.MULTI_SELECT,
  TemplateID.MULTI_SELECT_IMAGE,
  TemplateID.TRUE_FALSE_CHOICE,
];
const inlineChoiceTypes = [
  TemplateID.INLINE_CHOICE,
  TemplateID.STATIC_HORIZONTAL_SEQUENCING,
  TemplateID.STATIC_VERTICAL_SEQUENCING,
  TemplateID.FILL_IN_THE_BLANK_TILE_TEXT,
];
const textEntryTypes = [TemplateID.TEXT_ENTRY];
const dynamicDnDTypes = [
  TemplateID.DYNAMIC_HORIZONTAL_SEQUENCING,
  TemplateID.SORTING_BUCKETS,
  TemplateID.SORTING_BUCKETS_IMAGES,
];
export const nonAssessableTypes = [
  TemplateID.CONSTRUCTED_RESPONSE,
  TemplateID.CONSTRUCTED_RESPONSE_WITH_RUBRIC,
  TemplateID.EXTENDED_CONSTRUCTED_RESPONSE,
  TemplateID.EXTENDED_CONSTRUCTED_RESPONSE_WITH_RUBRIC,
];

export class EvaluatorFactory {
  static isCorrect(
    type: TemplateID,
    population: PopulationData,
    answers: (string | number)[][]
  ): boolean {
    if (multiChoiceTypes.includes(type)) {
      return MultiChoiceEvaluator.isCorrect(
        population as PopulationMultipleChoice,
        answers[0] as string[]
      );
    }

    if (inlineChoiceTypes.includes(type)) {
      return InlineChoiceEvaluator.isCorrect(
        population as PopulationInlineChoice,
        answers[0] as string[]
      );
    }

    if (textEntryTypes.includes(type)) {
      return TextEntryEvaluator.isCorrect(
        population as PopulationTextEntry,
        answers
      );
    }

    if (dynamicDnDTypes.includes(type)) {
      return DynamicDnDEvaluator.isCorrect(
        population as PopulationDragAndDrop,
        answers,
        type === TemplateID.DYNAMIC_HORIZONTAL_SEQUENCING
      );
    }

    return false;
  }

  static getWrongAnswers(
    type: TemplateID,
    population: PopulationData,
    answers: (string | number)[][],
    disabledAnswers: (string | number)[][]
  ): (string | number)[][] {
    if (multiChoiceTypes.includes(type)) {
      return [
        [
          ...disabledAnswers[0],
          ...MultiChoiceEvaluator.getWrongAnswers(
            population as PopulationMultipleChoice,
            answers[0] as string[]
          ),
        ],
      ];
    }

    if (inlineChoiceTypes.includes(type)) {
      return InlineChoiceEvaluator.getWrongAnswers(
        population as PopulationInlineChoice,
        answers[0] as string[]
      ).map((wrong, index) => [...wrong, ...(disabledAnswers[index] || [])]);
    }

    if (textEntryTypes.includes(type)) {
      return TextEntryEvaluator.getWrongAnswers(
        population as PopulationTextEntry,
        answers
      ).map((wrong, index) => [...wrong, ...(disabledAnswers[index] || [])]);
    }

    if (dynamicDnDTypes.includes(type)) {
      return DynamicDnDEvaluator.getWrongAnswers(
        population as PopulationDragAndDrop,
        answers,
        type === TemplateID.DYNAMIC_HORIZONTAL_SEQUENCING
      ).map((wrong, index) => [...wrong, ...(disabledAnswers[index] || [])]);
    }

    return [[]];
  }

  static getSelectedWrongAnswers(
    type: TemplateID,
    population: PopulationData,
    answers: (string | number)[][]
  ): (string | number)[][] {
    if (multiChoiceTypes.includes(type)) {
      return [
        [
          ...MultiChoiceEvaluator.getWrongAnswers(
            population as PopulationMultipleChoice,
            answers[0] as string[]
          ),
        ],
      ];
    }

    if (inlineChoiceTypes.includes(type)) {
      return InlineChoiceEvaluator.getWrongAnswers(
        population as PopulationInlineChoice,
        answers[0] as string[]
      ).map(wrong => [...wrong]);
    }

    if (textEntryTypes.includes(type)) {
      return TextEntryEvaluator.getWrongAnswers(
        population as PopulationTextEntry,
        answers
      ).map(wrong => [...wrong]);
    }

    if (dynamicDnDTypes.includes(type)) {
      return DynamicDnDEvaluator.getWrongAnswers(
        population as PopulationDragAndDrop,
        answers,
        type === TemplateID.DYNAMIC_HORIZONTAL_SEQUENCING
      ).map(wrong => [...wrong]);
    }

    return [[]];
  }

  static getSelectedWrongTargetedFeedback(
    type: TemplateID,
    selectedWrongAnswers: (number | string)[][],
    answerChoices: AnswerChoice[],
    population: PopulationData
  ): TargetedFeedback[] {
    if (multiChoiceTypes.includes(type)) {
      return MultiChoiceEvaluator.getSelectedWrongTargetedFeedback(
        selectedWrongAnswers,
        answerChoices
      );
    }

    if (inlineChoiceTypes.includes(type)) {
      return InlineChoiceEvaluator.getSelectedWrongTargetedFeedback(
        selectedWrongAnswers,
        answerChoices
      );
    }

    if (textEntryTypes.includes(type)) {
      return TextEntryEvaluator.getSelectedWrongTargetedFeedback(
        selectedWrongAnswers,
        population as PopulationTextEntry
      );
    }

    if (dynamicDnDTypes.includes(type)) {
      return DynamicDnDEvaluator.getSelectedWrongTargetedFeedback(
        selectedWrongAnswers,
        answerChoices
      );
    }

    return [];
  }

  static removeWrongAnswers(
    type: TemplateID,
    wrong: (string | number)[][],
    answers: (string | number)[][]
  ): (string | number)[][] {
    if (multiChoiceTypes.includes(type)) {
      return [
        MultiChoiceEvaluator.removeWrongAnswers(
          wrong[0] as string[],
          answers[0] as string[]
        ),
      ];
    }

    if (inlineChoiceTypes.includes(type)) {
      return [
        InlineChoiceEvaluator.removeWrongAnswers(
          wrong as string[][],
          answers[0] as string[]
        ),
      ];
    }

    if (textEntryTypes.includes(type)) {
      return TextEntryEvaluator.removeWrongAnswers(wrong, answers);
    }

    if (
      dynamicDnDTypes.includes(type) &&
      type !== TemplateID.DYNAMIC_HORIZONTAL_SEQUENCING
    ) {
      return DynamicDnDEvaluator.removeWrongAnswers(wrong, answers);
    }

    return [[]];
  }

  static isNotAssessable(type: TemplateID): boolean {
    return nonAssessableTypes.includes(type);
  }
}
