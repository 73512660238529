import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { LexicalTableComponent } from 'src/app/modules/lexical-table/components/lexical-table/lexical-table.component';

@Component({
  selector: 'htc-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements AfterViewInit, OnChanges {
  @Input() chartTitle = '';
  @Input() data: string | null = null;
  @Input() isDataUrl = false;
  @Input() fitToParentElement = false;
  @Output() chartLoaded = new EventEmitter<boolean>();

  @ViewChild(LexicalTableComponent, { static: true })
  lexicalTable!: LexicalTableComponent;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    if (this.lexicalTable && this.data) {
      this.setDataAndParse();
    } else {
      console.error(`Issue setting state on lexicalTable`);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.setDataAndParse();
    }
  }

  async setDataAndParse(): Promise<void> {
    if (this.isDataUrl && this.data) {
      await fetch(this.data)
        .then(res => {
          return res.json();
        })
        .then(data => {
          this.setEditorState(JSON.stringify(data));
        })
        .catch(err => console.error('issue fetching chart data', err));
    } else if (this.data) {
      this.setEditorState(this.data);
    }
  }

  private parseEditorStateFromJson(data: string): string {
    try {
      const parsedJson = JSON.parse(data);
      return JSON.stringify(parsedJson.editorState);
    } catch (error) {
      console.error('error parsing json', error);
      return '{}';
    }
  }

  private setEditorState(data: string): void {
    this.lexicalTable.setEditorState(this.parseEditorStateFromJson(data));
    this.chartLoaded.emit(true);
    this.changeDetectorRef.detectChanges();
  }
}
