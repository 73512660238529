import { Activity } from 'src/app/shared/interfaces';

export const lectura3: Activity = {
  id: '018ea019-0507-7c4e-b17d-305cd29b0c34',
  product: 'Lectura',
  language: 'es',
  skillName: 'Preposiciones',
  thumbnail: {
    id: 'recxcEc5etdqMvvWy',
    url: '/helix/Reading/Grade_3/Prepositions/Episode_03/R_G3_Prepositions_EP03_Poster.png',
    altText: [
      { language: 'en', altText: 'Axis and CAT stand on a beach.' },
      { language: 'es', altText: 'Axis y G.A.T.A. estan en una playa.' },
    ],
  },
  description:
    'Luego de un aterrizaje forzoso, Axis y G.A.T.A. deben conversar con los humanos para encontrar las piezas de su nave. Para ello, deben comprender cómo estos usan las preposiciones para dar instrucciones. ',
  domain: {
    text: 'Parts of Speech',
  },
  seriesName: 'Misión terrestre',
  episodeNumber: '3',
  episodeTitle: '¡Playa a la vista!',
  videos: [
    {
      id: 'recIY4IJjltzWRpNr',
      url: '/helix/Lectura/Grade_3/Prepositions/Episode_03/hls_streams/L_G3_Prepositions_Ep03_Video01.m3u8',
      posterURL:
        '/helix/Reading/Grade_3/Prepositions/Episode_03/R_G3_Prepositions_EP03_Poster.png',
      captions: [
        {
          src: '/helix/Lectura/Grade_3/Prepositions/Episode_03/L_G3_Prepositions_Ep03_Video01.vtt',
          srclang: 'es',
          mode: 'hidden',
          kind: 'captions',
        },
      ],
    },
  ],
  startScreenData: {
    learningTarget: [
      {
        id: '32597.C',
        learningTarget: {
          text: 'a cómo usar las preposiciones',
        },
        successCriteria: [
          {
            id: '32597.C.1',
            text: 'usar preposiciones para indicar lugar, tiempo y causa',
          },
        ],
      },
    ],
    background_leftImage: {
      id: 'recxcEc5etdqMvvWy',
      url: '/helix/Reading/Grade_3/Prepositions/Episode_03/R_G3_Prepositions_EP03_Poster.png',
      altText: [
        { language: 'en', altText: 'Axis and CAT stand on a beach.' },
        { language: 'es', altText: 'Axis y G.A.T.A. estan en una playa.' },
      ],
    },
    background_rightImage: {
      id: 'recxjC353VMav5ybu',
      url: '/helix/UX_Images/Start_Screen_Blobs/Nova-Scouts_space-sky.svg',
      altText: [
        { language: 'en', altText: 'Stars in space.' },
        { language: 'es', altText: 'Estrellas en el espacio.' },
      ],
    },
    foreground: {
      id: 'recOn2Luhc6XMnbRL',
      url: '/helix/UX_Images/Start_Screen_Characters/Cat5.svg',
      altText: [
        { language: 'en', altText: 'CAT is pouncing.' },
        { language: 'es', altText: 'G.A.T.A está saltando.' },
      ],
    },
  },
  populations: [
    {
      id: '32597.C.1.1',
      templateID: 0,
      complexity: 'Application',
      hint: [
        {
          id: '32597.C.1.1.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recRORCPGztEFmN5w',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_02.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y su propósito.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: 'Determina si la preposición _«en»_ indica tiempo, lugar o causa.\n\n«Más tarde te veré en el colegio».\n',
        },
        answerChoices: [
          {
            id: '32597.C.1.1_1',
            choice: {
              texts: [
                {
                  text: 'lugar\n',
                },
              ],
            },
          },
          {
            id: '32597.C.1.1_2',
            choice: {
              texts: [
                {
                  text: 'tiempo  \n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Las preposiciones que indican tiempo te dicen cuándo algo sucederá. La preposición «en» no te dice cuándo algo sucede.\n',
              },
            },
          },
          {
            id: '32597.C.1.1_3',
            choice: {
              texts: [
                {
                  text: 'causa\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Recuerda qué tipo de preposición te indica a dónde va el sujeto.  \n',
              },
            },
          },
        ],
        answers: ['32597.C.1.1_1'],
        associatedSuccessCriteria: ['32597.C.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La respuesta correcta es «lugar» porque la preposición «en» nos indica el sitio o lugar, que sería el colegio. \n',
      },
    },
    {
      id: '32597.C.1.2',
      templateID: 0,
      complexity: 'Application',
      hint: [
        {
          id: '32597.C.1.2.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recRORCPGztEFmN5w',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_02.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y su propósito.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: 'Determina si la preposición «hasta» indica tiempo, lugar o causa.\n\n«Mi abuela se quedará hasta el invierno».\n',
        },
        answerChoices: [
          {
            id: '32597.C.1.2_1',
            choice: {
              texts: [
                {
                  text: 'tiempo \n',
                },
              ],
            },
          },
          {
            id: '32597.C.1.2_2',
            choice: {
              texts: [
                {
                  text: 'lugar\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Las preposiciones que indican lugar te dicen dónde está un objeto. La preposición «hasta» no te dice la ubicación de un objeto.   \n',
              },
            },
          },
          {
            id: '32597.C.1.2_3',
            choice: {
              texts: [
                {
                  text: 'causa\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Recuerda qué tipo de preposición te da información sobre cuándo. \n',
              },
            },
          },
        ],
        answers: ['32597.C.1.2_1'],
        associatedSuccessCriteria: ['32597.C.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La respuesta correcta es «tiempo» porque _«hasta»_ nos indica el tiempo que se quedará la abuela.\n',
      },
    },
    {
      id: '32597.C.1.3',
      templateID: 0,
      complexity: 'Application',
      hint: [
        {
          id: '32597.C.1.3.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recRORCPGztEFmN5w',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_02.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y su propósito.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: 'Determina si la preposición «tras» indica tiempo, lugar o causa.\n\n«Mi gato está escondido tras el arbusto».\n',
        },
        answerChoices: [
          {
            id: '32597.C.1.3_1',
            choice: {
              texts: [
                {
                  text: 'lugar\n',
                },
              ],
            },
          },
          {
            id: '32597.C.1.3_2',
            choice: {
              texts: [
                {
                  text: 'tiempo\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Vuelve a leer la oración, y recuerda qué tipo de preposición te indica dónde está el sujeto.  \n',
              },
            },
          },
          {
            id: '32597.C.1.3_3',
            choice: {
              texts: [
                {
                  text: 'causa\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Recuerda qué tipo de preposición te indica dónde está el sujeto.  \n',
              },
            },
          },
        ],
        answers: ['32597.C.1.3_1'],
        associatedSuccessCriteria: ['32597.C.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La respuesta correcta es «lugar» porque la preposición «tras» nos indica el sitio o lugar, que sería el arbusto. \n',
      },
    },
    {
      id: '32597.C.1.4',
      templateID: 0,
      complexity: 'Application',
      hint: [
        {
          id: '32597.C.1.4.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recRORCPGztEFmN5w',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_02.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y su propósito.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: 'Determina si la preposición en la oración habla de tiempo, lugar o causa.\n\n«Carlos nada en la piscina».\n',
        },
        answerChoices: [
          {
            id: '32597.C.1.4_1',
            choice: {
              texts: [
                {
                  text: 'lugar\n',
                },
              ],
            },
          },
          {
            id: '32597.C.1.4_2',
            choice: {
              texts: [
                {
                  text: 'causa \n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Recuerda qué tipo de preposición te indica dónde se encuentra el sujeto de la oración.  \n',
              },
            },
          },
          {
            id: '32597.C.1.4_3',
            choice: {
              texts: [
                {
                  text: 'tiempo\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Vuelve a leer la oración, y recuerda qué tipo de preposición te indica en donde se encuentra la persona. \n',
              },
            },
          },
        ],
        answers: ['32597.C.1.4_1'],
        associatedSuccessCriteria: ['32597.C.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La respuesta correcta es «lugar» porque la preposición _«en»_ nos indica el sitio en donde nada Carlos, que sería la piscina.\n',
      },
    },
    {
      id: '32597.C.1.5',
      templateID: 0,
      complexity: 'Application',
      hint: [
        {
          id: '32597.C.1.5.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recRORCPGztEFmN5w',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_02.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y su propósito.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: 'Determina si la preposición en la oración habla de tiempo, lugar o causa.\n\n«Mi mamá hizo una fiesta por mi cumpleaños».\n',
        },
        answerChoices: [
          {
            id: '32597.C.1.5_1',
            choice: {
              texts: [
                {
                  text: 'causa\n',
                },
              ],
            },
          },
          {
            id: '32597.C.1.5_2',
            choice: {
              texts: [
                {
                  text: 'tiempo\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Recuerda qué tipo de preposición te da información sobre el porqué o el motivo. \n',
              },
            },
          },
          {
            id: '32597.C.1.5_3',
            choice: {
              texts: [
                {
                  text: 'lugar\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Vuelve a leer la oración, y recuerda qué tipo de preposición te indica el motivo por el cual algo sucede. \n',
              },
            },
          },
        ],
        answers: ['32597.C.1.5_1'],
        associatedSuccessCriteria: ['32597.C.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La respuesta correcta es «causa» porque la preposición «por» nos indica el motivo por el cuál se hará una fiesta. \n',
      },
    },
    {
      id: '32597.C.1.6',
      templateID: 0,
      complexity: 'Application',
      hint: [
        {
          id: '32597.C.1.6.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recRORCPGztEFmN5w',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_02.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y su propósito.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: 'Determina si la preposición en la oración habla de tiempo, lugar o causa.\n\n«Estoy resfriado, por eso no podré jugar».\n',
        },
        answerChoices: [
          {
            id: '32597.C.1.6_1',
            choice: {
              texts: [
                {
                  text: 'causa\n',
                },
              ],
            },
          },
          {
            id: '32597.C.1.6_2',
            choice: {
              texts: [
                {
                  text: 'lugar \n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Las preposiciones que indican causa te dicen el motivo por el cual sucede algo. La preposición «por» no te indica el lugar. \n',
              },
            },
          },
          {
            id: '32597.C.1.6_3',
            choice: {
              texts: [
                {
                  text: 'tiempo\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Vuelve a leer la oración, y recuerda qué tipo de preposición te indica el motivo por el cual algo sucede. \n',
              },
            },
          },
        ],
        answers: ['32597.C.1.6_1'],
        associatedSuccessCriteria: ['32597.C.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La respuesta correcta es «causa» porque la preposición _«por»_ nos indica el motivo por el cual no podrá jugar, porque está resfriado.\n',
      },
    },
    {
      id: '32597.C.1.7',
      templateID: 5,
      complexity: 'Application',
      hint: [
        {
          id: '32597.C.1.7.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recRORCPGztEFmN5w',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_02.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y su propósito.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: '¿Qué preposiciones muestran dónde está un objeto?\n\nElige sólo tres respuestas.\n',
        },
        answerChoices: [
          {
            id: '32597.C.1.7_1',
            choice: {
              texts: [
                {
                  text: 'tras\n',
                },
              ],
            },
          },
          {
            id: '32597.C.1.7_2',
            choice: {
              texts: [
                {
                  text: 'entre\n',
                },
              ],
            },
          },
          {
            id: '32597.C.1.7_3',
            choice: {
              texts: [
                {
                  text: 'bajo\n',
                },
              ],
            },
          },
          {
            id: '32597.C.1.7_4',
            choice: {
              texts: [
                {
                  text: 'después\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Recuerda qué tipo de preposición te indica en dónde se encuentra un objeto. \n',
              },
            },
          },
          {
            id: '32597.C.1.7_5',
            choice: {
              texts: [
                {
                  text: 'con\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '«Con» es una preposición que indica el modo. Sigue buscando la preposición que te dice dónde está el objeto. \n',
              },
            },
          },
          {
            id: '32597.C.1.7_6',
            choice: {
              texts: [
                {
                  text: 'por\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Recuerda cómo se llama el tipo de preposición que te dice dónde se encuentra un objeto. \n',
              },
            },
          },
        ],
        answers: ['32597.C.1.7_1', '32597.C.1.7_2', '32597.C.1.7_3'],
        associatedSuccessCriteria: ['32597.C.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'Las preposiciones «tras», «entre» y «bajo» nos dan información sobre el sitio en donde se encuentra un objeto. \n',
      },
    },
    {
      id: '32597.C.1.8',
      templateID: 5,
      complexity: 'Application',
      hint: [
        {
          id: '32597.C.1.8.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recRORCPGztEFmN5w',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_02.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y su propósito.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: '¿Qué preposiciones muestran cuándo ocurre algo?\n\nElige sólo tres respuestas.\n',
        },
        answerChoices: [
          {
            id: '32597.C.1.8_1',
            choice: {
              texts: [
                {
                  text: 'hasta\n',
                },
              ],
            },
          },
          {
            id: '32597.C.1.8_2',
            choice: {
              texts: [
                {
                  text: 'durante\n',
                },
              ],
            },
          },
          {
            id: '32597.C.1.8_3',
            choice: {
              texts: [
                {
                  text: 'desde\n',
                },
              ],
            },
          },
          {
            id: '32597.C.1.8_4',
            choice: {
              texts: [
                {
                  text: 'para\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Recuerda qué tipo de preposición te da información sobre cuándo. \n',
              },
            },
          },
          {
            id: '32597.C.1.8_5',
            choice: {
              texts: [
                {
                  text: 'por\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '«Por» es una preposición que indica la causa. Sigue buscando la preposición que te dice cuando algo sucederá. \n',
              },
            },
          },
          {
            id: '32597.C.1.8_6',
            choice: {
              texts: [
                {
                  text: 'mediante \n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Recuerda cómo se llama el tipo de preposición que te dice cuando algo va a suceder. \n',
              },
            },
          },
        ],
        answers: ['32597.C.1.8_1', '32597.C.1.8_2', '32597.C.1.8_3'],
        associatedSuccessCriteria: ['32597.C.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'Las preposiciones «hasta», «durante» y «desde» nos dan información sobre cuándo algo sucederá. \n',
      },
    },
    {
      id: '32597.C.1.9',
      templateID: 5,
      complexity: 'Application',
      hint: [
        {
          id: '32597.C.1.9.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recRORCPGztEFmN5w',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_02.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y su propósito.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: '¿Qué preposiciones muestran por qué sucede algo?\n\nElige sólo dos respuestas.\n',
        },
        answerChoices: [
          {
            id: '32597.C.1.9_1',
            choice: {
              texts: [
                {
                  text: 'de\n',
                },
              ],
            },
          },
          {
            id: '32597.C.1.9_2',
            choice: {
              texts: [
                {
                  text: 'por\n',
                },
              ],
            },
          },
          {
            id: '32597.C.1.9_3',
            choice: {
              texts: [
                {
                  text: 'entre\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Recuerda qué tipo de preposición te da información sobre el porqué o el motivo. \n',
              },
            },
          },
          {
            id: '32597.C.1.9_4',
            choice: {
              texts: [
                {
                  text: 'sobre\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Vuelve a leer la oración, y recuerda qué tipo de preposición te indica el motivo por el cual algo sucede. \n',
              },
            },
          },
        ],
        answers: ['32597.C.1.9_1', '32597.C.1.9_2'],
        associatedSuccessCriteria: ['32597.C.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'Las preposiciones «de» y «por» nos dan información sobre el motivo por el cual algo sucede. \n',
      },
    },
    {
      id: '32597.C.1.10',
      templateID: 5,
      complexity: 'Application',
      hint: [
        {
          id: '32597.C.1.10.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recRORCPGztEFmN5w',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_02.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y su propósito.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: '¿Qué preposiciones se utilizan en las imágenes de los animales?\n',
        },
        answerChoices: [
          {
            id: '32597.C.1.10_1',
            choice: {
              texts: [
                {
                  text: 'bajo\n',
                },
              ],
            },
          },
          {
            id: '32597.C.1.10_2',
            choice: {
              texts: [
                {
                  text: 'en\n',
                },
              ],
            },
          },
          {
            id: '32597.C.1.10_3',
            choice: {
              texts: [
                {
                  text: 'sobre\n',
                },
              ],
            },
          },
          {
            id: '32597.C.1.10_4',
            choice: {
              texts: [
                {
                  text: 'por\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Recuerda qué tipo de preposición te indica en dónde se encuentra un objeto. \n',
              },
            },
          },
          {
            id: '32597.C.1.10_5',
            choice: {
              texts: [
                {
                  text: 'durante\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Lee la oración y recuerda qué tipo de preposición te da información en dónde se encuentra un objeto. \n',
              },
            },
          },
          {
            id: '32597.C.1.10_6',
            choice: {
              texts: [
                {
                  text: 'desde\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Recuerda cómo se llama el tipo de preposición que te dice dónde se encuentra un objeto. \n',
              },
            },
          },
        ],
        answers: ['32597.C.1.10_1', '32597.C.1.10_2', '32597.C.1.10_3'],
        associatedSuccessCriteria: ['32597.C.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'Las preposiciones «bajo», «en» y «sobre» nos dan información sobre el sitio en donde se encuentran los animales. \n',
      },
      image: {
        id: 'recwc5xKwslTihP23',
        url: '/helix/TEI/SVGs/TEI_prepositions_imagegroup_01.svg',
        altText: [
          {
            language: 'en',
            altText:
              'A turtle under a table, a cat on a bed, and a horse jumping over a fence.',
          },
          {
            language: 'es',
            altText:
              'La tortuga debajo de la mesa, el gato sobre la cama y el caballo saltando sobre la valla.',
          },
        ],
      },
    },
  ],
  activityManifest: [
    {
      type: 'START_SCREEN',
    },
    {
      type: 'ENTRY_SURVEY',
    },
    {
      type: 'VIDEO',
      id: 'recIY4IJjltzWRpNr',
    },
    {
      type: 'QUESTION',
      id: '32597.C.1.1',
    },
    {
      type: 'QUESTION',
      id: '32597.C.1.2',
    },
    {
      type: 'QUESTION',
      id: '32597.C.1.3',
    },
    {
      type: 'QUESTION',
      id: '32597.C.1.4',
    },
    {
      type: 'QUESTION',
      id: '32597.C.1.5',
    },
    {
      type: 'QUESTION',
      id: '32597.C.1.6',
    },
    {
      type: 'QUESTION',
      id: '32597.C.1.7',
    },
    {
      type: 'QUESTION',
      id: '32597.C.1.8',
    },
    {
      type: 'QUESTION',
      id: '32597.C.1.9',
    },
    {
      type: 'QUESTION',
      id: '32597.C.1.10',
    },
    {
      type: 'EXIT_SURVEY',
    },
    {
      type: 'RESULTS_SCREEN',
    },
  ],
  version: '1562962166',
  instructionsImage: {
    id: 'recqnzfDjMQK7N7kr',
    url: '/helix/UX_Images/Characters/CAT/CAT_Chibi_Happy_Eyes_Open_Mouth_Open.svg',
    altText: [
      { language: 'en', altText: 'CAT is smiling.' },
      { language: 'es', altText: 'G.A.T.A está sonriendo.' },
    ],
  },
  characterLeanout: {
    id: 'svvjeewfjhHFEFgef',
    url: '/helix/TEI/Leanouts/Estrella_Leanout_01.svg',
    altText: [
      {
        language: 'en',
        altText: 'Estrella leans out from the side of the screen.',
      },
      {
        language: 'es',
        altText: 'Estrella se asoma por un lado de la pantalla.',
      },
    ],
  },
};
