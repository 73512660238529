import videojs from 'video.js';
import { DefaultSettingsMenuData } from '../../data';
import { ICustomMenuButtonOptions } from '../../interfaces';
import { CustomMenuButton } from '../custom-menu-button/custom-menu-button.component';

class ClosedCaptionsMenuButton extends CustomMenuButton {
  constructor(player: videojs.Player, options: ICustomMenuButtonOptions) {
    super(player, {
      ...options,
      myItems: DefaultSettingsMenuData.CaptionMenuItems,
      classNames: 'vjs-captions-menu-button',
      dataTestId: 'htc-vp-closed-captions-menu-button',
    });

    this.controlText('Closed Captions Menu');
  }
}

videojs.registerComponent('closedCaptionsMenuButton', ClosedCaptionsMenuButton);
export default ClosedCaptionsMenuButton;
