import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartComponent } from './components/chart/chart.component';
import { LexicalTableModule } from '../lexical-table/lexical-table.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ChartModalComponent } from './components/chart-modal/chart-modal.component';

@NgModule({
  declarations: [ChartComponent, ChartModalComponent],
  imports: [
    CommonModule,
    LexicalTableModule,
    SharedModule,
    NgxSkeletonLoaderModule,
  ],
  exports: [ChartComponent, ChartModalComponent],
})
export class ChartModule {}
