import { Component, OnInit } from '@angular/core';
import { FeatureFlagService } from 'src/app/modules/feature-flags/services/feature-flag/feature-flag.service';
import { ClickstreamEventTypeName } from 'src/app/modules/olp-api/enums/clickstream-events';
import { ClickstreamService } from 'src/app/modules/olp-api/services/clickstream/clickstream.service';
import { QuestionLogService } from 'src/app/modules/olp-api/services/question-log/question-log.service';
import {
  ClickstreamActivityData,
  ClickstreamEventData,
} from 'src/app/modules/olp-api/types';
import { ToggleComponent } from 'src/app/shared/components/toggle/toggle.component';
import { FeatureFlags } from 'src/app/shared/enums/feature-flags';
import { InactivityService } from 'src/app/shared/services';

@Component({
  selector: 'htc-clickstream-debug-menu',
  standalone: true,
  imports: [ToggleComponent],
  templateUrl: './clickstream-debug-menu.component.html',
  styleUrl: './clickstream-debug-menu.component.scss',
})
export class ClickstreamDebugMenuComponent implements OnInit {
  readonly SESSION_ID_STORAGE = 'LastSessionId';

  useAuth = false;
  useClickstream = false;

  json = JSON;

  question =
    'Has Anyone Really Been Far Even as Decided to Use Even Go Want to do Look More Like?';
  correctAnswer = 'Wii has';
  discrims: string[] = ['occasionally', 'undoubtedly', 'Wii has', 'never'];

  responses: string[] = [];
  maxAttempts = 2;
  currAttempt = 0;
  timeAsked = 0;

  flags = FeatureFlags;

  constructor(
    public clickstreamService: ClickstreamService,
    private questionLogService: QuestionLogService,
    public featureFlagService: FeatureFlagService,
    private inactivityService: InactivityService
  ) {}

  ngOnInit(): void {
    this.setFlagsFromService();
  }

  updateFlag(flagToUpdate: string, state: boolean): void {
    this.featureFlagService.setFlag(flagToUpdate, state);
    this.featureFlagService.saveCurrentAsOverride();
    this.setFlagsFromService();
  }

  async startSession(fresh: boolean): Promise<void> {
    const sessionId = fresh
      ? crypto.randomUUID()
      : this.getSessionIdFromLocalStorage();
    this.saveSessionIdToLocalStorage(sessionId);
    this.clickstreamService.clickstream.sessionId = sessionId;

    await this.clickstreamService.checkForAccessToken();

    const startSessionName = 'Microlearning Home';
    const activityData: ClickstreamActivityData = {
      activityName: startSessionName,
      category: 'n/a',
      scoreCriteria: 'n/a',
      difficulty: 0,
    };
    const sessionStart: ClickstreamEventData = {
      eventType: ClickstreamEventTypeName.SessionStart,
      key: startSessionName,
      offset: 0,
      data: {
        schoolYear: this.clickstreamService.clickstream.schoolYear,
        sessionId: sessionId,
      },
    };
    this.clickstreamService.clickstream.initializeClickstreamCache(
      activityData,
      false
    );
    this.clickstreamService.addEvent(sessionStart);
  }

  startActivity(): void {
    this.clickstreamService.startActivity(
      'activity',
      'category',
      'scoreCriteria',
      0
    );
    this.inactivityService.startInactivityTracking();
  }

  completeActivity(): void {
    this.inactivityService.pauseInactivityTracking();
    this.clickstreamService.completeActivity();
  }

  interruptActivity(): void {
    this.inactivityService.pauseInactivityTracking();
    this.clickstreamService.addEventFromProperties(
      ClickstreamEventTypeName.ActivityInterrupt,
      'Exit',
      'Exit Button'
    );
  }

  reportInactivity(): void {
    const inactivity = this.inactivityService.getCurrentInactiveTime();
    this.clickstreamService.addEventFromProperties(
      ClickstreamEventTypeName.Inactivity,
      'Inactivity',
      inactivity / 1000
    );
  }

  askQuestion(): void {
    this.questionLogService.askQuestion(
      this.question,
      this.correctAnswer,
      this.discrims,
      false,
      3
    );
    this.timeAsked = Date.now();
    this.currAttempt = 0;
    this.responses = [];
  }

  randomizeQuestion(): Promise<void> {
    return fetch('https://opentdb.com/api.php?amount=1').then(res => {
      if (res.ok && res.status === 200) {
        res.json().then(questionData => {
          this.question = questionData.results[0].question;
          this.correctAnswer = questionData.results[0].correct_answer;
          this.discrims = [
            this.correctAnswer,
            ...questionData.results[0].incorrect_answers,
          ];
        });
      }
    });
  }

  answerCorrect(): void {
    this.questionLogService.answerQuestion(this.correctAnswer);
  }

  answerIncorrect(): void {
    const answer = this.getRandomIncorrectAnswer();
    this.questionLogService.answerQuestion(answer);
    this.currAttempt++;
    this.responses.push(answer);
  }

  sendClickstream(): void {
    this.clickstreamService.saveClickstreamData();
  }

  clearEvents(): void {
    this.clickstreamService.clearEvents();
    this.questionLogService.resetState();
  }

  private getRandomIncorrectAnswer(): string {
    const discrimsMinusCorrect = this.discrims.filter(
      d => d !== this.correctAnswer
    );
    return discrimsMinusCorrect[
      Math.floor(Math.random() * discrimsMinusCorrect.length)
    ];
  }

  private setFlagsFromService(): void {
    this.useAuth = this.featureFlagService.isFlagEnabled(
      FeatureFlags.AUTHENTICATE
    );
    this.useClickstream = this.featureFlagService.isFlagEnabled(
      FeatureFlags.USE_CLICKSTREAM
    );
  }

  private saveSessionIdToLocalStorage(sessionId: string): void {
    localStorage.setItem(this.SESSION_ID_STORAGE, sessionId);
  }

  private getSessionIdFromLocalStorage(): string {
    const storageId = localStorage.getItem(this.SESSION_ID_STORAGE);
    if (!storageId) {
      console.warn('There was no storage id found, returning new uuid.');
    }
    return storageId ?? crypto.randomUUID();
  }
}
