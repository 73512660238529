import { environment } from './../environments/environment';
import { RandomUserResponse } from './shared/interfaces/random-user-name';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { ConfigDataService } from './modules/olp-api/services/config-data/config-data.service';
import { AuthSessionService } from './modules/auth/services/auth-session/auth-session.service';
import { FeatureFlagService } from './modules/feature-flags/services/feature-flag/feature-flag.service';
import { ActivityStateEnum, MessageCodes } from 'src/app/shared/enums';
import { MessageService } from './modules/message/services/message/message.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ActivityService,
  ScrollbarService,
  UserService,
} from './shared/services';
import { KnowledgeBaseService } from './modules/olp-api/services/kb/kb.service';
import { SettingsService } from './shared/services/settings/settings.service';
import { FooterService } from './modules/footer/services/footer.service';
import { PageTransitionService } from './shared/services/page-transitions/page-transitions.service';
import { CompatibilityService } from './shared/services/compatibility/compatibility.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ConfigurationService } from './modules/configuration/services/configuration/configuration.service';
import { UseAnimationService } from './modules/use-animation/use-animation.service';
import { ReferrerService } from './shared/services/referrer/referrer.service';
import { IProgressNodeData } from './modules/progress-bar/interfaces/progress-node-data.interface';
import { FeatureFlags } from './shared/enums/feature-flags';
import { ClickstreamService } from './modules/olp-api/services/clickstream/clickstream.service';
import { TrackingService } from './modules/tracking/services/tracking.service';
import { ChartService } from './modules/chart/services/chart.service';
import {
  LoadingScreenService,
  LoadingScreenState,
} from './modules/loading-screen/services/loading-screen.service';

@Component({
  selector: 'htc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  subscription = new Subscription();
  showFeatureFlagMenu = false;
  useAnimation = true;
  @ViewChild('htcPageContentContainer', { static: true })
  pageContentContainer!: ElementRef;
  ActivityStateEnum = ActivityStateEnum;
  platformSupported = true;
  FeatureFlags = FeatureFlags;
  exitButtonText = 'BUTTONS.EXIT';
  exitButtonIcon = '/assets/button-icons/arrow.svg';
  timeoutFlag = false;
  timeoutTimer!: NodeJS.Timeout;
  animationDelay = 1000;
  chartOpened = false;

  constructor(
    public activityService: ActivityService,
    public userService: UserService,
    public footerService: FooterService,
    public pageTransitionService: PageTransitionService,
    public scrollbarService: ScrollbarService,
    public featureFlagService: FeatureFlagService,
    public chartService: ChartService,
    public authSessionService: AuthSessionService,
    public loadingScreenService: LoadingScreenService,
    private messageService: MessageService,
    private kbService: KnowledgeBaseService,
    private clickstreamService: ClickstreamService,
    private configDataService: ConfigDataService,
    private settingsService: SettingsService,
    private httpClient: HttpClient,
    private compatibilityService: CompatibilityService,
    private router: Router,
    private location: Location,
    private configurationService: ConfigurationService,
    private title: Title,
    private useAnimationService: UseAnimationService,
    private referrerService: ReferrerService,
    private trackingService: TrackingService
  ) {}

  ngOnInit(): void {
    if (!this.featureFlagService.isFlagEnabled(FeatureFlags.EARLY_ACCESS)) {
      this.loadingScreenService.loadingScreenState.set(
        LoadingScreenState.shown
      );
    }
    this.timeoutFlag = false;
    this.pageTransitionService.setup();

    this.subscription.add(
      this.useAnimationService.useAnimation$.subscribe(useAnim => {
        this.animationDelay = useAnim ? 1750 : 500;
      })
    );

    this.subscription.add(
      this.chartService.getIsChartOpen().subscribe(isChartOpen => {
        this.chartOpened = isChartOpen;
      })
    );

    if (this.featureFlagService.isFlagEnabled('authenticate')) {
      if (this.referrerService.getNavTarget().length === 0) {
        this.referrerService.saveNavTarget();
      }
      this.subscription.add(
        this.authSessionService.checkAuth().subscribe({
          next: ({ isAuthenticated, userData, errorMessage }) => {
            if (!isAuthenticated) {
              if (errorMessage) {
                console.error('error authenticating', errorMessage);
                this.messageService.showMessage(
                  MessageCodes.HTTP_AUTH_REQUIRED
                );
              }
              return;
            }
            this.referrerService.removeNavTarget();
            this.authSessionService.saveRoles(userData);
            if (this.authSessionService.isTeacher) {
              this.trackingService.push({
                event: 'is_teacher',
                isTeacher: true,
              });
            }

            this.userService.firstName$.next(userData.given_name);
            this.userService.lastName$.next(userData.family_name);
            const userGrade = userData.grade;
            const grade =
              userGrade && !isNaN(Number(userGrade))
                ? parseInt(userGrade, 10)
                : 0;
            this.userService.grade$.next(grade);
            if (
              this.featureFlagService.isFlagEnabled(
                FeatureFlags.USE_CLICKSTREAM
              )
            ) {
              this.clickstreamService.clickstream.userId = userData.oid;
            }

            if (this.featureFlagService.isFlagEnabled('useKB')) {
              this.kbService.userId = this.configDataService.userId =
                userData.oid;
              this.kbService
                .getAll()
                .then(modules => {
                  if (modules) {
                    if (
                      modules.PowerPath_ActivityCustomizations &&
                      modules.PowerPath_ActivityCustomizations
                        .PPM_AVATARS_CustomizationSelected
                    ) {
                      const avatar =
                        modules.PowerPath_ActivityCustomizations
                          .PPM_AVATARS_CustomizationSelected;
                      this.userService.avatar$.next(avatar as number);
                    }

                    if (modules.MainMenu && modules.MainMenu.Stars) {
                      const stars = modules.MainMenu.Stars as number;
                      this.userService.stars$.next(stars);
                    }
                  }
                })
                .catch((error: Response) => {
                  if (error.status === 401) {
                    this.messageService.showMessage(
                      MessageCodes.HTTP_AUTH_REQUIRED
                    );
                  } else if (error.status >= 400) {
                    this.messageService.showMessage(
                      MessageCodes.HTTP_SERVER_INTERNAL
                    );
                  } else {
                    this.messageService.showMessage(
                      MessageCodes.APP_UNKNOWN_ERROR
                    );
                  }
                });

              this.configDataService
                .getModules()
                .then(modules => {
                  const nextStart = modules?.ISIPCalendar.nextStart;
                  if (nextStart) {
                    this.userService.schoolYear = new Date(nextStart as string);
                    this.clickstreamService.clickstream.schoolYear =
                      this.userService.schoolYear;
                  }
                })
                .catch(err => {
                  console.error('Error configDataService.getModules', err);
                });
            }

            this.settingsService.initSettings();
          },
          error: error => {
            console.error('error authenticating', error);
            this.referrerService.navigateToReferrer();
          },
        })
      );
    } else {
      this.setupRandomUser();
      this.settingsService.initSettings();
    }

    if (this.referrerService.isJITReferrer()) {
      this.exitButtonText = 'BUTTONS.CLOSE';
      this.exitButtonIcon = '/assets/button-icons/close.svg';
    }

    this.title.setTitle(this.configurationService.config.pageTitle);

    // check platform
    this.platformSupported = this.compatibilityService.checkCompatibility();

    this.useAnimationService.init();
    this.subscription.add(
      this.useAnimationService.useAnimation$.subscribe(useAnim => {
        this.useAnimation = useAnim;
      })
    );
  }

  ngAfterViewInit(): void {
    this.pageTransitionService.target = this.pageContentContainer.nativeElement;
    this.scrollbarService.applyCustomScrollbarStyles();
    this.setupMutationObserver();
    window.addEventListener('resize', () => {
      this.scrollbarService.applyCustomScrollbarStyles();
    });
  }

  private setupMutationObserver(): void {
    const observer = new MutationObserver(() => {
      this.scrollbarService.applyCustomScrollbarStyles();
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.pageTransitionService.cleanup();
    this.useAnimationService.onDestroy();
    if (this.timeoutTimer) {
      clearTimeout(this.timeoutTimer);
    }
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event: Event): void {
    if (environment.production) {
      event.preventDefault();
    }
  }

  @HostListener('window:popstate')
  hideBeforeBackButton(): void {
    this.messageService.clearMessages();
    this.activityService.exitActivity();
    this.footerService.show = false;
  }

  private setupRandomUser(): void {
    this.userService.avatar$.next(Math.floor(Math.random() * 35) + 1);
    this.userService.stars$.next(0);
    this.userService.grade$.next(4);
    this.httpClient
      .get<RandomUserResponse>('https://randomuser.me/api/?inc=name')
      .subscribe(res => {
        this.userService.firstName$.next(res.results[0].name.first);
        this.userService.lastName$.next(res.results[0].name.last);
      });
  }

  isProd(): boolean {
    return environment.production;
  }

  onProgressNodeClick(node: IProgressNodeData): void {
    if (this.authSessionService.isTeacher && !this.timeoutFlag) {
      this.timeoutFlag = true;
      this.activityService.goToProgressNodeStep(node);
      if (this.timeoutTimer) {
        clearTimeout(this.timeoutTimer);
      }
      this.timeoutTimer = setTimeout(() => {
        this.timeoutFlag = false;
      }, this.animationDelay);
    }
  }
}
