import { ActivityObjectTypes } from '../../shared/enums/activity-object-types';

export const constActivityManifestEnd = [
  {
    type: ActivityObjectTypes.EXIT_SURVEY,
  },
  {
    type: ActivityObjectTypes.RESULTS_SCREEN,
  },
];
