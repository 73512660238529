import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureFlagMenuComponent } from './components/feature-flag-menu/feature-flag-menu.component';
import { FeatureFlagDirective } from './directives/feature-flag/feature-flag.directive';
import { AccordionModule } from '../accordion/accordion.module';

@NgModule({
  declarations: [FeatureFlagMenuComponent],
  imports: [CommonModule, SharedModule, FormsModule, AccordionModule],
  exports: [FeatureFlagMenuComponent, FeatureFlagDirective],
})
export class FeatureFlagModule {}
