<htc-alert
  class="hint-popup"
  role="alertdialog"
  [(showAlert)]="!isHidden"
  title="{{ 'HINTS.HEADER' | translate }}"
  (showAlertChange)="onClose()"
  data-testid="hint-popup"
  imageSource="/assets/component-icons/hint-header.svg">
  <htc-carousel
    [content]="content"
    (newHintEvent)="onNewHint()"
    (allSlidesVisitedEvent)="onAllHintsSeen()"></htc-carousel>
</htc-alert>
