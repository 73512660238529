import { FeatureFlags } from '../shared/enums/feature-flags';
import { FeatureFlagNode } from '../shared/interfaces';

export const featureflagSections = {
  app: [
    FeatureFlags.SAVE_ACTIVITY_ANYWHERE,
    FeatureFlags.AUTOSAVE,
    FeatureFlags.ENABLE_ELASTIC_AGENT,
    FeatureFlags.EXPERIMENTAL_BROWSER,
    FeatureFlags.CDN_ACTIVITIES,
    FeatureFlags.CDN_ASSETS,
    FeatureFlags.DND_READ_ALOUD,
    FeatureFlags.TEACHER_MODE,
    FeatureFlags.DISABLE_ARBITRARY_LAUNCHER,
    FeatureFlags.SAVE_COMPLETED_OBJECT,
    FeatureFlags.RETURN_TO_REFERRER,
  ],
  content: [
    FeatureFlags.EARLY_ACCESS,
    FeatureFlags.CONTENT_TESTING,
    FeatureFlags.LIVE_CONTENT,
    FeatureFlags.SANDBOX,
  ],
  debug: [
    FeatureFlags.AUTO_ENABLE_NEXT,
    FeatureFlags.REPLAY_BUTTONS,
    FeatureFlags.RANDOMIZE_ANSWER_CHOICES,
    FeatureFlags.SHOW_ACTIVITY_VERSION,
    FeatureFlags.KB_DEBUG,
    FeatureFlags.COMPONENT_TEST_PAGE,
  ],
  user: [
    FeatureFlags.AUTHENTICATE,
    FeatureFlags.USE_KB,
    FeatureFlags.USE_ASSIGNMENT_API,
  ],
};

export const featureFlagLabels = {
  [FeatureFlags.TEACHER_MODE]: 'works with the "teacher" role only',
};

export const featureFlagNodeMap: Map<string, FeatureFlagNode> = new Map([
  [
    FeatureFlags.AUTHENTICATE,
    {
      children: [
        FeatureFlags.USE_KB,
        FeatureFlags.USE_CLICKSTREAM,
        FeatureFlags.USE_ASSIGNMENT_API,
      ],
    },
  ],
  [
    FeatureFlags.USE_KB,
    {
      parent: FeatureFlags.AUTHENTICATE,
      children: [FeatureFlags.USE_ASSIGNMENT_API],
    },
  ],
  [
    FeatureFlags.USE_CLICKSTREAM,
    { parent: FeatureFlags.AUTHENTICATE, children: [] },
  ],
  [
    FeatureFlags.USE_ASSIGNMENT_API,
    { parent: FeatureFlags.USE_KB, children: [] },
  ],
]);
