import { ActivityObjectTypes } from 'src/app/shared/enums/activity-object-types';
import { Activity } from 'src/app/shared/interfaces';
import {
  learningTargets,
  TEST_LEARNING_TARGET_TYPES,
} from '../learning-targets';
import { TEST_POPULATION_TYPES } from '../population-types';
import {
  populationsMathSCR,
  populationsMathECR,
  populationsDragAndDropFillInTheBlank,
  populationsDragAndDropSequencing,
  populationsDragAndDropSortingBuckets,
} from '../populations';
import { constActivityManifestEnd } from './activity-manifest-end';
import { constMathParams } from './math-params';

export const testMathActivities: { [key: string]: Activity } = {
  'TEST.M.SCR': {
    id: `TEST.M.SCR`,
    ...constMathParams,
    seriesName: `Testing Math Short Constructed Response Series`,
    episodeNumber: '1',
    episodeTitle: 'Math Short Constructed Response',
    description:
      'SCR_SHORT, SCR_MEDIUM, SCR_LONG, SCR_PASSAGE_SHORT, SCR_PASSAGE_MEDIUM, SCR_PASSAGE_LONG, SCRR_SHORT, SCRR_MEDIUM, SCRR_LONG, SCRR_PASSAGE_SHORT, SCRR_PASSAGE_MEDIUM, SCRR_PASSAGE_LONG',
    startScreenData: {
      ...constMathParams.startScreenData,
      learningTarget: [
        learningTargets[
          TEST_LEARNING_TARGET_TYPES.MATH_SHORT_CONSTRUCTED_RESPONSE
        ],
        learningTargets[
          TEST_LEARNING_TARGET_TYPES.MATH_SHORT_CONSTRUCTED_RESPONSE_WITH_RUBRIC
        ],
      ],
    },
    populations: [
      populationsMathSCR[TEST_POPULATION_TYPES.TEST_M_SCR_SHORT],
      populationsMathSCR[TEST_POPULATION_TYPES.TEST_M_SCR_MEDIUM],
      populationsMathSCR[TEST_POPULATION_TYPES.TEST_M_SCR_LONG],
      populationsMathSCR[TEST_POPULATION_TYPES.TEST_M_SCR_PASSAGE_SHORT],
      populationsMathSCR[TEST_POPULATION_TYPES.TEST_M_SCR_PASSAGE_MEDIUM],
      populationsMathSCR[TEST_POPULATION_TYPES.TEST_M_SCR_PASSAGE_LONG],
      populationsMathSCR[TEST_POPULATION_TYPES.TEST_M_SCRR_SHORT],
      populationsMathSCR[TEST_POPULATION_TYPES.TEST_M_SCRR_MEDIUM],
      populationsMathSCR[TEST_POPULATION_TYPES.TEST_M_SCRR_LONG],
      populationsMathSCR[TEST_POPULATION_TYPES.TEST_M_SCRR_PASSAGE_SHORT],
      populationsMathSCR[TEST_POPULATION_TYPES.TEST_M_SCRR_PASSAGE_MEDIUM],
      populationsMathSCR[TEST_POPULATION_TYPES.TEST_M_SCRR_PASSAGE_LONG],
    ],
    activityManifest: [
      ...constMathParams.activityManifest,
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_SCR_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_SCR_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_SCR_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_SCR_PASSAGE_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_SCR_PASSAGE_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_SCR_PASSAGE_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_SCRR_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_SCRR_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_SCRR_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_SCRR_PASSAGE_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_SCRR_PASSAGE_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_SCRR_PASSAGE_LONG}`,
      },
      ...constActivityManifestEnd,
    ],
  },
  'TEST.M.ECR': {
    id: `TEST.M.ECR`,
    ...constMathParams,
    seriesName: `Testing Math Extended Constructed Response With and Without Rubric Series`,
    episodeNumber: '1',
    episodeTitle: 'Math Extended Constructed Response',
    description:
      'ECR_SHORT, ECR_MEDIUM, ECR_LONG, ECR_PASSAGE_SHORT, ECR_PASSAGE_MEDIUM, ECR_PASSAGE_LONG, ECRR_SHORT, ECRR_MEDIUM, ECRR_LONG, ECRR_PASSAGE_SHORT, ECRR_PASSAGE_MEDIUM, ECRR_PASSAGE_LONG',
    startScreenData: {
      ...constMathParams.startScreenData,
      learningTarget: [
        learningTargets[
          TEST_LEARNING_TARGET_TYPES.MATH_EXTENDED_CONSTRUCTED_RESPONSE
        ],
        learningTargets[
          TEST_LEARNING_TARGET_TYPES
            .MATH_EXTENDED_CONSTRUCTED_RESPONSE_WITH_RUBRIC
        ],
      ],
    },
    populations: [
      populationsMathECR[TEST_POPULATION_TYPES.TEST_M_ECR_SHORT],
      populationsMathECR[TEST_POPULATION_TYPES.TEST_M_ECR_MEDIUM],
      populationsMathECR[TEST_POPULATION_TYPES.TEST_M_ECR_LONG],
      populationsMathECR[TEST_POPULATION_TYPES.TEST_M_ECR_PASSAGE_SHORT],
      populationsMathECR[TEST_POPULATION_TYPES.TEST_M_ECR_PASSAGE_MEDIUM],
      populationsMathECR[TEST_POPULATION_TYPES.TEST_M_ECR_PASSAGE_LONG],
      populationsMathECR[TEST_POPULATION_TYPES.TEST_M_ECRR_SHORT],
      populationsMathECR[TEST_POPULATION_TYPES.TEST_M_ECRR_MEDIUM],
      populationsMathECR[TEST_POPULATION_TYPES.TEST_M_ECRR_LONG],
      populationsMathECR[TEST_POPULATION_TYPES.TEST_M_ECRR_PASSAGE_SHORT],
      populationsMathECR[TEST_POPULATION_TYPES.TEST_M_ECRR_PASSAGE_MEDIUM],
      populationsMathECR[TEST_POPULATION_TYPES.TEST_M_ECRR_PASSAGE_LONG],
    ],
    activityManifest: [
      ...constMathParams.activityManifest,
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_ECR_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_ECR_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_ECR_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_ECR_PASSAGE_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_ECR_PASSAGE_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_ECR_PASSAGE_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_ECRR_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_ECRR_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_ECRR_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_ECRR_PASSAGE_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_ECRR_PASSAGE_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_ECRR_PASSAGE_LONG}`,
      },
      ...constActivityManifestEnd,
    ],
  },
  'TEST.M.DND': {
    id: `TEST.M.DND`,
    ...constMathParams,
    seriesName: `Testing Math Drag and Drop`,
    episodeNumber: '1',
    episodeTitle: 'Math Drag and Drop',
    description:
      'DND_FILL_BLANK_1, DND_FILL_BLANK_2, DND_FILL_BLANK_3, DND_FILL_BLANK_4, DND_FILL_BLANK_5, DND_STATIC_HORIZ_SEQ, DND_STATIC_VERT_SEQ, DND_SORTING_BUCKETS_1, DND_SORTING_BUCKETS_2, DND_SORTING_BUCKETS_3',
    startScreenData: {
      ...constMathParams.startScreenData,
      learningTarget: [
        learningTargets[TEST_LEARNING_TARGET_TYPES.DRAG_AND_DROP],
      ],
    },
    populations: [
      populationsDragAndDropFillInTheBlank[
        TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_1
      ],
      populationsDragAndDropFillInTheBlank[
        TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_2
      ],
      populationsDragAndDropFillInTheBlank[
        TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_3
      ],
      populationsDragAndDropFillInTheBlank[
        TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_4
      ],
      populationsDragAndDropFillInTheBlank[
        TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_5
      ],
      populationsDragAndDropSequencing[
        TEST_POPULATION_TYPES.TEST_M_DND_STATIC_HORIZ_SEQ
      ],
      populationsDragAndDropSequencing[
        TEST_POPULATION_TYPES.TEST_M_DND_STATIC_VERT_SEQ
      ],
      populationsDragAndDropSortingBuckets[
        TEST_POPULATION_TYPES.TEST_M_DND_SORTING_BUCKETS_1
      ],
      populationsDragAndDropSortingBuckets[
        TEST_POPULATION_TYPES.TEST_M_DND_SORTING_BUCKETS_2
      ],
      populationsDragAndDropSortingBuckets[
        TEST_POPULATION_TYPES.TEST_M_DND_SORTING_BUCKETS_3
      ],
    ],
    activityManifest: [
      ...constMathParams.activityManifest,
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_1}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_2}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_3}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_4}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_5}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_DND_STATIC_HORIZ_SEQ}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_DND_STATIC_VERT_SEQ}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_DND_SORTING_BUCKETS_1}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_DND_SORTING_BUCKETS_2}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_DND_SORTING_BUCKETS_3}`,
      },
      ...constActivityManifestEnd,
    ],
  },
};
