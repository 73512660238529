<button
  class="btn"
  [attr.id]="id ? id : null"
  [ngClass]="isHovering ? 'hover ' + classes : classes"
  data-testid="btn-object"
  htcAudioClick
  [audioType]="clickType"
  [descriptor]="clickDescriptor"
  (click)="buttonClicked($event)"
  (mouseenter)="mouseEnter()"
  (mouseleave)="mouseLeave()"
  [disabled]="!enable">
  <div
    class="row flex-full-row flex-align-center flex-justify-space-around ignore-pointer-events">
    <div class="button-text" data-testid="btn-text">
      {{ btnText }}
    </div>
    <svg-icon
      *ngIf="imagePath"
      src="{{ imagePath }}"
      data-testid="bwi-icon"></svg-icon>
  </div>
</button>
