import { inject, Injectable, ProviderToken } from '@angular/core';
import { ConfigDataAPI } from '../../classes/config-data/config-data-api';
import { ConfigDataModules, ConfigDataStore } from '../../types';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { MessageService } from 'src/app/modules/message/services/message/message.service';
import { MessageCodes } from 'src/app/shared/enums';

const getKbFacade = (): ConfigDataAPI => {
  const injectionPoint: ProviderToken<ConfigDataAPI> = ConfigDataAPI;
  const facade = inject(injectionPoint);
  return facade;
};

@Injectable()
export class ConfigDataService {
  private kb = getKbFacade();

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private messageService: MessageService
  ) {
    this.oidcSecurityService.getAccessToken().subscribe({
      next: token => {
        if (token) {
          this.kb.setAuthToken(token);
        }
      },
      error: err => {
        console.error(
          'we had issues getting an access token for config data service',
          err
        );
        this.messageService.showMessage(MessageCodes.HTTP_AUTH_REQUIRED);
      },
    });
  }

  set userId(value: number) {
    this.kb.userOid = value;
  }

  async checkForAccessToken(): Promise<boolean> {
    if (this.kb.authToken) {
      return true;
    }
    this.oidcSecurityService.getAccessToken().subscribe({
      next: token => {
        if (token) {
          this.kb.setAuthToken(token);
        }
      },
      error: err => {
        console.error('we had issues getting an access token', err);
        this.messageService.showMessage(MessageCodes.HTTP_AUTH_REQUIRED);
      },
    });
    return true;
  }

  async getModules(): Promise<ConfigDataModules | null | undefined> {
    await this.checkForAccessToken();
    return this.kb.getModules();
  }

  loadData(): Promise<string | null> {
    return this.kb.loadData();
  }

  getAll(): Promise<ConfigDataStore | null | undefined> {
    return this.kb.getAll();
  }

  get(section: string, key?: string): Promise<string | undefined> {
    return this.kb.get(section, key);
  }

  clearData(): void {
    this.kb.clearData();
  }

  retrieveConfigData(): Promise<unknown> {
    return this.kb.retrieveConfigData();
  }
}
