import { Activity } from 'src/app/shared/interfaces';

export const readingNoun2: Activity = {
  id: '018ea019-0790-7550-a069-d7282ee56622',
  product: 'Reading',
  language: 'en',
  skillName: 'Plural Nouns',
  thumbnail: {
    id: 'recq07zLler7JxaVm',
    url: '/helix/UX_Images/Lesson_Posters/Nouns_Pluralnouns_G3V2_Poster.jpg',
    altText: [
      {
        language: 'en',
        altText: 'Axis is covered in paint while talking to CAT.',
      },
      {
        language: 'es',
        altText: '',
      },
    ],
  },
  instructionsImage: {
    id: 'recShqii4tO2JCQyf',
    url: '/helix/UX_Images/Characters/Axis/Axis_Chibi_Head_Happy_Mouth_Open.svg',
    altText: [
      {
        language: 'en',
        altText: 'Axis is smiling.',
      },
      {
        language: 'es',
        altText: 'Axis está sonriendo.',
      },
    ],
  },
  characterLeanout: {
    id: 'recBBGySIgxHLnC6k',
    url: '/helix/TEI/Leanouts/Axis_Leanout_01.svg',
    altText: [
      {
        language: 'en',
        altText: 'Axis leans out from the side of the screen.',
      },
      {
        language: 'es',
        altText: 'Axis se asoma por el lado de la pantalla.',
      },
    ],
  },
  description:
    'With most of the hard work done, Axis needs to label the animals in his diorama. He gets a review from C.A.T. about irregular plural nouns to make sure it’s ready to go.',
  domain: {
    text: 'Nouns',
  },
  seriesName: 'Project Plurals',
  episodeNumber: '2',
  episodeTitle: 'Diorama Dilemma',
  videos: [
    {
      id: 'recgJqX6UiYJOjT7m',
      url: '/helix/Reading/Grade_3/Nouns_PluralNouns/Video_02/hls_streams/R_G3_PluralNouns_Video02.m3u8',
      posterURL:
        '/helix/UX_Images/Lesson_Posters/Nouns_Pluralnouns_G3V2_Poster.jpg',
      captions: [
        {
          src: '/helix/Reading/Grade_3/Nouns_PluralNouns/Video_02/R_G3_PluralNouns_Video02.vtt',
          srclang: 'en',
          mode: 'hidden',
          kind: 'captions',
        },
      ],
    },
  ],
  startScreenData: {
    learningTarget: [
      {
        id: '1434.B',
        learningTarget: {
          text: 'how to make irregular nouns plural',
        },
        successCriteria: [
          {
            id: '1434.B.1',
            text: 'form irregular plural nouns when the vowels change',
          },
          {
            id: '1434.B.2',
            text: 'form irregular plural nouns when both singular and plural forms have the same spelling',
          },
          {
            id: '1434.B.3',
            text: 'form irregular plural nouns when they follow no rule at all',
          },
        ],
      },
    ],
    background_leftImage: {
      id: 'recq07zLler7JxaVm',
      url: '/helix/UX_Images/Lesson_Posters/Nouns_Pluralnouns_G3V2_Poster.jpg',
      altText: [
        {
          language: 'en',
          altText: 'Axis is covered in paint while talking to CAT.',
        },
        {
          language: 'es',
          altText: '',
        },
      ],
    },
    background_rightImage: {
      id: 'recxjC353VMav5ybu',
      url: '/helix/UX_Images/Start_Screen_Blobs/Nova-Scouts_space-sky.svg',
      altText: [
        {
          language: 'en',
          altText: 'Stars in space.',
        },
        {
          language: 'es',
          altText: 'Estrellas en el espacio.',
        },
      ],
    },
    foreground: {
      id: 'recJFZiQMxFozF9ks',
      url: '/helix/UX_Images/Start_Screen_Characters/Axis3.svg',
      altText: [
        {
          language: 'en',
          altText: 'Axis is pointing at something.',
        },
        {
          language: 'es',
          altText: 'Axis está señalando algo.',
        },
      ],
    },
  },
  populations: [
    {
      id: '1434.B.1.1',
      templateID: 3,
      complexity: 'Knowledge',
      hint: [
        {
          id: '1434.B.1.1.0',
          type: 'BasicSlide',
          data: {
            title:
              'Some irregular plural nouns change vowels, change completely, or don’t change at all.\n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Write the plural form of the given word.\ngoose\n{WORD_TOKEN}\n',
          },
        ],
        answers: [['geese\n']],
      },
      feedbackSecondIncorrect: {
        content:
          'Geese is the correct plural form because of the vowel change from o to e.\n',
      },
    },
    {
      id: '1434.B.2.2',
      templateID: 3,
      complexity: 'Knowledge',
      hint: [
        {
          id: '1434.B.2.2.0',
          type: 'BasicSlide',
          data: {
            title:
              'Some irregular plural nouns change vowels, change completely, or don’t change at all.\n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Write the plural form of the given word.\nmouse\n{WORD_TOKEN}\n',
          },
        ],
        answers: [['mice\n']],
      },
      feedbackSecondIncorrect: {
        content:
          'Mice is the correct plural because the entire word changes.\n',
      },
    },
    {
      id: '1434.B.3.3',
      templateID: 3,
      complexity: 'Knowledge',
      hint: [
        {
          id: '1434.B.3.3.0',
          type: 'BasicSlide',
          data: {
            title:
              'Some irregular plural nouns change vowels, change completely, or don’t change at all.\n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Write the plural form of the given word.\nsheep\n{WORD_TOKEN}\n',
          },
        ],
        answers: [['sheep\n']],
      },
      feedbackSecondIncorrect: {
        content: 'Sheep is the correct plural because there is no change.\n',
      },
    },
    {
      id: '1434.B.2.4',
      templateID: 2,
      complexity: 'Comprehension',
      hint: [
        {
          id: '1434.B.2.4.0',
          type: 'BasicSlide',
          data: {
            title:
              'Some irregular plural nouns change vowels, change completely, or don’t change at all.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'The following sentence is correct:\n\nI saw three beautiful tropical fish at the aquarium.\n',
        },
        answerChoices: [
          {
            id: '1434.B.2.4_1',
            choice: {
              texts: [
                {
                  text: 'True\n',
                },
              ],
            },
          },
          {
            id: '1434.B.2.4_2',
            choice: {
              texts: [
                {
                  text: 'False\n',
                },
              ],
            },
          },
        ],
        answers: ['1434.B.2.4_1'],
        associatedSuccessCriteria: ['1434.B.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'Fish doesn’t change from singular to plural, so this sentence is correct.\n',
      },
    },
    {
      id: '1434.B.3.5',
      templateID: 2,
      complexity: 'Comprehension',
      hint: [
        {
          id: '1434.B.3.5.0',
          type: 'BasicSlide',
          data: {
            title:
              'Some irregular plural nouns change vowels, change completely, or don’t change at all.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'The following sentence is correct:\n\nA group of childs played hide and seek at the playground.\n\n\n',
        },
        answerChoices: [
          {
            id: '1434.B.3.5_1',
            choice: {
              texts: [
                {
                  text: 'False\n',
                },
              ],
            },
          },
          {
            id: '1434.B.3.5_2',
            choice: {
              texts: [
                {
                  text: 'True\n',
                },
              ],
            },
          },
        ],
        answers: ['1434.B.3.5_1'],
        associatedSuccessCriteria: ['1434.B.3'],
      },
      feedbackSecondIncorrect: {
        content:
          'The correct plural form of child is children, so this sentence is incorrect.\n',
      },
    },
    {
      id: '1434.B.1.6',
      templateID: 1,
      complexity: 'Application',
      hint: [
        {
          id: '1434.B.1.6.0',
          type: 'BasicSlide',
          data: {
            title:
              'Some irregular plural nouns change vowels, change completely, or don’t change at all.\n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Complete the sentence with the correct irregular plural noun.\n\nThe group of visiting firefighters was made up of several (man) {CHOICE_TOKEN} and women.\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '1434.B.1.6_1',
                  text: 'men\n',
                },
                {
                  id: '1434.B.1.6_2',
                  text: 'mans\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'The plural of _man_ is an irregular plural noun. Adding s won’t make it plural.\n',
                    },
                  },
                },
                {
                  id: '1434.B.1.6_3',
                  text: 'mens\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'It looks like a vowel was changed and an s was added. Only one change is needed.\n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['1434.B.1.6_1'],
        associatedSuccessCriteria: ['1434.B.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'The plural form of _man_is _men_ because the vowel changes.\n',
      },
    },
    {
      id: '1434.B.1.7',
      templateID: 1,
      complexity: 'Application',
      hint: [
        {
          id: '1434.B.1.7.0',
          type: 'BasicSlide',
          data: {
            title:
              'Some irregular plural nouns change vowels, change completely, or don’t change at all.\n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Complete the sentence with the correct irregular plural noun.\n\nEthan took off his shoes to feel the sand under his (foot) {CHOICE_TOKEN}.\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '1434.B.1.7_1',
                  text: 'feet\n',
                },
                {
                  id: '1434.B.1.7_2',
                  text: 'foots\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'The plural of _foot_ is an irregular plural noun. Adding s won’t make it plural.\n',
                    },
                  },
                },
                {
                  id: '1434.B.1.7_3',
                  text: 'feets\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'It looks like a vowel was changed and an s was added. Only one change is needed.\n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['1434.B.1.7_1'],
        associatedSuccessCriteria: ['1434.B.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'The plural form of _foot_is _feet_ because the vowels change.\n',
      },
    },
    {
      id: '1434.B.1.8',
      templateID: 1,
      complexity: 'Application',
      hint: [
        {
          id: '1434.B.1.8.0',
          type: 'BasicSlide',
          data: {
            title:
              'Some irregular plural nouns change vowels, change completely, or don’t change at all.\n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Complete the sentence with the correct irregular plural noun.\n\nMy sister has to get braces on her (tooth) {CHOICE_TOKEN} next week.\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '1434.B.1.8_1',
                  text: 'teeth\n',
                },
                {
                  id: '1434.B.1.8_2',
                  text: 'tooths\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'The plural of _tooth_ is an irregular plural noun. Adding s won’t make it plural.\n',
                    },
                  },
                },
                {
                  id: '1434.B.1.8_3',
                  text: 'teeths\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'It looks like a vowel was changed and an s was added. Only one change is needed.\n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['1434.B.1.8_1'],
        associatedSuccessCriteria: ['1434.B.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'The plural form of _tooth_is _teeth_ because the vowels change.\n',
      },
    },
    {
      id: '1434.B.2.9',
      templateID: 1,
      complexity: 'Application',
      hint: [
        {
          id: '1434.B.2.9.0',
          type: 'BasicSlide',
          data: {
            title:
              'Some irregular plural nouns change vowels, change completely, or don’t change at all.\n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Complete the sentence with the correct irregular plural noun.\n\nSeveral (deer) {CHOICE_TOKEN} approached the cabin as we ate breakfast.\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '1434.B.2.9_1',
                  text: 'deer\n',
                },
                {
                  id: '1434.B.2.9_2',
                  text: 'deers\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'The plural of _deer_ is an irregular plural noun. Adding s won’t make it plural.\n',
                    },
                  },
                },
                {
                  id: '1434.B.2.9_3',
                  text: 'deerses\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'Usually e-s is added to nouns that end in s, x, c-h, s-h, or s-s. _Deer_ does not end in those letters.\n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['1434.B.2.9_1'],
        associatedSuccessCriteria: ['1434.B.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'The plural form of _deer_is _deer_ because the word doesn’t change at all.\n',
      },
    },
    {
      id: '1434.B.3.10',
      templateID: 1,
      complexity: 'Application',
      hint: [
        {
          id: '1434.B.3.10.0',
          type: 'BasicSlide',
          data: {
            title:
              'Some irregular plural nouns change vowels, change completely, or don’t change at all.\n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Complete the sentence with the correct irregular plural noun.\n\nThe water park was full of (person) {CHOICE_TOKEN} on such a hot day.\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '1434.B.3.10_1',
                  text: 'people\n',
                },
                {
                  id: '1434.B.3.10_2',
                  text: 'persons\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'While _persons_ is a plural of person, it’s not commonly used. See if there is a better option.\n',
                    },
                  },
                },
                {
                  id: '1434.B.3.10_3',
                  text: 'persones\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'Usually es is added to nouns that end in s, x, c-h, s-h, or s-s. _Person_ does not end in those letters.\n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['1434.B.3.10_1'],
        associatedSuccessCriteria: ['1434.B.3'],
      },
      feedbackSecondIncorrect: {
        content:
          'The plural form of _person_is _people_. It doesn’t follow a specific rule.\n',
      },
    },
  ],
  activityManifest: [
    {
      type: 'START_SCREEN',
    },
    {
      type: 'ENTRY_SURVEY',
    },
    {
      type: 'VIDEO',
      id: 'recgJqX6UiYJOjT7m',
    },
    {
      type: 'QUESTION',
      id: '1434.B.1.1',
    },
    {
      type: 'QUESTION',
      id: '1434.B.2.2',
    },
    {
      type: 'QUESTION',
      id: '1434.B.3.3',
    },
    {
      type: 'QUESTION',
      id: '1434.B.2.4',
    },
    {
      type: 'QUESTION',
      id: '1434.B.3.5',
    },
    {
      type: 'QUESTION',
      id: '1434.B.1.6',
    },
    {
      type: 'QUESTION',
      id: '1434.B.1.7',
    },
    {
      type: 'QUESTION',
      id: '1434.B.1.8',
    },
    {
      type: 'QUESTION',
      id: '1434.B.2.9',
    },
    {
      type: 'QUESTION',
      id: '1434.B.3.10',
    },
    {
      type: 'EXIT_SURVEY',
    },
    {
      type: 'RESULTS_SCREEN',
    },
  ],
  version: '',
};
