import { Component, Input } from '@angular/core';
import { FeatureFlags } from '../../enums/feature-flags';
import { badgeIcons } from 'src/app/data/badge-icons';
import { AuthSessionService } from 'src/app/modules/auth/services/auth-session/auth-session.service';

@Component({
  selector: 'htc-avatar',
  template: `<figure class="avatar {{ maskStyle }}">
    <div class="avatar-mask {{ flair }} {{ maskStyle }}">
      <img
        src="{{ path + 'avatar_' + avatar + '.svg' }}"
        class="nav-avatar-img"
        attr.aria-label="{{ 'AVATAR.STUDENT_AVATAR' | translate }} {{ avatar }}"
        alt="{{ 'AVATAR.STUDENT_AVATAR' | translate }} {{ avatar }}"
        (error)="handleLoadError($event)" />
    </div>
    <img
      *ngIf="authSessionService.isTeacher"
      class="badge-img"
      [src]="badgePath + badgeIcons.TEACHER_MODE"
      alt="{{ 'HEADER.TEACHER_MODE_IMAGE' | translate }}" />
  </figure>`,
  styles: [
    `
      :host {
        display: inline-block;
        height: 100%;
        width: 100%;
      }

      .avatar {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 0;
        width: 100%;
        position: relative;
        padding-top: 100%;
        background: transparent;

        .badge-img {
          position: absolute;
          width: 50%;
          height: 50%;
          right: -1rem;
          bottom: 0;
        }

        @mixin shape-style($radius, $max, $height) {
          border-radius: $radius;

          img {
            max-width: $max;
            max-height: $max;
            height: $height;
          }
        }

        .avatar-mask {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          overflow: hidden;
          width: 100%;
          height: 100%;

          img {
            min-width: 100%;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
          }

          &.circle {
            @include shape-style(50%, 100%, 100%);
            background: var(--white);
            border: var(--avatar-masked-border);
          }

          &.square.masked {
            @include shape-style(1rem, 100%, auto);
            box-shadow: 0.375rem 0.25rem 0 var(--avatar-box-shadow);
            padding: 0.5rem;
            background-color: var(--avatar-masked-bg);

            img {
              border-radius: 1rem;
              overflow: hidden;
            }
          }
        }
      }
    `,
  ],
})
export class AvatarComponent {
  _flair = 'square';
  @Input() avatar: number | null = null;
  @Input() maskStyle = 'masked';

  @Input() set flair(value: string) {
    if (value === 'circle' || value === 'square') {
      this._flair = value;
    } else {
      console.warn(
        'Attempting to set avatar flair to invalid state, defaulting to square.'
      );
      this._flair = 'square';
    }
  }
  get flair(): string {
    return this._flair;
  }

  show = true;
  path = '/assets/user-avatars/';
  badgePath = '/assets/badge-icons/';
  badgeIcons = badgeIcons;
  unknown = this.path + 'unknown.svg';
  featureFlags = FeatureFlags;

  //it'd be super cool if we could get descriptions of avatars and set the label dynamically.
  constructor(public authSessionService: AuthSessionService) {}

  /**
   * Handles the avatar image not being found. Three sources are attempted:
   * 1. SVG version of the avatar (set in the template)
   * 2. A PNG version of the avatar on first failure
   * 3. An SVG "unknown" avatar on second (png) failure
   * @param event
   */
  handleLoadError(event: Event): void {
    const src = (event.target as HTMLImageElement).src;
    if (src.endsWith('.png')) {
      console.log('Could not load png avatar. Falling back to unknown avatar');
      (event.target as HTMLImageElement).src = this.unknown;
    } else if (!src.endsWith(this.unknown)) {
      console.log('Could not load svg avatar. Falling back to png');
      (event.target as HTMLImageElement).src =
        this.path + 'avatar_' + this.avatar + '.png';
    } else {
      console.log('Could not load last resort unknown avatar');
    }
  }
}
