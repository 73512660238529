import { Messages } from '../../../../data/messages';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Message } from 'src/app/shared/interfaces';
import { MessageCodes, TriggerActionTypes } from 'src/app/shared/enums';
import { FeatureFlagService } from 'src/app/modules/feature-flags/services/feature-flag/feature-flag.service';
import { FeatureFlags } from 'src/app/shared/enums/feature-flags';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  public messageData = new Subject<Message | undefined>();
  public triggerData = new Subject<TriggerActionTypes | undefined>();

  private onOkClickedFunction?: () => void;

  constructor(private featureFlag: FeatureFlagService) {}

  showMessage(id: number, okFn?: () => void): void {
    const message = Messages[id];
    // override home page link for Early Access page
    if (
      this.featureFlag.isFlagEnabled(FeatureFlags.EARLY_ACCESS) &&
      (id === MessageCodes.HTTP_FORBIDDEN || id === MessageCodes.HTTP_NOT_FOUND)
    ) {
      message.hyperLink = '/';
    }
    if (!message) return;
    if (okFn) {
      this.onOkClickedFunction = okFn;
    }
    this.messageData.next(message);
  }

  onOkClicked(): void {
    this.onOkClickedFunction?.call(this);
  }

  sendTrigger(trigger: TriggerActionTypes): void {
    this.triggerData.next(trigger);
  }

  clearTriggers(): void {
    this.triggerData.next(undefined);
  }

  clearMessages(): void {
    this.messageData.next(undefined);
  }
}
