import {
  MultipleChoiceTypes,
  DragAndDropTypes,
  SkipQuestionLogSortTypes,
  InlineChoiceTypes,
} from './../../enums/template-id';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EvaluatorFactory } from 'src/app/classes/evaluator-factory';
import {
  AnswerStateEnum,
  ComplexFeedbackTypes,
  ConstructedResponseTypes,
  CorrectnessEnum,
  TemplateID,
} from '../../enums';
import {
  AnswerChoice,
  Population,
  PopulationDragAndDrop,
  PopulationInlineChoice,
  PopulationMultipleChoice,
  PopulationResponse,
  PopulationTextEntry,
  TargetedFeedback,
} from '../../interfaces';
import { PopulationData } from '../../types/population-data';
import { ResultsService } from '../results/results.service';
import { ToolsService } from 'src/app/modules/tools/services/tools.service';
import { ToolID } from 'src/app/modules/tools/enums/tool-id';
import { toolItems } from 'src/app/modules/tools/data/tool-items';
import { ISlideData } from 'src/app/modules/carousel/interfaces';
import { PopulationConstructedResponse } from '../../interfaces/population-constructed-response';
import { ConstructedResponseEmit } from 'src/app/modules/tei/enums/constructed-response-emit.enum';
import { TeiTryDefaults } from 'src/app/data/tei-tries-defaults';
import { QuestionLogService } from 'src/app/modules/olp-api/services/question-log/question-log.service';
import { Feedback } from '../../components/feedback/feedback.interface';

@Injectable({
  providedIn: 'root',
})
export class PopulationService {
  readonly hintReadIcon = 'hint_read.svg';
  readonly hintUnreadIcon = 'hint_unread.svg';

  currentPopulation = new BehaviorSubject<Population | undefined>(undefined);
  visualOverride = new BehaviorSubject<boolean>(false);
  populations: Population[] = [];
  populationResponses: PopulationResponse[] = [];
  targetedFeedbacks: TargetedFeedback[] = [];
  isAllHintsSeen = new BehaviorSubject<boolean>(false);

  currTry = 0;
  popStartTime = 0;
  _isLastQuestion = false;
  lastReportedInterface = '';

  answerStates: AnswerStateEnum[] = [];
  idToAnswerTextMap: Map<string, string> = new Map();
  filledAnswers = new BehaviorSubject<(string | number)[][]>([[]]);
  selectedAnswers = new BehaviorSubject<(string | number)[][]>([[]]); // holds user's selected values for a single population
  disabledAnswers = new BehaviorSubject<(string | number)[][]>([[]]); // holds user's wrong selected values for a single population
  prevSelectedAnswers: (string | number)[][] = [[]];
  submittedSelectedAnswers: (string | number)[][] = [[]];
  correctAnswers: (string | number)[][] = [];
  lastFilledCheck = 0;

  resumeEvent = new BehaviorSubject<boolean>(false);

  constructor(
    private resultsService: ResultsService,
    private toolsService: ToolsService,
    private questionLogService: QuestionLogService
  ) {
    this.isAllHintsSeen.subscribe(isAllHintsSeen => {
      setTimeout(() => {
        //hack to run this during the next tick to fix ExpressionChangedAfterItHasBeenCheckedError
        if (isAllHintsSeen) {
          this.toolsService.setIcon(
            toolItems[ToolID.ON_DEMAND_HINT],
            this.hintReadIcon
          );
        } else {
          this.toolsService.setIcon(
            toolItems[ToolID.ON_DEMAND_HINT],
            this.hintUnreadIcon
          );
        }
      });
    });
  }

  startPopTimer(): void {
    this.popStartTime = Date.now();
  }

  isCurrentPopulationRubricType(): boolean {
    const currentPopType = this.getCurrentPopulationType();
    return (
      currentPopType === TemplateID.CONSTRUCTED_RESPONSE_WITH_RUBRIC ||
      currentPopType === TemplateID.EXTENDED_CONSTRUCTED_RESPONSE_WITH_RUBRIC
    );
  }

  /**
   * Gets the current type of the template for the current population
   * @returns TemplateID of the current population
   */
  getCurrentPopulationType(): TemplateID {
    return this.currentPopulation.getValue()?.templateID ?? -1;
  }

  /**
   * Gets the key of the current population template
   * @returns string of the current population template's key
   */
  getCurrentPopulationName(): string {
    const currType = this.getCurrentPopulationType();
    return TemplateID[currType];
  }

  /**
   * Gets the hints for the current population
   * @returns hint of the current population
   */
  getCurrentPopulationHints(): ISlideData[] {
    return this.currentPopulation.getValue()?.hint ?? [];
  }

  /**
   * Gets the first incorrect feedback text for the current population
   * @returns feedbackFirstIncorrect of the current population
   */
  getFirstIncorrectFeedback(): Feedback[] {
    if (this.targetedFeedbacks.length > 0) {
      return this.targetedFeedbacks.map(_targetedFeedback => {
        return _targetedFeedback.firstIncorrect;
      });
    }

    return [this.currentPopulation.getValue()?.feedbackFirstIncorrect ?? {}];
  }

  /**
   * Gets the second incorrect feedback text for the current population
   * @returns feedbackFirstIncorrect of the current population
   */
  getSecondIncorrectFeedback(): Feedback[] {
    let result: Feedback[] = [];

    if (this.targetedFeedbacks.length > 0) {
      result = this.targetedFeedbacks.map(_targetedFeedback => {
        return _targetedFeedback.secondIncorrect ?? {};
      });
    }

    const isAllBlank = result.every(
      feedbackStr =>
        (!feedbackStr.chartUrl || feedbackStr.chartUrl === '') &&
        (!feedbackStr.content || feedbackStr.content === '')
    );

    if (isAllBlank) {
      result = [
        this.currentPopulation.getValue()?.feedbackSecondIncorrect ?? {},
      ];
    }

    return result;
  }

  getCurrentPopAnswerState(): number {
    const popIndex = this.populations.findIndex(
      p => p.id === this.currentPopulation.getValue()?.id
    );
    if (popIndex !== -1 && popIndex < this.answerStates.length) {
      return this.answerStates[popIndex];
    }

    return AnswerStateEnum.INCOMPLETE;
  }

  /**
   * @returns true if the current question is a multi-select question
   */
  isMultiSelectQuestion(): boolean {
    const type = this.getCurrentPopulationType();
    return (
      type === TemplateID.MULTI_SELECT ||
      type === TemplateID.MULTI_SELECT_IMAGE ||
      type === TemplateID.DYNAMIC_HORIZONTAL_SEQUENCING ||
      type === TemplateID.SORTING_BUCKETS ||
      type === TemplateID.SORTING_BUCKETS_IMAGES
    );
  }

  /**
   * Evaluate supplied answers and returns their correctness.
   * @param pop population to evaluate
   * @param answers selected answers to test for correctness
   * @returns CorrectnessEnum representing the answer provided's correctness
   */
  evaluateAnswer(
    pop: PopulationData,
    answers: (string | number)[][]
  ): CorrectnessEnum {
    const submittedAnswers: (string | number)[][] = [...answers];
    const type = this.getCurrentPopulationType();
    const popIndex = this.populations.findIndex(
      p => p.id === this.currentPopulation.getValue()?.id
    );
    if (EvaluatorFactory.isNotAssessable(type)) {
      this.resultsService.numCorrect++;
      this.resultsService.numPerfect++;
      if (popIndex !== -1) {
        this.answerStates[popIndex] = AnswerStateEnum.COMPLETE;
      }
      this.recordResponse([
        [submittedAnswers[0][ConstructedResponseEmit.ANSWER]],
      ]);
      return CorrectnessEnum.COMPLETE;
    }
    if (EvaluatorFactory.isCorrect(type, pop, answers)) {
      this.resultsService.numCorrect++;
      if (this.currTry === 0) {
        this.resultsService.numPerfect++;
        if (popIndex !== -1) {
          this.answerStates[popIndex] = AnswerStateEnum.PERFECT;
        }
        this.recordResponse(submittedAnswers);
        return CorrectnessEnum.PERFECT;
      } else {
        if (popIndex !== -1) {
          this.answerStates[popIndex] = AnswerStateEnum.CORRECT;
        }
        this.recordResponse(submittedAnswers);
        return CorrectnessEnum.CORRECT;
      }
    } else {
      if (this.currTry + 1 < (pop.tries ?? TeiTryDefaults[type])) {
        this.currTry++;
        this.recordResponse(submittedAnswers);
        this.questionLogService.reAskQuestion();
        return CorrectnessEnum.INCORRECT;
      } else {
        this.resultsService.numIncorrect++;
        if (popIndex !== -1) {
          this.answerStates[popIndex] = AnswerStateEnum.INCORRECT;
        }
        this.recordResponse(submittedAnswers);
        return CorrectnessEnum.INCORRECT_FINAL;
      }
    }
  }

  /**
   * Translates a 1d or 2d array of strings or numbers into a 1d string array, adding inner delim if 2d array
   * @param answers array, either strings or numbers, 1d or 2d
   * @returns a string array of the answers
   */
  translatePopulationAnswerArrayToStringArray(
    answers: (string | number)[] | (string | number)[][]
  ): string[] {
    if (typeof answers[0] === 'string') {
      return answers as string[];
    } else if (typeof answers[0] === 'number') {
      return (answers as number[]).map(answer => answer.toString());
    } else if (Array.isArray(answers[0])) {
      if (typeof answers[0][0] === 'number') {
        return (answers as number[][]).map(answer => {
          return answer.join(this.questionLogService.STRING_INNER_DELIMITER);
        });
      } else {
        return (answers as string[][]).map(answer => {
          return answer.join(this.questionLogService.STRING_INNER_DELIMITER);
        });
      }
    }

    console.warn(`Could not translate answers: `, answers);
    return [];
  }

  private buildMultiChoiceQuestionDataArray(population: Population): {
    correctAnswers: string[];
    answerChoices: string[];
  } {
    if (!MultipleChoiceTypes.includes(population.templateID)) {
      console.warn(
        'Attempted to build multi choice question data from a non multi choice question',
        population.templateID
      );
      return { correctAnswers: [], answerChoices: [] };
    }
    const popData = population.populationData as PopulationMultipleChoice;
    const answerChoices: string[] = [];
    const correctAnswers: string[] = [];
    if (
      population.templateID === TemplateID.MULTIPLE_CHOICE_IMAGE ||
      population.templateID === TemplateID.MULTI_SELECT_IMAGE
    ) {
      (popData as PopulationMultipleChoice).answerChoices.forEach(choice => {
        const answer = choice.choice.images?.at(0)?.url ?? '';
        answerChoices.push(choice.choice.images?.at(0)?.url ?? '');
        if (choice.id) {
          this.idToAnswerTextMap.set(choice.id, answer);
          if (popData.answers.includes(choice.id)) {
            correctAnswers.push(answer);
          }
        }
      });
    } else {
      (popData as PopulationMultipleChoice).answerChoices.forEach(choice => {
        const answer = choice.choice.texts?.at(0)?.text ?? '';
        answerChoices.push(choice.choice.texts?.at(0)?.text ?? '');
        if (choice.id) {
          this.idToAnswerTextMap.set(choice.id, answer);
          if (popData.answers.includes(choice.id)) {
            correctAnswers.push(answer);
          }
        }
      });
    }

    return { correctAnswers: correctAnswers, answerChoices: answerChoices };
  }

  private buildTextEntryAnswersArray(population: Population): {
    correctAnswers: string[];
    answerChoices: string[];
  } {
    if (population.templateID !== TemplateID.TEXT_ENTRY) {
      console.warn(
        'Attempted to build text entry answers array from non-text entry question',
        population.templateID
      );
      return { correctAnswers: [], answerChoices: [] };
    }

    const popData = population.populationData as PopulationTextEntry;
    const correctAnswers: string[] = popData.answers.map(answer =>
      answer.toString()
    );

    return {
      correctAnswers: correctAnswers,
      answerChoices: correctAnswers,
    };
  }

  private buildInlineChoiceAnswersArray(population: Population): {
    correctAnswers: string[];
    answerChoices: string[];
  } {
    if (population.templateID !== TemplateID.INLINE_CHOICE) {
      console.warn(
        'Attempted to build inline choice answers array from non-inline choice question',
        population.templateID
      );
      return { correctAnswers: [], answerChoices: [] };
    }

    const popData = population.populationData as PopulationInlineChoice;
    const answerChoices: string[] = [];
    const correctAnswers: string[] = [];
    (popData as PopulationInlineChoice).answerChoices.forEach(choice => {
      if (choice.choice.texts) {
        choice.choice.texts.forEach(text => {
          answerChoices.push(text.text);
          if (text.id) {
            this.idToAnswerTextMap.set(text.id, text.text);
            if (popData.answers.includes(text.id)) {
              correctAnswers.push(text.text);
            }
          }
        });
      }
    });

    return { correctAnswers: correctAnswers, answerChoices: answerChoices };
  }

  private buildDndAnswersArray(population: Population): {
    correctAnswers: string[];
    answerChoices: string[];
  } {
    if (!DragAndDropTypes.includes(population.templateID)) {
      console.warn(
        'Attempted to build dnd answers array from non-dnd choice question',
        population.templateID,
        DragAndDropTypes
      );
      return { correctAnswers: [], answerChoices: [] };
    }
    const popData = population.populationData as PopulationDragAndDrop;
    const answerChoices: string[] = [];
    const correctAnswers: string[] = [];

    if (population.templateID === TemplateID.SORTING_BUCKETS_IMAGES) {
      (popData as PopulationDragAndDrop).answerChoices.forEach(choice => {
        const answer = choice.choice.images?.at(0)?.url ?? '';
        answerChoices.push(answer);
        if (choice.id) {
          this.idToAnswerTextMap.set(choice.id, answer);
          if (popData.answers.flat().includes(choice.id)) {
            correctAnswers.push(answer);
          }
        }
      });
    } else {
      (popData as PopulationDragAndDrop).answerChoices.forEach(choice => {
        const answer = choice.choice.texts?.at(0)?.text ?? '';
        answerChoices.push(answer);
        if (choice.id) {
          this.idToAnswerTextMap.set(choice.id, answer);
          if (popData.answers.flat().includes(choice.id)) {
            correctAnswers.push(answer);
          }
        }
      });
    }

    return { correctAnswers: correctAnswers, answerChoices: answerChoices };
  }

  getAnswerArrays(population: Population): {
    correctAnswers: string[];
    answerChoices: string[];
  } {
    const type = population.templateID;

    if (MultipleChoiceTypes.includes(population.templateID)) {
      return this.buildMultiChoiceQuestionDataArray(population);
    } else if (population.templateID === TemplateID.INLINE_CHOICE) {
      return this.buildInlineChoiceAnswersArray(population);
    } else if (population.templateID === TemplateID.TEXT_ENTRY) {
      return this.buildTextEntryAnswersArray(population);
    } else if (DragAndDropTypes.includes(population.templateID)) {
      return this.buildDndAnswersArray(population);
    }

    console.warn(
      'Attempted to build answer array from unsupported question type',
      type
    );
    return { correctAnswers: [], answerChoices: [] };
  }

  private buildInlineQuestion(population: Population): string {
    if (
      population.templateID !== TemplateID.INLINE_CHOICE &&
      population.templateID !== TemplateID.TEXT_ENTRY &&
      population.templateID !== TemplateID.FILL_IN_THE_BLANK_TILE_TEXT
    ) {
      console.warn(
        'Attempted to build inline choice question from non-inline choice question',
        population.templateID
      );
      return '';
    }

    const popData = population.populationData as PopulationInlineChoice;
    let question = '';
    popData.text.forEach(text => {
      const sanitizedQuestionText = text.text
        .replace(/\{WORD_TOKEN\}/g, '______') // remove all blank words
        .replace(/\{NUMBER_TOKEN\}/g, '______') // remove all blank numbers
        .replace(/\{DND_TOKEN\}/g, '______'); // remove all blank drag and drop tokens
      question += sanitizedQuestionText;
    });

    return question;
  }

  recordQuestion(population: Population): void {
    const popData = population.populationData;
    if (
      ConstructedResponseTypes.includes(population.templateID) ||
      population.templateID === TemplateID.TEXT_ENTRY
    ) {
      if (population.templateID === TemplateID.TEXT_ENTRY) {
        this.questionLogService.askTextEntryQuestion(
          this.currentPopulation.getValue()?.id ?? '',
          (popData as PopulationTextEntry).question?.text ?? '',
          (popData as PopulationTextEntry).answers.map(answer =>
            answer.toString()
          ),
          TemplateID.TEXT_ENTRY
        );
      } else {
        this.questionLogService.askOpenEndedQuestion(
          this.currentPopulation.getValue()?.id ?? '',
          (popData as PopulationConstructedResponse).text.text,
          population.templateID,
          (popData as PopulationConstructedResponse).chart?.data,
          (popData as PopulationConstructedResponse).options.characterLimit
        );
      }
    } else {
      const { correctAnswers, answerChoices } =
        this.getAnswerArrays(population);

      const question = InlineChoiceTypes.includes(
        this.getCurrentPopulationType()
      )
        ? this.buildInlineQuestion(population)
        : popData.question?.text ?? '';

      console.log(
        `question`,
        question,
        population,
        this.getCurrentPopulationType(),
        InlineChoiceTypes.includes(this.getCurrentPopulationType())
      );

      this.questionLogService.askQuestion(
        question,
        correctAnswers,
        answerChoices,
        false,
        population.templateID,
        !SkipQuestionLogSortTypes.includes(population.templateID)
      );
    }
  }

  /**
   * Records a response from the user for the current population
   * @param answers The answers submitted by the user
   * @param firstTry true if first response recorded for this population
   */
  recordResponse(answers: (string | number)[][]): void {
    const currPopIndex = this.populations.findIndex(
      p => p.id === this.currentPopulation.getValue()?.id
    );
    const currPopRes =
      this.populationResponses[
        currPopIndex !== -1 ? currPopIndex : this.populationResponses.length - 1
      ];

    if (!currPopRes) {
      this.populationResponses.push({
        responses: [answers],
        elapsedTime: Date.now() - this.popStartTime,
      });
    } else {
      currPopRes.responses.push(answers);
      currPopRes.elapsedTime = Date.now() - this.popStartTime;
    }

    if (
      ConstructedResponseTypes.includes(this.getCurrentPopulationType()) ||
      this.getCurrentPopulationType() === TemplateID.TEXT_ENTRY
    ) {
      this.questionLogService.answerOpenEndedQuestion(
        this.currentPopulation.getValue()?.id ?? '',
        (answers as string[][])[0][0]
      );
    } else {
      const sortResponse = !SkipQuestionLogSortTypes.includes(
        this.getCurrentPopulationType()
      );

      if (answers?.length > 0 && answers[0]?.length > 0) {
        this.questionLogService.answerQuestion(
          this.translatePopulationAnswerArrayToStringArray(
            answers.map(answer => {
              return answer.map(a => {
                return this.idToAnswerTextMap.get(a.toString()) ?? a.toString();
              });
            })
          ),
          sortResponse
        );
      }
    }
  }

  /**
   * get the current population's data
   * @returns PopulationData representing the current population data
   */
  getCurrentPopulationData(): PopulationData {
    const multiChoiceTypes = [
      TemplateID.MULTIPLE_CHOICE,
      TemplateID.MULTIPLE_CHOICE_IMAGE,
      TemplateID.MULTI_SELECT,
      TemplateID.MULTI_SELECT_IMAGE,
      TemplateID.TRUE_FALSE_CHOICE,
    ];
    const inlineChoiceTypes = [
      TemplateID.INLINE_CHOICE,
      TemplateID.STATIC_HORIZONTAL_SEQUENCING,
      TemplateID.STATIC_VERTICAL_SEQUENCING,
      TemplateID.DYNAMIC_HORIZONTAL_SEQUENCING,
      TemplateID.FILL_IN_THE_BLANK_TILE_TEXT,
      TemplateID.SORTING_BUCKETS,
      TemplateID.SORTING_BUCKETS_IMAGES,
    ];
    const textEntryTypes = [TemplateID.TEXT_ENTRY];
    const constructedResponseTypes = [
      TemplateID.CONSTRUCTED_RESPONSE,
      TemplateID.CONSTRUCTED_RESPONSE_WITH_RUBRIC,
      TemplateID.EXTENDED_CONSTRUCTED_RESPONSE,
      TemplateID.EXTENDED_CONSTRUCTED_RESPONSE_WITH_RUBRIC,
    ];

    const currPop = this.currentPopulation.getValue();
    const type = this.getCurrentPopulationType();
    if (multiChoiceTypes.includes(type)) {
      return currPop?.populationData as PopulationMultipleChoice;
    }

    if (inlineChoiceTypes.includes(type)) {
      return currPop?.populationData as PopulationInlineChoice;
    }

    if (textEntryTypes.includes(type)) {
      return currPop?.populationData as PopulationTextEntry;
    }

    if (constructedResponseTypes.includes(type)) {
      return currPop?.populationData as PopulationConstructedResponse;
    }

    return {};
  }

  getCurrentPopulationAnswerChoices(): AnswerChoice[] {
    const currPop = this.currentPopulation.getValue();
    let answerChoices: AnswerChoice[] = [];

    if (currPop && 'answerChoices' in currPop.populationData) {
      answerChoices = currPop.populationData.answerChoices;
    }

    return answerChoices;
  }

  getTotalCorrectTargets(id: string): number {
    let total = 0;
    for (let i = 0; i < this.populations.length; i++) {
      const pop = this.populations[i];
      if (pop.populationData && pop.populationData.associatedSuccessCriteria) {
        pop.populationData.associatedSuccessCriteria.forEach(tag => {
          if (
            tag === id &&
            this.answerStates.length > i &&
            (this.answerStates[i] === AnswerStateEnum.CORRECT ||
              this.answerStates[i] === AnswerStateEnum.PERFECT ||
              this.answerStates[i] === AnswerStateEnum.COMPLETE)
          ) {
            total += 1;
          }
        });
      }
    }

    return total;
  }

  getTotalTargets(id: string): number {
    let total = 0;
    this.populations.forEach(pop => {
      if (pop.populationData && pop.populationData.associatedSuccessCriteria) {
        pop.populationData.associatedSuccessCriteria.forEach(criteria => {
          if (criteria === id) {
            total += 1;
          }
        });
      }
    });

    return total;
  }

  /**
   * Set up for the new question
   */
  nextQuestion(newPop: Population, isLastQuestion?: boolean): void {
    this.filledAnswers.next([[]]);
    this.selectedAnswers.next([[]]);
    this.disabledAnswers.next([[]]);
    this.prevSelectedAnswers = [[]];
    this.submittedSelectedAnswers = [[]];
    this.currTry = 0;
    this.currentPopulation.next(newPop);
    this._isLastQuestion = isLastQuestion ?? false;
    this.isAllHintsSeen.next(false);
    this.idToAnswerTextMap = new Map();
    console.log(`next question, undid map`);
    this.questionLogService.resetState();
    this.recordQuestion(newPop);
  }

  //#endregion

  //#region TEI component methods
  getAnswersStateCount(state: AnswerStateEnum): number {
    return this.answerStates.filter(answerState => answerState === state)
      .length;
  }

  setSelectedAnswers(answers: (string | number)[][]): void {
    this.prevSelectedAnswers = this.selectedAnswers.getValue();
    this.selectedAnswers.next(answers);
  }

  setDisabledAnswers(pop: PopulationData): void {
    const type = this.getCurrentPopulationType();
    const answerChoices = this.getCurrentPopulationAnswerChoices();

    const selectedWrongAnswers = EvaluatorFactory.getSelectedWrongAnswers(
      type,
      pop,
      this.selectedAnswers.value
    );

    this.targetedFeedbacks = EvaluatorFactory.getSelectedWrongTargetedFeedback(
      type,
      selectedWrongAnswers,
      answerChoices,
      pop
    );

    this.disabledAnswers.next(
      EvaluatorFactory.getWrongAnswers(
        type,
        pop,
        this.selectedAnswers.value,
        this.disabledAnswers.value
      )
    );

    this.prevSelectedAnswers = this.selectedAnswers.getValue();
    this.selectedAnswers.next(
      EvaluatorFactory.removeWrongAnswers(
        type,
        this.disabledAnswers.value,
        this.selectedAnswers.value
      )
    );
  }

  getFilledAnswers(
    answers: (string | number)[][],
    popAnswers: (string | number)[] | (string | number)[][],
    selectedAnswers: BehaviorSubject<(string | number)[][]>
  ): (string | number)[][] {
    if (!Array.isArray(popAnswers[0])) {
      popAnswers = [popAnswers] as (string | number)[][];
    }
    for (let i = 0; i < popAnswers.length; i += 1) {
      // if selected answers are the same size as the population answers
      // we need to compare the answer states with each other
      const populationAnswers = popAnswers[i] as (string | number)[];
      if (!selectedAnswers.value[i]) {
        break;
      }
      if (
        selectedAnswers.value[i].filter(item => item !== '-1' && item !== '')
          .length === populationAnswers.length
      ) {
        if (
          selectedAnswers.value[i].every(
            value =>
              populationAnswers
                .toString()
                .trim()
                .toLowerCase()
                .indexOf(value.toString().trim().toLowerCase()) !== -1
          )
        ) {
          answers[i] = populationAnswers as (string | number)[];
        }
      } else {
        for (let j = 0; j < selectedAnswers.value[i].length; j += 1) {
          const index = this.getIndexOfAnAnswer(
            populationAnswers,
            selectedAnswers,
            i,
            j
          );
          if (index !== -1) {
            if (
              this.areAnswersWithOrder(
                this.currentPopulation.getValue()?.templateID ?? TemplateID.NONE
              )
            ) {
              // if we need to maintain the order of the answers
              answers[i][index] = populationAnswers[index] as string | number;
            } else {
              answers[i][j] = populationAnswers[index] as string | number;
            }
          }
        }
      }
    }
    return answers;
  }

  private getIndexOfAnAnswer(
    populationAnswers: (string | number)[],
    selectedAnswers: BehaviorSubject<(string | number)[][]>,
    i: number,
    j: number
  ): number {
    let index = -1;

    if (
      this.areAnswersWithOrder(
        this.currentPopulation.getValue()?.templateID ?? TemplateID.NONE
      )
    ) {
      // an answer must match the position
      index =
        populationAnswers.map(item => item.toString())[j] ===
        (selectedAnswers.value[i][j] as string)
          ? j
          : -1;
    } else {
      index = populationAnswers
        .map(item => item.toString())
        .indexOf(selectedAnswers.value[i][j] as string);
    }
    return index;
  }

  getMaxAttempts(tempType: TemplateID): number {
    const maxOneTry =
      tempType === TemplateID.TRUE_FALSE_CHOICE ||
      ConstructedResponseTypes.includes(tempType);
    return maxOneTry ? 1 : 2;
  }

  getCorrectAnswers(
    currTry: number,
    type: TemplateID,
    selectedAnswers: BehaviorSubject<(string | number)[][]>,
    answers?:
      | (string | number)[]
      | (string | number)[][]
      | string[]
      | string[][],
    isFinishedPopOverride?: boolean,
    setFilledAnswersOverride?: boolean
  ): (string | number)[][] {
    const popAnswers = answers ?? [];
    const areAnswers2dArray =
      popAnswers.length > 0 ? Array.isArray(popAnswers[0]) : false;

    let finishedPop = isFinishedPopOverride;
    if (finishedPop === undefined) {
      finishedPop = currTry >= this.getMaxAttempts(type);
    }

    if (finishedPop) {
      this.filledAnswers.next([[]]);

      if (areAnswers2dArray) {
        return popAnswers as string[][];
      }
      return [popAnswers as (string | number)[]];
    }

    if (popAnswers.length === 0) {
      return [popAnswers as (string | number)[]];
    }

    let a = [];
    if (areAnswers2dArray) {
      a = popAnswers.map(item => {
        item = item as string[];
        return Array(item.length).fill('');
      });
    } else {
      a = [Array(popAnswers.length).fill('')];
    }

    this.setFilledAnswersFromTries(
      currTry,
      a,
      popAnswers,
      selectedAnswers,
      setFilledAnswersOverride
    );

    return this.filledAnswers.getValue().length > 0 &&
      this.filledAnswers.getValue()[0].length > 0
      ? this.filledAnswers.getValue()
      : a;
  }

  setFilledAnswersFromTries(
    currTry: number,
    a: (string | number)[][],
    popAnswers: (string | number)[] | (string | number)[][] | string[][],
    selectedAnswers: BehaviorSubject<(string | number)[][]>,
    setFilledAnswersOverride?: boolean
  ): (string | number)[][] {
    if (
      (currTry > 0 && this.lastFilledCheck < currTry) ||
      setFilledAnswersOverride
    ) {
      const _filledAnswers = this.getFilledAnswers(
        a,
        popAnswers,
        selectedAnswers
      );

      this.filledAnswers.next(_filledAnswers);
      this.lastFilledCheck = currTry;

      return _filledAnswers;
    }
    return [[]];
  }

  areAnswersWithOrder(activeTemplateId: TemplateID): boolean {
    if (
      activeTemplateId === TemplateID.SORTING_BUCKETS ||
      activeTemplateId === TemplateID.SORTING_BUCKETS_IMAGES ||
      activeTemplateId === TemplateID.MULTI_SELECT ||
      activeTemplateId === TemplateID.MULTI_SELECT_IMAGE ||
      activeTemplateId === TemplateID.TEXT_ENTRY
    ) {
      return false;
    }
    return true;
  }

  resumeTargetedFeedback(): void {
    const curPop = this.currentPopulation.getValue();
    if (curPop && this.disabledAnswers.getValue().length > 0) {
      const type = this.getCurrentPopulationType();
      const answerChoices = this.getCurrentPopulationAnswerChoices();
      let selectedWrongAnswers: (string | number)[][] = [[]];
      this.targetedFeedbacks = [];
      const allFeedbacks = [];
      if (!ComplexFeedbackTypes.includes(type)) {
        let disabledAnswers;
        if (curPop.populationData.answers?.length === 1) {
          disabledAnswers = [this.disabledAnswers.getValue()[0]];
        } else {
          disabledAnswers = this.disabledAnswers.getValue();
        }
        selectedWrongAnswers = EvaluatorFactory.getSelectedWrongAnswers(
          type,
          curPop.populationData,
          disabledAnswers
        );
      } else {
        const disAnswers = JSON.parse(
          JSON.stringify(this.disabledAnswers.getValue())
        );

        // when resuming an activity, we need to show all feedbacks for each try
        while (disAnswers.length && disAnswers.join('').length > 0) {
          let combinedDisAnswers: (string | number)[] = [];
          for (let i = 0; i < disAnswers.length; i++) {
            if (disAnswers[i].length > 0) {
              combinedDisAnswers = combinedDisAnswers.concat(disAnswers[i][0]);
            } else {
              combinedDisAnswers.push('');
            }
            disAnswers[i].shift();
          }

          selectedWrongAnswers = EvaluatorFactory.getSelectedWrongAnswers(
            type,
            curPop.populationData,
            [combinedDisAnswers]
          );
          allFeedbacks.push(
            ...EvaluatorFactory.getSelectedWrongTargetedFeedback(
              type,
              selectedWrongAnswers,
              answerChoices,
              curPop.populationData
            )
          );
        }
        selectedWrongAnswers = [];
      }

      this.targetedFeedbacks = [
        ...allFeedbacks,
        ...EvaluatorFactory.getSelectedWrongTargetedFeedback(
          type,
          selectedWrongAnswers,
          answerChoices,
          curPop.populationData
        ),
      ];
    }
  }

  resumeActivity(): void {
    if (this.currTry > 0) {
      this.resumeTargetedFeedback();
    }

    this.resumeEvent.next(false);
    this.resumeEvent.next(true);
  }

  isLastQuestion(): boolean {
    return this._isLastQuestion;
  }
}
