import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoJsPlayerComponent } from './components/video-js-player/video-js-player.component';
import { VideoContainerComponent } from './components/video-container/video-container.component';
import { AutoScrollTranscriptComponent } from './components/auto-scroll-transcript/auto-scroll-transcript.component';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { MathTextModule } from '../math-text/math-text.module';

@NgModule({
  declarations: [
    VideoJsPlayerComponent,
    AutoScrollTranscriptComponent,
    VideoContainerComponent,
  ],
  imports: [CommonModule, HttpClientModule, TranslateModule, MathTextModule],
  exports: [VideoContainerComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class VideoModule {}
