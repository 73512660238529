import { Activity } from 'src/app/shared/interfaces';

export const lectura4: Activity = {
  id: '018ea019-0560-7cf1-ba83-14a12e832b9f',
  product: 'Lectura',
  language: 'es',
  skillName: 'Preposiciones',
  thumbnail: {
    id: 'recjexZF7wJx6ze6T',
    url: '/helix/UX_Images/Lesson_Posters/Prepositions_G3V4_Poster.jpg',
    altText: [
      {
        language: 'en',
        altText: 'Axis and CAT look at a panel on top of the spaceship.',
      },
      {
        language: 'es',
        altText:
          'Axis y G.A.T.A. observan un panel encima de la nave espacial.',
      },
    ],
  },
  instructionsImage: {
    id: 'recShqii4tO2JCQyf',
    url: '/helix/UX_Images/Characters/Axis/Axis_Chibi_Head_Happy_Mouth_Open.svg',
    altText: [
      {
        language: 'en',
        altText: 'Axis is smiling.',
      },
      {
        language: 'es',
        altText: 'Axis está sonriendo.',
      },
    ],
  },
  characterLeanout: {
    id: 'recBBGySIgxHLnC6k',
    url: '/helix/TEI/Leanouts/Axis_Leanout_01.svg',
    altText: [
      {
        language: 'en',
        altText: 'Axis leans out from the side of the screen.',
      },
      {
        language: 'es',
        altText: 'Axis se asoma por el lado de la pantalla.',
      },
    ],
  },
  description:
    'Axis y G.A.T.A. encontraron las piezas de la nave que faltaban, pero volver a armarlas podría ser complicado. Su manual de instrucciones también resultó deteriorado, por lo que este dúo dinámico deberá utilizar su conocimiento sobre preposiciones y locuciones preposicionales para armar las piezas. ',
  domain: {
    text: 'Parts of Speech',
  },
  seriesName: 'Misión terrestre',
  episodeNumber: '4',
  episodeTitle: 'Herramienta espacial en acción',
  videos: [
    {
      id: 'recagsH7FpPOkJjTS',
      url: '/helix/Lectura/Grade_3/Prepositions/Episode_04/hls_streams/L_G3_Prepositions_EP04_Video.m3u8',
      posterURL: '/helix/UX_Images/Lesson_Posters/Prepositions_G3V4_Poster.jpg',
      captions: [
        {
          src: '/helix/Lectura/Grade_3/Prepositions/Episode_04/L_G3_Prepositions_EP04_Video.vtt',
          srclang: 'es',
          mode: 'hidden',
          kind: 'captions',
        },
      ],
    },
  ],
  startScreenData: {
    learningTarget: [
      {
        id: '32597.D',
        learningTarget: {
          text: 'a usar locucaciones o frases preposicionales',
        },
        successCriteria: [
          {
            id: '32597.D.1',
            text: 'etender que las locuciones o frases preposicionales se asemejan a una preposición en su significado',
          },
          {
            id: '32597.D.2',
            text: 'usar locuciones o frases preposicionales en lugar de preposiciones en una oración',
          },
        ],
      },
    ],
    background_leftImage: {
      id: 'recjexZF7wJx6ze6T',
      url: '/helix/UX_Images/Lesson_Posters/Prepositions_G3V4_Poster.jpg',
      altText: [
        {
          language: 'en',
          altText: 'Axis and CAT look at a panel on top of the spaceship.',
        },
        {
          language: 'es',
          altText:
            'Axis y G.A.T.A. observan un panel encima de la nave espacial.',
        },
      ],
    },
    background_rightImage: {
      id: 'recxjC353VMav5ybu',
      url: '/helix/UX_Images/Start_Screen_Blobs/Nova-Scouts_space-sky.svg',
      altText: [
        {
          language: 'en',
          altText: 'Stars in space.',
        },
        {
          language: 'es',
          altText: 'Estrellas en el espacio.',
        },
      ],
    },
    foreground: {
      id: 'recJFZiQMxFozF9ks',
      url: '/helix/UX_Images/Start_Screen_Characters/Axis3.svg',
      altText: [
        {
          language: 'en',
          altText: 'Axis is pointing at something.',
        },
        {
          language: 'es',
          altText: 'Axis está señalando algo.',
        },
      ],
    },
  },
  populations: [
    {
      id: '32597.D.1.1',
      templateID: 0,
      complexity: 'Application',
      hint: [
        {
          id: '32597.D.1.1.0',
          type: 'BasicSlide',
          data: {
            title:
              'Las preposiciones de lugar dan información sobre dónde se encuentra un objeto.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Observa la imagen. Escoge la preposición correcta que complete la oración. \n«Los libros están \\_\\_\\_ el estante».\n',
        },
        answerChoices: [
          {
            id: '32597.D.1.1_1',
            choice: {
              texts: [
                {
                  text: 'en\n',
                },
              ],
            },
          },
          {
            id: '32597.D.1.1_2',
            choice: {
              texts: [
                {
                  text: 'bajo\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Recuerda que «bajo» significa en un lugar o posición inferior.\n',
              },
            },
          },
          {
            id: '32597.D.1.1_3',
            choice: {
              texts: [
                {
                  text: 'con\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '«Con» es una preposición que indica modo, no el lugar. \n',
              },
            },
          },
        ],
        answers: ['32597.D.1.1_1'],
        associatedSuccessCriteria: ['32597.D.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La respuesta es «en» porque esta preposición indica dónde está el objeto. «En el estante» sería el lugar en donde están los libros. \n',
      },
      image: {
        id: 'recpNzs8TZK2F2tCo',
        url: '/helix/TEI/SVGs/TEI_prepositions_bedroom_01.svg',
        altText: [
          {
            language: 'en',
            altText: 'A bedroom with a guitar, backpack, and rollerblades.',
          },
          {
            language: 'es',
            altText:
              'Una habitación con una guitarra, una mochila y unos patines.',
          },
        ],
      },
    },
    {
      id: '32597.D.1.2',
      templateID: 0,
      complexity: 'Application',
      hint: [
        {
          id: '32597.D.1.2.0',
          type: 'BasicSlide',
          data: {
            title:
              'Las preposiciones de lugar dan información sobre dónde se encuentra un objeto.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Observa la imagen. Escoge la preposición correcta que complete la oración. \n«La guitarra está apoyada \\_\\_\\_la pared».\n',
        },
        answerChoices: [
          {
            id: '32597.D.1.2_1',
            choice: {
              texts: [
                {
                  text: 'contra \n',
                },
              ],
            },
          },
          {
            id: '32597.D.1.2_2',
            choice: {
              texts: [
                {
                  text: 'con\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Observa la imagen nuevamente. «Con» quiere decir en compañía de algo o alguien.\n',
              },
            },
          },
          {
            id: '32597.D.1.2_3',
            choice: {
              texts: [
                {
                  text: 'por\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La preposición «por» nos dice el motivo por el cual sucede algo. \n',
              },
            },
          },
        ],
        answers: ['32597.D.1.2_1'],
        associatedSuccessCriteria: ['32597.D.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La respuesta es «contra» porque esta preposición indica dónde está el objeto. Aquí vemos la guitarra apoyada «contra» la pared.\n',
      },
      image: {
        id: 'recpNzs8TZK2F2tCo',
        url: '/helix/TEI/SVGs/TEI_prepositions_bedroom_01.svg',
        altText: [
          {
            language: 'en',
            altText: 'A bedroom with a guitar, backpack, and rollerblades.',
          },
          {
            language: 'es',
            altText:
              'Una habitación con una guitarra, una mochila y unos patines.',
          },
        ],
      },
    },
    {
      id: '32597.D.1.3',
      templateID: 0,
      complexity: 'Application',
      hint: [
        {
          id: '32597.D.1.3.0',
          type: 'BasicSlide',
          data: {
            title:
              'Las preposiciones de lugar dan información sobre dónde se encuentra un objeto.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Observa la imagen. Escoge la preposición correcta que complete la oración. \n«El póster está pegado \\_\\_\\_ la pared».\n',
        },
        answerChoices: [
          {
            id: '32597.D.1.3_1',
            choice: {
              texts: [
                {
                  text: 'sobre\n',
                },
              ],
            },
          },
          {
            id: '32597.D.1.3_2',
            choice: {
              texts: [
                {
                  text: 'por\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La preposición «por» nos dice el motivo por el cual sucede algo. \n',
              },
            },
          },
          {
            id: '32597.D.1.3_3',
            choice: {
              texts: [
                {
                  text: 'tras\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Observa la ubicación del póster. «Tras» significa «detrás de». \n',
              },
            },
          },
        ],
        answers: ['32597.D.1.3_1'],
        associatedSuccessCriteria: ['32597.D.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La respuesta es «sobre» porque esta preposición indica la posición del objeto. Aquí vemos el póster ubicado «sobre» la pared. \n',
      },
      image: {
        id: 'recpNzs8TZK2F2tCo',
        url: '/helix/TEI/SVGs/TEI_prepositions_bedroom_01.svg',
        altText: [
          {
            language: 'en',
            altText: 'A bedroom with a guitar, backpack, and rollerblades.',
          },
          {
            language: 'es',
            altText:
              'Una habitación con una guitarra, una mochila y unos patines.',
          },
        ],
      },
    },
    {
      id: '32597.D.2.4',
      templateID: 1,
      complexity: 'Application',
      hint: [
        {
          id: '32597.D.2.4.0',
          type: 'BasicSlide',
          data: {
            title:
              'Las locuciones preposicionales al igual que las preposiciones nos dan información sobre el lugar en donde se encuentra un objeto. \n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Elige la locución o frase preposicional que mejor complete la oración. \n«Los patines están {CHOICE_TOKEN} piso».\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '32597.D.2.4_1',
                  text: 'encima del \n',
                },
                {
                  id: '32597.D.2.4_2',
                  text: 'al lado de\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'Vuelve a observar la imagen. La locución o frase preposicional «al lado de» significa que el objeto está junto a algo.\n',
                    },
                  },
                },
                {
                  id: '32597.D.2.4_3',
                  text: 'dentro de \n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'Esta locución preposicional significa en el interior o dentro de un espacio. \n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['32597.D.2.4_1'],
        associatedSuccessCriteria: ['32597.D.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'La respuesta es «encima del» porque nos indica la ubicación del objeto. En la imagen vemos que los patines están ubicados «encima del» piso. \n',
      },
      image: {
        id: 'recpNzs8TZK2F2tCo',
        url: '/helix/TEI/SVGs/TEI_prepositions_bedroom_01.svg',
        altText: [
          {
            language: 'en',
            altText: 'A bedroom with a guitar, backpack, and rollerblades.',
          },
          {
            language: 'es',
            altText:
              'Una habitación con una guitarra, una mochila y unos patines.',
          },
        ],
      },
    },
    {
      id: '32597.D.2.5',
      templateID: 1,
      complexity: 'Application',
      hint: [
        {
          id: '32597.D.2.5.0',
          type: 'BasicSlide',
          data: {
            title:
              'Las locuciones preposicionales al igual que las preposiciones nos dan información sobre el lugar en donde se encuentra un objeto. \n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Elige la locución o frase preposicional que mejor complete la oración. \n«Sus zapatos están {CHOICE_TOKEN} la cama».\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '32597.D.2.5_1',
                  text: 'debajo de \n',
                },
                {
                  id: '32597.D.2.5_2',
                  text: 'dentro de \n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'Esta locución o frase preposicional significa en el interior o dentro de un espacio. \n',
                    },
                  },
                },
                {
                  id: '32597.D.2.5_3',
                  text: 'junto con\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'Vuelve a intentarlo. La locución preposicional «junto con» significa en compañía de alguien.\n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['32597.D.2.5_1'],
        associatedSuccessCriteria: ['32597.D.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'La respuesta correcta es «debajo de» porque nos indica la ubicación del objeto. En la imagen vemos que los zapatos están ubicados «debajo de» la cama. \n',
      },
      image: {
        id: 'recpNzs8TZK2F2tCo',
        url: '/helix/TEI/SVGs/TEI_prepositions_bedroom_01.svg',
        altText: [
          {
            language: 'en',
            altText: 'A bedroom with a guitar, backpack, and rollerblades.',
          },
          {
            language: 'es',
            altText:
              'Una habitación con una guitarra, una mochila y unos patines.',
          },
        ],
      },
    },
    {
      id: '32597.D.2.6',
      templateID: 1,
      complexity: 'Application',
      hint: [
        {
          id: '32597.D.2.6.0',
          type: 'BasicSlide',
          data: {
            title:
              'Las locuciones preposicionales al igual que las preposiciones nos dan información sobre el lugar en donde se encuentra un objeto. \n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Elige la locución o frase preposicional que mejor complete la oración. \n«La camisa está {CHOICE_TOKEN} su armario». \n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '32597.D.2.6_1',
                  text: 'dentro de\n',
                },
                {
                  id: '32597.D.2.6_2',
                  text: 'frente a \n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'Esta locución o frase preposicional indica que el objeto está delante de otro objeto. \n',
                    },
                  },
                },
                {
                  id: '32597.D.2.6_3',
                  text: 'al lado de\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'Vuelve a observar la imagen. La locución preposicional «al lado de» significa que el objeto está ubicado cerca de otro objeto.\n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['32597.D.2.6_1'],
        associatedSuccessCriteria: ['32597.D.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'La respuesta correcta es «dentro de» porque nos indica el lugar donde se encuentra el objeto. En la imagen vemos que la chaqueta está «dentro del» armario.\n',
      },
      image: {
        id: 'recpNzs8TZK2F2tCo',
        url: '/helix/TEI/SVGs/TEI_prepositions_bedroom_01.svg',
        altText: [
          {
            language: 'en',
            altText: 'A bedroom with a guitar, backpack, and rollerblades.',
          },
          {
            language: 'es',
            altText:
              'Una habitación con una guitarra, una mochila y unos patines.',
          },
        ],
      },
    },
    {
      id: '32597.D.2.7',
      templateID: 5,
      complexity: 'Analysis',
      hint: [
        {
          id: '32597.D.2.7.0',
          type: 'BasicSlide',
          data: {
            title:
              'Recuerda, en una oración las locuciones o frases preposicionales te dan información sobre el lugar, tiempo, modo o causa. \n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Selecciona las locuciones preposicionales que completen la oración correctamente. \n«Ella va a jugar tenis...»\n',
        },
        answerChoices: [
          {
            id: '32597.D.2.7_1',
            choice: {
              texts: [
                {
                  text: 'después de las 8 a. m.  \n',
                },
              ],
            },
          },
          {
            id: '32597.D.2.7_2',
            choice: {
              texts: [
                {
                  text: 'dentro de la cancha de tenis\n',
                },
              ],
            },
          },
          {
            id: '32597.D.2.7_3',
            choice: {
              texts: [
                {
                  text: 'junto a sus amigos \n',
                },
              ],
            },
          },
          {
            id: '32597.D.2.7_4',
            choice: {
              texts: [
                {
                  text: 'junto a las 8 a.m.\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Esta opción no es posible, porque «junto a» significa en compañía de alguien. No indica el tiempo.\n',
              },
            },
          },
          {
            id: '32597.D.2.7_5',
            choice: {
              texts: [
                {
                  text: 'dentro de un autobús\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Esta opción no hace que la oración tenga sentido, ya que no puedes jugar tenis dentro de un autobús. \n',
              },
            },
          },
          {
            id: '32597.D.2.7_6',
            choice: {
              texts: [
                {
                  text: 'con su bate \n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '«Con» es una preposición. Recuerda que una locución preposicional está formada por dos o más palabras.\n',
              },
            },
          },
        ],
        answers: ['32597.D.2.7_1', '32597.D.2.7_2', '32597.D.2.7_3'],
        associatedSuccessCriteria: ['32597.D.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'Estas son las respuestas correctas porque todas estas locuciones preposicionales te dan información sobre el lugar, tiempo, modo o causa y hacen que la oración tenga sentido. \n',
      },
    },
    {
      id: '32597.D.2.8',
      templateID: 5,
      complexity: 'Analysis',
      hint: [
        {
          id: '32597.D.2.8.0',
          type: 'BasicSlide',
          data: {
            title:
              'Recuerda, en una oración las locuciones o frases preposicionales te dan información sobre el lugar, tiempo, modo o causa. \n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Selecciona las frases preposicionales que completen la oración correctamente. \n«La cena se servirá...»\n',
        },
        answerChoices: [
          {
            id: '32597.D.2.8_1',
            choice: {
              texts: [
                {
                  text: 'en la mesa que está junto a la cocina \n',
                },
              ],
            },
          },
          {
            id: '32597.D.2.8_2',
            choice: {
              texts: [
                {
                  text: 'después de llegar a casa\n\n\n',
                },
              ],
            },
          },
          {
            id: '32597.D.2.8_3',
            choice: {
              texts: [
                {
                  text: 'antes de comer el postre \n',
                },
              ],
            },
          },
          {
            id: '32597.D.2.8_4',
            choice: {
              texts: [
                {
                  text: 'en la mañana \n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '«En» es una preposición. Además la oración no tendría sentido porque la cena no se come en la mañana.\n',
              },
            },
          },
          {
            id: '32597.D.2.8_5',
            choice: {
              texts: [
                {
                  text: 'antes del desayuno \n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Está opción no tiene sentido porque la cena no se come en la mañana. \n',
              },
            },
          },
          {
            id: '32597.D.2.8_6',
            choice: {
              texts: [
                {
                  text: 'después del postre \n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Si eliges está opción la oración no tendría sentido porque la cena se come antes del postre. \n',
              },
            },
          },
        ],
        answers: ['32597.D.2.8_1', '32597.D.2.8_2', '32597.D.2.8_3'],
        associatedSuccessCriteria: ['32597.D.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'Estas son las respuestas correctas porque todas estas locuciones preposicionales te dan información sobre el lugar, tiempo, modo o causa y hacen que la oración tenga sentido. \n',
      },
    },
    {
      id: '32597.D.1.9',
      templateID: 5,
      complexity: 'Analysis',
      hint: [
        {
          id: '32597.D.1.9.0',
          type: 'BasicSlide',
          data: {
            title:
              'La preposición «en» indica en qué lugar, tiempo o modo se realiza lo que dice el sujeto.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Identifica el uso correcto de la preposición «en». \n',
        },
        answerChoices: [
          {
            id: '32597.D.1.9_1',
            choice: {
              texts: [
                {
                  text: 'Mi abuela viene a visitarme en agosto. \n',
                },
              ],
            },
          },
          {
            id: '32597.D.1.9_2',
            choice: {
              texts: [
                {
                  text: 'Tengo una mascota en mi casa. \n',
                },
              ],
            },
          },
          {
            id: '32597.D.1.9_3',
            choice: {
              texts: [
                {
                  text: 'Mi papá viajó en tren.  \n',
                },
              ],
            },
          },
          {
            id: '32597.D.1.9_4',
            choice: {
              texts: [
                {
                  text: 'Estoy en triste. \n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Usar la preposición «en» en esta oración no tiene sentido, ya que «triste» no está indicando lugar, tiempo o modo.  \n',
              },
            },
          },
          {
            id: '32597.D.1.9_5',
            choice: {
              texts: [
                {
                  text: 'Hoy en noche.  \n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Esta oración no tiene sentido. Recuerda que las preposiciones unen palabras en una oración para que esta tenga sentido. \n',
              },
            },
          },
          {
            id: '32597.D.1.9_6',
            choice: {
              texts: [
                {
                  text: 'En almuerzo.\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Esta oración no tiene sentido. Recuerda que las preposiciones unen palabras en una oración para que esta tenga sentido. \n',
              },
            },
          },
        ],
        answers: ['32597.D.1.9_1', '32597.D.1.9_2', '32597.D.1.9_3'],
        associatedSuccessCriteria: ['32597.D.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'Estas son las respuestas correctas porque todas estas preposiciones indican el lugar, tiempo o modo. \n',
      },
    },
    {
      id: '32597.D.1.10',
      templateID: 5,
      complexity: 'Analysis',
      hint: [
        {
          id: '32597.D.1.10.0',
          type: 'BasicSlide',
          data: {
            title: 'La preposición «por» indica lugar o causa.  \n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Identifica el uso correcto de la preposición «por». \n',
        },
        answerChoices: [
          {
            id: '32597.D.1.10_1',
            choice: {
              texts: [
                {
                  text: 'Agarré a mi hermano por el brazo.\n',
                },
              ],
            },
          },
          {
            id: '32597.D.1.10_2',
            choice: {
              texts: [
                {
                  text: 'Por el frío, no fui al parque.\n',
                },
              ],
            },
          },
          {
            id: '32597.D.1.10_3',
            choice: {
              texts: [
                {
                  text: 'No salí a jugar por la lluvia.  \n',
                },
              ],
            },
          },
          {
            id: '32597.D.1.10_4',
            choice: {
              texts: [
                {
                  text: 'Hice una reserva por las 7. \n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Usar «por» en esta oración no tiene sentido. Recuerda que las preposiciones unen palabras en una oración para que esta tenga sentido.  \n',
              },
            },
          },
          {
            id: '32597.D.1.10_5',
            choice: {
              texts: [
                {
                  text: 'Por desayuno voy a tu casa. \n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Usar «por» en esta oración no tiene sentido. Recuerda que las preposiciones unen palabras en una oración para que esta tenga sentido. \n',
              },
            },
          },
          {
            id: '32597.D.1.10_6',
            choice: {
              texts: [
                {
                  text: 'Por lunes llego. \n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Esta oración no tiene sentido. Recuerda que las preposiciones unen palabras en una oración para que esta tenga sentido. \n',
              },
            },
          },
        ],
        answers: ['32597.D.1.10_1', '32597.D.1.10_2', '32597.D.1.10_3'],
        associatedSuccessCriteria: ['32597.D.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'Estas son las respuestas correctas porque todas estas preposiciones indican lugar o causa. \n',
      },
    },
  ],
  activityManifest: [
    {
      type: 'START_SCREEN',
    },
    {
      type: 'ENTRY_SURVEY',
    },
    {
      type: 'VIDEO',
      id: 'recagsH7FpPOkJjTS',
    },
    {
      type: 'QUESTION',
      id: '32597.D.1.1',
    },
    {
      type: 'QUESTION',
      id: '32597.D.1.2',
    },
    {
      type: 'QUESTION',
      id: '32597.D.1.3',
    },
    {
      type: 'QUESTION',
      id: '32597.D.2.4',
    },
    {
      type: 'QUESTION',
      id: '32597.D.2.5',
    },
    {
      type: 'QUESTION',
      id: '32597.D.2.6',
    },
    {
      type: 'QUESTION',
      id: '32597.D.2.7',
    },
    {
      type: 'QUESTION',
      id: '32597.D.2.8',
    },
    {
      type: 'QUESTION',
      id: '32597.D.1.9',
    },
    {
      type: 'QUESTION',
      id: '32597.D.1.10',
    },
    {
      type: 'EXIT_SURVEY',
    },
    {
      type: 'RESULTS_SCREEN',
    },
  ],
  version: '-2068029171',
};
