import { Component } from '@angular/core';
import { SlideTypeEnum } from '../../enums/slide-type';
import { SlideBaseComponent } from '../slide-base/slide-base.component';
import { SpeechRuleEngineService } from 'src/app/modules/math-text/services/speech-rule-engine.service';

@Component({
  selector: 'htc-slide-basic',
  templateUrl: './slide-basic.component.html',
  styleUrls: ['./slide-basic.component.scss'],
})
export class SlideBasicComponent extends SlideBaseComponent {
  constructor(public sres: SpeechRuleEngineService) {
    super();
  }

  type = SlideTypeEnum.basicSlide;
}
