import {
  AnswerChoice,
  PopulationDragAndDrop,
  TargetedFeedback,
} from './../shared/interfaces/';

export class DynamicDnDEvaluator {
  static isCorrect(
    population: PopulationDragAndDrop,
    answers: (string | number)[] | (string | number)[][],
    maintainOrder: boolean
  ): boolean {
    if (!Array.isArray(population.answers[0])) {
      answers = answers[0] as (string | number)[];
    }

    if (maintainOrder) {
      return (
        answers.length === population.answers.length &&
        answers.every(
          (answer, index) =>
            population.answers[index].toString().trim().toLowerCase() ===
            answer.toString().trim().toLowerCase()
        )
      );
    }

    return (
      answers.toString().length === population.answers.toString().length &&
      answers.every((answer, index) =>
        answer
          .toString()
          .trim()
          .toLowerCase()
          .split(',')
          .every(a =>
            population.answers[index]
              .toString()
              .trim()
              .toLowerCase()
              .includes(a)
          )
      )
    );
  }

  static getWrongAnswers(
    population: PopulationDragAndDrop,
    answers: (string | number)[][],
    maintainOrder: boolean
  ): (string | number)[][] {
    const wrong: (string | number)[][] = [];
    if (maintainOrder) {
      for (let i = 0; i < answers.length; i += 1) {
        if (
          (population.answers[i] &&
            answers[i].toString().trim().toLowerCase() !==
              population.answers[i].toString().trim().toLowerCase()) ||
          !population.answers[i]
        ) {
          wrong[i] = answers[i];
        } else {
          wrong[i] = [];
        }
      }
    } else {
      for (let i = 0; i < answers.length; i += 1) {
        const matchedAnswer = answers[i].filter(answer =>
          answer
            .toString()
            .split(',')
            .find(a => !population.answers[i].includes(a))
        );
        if (matchedAnswer) {
          wrong[i] = matchedAnswer;
        } else {
          wrong[i] = [];
        }
      }
    }

    return wrong;
  }

  static getSelectedWrongTargetedFeedback(
    selectedWrongAnswers: (number | string)[][],
    answerChoices: AnswerChoice[]
  ): TargetedFeedback[] {
    const result: TargetedFeedback[] = [];

    answerChoices.forEach(option => {
      if (selectedWrongAnswers.find(k => k.includes(option.id as string))) {
        if (option.targetedFeedback !== undefined) {
          result.push(option.targetedFeedback as TargetedFeedback);
        }
      }
    });

    return result;
  }

  static removeWrongAnswers(
    wrong: (string | number)[][],
    answers: (string | number)[][]
  ): (string | number)[][] {
    return answers.map((answer, i) => {
      answer = answer.filter(a => {
        return wrong[i].indexOf(a) === -1;
      });
      return answer;
    });
  }
}
