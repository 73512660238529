import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToolItem } from 'src/app/modules/tools/classes/tool-item';
import { ToolsService } from 'src/app/modules/tools/services/tools.service';
import { AudioDescriptor } from 'src/app/shared/enums/audio-descriptor';
import { AudioType } from 'src/app/shared/enums/audio-type';

@Component({
  selector: 'htc-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  tools!: ToolItem[];
  animOut = false;
  clickDescriptor = AudioDescriptor.SELECT_MINOR;
  clickType = AudioType.audio_SfxClick;

  subscriptions = new Subscription();

  constructor(public toolsService: ToolsService) {}

  ngOnInit(): void {
    this.tools = this.toolsService.getTools();

    this.subscriptions.add(
      this.toolsService.animOutEvent.subscribe(() => {
        this.animOut = true;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
