import { ActivityTool } from 'src/app/shared/enums';
import { ActivityObjectTypes } from 'src/app/shared/enums/activity-object-types';
import { Activity } from 'src/app/shared/interfaces';
import { constActivityManifestEnd } from './activity-manifest-end';
import { images, TEST_IMAGE_TYPES } from '../images';
import {
  learningTargets,
  TEST_LEARNING_TARGET_TYPES,
} from '../learning-targets';
import { TEST_POPULATION_TYPES } from '../population-types';
import {
  populationsReadingMC,
  populationsReadingMCI,
  populationsReadingMS,
  populationsReadingMSI,
  populationsReadingIC,
  populationsReadingTE,
  populationsReadingSCR,
  populationsReadingECR,
  populationsReadingDND,
  populationsOverridenTries,
  populationsDragAndDropSortingBucketsImages,
} from '../populations';
import { constReadingParams } from './reading-params';
import {
  KATEX_END_DELIM,
  KATEX_START_DELIM,
} from 'src/app/modules/math-text/utils/math-text-utils';
import { SlideTypeEnum } from 'src/app/modules/carousel/enums/slide-type';
import { multiChoiceQuestions } from '../multi-choice-questions';
import { TEST_CHOICE_TYPES } from '../choice-types';
import { multiSelectQuestions } from '../multi-select-questions';
import { inlineChoiceQuestions } from '../inline-choice-questions';
import { fillInTheBlankTileText } from '../fill-in-the-blank-tile-text-questions';
import { staticVerticalSequencing } from '../static-vertical-sequencing';
import { staticHorizontalSequencing } from '../static-horizontal-sequencing';
import { sortingBuckets } from '../sorting-buckets';
import { multiChoiceImageQuestions } from '../multi-choice-image-questions';
import { multiSelectImageQuestions } from '../multi-select-image-questions';
import { textEntryQuestions } from '../text-entry-questions';
import { sortingBucketsImages } from '../sorting-buckets-images';
import { constructedResponseQuestions } from '../constructed-response-questions';
import { extendedConstructedResponseQuestions } from '../extended-constructed-response-questions';
import { TestAssociatedSuccessCriteria } from '../test-associated-success-criteria.enum';

export const testReadingActivities: { [key: string]: Activity } = {
  'TEST.R.MC': {
    id: `TEST.R.MC`,
    ...constReadingParams,
    seriesName: `Testing Multiple Choice Series`,
    episodeNumber: '1',
    episodeTitle: 'Multiple Choice',
    description:
      'MC_SHORT, MC_MEDIUM, MC_LONG, MC_PASSAGE_SHORT, MC_PASSAGE_MEDIUM, MC_PASSAGE_LONG, TEST_R_MC_PASSAGE_SHORT_WITH_TITLE, TEST_R_MC_PASSAGE_MEDIUM_WITH_TITLE, TEST_R_MC_PASSAGE_LONG_WITH_TITLE, TEST_R_MC_TITLE_SHORT, TEST_R_MC_TITLE_MEDIUM, TEST_R_MC_TITLE_LONG, MC_TRUE_FALSE, MC_PASSAGE_TRUE_FALSE, MCI_SHORT, MCI_MEDIUM, MCI_LONG, MCI_PASSAGE_SHORT, MCI_PASSAGE_MEDIUM, MCI_PASSAGE_LONG',
    startScreenData: {
      ...constReadingParams.startScreenData,
      learningTarget: [
        learningTargets[TEST_LEARNING_TARGET_TYPES.MULTI_CHOICE],
      ],
    },
    populations: [
      populationsReadingMC[TEST_POPULATION_TYPES.TEST_R_MC_SHORT],
      populationsReadingMC[TEST_POPULATION_TYPES.TEST_R_MC_MEDIUM],
      populationsReadingMC[TEST_POPULATION_TYPES.TEST_R_MC_LONG],
      populationsReadingMC[TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_SHORT],
      populationsReadingMC[TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_MEDIUM],
      populationsReadingMC[TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_LONG],
      populationsReadingMC[
        TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_SHORT_WITH_TITLE
      ],
      populationsReadingMC[
        TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_MEDIUM_WITH_TITLE
      ],
      populationsReadingMC[
        TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_LONG_WITH_TITLE
      ],
      populationsReadingMC[TEST_POPULATION_TYPES.TEST_R_MC_TITLE_SHORT],
      populationsReadingMC[TEST_POPULATION_TYPES.TEST_R_MC_TITLE_MEDIUM],
      populationsReadingMC[TEST_POPULATION_TYPES.TEST_R_MC_TITLE_LONG],
      populationsReadingMC[TEST_POPULATION_TYPES.TEST_R_MC_TRUE_FALSE],
      populationsReadingMC[TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_TRUE_FALSE],
      populationsReadingMCI[TEST_POPULATION_TYPES.TEST_R_MCI_SHORT],
      populationsReadingMCI[TEST_POPULATION_TYPES.TEST_R_MCI_MEDIUM],
      populationsReadingMCI[TEST_POPULATION_TYPES.TEST_R_MCI_LONG],
      populationsReadingMCI[TEST_POPULATION_TYPES.TEST_R_MCI_PASSAGE_SHORT],
      populationsReadingMCI[TEST_POPULATION_TYPES.TEST_R_MCI_PASSAGE_MEDIUM],
      populationsReadingMCI[TEST_POPULATION_TYPES.TEST_R_MCI_PASSAGE_LONG],
    ],
    nextUp: {
      nextActivityId: 'TEST.R.MS',
      nextActivityThumbnail: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
      nextActivityName: 'Episode 2',
    },
    activityManifest: [
      ...constReadingParams.activityManifest,
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MC_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MC_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MC_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_SHORT_WITH_TITLE}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_MEDIUM_WITH_TITLE}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_LONG_WITH_TITLE}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MC_TITLE_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MC_TITLE_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MC_TITLE_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MC_TRUE_FALSE}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_TRUE_FALSE}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MCI_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MCI_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MCI_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MCI_PASSAGE_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MCI_PASSAGE_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MCI_PASSAGE_LONG}`,
      },
      ...constActivityManifestEnd,
    ],
  },
  'TEST.R.MS': {
    id: `TEST.R.MS`,
    ...constReadingParams,
    seriesName: ``,
    episodeNumber: '2',
    episodeTitle: 'Multiple Select',
    description:
      'MS_SHORT, MS_MEDIUM, MS_LONG, MS_PASSAGE_SHORT, MS_PASSAGE_MEDIUM, MS_PASSAGE_LONG, MSI_SHORT, MSI_MEDIUM, MSI_LONG, MSI_PASSAGE_SHORT, MSI_PASSAGE_MEDIUM, MSI_PASSAGE_LONG, episode has no series name',
    startScreenData: {
      ...constReadingParams.startScreenData,
      learningTarget: [
        learningTargets[TEST_LEARNING_TARGET_TYPES.MULTI_SELECT],
      ],
    },
    populations: [
      populationsReadingMS[TEST_POPULATION_TYPES.TEST_R_MS_SHORT],
      populationsReadingMS[TEST_POPULATION_TYPES.TEST_R_MS_MEDIUM],
      populationsReadingMS[TEST_POPULATION_TYPES.TEST_R_MS_LONG],
      populationsReadingMS[TEST_POPULATION_TYPES.TEST_R_MS_PASSAGE_SHORT],
      populationsReadingMS[TEST_POPULATION_TYPES.TEST_R_MS_PASSAGE_MEDIUM],
      populationsReadingMS[TEST_POPULATION_TYPES.TEST_R_MS_PASSAGE_LONG],
      populationsReadingMSI[TEST_POPULATION_TYPES.TEST_R_MSI_SHORT],
      populationsReadingMSI[TEST_POPULATION_TYPES.TEST_R_MSI_MEDIUM],
      populationsReadingMSI[TEST_POPULATION_TYPES.TEST_R_MSI_LONG],
      populationsReadingMSI[TEST_POPULATION_TYPES.TEST_R_MSI_PASSAGE_SHORT],
      populationsReadingMSI[TEST_POPULATION_TYPES.TEST_R_MSI_PASSAGE_MEDIUM],
      populationsReadingMSI[TEST_POPULATION_TYPES.TEST_R_MSI_PASSAGE_LONG],
    ],
    nextUp: {
      nextActivityId: 'TEST.R.ICTE',
      nextActivityThumbnail: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
      nextActivityName: 'Episode 3',
    },
    activityManifest: [
      ...constReadingParams.activityManifest,
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MS_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MS_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MS_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MS_PASSAGE_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MS_PASSAGE_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MS_PASSAGE_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MSI_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MSI_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MSI_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MSI_PASSAGE_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MSI_PASSAGE_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MSI_PASSAGE_LONG}`,
      },
      ...constActivityManifestEnd,
    ],
  },
  'TEST.R.ICTE': {
    id: `TEST.R.ICTE`,
    ...constReadingParams,
    seriesName: `Testing Inline Choice & Text Entry Series`,
    episodeNumber: '3',
    episodeTitle: 'Inline Choice & Text Entry',
    description:
      'IC_SHORT, IC_MEDIUM, IC_LONG, TE_SHORT, TE_MEDIUM, TE_LONG, NO CHARACTER LEANOUT',
    startScreenData: {
      ...constReadingParams.startScreenData,
      learningTarget: [
        learningTargets[TEST_LEARNING_TARGET_TYPES.INLINE_CHOICE],
        learningTargets[TEST_LEARNING_TARGET_TYPES.TEXT_ENTRY],
      ],
    },
    characterLeanout: undefined,
    nextUp: {
      nextActivityId: 'TEST.R.SCR',
      nextActivityThumbnail: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
      nextActivityName: 'Episode 4',
    },
    populations: [
      populationsReadingIC[TEST_POPULATION_TYPES.TEST_R_IC_SHORT],
      populationsReadingIC[TEST_POPULATION_TYPES.TEST_R_IC_MEDIUM],
      populationsReadingIC[TEST_POPULATION_TYPES.TEST_R_IC_LONG],
      populationsReadingTE[TEST_POPULATION_TYPES.TEST_R_TE_SHORT],
      populationsReadingTE[TEST_POPULATION_TYPES.TEST_R_TE_MEDIUM],
      populationsReadingTE[TEST_POPULATION_TYPES.TEST_R_TE_LONG],
    ],
    activityManifest: [
      ...constReadingParams.activityManifest,
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_IC_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_IC_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_IC_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_TE_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_TE_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_TE_LONG}`,
      },
      ...constActivityManifestEnd,
    ],
  },
  'TEST.R.SCR': {
    id: `TEST.R.SCR`,
    ...constReadingParams,
    seriesName: `Testing Short Constructed Response with and without Rubric Series`,
    episodeNumber: '4',
    episodeTitle: 'Short Constructed Response',
    description:
      'SCR_SHORT, SCR_MEDIUM, SCR_LONG, SCR_PASSAGE_SHORT, SCR_PASSAGE_MEDIUM, SCR_PASSAGE_LONG, SCRR_SHORT, SCRR_MEDIUM, SCRR_LONG, SCRR_PASSAGE_SHORT, SCRR_PASSAGE_MEDIUM, SCRR_PASSAGE_LONG',
    startScreenData: {
      ...constReadingParams.startScreenData,
      learningTarget: [
        learningTargets[TEST_LEARNING_TARGET_TYPES.SHORT_CONSTRUCTED_RESPONSE],
        learningTargets[
          TEST_LEARNING_TARGET_TYPES.SHORT_CONSTRUCTED_RESPONSE_WITH_RUBRIC
        ],
      ],
    },
    characterLeanout: images[TEST_IMAGE_TYPES.CHARACTER_LEANOUT],
    nextUp: {
      nextActivityId: 'TEST.R.MIX.1',
      nextActivityThumbnail: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
      nextActivityName: 'Episode 5',
    },
    populations: [
      populationsReadingSCR[TEST_POPULATION_TYPES.TEST_R_SCR_SHORT],
      populationsReadingSCR[TEST_POPULATION_TYPES.TEST_R_SCR_MEDIUM],
      populationsReadingSCR[TEST_POPULATION_TYPES.TEST_R_SCR_LONG],
      populationsReadingSCR[TEST_POPULATION_TYPES.TEST_R_SCR_PASSAGE_SHORT],
      populationsReadingSCR[TEST_POPULATION_TYPES.TEST_R_SCR_PASSAGE_MEDIUM],
      populationsReadingSCR[TEST_POPULATION_TYPES.TEST_R_SCR_PASSAGE_LONG],
      populationsReadingSCR[TEST_POPULATION_TYPES.TEST_R_SCRR_SHORT],
      populationsReadingSCR[TEST_POPULATION_TYPES.TEST_R_SCRR_MEDIUM],
      populationsReadingSCR[TEST_POPULATION_TYPES.TEST_R_SCRR_LONG],
      populationsReadingSCR[TEST_POPULATION_TYPES.TEST_R_SCRR_PASSAGE_SHORT],
      populationsReadingSCR[TEST_POPULATION_TYPES.TEST_R_SCRR_PASSAGE_MEDIUM],
      populationsReadingSCR[TEST_POPULATION_TYPES.TEST_R_SCRR_PASSAGE_LONG],
    ],
    activityManifest: [
      ...constReadingParams.activityManifest,
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_SCR_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_SCR_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_SCR_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_SCR_PASSAGE_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_SCR_PASSAGE_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_SCR_PASSAGE_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_SCRR_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_SCRR_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_SCRR_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_SCRR_PASSAGE_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_SCRR_PASSAGE_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_SCRR_PASSAGE_LONG}`,
      },
      ...constActivityManifestEnd,
    ],
  },
  'TEST.R.MIX.1': {
    id: `TEST.R.MIX.1`,
    ...constReadingParams,
    seriesName: `Testing Mix 1 Series`,
    episodeNumber: '5',
    episodeTitle: 'Mix 1 Entry',
    description:
      'MC_MEDIUM, MC_TRUE_FALSE, MCI_MEDIUM, MC_PASSAGE_MEDIUM, MS_MEDIUM, MS_PASSAGE_MEDIUM, MSI_MEDIUM, SCRR_LONG, IC_MEDIUM, TE_MEDIUM',
    startScreenData: {
      ...constReadingParams.startScreenData,
      learningTarget: [learningTargets[TEST_LEARNING_TARGET_TYPES.MIX_1]],
    },
    availableTools: [ActivityTool.VIDEO],
    populations: [
      populationsReadingMC[TEST_POPULATION_TYPES.TEST_R_MC_MEDIUM],
      populationsReadingMC[TEST_POPULATION_TYPES.TEST_R_MC_TRUE_FALSE],
      populationsReadingMCI[TEST_POPULATION_TYPES.TEST_R_MCI_MEDIUM],
      populationsReadingMC[TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_MEDIUM],
      populationsReadingMS[TEST_POPULATION_TYPES.TEST_R_MS_MEDIUM],
      populationsReadingMS[TEST_POPULATION_TYPES.TEST_R_MS_PASSAGE_MEDIUM],
      populationsReadingMSI[TEST_POPULATION_TYPES.TEST_R_MSI_MEDIUM],
      populationsReadingSCR[TEST_POPULATION_TYPES.TEST_R_SCRR_LONG],
      populationsReadingIC[TEST_POPULATION_TYPES.TEST_R_IC_MEDIUM],
      populationsReadingTE[TEST_POPULATION_TYPES.TEST_R_TE_MEDIUM],
    ],
    nextUp: {
      nextActivityId: 'TEST.R.ECR',
      nextActivityThumbnail: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
      nextActivityName: 'Episode 6',
    },
    activityManifest: [
      ...constReadingParams.activityManifest,
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MC_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MC_TRUE_FALSE}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MCI_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MS_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MS_PASSAGE_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MSI_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_SCRR_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_IC_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_TE_MEDIUM}`,
      },
      ...constActivityManifestEnd,
    ],
  },
  'TEST.R.ECR': {
    id: `TEST.R.ECR`,
    ...constReadingParams,
    seriesName: `Testing Extended Constructed Response with and without Rubric Series`,
    episodeNumber: '6',
    episodeTitle: 'Extended Constructed Response',
    description:
      'ECR_SHORT, ECR_MEDIUM, ECR_LONG, ECR_PASSAGE_SHORT, ECR_PASSAGE_MEDIUM, ECR_PASSAGE_LONG, ECRR_SHORT, ECRR_MEDIUM, ECRR_LONG, ECRR_PASSAGE_SHORT, ECRR_PASSAGE_MEDIUM, ECRR_PASSAGE_LONG',
    startScreenData: {
      ...constReadingParams.startScreenData,
      learningTarget: [
        learningTargets[
          TEST_LEARNING_TARGET_TYPES.EXTENDED_CONSTRUCTED_RESPONSE
        ],
      ],
    },
    nextUp: {
      nextActivityId: 'TEST.R.DND',
      nextActivityThumbnail: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
      nextActivityName: 'Episode 7',
    },
    populations: [
      populationsReadingECR[TEST_POPULATION_TYPES.TEST_R_ECR_SHORT],
      populationsReadingECR[TEST_POPULATION_TYPES.TEST_R_ECR_MEDIUM],
      populationsReadingECR[TEST_POPULATION_TYPES.TEST_R_ECR_LONG],
      populationsReadingECR[TEST_POPULATION_TYPES.TEST_R_ECR_PASSAGE_SHORT],
      populationsReadingECR[TEST_POPULATION_TYPES.TEST_R_ECR_PASSAGE_MEDIUM],
      populationsReadingECR[TEST_POPULATION_TYPES.TEST_R_ECR_PASSAGE_LONG],
      populationsReadingECR[TEST_POPULATION_TYPES.TEST_R_ECRR_SHORT],
      populationsReadingECR[TEST_POPULATION_TYPES.TEST_R_ECRR_MEDIUM],
      populationsReadingECR[TEST_POPULATION_TYPES.TEST_R_ECRR_LONG],
      populationsReadingECR[TEST_POPULATION_TYPES.TEST_R_ECRR_PASSAGE_SHORT],
      populationsReadingECR[TEST_POPULATION_TYPES.TEST_R_ECRR_PASSAGE_MEDIUM],
      populationsReadingECR[TEST_POPULATION_TYPES.TEST_R_ECRR_PASSAGE_LONG],
    ],
    activityManifest: [
      ...constReadingParams.activityManifest,
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_ECR_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_ECR_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_ECR_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_ECR_PASSAGE_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_ECR_PASSAGE_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_ECR_PASSAGE_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_ECRR_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_ECRR_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_ECRR_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_ECRR_PASSAGE_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_ECRR_PASSAGE_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_ECRR_PASSAGE_LONG}`,
      },
      ...constActivityManifestEnd,
    ],
  },
  'TEST.R.DND': {
    id: `TEST.R.DND`,
    ...constReadingParams,
    seriesName: `Testing Drag and Drop Series`,
    episodeNumber: '7',
    episodeTitle: 'Drag and Drop',
    description:
      'DND_BLANK_SHORT, DND_BLANK_LONG, DND_SEQ_STATIC_HORZ_SHORT, DND_SEQ_STATIC_HORZ_LONG, DND_SEQ_STATIC_VERT_SHORT, DND_SEQ_STATIC_VERT_LONG, DND_SORT_BUCKETS_SHORT, DND_SORT_BUCKETS_LONG',
    startScreenData: {
      ...constReadingParams.startScreenData,
      learningTarget: [
        learningTargets[TEST_LEARNING_TARGET_TYPES.DRAG_AND_DROP],
      ],
    },
    populations: [
      populationsReadingDND[TEST_POPULATION_TYPES.TEST_R_DND_BLANK_SHORT],
      populationsReadingDND[TEST_POPULATION_TYPES.TEST_R_DND_BLANK_LONG],
      populationsReadingDND[
        TEST_POPULATION_TYPES.TEST_R_DND_SEQ_STATIC_HORZ_SHORT
      ],
      populationsReadingDND[
        TEST_POPULATION_TYPES.TEST_R_DND_SEQ_STATIC_HORZ_LONG
      ],
      populationsReadingDND[
        TEST_POPULATION_TYPES.TEST_R_DND_SEQ_STATIC_VERT_SHORT
      ],
      populationsReadingDND[
        TEST_POPULATION_TYPES.TEST_R_DND_SEQ_STATIC_VERT_LONG
      ],
      populationsReadingDND[
        TEST_POPULATION_TYPES.TEST_R_DND_SORT_BUCKETS_SHORT
      ],
      populationsReadingDND[TEST_POPULATION_TYPES.TEST_R_DND_SORT_BUCKETS_LONG],
    ],

    activityManifest: [
      ...constReadingParams.activityManifest,
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_DND_BLANK_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_DND_BLANK_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_DND_SEQ_STATIC_HORZ_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_DND_SEQ_STATIC_HORZ_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_DND_SEQ_STATIC_VERT_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_DND_SEQ_STATIC_VERT_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_DND_SORT_BUCKETS_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_DND_SORT_BUCKETS_LONG}`,
      },
      {
        type: ActivityObjectTypes.RESULTS_SCREEN,
      },
    ],
  },
  'TEST.R.MIX.MRC': {
    id: `TEST.R.MIX.MRC`,
    ...constReadingParams,
    seriesName: `Testing Everything`,
    episodeNumber: '1',
    episodeTitle: 'Testing Everything with 3 try global override',
    description:
      'Global try override: 3 MC_MEDIUM TEST_R_MC_TRUE_FALSE TEST_R_MCI_MEDIUM TEST_R_MS_MEDIUM TEST_R_MSI_MEDIUM TEST_R_IC_MEDIUM TEST_R_TE_MEDIUM TEST_R_SCR_MEDIUM TEST_R_SCRR_MEDIUM TEST_R_ECR_MEDIUM TEST_R_ECRR_MEDIUM TEST_R_DND_BLANK_SHORT TEST_R_DND_SORT_BUCKETS_SHORT',
    startScreenData: {
      ...constReadingParams.startScreenData,
      learningTarget: [
        learningTargets[TEST_LEARNING_TARGET_TYPES.MULTIPLE_TRIES],
      ],
    },
    globalTryOverride: 3,
    populations: [
      populationsReadingMC[TEST_POPULATION_TYPES.TEST_R_MC_MEDIUM],
      populationsReadingMC[TEST_POPULATION_TYPES.TEST_R_MC_TRUE_FALSE],
      populationsReadingMCI[TEST_POPULATION_TYPES.TEST_R_MCI_MEDIUM],
      populationsReadingMS[TEST_POPULATION_TYPES.TEST_R_MS_MEDIUM],
      populationsReadingMSI[TEST_POPULATION_TYPES.TEST_R_MSI_MEDIUM],
      populationsReadingIC[TEST_POPULATION_TYPES.TEST_R_IC_MEDIUM],
      populationsReadingTE[TEST_POPULATION_TYPES.TEST_R_TE_MEDIUM],
      populationsReadingSCR[TEST_POPULATION_TYPES.TEST_R_SCR_MEDIUM],
      populationsReadingSCR[TEST_POPULATION_TYPES.TEST_R_SCRR_MEDIUM],
      populationsReadingECR[TEST_POPULATION_TYPES.TEST_R_ECR_MEDIUM],
      populationsReadingECR[TEST_POPULATION_TYPES.TEST_R_ECRR_MEDIUM],
      populationsReadingDND[TEST_POPULATION_TYPES.TEST_R_DND_BLANK_SHORT],
      populationsReadingDND[
        TEST_POPULATION_TYPES.TEST_R_DND_SORT_BUCKETS_SHORT
      ],
    ],
    nextUp: {
      nextActivityId: 'TEST.R.MS',
      nextActivityThumbnail: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
      nextActivityName: 'Episode 2',
    },
    activityManifest: [
      ...constReadingParams.activityManifest,
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MC_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MC_TRUE_FALSE}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MCI_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MS_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MSI_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_IC_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_TE_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_SCR_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_SCRR_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_ECR_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_ECRR_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_DND_BLANK_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_DND_SORT_BUCKETS_SHORT}`,
      },
      ...constActivityManifestEnd,
    ],
  },
  'TEST.R.MIX.MRD': {
    id: `TEST.R.MIX.MRD`,
    ...constReadingParams,
    seriesName: `Testing Everything`,
    episodeNumber: '1',
    episodeTitle: 'Testing Everything with random try overrides',
    description:
      'TEST_R_MC_MEDIUM - 1 try TEST_R_MCI_MEDIUM - 2 tries TEST_R_MS_MEDIUM - 3 tries TEST_R_MSI_MEDIUM - 1 try TEST_R_TE_MEDIUM - 2 tries TEST_R_IC_MEDIUM - 3 tries TEST_R_DND_BLANK_SHORT - 1 try TEST_R_DND_SORT_BUCKETS_SHORT - 4 tries',
    startScreenData: {
      ...constReadingParams.startScreenData,
      learningTarget: [
        learningTargets[TEST_LEARNING_TARGET_TYPES.MULTIPLE_TRIES],
      ],
    },
    populations: [
      populationsOverridenTries[TEST_POPULATION_TYPES.TEST_R_MC_MEDIUM_1_TRY],
      populationsOverridenTries[TEST_POPULATION_TYPES.TEST_R_MCI_MEDIUM_2_TRY],
      populationsOverridenTries[TEST_POPULATION_TYPES.TEST_R_MS_MEDIUM_3_TRY],
      populationsOverridenTries[TEST_POPULATION_TYPES.TEST_R_MSI_MEDIUM_1_TRY],
      populationsOverridenTries[TEST_POPULATION_TYPES.TEST_R_TE_MEDIUM_2_TRY],
      populationsOverridenTries[TEST_POPULATION_TYPES.TEST_R_IC_MEDIUM_3_TRY],
      populationsOverridenTries[
        TEST_POPULATION_TYPES.TEST_R_DND_BLANK_SHORT_1_TRY
      ],
      populationsOverridenTries[
        TEST_POPULATION_TYPES.TEST_R_DND_SORT_BUCKETS_SHORT_4_TRY
      ],
    ],
    nextUp: {
      nextActivityId: 'TEST.R.MS',
      nextActivityThumbnail: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
      nextActivityName: 'Episode 2',
    },
    activityManifest: [
      ...constReadingParams.activityManifest,
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MC_MEDIUM_1_TRY}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MCI_MEDIUM_2_TRY}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MS_MEDIUM_3_TRY}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MSI_MEDIUM_1_TRY}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_TE_MEDIUM_2_TRY}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_IC_MEDIUM_3_TRY}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_DND_BLANK_SHORT_1_TRY}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_DND_SORT_BUCKETS_SHORT_4_TRY}`,
      },
      ...constActivityManifestEnd,
    ],
  },
  'TEST.R.CS': {
    id: `TEST.R.CS`,
    ...constReadingParams,
    seriesName: `Clickstream Testing Series`,
    episodeNumber: '1',
    episodeTitle: 'Clickstream Testing',
    description:
      'MC_SHORT, MCI_SHORT, MC_TRUE_FALSE, MS_SHORT, MSI_SHORT, IC_SHORT, TE_SHORT, SCR_SHORT, SCRR_SHORT, ECR_SHORT, ECRR_SHORT, DND_BLANK_SHORT, DND_SORT_BUCKETS_SHORT',
    startScreenData: {
      ...constReadingParams.startScreenData,
      learningTarget: [
        learningTargets[TEST_LEARNING_TARGET_TYPES.DRAG_AND_DROP],
      ],
    },
    populations: [
      populationsReadingMC[TEST_POPULATION_TYPES.TEST_R_MC_SHORT],
      populationsReadingMCI[TEST_POPULATION_TYPES.TEST_R_MCI_SHORT],
      populationsReadingMC[TEST_POPULATION_TYPES.TEST_R_MC_TRUE_FALSE],
      populationsReadingMS[TEST_POPULATION_TYPES.TEST_R_MS_SHORT],
      populationsReadingMSI[TEST_POPULATION_TYPES.TEST_R_MSI_SHORT],
      populationsReadingIC[TEST_POPULATION_TYPES.TEST_R_IC_SHORT],
      populationsReadingTE[TEST_POPULATION_TYPES.TEST_R_TE_SHORT],
      populationsReadingSCR[TEST_POPULATION_TYPES.TEST_R_SCR_SHORT],
      populationsReadingSCR[TEST_POPULATION_TYPES.TEST_R_SCRR_SHORT],
      populationsReadingECR[TEST_POPULATION_TYPES.TEST_R_ECR_SHORT],
      populationsReadingECR[TEST_POPULATION_TYPES.TEST_R_ECRR_SHORT],
      populationsReadingDND[TEST_POPULATION_TYPES.TEST_R_DND_BLANK_SHORT],
      populationsDragAndDropSortingBucketsImages[
        TEST_POPULATION_TYPES.TEST_R_DND_SORTING_BUCKETS_IMAGES_1
      ],
    ],
    activityManifest: [
      ...constReadingParams.activityManifest,
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MC_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MCI_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MC_TRUE_FALSE}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MS_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MSI_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_IC_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_TE_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_SCR_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_SCRR_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_ECR_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_ECRR_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_DND_BLANK_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_DND_SORTING_BUCKETS_IMAGES_1}`,
      },
      ...constActivityManifestEnd,
    ],
  },
  'TEST.R.KATEX-MIX.1': {
    id: `TEST.R.KATEX-MIX.1`,
    ...constReadingParams,
    seriesName: `Testing KATEX Mix 1 Series`,
    episodeNumber: '11',
    episodeTitle: 'KATEX Mix 1 Entry',
    description:
      'MC_PASSAGE_MEDIUM, MS_MEDIUM, IC_MEDIUM, DND_BLANK_SHORT, DND_SEQ_STATIC_HORZ_SHORT, DND_SEQ_STATIC_VERT_SHORT, DND_SORT_BUCKETS_SHORT, MCI_SHORT, MSI_SHORT, TE_SHORT, MC_TRUE_FALSE, DND_SORTING_BUCKETS_IMAGES_1, SCR_SHORT, ECR_SHORT',
    startScreenData: {
      ...constReadingParams.startScreenData,
      learningTarget: [
        {
          ...learningTargets[TEST_LEARNING_TARGET_TYPES.MIX_1],
          learningTarget: {
            text: `to test fully the mix variants (start of math: ${KATEX_START_DELIM}\\frac{1}{3} \\tfrac{1}{3} {1 \\over 3} \\dfrac{1}{3} \\$2.99 \\\\ 30\\degree \\div 10 \\times 6 \\lparen87.3\\rparen${KATEX_END_DELIM} :end of math) with katex`,
          },
          successCriteria: [
            {
              id: TestAssociatedSuccessCriteria.MULTI_CHOICE,
              text: `success criteria with a fraction: ${KATEX_START_DELIM}\\begin{alignat}{2}10&x+&3&y=2\\\\3&x+&13&y=4\\end{alignat}${KATEX_END_DELIM}`,
            },
          ],
        },
      ],
    },
    availableTools: [ActivityTool.VIDEO],
    populations: [
      {
        ...populationsReadingMC[TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_MEDIUM],
        hint: [
          {
            id: '99',
            type: SlideTypeEnum.basicSlide,
            data: {
              title: `${KATEX_START_DELIM}\\frac{2}{3}${KATEX_END_DELIM}\n${KATEX_START_DELIM}\\frac{45}{168}${KATEX_END_DELIM}`,
            },
          },
        ],
        passage: {
          text: `${KATEX_START_DELIM}\frac{a}{b}${KATEX_END_DELIM}Duis ullamco proident consequat deserunt nostrud.${KATEX_START_DELIM}\frac{d}{c}${KATEX_END_DELIM}\n\n\n\n\n\n\n<i><b>Look a new line appears!</b></i>`,
        },
        populationData: multiChoiceQuestions[TEST_CHOICE_TYPES.TEST_R_MC_KATEX],
      },
      {
        ...populationsReadingMS[TEST_POPULATION_TYPES.TEST_R_MS_MEDIUM],
        populationData: multiSelectQuestions[TEST_CHOICE_TYPES.TEST_R_MS_KATEX],
      },
      {
        ...populationsReadingIC[TEST_POPULATION_TYPES.TEST_R_IC_MEDIUM],
        populationData:
          inlineChoiceQuestions[TEST_CHOICE_TYPES.TEST_R_IC_KATEX],
      },
      {
        ...populationsReadingDND[TEST_POPULATION_TYPES.TEST_R_DND_BLANK_SHORT],
        populationData:
          fillInTheBlankTileText[TEST_CHOICE_TYPES.TEST_R_DND_BLANK_KATEX],
      },
      {
        ...populationsReadingDND[
          TEST_POPULATION_TYPES.TEST_R_DND_SEQ_STATIC_HORZ_SHORT
        ],
        populationData:
          staticHorizontalSequencing[
            TEST_CHOICE_TYPES.TEST_R_DND_SEQ_STATIC_HORZ_KATEX
          ],
      },
      {
        ...populationsReadingDND[
          TEST_POPULATION_TYPES.TEST_R_DND_SEQ_STATIC_VERT_SHORT
        ],
        populationData:
          staticVerticalSequencing[
            TEST_CHOICE_TYPES.TEST_R_DND_SEQ_STATIC_VERT_KATEX
          ],
      },
      {
        ...populationsReadingDND[
          TEST_POPULATION_TYPES.TEST_R_DND_SORT_BUCKETS_SHORT
        ],
        populationData:
          sortingBuckets[TEST_CHOICE_TYPES.TEST_R_DND_SORT_BUCKETS_KATEX],
      },
      // Just question text testing for katex
      {
        ...populationsReadingMCI[TEST_POPULATION_TYPES.TEST_R_MCI_SHORT],
        populationData: {
          ...multiChoiceImageQuestions[TEST_CHOICE_TYPES.TEST_R_MCI_SHORT],
          question: {
            text: `A cool question with a fraction: ${KATEX_START_DELIM}\frac{a}{b}${KATEX_END_DELIM}.`,
          },
        },
      },
      {
        ...populationsReadingMSI[TEST_POPULATION_TYPES.TEST_R_MSI_SHORT],
        populationData: {
          ...multiSelectImageQuestions[TEST_CHOICE_TYPES.TEST_R_MSI_SHORT],
          question: {
            text: `A cool question with a fraction: ${KATEX_START_DELIM}\frac{a}{b}${KATEX_END_DELIM}.`,
          },
        },
      },
      {
        ...populationsReadingTE[TEST_POPULATION_TYPES.TEST_R_TE_SHORT],
        populationData: {
          ...textEntryQuestions[TEST_CHOICE_TYPES.TEST_R_TE_SHORT],
          text: [
            {
              text: `${KATEX_START_DELIM}\\begin{aligned}124.578 \\\\ \\underbar{+ 12.320}\\end{aligned}${KATEX_END_DELIM}{WORD_TOKEN}.`,
            },
          ],
        },
      },
      {
        ...populationsReadingMC[TEST_POPULATION_TYPES.TEST_R_MC_TRUE_FALSE],
        populationData: {
          ...multiChoiceQuestions[TEST_CHOICE_TYPES.TEST_R_MC_TRUE_FALSE],
          question: {
            text: `A cool question with a fraction: ${KATEX_START_DELIM}\frac{a}{b}${KATEX_END_DELIM}.`,
          },
        },
      },
      {
        ...populationsDragAndDropSortingBucketsImages[
          TEST_POPULATION_TYPES.TEST_R_DND_SORTING_BUCKETS_IMAGES_1
        ],
        populationData: {
          ...sortingBucketsImages['test1'],
          question: {
            text: `A cool question with a fraction: ${KATEX_START_DELIM}\frac{a}{b}${KATEX_END_DELIM}.`,
          },
          labels: [
            {
              text: `Fraction: ${KATEX_START_DELIM}\frac{a}{b}${KATEX_END_DELIM}`,
            },
            sortingBucketsImages['test1'].labels[1],
          ],
        },
      },
      {
        ...populationsReadingSCR[TEST_POPULATION_TYPES.TEST_R_SCR_SHORT],
        populationData: {
          ...constructedResponseQuestions[TEST_CHOICE_TYPES.TEST_R_SCR_SHORT],
          text: {
            text: `A cool question with a fraction: ${KATEX_START_DELIM}\frac{a}{b}${KATEX_END_DELIM}.`,
          },
        },
      },
      {
        ...populationsReadingECR[TEST_POPULATION_TYPES.TEST_R_ECR_SHORT],
        populationData: {
          ...extendedConstructedResponseQuestions[
            TEST_CHOICE_TYPES.TEST_R_ECR_SHORT
          ],
          text: {
            text: `A cool question with a fraction: ${KATEX_START_DELIM}\frac{a}{b}${KATEX_END_DELIM}.`,
          },
        },
      },
    ],
    activityManifest: [
      ...constReadingParams.activityManifest,
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MC_PASSAGE_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MS_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_IC_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_DND_BLANK_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_DND_SEQ_STATIC_HORZ_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_DND_SEQ_STATIC_VERT_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_DND_SORT_BUCKETS_SHORT}`,
      },
      // Just question text testing for katex
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MCI_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MSI_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_TE_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MC_TRUE_FALSE}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_DND_SORTING_BUCKETS_IMAGES_1}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_SCR_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_ECR_SHORT}`,
      },
      ...constActivityManifestEnd,
    ],
  },
};
