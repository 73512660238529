<div class="flex-container flex-col flex-col">
  <div
    (keydown)="keydownPress($event)"
    class="active carousel with-slide-nav"
    data-testid="carousel">
    <ul #slides class="slides" data-testid="slides">
      <li
        class="slide"
        [ngClass]="{
          current: currentSlide === i,
          next: i === currentSlide + 1,
          prev: i === currentSlide - 1
        }"
        aria-hidden="true"
        tabindex="-1"
        *ngFor="let slide of content; let i = index">
        <ng-template [htcViewContainerDirective]></ng-template>
      </li>
    </ul>
    <div
      aria-live="polite"
      aria-atomic="true"
      class="liveregion visually-hidden"
      data-testid="liveregion">
      {{ 'CAROUSEL.ITEM' | translate }} {{ currentSlide + 1 }}
      {{ 'CAROUSEL.OF' | translate }} {{ content.length }}
    </div>
  </div>
  <ul
    class="controls"
    *ngIf="!hasOneSlide"
    data-testid="controls"
    (keydown)="keydownPress($event)">
    <li>
      <htc-button-with-icon
        class="btn btn-with-left-icon btn-carousel-prev"
        [id]="btnPrevId"
        [btnText]="'CAROUSEL.PREVIOUS' | translate"
        imagePath="/assets/button-icons/chevron.svg"
        [enable]="isPreviousEnabled()"
        (btnClicked)="toPreviousSlide()"
        data-testid="htc-carousel-btn-prev"
        htcAudioClick
        [audioType]="clickType"
        [descriptor]="clickDescriptor"></htc-button-with-icon>
    </li>
    <li>
      <ul class="slide-nav flex-container flex-center" data-testid="slide-nav">
        <li
          *ngFor="let slide of content; let i = index"
          [ngClass]="{
            current: currentSlide === i,
            'taper-hidden': contentLength > 3 && currentSlide !== i,
            taper:
              contentLength > 3 &&
              (currentSlide - i === 1 || currentSlide - i === -1),
            'taper-small':
              contentLength > 3 &&
              ((currentSlide === 0 && currentSlide - i === -2) ||
                (currentSlide === contentLength - 1 && currentSlide - i === 2))
          }">
          <span class="visually-hidden"
            >{{ 'CAROUSEL.SLIDE' | translate }} {{ i + 1 }}</span
          >
          <span class="visually-hidden" *ngIf="currentSlide === i"
            >({{ 'CAROUSEL.CURRENT_ITEM' | translate }})</span
          >
        </li>
      </ul>
    </li>
    <li>
      <htc-button-with-icon
        class="btn btn-with-right-icon btn-carousel-next"
        [id]="btnNextId"
        [btnText]="'CAROUSEL.NEXT' | translate"
        imagePath="/assets/button-icons/chevron.svg"
        [enable]="isNextEnabled()"
        (btnClicked)="toNextSlide()"
        data-testid="htc-carousel-btn-next"
        htcAudioClick
        [audioType]="clickType"
        [descriptor]="clickDescriptor"></htc-button-with-icon>
    </li>
  </ul>
</div>
