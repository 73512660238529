import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityPageComponent } from './activity-page.component';
import { ActivityRoutingModule } from './activity-routing.module';

@NgModule({
  declarations: [ActivityPageComponent],
  imports: [CommonModule, ActivityRoutingModule],
})
export class ActivityModule {}
