import { Component, Input } from '@angular/core';
import { ITool } from '../../interfaces/tool.interface';

@Component({
  selector: 'htc-test-toggle',
  templateUrl: './test-toggle.component.html',
})
export class TestToggleComponent implements ITool {
  constructor() {}
  @Input() id!: number;
  @Input() toolName!: string;
  @Input() icon!: string;
  @Input() toggledIcon?: string | undefined;
  @Input() testId = '';
}
