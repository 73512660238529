<div htcTrapFocus>
  <div
    class="loading"
    [ngClass]="{
      'loading-transition-out': loadingScreenService.loadingScreenState() === 1
    }">
    <div class="loading-bg" #loadingBackground></div>
    <div class="loading-clouds">
      <svg-icon
        class="loading-clouds-cloud cloud-bg"
        src="/assets/loading-images/cloud_bg.svg"></svg-icon>
      <svg-icon
        class="loading-clouds-cloud cloud-mg"
        src="/assets/loading-images/cloud_mg.svg"></svg-icon>
      <svg-icon
        class="loading-clouds-cloud cloud-fg"
        src="/assets/loading-images/cloud_fg.svg"></svg-icon>
    </div>
    <div class="loading-group">
      <img
        src="/assets/loading-images/icon-kid-cape.png"
        width="147"
        height="164"
        [attr.aria-label]="'LOADING_SCREEN.KID_WITH_CAPE' | translate" />
      <h1 id="status">Loading</h1>
      <svg-icon
        class="loading-group-dots"
        src="/assets/loading-images/loading-circle.svg"></svg-icon>
    </div>
  </div>
</div>
