import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { VideoDefaultConfig } from 'src/app/modules/video/data';
import { VideoData } from '../../../../shared/interfaces';
import { ActivityService } from 'src/app/shared/services/activity/activity.service';
import { VideoService } from 'src/app/modules/video/services/video.service';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { TranscriptService } from 'src/app/modules/video/services/transcript.service';
import { ToolsService } from '../../services/tools.service';
import { ITool } from '../../interfaces/tool.interface';
import { ReadAloudService } from 'src/app/modules/read-aloud/services/read-aloud.service';

@Component({
  selector: 'htc-video-popup',
  templateUrl: './video-popup.component.html',
  styleUrls: ['./video-popup.component.scss'],
})
export class VideoPopupComponent
  implements OnInit, OnDestroy, AfterViewInit, ITool
{
  @Input() id!: number;
  @Input() toolName!: string;
  @Input() icon!: string;
  @Input() toggledIcon?: string | undefined;
  @Input() testId = '';

  videoData$: BehaviorSubject<VideoData | undefined> = new BehaviorSubject<
    VideoData | undefined
  >(undefined);
  videoConfig$: BehaviorSubject<VideoDefaultConfig | undefined> =
    new BehaviorSubject<VideoDefaultConfig | undefined>(undefined);
  sub = new Subscription();
  showTranscript = false;

  @ViewChild(DialogComponent, { static: true }) dialog!: DialogComponent;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private activityService: ActivityService,
    private videoService: VideoService,
    private transcriptService: TranscriptService,
    private toolsService: ToolsService,
    private readAloudService: ReadAloudService
  ) {}

  ngOnInit(): void {
    this.videoData$ = this.activityService.videoData$;
    this.videoConfig$ = this.activityService.videoConfig$;

    this.sub.add(
      this.transcriptService.showTranscript$.subscribe(show => {
        this.showTranscript = show;
      })
    );
  }

  ngAfterViewInit(): void {
    if (this.dialog) {
      this.dialog.open();
      this.readAloudService.stopCurrent();
    }

    this.changeDetectorRef.detectChanges();
  }

  onPopupChange(): void {
    if (!this.dialog.isOpen) {
      this.videoService.player?.pause();
      this.toolsService.deactivateTool(this.id);
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
