export const activityIdToUuidMap: { [key: string]: string } = {
  'Reading.3.Prepositions.1.FromPlanetstoStars':
    '018ea00f-3c27-7969-93cd-602343a6031c',
  'reading-3-prepositions-1-fromplanetstostars':
    '018ea00f-3c27-7969-93cd-602343a6031c',
  'Reading.3.Prepositions.2.CometTale': '018ea00f-3c69-7bbd-9539-5993db71d0e1',
  'reading-3-prepositions-2-comettale': '018ea00f-3c69-7bbd-9539-5993db71d0e1',
  'Reading.3.Prepositions.3.SurfsUpShipsDown':
    '018ea00f-3cba-73c4-a05e-c70a802dc69f',
  'reading-3-prepositions-3-surfsupshipsdown':
    '018ea00f-3cba-73c4-a05e-c70a802dc69f',
  'Reading.3.Prepositions.4.GlitchintheManual':
    '018ea00f-3cff-70c0-b58d-8f071c649df4',
  'reading-3-prepositions-4-glitchinthemanual':
    '018ea00f-3cff-70c0-b58d-8f071c649df4',
  'Reading.3.Prepositions.5.DiscoveryIsOurs':
    '018ea00f-3d50-7475-be50-32746f35e97d',
  'reading-3-prepositions-5-discoveryisours':
    '018ea00f-3d50-7475-be50-32746f35e97d',
  'Reading.3.Functionofnouns.1.GrammarGoggles':
    '018ea019-05bb-7425-8615-5d865e215e22',
  'reading-3-functionofnouns-1-grammargoggles':
    '018ea019-05bb-7425-8615-5d865e215e22',
  'Reading.4.Prepositions.2.CosmicClues':
    '018ea019-06f0-707a-8060-9d56b4a083fd',
  'reading-4-prepositions-2-cosmicclues':
    '018ea019-06f0-707a-8060-9d56b4a083fd',
  'Reading.5.PrepositionalPhrases.1.JungleGeodes':
    '018ea019-0748-7288-9a2a-d17a3e143768',
  'reading-5-prepositionalphrases-1-junglegeodes':
    '018ea019-0748-7288-9a2a-d17a3e143768',
  'Reading.3.PluralNouns.2.DioramaDilemma':
    '018ea019-0790-7550-a069-d7282ee56622',
  'reading-3-pluralnouns-2-dioramadilemma':
    '018ea019-0790-7550-a069-d7282ee56622',
  'Lectura.5.Locucionesofrasespreposicionales.1.Lasrocasdelajungla':
    '018ea019-027d-75a0-a911-243a6645f265',
  'lectura-5-locucionesofrasespreposicionales-1-lasrocasdelajungla':
    '018ea019-027d-75a0-a911-243a6645f265',
  'Lectura.5.Locucionesofrasespreposicionales.2.PlanetaBurger':
    '018ea019-031c-78b2-b1c2-101528e43f3d',
  'lectura-5-locucionesofrasespreposicionales-2-planetaburger':
    '018ea019-031c-78b2-b1c2-101528e43f3d',
  'Lectura.3.Preposiciones.1.Delosplanetasalasestrellas':
    '018ea019-0420-7f67-b665-fbfeb764deee',
  'lectura-3-preposiciones-1-delosplanetasalasestrellas':
    '018ea019-0420-7f67-b665-fbfeb764deee',
  'Lectura.3.Preposiciones.2.Cometaalavista':
    '018ea019-04a4-72ba-bdbf-64a85e6f9bd5',
  'lectura-3-preposiciones-2-cometaalavista':
    '018ea019-04a4-72ba-bdbf-64a85e6f9bd5',
  'Lectura.3.Preposiciones.3.Playaalavista':
    '018ea019-0507-7c4e-b17d-305cd29b0c34',
  'lectura-3-preposiciones-3-playaalavista':
    '018ea019-0507-7c4e-b17d-305cd29b0c34',
  'Lectura.3.Preposiciones.4.Herramientaespacialenacción':
    '018ea019-0560-7cf1-ba83-14a12e832b9f',
  'lectura-3-preposiciones-4-herramientaespacialenacción':
    '018ea019-0560-7cf1-ba83-14a12e832b9f',
  'Lectura.4.Preposiciones.2.Contraccionescósmicas':
    '018ea019-061e-7889-81a8-ca79b4c10235',
  'lectura-4-preposiciones-2-contraccionescósmicas':
    '018ea019-061e-7889-81a8-ca79b4c10235',
  'Lectura.4.Preposiciones.4.Intentodevuelo':
    '018ea019-067c-7e31-b84e-b5813d55334d',
  'lectura-4-preposiciones-4-intentodevuelo':
    '018ea019-067c-7e31-b84e-b5813d55334d',
};
