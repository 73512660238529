import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IProgressNodeData } from 'src/app/modules/progress-bar/interfaces/progress-node-data.interface';
import { PageProgressService } from 'src/app/shared/services';

@Component({
  selector: 'htc-subheader',
  templateUrl: './subheader.component.html',
  styleUrls: ['./subheader.component.scss'],
})
export class SubheaderComponent {
  @Input() episode = '0';
  @Input() showProgressBar = true;
  @Input() showElapsedTime = true;
  @Input() progressBarEnabled = false;
  @Output() clickedEvent = new EventEmitter<IProgressNodeData>();

  constructor(public pageProgressService: PageProgressService) {}

  onProgressNodeClick(node: IProgressNodeData): void {
    this.clickedEvent.emit(node);
  }
}
