import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FullscreenService {
  fullscreen$ = new BehaviorSubject<boolean | undefined>(undefined);

  constructor() {}

  toggleFullscreen(): boolean {
    const isFullscreen = !this.fullscreen$.getValue();
    this.fullscreen$.next(isFullscreen);
    return isFullscreen;
  }
}
