import { ActivityObjectTypes } from 'src/app/shared/enums/activity-object-types';
import { Activity } from 'src/app/shared/interfaces';
import { constActivityManifestEnd } from './activity-manifest-end';

import { TEST_POPULATION_TYPES } from '../population-types';
import { populationsReadingMC } from '../populations';
import { constMultipleVideosParams } from './multiple-videos-params';

export const testVideoActivities: { [key: string]: Activity } = {
  'TEST.R.V': {
    id: `TEST.R.V`,
    ...constMultipleVideosParams,
    seriesName: `Testing Videos`,
    episodeNumber: '1',
    episodeTitle: 'Multiple Videos',
    description: 'MC_SHORT',
    startScreenData: constMultipleVideosParams.startScreenData,
    populations: [populationsReadingMC[TEST_POPULATION_TYPES.TEST_R_MC_SHORT]],
    activityManifest: [
      ...constMultipleVideosParams.activityManifest,
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_MC_SHORT}`,
      },
      ...constActivityManifestEnd,
    ],
  },
};
