import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ITranscriptLine } from '../interfaces';
import { UtilsService, VideoService } from '.';

@Injectable({
  providedIn: 'root',
})
export class TranscriptService {
  showTranscript$ = new BehaviorSubject<boolean>(false);
  transcript: ITranscriptLine[][] = [[]];
  activeTranscript$ = new BehaviorSubject<ITranscriptLine[] | undefined>(
    undefined
  );
  private activeTranscriptIndex = 0;
  focusedTranscriptIndex$ = new BehaviorSubject<number>(0);
  maxTranscriptIndex$ = new BehaviorSubject<number>(0);
  sub = new Subscription();

  constructor(
    private videoService: VideoService,
    private utilsService: UtilsService,
    private httpClient: HttpClient
  ) {}

  set ActiveTranscriptIndex(index: number) {
    const newIndex = Math.max(0, Math.min(index, this.transcript.length - 1));
    this.activeTranscriptIndex = newIndex;
    this.activeTranscript$.next(this.transcript[this.activeTranscriptIndex]);
  }

  init(): void {
    this.sub = new Subscription();
    this.subToWatchTime();
    this.subToTimeChange();
  }

  subToWatchTime(): void {
    this.sub.add(
      this.videoService.watchTime$
        .asObservable()
        .subscribe((watchTime: number) => {
          if (this.videoService.player && this.transcript.length > 0) {
            this.calcMaxTranscriptIndex(watchTime * 1000);
          }
        })
    );
  }

  subToTimeChange(): void {
    this.focusedTranscriptIndex$.next(0);
    this.videoService.player?.on('timeupdate', () => {
      this.calcTranscriptFocus();
    });
  }

  ShowTranscript(show: boolean): void {
    this.showTranscript$.next(show);
  }

  /**
   * Toggle the transcript on/off
   * @returns boolean if transcript is shown or not
   */
  toggleTranscript(toggleClassElements?: Element[]): boolean {
    const show = !this.showTranscript$.getValue();

    if (toggleClassElements) {
      this.utilsService.changeTogglesClassList(toggleClassElements, show);
    }

    this.ShowTranscript(show);

    if (this.videoService.player) {
      if (show) {
        this.videoService.player.addClass('vjs-condensed');
      } else {
        this.videoService.player.removeClass('vjs-condensed');
      }
    }

    return show;
  }

  /**
   * Read the VTT file and create transcript from it
   * determine MS start and end times each line is for auto
   * transcript scrolling during video play
   * @param  {string} filename
   * @returns void
   */
  syncReadFile(filename: string): void {
    this.httpClient.get(filename, { responseType: 'text' }).subscribe(data => {
      const lineCount = (data.match(/-->/g) || []).length;
      const splitData = data.split('-->');

      const formatArr: ITranscriptLine[] = [];
      for (let i = 0; i < lineCount; i++) {
        const line = this.parseTranscriptLine(i, splitData);
        if (line) {
          formatArr.push(line);
        } else {
          console.error('Transcript Parse Error at Line', i);
        }
      }

      if (formatArr.length > 0) {
        this.transcript.push(formatArr);
        this.activeTranscript$.next(
          this.transcript[this.activeTranscriptIndex]
        );
      }
    });
  }

  /**
   * find transcript line at delim --> index
   * @param  {number} index of delim --> found
   * @param  {string[]} splitData data split via timestamp delim -->
   * @returns ITranscriptLine | null
   */
  parseTranscriptLine(
    index: number,
    splitData: string[]
  ): ITranscriptLine | null {
    // out of bounds bogus timestamp
    if (index + 1 >= splitData.length || index < 0) {
      return null;
    }

    const line = { startTimeMS: 0, endTimeMS: 0, texts: [] as string[] };
    const beg = splitData[index];
    const end = splitData[index + 1];

    if (beg.length > 0 && end.length > 0) {
      // get start time
      const startStr = this.findStartTime(beg);
      if (!startStr) {
        return null;
      }
      line.startTimeMS = this.convertVTTTimeToMS(startStr);

      // get end time
      const endStr = this.findEndTime(end);
      if (!endStr) {
        return null;
      }
      line.endTimeMS = this.convertVTTTimeToMS(endStr);

      // get texts
      const texts = this.findTexts(end);
      if (!texts.length) {
        return null;
      }
      line.texts = texts;

      return line;
    }

    return null;
  }

  /**
   * find start time or empty string given data already split via timestamp delim -->
   * @param  {string} data string before timestamp delim -->
   * @returns string
   */
  findStartTime(data: string): string {
    return this.findTime(data, true);
  }

  /**
   * find end time or empty string given data already split via timestamp delim -->
   * @param  {string} data string after timestamp delim -->
   * @returns string
   */
  findEndTime(data: string): string {
    return this.findTime(data, false);
  }

  /**
   * find start or end time stamp given already split by delim string --> returns
   * empty string if cannot find
   * @param  {string} data string before or after timestamp delim -->
   * @param  {boolean} isStartTime is this start time or end time
   * @returns string
   */
  findTime(data: string, isStartTime: boolean): string {
    const splitByNewLines = data.split(/\r?\n/);
    const timeLine =
      splitByNewLines[isStartTime ? splitByNewLines.length - 1 : 0];
    const timeLineArr = timeLine.trim().split(' ');

    return timeLineArr[isStartTime ? timeLineArr.length - 1 : 0];
  }

  /**
   * find texts after given time stamp and stop when finding empty line
   * @param  {string} data string after timestamp delim -->
   * @returns string[]
   */
  findTexts(data: string): string[] {
    const texts = [];
    const splitByNewLines = data.split(/\r?\n/);

    for (let i = 1; i < splitByNewLines.length; i++) {
      const line = splitByNewLines[i];
      if (line && line.trim()) {
        texts.push(line);
      } else {
        break;
      }
    }

    return texts;
  }

  /**
   * convert VTT times to actual millisecond times
   * @param  {string} vttTime
   * @returns number
   */
  convertVTTTimeToMS(vttTime: string): number {
    let msTime = 0;
    // not sure why replace all not here but whateves
    let replacedColon = vttTime.replace(':', '.');
    replacedColon = replacedColon.replace(':', '.');
    const splitTimes = replacedColon.split('.');
    const TIME = [3600000, 60000, 1000, 1];

    // we do not assume we have hours within the transcript times
    // so need to do reverse shinagons
    let startTIMEIndex = TIME.length - 1;
    for (let i = splitTimes.length - 1; i >= 0; i--) {
      const element = splitTimes[i];

      msTime += TIME[startTIMEIndex] * +element;
      startTIMEIndex -= 1;
    }

    return msTime;
  }

  /**
   * once transcript line is pressed change player current time
   * @param  {ITranscriptLine} tranLine
   * @returns void
   */
  onTranLine(tranLine: ITranscriptLine): void {
    if (this.videoService.player) {
      const newTime = this.videoService.constrainTimeToWatched(
        tranLine.startTimeMS / 1000
      );
      if (this.videoService.shouldTimeChange(newTime)) {
        this.videoService.player.currentTime(newTime);
        this.calcTranscriptFocus();
      }
    }
  }

  calcMaxTranscriptIndex(watchTimeMs: number): number {
    if (
      this.transcript.length > this.activeTranscriptIndex &&
      this.transcript[this.activeTranscriptIndex].length >
        this.maxTranscriptIndex$.getValue()
    ) {
      const currentMaxTranscript =
        this.transcript[this.activeTranscriptIndex][
          this.maxTranscriptIndex$.getValue()
        ];

      if (
        watchTimeMs < currentMaxTranscript.startTimeMS ||
        watchTimeMs >= currentMaxTranscript.endTimeMS
      ) {
        const transcriptLength =
          this.transcript[this.activeTranscriptIndex].length;
        const lastTranscriptLine =
          this.transcript[this.activeTranscriptIndex][transcriptLength - 1];

        // watch time greater than last transcript line set to last index
        // else go to each transcript to find max
        if (watchTimeMs >= lastTranscriptLine.endTimeMS) {
          this.maxTranscriptIndex$.next(transcriptLength - 1);
        } else {
          for (let i = 0; i < transcriptLength; i++) {
            const transcript = this.transcript[this.activeTranscriptIndex][i];

            if (
              watchTimeMs >= transcript.startTimeMS &&
              watchTimeMs < transcript.endTimeMS
            ) {
              this.maxTranscriptIndex$.next(i);
              break;
            }
          }
        }
      }
    }

    return this.maxTranscriptIndex$.getValue();
  }

  calcTranscriptFocus(): number {
    const currentTimeMs = (this.videoService.player?.currentTime() || 0) * 1000;

    const transcriptLength = this.transcript[this.activeTranscriptIndex].length;
    for (let i = 0; i < transcriptLength; i++) {
      const transcript = this.transcript[this.activeTranscriptIndex][i];

      if (
        currentTimeMs >= transcript.startTimeMS &&
        currentTimeMs < transcript.endTimeMS
      ) {
        this.focusedTranscriptIndex$.next(i);
        break;
      }
    }

    return this.focusedTranscriptIndex$.getValue();
  }

  cleanup(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.showTranscript$.next(false);
  }
}
