<section class="carousel-slide-basic">
  @if (data.title) {
    <p
      *htcReadAloud="
        {
          id: 'activity-title',
          text: data.title | htcMathToSpeech: (sres.lastAddedMapKey | async)
        };
        type: 'NO_PAUSE';
        theme: 'LIGHT'
      "
      data-testid="title">
      <htc-math-text-wrapper
        [attr.aria-label]="
          data.title | htcMathToSpeech: (sres.lastAddedMapKey | async)
        "
        [textInput]="data.title"></htc-math-text-wrapper>
    </p>
  }

  <div *ngIf="data.imgData">
    <img
      [hidden]="!imageLoaded"
      [src]="data.imgData.url | cdnSrcBaseUrl"
      [alt]="data.imgData.altText | altTextLang"
      (load)="onLoadComplete()"
      (error)="onLoadComplete()"
      data-testid="img" />
    <ngx-skeleton-loader
      [hidden]="imageLoaded"
      appearance="circle"
      [count]="1"
      animation="pulse"
      [theme]="{
        width: '100%',
        height: '5rem',
        'border-radius': '0.3125rem',
        margin: 0
      }"></ngx-skeleton-loader>
  </div>
</section>
