import {
  AfterViewInit,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { IProgressNodeData } from '../../interfaces/progress-node-data.interface';
import { IProgressNodeComponent } from '../../interfaces/progress-node-comp.interface';
import { ProgressService } from '../../services/progress.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IProgressBarConfig } from '../../interfaces/progress-bar-config.interface';
import {
  ProgressNodeTypeEnum,
  ProgressNodeFocusEnum,
  ProgressBarOrientationEnum,
} from '../../enums';
import { ProgressNodeComponent } from '../progress-node/progress-node.component';
import { VideoNodeComponent } from '../video-node/video-node.component';
import { DinkusNodeComponent } from '../dinkus-node/dinkus-node.component';
import { ViewContainerDirective } from '../../../../shared/directives/view-container/view-container.directive';

type progressDims = {
  progParentDim: number;
  parentPadding: number;
  progPadding: number;
  children: number;
  progParentEl: HTMLElement;
  progEl: HTMLElement;
  isHorizontal: boolean;
};

@Component({
  selector: 'htc-progress-bar',
  template: `
    <div
      #progressContainer
      class="progress-container"
      [ngClass]="[config.orientation]">
      <div class="progress-bar">
        <div></div>
        <div></div>
      </div>
      <div
        class="progress-contents flex-container flex-row flex-justify-space-between">
        <ng-template [htcViewContainerDirective]></ng-template>
      </div>
    </div>
  `,
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() nodes$: BehaviorSubject<IProgressNodeData[]> = new BehaviorSubject<
    IProgressNodeData[]
  >([]);
  @Input() config: IProgressBarConfig = ProgressService.DEFAULT_CONFIG;
  @Input() enabled = true;
  @Output() clickedEvent = new EventEmitter<IProgressNodeData>();
  @ViewChild(ViewContainerDirective, { static: true })
  progNodeHost!: ViewContainerDirective;
  @ViewChild('progressContainer', { static: true })
  progressContainer!: ElementRef;
  compRefs: ComponentRef<IProgressNodeComponent>[] = [];
  sub!: Subscription;
  nodes: IProgressNodeData[] = [];
  resizeTimeout = -1;
  readonly resizeTimer = 200;

  constructor(
    public progressService: ProgressService,
    private elem: ElementRef
  ) {}

  ngOnInit(): void {
    if (this.nodes$) {
      this.sub = this.nodes$.subscribe((updatedNodes: IProgressNodeData[]) => {
        this.nodes = updatedNodes;
        if (this.progNodeHost && this.progNodeHost.viewContainerRef) {
          if (
            this.compRefs.length === 0 ||
            this.compRefs.length !== this.nodes.length
          ) {
            this.loadComponents(this.progNodeHost.viewContainerRef);
          } else {
            this.updateComponents(this.progNodeHost.viewContainerRef);
          }
          this.updateProgress();
        } else {
          console.warn('Progress Bar view container ref is falsey');
        }
      });
    }
  }

  ngAfterViewInit(): void {
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (this.resizeTimeout !== -1) {
      window.clearTimeout(this.resizeTimeout);
      this.resizeTimeout = -1;
    }
    this.resizeTimeout = window.setTimeout(() => {
      if (this.config.autoDinkus) {
        const dim =
          this.config.orientation === ProgressBarOrientationEnum.vertical ||
          this.config.orientation === ProgressBarOrientationEnum.verticalReverse
            ? document.documentElement.clientHeight
            : document.documentElement.clientWidth;
        const autoWindowDim = this.config.autoDinkusWindowDim ?? 800;
        const autoMaxChildCount = this.config.autoDinkusMaxChildren ?? 9;
        this.adaptiveDinkus(dim, autoWindowDim, autoMaxChildCount);
      }
    }, this.resizeTimer);
  }

  adaptiveDinkus(
    dim: number,
    autoWindowDim: number,
    autoMaxChildCount: number
  ): void {
    if (dim <= autoWindowDim) {
      this.onResizeChangeChildCount(dim);
    } else if (
      dim > autoWindowDim &&
      (this.config.autoDinkusIdealChildren ?? 11) <
        this.progressService.avaNodes.length
    ) {
      this.onResizeChangeChildCountHigher();
    } else if (dim > autoWindowDim && this.config.useDinkus) {
      this.setupDinkusChildCount(false, autoMaxChildCount + 1);
    }
  }

  onResizeChangeChildCount(dim: number): void {
    const autoDinkusWindowDim = this.config.autoDinkusWindowDim ?? 0;
    const autoDinkusNodeDim = this.config.autoDinkusNodeDim ?? 0;
    const maxChildCount = this.config.autoDinkusMaxChildren ?? 9;
    const minChildCount = this.config.autoDinkusMinChildren ?? 5;
    const curChildCount = this.config.dinkusChildCount ?? 0;
    let newChildCount = curChildCount;
    let refreshShownNodes = false;

    for (let i = maxChildCount; i >= minChildCount; i--) {
      if (
        dim <=
        autoDinkusWindowDim - (maxChildCount - i) * autoDinkusNodeDim
      ) {
        refreshShownNodes = true;
        newChildCount = i;

        if (newChildCount === curChildCount) {
          refreshShownNodes = false;
        }
      }
    }

    if (refreshShownNodes) {
      this.setupDinkusChildCount(true, newChildCount);
    }
  }

  onResizeChangeChildCountHigher(): void {
    if (this.config.autoDinkusNodeDim) {
      const autoDinkusNodeDim = this.config.autoDinkusNodeDim;
      const progEl = <Element>(
        this.progressContainer.nativeElement.parentElement
      );
      const progElParent = progEl.parentElement;

      const avaDim = this.getAvaProgressDims(
        progEl as HTMLElement,
        progElParent as HTMLElement
      );

      const nodeAmount = Math.round(avaDim / autoDinkusNodeDim);

      this.setupDinkusChildCount(true, nodeAmount);
    }
  }

  getAvaProgressDims(progEl: HTMLElement, progParentEl: HTMLElement): number {
    const children = progParentEl.children.length;
    const progCompStyle = getComputedStyle(progEl.children[0] as HTMLElement);
    const compStyle = getComputedStyle(progParentEl);
    let progressDims: progressDims;

    if (
      this.config.orientation === ProgressBarOrientationEnum.vertical ||
      this.config.orientation === ProgressBarOrientationEnum.verticalReverse
    ) {
      progressDims = {
        progParentDim: progParentEl.clientHeight,
        parentPadding:
          +compStyle.paddingTop.replace('px', '') +
          +compStyle.paddingBottom.replace('px', ''),
        progPadding:
          +progCompStyle.paddingTop.replace('px', '') +
          +progCompStyle.paddingBottom.replace('px', ''),
        children,
        progParentEl,
        progEl,
        isHorizontal: false,
      };
    } else {
      progressDims = {
        progParentDim: progParentEl.clientWidth,
        parentPadding:
          +compStyle.paddingLeft.replace('px', '') +
          +compStyle.paddingRight.replace('px', ''),
        progPadding:
          +progCompStyle.paddingLeft.replace('px', '') +
          +progCompStyle.paddingRight.replace('px', ''),
        children,
        progParentEl,
        progEl,
        isHorizontal: true,
      };
    }

    return this.calcAvaProgressDims(progressDims);
  }

  calcAvaProgressDims({
    progParentDim,
    parentPadding,
    progPadding,
    children,
    progParentEl,
    progEl,
    isHorizontal,
  }: progressDims): number {
    let totalChildrenDim = 0;
    for (let i = 0; i < children; i++) {
      const child = progParentEl?.children[i];
      if (child !== progEl) {
        totalChildrenDim += isHorizontal
          ? child.clientWidth
          : child.clientHeight;
      }
    }

    return progParentDim - parentPadding - progPadding - totalChildrenDim;
  }

  setupDinkusChildCount(useDinkus: boolean, dinkusChildCount: number): void {
    this.config.useDinkus = useDinkus;
    this.config.dinkusChildCount = dinkusChildCount;
    this.progressService.Config = this.config;
    this.progressService.refreshShownNodes();
  }

  /**
   * Complete clear and load all new shown dynamic components
   * @param  {ViewContainerRef} viewContainerRef
   */
  loadComponents(viewContainerRef: ViewContainerRef): void {
    viewContainerRef.clear();
    this.compRefs = [];

    for (let i = 0; i < this.nodes.length; i++) {
      const node = this.nodes[i];
      this.createDynamicComponent(viewContainerRef, node);
    }
  }

  /**
   * Update existing shown dynamic components and only
   * replace and create a new component if progress node
   * type is different
   * @param  {ViewContainerRef} viewContainerRef
   */
  updateComponents(viewContainerRef: ViewContainerRef): void {
    for (let i = 0; i < this.nodes.length; i++) {
      const node = this.nodes[i];
      const compRef = this.compRefs[i];
      const compRefInst = compRef.instance;

      if (compRefInst.type === node.type) {
        compRefInst.data = node.data;
        compRefInst.index = node.index;
        compRefInst.focus$.next(node.focus);
        compRefInst.status$.next(node.status);
        if (this.enabled) {
          compRefInst.clickedEmitter = this.clickedEvent;
          compRefInst.disabled = this.progressService.focusIndex === node.index;
        }
      } else {
        const newRef = this.createDynamicComponent(
          viewContainerRef,
          node,
          i,
          true
        );
        this.compRefs[i] = newRef;
      }
    }
  }

  /**
   * Create a progress node dynamic component
   * add to view container ref
   * set instance data such as
   * focus, status, index, type and data
   * @param  {ViewContainerRef} viewContainerRef space for dynamic components
   *                                              to be created on
   * @param  {IProgressNodeData} progNode data to set on dynamic instance
   * @param  {number} index? where to insert comp else creates at end
   * @param  {boolean} replace? should replace the existing index with index given
   * @returns ComponentRef {ComponentRef<IProgressNodeComponent>}
   *                  dynamic component ref created
   */
  createDynamicComponent(
    viewContainerRef: ViewContainerRef,
    progNode: IProgressNodeData,
    index?: number,
    replace?: boolean
  ): ComponentRef<IProgressNodeComponent> {
    let componentRef;
    if (index !== undefined) {
      if (replace) {
        viewContainerRef.remove(index);
      }
      componentRef = viewContainerRef.createComponent<IProgressNodeComponent>(
        this.parseCompType(progNode.type),
        { index: index }
      );
    } else {
      componentRef = viewContainerRef.createComponent<IProgressNodeComponent>(
        this.parseCompType(progNode.type)
      );
    }

    componentRef.instance.data = progNode.data;
    componentRef.instance.focus$.next(progNode.focus);
    componentRef.instance.status$.next(progNode.status);
    componentRef.instance.index = progNode.index;
    if (this.enabled) {
      componentRef.instance.clickedEmitter = this.clickedEvent;
      componentRef.instance.disabled =
        this.progressService.focusIndex === componentRef.instance.index;
    }

    if (index !== undefined) {
      const replaceAmt = replace ? 1 : 0;
      this.compRefs.splice(index, replaceAmt, componentRef);
    } else {
      this.compRefs.push(componentRef);
    }

    return componentRef;
  }

  parseCompType(type: string): Type<IProgressNodeComponent> {
    switch (type) {
      case ProgressNodeTypeEnum.progressNodeComp:
        return ProgressNodeComponent;
      case ProgressNodeTypeEnum.dinkusNodeComp:
        return DinkusNodeComponent;
      case ProgressNodeTypeEnum.videoNodeComp:
        return VideoNodeComponent;
      default:
        return ProgressNodeComponent;
    }
  }

  updateProgress(): void {
    const progress = this.elem.nativeElement.querySelector(
      '.progress-bar > div:first-child'
    ) as HTMLElement;
    const { length } = this.nodes;
    const increment = 100 / length;
    let index = this.nodes
      .map(node => node.focus)
      .indexOf(ProgressNodeFocusEnum.active);

    if (index === this.nodes.length - 1) {
      index = length;
    }
    const value = `min(calc(2rem + ${index} * ${increment}%), 100%)`;
    if (
      this.config.orientation === ProgressBarOrientationEnum.vertical ||
      this.config.orientation === ProgressBarOrientationEnum.verticalReverse
    ) {
      progress.style.height = value;
    } else {
      progress.style.width = value;
    }
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }

    if (this.resizeTimeout !== -1) {
      window.clearTimeout(this.resizeTimeout);
    }
  }
}
