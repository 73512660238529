import { Injectable } from '@angular/core';
import { ConfigurationService } from 'src/app/modules/configuration/services/configuration/configuration.service';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class EarlyAccessService {
  readonly authenticatedEncodedString = CryptoJS.SHA256(
    'authenticated'
  ).toString(CryptoJS.enc.Hex);

  authenticated = false;

  constructor(private configurationService: ConfigurationService) {
    this.getAuthFromSession();
  }

  checkPasscode(passcode: string): boolean {
    const passcodeEncrypted = CryptoJS.SHA256(passcode).toString(
      CryptoJS.enc.Hex
    );
    this.authenticated =
      this.configurationService.config.passcodes.includes(passcodeEncrypted);
    this.saveAuthToSession();
    return this.authenticated;
  }

  saveAuthToSession(): void {
    sessionStorage.setItem(
      this.authenticatedEncodedString,
      Date.now().toString()
    );
  }

  getAuthFromSession(): void {
    this.authenticated =
      sessionStorage.getItem(this.authenticatedEncodedString) !== null;
  }
}
