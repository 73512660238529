import { ISettingsMenuItem } from '../interfaces';

export class DefaultSettingsMenuData {
  static readonly CaptionMenuItems: ISettingsMenuItem[] = [
    {
      name: 'Closed captions',
      id: 'vjs-captions-in-settings',
      htmlClass: '.captions-group',
      addClasses: ['captions-group', 'flexed-group'],
    },
    {
      name: 'Font size',
      id: 'vjs-font-size-in-settings',
      htmlClass: '.font-size-group',
      addClasses: ['font-size-group', 'flexed-group', 'radial-group'],
    },
  ];
  static readonly TranscriptMenuItems: ISettingsMenuItem[] = [
    {
      name: 'Transcript',
      id: 'vjs-transcript-in-settings',
      htmlClass: '.transcript-group',
      addClasses: ['transcript-group', 'flexed-group'],
    },
  ];
  static readonly SettingMenuItems: ISettingsMenuItem[] = [
    ...this.TranscriptMenuItems,
    ...this.CaptionMenuItems,
  ];
}
