import { KnowledgeBaseService } from '../../../modules/olp-api/services/kb/kb.service';
import { Router } from '@angular/router';
import { EventEmitter, Inject, Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  Subject,
  Observable,
  catchError,
  map,
  Subscription,
  take,
} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  Activity,
  Population,
  LearningTarget,
  TextData,
  VideoData,
  TargetResultData,
  SurveyPrompt,
  ImageData,
  NextUp,
  AnswerChoice,
} from '../../interfaces';
import {
  ActivityStateEnum,
  AnswerStateEnum,
  ActivityTool,
  LanguageCodes,
  SaveIntervalTypes,
  NonMutableTryTypes,
  TemplateID,
  SaveAnswerStatesTypes,
} from './../../enums';
import {
  ProgressBarOrientationEnum,
  ProgressNodeFocusEnum,
  ProgressNodeStatusEnum,
  ProgressNodeTypeEnum,
} from 'src/app/modules/progress-bar/enums';
import {
  EXIT_SURVEY_TYPES,
  exitSurveys,
  sandboxActivities,
  siNoChoices,
  yesNoChoices,
  yesNoicons,
} from 'src/app/data';
import { IProgressBarConfig } from 'src/app/modules/progress-bar/interfaces/progress-bar-config.interface';
import { IProgressNodeData } from 'src/app/modules/progress-bar/interfaces/progress-node-data.interface';
import { PageProgressService } from '../page-progress-bar/page-progress.service';
import { videos } from 'src/app/data';
import { VideoDefaultConfig } from 'src/app/modules/video/data';
import { VideoService } from 'src/app/modules/video/services/video.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivityObjectTypes } from '../../enums/activity-object-types';
import { ActivityManifestObject } from '../../interfaces/activity-manifest-object';
import { ToolsService } from 'src/app/modules/tools/services/tools.service';
import { TimeElapsedService } from 'src/app/shared/services/time-elapsed/time-elapsed.service';
import { FeatureFlagService } from '../../../modules/feature-flags/services/feature-flag/feature-flag.service';
import { ResultsService } from '../results/results.service';
import { UserService } from '../user/user.service';
import { PopulationService } from '../population/population.service';
import { FeatureFlags } from '../../enums/feature-flags';
import { ConfigurationService } from 'src/app/modules/configuration/services/configuration/configuration.service';
import { SpeechSynthesisService } from 'src/app/modules/speech-synthesis/services/speech-synthesis/speech-synthesis.service';
import { InactivityService } from '../inactivity/inactivity.service';
import { TrackingService } from 'src/app/modules/tracking/services/tracking.service';
import { ISlideData } from 'src/app/modules/carousel/interfaces';
import { VideoSaveStateService } from 'src/app/modules/video/services/video-save-state.service';
import {
  CompleteActivityStateService,
  IncompleteActivityState,
  IncompleteActivityStateService,
} from 'src/app/modules/activity-save-state';
import { UseAnimationService } from 'src/app/modules/use-animation/use-animation.service';
import { DOCUMENT } from '@angular/common';
import { FooterService } from 'src/app/modules/footer/services/footer.service';
import { AuthSessionService } from 'src/app/modules/auth/services/auth-session/auth-session.service';
import seedrandom from 'seedrandom';
import { footerData } from 'src/app/data/footer-data';
import { ClickstreamService } from 'src/app/modules/olp-api/services/clickstream/clickstream.service';
import { ClickstreamEventTypeName } from 'src/app/modules/olp-api/enums/clickstream-events';
import { nonAssessableTypes } from 'src/app/classes/evaluator-factory';
import { ReferrerService } from '../referrer/referrer.service';
import { AssignmentService } from 'src/app/modules/olp-api/services/assignment/assignment.service';
import { ActivityStatus } from '../../enums/activity-status';
import {
  LoadingScreenService,
  LoadingScreenState,
} from 'src/app/modules/loading-screen/services/loading-screen.service';

@Injectable({
  providedIn: 'root',
})
export class ActivityService implements OnDestroy {
  static readonly PAGE_BAR_LOCAL_STORAGE = 'PageProgressBar';
  static readonly SAVE_ON_STATE_TYPES = [
    ActivityObjectTypes.VIDEO,
    ActivityObjectTypes.QUESTION,
  ];

  skillName$ = new BehaviorSubject<string>('');
  domain = new BehaviorSubject<string>('');
  standard = new Subject<TextData>();
  seriesName = new BehaviorSubject<string>('');
  episodeTitle = new BehaviorSubject<string>('');
  episodeNumber = new BehaviorSubject<string>('0');
  learningTarget = new BehaviorSubject<LearningTarget[]>([]);
  entrySurvey$ = new BehaviorSubject<SurveyPrompt[] | undefined>(undefined);
  exitSurvey$ = new BehaviorSubject<SurveyPrompt[] | undefined>(undefined);
  video = new Subject<VideoData[]>();
  tools = new Subject<ActivityTool[]>();
  nextUp$ = new BehaviorSubject<NextUp | undefined>(undefined);
  isSandboxActivity$ = new BehaviorSubject<boolean>(false);

  isAssignment = false;
  currentActivityOid = -1;
  assignmentId: string | undefined;

  showHeader = new BehaviorSubject<boolean>(false);
  showRewards = new BehaviorSubject<boolean>(false);
  showProgressBar = new BehaviorSubject<boolean>(true);
  showExitButton = new BehaviorSubject<boolean>(true);
  showElapsedTime = new BehaviorSubject<boolean>(true);
  showSecondaryHeader = new BehaviorSubject<boolean>(false);
  pageRoute = new BehaviorSubject<string>('/');

  showInternal = new BehaviorSubject<boolean>(false);
  showVersion = new BehaviorSubject<boolean>(false);

  videoData$ = new BehaviorSubject<VideoData | undefined>(videos[0]);
  videoConfig$ = new BehaviorSubject<VideoDefaultConfig | undefined>(
    new VideoDefaultConfig()
  );

  activityState = ActivityStateEnum.INVALID;
  questionNumber = -1;
  currStep = 0;
  prevStep = 0;
  shouldRoute = true;
  elapsedTime = 0;
  lastReportedInterface = '';
  advanceActivityEvent = new EventEmitter<void>();

  videoProgression = 0;
  currActivity!: Activity;
  currActivityManifest = new BehaviorSubject<ActivityManifestObject[]>([]);
  quickExit = false;

  currentPage = 0;
  pageProgressConfig: IProgressBarConfig = {
    orientation: ProgressBarOrientationEnum.horizontal,
    useDinkus: false,
    autoDinkus: true,
    autoDinkusWindowDim: 800,
    autoDinkusNodeDim: 56,
    dinkusChildCount: 11,
    autoDinkusMinChildren: 5,
    autoDinkusMaxChildren: 10,
    autoDinkusIdealChildren: 11,
  };

  seedrandom = seedrandom();
  activitySeed = '';
  subscriptions = new Subscription();

  entrySurveyIndex = new BehaviorSubject<number>(0);
  readonly languageCode = LanguageCodes.ENGLISH as string; // default language is English

  startScreenBG_left = new BehaviorSubject<ImageData>({ id: '', url: '' });
  startScreenBG_right = new BehaviorSubject<ImageData>({ id: '', url: '' });
  startScreenFG = new BehaviorSubject<ImageData>({ id: '', url: '' });
  saveInterval!: NodeJS.Timeout;
  readonly saveIntervalTime = 5000;

  clickedOnProgNodeFlag = false;
  popAnimationInStarted = new EventEmitter<void>();

  constructor(
    private pageProgressService: PageProgressService,
    private httpClient: HttpClient,
    private router: Router,
    private videoService: VideoService,
    private toolsService: ToolsService,
    public translate: TranslateService,
    private configService: ConfigurationService,
    private timeElapsedService: TimeElapsedService,
    private featureFlagService: FeatureFlagService,
    private kbService: KnowledgeBaseService,
    private userService: UserService,
    private resultsService: ResultsService,
    private incompleteActivityStateService: IncompleteActivityStateService,
    private populationService: PopulationService,
    private speechSynthesisService: SpeechSynthesisService,
    private inactivityService: InactivityService,
    private completeActivityStateService: CompleteActivityStateService,
    private trackingService: TrackingService,
    private videoSaveStateService: VideoSaveStateService,
    private useAnimationService: UseAnimationService,
    private footerService: FooterService,
    private authSessionService: AuthSessionService,
    private clickstreamService: ClickstreamService,
    private referrerService: ReferrerService,
    private assignmentService: AssignmentService,
    private loadingScreenService: LoadingScreenService,
    @Inject(DOCUMENT) private document: Document
  ) {
    translate.addLangs(Object.values(LanguageCodes));
    translate.setDefaultLang(this.languageCode);

    this.exitSurvey$.subscribe(survey => {
      if (survey) {
        for (let i = 0; i < survey.length; i++) {
          if (survey[i].response) {
            this.trackingService.push({
              event: 'Post Survey Response',
              response: survey[i].response,
            });
            this.trackingService.push({
              response: undefined,
            });
          }
        }
      }
    });

    this.inactivityService.inactivityEvent$.subscribe(
      (inactiveTime: number) => {
        this.clickstreamService.addEventFromProperties(
          ClickstreamEventTypeName.Inactivity,
          'Inactivity',
          inactiveTime / 1000
        );
      }
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  setIsAssignment(value: boolean): void {
    this.isAssignment = value;
  }

  //#region fetch and parse activity

  /**
   * Get Activity through HTTP request with the supplied activityId and parse it when retrieved.
   * @param activityId string activityId to create the request for
   * @returns Observable which will execute the fetch when subscribed to.
   */
  fetchActivity(activityId: string): Observable<unknown> {
    let url = `api/activities/${activityId}`;
    if (
      this.featureFlagService.isFlagEnabled(FeatureFlags.CDN_ACTIVITIES) &&
      !sandboxActivities.find(activity => activity.id === activityId)
    ) {
      url = new URL(
        `PracticeData/${activityId}.json`,
        this.configService.config.assetCdnDomain
      ).toString();
    }

    return this.httpClient.get<Activity>(url).pipe(
      catchError(err => {
        console.error('Error fetching activity', err);
        throw err;
      }),
      map(res => {
        //Keeping in this console log for testing in dev purposes, will remove later. (3/4/24 BL)
        console.log(`we have a return from url [${url}] of`, res);
        this.currActivity = res;
        this.parseActivity();
      })
    );
  }

  /**
   * Get activity through Http request with supplied activityId, but don't parse the activity.
   * @param activityId string activityId to create the request for
   * @returns Observable which will execute the fetch when subscribed to.
   */
  fetchActivityInfo(activityId: string): Observable<Activity> {
    const url = `api/activities/${activityId}`;
    return this.httpClient.get<Activity>(url).pipe(
      catchError(err => {
        console.error('Error fetching activity', err);
        throw err;
      })
    );
  }

  /**
   * Parse the current activity, and populate the subjects.
   */
  parseActivity(): void {
    console.log("We're about to parse the activity.", this.currActivity);
    this.prevStep = 0;
    this.parseStandard()
      .parseDomain()
      .parseAvailableTools()
      .parseSeriesName()
      .parseSkillName()
      .parseActivityEpisode()
      .parseNextUp()
      .parseVideo();

    this.setLanguage();
    this.parseActivityManifest();
    this.createEntryScreenSurvey();

    this.processGlobalRetryOverride();
    this.currActivityManifest.next(this.currActivity.activityManifest);

    this.resultsService.numQuestions = this.currActivity.populations.length;
    this.populationService.populations = this.currActivity.populations;
    for (let i = 0; i < this.populationService.populations.length; i++) {
      this.populationService.populationResponses.push({
        responses: [],
        elapsedTime: 0,
      });
    }
  }
  //#endregion

  randomizeAnswerChoices(): void {
    let answerChoices: AnswerChoice[];

    if (!this.activitySeed) {
      this.activitySeed = this.seedrandom.double().toString();
      this.seedrandom = seedrandom(this.activitySeed);
    }

    this.currActivity.populations.forEach(population => {
      if (
        population.templateID === TemplateID.TRUE_FALSE_CHOICE ||
        !this.featureFlagService.isFlagEnabled(
          FeatureFlags.RANDOMIZE_ANSWER_CHOICES
        )
      ) {
        return;
      }

      if ('answerChoices' in population.populationData) {
        answerChoices = population.populationData.answerChoices;
      }

      if (!answerChoices) {
        return;
      }

      if (
        population.templateID === TemplateID.INLINE_CHOICE ||
        population.templateID === TemplateID.STATIC_HORIZONTAL_SEQUENCING ||
        population.templateID === TemplateID.DYNAMIC_HORIZONTAL_SEQUENCING ||
        population.templateID === TemplateID.STATIC_VERTICAL_SEQUENCING
      ) {
        answerChoices.forEach(answerChoice => {
          this.shuffle(answerChoice.choice.texts ?? []);
          this.shuffle(answerChoice.choice.images ?? []);
          this.shuffle(answerChoice.choice.videos ?? []);
          this.shuffle(answerChoice.choice.audios ?? []);
        });
      } else {
        this.shuffle(answerChoices);
      }
      answerChoices = [];
    });

    console.log('Current seed: ', this.activitySeed);
  }

  shuffle<Type>(array: Type[]): Type[] {
    let currentIndex = array.length,
      randomIndex;

    while (currentIndex != 0) {
      randomIndex = Math.floor(this.seedrandom() * currentIndex--);
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  launchActivity(id: string): Promise<boolean> {
    this.resetActivityState();
    return new Promise<boolean>((resolve, reject) => {
      this.fetchActivity(id).subscribe({
        next: () => {
          this.timeElapsedService.startTimer();
          this.initActivity();
          resolve(true);
        },
        error: () => {
          reject(false);
          return this.resetActivityAndNavigateHome();
        },
      });
    });
  }

  private processGlobalRetryOverride(): void {
    if (
      !this.currActivity ||
      this.currActivity.globalTryOverride === undefined
    ) {
      return;
    }
    this.currActivity.populations.forEach(pop => {
      if (
        pop.populationData.tries === undefined &&
        !NonMutableTryTypes.includes(pop.templateID)
      ) {
        pop.populationData.tries = this.currActivity.globalTryOverride;
      }
    });
  }

  private parseStandard(): this {
    if (this.currActivity.standard) {
      this.standard.next(this.currActivity.standard);
    }

    return this;
  }

  private parseDomain(): this {
    if (this.currActivity.domain?.text) {
      this.domain.next(this.currActivity.domain.text);
    }

    return this;
  }

  private parseAvailableTools(): this {
    if (this.currActivity.availableTools) {
      this.tools.next(this.currActivity.availableTools);
    }

    return this;
  }

  private parseSeriesName(): this {
    if (this.currActivity.seriesName) {
      this.seriesName.next(this.currActivity.seriesName);
    }

    return this;
  }

  private parseSkillName(): this {
    if (this.currActivity.skillName) {
      this.skillName$.next(this.currActivity.skillName);
    }

    return this;
  }

  private parseNextUp(): this {
    if (this.currActivity.nextUp) {
      this.nextUp$.next(this.currActivity.nextUp);
    } else {
      this.nextUp$.next(undefined);
    }

    return this;
  }

  private parseActivityEpisode(): this {
    if (this.currActivity.episodeTitle) {
      this.episodeTitle.next(this.currActivity.episodeTitle);
    }

    if (this.currActivity.episodeNumber) {
      this.episodeNumber.next(this.currActivity.episodeNumber);
    }

    return this;
  }

  private parseVideo(): this {
    const video = this.currActivity.videos[0];
    if (video) {
      if (this.featureFlagService.isFlagEnabled(FeatureFlags.CDN_ASSETS)) {
        video.url = new URL(
          video.url,
          this.configService.config.assetCdnDomain
        ).toString();
        video.type = 'application/x-mpegurl';
        video.lang = this.currActivity.language;
        video.captions?.forEach(caption => {
          if (!caption.src) return;
          caption.src = new URL(
            caption.src,
            this.configService.config.assetCdnDomain
          ).toString();
        });
        if (video.posterURL) {
          video.posterURL = new URL(
            video.posterURL,
            this.configService.config.assetCdnDomain
          ).toString();
        }
      }
      this.videoData$.next(video as VideoData);
    }
    return this;
  }

  private setLanguage(): void {
    const language = this.currActivity.language || 'en';
    this.document.documentElement.lang = language;
    this.translate.use(language);
    this.speechSynthesisService.lang =
      this.currActivity.language === LanguageCodes.ENGLISH
        ? LanguageCodes.ENGLISH_EXTENDED
        : LanguageCodes.SPANISH_EXTENDED;
  }

  private createEntryScreenSurvey(): void {
    const surveys: SurveyPrompt[] = [];
    if (this.currActivity?.startScreenData?.learningTarget) {
      this.currActivity.startScreenData.learningTarget.forEach(target => {
        target.successCriteria.forEach(criteria => {
          surveys.push({
            prompt: criteria,
            choices:
              this.currActivity.language === LanguageCodes.ENGLISH
                ? yesNoChoices
                : siNoChoices,
            icons: yesNoicons,
          });
        });
      });
      this.entrySurvey$.next(surveys);
    }
  }

  private parseActivityManifest(): void {
    for (const piece of this.currActivity.activityManifest) {
      switch (piece.type) {
        case ActivityObjectTypes.ENTRY_SURVEY: {
          if (this.currActivity.entrySurvey) {
            this.entrySurvey$.next(this.currActivity.entrySurvey);
          }
          break;
        }
        case ActivityObjectTypes.START_SCREEN: {
          this.parseActivityStartScreenManifest();
          break;
        }

        case ActivityObjectTypes.EXIT_SURVEY: {
          if (this.currActivity.language === LanguageCodes.ENGLISH) {
            this.exitSurvey$.next([exitSurveys[EXIT_SURVEY_TYPES.ENGLISH]]);
          } else {
            this.exitSurvey$.next([exitSurveys[EXIT_SURVEY_TYPES.SPANISH]]);
          }
          break;
        }
        case ActivityObjectTypes.RESULTS_SCREEN: {
          //does nothing for now
          break;
        }
      }
    }
  }

  private parseActivityStartScreenManifest(): void {
    if (this.currActivity.startScreenData) {
      if (this.currActivity.startScreenData.learningTarget) {
        this.learningTarget.next(
          this.currActivity.startScreenData.learningTarget
        );
      }

      if (this.currActivity.startScreenData.background_leftImage) {
        this.startScreenBG_left.next(
          this.currActivity.startScreenData.background_leftImage
        );
      }

      if (this.currActivity.startScreenData.background_rightImage) {
        this.startScreenBG_right.next(
          this.currActivity.startScreenData.background_rightImage
        );
      }

      if (this.currActivity.startScreenData.foreground) {
        this.startScreenFG.next(this.currActivity.startScreenData.foreground);
      }
    }
  }

  /**
   * Get the data that the ActivityManifestObject is referring to
   * @param obj ActivityManifestObject to get the asset data from
   * @returns filled data from the manifest object, or undefined if not found
   */
  getAssetData(
    obj: ActivityManifestObject
  ): VideoData | Population | undefined {
    switch (obj.type) {
      case ActivityObjectTypes.VIDEO: {
        if (!obj.id) {
          break;
        }
        const video = this.currActivity.videos.find(
          element => element.id === obj.id
        );
        if (video) {
          return video;
        } else {
          break;
        }
      }
      case ActivityObjectTypes.QUESTION: {
        if (!obj.id) break;
        const population = this.currActivity.populations.find(
          element => element.id === obj.id
        );
        if (population) {
          return population;
        } else {
          break;
        }
      }
    }
    return undefined;
  }

  /**
   * Initialize a brand new activity based on currentActivity if activityId is undefined, attempt to initialize with saved state if string is provided
   * @param activityId undefined to init new activity; string activityId to check local storage to init with saved activity state
   */
  initActivity(activityId?: string): boolean | void {
    if (activityId) {
      let shouldResume = false;
      this.incompleteActivityStateService
        .getSavedActivityState(activityId)
        .then(activity => {
          if (activity) {
            console.log(
              'activityState after resume for',
              activityId,
              'is',
              activity
            );
            shouldResume = true;
            this.resumeActivityFromState(activity);
            this.randomizeAnswerChoices();
          } else {
            this.initNewActivity();
            this.loadingScreenService.loadingScreenState.set(
              LoadingScreenState.animatingOut
            );
          }
        })
        .catch(() => {
          this.initNewActivity();
        });
      if (shouldResume) {
        return true;
      }
    } else {
      this.initNewActivity();
      return true;
    }
  }

  private initNewActivity(): void {
    this.currStep = -1;
    this.questionNumber = -1;
    this.populationService.answerStates = new Array<AnswerStateEnum>(
      this.populationService.populations.length
    ).fill(AnswerStateEnum.INCOMPLETE);
    this.inactivityService.endInactivityTracking();
    this.inactivityService.startInactivityTracking();
    this.videoSaveStateService.getVideoSaveStateWithId(
      this.currActivity.videos[0].id
    );
    this.randomizeAnswerChoices();
    this.reportCurrentActivityToAnalytics();
    this.startActivity();

    // Need to keep this logic here, because an activity can have any order of the nodes.
    // For example,
    //    question, video, video, question
    //    video, question, question
    //    etc.
    // So we don't want to create a self-selected assignment on the video node, if the
    // video node comes after the question node.
    // This is the most logical place to create/update an assignment
    this.assignmentService.checkForAccessToken();
    if (this.isAssignment) {
      console.log(
        'The current activity is the assignment, updating the status to IN PROGRESS'
      );
      this.assignmentService.updateAssignmentRecord(
        this.currentActivityOid,
        ActivityStatus.IN_PROGRESS
      );
    } else {
      console.log('Start Self-selected Activity');
      this.assignmentService.startSelfSelectedAssignment(
        this.currentActivityOid
      );
    }
    this.loadingScreenService.loadingScreenState.set(
      LoadingScreenState.animatingOut
    );
  }

  resumeActivityFromState(activityState: IncompleteActivityState): void {
    const nextStep =
      this.currActivity.activityManifest.length > activityState.currentStep + 1
        ? activityState.currentStep + 1
        : activityState.currentStep;

    this.setupActivityFromState(activityState);
    this.startPageProgressBar(activityState.progressNodes, 1);
    this.inactivityService.endInactivityTracking();
    this.inactivityService.startInactivityTracking();
    this.reportCurrentActivityToAnalytics();

    const { id, type } = this.currActivity.activityManifest[nextStep];
    if (
      type === ActivityObjectTypes.QUESTION ||
      type === ActivityObjectTypes.VIDEO
    ) {
      this.handleProgressBarResume(activityState, id ?? '', type, nextStep);
    } else if (
      this.featureFlagService.isFlagEnabled(FeatureFlags.SAVE_ACTIVITY_ANYWHERE)
    ) {
      this.advanceActivity(true);
    }
  }

  handleProgressBarResume(
    activityState: IncompleteActivityState,
    id: string,
    type: string,
    nextStep: number
  ): void {
    if (!id) {
      this.advanceActivity(true);
      return;
    }

    if (type === ActivityObjectTypes.QUESTION) {
      const index = this.currActivity.activityManifest
        .filter(a => a.type === type)
        .findIndex(q => q.id === id);

      if (index !== -1) {
        const isFinishedQuestion =
          activityState.answerStates[index] !== AnswerStateEnum.INCOMPLETE;

        // advance to next question if finished and not in teacher mode
        if (isFinishedQuestion && !this.authSessionService.isTeacher) {
          activityState.selectedAnswers = [[]];
          nextStep++;
        }
      }
    } else if (type === ActivityObjectTypes.VIDEO) {
      if (
        this.videoSaveStateService.curVideoState$.getValue().videoWatched &&
        !this.authSessionService.isTeacher &&
        this.currActivityManifest.value[nextStep].id ===
          this.videoSaveStateService.curVideoState$.value.id
      ) {
        nextStep++;
      }
    }

    this.resumeOrAdvanceActivity(
      nextStep,
      activityState.curHintsRevealed,
      activityState.selectedAnswers
    );
  }

  resumeOrAdvanceActivity(
    nextStep: number,
    curHintsRevealed?: ISlideData[],
    selectedAnswers?: (string | number)[][]
  ): void {
    const progStartIndex = this.findStartProgressBarStep();
    const nodeIndex = nextStep - progStartIndex;
    if (
      nodeIndex >= 0 &&
      nodeIndex <
        this.currActivity.activityManifest.filter(
          a =>
            a.type === ActivityObjectTypes.QUESTION ||
            a.type === ActivityObjectTypes.VIDEO
        ).length
    ) {
      this.goToProgressNodeStep(
        this.pageProgressService.AvailableProgressNodes[nodeIndex],
        curHintsRevealed,
        selectedAnswers
      );
      if (selectedAnswers) {
        this.populationService.lastFilledCheck = this.populationService.currTry;
        this.populationService.setSelectedAnswers(selectedAnswers);
      }
    } else {
      this.advanceActivity(true);
    }
  }

  handleResumePopulation(activity: IncompleteActivityState): void {
    this.populationService.currTry = activity.currTry ?? 0;
    this.populationService.selectedAnswers.next(activity.selectedAnswers ?? []);
    this.populationService.filledAnswers.next(activity.filledAnswers ?? []);
    this.populationService.disabledAnswers.next(
      this.populationService.getCurrentPopAnswerState() ===
        AnswerStateEnum.COMPLETE
        ? [['disabled']]
        : activity.disabledAnswers ?? []
    );
    this.populationService.prevSelectedAnswers =
      this.populationService.selectedAnswers.getValue();
    this.questionNumber = activity.questionNumber;
    const popIndex = this.currActivity.activityManifest
      .filter(a => a.type === ActivityObjectTypes.QUESTION)
      .findIndex(
        q => q.id === this.currActivity.activityManifest[this.currStep].id
      );
    if (popIndex !== -1) {
      this.populationService.currentPopulation.next(
        this.populationService.populations[popIndex]
      );
    }

    this.populationService.resumeActivity();

    this.toolsService.deactivateAllTools();

    // refresh the tei to show updated info
    const curPop = this.populationService.currentPopulation.getValue();
    if (curPop) {
      this.setSeenHints(activity);
    }
  }

  setSeenHints(activity: IncompleteActivityState): void {
    // handle seen hints
    // Could probably have edited obj directly but seemed sketchy
    const popVal = this.populationService.currentPopulation.getValue();

    if (popVal && popVal.hint) {
      const newHints: ISlideData[] = [];

      popVal.hint.forEach(hint => {
        const newHint = { ...hint };

        if (
          activity.curHintsRevealed?.find((h: ISlideData) => h.id === hint.id)
        ) {
          newHint.wasViewed = true;
        }

        newHints.push(newHint);
      });
      this.populationService.currentPopulation.next({
        ...popVal,
        hint: newHints,
      });
      if (newHints.every(hint => hint.wasViewed)) {
        this.populationService.isAllHintsSeen.next(true);
      }
    }
  }

  setIsSandboxActivity(value: boolean): void {
    this.isSandboxActivity$.next(value);
  }

  /**
   * Sets the service's state to a passed in state.
   * @param state IncompleteActivityState to setup service with
   */
  setupActivityFromState(state: IncompleteActivityState): void {
    this.currStep = state.currentStep;
    this.questionNumber = state.questionNumber;
    this.resultsService.numPerfect = state.numPerfect;
    this.resultsService.numCorrect = state.numCorrect;
    this.resultsService.numIncorrect = state.numIncorrect;
    this.populationService.currTry = state.currTry ?? 0;
    this.populationService.answerStates = state.answerStates;
    this.populationService.populationResponses = state.populationResponses;
    this.populationService.selectedAnswers.next(state.selectedAnswers ?? []);
    this.populationService.disabledAnswers.next(state.disabledAnswers ?? []);
    this.populationService.prevSelectedAnswers =
      this.populationService.selectedAnswers.getValue();
    if (
      this.questionNumber >= 0 &&
      this.questionNumber < this.currActivity.populations.length
    ) {
      this.populationService.currentPopulation.next(
        this.currActivity.populations[this.questionNumber]
      );
      this.setSeenHints(state);
    }
    this.startPageProgressBar(state.progressNodes);
    this.elapsedTime = state.elapsedTime;
    this.timeElapsedService.setTime(this.elapsedTime);
    this.isAssignment = state.isAssignment;
    this.currentActivityOid = state.currentActivityOid;
    this.activitySeed = state.activitySeed ?? '';
    this.seedrandom = seedrandom(this.activitySeed);
  }

  /**
   * Create the progress bar, set up the header, then route according to the activity state.
   */
  startActivity(): void {
    this.createProgressBar();
    this.advanceActivity();
  }

  /**
   * Create progress bar based on the current activity state.
   */
  createProgressBar(): void {
    const progressBarNodes: IProgressNodeData[] = [];
    let qNum = 1;

    for (const piece of this.currActivity.activityManifest) {
      switch (piece.type) {
        case ActivityObjectTypes.VIDEO: {
          progressBarNodes.push({
            index: progressBarNodes.length,
            type: ProgressNodeTypeEnum.videoNodeComp,
            data: {},
            focus: ProgressNodeFocusEnum.inactive,
            status: ProgressNodeStatusEnum.none,
          });
          break;
        }
        case ActivityObjectTypes.QUESTION: {
          progressBarNodes.push({
            index: progressBarNodes.length,
            type: ProgressNodeTypeEnum.progressNodeComp,
            data: { text: `${qNum}` },
            focus: ProgressNodeFocusEnum.inactive,
            status: ProgressNodeStatusEnum.none,
          });
          qNum++;
          break;
        }
      }
    }
    console.log("we're creating progress bar with", progressBarNodes);
    this.startPageProgressBar(progressBarNodes);
  }

  /**
   * Helper function to set header show flags if they've changed.
   * @param showHeader true to show header; false to hide header
   * @param showProgressBar true to show progress bar; false to hide progress bar
   * @param showRewards true to show rewards; false to hide rewards
   */
  setupHeader(
    showHeader: boolean,
    showProgressBar: boolean,
    showRewards: boolean,
    showElapsedTime: boolean,
    showSecondaryHeader: boolean,
    pageRoute: string
  ): void {
    if (this.showHeader.getValue() !== showHeader) {
      this.showHeader.next(showHeader);
    }
    if (this.showProgressBar.getValue() !== showProgressBar) {
      this.showProgressBar.next(showProgressBar);
    }
    if (this.showRewards.getValue() !== showRewards) {
      this.showRewards.next(showRewards);
    }
    if (this.showElapsedTime.getValue() !== showElapsedTime) {
      this.showElapsedTime.next(showElapsedTime);
    }
    if (this.showSecondaryHeader.getValue() !== showSecondaryHeader) {
      this.showSecondaryHeader.next(showSecondaryHeader);
    }
    if (this.pageRoute.getValue() !== pageRoute) {
      this.pageRoute.next(pageRoute);
    }
  }

  /**
   * Based on activity state set up the header flags, and navigate to the appropriate url.
   */
  setHeaderAndRoute(): void {
    if (!this.showInternal.getValue()) {
      this.showInternal.next(true);
    }
    if (!this.shouldRoute) return;

    let route = '/';
    console.log(`we're now setHeaderAndRoute`, this.activityState);
    switch (this.activityState) {
      case ActivityStateEnum.START:
        this.setupHeader(true, false, true, false, false, '/start');
        route = 'start';
        break;
      case ActivityStateEnum.SELF_RATE:
        this.setupHeader(true, true, false, true, false, '/self-rating');
        route = 'self-rating';
        break;
      case ActivityStateEnum.VIDEO:
        this.setupHeader(true, true, false, true, true, '/video');
        route = 'video';
        break;
      case ActivityStateEnum.QUESTION:
        this.setupHeader(true, true, false, true, true, '/tei');
        route = 'tei';
        break;
      case ActivityStateEnum.RESULTS:
        this.setupHeader(true, false, true, false, false, '/results');
        route = 'results';
        break;
      case ActivityStateEnum.EXIT_SURVEY:
        this.setupHeader(true, false, false, false, false, '/survey');
        route = 'survey';
        break;
    }
    // allow to play SFX and then proceed to the next page
    setTimeout(() => {
      this.router
        .navigate([route], {
          skipLocationChange: true,
        })
        .then(() => {
          this.resetFocus();
        })
        .catch(err => console.log(err));
    }, 0);
  }

  resetFocus(): void {
    document.getElementById('top-focus-anchor')?.focus();
  }

  goToProgressNodeStep(
    node: IProgressNodeData,
    curHintsRevealed?: ISlideData[],
    selectedAnswers?: (string | number)[][]
  ): void {
    this.clickedOnProgNodeFlag = true;
    const startProgStep = this.findFirstProgNodeIndex();

    if (startProgStep !== -1) {
      this.prevStep = this.currStep;
      this.currStep = node.index + startProgStep;
      const activityStep = this.currActivity.activityManifest[this.currStep];
      const popIndex = this.resetPopulationService(activityStep);
      this.questionNumber = popIndex;

      const activityState = this.exportIncompleteActivityState();
      if (curHintsRevealed) {
        activityState.curHintsRevealed = curHintsRevealed;
      }

      // handle activity state pop resume info and resuming videos
      this.populationService.visualOverride.next(true);
      if (
        popIndex !== -1 &&
        !nonAssessableTypes.includes(
          this.populationService.populations[popIndex].templateID
        )
      ) {
        this.evaluatePopResponses(activityState, popIndex);
        this.resetPopulationService(activityStep);
      } else if (activityStep.type === ActivityObjectTypes.VIDEO) {
        this.setVideoSaveState(this.currStep);
      }

      if (selectedAnswers && popIndex !== -1) {
        this.handleResumingNonAssessableTypes(
          popIndex,
          activityState,
          selectedAnswers
        );
      }

      this.pageProgressService.newFocusIndex(node.index);
      this.currStep -= 1;
      if (this.useAnimationService.useAnimation$.getValue()) {
        this.popAnimationInStarted.pipe(take(1)).subscribe(() => {
          const activityStep =
            this.currActivity.activityManifest[this.currStep];
          this.handleResumeAftermath(activityState, activityStep, node);
        });
      }

      this.populationService.visualOverride.next(false);

      this.advanceActivity(true);

      if (!this.useAnimationService.useAnimation$.getValue()) {
        this.handleResumeAftermath(activityState, activityStep, node);
      }
    }

    this.clickedOnProgNodeFlag = false;
  }

  handleResumingNonAssessableTypes(
    popIndex: number,
    activityState: IncompleteActivityState,
    selectedAnswers: (string | number)[][]
  ): void {
    if (
      nonAssessableTypes.includes(
        this.populationService.populations[popIndex].templateID
      )
    ) {
      activityState.selectedAnswers = selectedAnswers;
    }
  }

  resetPopulationService(activityStep: ActivityManifestObject): number {
    let popIndex = -1;
    if (activityStep.type === ActivityObjectTypes.QUESTION) {
      popIndex = this.currActivity.activityManifest
        .filter(a => a.type === ActivityObjectTypes.QUESTION)
        .findIndex(
          q => q.id === this.currActivity.activityManifest[this.currStep].id
        );

      this.populationService.filledAnswers.next([[]]);
      this.populationService.selectedAnswers.next([[]]);
      this.populationService.disabledAnswers.next([[]]);
      this.populationService.lastFilledCheck = 0;
      this.populationService.currTry = 0;
    }

    return popIndex;
  }

  evaluatePopResponses(
    activityState: IncompleteActivityState,
    popIndex: number
  ): IncompleteActivityState {
    activityState.currTry =
      activityState.populationResponses[popIndex].responses.length;
    activityState.questionNumber = popIndex;
    const questionNumber = this.questionNumber;
    this.questionNumber = popIndex;
    this.populationService.currentPopulation.next(
      this.populationService.populations[this.questionNumber]
    );
    const answers = this.populationService.getCurrentPopulationData().answers;
    const tempType =
      this.populationService.currentPopulation.getValue()?.templateID;

    activityState.populationResponses[popIndex].responses.forEach(
      (response, index) => {
        this.populationService.setSelectedAnswers(response);
        this.populationService.setDisabledAnswers(
          this.populationService.getCurrentPopulationData()
        );
        const finishedPop =
          activityState.answerStates[popIndex] !== AnswerStateEnum.INCOMPLETE &&
          activityState.populationResponses[popIndex].responses.length - 1 ===
            index;

        this.populationService.lastFilledCheck = index - 1;

        this.populationService.getCorrectAnswers(
          index,
          tempType ?? TemplateID.NONE,
          this.populationService.selectedAnswers,
          answers,
          finishedPop,
          true
        );
      }
    );

    activityState.filledAnswers =
      this.populationService.filledAnswers.getValue();
    activityState.selectedAnswers =
      this.populationService.selectedAnswers.getValue();
    activityState.disabledAnswers =
      this.populationService.disabledAnswers.getValue();
    this.questionNumber = questionNumber;

    return activityState;
  }

  setVideoSaveState(step: number): void {
    const index = this.currActivity.activityManifest
      .filter(a => a.type === ActivityObjectTypes.VIDEO)
      .findIndex(v => v.id === this.currActivity.activityManifest[step].id);
    if (index !== -1) {
      this.videoSaveStateService.getVideoSaveStateWithId(
        this.currActivity.videos[index].id
      );
    }
  }

  handleResumeAftermath(
    activityState: IncompleteActivityState,
    activityStep: ActivityManifestObject,
    node: IProgressNodeData
  ): void {
    if (activityStep.type === ActivityObjectTypes.QUESTION) {
      this.handleResumePopulation(activityState);
    }

    if (node.status !== ProgressNodeStatusEnum.none) {
      this.footerService.footerData = footerData.NEXT_WITH_TOOLS;
      this.footerService.enableFooterButton();
    } else {
      if (activityStep.type === ActivityObjectTypes.VIDEO) {
        this.footerService.footerData = footerData.NEXT_WITHOUT_TOOLS;
        this.footerService.enableFooterButton();
      } else {
        this.footerService.footerData = footerData.OK_WITH_TOOLS;
        this.footerService.disableFooterButton();
      }
    }
  }

  findStartProgressBarStep(): number {
    for (let i = 0; i < this.currActivity.activityManifest.length; i++) {
      const actType = this.currActivity.activityManifest[i].type;
      if (
        actType === ActivityObjectTypes.QUESTION ||
        actType === ActivityObjectTypes.VIDEO
      ) {
        return i;
      }
    }
    return -1;
  }

  findLastProgressBarStep(): number {
    const startProgNodeStep = this.findStartProgressBarStep();
    if (startProgNodeStep === -1) {
      return -1;
    }

    return (
      startProgNodeStep +
      this.pageProgressService.AvailableProgressNodes.length -
      1
    );
  }

  isActivityFinished(): boolean {
    const allQuestionsAnswered =
      this.populationService.answerStates.findIndex(
        a => a === AnswerStateEnum.INCOMPLETE
      ) === -1;
    const allVideosSeen =
      this.pageProgressService.AvailableProgressNodes.filter(
        a => a.type === ProgressNodeTypeEnum.videoNodeComp
      ).findIndex(v => v.focus === ProgressNodeFocusEnum.inactive) === -1;
    return allQuestionsAnswered && allVideosSeen;
  }

  isCurrStepOnProgressBar(): boolean {
    if (
      this.currStep < 0 ||
      this.currActivity.activityManifest.length <= this.currStep
    ) {
      return false;
    }
    const objType = this.currActivity.activityManifest[this.currStep].type;
    return (
      objType === ActivityObjectTypes.QUESTION ||
      objType === ActivityObjectTypes.VIDEO
    );
  }

  /**
   * Move the activity forward one step
   * If we're in the question state, attempt to move on to the next question
   * if we've exhausted the populations, moves the state forward.
   * will ignore if attempting to advance beyond results state.
   */
  advanceActivity(disableSave?: boolean): void {
    if (
      this.isCurrStepOnProgressBar() &&
      this.pageProgressService.isFocusedLastProgNode() &&
      this.currStep === this.findLastProgressBarStep()
    ) {
      this.fillIncompleteAnswersIncorrect();
    }
    if (++this.currStep >= this.currActivity.activityManifest.length) {
      console.error(
        "We're at the end of the activity, probably should have been handled better.",
        this.currStep,
        this.currActivity.activityManifest.length
      );
      return this.resetActivityAndNavigateHome();
    }
    console.log(
      "we're now at step",
      this.currStep,
      this.currActivity.activityManifest[this.currStep],
      'current state',
      this.activityState
    );

    this.handleNextActivityObjectType();
    this.reportCurrentInterfaceToAnalytics();
    if (
      !disableSave &&
      this.featureFlagService.isFlagEnabled(FeatureFlags.AUTOSAVE)
    ) {
      this.saveActivityOnNewStepType();
    }
    this.inactivityService.pauseInactivityTracking();
    this.inactivityService.resumeInactivityTracking();
    this.advanceActivityEvent.emit();
  }

  private handleNextActivityObjectType(): void {
    switch (this.currActivity.activityManifest[this.currStep].type) {
      case ActivityObjectTypes.START_SCREEN: {
        this.activityState = ActivityStateEnum.START;
        this.setHeaderAndRoute();
        break;
      }
      case ActivityObjectTypes.ENTRY_SURVEY: {
        this.activityState = ActivityStateEnum.SELF_RATE;
        this.setHeaderAndRoute();
        break;
      }
      case ActivityObjectTypes.VIDEO: {
        this.processAdvanceVideo();
        break;
      }
      case ActivityObjectTypes.QUESTION: {
        this.processAdvanceQuestion();
        break;
      }
      case ActivityObjectTypes.EXIT_SURVEY: {
        this.activityState = ActivityStateEnum.EXIT_SURVEY;
        this.setHeaderAndRoute();
        break;
      }
      case ActivityObjectTypes.RESULTS_SCREEN: {
        this.activityState = ActivityStateEnum.RESULTS;
        this.setHeaderAndRoute();
        break;
      }
    }
  }

  private processAdvanceVideo(): void {
    this.setQuestionNumberFromStep();
    const startProgBarStep = this.findStartProgressBarStep();
    if (startProgBarStep !== -1) {
      this.updateProgressBar(this.currStep - this.findStartProgressBarStep());
    }
    if (this.activityState !== ActivityStateEnum.VIDEO) {
      this.activityState = ActivityStateEnum.VIDEO;
      this.setHeaderAndRoute();
    }
    const video = this.getAssetData(
      this.currActivity.activityManifest[this.currStep]
    );

    if (video) {
      this.videoData$.next(video as VideoData);
    }
    if (video?.id) {
      this.videoSaveStateService.getVideoSaveStateWithId(video.id);
    }
  }

  private processAdvanceQuestion(): void {
    this.setQuestionNumberFromStep();
    const startProgBarStep = this.findStartProgressBarStep();
    if (startProgBarStep !== -1) {
      this.updateProgressBar(this.currStep - this.findStartProgressBarStep());
    }

    this.timeElapsedService.startTimer();
    const pop = this.getAssetData(
      this.currActivity.activityManifest[this.currStep]
    );
    if (pop) {
      if (
        this.isResumingQuestion(this.questionNumber) &&
        !this.clickedOnProgNodeFlag
      ) {
        const node = this.pageProgressService.getQuestionNode(
          this.questionNumber
        );
        if (node) {
          this.goToProgressNodeStep(node);
          return;
        }
      }

      this.populationService.nextQuestion(
        pop as Population,
        this.populationService.populations.length - 1 <= this.questionNumber
      );
      if (SaveIntervalTypes.includes((pop as Population).templateID)) {
        this.startSaveInterval(this.saveIntervalTime);
      } else {
        this.stopSaveInterval();
      }
    }
    if (this.activityState !== ActivityStateEnum.QUESTION) {
      this.activityState = ActivityStateEnum.QUESTION;
      this.setHeaderAndRoute();
    }
  }

  setQuestionNumberFromStep(): void {
    let questionNumber = -1;
    for (let i = 0; i < this.currActivity.activityManifest.length; i++) {
      if (this.currStep < i) {
        break;
      }
      const { type } = this.currActivity.activityManifest[i];
      if (type === ActivityObjectTypes.QUESTION) {
        questionNumber++;
      }
    }
    if (questionNumber !== -1) {
      this.questionNumber = questionNumber;
    }
  }

  isResumingQuestion(questionNumber: number): boolean {
    if (
      questionNumber < 0 ||
      questionNumber >= this.populationService.answerStates.length ||
      questionNumber >= this.populationService.populationResponses.length
    ) {
      return false;
    }
    const answerStateNotIncomplete =
      this.populationService.answerStates[this.questionNumber] !==
      AnswerStateEnum.INCOMPLETE;
    if (answerStateNotIncomplete) {
      return true;
    } else {
      const responses =
        this.populationService.populationResponses[questionNumber].responses;
      const havePopulationResponses =
        responses.length > 0 &&
        responses[0].length > 0 &&
        responses[0][0].length > 0 &&
        responses[0][0][0].toString().length > 0;
      return havePopulationResponses;
    }
  }

  async saveActivityOnNewStepType(): Promise<void> {
    const curType = this.currActivity.activityManifest[this.currStep]
      .type as ActivityObjectTypes;
    // only attempt to save if on a different type of page than last step
    if (
      this.currStep === 0 ||
      this.currActivity.activityManifest[this.currStep - 1].type !== curType
    ) {
      // only submit save if type within save types or if has 'saveActivityAnywhere' flag
      if (this.shouldSaveActivityState(curType)) {
        await this.submitActivityData(true);
      } else {
        // remove activity from resumes cause most likely either done or started over
        const resumeActivityIds =
          await this.incompleteActivityStateService.getSavedActivityIds();

        if (
          resumeActivityIds.findIndex(id => id === this.currActivity.id) !== -1
        ) {
          await this.incompleteActivityStateService.removeActivity(
            this.currActivity.id
          );
        }
      }
    }
  }

  shouldSaveActivityState(curType: ActivityObjectTypes): boolean {
    return (
      ActivityService.SAVE_ON_STATE_TYPES.findIndex(
        saveType => saveType === curType
      ) !== -1 || this.featureFlagService.isFlagEnabled('saveActivityAnywhere')
    );
  }

  hasAnswerChanged(): boolean {
    return (
      this.populationService.selectedAnswers.getValue() !==
      this.populationService.prevSelectedAnswers
    );
  }

  savedChangedAnswers(
    savedAnswers: (string | number)[][],
    prevSelectedAnswers: (string | number)[][]
  ): void {
    // check if the past prev selected is the current prev selected
    // if not then want the save interval to save on next timeout
    if (prevSelectedAnswers === this.populationService.prevSelectedAnswers) {
      this.populationService.prevSelectedAnswers = savedAnswers;
    }
  }

  startSaveInterval(time: number): void {
    this.stopSaveInterval();
    this.saveInterval = setInterval(async () => {
      const curType = this.currActivity.activityManifest[this.currStep]
        .type as ActivityObjectTypes;
      const isQuestion = curType === ActivityObjectTypes.QUESTION;
      const shouldSave = isQuestion && this.hasAnswerChanged();
      const prevSelectedAnswers = this.populationService.prevSelectedAnswers;
      const selectedAnswers = this.populationService.selectedAnswers.getValue();
      if (this.shouldSaveActivityState(curType) && shouldSave) {
        await this.submitActivityData(true);
        if (isQuestion) {
          this.savedChangedAnswers(selectedAnswers, prevSelectedAnswers);
        }
        // for the constructed response TEIs we need to save the answers as submitted
        this.populationService.submittedSelectedAnswers = selectedAnswers;
      }
    }, time);
  }

  stopSaveInterval(): void {
    if (this.saveInterval) {
      clearInterval(this.saveInterval);
    }
  }

  async submitActivityData(resumeableActivity?: boolean): Promise<void> {
    if (
      this.authSessionService.isTeacher &&
      !this.featureFlagService.isFlagEnabled('saveActivityAnywhere')
    ) {
      console.log('Teacher mode is enabled. Progress is not saved.');
      return;
    }
    console.log(
      'submitActivityData',
      resumeableActivity,
      this.exportIncompleteActivityState()
    );

    if (!resumeableActivity) {
      this.clickstreamService.addEventFromProperties(
        ClickstreamEventTypeName.Event,
        'Video',
        {
          timeWatched:
            this.videoSaveStateService.curVideoState$.getValue()
              .videoTotalWatchtime,
          videoDuration: this.videoService.videoDuration,
        }
      );
      this.clickstreamService.completeActivity(
        this.resultsService.getPercentage(),
        this.resultsService.earnedStars
      );
      this.clickstreamService.saveClickstreamData();
      this.clickstreamService.clearCache();
      this.resultsService.earnedStars =
        this.resultsService.getCurrentNumEarnedStars();
      this.trackingService.push({
        event: 'Activity Complete',
        timeElapsed: this.timeElapsedService.getTimeAsSeconds(),
      });
      this.trackingService.push({
        event: 'Video Time Watched',
        timeElapsed:
          this.videoSaveStateService.curVideoState$.getValue()
            .videoTotalWatchtime,
      });
      this.trackingService.push({
        timeElapsed: undefined,
      });
      if (this.featureFlagService.isFlagEnabled('useKB')) {
        await this.submitStarsToKB();
        await this.completeActivityStateService
          .addActivityStateAndSync({
            activityId: this.currActivity.id,
            dateCompleted: Date.now().toString(),
            timeToCompleteSecs: this.timeElapsedService.getTimeAsSeconds(),
            starsEarned: this.resultsService.earnedStars,
            score: this.resultsService.getPercentage(),
            answerStates: this.populationService.answerStates,
          })
          .catch(err => console.error('error adding complete activity', err));
      }
      if (!this.featureFlagService.isFlagEnabled('saveActivityAnywhere')) {
        await this.incompleteActivityStateService.removeActivity(
          this.currActivity.id
        );
      }
    } else {
      const pop = this.getAssetData(
        this.currActivity.activityManifest[this.currStep]
      );
      if (
        pop &&
        SaveAnswerStatesTypes.includes((pop as Population).templateID)
      ) {
        this.populationService.submittedSelectedAnswers =
          this.populationService.selectedAnswers.value;
      }
      await this.saveActivity();
    }
  }

  async submitStarsToKB(): Promise<void> {
    const currentStars = this.userService.stars$.getValue() ?? 0;

    if (
      this.featureFlagService.isFlagEnabled('useKB') &&
      (await this.kbService.get('MainMenu', 'Stars')) !==
        currentStars + this.resultsService.earnedStars
    ) {
      this.kbService.set(
        'MainMenu',
        'Stars',
        currentStars + this.resultsService.earnedStars
      );
    }
  }

  /**
   * handles progress bar state update when click on the next button
   */
  updateProgressBarState(): void {
    this.pageProgressService.updateProgressFromAnswerStates(
      this.populationService.answerStates
    );
  }

  nextProgressBarFocus(): void {
    this.pageProgressService.newFocusIndex();
  }

  findFirstProgNodeIndex(): number {
    for (let i = 0; i < this.currActivity.activityManifest.length; i++) {
      const step = this.currActivity.activityManifest[i];
      if (
        step.type === ActivityObjectTypes.VIDEO ||
        step.type === ActivityObjectTypes.QUESTION
      ) {
        return i;
      }
    }
    return -1;
  }

  fillIncompleteAnswersIncorrect(): void {
    for (let i = 0; i < this.populationService.answerStates.length; i++) {
      const a = this.populationService.answerStates[i];
      if (a === AnswerStateEnum.INCOMPLETE) {
        this.populationService.answerStates[i] = AnswerStateEnum.INCORRECT;
        this.resultsService.numIncorrect++;
      }
    }
  }

  getTargetResultData(): TargetResultData[] {
    const targetResultData: TargetResultData[] = [];
    const sub = this.learningTarget.subscribe(targets => {
      targets.forEach(target => {
        for (let i = 0; i < target.successCriteria.length; i++) {
          const sucCrit = target.successCriteria[i];
          const id = sucCrit.id;
          if (!id) {
            return;
          }
          targetResultData.push({
            id: id,
            imagePath: this.getRandomTargetImage(),
            totalCorrect: `${this.populationService.getTotalCorrectTargets(
              id
            )}`,
            totalAssessed: `${this.populationService.getTotalTargets(id)}`,
            description: sucCrit.text,
          });
        }
      });
    });
    sub.unsubscribe();

    return targetResultData;
  }

  getRandomTargetImage(): string {
    return '/assets/learning-target/diamond.svg';
  }

  //#region Progress Bar
  savePageProgressBar(): void {
    localStorage.setItem(
      ActivityService.PAGE_BAR_LOCAL_STORAGE,
      JSON.stringify(this.pageProgressService.AvailableProgressNodes)
    );
  }

  loadPageProgressBar(): void {
    let result: Array<IProgressNodeData> = [];
    const barData = localStorage.getItem(
      ActivityService.PAGE_BAR_LOCAL_STORAGE
    );
    if (barData) {
      result = JSON.parse(barData);
    }
    this.startPageProgressBar(result);
  }

  startPageProgressBar(
    pageProgressData: IProgressNodeData[],
    offset?: number
  ): void {
    this.pageProgressService.progressService.Config = this.pageProgressConfig;
    this.currentPage = this.findCurrentPage(pageProgressData) + (offset ?? 0);
    this.pageProgressService.AvailableProgressNodes = pageProgressData;
    this.pageProgressService.newFocusIndex(this.currentPage);
    if (offset) {
      this.pageProgressService.setPreviousToSeen();
    }
  }

  isCurrentPopulationAnswered(): boolean {
    if (
      this.pageProgressService.AvailableProgressNodes &&
      this.pageProgressService.AvailableProgressNodes.length > 0
    ) {
      const node = this.pageProgressService.AvailableProgressNodes.find(
        p => p.focus === ProgressNodeFocusEnum.active
      );
      return node?.status !== ProgressNodeStatusEnum.none;
    }
    return false;
  }

  isCurrentPopulationIncorrect(): boolean {
    if (
      this.pageProgressService.AvailableProgressNodes &&
      this.pageProgressService.AvailableProgressNodes.length > 0
    ) {
      const node = this.pageProgressService.AvailableProgressNodes.find(
        p => p.focus === ProgressNodeFocusEnum.active
      );
      return node?.status === ProgressNodeStatusEnum.wrong;
    }
    return false;
  }

  findCurrentPage(pageProgressData: IProgressNodeData[]): number {
    let pageNum = 0;

    for (let i = 0; i < pageProgressData.length; i++) {
      const page = pageProgressData[i];
      if (page.focus === ProgressNodeFocusEnum.active) {
        pageNum = i;
        break;
      }
    }

    return pageNum;
  }

  updateProgressBar(newIndex: number): void {
    this.currentPage = newIndex;
    const isComplete = this.completionCheck();
    if (!isComplete) {
      this.pageProgressService.newFocusIndex(newIndex);
    }
    this.savePageProgressBar();
  }

  completionCheck(): boolean {
    return (
      this.currentPage >= this.pageProgressService.AvailableProgressNodes.length
    );
  }

  //#endregion

  //#region Activity State

  resetActivityState(): void {
    this.resultsService.numPerfect = 0;
    this.resultsService.numCorrect = 0;
    this.resultsService.numIncorrect = 0;
    this.updateStarCounter();
    this.activityState = ActivityStateEnum.INVALID;
    this.populationService.populationResponses = [];
    this.populationService.answerStates = new Array<AnswerStateEnum>(
      this.populationService.populations.length
    ).fill(AnswerStateEnum.INCOMPLETE);
    this.populationService.currTry = 0;
    this.populationService.selectedAnswers.next([]);
    this.populationService.disabledAnswers.next([]);
    this.populationService.prevSelectedAnswers = [];
    this.showHeader.next(false);
    this.showSecondaryHeader.next(false);
    this.toolsService.clearTools();
    this.videoService.resetWatchTime();
    this.seriesName.next('');
    this.episodeTitle.next('');
    this.skillName$.next('');
    this.learningTarget.next([]);
    this.isAssignment = false;
    this.currentActivityOid = -1;
    this.activitySeed = '';
  }

  /**
   * Hide the header and navigate to the home page
   */
  resetActivityAndNavigateHome(): void {
    if (
      !this.featureFlagService.isFlagEnabled(FeatureFlags.RETURN_TO_REFERRER)
    ) {
      if (this.isSandboxActivity$.getValue()) {
        this.router.navigate(['/sandbox']);
      } else {
        this.router.navigate(['/']);
      }

      this.resetActivityState();
    } else {
      this.referrerService.navigateToReferrer();
    }
  }

  updateStarCounter(): void {
    if (this.resultsService.earnedStars === 0) {
      return;
    }
    const currentStars = this.userService.stars$.getValue() || 0;
    this.userService.stars$.next(
      currentStars + this.resultsService.earnedStars
    );
    this.resultsService.earnedStars = 0;
  }

  async saveActivity(): Promise<void> {
    if (this.currActivity) {
      const exportActivity = this.exportIncompleteActivityState();
      console.log('we got an export:', exportActivity);
      await this.incompleteActivityStateService.saveActivity(exportActivity);
    }
  }

  /**
   * Attempt to save the activity state in local storage, and resetActivity
   */
  async exitActivity(): Promise<void> {
    this.stopSaveInterval();
    this.clickstreamService.interruptActivity();
    if (
      this.currActivity &&
      this.currActivity.activityManifest.length > this.currStep
    ) {
      const curType = this.currActivity.activityManifest[this.currStep]
        .type as ActivityObjectTypes;
      if (this.shouldSaveActivityState(curType)) {
        await this.submitActivityData(true);
      }
    }

    this.toolsService.deactivateAllTools();
    this.inactivityService.endInactivityTracking();
    setTimeout(() => {
      //hack to run this during the next tick to fix ExpressionChangedAfterItHasBeenCheckedError
      this.footerService.show = false;
    });
    this.resetActivityAndNavigateHome();
  }

  /**
   * Gets the current step from the activity manifest, if it's a question gets the TEI interface key name.
   * @returns string representing the current location in the activity
   */
  getCurrentInterface(): string {
    const currStep = this.currActivity.activityManifest[this.currStep]
      .type as ActivityObjectTypes;
    if (currStep === ActivityObjectTypes.QUESTION) {
      return this.populationService.getCurrentPopulationName();
    }
    return currStep;
  }

  /**
   * If the current interface is different from the previously reported interface, this function reports the current interface
   */
  reportCurrentInterfaceToAnalytics(): void {
    const currInterface = this.getCurrentInterface();
    if (this.lastReportedInterface !== currInterface) {
      this.trackingService.push({ interface: this.getCurrentInterface() });
      this.lastReportedInterface = currInterface;
    }
  }

  /**
   * Push the current activityId onto the analytics datalayer
   */
  reportCurrentActivityToAnalytics(): void {
    this.trackingService.push({ activityId: this.currActivity.id });
    this.clickstreamService.startActivity(
      this.currActivity.episodeTitle ?? 'default activity title',
      'category',
      'scoreCriteria',
      0
    );
  }

  exportIncompleteActivityState(): IncompleteActivityState {
    const activityState: IncompleteActivityState = {
      id: this.currActivity.id,
      currentStep: this.currStep - 1,
      questionNumber:
        this.questionNumber < 0 ? this.questionNumber : this.questionNumber - 1,
      numPerfect: this.resultsService.numPerfect,
      numCorrect: this.resultsService.numCorrect,
      numIncorrect: this.resultsService.numIncorrect,
      answerStates: this.populationService.answerStates,
      populationResponses: this.populationService.populationResponses,
      progressNodes: this.pageProgressService.AvailableProgressNodes,
      elapsedTime: this.timeElapsedService.getTimeAsSeconds(),
      currTry: this.populationService.currTry,
      filledAnswers: this.populationService.filledAnswers.getValue(),
      selectedAnswers: this.populationService.submittedSelectedAnswers,
      disabledAnswers: this.populationService.disabledAnswers.getValue(),
      curHintsRevealed: this.populationService
        .getCurrentPopulationHints()
        .filter(val => val.wasViewed ?? false),
      isAssignment: this.isAssignment,
      currentActivityOid: this.currentActivityOid,
      lastOpened: Date.now().toString(),
      activitySeed: this.activitySeed,
    };

    return activityState;
  }

  /**
   * Transforms provided period format activity id to slugiffied activityId
   * @param activityId An activity id in the previous format (Subject.Grade.etc...)
   * @returns An activity id with the new slug format (subject-grade-etc...)
   */
  private sluggifyActivityId(activityId: string): string {
    return activityId.split('.').join('-').toLowerCase();
  }

  /**
   * DEBUG USE ONLY
   * relaunches the current activity to be used with content preview only
   */
  relaunchContentPreview(): void {
    if (this.featureFlagService.isFlagEnabled(FeatureFlags.CONTENT_TESTING)) {
      this.resetActivityState();
      this.timeElapsedService.startTimer();
      this.initActivity();
    } else {
      console.warn(
        'Tried calling activity service relaunchContentPreview but contentTesting feature flag isnt true, please only use this for debug purposes only!'
      );
    }
  }
}
