import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ReplaySubject, Subscription } from 'rxjs';
import { skipWhile } from 'rxjs/operators';
import { AudioDescriptor } from '../../enums/audio-descriptor';
import { AudioType } from '../../enums/audio-type';
import { UseAnimationService } from 'src/app/modules/use-animation/use-animation.service';

@Component({
  selector: 'htc-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements AfterContentInit, OnDestroy, OnChanges {
  @Input() useBackground? = true;
  @Input() showPopup = false;
  @Input() theme = '';
  @Output() showPopupChange = new EventEmitter<boolean>();
  @Input() showClose = true;
  @Input() useWideMode = false;
  @Input() fullscreen = false;
  @Input() skipCloseBtnFocus = false;
  @Input() allowEscClose = true;

  @Input() title = '';
  @Input() titleIcon = '';

  @ViewChild('dialog', { static: false })
  dialog!: ElementRef<HTMLDialogElement>;

  changeState = new ReplaySubject<SimpleChanges>(1);
  subscription = new Subscription();
  isOpen = false;
  clickDescriptor = AudioDescriptor.CLICK_GENERAL;
  clickType = AudioType.audio_SfxClick;
  debounceClose = false;
  private isClosing = false;

  constructor(private useAnimationService: UseAnimationService) {}

  ngAfterContentInit(): void {
    this.subscription.add(
      this.changeState
        .pipe(skipWhile(() => !this.dialog))
        .subscribe(({ showPopup }: SimpleChanges) => {
          if (showPopup?.currentValue && !this.isOpen) {
            this.open();
          } else if (!showPopup?.currentValue && this.isOpen) {
            this.close();
          }
        })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.changeState.next(changes);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  open(): void {
    // prevent immediate closing
    this.debounceClose = true;
    setTimeout(() => {
      this.debounceClose = false;
    }, 0);

    this.isOpen = true;
    this.dialog.nativeElement.showModal();
    this.showPopupChange.emit(true);
  }

  @HostListener('keydown.tab', ['$event'])
  @HostListener('keydown.shift.tab', ['$event'])
  handleTabKey($event?: Event): void {
    // when the dialog is closing prevent press Tab, so prevents Tab key functionality inside the dialog
    if (this.isClosing && $event) {
      $event.preventDefault();
      return;
    }
  }

  @HostListener('keydown.esc', ['$event']) // For IE11 coverage
  close($event?: Event): void {
    if (this.debounceClose) {
      return;
    }

    if (!this.allowEscClose && $event) {
      this.preventDefaultStopProp($event);
      return;
    }

    if (this.isOpen && this.dialog?.nativeElement) {
      $event && this.preventDefaultStopProp($event);

      // play SFX if the dialog was closed using ESC key
      const dialog = (<HTMLElement>$event?.target)?.closest('dialog');
      if (dialog) {
        const closeBtn = dialog.querySelectorAll('button.btn-dialog-close');
        if (closeBtn.length > 0) {
          (<HTMLElement>closeBtn[0]).click();
        }
      }

      if (!this.useAnimationService.useAnimation$.getValue()) {
        this.isClosing = true;
        this.setCloseState();
        return;
      }

      this.closeDialogRoot();
    }
  }

  preventDefaultStopProp($event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();
  }

  closeDialogRoot(): void {
    const dialogRoot = this.dialog.nativeElement.parentElement;
    if (dialogRoot) {
      this.isClosing = true;
      dialogRoot.classList.add('dialog-anim-out');
      dialogRoot.onanimationend = (): void => {
        dialogRoot.onanimationend = null;
        dialogRoot.classList.remove('dialog-anim-out');
        this.setCloseState();
      };
    }
  }

  private setCloseState(): void {
    this.isOpen = false;
    this.dialog.nativeElement.close();
    this.showPopupChange.emit(false);
    this.isClosing = false;
  }
}
