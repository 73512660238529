import { FooterData } from '../shared/interfaces';

export const footerData: { [key: string]: FooterData } = {
  NO_BUTTON_WITH_TOOLS: {
    showTools: true,
    showButton: false,
    buttonText: '',
    buttonIconPath: '',
    buttonIconClasses: '',
  },
  START_NEXT_WITH_TOOLS: {
    showTools: true,
    showButton: true,
    buttonText: 'START.NEXT_BUTTON',
    buttonIconPath: 'assets/button-icons/right-arrow.svg',
    buttonIconClasses: 'btn btn-footer btn-with-right-icon',
  },
  NEXT_WITH_TOOLS: {
    showTools: true,
    showButton: true,
    buttonText: 'BUTTONS.NEXT',
    buttonIconPath: 'assets/button-icons/right-arrow.svg',
    buttonIconClasses: 'btn btn-footer btn-with-right-icon',
  },
  START_WITH_TOOLS: {
    showTools: true,
    showButton: true,
    buttonText: 'BUTTONS.START',
    buttonIconPath: 'assets/button-icons/right-arrow.svg',
    buttonIconClasses: 'btn btn-footer btn-with-right-icon',
  },
  OK_WITH_TOOLS: {
    showTools: true,
    showButton: true,
    buttonText: 'BUTTONS.OK',
    buttonIconPath: 'assets/button-icons/check.svg',
    buttonIconClasses: 'btn btn-footer btn-with-right-icon',
  },
  NO_BUTTON_WITHOUT_TOOLS: {
    showTools: false,
    showButton: false,
    buttonText: '',
    buttonIconPath: '',
    buttonIconClasses: '',
  },
  START_NEXT_WITHOUT_TOOLS: {
    showTools: false,
    showButton: true,
    buttonText: 'START.NEXT_BUTTON',
    buttonIconPath: 'assets/button-icons/right-arrow.svg',
    buttonIconClasses: 'btn btn-footer btn-with-right-icon',
  },
  NEXT_WITHOUT_TOOLS: {
    showTools: false,
    showButton: true,
    buttonText: 'BUTTONS.NEXT',
    buttonIconPath: 'assets/button-icons/right-arrow.svg',
    buttonIconClasses: 'btn btn-footer btn-with-right-icon',
  },
  START_WITHOUT_TOOLS: {
    showTools: false,
    showButton: true,
    buttonText: 'BUTTONS.START',
    buttonIconPath: 'assets/button-icons/right-arrow.svg',
    buttonIconClasses: 'btn btn-footer btn-with-right-icon',
  },
  OK_WITHOUT_TOOLS: {
    showTools: false,
    showButton: true,
    buttonText: 'BUTTONS.OK',
    buttonIconPath: 'assets/button-icons/check.svg',
    buttonIconClasses: 'btn btn-footer btn-with-right-icon',
  },
  BEGIN_ACTIVITY_WITH_TOOLS: {
    showTools: true,
    showButton: true,
    buttonText: 'BUTTONS.START',
    buttonIconPath: 'assets/button-icons/right-arrow.svg',
    buttonIconClasses:
      'btn btn-footer btn-with-right-icon btn-animate-slide-in-from-right',
  },
  SKIP_WITH_TOOLS: {
    showTools: true,
    showButton: true,
    buttonText: 'BUTTONS.SKIP',
    buttonIconPath: '',
    buttonIconClasses: 'btn btn-footer',
  },
};
