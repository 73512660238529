import { Component } from '@angular/core';

@Component({
  selector: 'htc-status-correct',
  templateUrl: './status-correct.component.html',
  styleUrls: ['../progress-node/progress-node.component.scss'],
})
export class StatusCorrectComponent {
  readonly path = '/assets/progress-node-icons/';
  readonly imageUrl = 'check.svg';

  constructor() {}
}
