import { Component, Input } from '@angular/core';
import { ISlideContent, ISlideData } from '../../interfaces/index';

@Component({
  selector: 'htc-slide-base',
  template: ``,
})
export class SlideBaseComponent implements ISlideData {
  type = '';
  imageLoaded = false;
  @Input() id = '';
  @Input() data: ISlideContent = {};

  constructor() {}

  onLoadComplete(): void {
    this.imageLoaded = true;
  }
}
