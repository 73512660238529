<div
  class="flex-container flex-justify-space-around flex-align-center controls">
  <div>
    <div
      class="bubble"
      [ngClass]="{
        'mini-bubble': !(isPlaying || isPaused),
        'bubble-with-pause': (isPlaying || isPaused) && showPlayPause,
        'bubble-no-pause': (isPlaying || isPaused) && !showPlayPause
      }">
      <div
        class="bubble-content"
        [ngClass]="{
          'show-content': !(isPlaying || isPaused)
        }">
        <img class="speaker" src="/assets/read-aloud/speaker.svg" />
      </div>
      <div
        class="bubble-content"
        [ngClass]="{
          'show-content': isPlaying || isPaused
        }">
        <button
          #controls
          *ngIf="showPlayPause"
          [tabindex]="preventTabTargeting ? -1 : 0"
          (keyup.space)="stopSpaceEvent($event)"
          data-testid="read-aloud-controls-play-pause-img">
          <img
            src="{{
              isPlaying
                ? '/assets/read-aloud/audioPlayerPause.svg'
                : '/assets/read-aloud/audioPlayerPlay.svg'
            }}"
            alt="(isPlaying
              ? 'BUTTONS.PLAY'
              : 'BUTTONS.PAUSE'
            ) | translate" />
        </button>
        <div class="visualizer">
          <htc-lottie-player
            *ngIf="isPlaying || isPaused"
            [ngClass]="{ 'display-none': isLoading }"
            class="waveform"
            [autoplay]="true"
            autoplaySegment="waveform-loop"
            [loopAutoplaySegment]="true"
            [src]="'/assets/read-aloud/waveform.json'"
            data-testid="read-aloud-controls-waveform-lottie">
          </htc-lottie-player>
          <svg-icon
            *ngIf="isPlaying || isPaused"
            [ngClass]="{ 'display-none': !isLoading }"
            class="loading"
            src="/assets/loading-images/loading-circle.svg"
            data-testid="read-aloud-controls-loading-svg"></svg-icon>
        </div>
      </div>
    </div>
  </div>
</div>
