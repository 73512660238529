/**
 * Message states in application
 * @enum {number}
 */
export enum MessageCodes {
  HTTP_AUTH_REQUIRED = 401, // Authentication Required
  HTTP_FORBIDDEN = 403, // Not Authorized
  HTTP_NOT_FOUND = 404, // Page Not Found
  HTTP_UNEXPECTED_NET_ERROR = 409, // Unexpected Network Error
  HTTP_NET_GENERIC = 499, // Unrecognized Network Error
  HTTP_SERVER_INTERNAL = 500, // Internal Server Error
  HTTP_SERVICE_UNAVAIL = 503, // Service Unavailable
  APP_NETWORK = 903, // Network Error
  APP_RES = 904, // Resolution Warning
  APP_WARN_REFRESH = 905, // Refresh Warning
  APP_COMPAT = 907, // Compatibility Message
  APP_LOGOUT_CONFIRM = 1001, // Confirm Closing Tab/Window While Logged In
  APP_CLOSE_CONFIRM = 1002, // Confirm Closing Tab/Window While Logged In
  APP_IDLE = 1003, // Notification of Inactivity
  APP_EXIT_ACTIVITY_CONFIRM = 1006, // Exit Activity Confirmation
  APP_EXIT_ACTIVITY_CONFIRM_ES = 1007, // Exit Activity Confirmation en español
  APP_UNKNOWN_ERROR = 1111, // Unknown Error
  HIDE_INTERNAL = 6384,
  SPEECH_SYNTHESIS_AUDIO_BUSY,
  SPEECH_SYNTHESIS_AUDIO_HARDWARE,
  SPEECH_SYNTHESIS_NETWORK,
  SPEECH_SYNTHESIS_SYNTHESIS_UNAVAILABLE,
  SPEECH_SYNTHESIS_SYNTHESIS_FAILED,
  SPEECH_SYNTHESIS_LANGUAGE_UNAVAILABLE,
  SPEECH_SYNTHESIS_VOICE_UNAVAILABLE,
  SPEECH_SYNTHESIS_TEXT_TOO_LONG,
  SPEECH_SYNTHESIS_INVALID_ARGUMENT,
  SPEECH_SYNTHESIS_NOT_ALLOWED,
  SPEECH_SYNTHESIS_TAB_MUTED,
}
