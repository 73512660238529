<htc-dialog
  [useBackground]="false"
  [useWideMode]="true"
  [showClose]="true"
  [skipCloseBtnFocus]="true"
  (showPopupChange)="onPopupChange()"
  data-testid="htc-vp-popup-dialog">
  <div
    class="video-container"
    [ngClass]="{
      'stand-alone': !showTranscript,
      transcription: showTranscript
    }"
    data-testid="htc-vp-popup-outer-container">
    <htc-video-container
      [videoConfig$]="videoConfig$"
      [videoData$]="videoData$"
      [isPopup]="true"></htc-video-container>
  </div>
</htc-dialog>
