import { Activity } from 'src/app/shared/interfaces';

export const reading4: Activity = {
  id: 'Reading.0.Test.X.ReadingTestActivity',
  product: 'Reading',
  language: 'en',
  skillName: 'Test',
  thumbnail: {
    id: 'rec4EpNQv1wWDfwmq',
    url: '/helix/Reading/Grade_K/Letterrooms/A/R_Letterrooms_A_Astronaut.png',
    altText: [
      { language: 'en', altText: '' },
      { language: 'es', altText: '' },
    ],
  },
  description: 'This activity is for use to try out different question types.',
  domain: {
    text: 'Letters',
  },
  seriesName: 'Sample Testing Series',
  episodeNumber: 'X',
  episodeTitle: 'Reading Test Activity',
  videos: [
    {
      id: 'recFIhTAcT51PbaF5',
      url: '/helix/Reading/Grade_K/Letterrooms/A/hls_streams/R_Letterrooms_A_Astronaut.m3u8',
      posterURL:
        '/helix/Reading/Grade_K/Letterrooms/A/R_Letterrooms_A_Astronaut.png',
      captions: [
        {
          srclang: 'en',
          mode: 'hidden',
          kind: 'captions',
        },
      ],
    },
  ],
  startScreenData: {
    learningTarget: [
      {
        id: '1684.A',
        learningTarget: {
          text: 'to recognize the letter Aa',
        },
        successCriteria: [
          {
            id: '1684.A.1',
            text: 'point to letter Aa and name it',
          },
        ],
      },
    ],
    background_leftImage: {
      id: 'recygZeNHb67g46Je',
      url: '/helix/UX_Images/Start_Screen_Blobs/Prepositions_G3V2_Poster.jpg',
      altText: [
        {
          language: 'en',
          altText: 'Axis floats in space outside his spaceship.',
        },
        {
          language: 'es',
          altText: 'Axis flota en el espacio afuera de su nave espacial.',
        },
      ],
    },
    background_rightImage: {
      id: 'recxjC353VMav5ybu',
      url: '/helix/UX_Images/Start_Screen_Blobs/Nova-Scouts_space-sky.svg',
      altText: [
        {
          language: 'en',
          altText: 'Stars in space.',
        },
        {
          language: 'es',
          altText: 'Estrellas en el espacio.',
        },
      ],
    },
    foreground: {
      id: 'recOn2Luhc6XMnbRL',
      url: '/helix/UX_Images/Start_Screen_Characters/Cat5.svg',
      altText: [
        {
          language: 'en',
          altText: 'CAT is pouncing.',
        },
        {
          language: 'es',
          altText: 'G.A.T.A está saltando.',
        },
      ],
    },
  },
  populations: [
    {
      id: '1684.A.1.1',
      templateID: 0,
      hint: [
        {
          id: '1684.A.1.1.0',
          type: 'BasicSlide',
          data: {
            title: 'Try singing the ABC song!\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'What is the first letter of the alphabet?\n',
        },
        answerChoices: [
          {
            id: '1684.A.1.1_1',
            choice: {
              texts: [
                {
                  text: 'Aa\n',
                },
              ],
            },
          },
          {
            id: '1684.A.1.1_2',
            choice: {
              texts: [
                {
                  text: 'Bb\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: 'This is the second letter of the alphabet.\n',
              },
            },
          },
          {
            id: '1684.A.1.1_3',
            choice: {
              texts: [
                {
                  text: 'Cc\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: 'This is the third letter of the alphabet.\n',
              },
            },
          },
        ],
        answers: ['1684.A.1.1_1'],
        associatedSuccessCriteria: ['1684.A.1'],
      },
      feedbackSecondIncorrect: {
        content: 'The first letter of the alphabet is Aa!\n',
      },
    },
    {
      id: '1684.A.1.2',
      templateID: 4,
      hint: [
        {
          id: '1684.A.1.2.0',
          type: 'BasicSlide',
          data: {
            title: 'Cats have four legs.\n',
          },
        },
        {
          id: '1684.A.1.2.1',
          type: 'BasicSlide',
          data: {
            title: 'Cats are animals.\n',
          },
        },
        {
          id: '1684.A.1.2.2',
          type: 'BasicSlide',
          data: {
            title: '\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Which is a picture of a cat?\n',
        },
        answerChoices: [
          {
            id: '1684.A.1.2_1',
            choice: {
              images: [
                {
                  id: 'recyc3ccnJo7mc5uk',
                  url: '/helix/TEI/SVGs/TEI_prepositions_catontable_01.svg',
                  altText: [
                    {
                      language: 'en',
                      altText: 'A cat sleeps on a table.',
                    },
                    {
                      language: 'es',
                      altText: 'El gato se duerme sobre la mesa.',
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '1684.A.1.2_2',
            choice: {
              images: [
                {
                  id: 'recb3rMU07RfjdlSM',
                  url: '/helix/TEI/SVGs/TEI_prepositions_ham_cheesesandwich_01.svg',
                  altText: [
                    {
                      language: 'en',
                      altText: '',
                    },
                    {
                      language: 'es',
                      altText: '',
                    },
                  ],
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: 'This is a type of food, not an animal.\n',
              },
            },
          },
          {
            id: '1684.A.1.2_3',
            choice: {
              images: [
                {
                  id: 'recEee5JWgVPIBdwX',
                  url: '/helix/TEI/SVGs/TEI_prepositions_Mexico_01.svg',
                  altText: [
                    {
                      language: 'en',
                      altText: '',
                    },
                    {
                      language: 'es',
                      altText: '',
                    },
                  ],
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: { content: 'This is the country Mexico.\n' },
            },
          },
        ],
        answers: ['1684.A.1.2_1'],
        associatedSuccessCriteria: ['1684.A.1'],
      },
      feedbackSecondIncorrect: {
        content: "This is a cat because it's an animal with four legs.\n",
      },
    },
    {
      id: '1684.A.1.3',
      templateID: 5,
      hint: [
        {
          id: '1684.A.1.3.0',
          type: 'BasicSlide',
          data: {
            title: 'Farm animals usually live on a farm.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Which two of these are farm animals?\n',
        },
        answerChoices: [
          {
            id: '1684.A.1.3_1',
            choice: {
              texts: [
                {
                  text: 'Chicken\n',
                },
              ],
            },
          },
          {
            id: '1684.A.1.3_2',
            choice: {
              texts: [
                {
                  text: 'Cow\n',
                },
              ],
            },
          },
          {
            id: '1684.A.1.3_3',
            choice: {
              texts: [
                {
                  text: 'Car\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: 'A car is not alive and farm animals are alive.\n',
              },
            },
          },
          {
            id: '1684.A.1.3_4',
            choice: {
              texts: [
                {
                  text: 'Dragon\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: 'Dragons are fictional creatures, not farm animals.\n',
              },
            },
          },
        ],
        answers: ['1684.A.1.3_1', '1684.A.1.3_2'],
        associatedSuccessCriteria: ['1684.A.1'],
      },
      feedbackSecondIncorrect: {
        content: 'These are all animals you can find on a farm.\n',
      },
    },
    {
      id: '1684.A.1.4',
      templateID: 6,
      hint: [
        {
          id: '1684.A.1.4.0',
          type: 'BasicSlide',
          data: {
            title: 'Animals are living creatures.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Which of these pictures has an animal? Pick three.\n',
        },
        answerChoices: [
          {
            id: '1684.A.1.4_1',
            choice: {
              images: [
                {
                  id: 'recoRL4irk6j0z9RT',
                  url: '/helix/TEI/SVGs/TEI_prepositions_dogbetweentree_01.svg',
                  altText: [
                    {
                      language: 'en',
                      altText: 'A dog sits between two trees.',
                    },
                    {
                      language: 'es',
                      altText: 'El perro se sienta entre dos árboles.',
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '1684.A.1.4_2',
            choice: {
              images: [
                {
                  id: 'recC05a2PRCNlveb8',
                  url: '/helix/TEI/SVGs/TEI_prepositions_horsejumpingfence_01.svg',
                  altText: [
                    {
                      language: 'en',
                      altText: 'A horse jumps over a fence.',
                    },
                    {
                      language: 'es',
                      altText: 'El caballo salta sobre la valla.',
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '1684.A.1.4_3',
            choice: {
              images: [
                {
                  id: 'recUnmYAgpbIWajkS',
                  url: '/helix/TEI/SVGs/TEI_prepositions_foxinbox_01.svg',
                  altText: [
                    {
                      language: 'en',
                      altText: 'A fox sits inside a cardboard box.',
                    },
                    {
                      language: 'es',
                      altText:
                        'El zorro se sienta dentro de la caja de cartón.',
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '1684.A.1.4_4',
            choice: {
              images: [
                {
                  id: 'reczsbMN6bR9pKlaB',
                  url: '/helix/TEI/SVGs/TEI_prepositions_ballundertable_01.svg',
                  altText: [
                    {
                      language: 'en',
                      altText: 'A soccer ball sits under a table.',
                    },
                    {
                      language: 'es',
                      altText: ' La pelota de fútbol está debajo de la mesa.',
                    },
                  ],
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'This image is of a ball and table. There is no animal.\n',
              },
            },
          },
        ],
        answers: ['1684.A.1.4_1', '1684.A.1.4_2', '1684.A.1.4_3'],
        associatedSuccessCriteria: ['1684.A.1'],
      },
      feedbackSecondIncorrect: {
        content: 'Only images that have at least one animal are correct.\n',
      },
    },
    {
      id: '1684.A.1.5',
      templateID: 2,
      hint: [
        {
          id: '1684.A.1.5.0',
          type: 'BasicSlide',
          data: {
            title: 'Look at the position of the horse.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'The horse is jumping over the fence.\n',
        },
        answerChoices: [
          {
            id: '1684.A.1.5_1',
            choice: {
              texts: [
                {
                  text: 'true\n',
                },
              ],
            },
          },
          {
            id: '1684.A.1.5_2',
            choice: {
              texts: [
                {
                  text: 'false\n',
                },
              ],
            },
          },
        ],
        answers: ['1684.A.1.5_1'],
        associatedSuccessCriteria: ['1684.A.1'],
      },
      feedbackSecondIncorrect: {
        content: 'The horse is indeed leaping over the fence.\n',
      },
      image: {
        id: 'recC05a2PRCNlveb8',
        url: '/helix/TEI/SVGs/TEI_prepositions_horsejumpingfence_01.svg',
        altText: [
          {
            language: 'en',
            altText: 'A horse jumps over a fence.',
          },
          {
            language: 'es',
            altText: 'El caballo salta sobre la valla.',
          },
        ],
      },
    },
    {
      id: '1684.A.1.6',
      templateID: 1,
      hint: [
        {
          id: '1684.A.1.6.0',
          type: 'BasicSlide',
          data: {
            title: 'There has to be water for people to swim in.\n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'People can swim at the {CHOICE_TOKEN}.\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '1684.A.1.6_1',
                  text: 'beach\n',
                },
                {
                  id: '1684.A.1.6_2',
                  text: 'desert\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content: 'Deserts often have no water whatsoever.\n',
                    },
                  },
                },
                {
                  id: '1684.A.1.6_3',
                  text: 'bedroom\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        "Bedrooms don't usually have swimming pools in them.\n",
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['1684.A.1.6_1'],
        associatedSuccessCriteria: ['1684.A.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'The only place that people can swim in this question is the beach.\n',
      },
      image: {
        id: 'recaG0kdzb6WWd1bl',
        url: '/helix/TEI/SVGs/TEI_prepositions_boyonbeach_01.svg',
        altText: [
          {
            language: 'en',
            altText: '',
          },
          {
            language: 'es',
            altText: '',
          },
        ],
      },
    },
    {
      id: '1684.A.1.7',
      templateID: 3,
      hint: [
        {
          id: '1684.A.1.7.0',
          type: 'BasicSlide',
          data: {
            title: 'This animal rhymes with "box."\n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'A {WORD_TOKEN} is in the box.\n',
          },
        ],
        answers: [['fox\n']],
      },
      feedbackSecondIncorrect: {
        content: 'This orange animal is called a fox.\n',
      },
      image: {
        id: 'recUnmYAgpbIWajkS',
        url: '/helix/TEI/SVGs/TEI_prepositions_foxinbox_01.svg',
        altText: [
          { language: 'en', altText: 'A fox sits inside a cardboard box.' },
          {
            language: 'es',
            altText: 'El zorro se sienta dentro de la caja de cartón.',
          },
        ],
      },
    },
  ],
  activityManifest: [
    {
      type: 'START_SCREEN',
    },
    {
      type: 'ENTRY_SURVEY',
    },
    {
      type: 'VIDEO',
      id: 'recFIhTAcT51PbaF5',
    },
    {
      type: 'QUESTION',
      id: '1684.A.1.1',
    },
    {
      type: 'QUESTION',
      id: '1684.A.1.2',
    },
    {
      type: 'QUESTION',
      id: '1684.A.1.3',
    },
    {
      type: 'QUESTION',
      id: '1684.A.1.4',
    },
    {
      type: 'QUESTION',
      id: '1684.A.1.5',
    },
    {
      type: 'QUESTION',
      id: '1684.A.1.6',
    },
    {
      type: 'QUESTION',
      id: '1684.A.1.7',
    },
    {
      type: 'EXIT_SURVEY',
    },
    {
      type: 'RESULTS_SCREEN',
    },
  ],
  version: '-2054757146',
  instructionsImage: {
    id: 'recqnzfDjMQK7N7kr',
    url: '/helix/UX_Images/Characters/CAT/CAT_Chibi_Happy_Eyes_Open_Mouth_Open.svg',
    altText: [
      { language: 'en', altText: 'CAT is smiling.' },
      { language: 'es', altText: 'G.A.T.A está sonriendo.' },
    ],
  },
  characterLeanout: {
    id: 'svvjeewfjhHFEFgef',
    url: '/helix/TEI/Leanouts/Estrella_Leanout_01.svg',
    altText: [
      {
        language: 'en',
        altText: 'Estrella leans out from the side of the screen.',
      },
      {
        language: 'es',
        altText: 'Estrella se asoma por un lado de la pantalla.',
      },
    ],
  },
};
