import { Activity } from 'src/app/shared/interfaces';

export const reading2: Activity = {
  id: '018ea00f-3c69-7bbd-9539-5993db71d0e1',
  product: 'Reading',
  language: 'en',
  skillName: 'Prepositions',
  thumbnail: {
    id: 'recygZeNHb67g46Je',
    url: '/helix/Reading/Grade_3/Prepositions/Episode_02/R_G3_Prepositions_EP02_Poster.png',
    altText: [
      {
        language: 'en',
        altText: 'Axis floats in space outside his spaceship.',
      },
      {
        language: 'es',
        altText: 'Axis flota en el espacio afuera de su nave espacial.',
      },
    ],
  },
  description:
    'With their shields down, Axis and C.A.T. must race against the clock to get them back up. Knowing how to use prepositions in context will help them avoid more damage from a nearby comet.',
  domain: {
    text: 'Parts of Speech',
  },
  seriesName: "Axis's Big Break",
  episodeNumber: '2',
  episodeTitle: 'Comet Tale',
  videos: [
    {
      id: 'recdiMNqL34JyVSCM',
      url: '/helix/Reading/Grade_3/Prepositions/Episode_02/hls_streams/R_G3_Prepositions_EP02_Video01.m3u8',
      posterURL:
        '/helix/Reading/Grade_3/Prepositions/Episode_02/R_G3_Prepositions_EP02_Poster.png',
      captions: [
        {
          src: '/helix/Reading/Grade_3/Prepositions/Episode_02/R_G3_Prepositions_EP02_Video01.vtt',
          srclang: 'en',
          mode: 'hidden',
          kind: 'captions',
        },
      ],
    },
  ],
  startScreenData: {
    learningTarget: [
      {
        id: '33744.B',
        learningTarget: {
          text: 'to identify a preposition or prepositional phrase',
        },
        successCriteria: [
          {
            id: '33744.B.1',
            text: 'locate prepositions and prepositional phrases in isolation',
          },
          {
            id: '33744.B.2',
            text: 'locate prepositions and prepositional phrases in context',
          },
        ],
      },
    ],
    background_leftImage: {
      id: 'recygZeNHb67g46Je',
      url: '/helix/Reading/Grade_3/Prepositions/Episode_02/R_G3_Prepositions_EP02_Poster.png',
      altText: [
        {
          language: 'en',
          altText: 'Axis floats in space outside his spaceship.',
        },
        {
          language: 'es',
          altText: 'Axis flota en el espacio afuera de su nave espacial.',
        },
      ],
    },
    background_rightImage: {
      id: 'recxjC353VMav5ybu',
      url: '/helix/UX_Images/Start_Screen_Blobs/Nova-Scouts_space-sky.svg',
      altText: [
        { language: 'en', altText: 'Stars in space.' },
        { language: 'es', altText: 'Estrellas en el espacio.' },
      ],
    },
    foreground: {
      id: 'recJFZiQMxFozF9ks',
      url: '/helix/UX_Images/Start_Screen_Characters/Axis3.svg',
      altText: [
        { language: 'en', altText: 'Axis is pointing at something.' },
        { language: 'es', altText: 'Axis está apuntando a algo.' },
      ],
    },
  },
  populations: [
    {
      id: '33744.B.2.1',
      templateID: 0,
      complexity: 'Knowledge',
      hint: [
        {
          id: '33744.B.2.1.0',
          type: 'BasicSlide',
          data: {
            title: 'Prepositions are before certain parts of speech.\n',
          },
        },
        {
          id: '33744.B.2.1.1',
          type: 'BasicSlide',
          data: {
            title: 'Look at what is linked together.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Identify the preposition in the sentence.\n\n**Tom took a sip of his drink.**\n',
        },
        answerChoices: [
          {
            id: '33744.B.2.1_1',
            choice: {
              texts: [
                {
                  text: 'of\n',
                },
              ],
            },
          },
          {
            id: '33744.B.2.1_2',
            choice: {
              texts: [
                {
                  text: 'a\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: 'A is an article, not a preposition.\n',
              },
            },
          },
          {
            id: '33744.B.2.1_3',
            choice: {
              texts: [
                {
                  text: 'sip\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: 'Sip is a verb, and verbs can not be prepositions.\n',
              },
            },
          },
        ],
        answers: ['33744.B.2.1_1'],
        associatedSuccessCriteria: ['33744.B.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'The prepositional phrase is _of his drink_. _Of_ is the preposition, and _his drink_ is the object of the phrase.\n',
      },
    },
    {
      id: '33744.B.2.2',
      templateID: 0,
      complexity: 'Knowledge',
      hint: [
        {
          id: '33744.B.2.2.0',
          type: 'BasicSlide',
          data: {
            title: 'Prepositions are before certain parts of speech.\n',
          },
        },
        {
          id: '33744.B.2.2.1',
          type: 'BasicSlide',
          data: {
            title: 'Look at what is linked together.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Identify the preposition in the sentence.\n\n**We sailed on a boat this summer.**\n',
        },
        answerChoices: [
          {
            id: '33744.B.2.2_1',
            choice: {
              texts: [
                {
                  text: 'on\n',
                },
              ],
            },
          },
          {
            id: '33744.B.2.2_2',
            choice: {
              texts: [
                {
                  text: 'a\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: 'A is an article, not a preposition.\n',
              },
            },
          },
          {
            id: '33744.B.2.2_3',
            choice: {
              texts: [
                {
                  text: 'this\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: 'This is a pronoun, not a preposition.\n',
              },
            },
          },
        ],
        answers: ['33744.B.2.2_1'],
        associatedSuccessCriteria: ['33744.B.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'The prepositional phrase is _on a boat_. _On_ is the preposition, and _a boat_ is the object of the phrase.\n',
      },
    },
    {
      id: '33744.B.2.3',
      templateID: 0,
      complexity: 'Knowledge',
      hint: [
        {
          id: '33744.B.2.3.0',
          type: 'BasicSlide',
          data: {
            title: 'Prepositions are before certain parts of speech.\n',
          },
        },
        {
          id: '33744.B.2.3.1',
          type: 'BasicSlide',
          data: {
            title: 'Look at what is linked together.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Identify the preposition in the sentence.\n\n**My dog was hiding under the table.**\n',
        },
        answerChoices: [
          {
            id: '33744.B.2.3_1',
            choice: {
              texts: [
                {
                  text: 'under\n',
                },
              ],
            },
          },
          {
            id: '33744.B.2.3_2',
            choice: {
              texts: [
                {
                  text: 'was\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: { content: 'Was is a verb, not a preposition\n' },
            },
          },
          {
            id: '33744.B.2.3_3',
            choice: {
              texts: [
                {
                  text: 'the\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: 'This is just an article, not a preposition.\n',
              },
            },
          },
        ],
        answers: ['33744.B.2.3_1'],
        associatedSuccessCriteria: ['33744.B.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'The prepositional phrase is _under the table_. _Under_ is the preposition, and _the table_ is the object of the phrase.\n',
      },
    },
    {
      id: '33744.B.2.4',
      templateID: 0,
      complexity: 'Knowledge',
      hint: [
        {
          id: '33744.B.2.4.0',
          type: 'BasicSlide',
          data: {
            title: 'Try looking for the preposition first.\n',
          },
        },
        {
          id: '33744.B.2.4.1',
          type: 'BasicSlide',
          data: {
            title: 'Prepositional phrases add more information.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Identify the prepositional phrase in the sentence.\n\n**You must do everything on the list.**\n',
        },
        answerChoices: [
          {
            id: '33744.B.2.4_1',
            choice: {
              texts: [
                {
                  text: 'on the list\n',
                },
              ],
            },
          },
          {
            id: '33744.B.2.4_2',
            choice: {
              texts: [
                {
                  text: 'do everything\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Do is a verb, and a prepositional phrase begins with a preposition.\n',
              },
            },
          },
          {
            id: '33744.B.2.4_3',
            choice: {
              texts: [
                {
                  text: 'must do\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: 'Must is a verb, not a preposition.\n',
              },
            },
          },
        ],
        answers: ['33744.B.2.4_1'],
        associatedSuccessCriteria: ['33744.B.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'The prepositional phrase is _on the list_. _On_ is the preposition, and _the list_ is the object of the phrase.\n',
      },
    },
    {
      id: '33744.B.2.5',
      templateID: 0,
      complexity: 'Knowledge',
      hint: [
        {
          id: '33744.B.2.5.0',
          type: 'BasicSlide',
          data: {
            title: 'Try looking for the preposition first.\n',
          },
        },
        {
          id: '33744.B.2.5.1',
          type: 'BasicSlide',
          data: {
            title: 'Prepositional phrases add more information.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Identify the prepositional phrase in the sentence.\n\n**The lights in the room were bright.**\n',
        },
        answerChoices: [
          {
            id: '33744.B.2.5_1',
            choice: {
              texts: [
                {
                  text: 'in the room\n',
                },
              ],
            },
          },
          {
            id: '33744.B.2.5_2',
            choice: {
              texts: [
                {
                  text: 'were bright\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Were is a verb, and a prepositional phrase begins with a preposition.\n',
              },
            },
          },
          {
            id: '33744.B.2.5_3',
            choice: {
              texts: [
                {
                  text: 'the room\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'We need to add a preposition to make this a prepositional phrase.\n',
              },
            },
          },
        ],
        answers: ['33744.B.2.5_1'],
        associatedSuccessCriteria: ['33744.B.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'The prepositional phrase is _in the room_. _In_ is the preposition, and _the room_ is the object of the phrase.\n',
      },
    },
    {
      id: '33744.B.2.6',
      templateID: 0,
      complexity: 'Knowledge',
      hint: [
        {
          id: '33744.B.2.6.0',
          type: 'BasicSlide',
          data: {
            title: 'Try looking for the preposition first.\n',
          },
        },
        {
          id: '33744.B.2.6.1',
          type: 'BasicSlide',
          data: {
            title: 'Prepositional phrases add more information.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Identify the prepositional phrase in the sentence.\n\n**The main character lived on an island.**\n',
        },
        answerChoices: [
          {
            id: '33744.B.2.6_1',
            choice: {
              texts: [
                {
                  text: 'on an island\n',
                },
              ],
            },
          },
          {
            id: '33744.B.2.6_2',
            choice: {
              texts: [
                {
                  text: 'lived on\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Lived is a verb, and a prepositional phrase begins with a preposition.\n',
              },
            },
          },
          {
            id: '33744.B.2.6_3',
            choice: {
              texts: [
                {
                  text: 'an island\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  "Close! But it looks like we're missing a preposition.\n",
              },
            },
          },
        ],
        answers: ['33744.B.2.6_1'],
        associatedSuccessCriteria: ['33744.B.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'The prepositional phrase is _on an island_. _On_ is the preposition, and _the island_ is the object of the phrase.\n',
      },
    },
    {
      id: '33744.B.1.7',
      templateID: 5,
      complexity: 'Knowledge',
      hint: [
        {
          id: '33744.B.1.7.0',
          type: 'BasicSlide',
          data: {
            title: 'Prepositions are before certain parts of speech.\n',
          },
        },
        {
          id: '33744.B.1.7.1',
          type: 'BasicSlide',
          data: {
            title: 'Try creating a full phrase with the word.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Which of the following words are prepositions? Choose 3.\n',
        },
        answerChoices: [
          {
            id: '33744.B.1.7_1',
            choice: {
              texts: [
                {
                  text: 'above\n',
                },
              ],
            },
          },
          {
            id: '33744.B.1.7_2',
            choice: {
              texts: [
                {
                  text: 'through\n',
                },
              ],
            },
          },
          {
            id: '33744.B.1.7_3',
            choice: {
              texts: [
                {
                  text: 'with\n',
                },
              ],
            },
          },
          {
            id: '33744.B.1.7_4',
            choice: {
              texts: [
                {
                  text: 'how\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: 'How can be many things but not a preposition.\n',
              },
            },
          },
          {
            id: '33744.B.1.7_5',
            choice: {
              texts: [
                {
                  text: 'was\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: 'Was is a verb, and verbs cannot be prepositions.\n',
              },
            },
          },
          {
            id: '33744.B.1.7_6',
            choice: {
              texts: [
                {
                  text: 'being\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: { content: 'This is a verb.\n' },
            },
          },
        ],
        answers: ['33744.B.1.7_1', '33744.B.1.7_2', '33744.B.1.7_3'],
        associatedSuccessCriteria: ['33744.B.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'These words are all prepositions and begin a prepositional phrase.\n',
      },
    },
    {
      id: '33744.B.1.8',
      templateID: 5,
      complexity: 'Knowledge',
      hint: [
        {
          id: '33744.B.1.8.0',
          type: 'BasicSlide',
          data: {
            title: 'Prepositions are before certain parts of speech.\n',
          },
        },
        {
          id: '33744.B.1.8.1',
          type: 'BasicSlide',
          data: {
            title: 'Try creating a full phrase with the word.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Which of the following words are prepositions?\nChoose 3.\n',
        },
        answerChoices: [
          {
            id: '33744.B.1.8_1',
            choice: {
              texts: [
                {
                  text: 'at\n',
                },
              ],
            },
          },
          {
            id: '33744.B.1.8_2',
            choice: {
              texts: [
                {
                  text: 'for\n',
                },
              ],
            },
          },
          {
            id: '33744.B.1.8_3',
            choice: {
              texts: [
                {
                  text: 'by\n',
                },
              ],
            },
          },
          {
            id: '33744.B.1.8_4',
            choice: {
              texts: [
                {
                  text: 'find\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: { content: 'Find is a verb.\n' },
            },
          },
          {
            id: '33744.B.1.8_5',
            choice: {
              texts: [
                {
                  text: 'want\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: 'Want is a verb, and verbs can not be prepositions.\n',
              },
            },
          },
          {
            id: '33744.B.1.8_6',
            choice: {
              texts: [
                {
                  text: 'many\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: 'Many is an adjective, not a preposition.\n',
              },
            },
          },
        ],
        answers: ['33744.B.1.8_1', '33744.B.1.8_2', '33744.B.1.8_3'],
        associatedSuccessCriteria: ['33744.B.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'These words are all prepositions and begin a prepositional phrase.\n',
      },
    },
    {
      id: '33744.B.1.9',
      templateID: 5,
      complexity: 'Knowledge',
      hint: [
        {
          id: '33744.B.1.9.0',
          type: 'BasicSlide',
          data: {
            title: 'Try looking for the preposition first.\n',
          },
        },
        {
          id: '33744.B.1.9.1',
          type: 'BasicSlide',
          data: {
            title: 'Prepositional phrases add more information.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Which of the following words are prepositional phrases? Choose 3.\n',
        },
        answerChoices: [
          {
            id: '33744.B.1.9_1',
            choice: {
              texts: [
                {
                  text: 'across the street\n',
                },
              ],
            },
          },
          {
            id: '33744.B.1.9_2',
            choice: {
              texts: [
                {
                  text: 'inside the box\n',
                },
              ],
            },
          },
          {
            id: '33744.B.1.9_3',
            choice: {
              texts: [
                {
                  text: 'in a hurry\n',
                },
              ],
            },
          },
          {
            id: '33744.B.1.9_4',
            choice: {
              texts: [
                {
                  text: 'eating lunch\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  "This is a verb phrase, and prepositional phrases don't start with verbs.\n",
              },
            },
          },
          {
            id: '33744.B.1.9_5',
            choice: {
              texts: [
                {
                  text: 'rushed by\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'This cannot be a prepositional phrase because it starts with a verb.\n',
              },
            },
          },
          {
            id: '33744.B.1.9_6',
            choice: {
              texts: [
                {
                  text: 'the stairs\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  "I see a noun, but it looks like we're missing a preposition.\n",
              },
            },
          },
        ],
        answers: ['33744.B.1.9_1', '33744.B.1.9_2', '33744.B.1.9_3'],
        associatedSuccessCriteria: ['33744.B.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'These phrases start with a preposition and end with a noun.\n',
      },
    },
    {
      id: '33744.B.1.10',
      templateID: 5,
      complexity: 'Knowledge',
      hint: [
        {
          id: '33744.B.1.10.0',
          type: 'BasicSlide',
          data: {
            title: 'Try looking for the preposition first.\n',
          },
        },
        {
          id: '33744.B.1.10.1',
          type: 'BasicSlide',
          data: {
            title: 'Prepositional phrases add more information.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Which of the following words are prepositional phrases? Choose 3.\n',
        },
        answerChoices: [
          {
            id: '33744.B.1.10_1',
            choice: {
              texts: [
                {
                  text: 'at night\n',
                },
              ],
            },
          },
          {
            id: '33744.B.1.10_2',
            choice: {
              texts: [
                {
                  text: 'for a reason\n',
                },
              ],
            },
          },
          {
            id: '33744.B.1.10_3',
            choice: {
              texts: [
                {
                  text: 'on the road\n',
                },
              ],
            },
          },
          {
            id: '33744.B.1.10_4',
            choice: {
              texts: [
                {
                  text: 'short and sweet\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  "Short and sweet are just two adjectives, and they don't make a prepositional phrase.\n",
              },
            },
          },
          {
            id: '33744.B.1.10_5',
            choice: {
              texts: [
                {
                  text: 'work hard\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  "This starts with a verb, so it can't be a prepositional phrase.\n",
              },
            },
          },
          {
            id: '33744.B.1.10_6',
            choice: {
              texts: [
                {
                  text: 'write well\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: "Let's try this again. Write is a verb.\n",
              },
            },
          },
        ],
        answers: ['33744.B.1.10_1', '33744.B.1.10_2', '33744.B.1.10_3'],
        associatedSuccessCriteria: ['33744.B.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'These phrases start with a preposition and end with a noun.\n',
      },
    },
  ],
  activityManifest: [
    {
      type: 'START_SCREEN',
    },
    {
      type: 'ENTRY_SURVEY',
    },
    {
      type: 'VIDEO',
      id: 'recdiMNqL34JyVSCM',
    },
    {
      type: 'QUESTION',
      id: '33744.B.2.1',
    },
    {
      type: 'QUESTION',
      id: '33744.B.2.2',
    },
    {
      type: 'QUESTION',
      id: '33744.B.2.3',
    },
    {
      type: 'QUESTION',
      id: '33744.B.2.4',
    },
    {
      type: 'QUESTION',
      id: '33744.B.2.5',
    },
    {
      type: 'QUESTION',
      id: '33744.B.2.6',
    },
    {
      type: 'QUESTION',
      id: '33744.B.1.7',
    },
    {
      type: 'QUESTION',
      id: '33744.B.1.8',
    },
    {
      type: 'QUESTION',
      id: '33744.B.1.9',
    },
    {
      type: 'QUESTION',
      id: '33744.B.1.10',
    },
    {
      type: 'EXIT_SURVEY',
    },
    {
      type: 'RESULTS_SCREEN',
    },
  ],
  version: '1562962166',
  nextUp: {
    nextActivityId: '018ea00f-3cba-73c4-a05e-c70a802dc69f',
    nextActivityName: "Surf's Up, Ship's Down",
    nextActivityThumbnail: {
      id: 'recxcEc5etdqMvvWy',
      url: '/helix/Reading/Grade_3/Prepositions/Episode_03/R_G3_Prepositions_EP03_Poster.png',
      altText: [
        { language: 'en', altText: 'Axis and CAT stand on a beach.' },
        { language: 'es', altText: 'Axis y G.A.T.A. estan en una playa.' },
      ],
    },
  },
  instructionsImage: {
    id: 'recShqii4tO2JCQyf',
    url: '/helix/UX_Images/Characters/Axis/Axis_Chibi_Head_Happy_Mouth_Open.svg',
    altText: [
      { language: 'en', altText: 'Axis is smiling.' },
      { language: 'es', altText: 'Axis está sonriendo.' },
    ],
  },
  characterLeanout: {
    id: 'svvjeewfjhHFEFgef',
    url: '/helix/TEI/Leanouts/Estrella_Leanout_01.svg',
    altText: [
      {
        language: 'en',
        altText: 'Estrella leans out from the side of the screen.',
      },
      {
        language: 'es',
        altText: 'Estrella se asoma por un lado de la pantalla.',
      },
    ],
  },
};
