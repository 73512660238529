/**
 * Types of audio resources
 * @enum {number}
 */
export enum AudioType {
  audio_Undefined,
  audio_SfxClick,
  audio_SfxAnim,
  audio_SfxAnswer,
}
