import {
  Component,
  ContentChild,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'htc-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnInit {
  @ContentChild('sect', { static: true })
  sectionTemplate!: TemplateRef<unknown>;

  @Input() accordionSections!: string[];

  sectionsExpanded: boolean[] = [];

  constructor() {}

  ngOnInit(): void {
    for (let i = 0; i < this.accordionSections.length; i++) {
      this.sectionsExpanded.push(false);
    }
  }

  onAccordionToggle(section: number): void {
    this.sectionsExpanded[section] = !this.sectionsExpanded[section];
  }
}
