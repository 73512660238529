import { KnowledgeBaseAPI } from '../../../olp-api/classes/knowledge-base/knowledge-base-api';
import { IncompleteActivityState } from '../../interfaces/incomplete-activity-state';

export class ActivityStateManager {
  readonly activityLocalStorage = 'ActivityStorage';
  readonly activityKBStorage = 'HTMLActivityStorage';
  readonly incompleteActivitiesStorage = 'IncompleteActivities';
  readonly activityKBIds = 'Ids';
  readonly activityIdLocalStorage = 'SavedActivityIds';
  readonly activityDelimeter = ',';

  useKb = false;
  kbClient?: KnowledgeBaseAPI;

  storagePrefix = '';

  /**
   *
   * @param kbClient the KnowledgeBaseAPI class to use to interface with kb,
   *                 pass nothing||undefined if we want to use localStorage
   */
  constructor(kbClient?: KnowledgeBaseAPI) {
    this.useKb = !!kbClient;
    if (kbClient) {
      this.kbClient = kbClient;
    }
  }

  /**
   * Save the activity state, and ensure the id is in the savedActivity list, then syncs to the kb if we're using kb.
   * @param activityState the activity state to save
   * @returns Promise which resolves when kb has synced, or immediately if using localStorage
   */
  async saveIncompleteActivity(
    activityState: IncompleteActivityState
  ): Promise<void> {
    this.saveIncompleteActivityState(activityState);
    await this.addSavedActivityId(activityState.id);
    if (this.useKb) {
      return this.kbClient?.sync();
    } else {
      return Promise.resolve();
    }
  }

  /**
   *
   * @param activityState The activity id which we're trying to remove from the saved dictionary
   * @returns
   */
  async removeIncompleteActivity(activityId: string): Promise<void> {
    this.removeIncompleteActivityState(activityId);
    await this.removeSavedActivityId(activityId);
    if (this.useKb) {
      return this.kbClient?.sync();
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Saves the given activity state to knowledge base if useKB flag is enabled, otherwise saves to local storage
   * @param activityState activity state to save
   */
  saveIncompleteActivityState(activityState: IncompleteActivityState): void {
    if (this.useKb) {
      this.kbClient
        ?.get(this.activityKBStorage, this.incompleteActivitiesStorage)
        .then(incompleteActivities => {
          if (
            incompleteActivities === null ||
            incompleteActivities === undefined
          ) {
            incompleteActivities = {};
          }
          Object.assign(incompleteActivities, {
            [activityState.id]: JSON.stringify(activityState),
          });
          this.kbClient?.set(
            this.activityKBStorage,
            this.incompleteActivitiesStorage,
            incompleteActivities
          );
        });
    } else {
      localStorage.setItem(
        `${this.storagePrefix}${this.activityLocalStorage}_${activityState.id}`,
        JSON.stringify(activityState)
      );
    }
  }

  async removeIncompleteActivityState(activityId: string): Promise<void> {
    if (this.useKb) {
      return this.kbClient
        ?.get(this.activityKBStorage, this.incompleteActivitiesStorage)
        .then(incompleteActivities => {
          if (incompleteActivities) {
            const incompleteRecord = incompleteActivities as Record<
              string,
              IncompleteActivityState
            >;
            if (incompleteRecord[activityId]) {
              delete incompleteRecord[activityId];
              this.kbClient?.set(
                this.activityKBStorage,
                this.incompleteActivitiesStorage,
                incompleteActivities
              );
            } else {
              console.warn(
                `couldn't find incomplete activity with id ${activityId} to remove`
              );
            }
          }
        });
    } else {
      localStorage.removeItem(
        `${this.storagePrefix}${this.activityLocalStorage}_${activityId}`
      );
    }
  }

  /**
   * Add activityId to the saved id list if it's not there already
   * @param activityId ID of the activity we're saving
   */
  async addSavedActivityId(activityId: string): Promise<void> {
    const savedIds = await this.getSavedActivityIds();
    const newIds = this.appendSavedActivityId(activityId, savedIds);

    this.setSavedActivityIds(newIds);
  }

  async removeSavedActivityId(activityId: string): Promise<void> {
    const savedIds = await this.getSavedActivityIds();
    const newIds = this.removeActivityId(activityId, savedIds);

    this.setSavedActivityIds(newIds);
  }

  setSavedActivityIds(newIds: string[]): void {
    if (this.useKb) {
      this.kbClient?.set(
        this.activityKBStorage,
        this.activityKBIds,
        newIds.join(this.activityDelimeter)
      );
    } else {
      localStorage.setItem(
        `${this.storagePrefix}${this.activityIdLocalStorage}`,
        newIds.join(this.activityDelimeter)
      );
    }
  }

  appendSavedActivityId(activityId: string, existingIds: string[]): string[] {
    return [...new Set([...existingIds, activityId])];
  }

  removeActivityId(activityId: string, existingIds: string[]): string[] {
    return existingIds.filter(id => id !== activityId);
  }

  /**
   * Export the current activity's state
   * @returns IncompleteActivityState representing current activity state
   */
  /**
   * Get string array of activity id's we have activity states saved for.
   * @returns String array representing all id's we have activity states saved for
   */
  async getSavedActivityIds(): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let ids = '';
      if (this.useKb) {
        this.kbClient
          ?.get(this.activityKBStorage, this.activityKBIds)
          .then(retrievedIds => {
            if (retrievedIds) {
              resolve(
                (retrievedIds as string)
                  .split(this.activityDelimeter)
                  .filter(id => id !== '')
              );
            } else {
              resolve([]);
            }
          })
          .catch(e => {
            console.error('error fetching saved activity ids', e);
            reject(e);
          });
      } else {
        ids =
          localStorage.getItem(
            `${this.storagePrefix}${this.activityIdLocalStorage}`
          ) ?? '';
        resolve(ids.split(this.activityDelimeter).filter(id => id !== ''));
      }
    });
  }

  /**
   * Gets the saved state data from the passed in activity Id if it exists
   * @param activityId The id of the activity we want to get the saved state for
   * @returns IncompleteActivityState if it exists, undefined if it doesn't
   */
  async getSavedActivityState(
    activityId: string
  ): Promise<IncompleteActivityState> {
    return new Promise((resolve, reject) => {
      if (this.useKb) {
        this.kbClient
          ?.get(this.activityKBStorage, this.incompleteActivitiesStorage)
          .then(activityStorage => {
            const activityStorageRecord = activityStorage as Record<
              string,
              string
            >;
            if (activityStorageRecord && activityStorageRecord[activityId]) {
              resolve(JSON.parse(activityStorageRecord[activityId]));
            } else {
              reject('activity state not found');
            }
          })
          .catch(e => {
            console.error('error fetching saved activity state', e);
            reject(e);
          });
      } else {
        const localActivity = localStorage.getItem(
          `${this.storagePrefix}${this.activityLocalStorage}_${activityId}`
        );
        if (localActivity) {
          resolve(JSON.parse(localActivity));
        } else {
          reject('activity state not found');
        }
      }
    });
  }

  /**
   * Gets an array of all saved activity states
   * @returns Array of IncompleteActivityState objects for all saved states
   */
  async getSavedActivityStates(): Promise<IncompleteActivityState[]> {
    const activities = [] as IncompleteActivityState[];
    const ids = await this.getSavedActivityIds();
    await Promise.all(
      ids.map(async id => {
        await this.getSavedActivityState(id).then(activity => {
          activities.push(activity);
        });
      })
    );

    return activities;
  }
}
