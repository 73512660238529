<htc-dialog
  [useBackground]="false"
  [useWideMode]="true"
  theme="chart-dialog"
  (showPopupChange)="onPopupChange()"
  data-testid="chart-popup">
  <ngx-skeleton-loader
    [hidden]="loaded"
    appearance="line"
    [count]="10"
    animation="pulse"
    [ngStyle]="{ width: '40rem', height: '20rem' }"
    [theme]="{
      width: '40rem',
      height: '1rem',
      'border-radius': '0.3125rem'
    }"
    data-testid="htc-multi-choice-image-skeleton"></ngx-skeleton-loader>
  <htc-chart
    [data]="chart.data"
    [isDataUrl]="chart.isUrl"
    (chartLoaded)="onChartLoaded()"></htc-chart>
</htc-dialog>
