import { SlideTypeEnum } from '../modules/carousel/enums/slide-type';
import { ISlideData } from '../modules/carousel/interfaces';
import { CHART_TYPES, charts } from './charts';
import { TEST_IMAGE_TYPES, images } from './images';

export enum TEST_HINT_TYPES {
  SINGLE_SHORT,
  SINGLE_MEDIUM,
  SINGLE_LONG,
  DOUBLE_MEDIUM,
  MULTIPLE_MEDIUM,
  ES_SINGLE_SHORT,
  ES_SINGLE_MEDIUM,
  ES_SINGLE_LONG,
  ES_DOUBLE_MEDIUM,
  ES_MULTIPLE_MEDIUM,
}

export const chartHints: { [key: number]: ISlideData[] } = {
  [TEST_HINT_TYPES.SINGLE_SHORT]: [
    {
      id: '0',
      type: SlideTypeEnum.chartSlide,
      data: {
        title: 'Chart hint here',
        chartData: charts[CHART_TYPES.SAMPLE_WITH_TITLE],
      },
    },
  ],
  [TEST_HINT_TYPES.SINGLE_MEDIUM]: [
    {
      id: '0',
      type: SlideTypeEnum.chartSlide,
      data: {
        title:
          'Chart hint here chart hint here chart hint here chart hint here',
        chartData: charts[CHART_TYPES.SAMPLE_WITHOUT_TITLE],
      },
    },
  ],
  [TEST_HINT_TYPES.SINGLE_LONG]: [
    {
      id: '0',
      type: SlideTypeEnum.chartSlide,
      data: {
        title:
          'Chart hint here Chart hint here Chart hint here Chart hint here Chart hint here Chart hint here Chart hint here',
        chartData: charts[CHART_TYPES.SAMPLE_WITHOUT_TITLE],
      },
    },
  ],
  [TEST_HINT_TYPES.DOUBLE_MEDIUM]: [
    {
      id: '0',
      type: SlideTypeEnum.chartSlide,
      data: {
        title:
          'Chart hint here chart hint here chart hint here chart hint here',
        chartData: charts[CHART_TYPES.SAMPLE_WITHOUT_TITLE],
      },
    },
    {
      id: '1',
      type: SlideTypeEnum.chartSlide,
      data: {
        title: 'Chart hint here',
        chartData: charts[CHART_TYPES.SAMPLE_WITHOUT_TITLE],
      },
    },
  ],
  [TEST_HINT_TYPES.ES_SINGLE_SHORT]: [
    {
      id: '0',
      type: SlideTypeEnum.chartSlide,
      data: {
        title: 'Yo hablo Español',
        chartData: charts[CHART_TYPES.EXPERIMENTAL],
      },
    },
  ],
  [TEST_HINT_TYPES.ES_SINGLE_MEDIUM]: [
    {
      id: '0',
      type: SlideTypeEnum.chartSlide,
      data: {
        title: 'Yo hablo Español Yo hablo Español Yo hablo Español',
        chartData: charts[CHART_TYPES.EXPERIMENTAL],
      },
    },
  ],
};

export const hints: { [key: number]: ISlideData[] } = {
  [TEST_HINT_TYPES.SINGLE_SHORT]: [
    {
      id: '0',
      type: SlideTypeEnum.basicSlide,
      data: {
        title: 'Insert first hint here (1)',
      },
    },
  ],
  [TEST_HINT_TYPES.SINGLE_MEDIUM]: [
    {
      id: '0',
      type: SlideTypeEnum.basicSlide,
      data: {
        title:
          'Insert first hint here (1) Insert first hint here (1) Insert first hint here (1)',
      },
    },
  ],
  [TEST_HINT_TYPES.SINGLE_LONG]: [
    {
      id: '0',
      type: SlideTypeEnum.basicSlide,
      data: {
        title:
          'Insert first hint here (1) Insert first hint here (1) Insert first hint here (1) Insert first hint here (1) Insert first hint here (1) Insert first hint here (1)',
      },
    },
  ],
  [TEST_HINT_TYPES.DOUBLE_MEDIUM]: [
    {
      id: '0',
      type: SlideTypeEnum.basicSlide,
      data: {
        title: 'Insert first hint here (1)',
        imgData: images[TEST_IMAGE_TYPES.CAT_BESIDE_TABLE],
      },
    },
    {
      id: '1',
      type: SlideTypeEnum.basicSlide,
      data: {
        title:
          'Insert first hint here (1) Insert first hint here (1) Insert first hint here (1) Insert first hint here (1) Insert first hint here (1) Insert first hint here (1)',
      },
    },
  ],
  [TEST_HINT_TYPES.MULTIPLE_MEDIUM]: [
    {
      id: '0',
      type: SlideTypeEnum.basicSlide,
      data: {
        title: 'Insert first hint here (1)',
        imgData: images[TEST_IMAGE_TYPES.CAT_BESIDE_TABLE],
      },
    },
    {
      id: '1',
      type: SlideTypeEnum.basicSlide,
      data: {
        title: 'Insert first hint here (2)',
        imgData: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
      },
    },
    {
      id: '2',
      type: SlideTypeEnum.basicSlide,
      data: {
        title:
          'Insert first hint here (1) Insert first hint here (1) Insert first hint here (1) Insert first hint here (1) Insert first hint here (1) Insert first hint here (1)',
      },
    },
  ],
  [TEST_HINT_TYPES.ES_SINGLE_SHORT]: [
    {
      id: '0',
      type: SlideTypeEnum.basicSlide,
      data: {
        title: 'Inserte la primera pista aquí (1)',
      },
    },
  ],
  [TEST_HINT_TYPES.ES_SINGLE_MEDIUM]: [
    {
      id: '0',
      type: SlideTypeEnum.basicSlide,
      data: {
        title:
          'Inserte la primera pista aquí (1) Inserte la primera pista aquí (1) Inserte la primera pista aquí (1)',
      },
    },
  ],
  [TEST_HINT_TYPES.ES_SINGLE_LONG]: [
    {
      id: '0',
      type: SlideTypeEnum.basicSlide,
      data: {
        title:
          'Inserte la primera pista aquí (1) Inserte la primera pista aquí (1) Inserte la primera pista aquí (1) Inserte la primera pista aquí (1) Inserte la primera pista aquí (1) Inserte la primera pista aquí (1)',
      },
    },
  ],
  [TEST_HINT_TYPES.ES_DOUBLE_MEDIUM]: [
    {
      id: '0',
      type: SlideTypeEnum.basicSlide,
      data: {
        title: 'Inserte la primera pista aquí (1)',
        imgData: images[TEST_IMAGE_TYPES.CAT_BESIDE_TABLE],
      },
    },
    {
      id: '1',
      type: SlideTypeEnum.basicSlide,
      data: {
        title:
          'Inserte la primera pista aquí (1) Inserte la primera pista aquí (1) Inserte la primera pista aquí (1) Inserte la primera pista aquí (1) Inserte la primera pista aquí (1) Inserte la primera pista aquí (1)',
      },
    },
  ],
  [TEST_HINT_TYPES.ES_MULTIPLE_MEDIUM]: [
    {
      id: '0',
      type: SlideTypeEnum.basicSlide,
      data: {
        title: 'Inserte la primera pista aquí (1)',
        imgData: images[TEST_IMAGE_TYPES.CAT_BESIDE_TABLE],
      },
    },
    {
      id: '1',
      type: SlideTypeEnum.basicSlide,
      data: {
        title: 'Inserte la primera pista aquí (2)',
        imgData: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
      },
    },
    {
      id: '2',
      type: SlideTypeEnum.basicSlide,
      data: {
        title:
          'Inserte la primera pista aquí (1) Inserte la primera pista aquí (1) Inserte la primera pista aquí (1) Inserte la primera pista aquí (1) Inserte la primera pista aquí (1) Inserte la primera pista aquí (1)',
      },
    },
  ],
};
