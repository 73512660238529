import { Injectable, ProviderToken, inject } from '@angular/core';
import { AssignmentAPI } from '../../classes/assignment-api/assignment-api';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { MessageService } from 'src/app/modules/message/services/message/message.service';
import { MessageCodes } from 'src/app/shared/enums';
import { TimeElapsedService } from 'src/app/shared/services';
import { ResultsService } from 'src/app/shared/services/results/results.service';
import { ActivityStatus } from 'src/app/shared/enums/activity-status';
import { AuthSessionService } from 'src/app/modules/auth/services/auth-session/auth-session.service';
import { FeatureFlagService } from 'src/app/modules/feature-flags/services/feature-flag/feature-flag.service';
import { FeatureFlags } from 'src/app/shared/enums/feature-flags';

const getAssignmentFacade = (): AssignmentAPI => {
  const injectionPoint: ProviderToken<AssignmentAPI> = AssignmentAPI;
  const facade = inject(injectionPoint);
  return facade;
};

@Injectable({
  providedIn: 'root',
})
export class AssignmentService {
  public assignment = getAssignmentFacade();

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private messageService: MessageService,
    private timeElapsedService: TimeElapsedService,
    private resultService: ResultsService,
    private authSessionService: AuthSessionService,
    private featureFlagsService: FeatureFlagService
  ) {}

  async checkForAccessToken(): Promise<boolean> {
    if (this.assignment.authToken) {
      return true;
    }
    this.oidcSecurityService.getAccessToken().subscribe({
      next: token => {
        if (token) {
          this.assignment.setAuthToken(token);
        }
      },
      error: err => {
        console.error('We had issues getting an access token', err);
        this.messageService.showMessage(MessageCodes.HTTP_AUTH_REQUIRED);
      },
    });
    return true;
  }

  startSelfSelectedAssignment(id: number): void {
    if (!this.authSessionService.hasRole('student')) {
      console.warn(
        'Warning! Cannot start a self-selected, because current user is not a student'
      );
      return;
    }
    if (
      !this.featureFlagsService.isFlagEnabled(FeatureFlags.USE_ASSIGNMENT_API)
    ) {
      console.warn(
        'Warning! Cannot use the Assignment API with the feature enabled'
      );
      return;
    }
    if (id < 0) {
      console.error(
        'AssignmentService - create an assignment:: Invalid ID for the self-selected assignment',
        id
      );
      return;
    }

    this.assignment
      .createNewRecord([{ contentId: id }])
      .then(res => {
        if (res) {
          console.log('SAVE TO KB', res);
          this.updateAssignmentRecord(id, ActivityStatus.IN_PROGRESS);
        } else {
          this.messageService.showMessage(MessageCodes.HTTP_FORBIDDEN);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  updateAssignmentRecord(id: number, status: string): void {
    if (!this.authSessionService.hasRole('student')) {
      console.warn(
        'Warning! Cannot update an assignment, because current user is not a student'
      );
      return;
    }
    if (
      !this.featureFlagsService.isFlagEnabled(FeatureFlags.USE_ASSIGNMENT_API)
    ) {
      console.warn(
        'Warning! Cannot use the Assignment API with the feature enabled'
      );
      return;
    }
    if (id < 0) {
      console.error(
        'AssignmentService - update an assignment:: Invalid ID for the self-selected assignment',
        id
      );
      return;
    }

    const record = {
      id,
      status,
      numQuestionsCorrect: this.resultService.numCorrect,
      numQuestionsIncorrect: this.resultService.numIncorrect,
      score:
        status === ActivityStatus.IN_PROGRESS
          ? 0
          : this.resultService.getPercentage(),
      duration: this.timeElapsedService.getTimeAsSeconds(),
    };
    this.assignment.updateRecord([record]);
  }
}
