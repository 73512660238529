import videojs from 'video.js';

export class ControlBarDefaultConfig implements videojs.ControlBarOptions {
  playToggle = true;
  currentTimeDisplay = true;
  durationDisplay = true;
  remainingTimeDisplay = false;
  timeDivider = true;
  fullscreenToggle = false;
  pictureInPictureToggle = false;
  children = [
    'playToggle',
    'jumpToStart',
    'volumePanel',
    'spacer',
    'jumpBack',
    'currentTimeDisplay',
    'timeDivider',
    'durationDisplay',
    {
      name: 'progressControl',
      children: [
        {
          name: 'seekBar',
          children: ['loadProgressBar', 'watchTime', 'mouseTimeDisplay'],
        },
      ],
    },
    'jumpAhead',
    'spacer',
    'closedCaptionsMenuButton',
    'transcriptToggle',
    'settingsMenuButton',
    'customFullscreenToggle',
  ];
  volumePanel = {
    inline: true,
  };
}
