<div class="toolbar" [ngClass]="{ 'toolbar-anim-out': animOut }">
  <htc-popup-toolbar-button
    *ngFor="let tool of tools"
    [id]="tool.data.id"
    [toolName]="tool.data.toolName"
    [icon]="toolsService.iconMap.get(tool.data.id) ?? tool.data.icon"
    [toggledIcon]="tool.data.toggledIcon"
    htcAudioClick
    [audioType]="clickType"
    [descriptor]="clickDescriptor"
    [testId]="tool.data.testId"></htc-popup-toolbar-button>
  <htc-read-aloud-toggle></htc-read-aloud-toggle>
</div>
