import { Activity } from 'src/app/shared/interfaces';

export const reading1: Activity = {
  id: '018ea00f-3c27-7969-93cd-602343a6031c',
  product: 'Reading',
  language: 'en',
  skillName: 'Prepositions',
  thumbnail: {
    id: 'recSkH6hmMMvnBbyL',
    url: '/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png',
    altText: [
      {
        language: 'en',
        altText:
          'Axis has a video call with Estrella in the cockpit of his ship.',
      },
      {
        language: 'es',
        altText:
          'Axis tiene una videollamada con Estrella en la cabina de su nave.',
      },
    ],
  },
  description:
    'Nova Scout Axis and his trusty AI sidekick, C.A.T., are about to start their Earthen merit badges. They must master the use of prepositions from the human language of English.',
  domain: {
    text: 'Parts of Speech',
  },
  seriesName: "Axis's Big Break",
  episodeNumber: '1',
  episodeTitle: 'From Planets to Stars',
  videos: [
    {
      id: 'recilDqhv5pV5Y8fu',
      url: '/helix/Reading/Grade_3/Prepositions/Episode_01/hls_streams/R_G3_Prepositions_Ep01_Video01.m3u8',
      posterURL:
        '/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Poster.png',
      captions: [
        {
          src: '/helix/Reading/Grade_3/Prepositions/Episode_01/R_G3_Prepositions_Ep01_Video01.vtt',
          srclang: 'en',
          mode: 'hidden',
          kind: 'captions',
        },
      ],
    },
  ],
  startScreenData: {
    learningTarget: [
      {
        id: '33744.A',
        learningTarget: {
          text: 'what a preposition and prepositional phrase is',
        },
        successCriteria: [
          {
            id: '33744.A.1',
            text: 'define a preposition',
          },
          {
            id: '33744.A.2',
            text: 'define a prepositional phrase',
          },
          {
            id: '33744.A.3',
            text: 'use objects or words to show/tell what a preposition does',
          },
        ],
      },
    ],
    background_leftImage: {
      id: 'recogPPnqdeKi1m58',
      url: '/helix/UX_Images/Start_Screen_Blobs/Prepositions_G3V1_Poster.jpg',
      altText: [
        {
          language: 'en',
          altText:
            'Axis has a video call with Estrella in the cockpit of his ship.',
        },
        {
          language: 'es',
          altText:
            'Axis tiene una videollamada con Estrella en la cabina de su nave.',
        },
      ],
    },
    background_rightImage: {
      id: 'recxjC353VMav5ybu',
      url: '/helix/UX_Images/Start_Screen_Blobs/Nova-Scouts_space-sky.svg',
      altText: [
        { language: 'en', altText: 'Stars in space.' },
        { language: 'es', altText: 'Estrellas en el espacio.' },
      ],
    },
    foreground: {
      id: 'recnQirQF1onHfeSJ',
      url: '/helix/UX_Images/Start_Screen_Characters/Estrella5.svg',
      altText: [
        { language: 'en', altText: 'Estrella is waving.' },
        { language: 'es', altText: 'Estrella está saludando.' },
      ],
    },
  },
  populations: [
    {
      id: '33744.A.1.1',
      templateID: 0,
      complexity: 'Knowledge',
      hint: [
        {
          id: '33744.A.1.1.0',
          type: 'BasicSlide',
          data: {
            title: 'Think about what prepositions link together.\n',
          },
        },
        {
          id: '33744.A.1.1.1',
          type: 'BasicSlide',
          data: {
            title:
              'Only certain parts of speech are used with a preposition.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'What is the correct definition of a preposition?\n',
        },
        answerChoices: [
          {
            id: '33744.A.1.1_1',
            choice: {
              texts: [
                {
                  text: 'a word that links nouns, pronouns, or phrases to other words in a sentence\n',
                },
              ],
            },
          },
          {
            id: '33744.A.1.1_2',
            choice: {
              texts: [
                {
                  text: 'a word that links nouns, verbs, or pronouns to other words in a sentence\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Not all parts of speech are linked with a preposition.\n',
              },
            },
          },
          {
            id: '33744.A.1.1_3',
            choice: {
              texts: [
                {
                  text: 'a word that links words, phrases, or clauses to other words in a sentence\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Take another look at what prepositions link together.\n',
              },
            },
          },
        ],
        answers: ['33744.A.1.1_1'],
        associatedSuccessCriteria: ['33744.A.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'Prepositions link nouns, pronouns, or phrases to other words in a sentence.\n',
      },
    },
    {
      id: '33744.A.2.2',
      templateID: 0,
      complexity: 'Knowledge',
      hint: [
        {
          id: '33744.A.2.2.0',
          type: 'BasicSlide',
          data: {
            title: 'Think about what makes a prepositional phrase.\n',
          },
        },
        {
          id: '33744.A.2.2.1',
          type: 'BasicSlide',
          data: {
            title:
              'Prepositional phrases start and end with certain parts of speech.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'What is the correct definition of a prepositional phrase?\n',
        },
        answerChoices: [
          {
            id: '33744.A.2.2_1',
            choice: {
              texts: [
                {
                  text: 'a phrase that begins with a preposition and ends with a noun or pronoun that is used to modify an object\n',
                },
              ],
            },
          },
          {
            id: '33744.A.2.2_2',
            choice: {
              texts: [
                {
                  text: 'a phrase that begins with a noun or pronoun and ends with a preposition that is used to modify an object\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Think about where a preposition belongs in a prepositional phrase.\n',
              },
            },
          },
          {
            id: '33744.A.2.2_3',
            choice: {
              texts: [
                {
                  text: 'a phrase that begins with a preposition and ends with a verb that is used to modify an object\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Remember which parts of speech end a prepositional phrase.\n',
              },
            },
          },
        ],
        answers: ['33744.A.2.2_1'],
        associatedSuccessCriteria: ['33744.A.2'],
      },
      feedbackSecondIncorrect: {
        content: 'Prepositional phrases end with with a noun or pronoun.\n',
      },
    },
    {
      id: '33744.A.2.4',
      templateID: 1,
      complexity: 'Knowledge',
      hint: [
        {
          id: '33744.A.2.4.0',
          type: 'BasicSlide',
          data: {
            title: 'Think about what makes a prepositional phrase.\n',
          },
        },
        {
          id: '33744.A.2.4.1',
          type: 'BasicSlide',
          data: {
            title:
              'Prepositional phrases start and end with certain parts of speech.\n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'A prepositional phrase is a phrase that begins with a preposition and ends with {CHOICE_TOKEN}.\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '33744.A.2.4_1',
                  text: 'a noun or pronoun\n',
                },
                {
                  id: '33744.A.2.4_2',
                  text: 'a noun or verb\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'Nouns can end a prepositional phrase, but verbs cannot.\n',
                    },
                  },
                },
                {
                  id: '33744.A.2.4_3',
                  text: 'a noun or adjective\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'Nouns can end a prepositional phrase, but adjectives cannot.\n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['33744.A.2.4_1'],
        associatedSuccessCriteria: ['33744.A.2'],
      },
      feedbackSecondIncorrect: {
        content: 'Prepositional phrases only end in a noun or pronoun.\n',
      },
    },
    {
      id: '33744.A.3.5',
      templateID: 4,
      complexity: 'Comprehension',
      hint: [
        {
          id: '33744.A.3.5.0',
          type: 'BasicSlide',
          data: {
            title:
              'The preposition gives you information about the object of the phrase.\n',
          },
        },
        {
          id: '33744.A.3.5.1',
          type: 'BasicSlide',
          data: {
            title:
              'The preposition will say when, where, or how something happens or is.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Which picture matches the prepositional phrase "**under the table**"?\n',
        },
        answerChoices: [
          {
            id: '33744.A.3.5_1',
            choice: {
              images: [
                {
                  id: 'recURFARAsVKGCuGa',
                  url: '/helix/TEI/SVGs/TEI_prepositions_catundertable_01.svg',
                  altText: [
                    { language: 'en', altText: 'A cat sits below a table.' },
                    {
                      language: 'es',
                      altText: 'El gato se siente debajo de la mesa.',
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '33744.A.3.5_2',
            choice: {
              images: [
                {
                  id: 'recOMYtMhQPm5AKji',
                  url: '/helix/TEI/SVGs/TEI_prepositions_catnextotable_01.svg',
                  altText: [
                    { language: 'en', altText: 'A cat sits beside a table.' },
                    {
                      language: 'es',
                      altText: 'El gato se sienta al lado de la mesa.',
                    },
                  ],
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: { content: 'This cat is next to the table.\n' },
            },
          },
          {
            id: '33744.A.3.5_3',
            choice: {
              images: [
                {
                  id: 'recyc3ccnJo7mc5uk',
                  url: '/helix/TEI/SVGs/TEI_prepositions_catontable_01.svg',
                  altText: [
                    { language: 'en', altText: 'A cat sleeps on a table.' },
                    {
                      language: 'es',
                      altText: 'El gato se duerme sobre la mesa.',
                    },
                  ],
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: { content: 'This cat is on top of the table.\n' },
            },
          },
        ],
        answers: ['33744.A.3.5_1'],
        associatedSuccessCriteria: ['33744.A.3'],
      },
      feedbackSecondIncorrect: {
        content: '_Under_ means the object is **_below_**.\n',
      },
    },
    {
      id: '33744.A.3.6',
      templateID: 4,
      complexity: 'Comprehension',
      hint: [
        {
          id: '33744.A.3.6.0',
          type: 'BasicSlide',
          data: {
            title:
              'The preposition gives you information about the object of the phrase.\n',
          },
        },
        {
          id: '33744.A.3.6.1',
          type: 'BasicSlide',
          data: {
            title:
              'The preposition will say when, where, or how something happens or is.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Which picture matches the prepositional phrase "**between the trees**"?\n',
        },
        answerChoices: [
          {
            id: '33744.A.3.6_1',
            choice: {
              images: [
                {
                  id: 'recoRL4irk6j0z9RT',
                  url: '/helix/TEI/SVGs/TEI_prepositions_dogbetweentree_01.svg',
                  altText: [
                    {
                      language: 'en',
                      altText: 'A dog sits between two trees.',
                    },
                    {
                      language: 'es',
                      altText: 'El perro se sienta entre dos árboles.',
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '33744.A.3.6_2',
            choice: {
              images: [
                {
                  id: 'recXNVhND03ij3EUy',
                  url: '/helix/TEI/SVGs/TEI_prepositions_doginfrontoftree_01.svg',
                  altText: [
                    {
                      language: 'en',
                      altText: 'A dog sits in front of a tree.',
                    },
                    {
                      language: 'es',
                      altText: 'El perro se sienta frente al árbol.',
                    },
                  ],
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'The preposition between shows something in the space separating two objects. There is just one object, the tree.\n',
              },
            },
          },
          {
            id: '33744.A.3.6_3',
            choice: {
              images: [
                {
                  id: 'rec7jtmrWtlHwjfbX',
                  url: '/helix/TEI/SVGs/TEI_prepositions_dogbehindtree_01.svg',
                  altText: [
                    { language: 'en', altText: 'A dog sits behind a tree.' },
                    {
                      language: 'es',
                      altText: 'El perro se sienta detrás del árbol.',
                    },
                  ],
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: 'You can barely see the dog behind the tree.\n',
              },
            },
          },
        ],
        answers: ['33744.A.3.6_1'],
        associatedSuccessCriteria: ['33744.A.3'],
      },
      feedbackSecondIncorrect: {
        content:
          '_Between_ means **_in the space that separates two objects_**.\n',
      },
    },
    {
      id: '33744.A.3.7',
      templateID: 0,
      complexity: 'Comprehension',
      hint: [
        {
          id: '33744.A.3.7.0',
          type: 'BasicSlide',
          data: {
            title:
              'The preposition gives you information about the object of the phrase.\n',
          },
        },
        {
          id: '33744.A.3.7.1',
          type: 'BasicSlide',
          data: {
            title:
              'The preposition will say when, where, or how something happens or is.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Which preposition matches the picture?\n',
        },
        answerChoices: [
          {
            id: '33744.A.3.7_1',
            choice: {
              texts: [
                {
                  text: 'in\n',
                },
              ],
            },
          },
          {
            id: '33744.A.3.7_2',
            choice: {
              texts: [
                {
                  text: 'behind\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: "The fox is hiding, but he's not behind the box.\n",
              },
            },
          },
          {
            id: '33744.A.3.7_3',
            choice: {
              texts: [
                {
                  text: 'under\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: 'Don\'t forget "under" means below.\n',
              },
            },
          },
        ],
        answers: ['33744.A.3.7_1'],
        associatedSuccessCriteria: ['33744.A.3'],
      },
      feedbackSecondIncorrect: { content: 'The fox is **inside** the box.\n' },
      image: {
        id: 'recUnmYAgpbIWajkS',
        url: '/helix/TEI/SVGs/TEI_prepositions_foxinbox_01.svg',
        altText: [
          { language: 'en', altText: 'A fox sits inside a cardboard box.' },
          {
            language: 'es',
            altText: 'El zorro se sienta dentro de la caja de cartón.',
          },
        ],
      },
    },
    {
      id: '33744.A.3.8',
      templateID: 0,
      complexity: 'Comprehension',
      hint: [
        {
          id: '33744.A.3.8.0',
          type: 'BasicSlide',
          data: {
            title:
              'The preposition gives you information about the object of the phrase.\n',
          },
        },
        {
          id: '33744.A.3.8.1',
          type: 'BasicSlide',
          data: {
            title:
              'The preposition will say when, where, or how something happens or is.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Which preposition matches the picture?\n',
        },
        answerChoices: [
          {
            id: '33744.A.3.8_1',
            choice: {
              texts: [
                {
                  text: 'on\n',
                },
              ],
            },
          },
          {
            id: '33744.A.3.8_2',
            choice: {
              texts: [
                {
                  text: 'between\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  "There's only one box, so the bird can't be between.\n",
              },
            },
          },
          {
            id: '33744.A.3.8_3',
            choice: {
              texts: [
                {
                  text: 'in\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  "We wouldn't be able to see the bird if it was in the box.\n",
              },
            },
          },
        ],
        answers: ['33744.A.3.8_1'],
        associatedSuccessCriteria: ['33744.A.3'],
      },
      feedbackSecondIncorrect: {
        content: 'The bird is **on top of** the box.\n',
      },
      image: {
        id: 'recGdNetFtqNKuEjf',
        url: '/helix/TEI/SVGs/TEI_prepositions_birdonbox_01.svg',
        altText: [
          { language: 'en', altText: 'A bird sits on a box.' },
          { language: 'es', altText: 'El pájaro se para sobre la caja.' },
        ],
      },
    },
    {
      id: '33744.A.3.9',
      templateID: 0,
      complexity: 'Comprehension',
      hint: [
        {
          id: '33744.A.3.9.0',
          type: 'BasicSlide',
          data: {
            title:
              'The preposition gives you information about the object of the phrase.\n',
          },
        },
        {
          id: '33744.A.3.9.1',
          type: 'BasicSlide',
          data: {
            title:
              'The preposition will say when, where, or how something happens.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: "Which preposition best describes the soccer ball's position in the picture?\n",
        },
        answerChoices: [
          {
            id: '33744.A.3.9_1',
            choice: {
              texts: [
                {
                  text: 'under\n',
                },
              ],
            },
          },
          {
            id: '33744.A.3.9_2',
            choice: {
              texts: [
                {
                  text: 'on\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  "If something is _on_ an object, it's usually higher up.\n",
              },
            },
          },
          {
            id: '33744.A.3.9_3',
            choice: {
              texts: [
                {
                  text: 'between\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: 'You need two items to be _between_.\n',
              },
            },
          },
        ],
        answers: ['33744.A.3.9_1'],
        associatedSuccessCriteria: ['33744.A.3'],
      },
      feedbackSecondIncorrect: {
        content: 'The ball is **below** the table.\n',
      },
      image: {
        id: 'reczsbMN6bR9pKlaB',
        url: '/helix/TEI/SVGs/TEI_prepositions_ballundertable_01.svg',
        altText: [
          { language: 'en', altText: 'A soccer ball sits under a table.' },
          {
            language: 'es',
            altText: ' La pelota de fútbol está debajo de la mesa.',
          },
        ],
      },
    },
    {
      id: '33744.A.3.10',
      templateID: 5,
      complexity: 'Comprehension',
      hint: [
        {
          id: '33744.A.3.10.0',
          type: 'BasicSlide',
          data: {
            title: 'Think about what prepositions link together.\n',
          },
        },
        {
          id: '33744.A.3.10.1',
          type: 'BasicSlide',
          data: {
            title:
              'Only certain parts of speech are used with a preposition.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Which **three** choices could complete this statement: A preposition . . .\n',
        },
        answerChoices: [
          {
            id: '33744.A.3.10_1',
            choice: {
              texts: [
                {
                  text: 'adds detail in a sentence\n',
                },
              ],
            },
          },
          {
            id: '33744.A.3.10_2',
            choice: {
              texts: [
                {
                  text: 'links words in a sentence\n',
                },
              ],
            },
          },
          {
            id: '33744.A.3.10_3',
            choice: {
              texts: [
                {
                  text: 'is used in prepositional phrases\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: { content: '\n' },
            },
          },
          {
            id: '33744.A.3.10_4',
            choice: {
              texts: [
                {
                  text: 'is not a part of speech\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'A preposition _is_ a part of speech used in a prepositional phrase.\n',
              },
            },
          },
          {
            id: '33744.A.3.10_5',
            choice: {
              texts: [
                {
                  text: 'links two sentences \n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: "You're thinking of a conjunction.\n",
              },
            },
          },
        ],
        answers: ['33744.A.3.10_1', '33744.A.3.10_2', '33744.A.3.10_3'],
        associatedSuccessCriteria: ['33744.A.3'],
      },
      feedbackSecondIncorrect: {
        content:
          'Together these answer choices define what a preposition does.\n',
      },
    },
    {
      id: '33744.A.1.3',
      templateID: 1,
      complexity: 'Knowledge',
      hint: [
        {
          id: '33744.A.1.3.0',
          type: 'BasicSlide',
          data: {
            title: 'Think about what prepositions link together.\n',
          },
        },
        {
          id: '33744.A.1.3.1',
          type: 'BasicSlide',
          data: {
            title:
              'Only certain parts of speech are used with a preposition.\n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'A preposition is a word that links nouns, {CHOICE_TOKEN}, or phrases to other words in a sentence.\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '33744.A.1.3_1',
                  text: 'pronouns\n',
                },
                {
                  id: '33744.A.1.3_2',
                  text: 'verbs\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content: 'Verbs are words that show action.\n',
                    },
                  },
                },
                {
                  id: '33744.A.1.3_3',
                  text: 'adjectives\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content: 'Adjectives describe things.\n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['33744.A.1.3_1'],
        associatedSuccessCriteria: ['33744.A.1'],
      },
      feedbackSecondIncorrect: {
        content: 'Prepositions are used with nouns, pronouns, or phrases. \n',
      },
    },
  ],
  activityManifest: [
    {
      type: 'START_SCREEN',
    },
    {
      type: 'ENTRY_SURVEY',
    },
    {
      type: 'VIDEO',
      id: 'recilDqhv5pV5Y8fu',
    },
    {
      type: 'QUESTION',
      id: '33744.A.1.1',
    },
    {
      type: 'QUESTION',
      id: '33744.A.2.2',
    },
    {
      type: 'QUESTION',
      id: '33744.A.2.4',
    },
    {
      type: 'QUESTION',
      id: '33744.A.3.5',
    },
    {
      type: 'QUESTION',
      id: '33744.A.3.6',
    },
    {
      type: 'QUESTION',
      id: '33744.A.3.7',
    },
    {
      type: 'QUESTION',
      id: '33744.A.3.8',
    },
    {
      type: 'QUESTION',
      id: '33744.A.3.9',
    },
    {
      type: 'QUESTION',
      id: '33744.A.3.10',
    },
    {
      type: 'QUESTION',
      id: '33744.A.1.3',
    },
    {
      type: 'EXIT_SURVEY',
    },
    {
      type: 'RESULTS_SCREEN',
    },
  ],
  version: '-1271323783',
  nextUp: {
    nextActivityId: '018ea00f-3c69-7bbd-9539-5993db71d0e1',
    nextActivityName: 'Comet Tale',
    nextActivityThumbnail: {
      id: 'recygZeNHb67g46Je',
      url: '/helix/Reading/Grade_3/Prepositions/Episode_02/R_G3_Prepositions_EP02_Poster.png',
      altText: [
        {
          language: 'en',
          altText: 'Axis floats in space outside his spaceship.',
        },
        {
          language: 'es',
          altText: 'Axis flota en el espacio afuera de su nave espacial.',
        },
      ],
    },
  },
  instructionsImage: {
    id: 'recUzD6BEnXxRd8Wh',
    url: '/helix/UX_Images/Characters/Estrella/Estrella_Chibi_Head_Happy_Eyes_Open_Mouth_Open.svg',
    altText: [
      { language: 'en', altText: 'Estrella is smiling.' },
      { language: 'es', altText: 'Estrella está sonriendo.' },
    ],
  },
  characterLeanout: {
    id: 'svvjeewfjhHFEFgef',
    url: '/helix/TEI/Leanouts/Estrella_Leanout_01.svg',
    altText: [
      {
        language: 'en',
        altText: 'Estrella leans out from the side of the screen.',
      },
      {
        language: 'es',
        altText: 'Estrella se asoma por un lado de la pantalla.',
      },
    ],
  },
};
