import {
  AudioResource,
  AudioResourceMapping,
} from '../shared/interfaces/audio-types';
import { Themes } from '../shared/enums/themes';
import { AudioType } from '../shared/enums/audio-type';
import { AudioDescriptor } from '../shared/enums/audio-descriptor';

export const defaultAudioFolder = '/assets/sfx/';
const sfxFolderTHEMES = 'themes/';
const themeFolderDEFAULT = sfxFolderTHEMES + Themes.DEFAULT;

export const audioMappings: AudioResourceMapping[] = [
  {
    type: AudioType.audio_SfxClick,
    theme: Themes.DEFAULT,
    descriptor: AudioDescriptor.SELECT_MAJOR_HI,
    id: 'idSfxClickMajorHi',
  },
  {
    type: AudioType.audio_SfxClick,
    theme: Themes.DEFAULT,
    descriptor: AudioDescriptor.SELECT_MAJOR_LO,
    id: 'idSfxClickMajorLo',
  },
  {
    type: AudioType.audio_SfxClick,
    theme: Themes.DEFAULT,
    descriptor: AudioDescriptor.SELECT_MINOR,
    id: 'idSfxClickMinor',
  },
  {
    type: AudioType.audio_SfxClick,
    theme: Themes.DEFAULT,
    descriptor: AudioDescriptor.CLICK_GENERAL,
    id: 'idSfxClickGeneral',
  },
  {
    type: AudioType.audio_SfxAnswer,
    theme: Themes.DEFAULT,
    descriptor: AudioDescriptor.ANSWER_CORRECT,
    id: 'idSfxAnswerCorrect',
  },
  {
    type: AudioType.audio_SfxAnswer,
    theme: Themes.DEFAULT,
    descriptor: AudioDescriptor.ANSWER_INCORRECT,
    id: 'idSfxAnswerIncorrect',
  },
  {
    type: AudioType.audio_SfxAnim,
    theme: Themes.DEFAULT,
    descriptor: AudioDescriptor.ANIM_RESULTS,
    id: 'idSfxAnimResults',
  },
  {
    type: AudioType.audio_SfxAnim,
    theme: Themes.DEFAULT,
    descriptor: AudioDescriptor.ANIM_CHEST,
    id: 'idSfxAnimChest',
  },
  {
    type: AudioType.audio_SfxClick,
    theme: Themes.DEFAULT,
    descriptor: AudioDescriptor.PICKUP_TILE,
    id: 'idSfxPickupTile',
  },
  {
    type: AudioType.audio_SfxClick,
    theme: Themes.DEFAULT,
    descriptor: AudioDescriptor.PLACE_TILE,
    id: 'idSfxPlaceTile',
  },
];

export const audioResources: AudioResource[] = [
  {
    id: 'idSfxClickMajorHi',
    url: defaultAudioFolder + themeFolderDEFAULT + '/select_major_hi.wav',
  },
  {
    id: 'idSfxClickMajorLo',
    url: defaultAudioFolder + themeFolderDEFAULT + '/select_major_lo.wav',
  },
  {
    id: 'idSfxClickMinor',
    url: defaultAudioFolder + themeFolderDEFAULT + '/select_minor.wav',
  },
  {
    id: 'idSfxAnswerCorrect',
    url: defaultAudioFolder + themeFolderDEFAULT + '/answer_correct.wav',
  },
  {
    id: 'idSfxAnswerIncorrect',
    url: defaultAudioFolder + themeFolderDEFAULT + '/answer_incorrect.wav',
  },
  {
    id: 'idSfxClickGeneral',
    url: defaultAudioFolder + themeFolderDEFAULT + '/general_click.wav',
  },
  {
    id: 'idSfxAnimResults',
    url: defaultAudioFolder + themeFolderDEFAULT + '/anim_results.wav',
  },
  {
    id: 'idSfxAnimChest',
    url: defaultAudioFolder + themeFolderDEFAULT + '/anim_chest.wav',
  },
  {
    id: 'idSfxPickupTile',
    url: defaultAudioFolder + themeFolderDEFAULT + '/pickup_tile.mp3',
  },
  {
    id: 'idSfxPlaceTile',
    url: defaultAudioFolder + themeFolderDEFAULT + '/place_tile.mp3',
  },
];
