import { FeatureFlagService } from '../../services/feature-flag/feature-flag.service';
import { Component, OnDestroy } from '@angular/core';
import { DebugService } from 'src/app/modules/debug/services/debug/debug.service';
import { featureFlagLabels, featureflagSections } from 'src/app/data';
import { Subscription } from 'rxjs';

@Component({
  selector: 'htc-feature-flag-menu',
  templateUrl: './feature-flag-menu.component.html',
  styleUrls: ['./feature-flag-menu.component.scss'],
})
export class FeatureFlagMenuComponent implements OnDestroy {
  flags!: Map<string, boolean>;
  flagSectionsMap: Map<string, string[]> = new Map();
  sectionKeys: string[] = [];
  subscription = new Subscription();
  flagLabels: { [key: string]: string } = featureFlagLabels;

  constructor(
    public featureFlagService: FeatureFlagService,
    public debugService: DebugService
  ) {
    this.flags = new Map(featureFlagService.getFlags());
    this.flagSectionsMap = new Map(Object.entries(featureflagSections));
    this.sectionKeys = Array.from(this.flagSectionsMap.keys());
    //we're making a local copy of flags and deleting the featureFlagDebug entry
    //so that we don't display it in the menu.
    this.flags.delete('featureFlagDebug');
    // listen for the flag changes if setFlag, clearOverrideFlags or populateFlags called in the FeatureFlagService
    this.subscription.add(
      featureFlagService.flagsChanged.subscribe(() => {
        this.flags = new Map(featureFlagService.getFlags());
      })
    );
  }

  onCheckboxClicked(key: string): void {
    this.featureFlagService.setFlag(
      key,
      !this.featureFlagService.isFlagEnabled(key)
    );
    this.featureFlagService.saveCurrentAsOverride();
  }

  clearOverrides(): void {
    this.featureFlagService.clearOverrideFlags();
    window.alert('Feature flag overrides wiped!');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
