import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
} from '@angular/core';

@Directive({
  selector: '[htcFocus]',
})
export class FocusDirective implements AfterViewInit, OnDestroy {
  observer!: MutationObserver;
  _isFocused = false;
  @Input() set isFocused(value: boolean) {
    this._isFocused = value;
    if (this._isFocused) {
      this.focus();
    }
  }
  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    this.observer = new MutationObserver(mutations => {
      for (let i = 0; i < mutations.length; i++) {
        if (mutations[i].attributeName === 'style') {
          // skip style attributes
          continue;
        }
        // remove event listeners to prevent having multiple references to the same element
        this.el.nativeElement.removeAllListeners();
        if (this._isFocused) {
          this.focus();
        }
      }
    });
    this.observer.observe(this.el.nativeElement, {
      attributes: true,
      childList: true,
      characterData: true,
    });
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }

  focus(): void {
    const element: HTMLElement | null = this.el.nativeElement;
    const focusable = element?.querySelectorAll(
      'a[href]:not(:disabled), button:not(:disabled), textarea:not(:disabled), input[type="text"]:not(:disabled),' +
        'input[type="radio"]:not(:disabled), input[type="checkbox"]:not(:disabled),' +
        'select:not(:disabled), htc-dropdown:not(:disabled),' +
        'ol[tabindex="0"], section[tabindex="0"]'
    );
    if (element && focusable) {
      const focusableElements = Array.from(focusable).map(
        el => el as HTMLElement
      );
      const [firstFocusableElmt] = focusableElements;

      firstFocusableElmt.focus();
    }
  }
}
