import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollbarService {
  isScrollbarHidden$ = new BehaviorSubject<boolean>(false);

  constructor() {}

  setIsScrollbarHidden(value: boolean): void {
    this.isScrollbarHidden$.next(value);
  }

  resetScrollbarToTop(): void {
    const elements = document.querySelectorAll('.reset-scrollbar');

    if (elements.length === 0) {
      return;
    }

    elements.forEach(element => {
      element.scrollTo(0, 0);
    });
  }

  applyCustomScrollbarStyles(): void {
    const elements = document.querySelectorAll('*');

    elements.forEach(element => {
      if (
        element.clientHeight < element.scrollHeight ||
        element.clientWidth < element.scrollWidth
      ) {
        const bgColor = this.getBackgroundColor(element);
        if (bgColor === 'light') {
          this.applyLightScrollbarStyles(element as HTMLElement);
        } else if (bgColor === 'dark') {
          this.applyDarkScrollbarStyles(element as HTMLElement);
        }
      }
    });
  }

  private applyLightScrollbarStyles(element: HTMLElement): void {
    element.style.setProperty(
      '--scroll-track-bkg',
      'var(--scroll-track-light-bkg)'
    );
  }

  private applyDarkScrollbarStyles(element: HTMLElement): void {
    element.style.setProperty(
      '--scroll-track-bkg',
      'var(--scroll-track-dark-bkg)'
    );
  }

  private getBackgroundColor(element: Node): string {
    if (element === document) {
      return '';
    }
    const computedStyle = getComputedStyle(element as HTMLElement);
    const backgroundColor = computedStyle.backgroundColor;

    if (
      backgroundColor === 'rgba(0, 0, 0, 0)' ||
      backgroundColor === 'transparent'
    ) {
      if (element.parentNode) {
        return this.getBackgroundColor(element.parentNode);
      }
    } else {
      return this.getBackgroundType(backgroundColor);
    }

    return '';
  }

  private getBackgroundType = (backgroundColor: string): string => {
    const isHexColor = /^#(?:[0-9a-fA-F]{3}){1,2}$/.test(backgroundColor);
    const isRgbColor = /^rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)$/.test(
      backgroundColor
    );
    const isRgbaColor =
      /^rgba\((\d{1,3}), (\d{1,3}), (\d{1,3}), (0(\.\d+)?|1(\.0+)?)\)$/.test(
        backgroundColor
      );

    let rgb: { r: number; g: number; b: number } | null = null;
    if (isHexColor) {
      rgb = this.hexToRgb(backgroundColor);
    } else if (isRgbColor || isRgbaColor) {
      const index = isRgbColor ? 4 : 5;
      const rgbValues = backgroundColor
        .substring(index, backgroundColor.length - 1)
        .replace(/ /g, '')
        .split(',');

      rgb = {
        r: parseInt(rgbValues[0], 10),
        g: parseInt(rgbValues[1], 10),
        b: parseInt(rgbValues[2], 10),
      };
    }

    if (rgb && rgb.r + rgb.g + rgb.b > (3 * 255) / 2) {
      return 'light';
    } else {
      return 'dark';
    }
  };

  private hexToRgb(hex: string): { r: number; g: number; b: number } | null {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }
}
