<div class="popup" htcTrapFocus>
  <div class="popup-menu">
    <htc-avatar
      class="avatar"
      [avatar]="avatar"
      [flair]="'square'"
      maskStyle="none"></htc-avatar>
    <h3 class="student-name">{{ name }}</h3>
    <button
      #btnAvatar
      data-testid="htc-avatar-open-btn"
      id="btn-avatar-chooser"
      class="btn btn-primary btn-profile btn-avatar"
      type="button"
      role="menuitem"
      htcAudioClick
      [audioType]="clickType"
      [descriptor]="clickDescriptor"
      disabled>
      <svg-icon
        class="btn-icon"
        src="assets/button-icons/avatar.svg"></svg-icon>
      {{ 'PROFILE_MENU.AVATAR' | translate }}
    </button>
    <button
      data-testid="htc-theme-open-btn"
      class="btn btn-primary btn-profile btn-theme"
      type="button"
      role="menuitem"
      htcAudioClick
      [audioType]="clickType"
      [descriptor]="clickDescriptor"
      disabled>
      <svg-icon class="btn-icon" src="assets/button-icons/theme.svg"></svg-icon>
      {{ 'PROFILE_MENU.THEME' | translate }}
    </button>
    <button
      data-testid="htc-settings-open-btn"
      class="btn btn-primary btn-profile btn-theme"
      type="button"
      role="menuitem"
      htcAudioClick
      [audioType]="clickType"
      [descriptor]="clickDescriptor"
      (click)="handleSettingsPopup($event)">
      <svg-icon
        class="btn-icon"
        src="assets/button-icons/settings.svg"></svg-icon>
      {{ 'PROFILE_MENU.SETTINGS' | translate }}
    </button>

    <div class="version">
      <p>v{{ version }}</p>

      <button
        data-testid="htc-support-open-btn"
        class="btn-link interactive-cmp"
        role="menuitem"
        htcAudioClick
        [audioType]="clickType"
        [descriptor]="clickDescriptor"
        (click)="handleSupportPopup($event)">
        {{ 'PROFILE_MENU.SUPPORT' | translate }}
      </button>
    </div>
  </div>
  <footer class="popup-footer">
    <button
      data-testid="htc-logout-btn"
      class="btn btn-logout btn-profile btn-profile-logout"
      type="button"
      role="menuitem"
      (click)="logoutPopup()"
      htcAudioClick
      [audioType]="clickType"
      [descriptor]="clickDescriptor"
      [disabled]="!featureFlagService.isFlagEnabled(featureFlags.AUTHENTICATE)">
      <svg-icon src="assets/button-icons/logout.svg"></svg-icon>
      {{ 'PROFILE_MENU.LOGOUT' | translate }}
    </button>
  </footer>
</div>
<button
  type="button"
  id="profile-menu-close-btn"
  class="btn btn-close btn-profile-close"
  (click)="closeProfile()"
  attr.aria-label="{{ 'PROFILE_MENU.CLOSE_ARIA_LABEL' | translate }}"
  htcAudioClick
  [audioType]="clickType"
  [descriptor]="clickDescriptor"
  data-testid="htc-profile-popup-close-button">
  <svg-icon src="assets/button-icons/close.svg"></svg-icon>
</button>
