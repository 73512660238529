@for (field of fields; track $index) {
  @if (!field.isMathText) {
    @if (field.textInput !== '\n') {
      <markdown
        [attr.aria-hidden]="true"
        [data]="field.textInput"
        [inline]="true"></markdown>
    } @else {
      <br />
    }
  } @else {
    <htc-math-text
      [attr.aria-hidden]="true"
      [texFunction]="field.textInput"></htc-math-text>
  }
}
