/*
 * The type of TEIs (technology enhanced items)
 * ex. Multiple choice
 */
export enum TemplateID {
  NONE = -1,
  MULTIPLE_CHOICE = 0,
  INLINE_CHOICE = 1,
  TRUE_FALSE_CHOICE = 2,
  TEXT_ENTRY = 3,
  MULTIPLE_CHOICE_IMAGE = 4,
  MULTI_SELECT = 5,
  MULTI_SELECT_IMAGE = 6,
  STATIC_HORIZONTAL_SEQUENCING = 7,
  DYNAMIC_HORIZONTAL_SEQUENCING = 8,
  STATIC_VERTICAL_SEQUENCING = 9,
  // T_CHART,
  // VENN_DIAGRAM,
  // MATCHING,
  // BASIC_SORTING,
  // BASIC_SORTING_ALT,
  // DROP_BUCKET,
  FILL_IN_THE_BLANK_TILE_TEXT = 10,
  CONSTRUCTED_RESPONSE = 11,
  CONSTRUCTED_RESPONSE_WITH_RUBRIC = 12,
  EXTENDED_CONSTRUCTED_RESPONSE = 13,
  EXTENDED_CONSTRUCTED_RESPONSE_WITH_RUBRIC = 14,
  SORTING_BUCKETS = 15,
  SORTING_BUCKETS_IMAGES = 16,
}

export const MultipleChoiceTypes = [
  TemplateID.MULTIPLE_CHOICE,
  TemplateID.MULTIPLE_CHOICE_IMAGE,
  TemplateID.MULTI_SELECT,
  TemplateID.MULTI_SELECT_IMAGE,
  TemplateID.TRUE_FALSE_CHOICE,
];

export const InlineChoiceTypes = [
  TemplateID.INLINE_CHOICE,
  TemplateID.TEXT_ENTRY,
  TemplateID.FILL_IN_THE_BLANK_TILE_TEXT,
];

export const ConstructedResponseTypes = [
  TemplateID.CONSTRUCTED_RESPONSE,
  TemplateID.CONSTRUCTED_RESPONSE_WITH_RUBRIC,
  TemplateID.EXTENDED_CONSTRUCTED_RESPONSE,
  TemplateID.EXTENDED_CONSTRUCTED_RESPONSE_WITH_RUBRIC,
];

export const ComplexFeedbackTypes = [
  TemplateID.INLINE_CHOICE,
  TemplateID.TEXT_ENTRY,
  TemplateID.STATIC_HORIZONTAL_SEQUENCING,
  TemplateID.DYNAMIC_HORIZONTAL_SEQUENCING,
  TemplateID.STATIC_VERTICAL_SEQUENCING,
  ...ConstructedResponseTypes,
];

export const DragAndDropTypes = [
  TemplateID.SORTING_BUCKETS,
  TemplateID.SORTING_BUCKETS_IMAGES,
  TemplateID.FILL_IN_THE_BLANK_TILE_TEXT,
  TemplateID.STATIC_HORIZONTAL_SEQUENCING,
  TemplateID.DYNAMIC_HORIZONTAL_SEQUENCING,
  TemplateID.STATIC_VERTICAL_SEQUENCING,
];

export const SaveIntervalTypes = [...ConstructedResponseTypes];

export const SaveAnswerStatesTypes = [
  ...ConstructedResponseTypes,
  TemplateID.SORTING_BUCKETS,
  TemplateID.SORTING_BUCKETS_IMAGES,
];

export const NonMutableTryTypes = [
  TemplateID.TRUE_FALSE_CHOICE,
  ...ConstructedResponseTypes,
];

export const SkipQuestionLogSortTypes = [
  TemplateID.TEXT_ENTRY,
  TemplateID.FILL_IN_THE_BLANK_TILE_TEXT,
  TemplateID.SORTING_BUCKETS,
  TemplateID.SORTING_BUCKETS_IMAGES,
  TemplateID.STATIC_HORIZONTAL_SEQUENCING,
  TemplateID.DYNAMIC_HORIZONTAL_SEQUENCING,
  TemplateID.STATIC_VERTICAL_SEQUENCING,
];
