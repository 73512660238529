/* eslint-disable complexity */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DebugService } from '../../services/debug/debug.service';
import {
  Activity,
  AnswerChoice,
  Population,
  PopulationInlineChoice,
  PopulationMultipleChoice,
  PopulationTextEntry,
} from 'src/app/shared/interfaces';
import { ActivityService } from 'src/app/shared/services';

import { Subscription } from 'rxjs';
import { ActivityObjectTypes } from 'src/app/shared/enums/activity-object-types';
import { AnswerStateEnum, TemplateID } from 'src/app/shared/enums';
import {
  ProgressNodeFocusEnum,
  ProgressNodeStatusEnum,
} from 'src/app/modules/progress-bar/enums';
import { TextEntryEvaluator } from 'src/app/classes/text-entry-evaluator';
import { InlineChoiceEvaluator } from 'src/app/classes/inline-choice-evaluator';
import { MultiChoiceEvaluator } from 'src/app/classes/multi-choice-evaluator';
import { VideoSaveStateService } from 'src/app/modules/video/services/video-save-state.service';
import { VideoState } from 'src/app/shared/interfaces/video-state';
import {
  IncompleteActivityState,
  IncompleteActivityStateService,
} from 'src/app/modules/activity-save-state';

@Component({
  selector: 'htc-incomplete-save-data-editor',
  templateUrl: './incomplete-save-data-editor.component.html',
  styleUrls: ['./incomplete-save-data-editor.component.scss'],
})
export class IncompleteSaveDataEditorComponent implements OnInit, OnDestroy {
  exportToClipboard = false;
  maxPopsAvailable = 0;
  json = JSON;

  activityId = '';
  activity?: Activity;
  activityState?: IncompleteActivityState;
  episodeSub = new Subscription();
  templateIdEnum = TemplateID;
  steps: string[] = [];
  currAnswers = new Map<number, (string | number)[][]>();
  currElapsedTime: number[] = [];
  videoProgression = 0;
  videoTotalWatchedTime = 0;
  videoWatchedFully = false;

  constructor(
    public debugService: DebugService,
    public videosaveStateService: VideoSaveStateService,
    private activityService: ActivityService,
    private activityStateService: IncompleteActivityStateService
  ) {}

  ngOnInit(): void {
    this.episodeSub.add(
      this.activityService.episodeTitle.subscribe(() => {
        if (!this.activityService.currActivity) {
          return;
        }
        this.activityId = this.activityService.currActivity.id;
        this.activity = this.activityService.currActivity;
        this.activityState =
          this.activityStateService.createDefaultActivityState(this.activity);
        this.generateStepOptions();
      })
    );
  }

  resetActivity(resetActivity = true, resetActivityState = true): void {
    if (resetActivity) {
      this.activityService.resetActivityState();
      this.activity = undefined;
      this.activityId = '';
    }
    if (resetActivityState) {
      this.activityState = undefined;
    }
  }

  findActivity(): void {
    this.activityService.fetchActivity(this.activityId).subscribe({
      error: err => {
        console.log(err);
        alert(
          `We weren't able to find your desired activity (${err.statusText})`
        );
      },
    });
  }

  getPopQuestion(pop: Population): string {
    if (
      pop.templateID === TemplateID.INLINE_CHOICE ||
      pop.templateID === TemplateID.TEXT_ENTRY
    ) {
      return (pop.populationData as PopulationInlineChoice).text
        .reduce((acc, curr) => acc + ' ' + curr.text, '')
        .trim();
    } else {
      return (pop.populationData as PopulationMultipleChoice).question.text;
    }
  }

  getMultiChoiceAnswerChoices(pop: Population): AnswerChoice[] {
    return (pop.populationData as PopulationMultipleChoice).answerChoices;
  }

  getInlineAnswerChoices(pop: Population): AnswerChoice[] {
    const choices = (pop.populationData as PopulationInlineChoice)
      .answerChoices;
    return choices;
  }

  getTextEntryAnswer(pop: Population): (string | number)[][] {
    return (pop.populationData as PopulationTextEntry).answers;
  }

  generateStepOptions(): void {
    if (!this.activity) {
      return;
    }
    this.steps = [];
    let questionIndex = 0;
    for (let i = 0; i < this.activity.activityManifest.length; i++) {
      let type = this.activity.activityManifest[i].type;
      if (type === ActivityObjectTypes.QUESTION.toString()) {
        type += ' ' + (questionIndex + 1).toString();
        questionIndex++;
      }
      this.steps.push(i.toString() + ' - ' + type);
    }
  }

  currStepChanged(): void {
    this.determineMaxQuestion();
    this.fillPopulationResponses();
  }

  fillPopulationResponses(): void {
    if (!this.activityState) {
      return;
    }

    for (let i = 0; i < this.maxPopsAvailable; i++) {
      if (!this.activityState.populationResponses[i]) {
        this.activityState.populationResponses[i] = {
          responses: [],
          elapsedTime: 0,
        };
      }
    }
  }

  determineMaxQuestion(): void {
    if (!this.activityState || !this.activityState.currentStep) {
      return;
    }

    let max = 0;
    for (let i = 0; i < this.activityState.currentStep; i++) {
      if (
        this.activity?.activityManifest[i].type === ActivityObjectTypes.QUESTION
      ) {
        max++;
      }
    }
    this.maxPopsAvailable = max;
  }

  answerChanged(
    index: number,
    answer: (string | number)[],
    event?: Event
  ): void {
    const eventTargetInput = event?.target as HTMLInputElement;
    const isRadio = eventTargetInput && eventTargetInput.type === 'radio';
    const unchecking = eventTargetInput && !eventTargetInput.checked;

    let existingAnswer = this.currAnswers.get(index);

    if (isRadio) {
      existingAnswer = [[...answer]];
    } else if (existingAnswer) {
      const answerIndex = existingAnswer[0].indexOf(answer[0]);
      if (!unchecking) {
        if (answerIndex >= 0) {
          return;
        } else {
          // existingAnswer.push([...answer]);
          existingAnswer[0] = [...existingAnswer[0], ...answer];
        }
      } else {
        existingAnswer[0].splice(answerIndex, 1);
      }
    }
    this.currAnswers.set(index, existingAnswer ?? [answer]);
  }

  textAnswerChanged(
    event: Event,
    questionIndex: number,
    tokenIndex: number
  ): void {
    const currAnswer = this.currAnswers.get(questionIndex) ?? [];
    currAnswer[tokenIndex] = [(event.target as HTMLInputElement).value ?? ''];
    this.currAnswers.set(questionIndex, currAnswer);
  }

  elapsedTimeChange(event: Event, index: number): void {
    this.currElapsedTime[index] = parseInt(
      (event.target as HTMLInputElement).value,
      10
    );
  }

  addAttempt(index: number): void {
    if (!this.activityState) {
      return;
    }
    if (
      this.activityState.populationResponses[index].responses.length > 0 &&
      this.activityState.populationResponses[index].responses[0].length == 2
    ) {
      alert(`You've already added two responses, you cannot add more.`);
      return;
    }

    const answer = this.currAnswers.get(index);
    if (!answer) {
      return;
    }

    this.activityState.populationResponses[index].responses.push(answer);
    this.activityState.populationResponses[index].elapsedTime =
      this.currElapsedTime[index] ?? 0;
  }

  exportSaveState(): void {
    if (!this.activityState) {
      return;
    }
    this.fillInTheRest();
    this.debugService.exportActivityState(
      this.exportToClipboard,
      this.activityState
    );
  }

  launchSaveState(): void {
    if (!this.activityState) {
      return;
    }
    this.fillInTheRest();

    const vidState: VideoState = {
      id: this.activity?.videos[0].id ?? '',
      videoProgression: this.videoProgression,
      videoTotalWatchtime: this.videoTotalWatchedTime,
      videoWatched: this.videoWatchedFully,
    };
    this.videosaveStateService.curVideoState$.next(vidState);

    this.activityService.resetActivityState();
    this.activityService.resumeActivityFromState(this.activityState);
    this.debugService.showDebugMenu.next(false);
  }

  setAnswerStatesAndCorrectCounts(
    activityState: IncompleteActivityState
  ): void {
    for (let i = 0; i < activityState.populationResponses.length; i++) {
      const response = activityState.populationResponses[i];
      if (response.responses.length === 0) {
        response.responses = [[[], []]];
      }
      const numResponses = response.responses.length;
      const firstAttempt = numResponses === 1;
      const latestCorrect = this.answerCorrect(
        response.responses[numResponses - 1],
        i
      );

      if (latestCorrect && firstAttempt) {
        activityState.answerStates[i] = AnswerStateEnum.PERFECT;
        activityState.numPerfect++;
      } else if (latestCorrect) {
        activityState.answerStates[i] = AnswerStateEnum.CORRECT;
        activityState.numCorrect++;
      } else {
        for (let j = response.responses.length; j < 2; j++) {
          response.responses[j] = [];
        }
        activityState.answerStates[i] = AnswerStateEnum.INCORRECT;
        activityState.numIncorrect++;
      }
    }
  }

  fillInTheRest(): void {
    if (!this.activityState || !this.activity) {
      return;
    }

    this.resetTheRest(this.activityState);
    this.setAnswerStatesAndCorrectCounts(this.activityState);
    this.activityState.currentStep = +(this.activityState.currentStep - 1);
    this.activityState.elapsedTime = +this.activityState.elapsedTime;

    let progressIndex = 0;
    let questionIndex = 0;

    for (let i = 0; i < this.activity.activityManifest.length; i++) {
      const currType = this.activity.activityManifest[i].type;
      if (
        currType === ActivityObjectTypes.VIDEO ||
        currType === ActivityObjectTypes.QUESTION
      ) {
        const transCurrStep = this.activityState.currentStep + 2;

        if (i < transCurrStep) {
          this.activityState.progressNodes[progressIndex].focus =
            ProgressNodeFocusEnum.seen;
        } else if (i == transCurrStep) {
          this.activityState.progressNodes[progressIndex - 1].focus =
            ProgressNodeFocusEnum.active;
          this.activityState.questionNumber = questionIndex - 2;
        }

        if (currType === ActivityObjectTypes.QUESTION) {
          this.activityState.progressNodes[progressIndex].status =
            this.translateAnswerStateToProgressNodeStatus(
              this.activityState.answerStates[questionIndex]
            );
          questionIndex++;
        }
        progressIndex++;
      }
    }
  }

  answerCorrect(answer: (string | number)[][], index: number): boolean {
    if (!this.activity) {
      return false;
    }
    const thisPop = this.activity.populations[index];
    if (thisPop.templateID == TemplateID.TEXT_ENTRY) {
      return TextEntryEvaluator.isCorrect(
        thisPop.populationData as PopulationTextEntry,
        answer
      );
    } else if (thisPop.templateID == TemplateID.INLINE_CHOICE) {
      return InlineChoiceEvaluator.isCorrect(
        thisPop.populationData as PopulationInlineChoice,
        answer[0] as string[]
      );
    } else {
      return MultiChoiceEvaluator.isCorrect(
        thisPop.populationData as PopulationMultipleChoice,
        answer[0] as string[]
      );
    }
  }

  resetTheRest(activityState: IncompleteActivityState): void {
    activityState.answerStates = [];
    activityState.numPerfect = 0;
    activityState.numCorrect = 0;
    activityState.numIncorrect = 0;
    activityState.currTry = 0;
    activityState.filledAnswers = [];
    activityState.selectedAnswers = [];
    activityState.disabledAnswers = [[]];
    activityState.curHintsRevealed = [];
    //TODO: Make it possible to resume mid answer
  }

  private translateAnswerStateToProgressNodeStatus(
    answerState: AnswerStateEnum
  ): ProgressNodeStatusEnum {
    switch (answerState) {
      case AnswerStateEnum.CORRECT:
        return ProgressNodeStatusEnum.correct;
      case AnswerStateEnum.INCORRECT:
        return ProgressNodeStatusEnum.wrong;
      case AnswerStateEnum.PERFECT:
        return ProgressNodeStatusEnum.perfect;
      default:
        return ProgressNodeStatusEnum.none;
    }
  }

  ngOnDestroy(): void {
    this.episodeSub.unsubscribe();
    this.maxPopsAvailable = 0;
    this.resetActivity(false, true);
  }
}
