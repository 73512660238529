<div class="flex-container flex-center flex-col full-height menu-container">
  <div class="flex-container flex-col message-container">
    <select name="Messages" id="messages" [(ngModel)]="messageCode">
      <option
        *ngFor="let message of Object.keys(messages); let i = index"
        [ngValue]="message">
        {{ message }}:
        {{ Object.values(messages)[i].errorName }}
      </option>
    </select>
    <button class="btn btn-primary" (click)="showMessage()">
      Display Message
    </button>
  </div>
  <div class="flex-container flex-col message-container">
    <input
      type="text"
      [(ngModel)]="referrer"
      placeholder="Enter url to set as referrer" />
    <button class="btn btn-primary" (click)="setReferrer()">
      Set Referrer
    </button>
  </div>
</div>
