export enum TEST_POPULATION_TYPES {
  TEST_R_MC_SHORT,
  TEST_R_MC_MEDIUM,
  TEST_R_MC_LONG,
  TEST_R_MC_PASSAGE_SHORT,
  TEST_R_MC_PASSAGE_MEDIUM,
  TEST_R_MC_PASSAGE_LONG,
  TEST_R_MC_PASSAGE_SHORT_WITH_TITLE,
  TEST_R_MC_PASSAGE_MEDIUM_WITH_TITLE,
  TEST_R_MC_PASSAGE_LONG_WITH_TITLE,
  TEST_R_MC_TITLE_SHORT,
  TEST_R_MC_TITLE_MEDIUM,
  TEST_R_MC_TITLE_LONG,
  TEST_R_MCI_SHORT,
  TEST_R_MCI_MEDIUM,
  TEST_R_MCI_LONG,
  TEST_R_MCI_PASSAGE_SHORT,
  TEST_R_MCI_PASSAGE_MEDIUM,
  TEST_R_MCI_PASSAGE_LONG,
  TEST_R_MS_SHORT,
  TEST_R_MS_MEDIUM,
  TEST_R_MS_LONG,
  TEST_R_MS_PASSAGE_SHORT,
  TEST_R_MS_PASSAGE_MEDIUM,
  TEST_R_MS_PASSAGE_LONG,
  TEST_R_MSI_SHORT,
  TEST_R_MSI_MEDIUM,
  TEST_R_MSI_LONG,
  TEST_R_MSI_PASSAGE_SHORT,
  TEST_R_MSI_PASSAGE_MEDIUM,
  TEST_R_MSI_PASSAGE_LONG,
  TEST_R_TE_SHORT,
  TEST_R_TE_MEDIUM,
  TEST_R_TE_LONG,
  TEST_R_IC_SHORT,
  TEST_R_IC_MEDIUM,
  TEST_R_IC_LONG,
  TEST_R_MC_TRUE_FALSE,
  TEST_R_MC_PASSAGE_TRUE_FALSE,
  TEST_R_SCR_SHORT,
  TEST_R_SCR_MEDIUM,
  TEST_R_SCR_LONG,
  TEST_R_SCR_PASSAGE_SHORT,
  TEST_R_SCR_PASSAGE_MEDIUM,
  TEST_R_SCR_PASSAGE_LONG,
  TEST_R_SCRR_SHORT,
  TEST_R_SCRR_MEDIUM,
  TEST_R_SCRR_LONG,
  TEST_R_SCRR_PASSAGE_SHORT,
  TEST_R_SCRR_PASSAGE_MEDIUM,
  TEST_R_SCRR_PASSAGE_LONG,
  TEST_R_ECR_SHORT,
  TEST_R_ECR_MEDIUM,
  TEST_R_ECR_LONG,
  TEST_R_ECR_PASSAGE_SHORT,
  TEST_R_ECR_PASSAGE_MEDIUM,
  TEST_R_ECR_PASSAGE_LONG,
  TEST_R_ECRR_SHORT,
  TEST_R_ECRR_MEDIUM,
  TEST_R_ECRR_LONG,
  TEST_R_ECRR_PASSAGE_SHORT,
  TEST_R_ECRR_PASSAGE_MEDIUM,
  TEST_R_ECRR_PASSAGE_LONG,
  TEST_R_DND_BLANK_SHORT,
  TEST_R_DND_BLANK_LONG,
  TEST_R_DND_SEQ_STATIC_HORZ_SHORT,
  TEST_R_DND_SEQ_STATIC_HORZ_LONG,
  TEST_R_DND_SEQ_STATIC_VERT_SHORT,
  TEST_R_DND_SEQ_STATIC_VERT_LONG,
  TEST_R_DND_SORT_BUCKETS_SHORT,
  TEST_R_DND_SORT_BUCKETS_LONG,
  TEST_S_MC_SHORT,
  TEST_S_MC_MEDIUM,
  TEST_S_MC_LONG,
  TEST_S_MC_PASSAGE_SHORT,
  TEST_S_MC_PASSAGE_MEDIUM,
  TEST_S_MC_PASSAGE_LONG,
  TEST_S_MCI_SHORT,
  TEST_S_MCI_MEDIUM,
  TEST_S_MCI_LONG,
  TEST_S_MCI_PASSAGE_SHORT,
  TEST_S_MCI_PASSAGE_MEDIUM,
  TEST_S_MCI_PASSAGE_LONG,
  TEST_S_MS_SHORT,
  TEST_S_MS_MEDIUM,
  TEST_S_MS_LONG,
  TEST_S_MS_PASSAGE_SHORT,
  TEST_S_MS_PASSAGE_MEDIUM,
  TEST_S_MS_PASSAGE_LONG,
  TEST_S_MSI_SHORT,
  TEST_S_MSI_MEDIUM,
  TEST_S_MSI_LONG,
  TEST_S_MSI_PASSAGE_SHORT,
  TEST_S_MSI_PASSAGE_MEDIUM,
  TEST_S_MSI_PASSAGE_LONG,
  TEST_S_TE_SHORT,
  TEST_S_TE_MEDIUM,
  TEST_S_TE_LONG,
  TEST_S_IC_SHORT,
  TEST_S_IC_MEDIUM,
  TEST_S_IC_LONG,
  TEST_S_MC_TRUE_FALSE,
  TEST_S_MC_PASSAGE_TRUE_FALSE,
  TEST_S_SCR_SHORT,
  TEST_S_SCR_MEDIUM,
  TEST_S_SCR_LONG,
  TEST_S_SCRR_SHORT,
  TEST_S_SCRR_MEDIUM,
  TEST_S_SCRR_LONG,
  TEST_S_SCR_PASSAGE_SHORT,
  TEST_S_SCR_PASSAGE_MEDIUM,
  TEST_S_SCR_PASSAGE_LONG,
  TEST_S_SCRR_PASSAGE_SHORT,
  TEST_S_SCRR_PASSAGE_MEDIUM,
  TEST_S_SCRR_PASSAGE_LONG,
  TEST_S_ECR_SHORT,
  TEST_S_ECR_MEDIUM,
  TEST_S_ECR_LONG,
  TEST_S_ECRR_SHORT,
  TEST_S_ECRR_MEDIUM,
  TEST_S_ECRR_LONG,
  TEST_S_ECR_PASSAGE_SHORT,
  TEST_S_ECR_PASSAGE_MEDIUM,
  TEST_S_ECR_PASSAGE_LONG,
  TEST_S_ECRR_PASSAGE_SHORT,
  TEST_S_ECRR_PASSAGE_MEDIUM,
  TEST_S_ECRR_PASSAGE_LONG,
  TEST_M_SCR_SHORT,
  TEST_M_SCR_MEDIUM,
  TEST_M_SCR_LONG,
  TEST_M_SCR_PASSAGE_SHORT,
  TEST_M_SCR_PASSAGE_MEDIUM,
  TEST_M_SCR_PASSAGE_LONG,
  TEST_M_SCRR_SHORT,
  TEST_M_SCRR_MEDIUM,
  TEST_M_SCRR_LONG,
  TEST_M_SCRR_PASSAGE_SHORT,
  TEST_M_SCRR_PASSAGE_MEDIUM,
  TEST_M_SCRR_PASSAGE_LONG,
  TEST_M_ECR_SHORT,
  TEST_M_ECR_MEDIUM,
  TEST_M_ECR_LONG,
  TEST_M_ECR_PASSAGE_SHORT,
  TEST_M_ECR_PASSAGE_MEDIUM,
  TEST_M_ECR_PASSAGE_LONG,
  TEST_M_ECRR_SHORT,
  TEST_M_ECRR_MEDIUM,
  TEST_M_ECRR_LONG,
  TEST_M_ECRR_PASSAGE_SHORT,
  TEST_M_ECRR_PASSAGE_MEDIUM,
  TEST_M_ECRR_PASSAGE_LONG,
  TEST_M_DND_STATIC_HORIZ_SEQ,
  TEST_M_DND_DYNAMIC_HORIZ_SEQ,
  TEST_M_DND_STATIC_VERT_SEQ,
  TEST_M_DND_FILL_BLANK_1,
  TEST_M_DND_FILL_BLANK_2,
  TEST_M_DND_FILL_BLANK_3,
  TEST_M_DND_FILL_BLANK_4,
  TEST_M_DND_FILL_BLANK_5,
  TEST_M_DND_SORTING_BUCKETS_1,
  TEST_M_DND_SORTING_BUCKETS_2,
  TEST_M_DND_SORTING_BUCKETS_3,
  TEST_R_MC_MEDIUM_1_TRY,
  TEST_R_MCI_MEDIUM_2_TRY,
  TEST_R_MS_MEDIUM_3_TRY,
  TEST_R_MSI_MEDIUM_1_TRY,
  TEST_R_TE_MEDIUM_2_TRY,
  TEST_R_IC_MEDIUM_3_TRY,
  TEST_R_DND_BLANK_SHORT_1_TRY,
  TEST_R_DND_SORT_BUCKETS_SHORT_4_TRY,
  TEST_R_DND_SORTING_BUCKETS_IMAGES_1,
  TEST_R_DND_SORTING_BUCKETS_IMAGES_2,
}
