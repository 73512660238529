/**
 * This object is meant to store the state of an activity completion, similar to the click stream,
 * but accessible by our applications.
 *
 * It is used in an array inside of the CompletedActivity object
 *
 * There is a similar class called IncompleteActivityState which is meant to keep the state of the activity
 * between playthroughs to enable leaving and coming back. We don't need to be storing that much information
 * all of the time, so we've got this object to represent a playthrough after it's completion
 */

import { AnswerStateEnum } from 'src/app/shared/enums';

export interface CompleteActivityState {
  activityId: string;
  dateCompleted: string;
  timeToCompleteSecs: number;
  starsEarned: number;
  score: number;
  answerStates: AnswerStateEnum[];
}
