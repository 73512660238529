<div
  class="input read-aloud-excluded-element"
  [ngClass]="{
    wrong: isWrongAnswer,
    correct: isCorrectAnswer,
    invalid: !isValueValid
  }"
  (click)="onClickEvent($event)">
  <span
    ><svg-icon src="/assets/button-icons/check_enabled.svg"></svg-icon
  ></span>
  <input
    data-cmp="text-entry-input"
    [id]="uniqueId"
    [placeholder]="'TEI.INPUT_PLACEHOLDER' | translate"
    [attr.aria-placeholder]="'TEI.INPUT_PLACEHOLDER' | translate"
    [attr.data-testid]="uniqueId"
    [value]="value"
    [required]="required"
    [disabled]="isCorrectAnswer"
    (input)="handleChange($event)"
    (paste)="onPaste($event)"
    (keydown)="onKeyDownEvent($event)"
    (blur)="onBlurEvent()"
    (invalid)="handleInvalidInput($event)"
    [attr.aria-disabled]="isCorrectAnswer"
    autocomplete="off"
    title=""
    pattern="{{ pattern }}"
    [attr.inputmode]="inputMode" />
  <div class="focus"></div>

  <htc-inline-correct-answer
    *ngIf="showAnswer"
    [showAnswer]="showAnswer"
    [isCorrectAnswer]="!showWrongAnswer"
    value="{{ displayedAnswer }}"></htc-inline-correct-answer>

  <!-- 
    aria-live="assertive": Specifies that the error message should be announced immediately 
                           to the user (as opposed to waiting for a pause in screen reader output).
    aria-atomic="true": Indicates that the entire contents of the <div> should be read out 
                        by screen readers, even if only part of it has changed.
  -->
  <div
    #invalidInputDiv
    *ngIf="!isValueValid && inputTouched"
    [attr.data-testid]="'invalid-input-' + uniqueId"
    class="invalid-input"
    role="alert"
    aria-live="assertive"
    aria-atomic="true">
    <svg-icon
      src="/assets/component-icons/dropdown-correct-polygon.svg"></svg-icon>
    {{ inputErrorMessage }}
  </div>
</div>
