import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, Input } from '@angular/core';
import { SpeechRuleEngineService } from 'src/app/modules/math-text/services/speech-rule-engine.service';

@Component({
  selector: 'htc-inline-correct-answer',
  templateUrl: './inline-correct-answer.component.html',
  styleUrls: ['./inline-correct-answer.component.scss'],
  animations: [
    trigger('fadeInSlide', [
      transition('* => *', [
        animate(
          '300ms ease-in',
          keyframes([
            style({ opacity: 0 }),
            style({ display: 'flex', opacity: 1 }),
          ])
        ),
      ]),
    ]),
  ],
})
export class InlineCorrectAnswerComponent {
  @Input() showAnswer = false;
  @Input() isCorrectAnswer = true;
  @Input() value = '';
  @Input() bottomPosition = '';

  constructor(public sres: SpeechRuleEngineService) {}
}
