import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  onText = 'on';
  offText = 'off';
  selectedText = 'Selected';
  selectedSpan = `<span class="visually-hidden">(${this.selectedText})</span>`;

  constructor() {}

  static findChildEditInnerHTML(
    parent: Element,
    query: string,
    innerHTML: string
  ): boolean {
    const foundEl = parent.querySelector(query);
    if (foundEl) {
      foundEl.innerHTML = innerHTML;

      return true;
    }

    return false;
  }

  static findChildEditAttribute(
    parent: Element,
    query: string,
    attribute: string,
    value: string
  ): boolean {
    const foundEl = parent.querySelector(query);
    if (foundEl) {
      foundEl.setAttribute(attribute, value);

      return true;
    }

    return false;
  }

  findElementEditClass(
    classId: string,
    classEdit: string,
    add: boolean
  ): Element | undefined {
    return this.editElementClassFromParent(document, classId, classEdit, add);
  }

  editElementClassFromParent(
    parent: Element | Document,
    classId: string,
    classEdit: string,
    add: boolean
  ): Element | undefined {
    const el = parent.getElementsByClassName(classId)[0];

    if (el) {
      this.editClass(el, classEdit, add);
    }

    return el;
  }

  editClass(el: Element, classEdit: string, add: boolean): void {
    const elClasses = el.classList;

    if (add) {
      elClasses.add(classEdit);
    } else if (elClasses.contains(classEdit)) {
      elClasses.remove(classEdit);
    }
  }

  changeRadiosClassList(els: Element[], active: boolean): void {
    els.forEach((el: Element) => {
      if (active) {
        el.classList.add('active');
        el.setAttribute(
          'Title',
          `${el.getAttribute('Title')} (${this.selectedText})`
        );
        el.setAttribute('aria-selected', 'true');
        if (!el.innerHTML.includes(this.selectedSpan)) {
          el.innerHTML += this.selectedSpan;
        }
      } else {
        el.classList.remove('active');

        el.setAttribute(
          'Title',
          `${el.getAttribute('Title')?.replace(` (${this.selectedText})`, '')}`
        );
        el.setAttribute('aria-selected', 'false');
        el.innerHTML = el.innerHTML.replace(this.selectedSpan, '');
      }
    });
  }

  changeTogglesClassList(els: Element[], toggleOn: boolean): void {
    els.forEach((el: Element) => {
      const label = el.querySelector('.label');

      if (toggleOn) {
        el.classList.add('toggler-on');
        el.classList.remove('toggler-off');
        el.setAttribute('aria-pressed', 'true');

        if (label && this.onText) {
          label.innerHTML = this.onText;
        }
      } else {
        el.classList.remove('toggler-on');
        el.classList.add('toggler-off');
        el.setAttribute('aria-pressed', 'false');

        if (label && this.offText) {
          label.innerHTML = this.offText;
        }
      }
    });
  }
}
