import { Injectable } from '@angular/core';
import { ConfigurationService } from 'src/app/modules/configuration/services/configuration/configuration.service';
import { ConfigData, ConfigDataModules, ConfigDataStore } from '../../types';
import { APIHandler } from '../api-handler/api-handler';

@Injectable()
export class ConfigDataAPI implements ConfigData {
  userOid!: number;
  authToken = '';
  readonly configData = 'ConfigData';
  readonly path_base = 'knowledge-base/config-data';
  private apiHandler: APIHandler;

  constructor(private configService: ConfigurationService) {
    this.apiHandler = new APIHandler({
      baseUrl: this.configService.config.olpApiDomain,
    });
  }

  setAuthToken(authToken: string): void {
    this.authToken = authToken;
    this.configureHandler();
  }

  configureHandler(): void {
    this.apiHandler.configure({
      baseUrl: this.configService.config.olpApiDomain,
      defaultHeaders: {
        Authorization: 'Bearer ' + this.authToken,
        'Content-Type': 'application/json',
      },
    });
  }

  async getAll(): Promise<ConfigDataStore | null | undefined> {
    const path = this.buildPath();
    return this.apiHandler.get<ConfigDataStore>(path);
  }

  async getModules(): Promise<ConfigDataModules | null | undefined> {
    const configData = await this.getAll();
    return configData?.data.modules;
  }

  /**
   * Sets ConfigData to sessionStorage
   */
  async loadData(): Promise<string | null> {
    if (!this.userOid) {
      return Promise.reject(null);
    }

    const rawData = await this.retrieveConfigData();
    if (rawData) {
      sessionStorage.setItem(this.configData, rawData);
      return Promise.resolve(rawData);
    }
    return Promise.reject(null);
  }

  /**
   * Gets the specified key from ConfigData.
   * @param section ConfigData section of the key
   * @param key     ConfigData key to get, optional
   */
  async get(section: string, key?: string): Promise<string | undefined> {
    const data =
      sessionStorage.getItem(this.configData) ||
      (await this.loadData().catch(() =>
        console.error('ConfigDataAPI.get: Cannot load user ConfigData')
      ));

    const parsed: Record<string, Record<string, string>> = data
      ? JSON.parse(data)
      : {};

    const isValidSection =
      parsed &&
      Object.keys(parsed).length !== 0 &&
      Object.keys(parsed).includes(section);

    if (isValidSection && !key) {
      // empty key parameter; return the section data as string
      return JSON.stringify(parsed[section]);
    } else if (
      isValidSection &&
      key &&
      Object.keys(parsed[section]).includes(key)
    ) {
      // return key value as string
      return parsed[section][key];
    }

    // data invalid
    console.error(
      `ConfigDataAPI.get: failed to retrieve requested data. Please make sure section/key is valid`
    );
    return undefined;
  }

  clearData(): void {
    sessionStorage.removeItem(this.configData);
  }

  retrieveConfigData(): Promise<string> {
    const path = this.buildPath();
    return this.apiHandler.get<string>(path);
  }

  private buildPath(): string {
    return `${this.path_base}/${this.userOid}`;
  }
}
