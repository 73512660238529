import { Injectable } from '@angular/core';
import { MessageService } from 'src/app/modules/message/services/message/message.service';
import { AuthSessionService } from 'src/app/modules/auth/services/auth-session/auth-session.service';
import { FeatureFlagService } from 'src/app/modules/feature-flags/services/feature-flag/feature-flag.service';
import { ReferrerService } from '../services/referrer/referrer.service';
import { FeatureFlags } from '../enums/feature-flags';
import { MessageCodes } from '../enums';

@Injectable({
  providedIn: 'root',
})
export class ArbitraryLaunchGuard {
  constructor(
    private messageService: MessageService,
    private authSessionService: AuthSessionService,
    private featureFlagService: FeatureFlagService,
    private referrerService: ReferrerService
  ) {}

  canActivate(): boolean {
    // HTEC-701
    // allow access is a user has Jump ACL
    // or Authentication is disabled
    // or referrer is Istation.com
    if (
      this.authSessionService.hasRole('jumpScene') ||
      !this.featureFlagService.isFlagEnabled(FeatureFlags.AUTHENTICATE) ||
      this.featureFlagService.isFlagEnabled(
        FeatureFlags.DISABLE_ARBITRARY_LAUNCHER
      ) ||
      this.referrerService.isIstationNavTarget()
    ) {
      return true;
    }
    this.messageService.showMessage(MessageCodes.HTTP_FORBIDDEN);
    return false; // Don't allow access
  }
}
