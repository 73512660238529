import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { UseAnimationService } from 'src/app/modules/use-animation/use-animation.service';
import { AudioDescriptor } from 'src/app/shared/enums/audio-descriptor';
import { AudioType } from 'src/app/shared/enums/audio-type';
import { Settings } from 'src/app/shared/interfaces';
import { PopupService } from 'src/app/shared/services/popup/popup.service';
import { SettingsService } from 'src/app/shared/services/settings/settings.service';

@Component({
  selector: 'htc-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsComponent implements OnChanges {
  @Input() showPopup = false;
  @Output() showPopupChange = new EventEmitter<boolean>();

  settings: Settings = {};
  originalSettingsStringify = '';
  sub = new Subscription();
  clickDescriptor = AudioDescriptor.CLICK_GENERAL;
  clickType = AudioType.audio_SfxClick;

  constructor(
    private useAnimationService: UseAnimationService,
    private popupService: PopupService,
    private settingsService: SettingsService
  ) {
    this.initState();
  }

  initState(): void {
    this.settings = {
      ...this.settingsService.settings.getValue(),
    };
    if (this.settings.prefersReducedMotion === undefined) {
      this.settings.prefersReducedMotion =
        !this.useAnimationService.useAnimation$.getValue();
    }
    this.originalSettingsStringify = JSON.stringify(this.settings);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showPopup && changes.showPopup.currentValue === true) {
      this.initState();
    }
  }

  @HostListener('keydown.escape', ['$event'])
  @HostListener('keydown.esc', ['$event']) // For IE11 coverage
  closePopup(): void {
    this.showPopup = false;
    this.initState();
    this.popupService.focusActiveElement();
    this.showPopupChange.emit(this.showPopup);
  }

  checkChanges(): boolean {
    return this.originalSettingsStringify !== JSON.stringify(this.settings);
  }

  saveSettings(): void {
    this.originalSettingsStringify = JSON.stringify(this.settings);

    if (this.settings.prefersReducedMotion !== undefined) {
      this.useAnimationService.useAnimation$.next(
        !this.settings.prefersReducedMotion
      );
    }

    this.settingsService.saveSettings(this.settings);
  }
}
