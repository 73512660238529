import { EarlyAccessService } from './../../modules/early-access/services/early-access/early-access.service';
import { FeatureFlagService } from 'src/app/modules/feature-flags/services/feature-flag/feature-flag.service';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { FeatureFlags } from '../enums/feature-flags';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EarlyAccessGuard {
  constructor(
    private featureFlagService: FeatureFlagService,
    private earlyAccessService: EarlyAccessService,
    private router: Router
  ) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (
      !this.featureFlagService.isFlagEnabled(FeatureFlags.EARLY_ACCESS) ||
      this.earlyAccessService.authenticated
    ) {
      return true;
    } else {
      return this.router.createUrlTree(['/demo-login']);
    }
  }
}
