import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ITool } from 'src/app/modules/tools/interfaces/tool.interface';
import { ToolsService } from 'src/app/modules/tools/services/tools.service';
import { PopupService } from 'src/app/shared/services/popup/popup.service';
import { ReadAloudService } from 'src/app/modules/read-aloud/services';

@Component({
  selector: 'htc-popup-toolbar-button',
  templateUrl: './popup-toolbar-button.component.html',
  styleUrls: ['./popup-toolbar-button.component.scss'],
})
export class PopupToolbarButtonComponent implements ITool, OnInit, OnDestroy {
  @Input() id!: number;
  // If the tool name should be translate prefix with 'Translate_' and it's translation is under the TOOL_BAR key
  @Input() toolName!: string;
  @Input() icon!: string;
  @Input() toggledIcon?: string;
  @Input() testId = 'htc-popup-toolbar-button';

  path = '/assets/toolbar-icons/';
  isHovering = false;
  isSelected = false;
  sub = new Subscription();

  constructor(
    private toolsService: ToolsService,
    private popupService: PopupService,
    private translateService: TranslateService,
    private readAloudService: ReadAloudService
  ) {}

  ngOnInit(): void {
    if (this.toolName) {
      this.translateName(); //translate for the current language
      this.sub.add(
        this.translateService.onLangChange.subscribe(() => {
          this.translateName(); //translate for subsequent language changes
        })
      );
    }
    this.sub.add(
      this.toolsService.getActivatedTools().subscribe(toolIds => {
        this.toggleButton(toolIds.indexOf(this.id) !== -1);
      })
    );
  }

  translateName(): void {
    this.toolName = this.translateService.instant(
      'TOOL_BAR.' + this.toolName.toUpperCase()
    );
  }

  onClick(event: Event): void {
    this.readAloudService.stopCurrent();
    if (!this.isSelected) {
      this.toolsService.activateTool(this.id);
      this.popupService.setActiveElement(event.currentTarget as HTMLElement);
    } else {
      this.toolsService.deactivateTool(this.id);
    }
  }

  toggleButton(value: boolean): void {
    this.isSelected = value;
  }

  openTooltip(): void {
    this.isHovering = true;
  }

  closeTooltip(): void {
    this.isHovering = false;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
