import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Chart } from 'src/app/shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  private chart$ = new BehaviorSubject<Chart>({ data: '', isUrl: false });
  private isChartOpen$ = new BehaviorSubject<boolean>(false);

  getChart(): Observable<Chart> {
    return this.chart$.asObservable();
  }

  getIsChartOpen(): Observable<boolean> {
    return this.isChartOpen$.asObservable();
  }

  openChart(chartToOpen: Chart): void {
    this.chart$.next(chartToOpen);
    this.isChartOpen$.next(true);
  }

  closeChart(): void {
    this.chart$.next({ data: '', isUrl: false });
    this.isChartOpen$.next(false);
  }
}
