import { Injectable, signal, WritableSignal } from '@angular/core';

export enum LoadingScreenState {
  hidden = 0,
  animatingOut = 1,
  shown = 2,
}

@Injectable({
  providedIn: 'root',
})
export class LoadingScreenService {
  loadingScreenState: WritableSignal<number> = signal(
    LoadingScreenState.hidden
  );
}
