import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  AfterViewInit,
  OnInit,
  OnChanges,
  SimpleChanges,
  HostListener,
} from '@angular/core';
import { TableNode, TableCellNode, TableRowNode } from '@lexical/table';
import { LexicalEditor, CreateEditorArgs, createEditor } from 'lexical';

@Component({
  selector: 'htc-lexical-table',
  templateUrl: './lexical-table.component.html',
  styleUrls: ['./lexical-table.component.scss'],
})
export class LexicalTableComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() editable = false;
  @Input() state = '';
  @Input() fitToParentElement = false;

  config: CreateEditorArgs = {
    namespace: `table`,
    theme: {
      table: 'table',
      tableCell: 'table-cell',
      tableCellHeader: 'table-cell-header',
    },
    onError: console.error,
    editable: this.editable,
    nodes: [TableNode, TableCellNode, TableRowNode],
  };
  editor!: LexicalEditor;

  @ViewChild('lexicalComposer', { static: true })
  lexicalComposer!: ElementRef;

  ngOnInit(): void {
    this.initEditor();
    this.handleFit();
  }

  ngAfterViewInit(): void {
    this.setEditorState(this.state);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.editor && changes.state && changes.state.currentValue) {
      this.setEditorState(changes.state.currentValue);
    }
  }

  setEditorState(state: string): void {
    if (state !== undefined && state.length) {
      const parsedState = this.editor.parseEditorState(state);
      this.editor.setEditorState(parsedState);
    }
  }

  private initEditor(): void {
    this.editor = createEditor(this.config);

    this.editor.setEditable(this.editable);
    this.editor.setRootElement(this.lexicalComposer.nativeElement);
  }

  @HostListener('window:resize', ['$event'])
  private handleFit(): void {
    if (this.fitToParentElement) {
      const interval = setInterval(() => {
        this.lexicalComposer.nativeElement.closest('section').style.maxHeight =
          '100px';

        if (this.lexicalComposer.nativeElement.children.length > 0) {
          this.fitToParent(this.lexicalComposer.nativeElement, 0);
          this.lexicalComposer.nativeElement.closest(
            'section'
          ).style.maxHeight = 'none';
          clearInterval(interval);
        }
      }, 1);
    }
  }

  private scaleAmountNeededToFit(el: HTMLElement, margin = 0): number {
    const siblings = el.closest('section')?.children;
    let siblingsHeight = 0;
    if (siblings) {
      for (let i = 0; i < siblings.length; i++) {
        if (!siblings[i].contains(this.lexicalComposer.nativeElement)) {
          const computedStyle = window.getComputedStyle(siblings[i]);
          siblingsHeight +=
            siblings[i].clientHeight +
            parseInt(computedStyle.marginTop, 10) +
            parseInt(computedStyle.marginBottom, 10);
        }
      }
    }
    const parentSize = {
      width: (el.closest('section')?.clientWidth || 300) - margin * 2,
      height:
        (el.closest('section')?.scrollHeight || 300) -
        margin * 2 -
        siblingsHeight,
    };

    return Math.min(
      (parentSize.width - 16) / el.children[0].clientWidth,
      parentSize.height / el.children[0].clientHeight
    );
  }

  private fitToParent(element: HTMLElement, margin: number): void {
    const scale = this.scaleAmountNeededToFit(element, margin);
    element.style.transformOrigin = '0 0';
    element.style.transform = `translate(${margin}px, ${margin}px) scale(${scale})`;
    if (element.parentElement) {
      element.parentElement.style.height =
        element.getBoundingClientRect().height + 'px';
    }
  }
}
