@if (isAuthorized) {
  <div class="flex-container flex-col base-gap-3r">
    <section class="settings">
      <h3>Settings</h3>
      <div *ngFor="let key of Object.keys(settings); index as i">
        <label>
          {{ key }}
          <input
            [id]="key"
            type="{{ key === 'subtitlesFontSize' ? 'number' : 'checkbox' }}"
            (change)="onCheckboxClicked(key, $event)" />
        </label>
      </div>
      <div class="flex-container flex-row menu">
        <button
          class="btn btn-primary"
          (click)="onSaveSettings()"
          data-testid="kb-debug-save-settings">
          Save Settings KB
        </button>
        <button
          class="btn btn-danger"
          (click)="onDeleteSettings()"
          data-testid="kb-debug-delete-settings">
          Delete Settings KB
        </button>
      </div>
    </section>
    <section class="kb-manip">
      <h3>Manipulate Entire KB</h3>
      <div class="center-vertically">
        <div class="flex-container flex-row menu">
          <button class="btn btn-primary" (click)="printKb()">
            Print KB to console
          </button>
          <button class="btn btn-danger" (click)="clearKb()">
            Erase Entire KB
          </button>
        </div>
      </div>
      <button class="btn btn-danger" (click)="invalidateCco()">
        invalidate CCO
      </button>
    </section>
    <section class="kb-manip">
      <h3>Saved Activities</h3>
      <div class="center-vertically">
        <div class="flex-container flex-row menu">
          <button class="btn btn-primary" (click)="printIncomplete()">
            Print Incomplete Activities to console
          </button>
          <button class="btn btn-danger" (click)="printComplete()">
            Print Completed Activities to console
          </button>
        </div>
      </div>
    </section>
  </div>
} @else {
  <div #unauthorized data-testid="kb-fail">
    <p>
      Failed to open kb debug menu, ensure kbDebug and useKb feature flags are
      enabled.
    </p>
    <button class="btn btn-primary" (click)="sendUnauthRequest()">
      Send Unauthenticated Request
    </button>
  </div>
}
