import { Subscription } from 'rxjs';
import { AudioData } from 'src/app/shared/interfaces';
import {
  Component,
  Input,
  TemplateRef,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ReadAloudService,
  SpeechSynthesisReadAloudService,
} from '../../services';
import { AudioService } from 'src/app/modules/audio/services/audio/audio.service';
import { ReadAloudPlayerType } from '../../enums/read-aloud-player-type';
import { ReadAloudTheme } from '../../enums/read-aloud-theme';

@Component({
  selector: 'htc-read-aloud',
  templateUrl: './read-aloud.component.html',
  styleUrls: ['./read-aloud.component.scss'],
})
export class ReadAloudComponent implements OnDestroy, OnInit, AfterViewInit {
  @Input() type = 'WITH_PAUSE';
  @Input() theme = 'LIGHT';
  @Input() template!: TemplateRef<unknown> | null; //Added by scss
  @Input() showPlayPause = false; //Show play/pause controls
  @Input() audioData: AudioData = { id: '', url: '' };
  @Input() preventTabTargeting = false;
  @Input() forceHide = false;
  @Input() customTextColor!: string;
  @ViewChild('readAloudContainer') div!: ElementRef<HTMLDivElement>;

  isPlaying = false; //Shows/hides controls overlay
  isPaused = false; //Swaps play/pause + wave images in HTML controls overlay
  isLoading = false; //Shows loading circle in HTML controls overlay
  onEndSubscription!: Subscription; //Called when audio ends
  playerType = ReadAloudPlayerType;
  readAloudTheme = ReadAloudTheme;
  show = true; //Show read aloud?
  uuid = '';

  constructor(
    private readAloudService: ReadAloudService,
    private speechSynthesisReadAloudService: SpeechSynthesisReadAloudService,
    private audioService: AudioService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.uuid = crypto.randomUUID();
    this.readAloudService.readAloudMode.subscribe(mode => {
      this.show = mode;
      this.changeDetectorRef.detectChanges();
    });
    this.audioService.failedAudioId.subscribe(id => {
      if (this.audioData.id === id) {
        this.show = false;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.readAloudService.currentReadAloud === this)
      this.readAloudService.stopCurrent();
    this.readAloudService.releaseReadAloud(this);
  }

  ngAfterViewInit(): void {
    this.showPlayPause = this.type === ReadAloudPlayerType.WITH_PAUSE;
    this.changeDetectorRef.detectChanges();
  }

  handleClick(event?: Event): void {
    if (
      !this.show ||
      (this.isPlaying && !this.showPlayPause) ||
      this.speechSynthesisReadAloudService.currentlyRetrying ||
      this.readAloudService.inStopAndPlayDelay ||
      event?.type === 'keyup'
    )
      return;

    event?.stopPropagation();
    event?.preventDefault();
    //Draw border (if read aloud is enabled)
    this.readAloudService.select(this.div.nativeElement);

    if (this.readAloudService.currentReadAloud) {
      if (
        this.readAloudService.currentReadAloud === this &&
        this.showPlayPause
      ) {
        //Playing this audio and have pause, so toggle pause
        this.isPaused = !this.isPaused;
        this.readAloudService.togglePauseCurrent();
      } else {
        //Stop the other audio and play (queue or load and queue) this
        this.readAloudService.stopAndPlay(this);
      }
    } else {
      //No audio currently playing, so play (or load and play) the audio
      this.readAloudService.play(this);
    }
  }
}
