import { Injectable } from '@angular/core';
import { Configuration } from '../../types/configuration';
import configJson from 'src/configurations/configuration-dev/configuration.json';
import { LogLevel, OpenIdConfiguration } from 'angular-auth-oidc-client';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  fetched = false;
  fetching = false;
  config!: Configuration;
  path = '/configuration.json';

  constructor() {
    const configJsonObject = new Map(Object.entries(configJson.featureFlags));
    this.config = { ...configJson, featureFlags: configJsonObject };
  }

  getAuthConfig(): Promise<OpenIdConfiguration> {
    return new Promise(resolve => {
      this.fetched
        ? resolve(this.extractAuthConfig())
        : this.load().then(() => resolve(this.extractAuthConfig()));
    });
  }

  private extractAuthConfig(): OpenIdConfiguration {
    const matches = /DefaultDomain\??(?:=|%3D)([^\],]*)/.exec(
      window.location.search
    );
    const customParamsAuthRequest =
      matches && matches.length > 1
        ? {
            domain: matches[1],
          }
        : {};
    return <OpenIdConfiguration>{
      configId: this.config.configId,
      authority: this.config.idServerUrl,
      authWellknownEndpointUrl:
        this.config.idServerUrl + '.well-known/openid-configuration',
      redirectUrl: window.location.origin + '/auth/login',
      postLogoutRedirectUri: window.location.origin + '/auth/logout',
      clientId: 'Istation.Practice',
      responseType: 'code',
      silentRenew: true,
      maxIdTokenIatOffsetAllowedInSeconds: 600,
      useRefreshToken: true,
      logLevel: this.config.production ? LogLevel.None : LogLevel.Debug,
      secureRoutes: [
        this.config.idServerUrl,
        this.config.olpApiDomain,
        this.config.contentMetadataDomain,
      ],
      customParamsAuthRequest,
    };
  }

  load(): Promise<void> {
    return new Promise(resolve => {
      if (this.fetched) {
        resolve();
      } else if (this.fetching) {
        const fetchCheck = window.setInterval(() => {
          if (this.fetched) {
            window.clearInterval(fetchCheck);
            resolve();
          }
        }, 1000);
      } else {
        this.fetch().then(() => resolve());
      }
    });
  }

  async fetch(path: string = '/configuration.json'): Promise<void> {
    this.fetching = true;
    this.path = path;
    return fetch(this.path)
      .then(res => {
        this.fetching = false;
        this.fetched = true;
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(
            `Environment Configuration not retrievable from server. (${res.status}) ${res.statusText}`
          );
        }
      })
      .then(json => {
        if (json) {
          this.config = json;
        } else {
          throw new Error('Json response is empty');
        }
      });
  }
}
