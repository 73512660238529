<div class="container">
  <div class="section">
    <h3>Import</h3>
    <p>
      You can paste a json object representing an incomplete save activity,
      <strong>OR</strong> upload a json file for an activity
    </p>
    <div class="upload">
      <textarea
        name="importSaveData"
        id="importSaveData"
        cols="40"
        rows="5"
        [(ngModel)]="importText"
        (change)="onTextAreaChange()"
        placeholder="Paste your activity json here..."></textarea>
      <label for="uploadSaveDataJson" class="prevent-select">
        or upload your json file
      </label>
      <div>
        <input
          #uploadSaveDataJson
          type="file"
          accept=".json"
          name="uploadSaveDataJson"
          id="uploadSaveDataJson"
          (change)="onFileSelected($event)" />
        <button *ngIf="importFile" class="clear" (click)="clearFile()">
          Clear File
        </button>
      </div>
    </div>
    <htc-button-with-icon
      *ngIf="importedActivityState === undefined"
      btnText="Import"
      (click)="import()"></htc-button-with-icon>
    <htc-button-with-icon
      *ngIf="importedActivityState !== undefined"
      btnText="Launch"
      (click)="launch()"></htc-button-with-icon>
  </div>
  <hr />
  <div class="section">
    <h3>Export</h3>
    <p>
      You can export the current state of the activity. Either check the box to
      have it saved to your clipboard, or uncheck the box to save a file to your
      computer
    </p>
    <div>
      <label for="copyToClipboard" class="prevent-select">
        Export to Clipboard
      </label>
      <input
        type="checkbox"
        name="copyToClipboard"
        id="copyToClipboard"
        [(ngModel)]="exportToClipboard" />
    </div>
    <htc-button-with-icon
      btnText="Export"
      (btnClicked)="export()"></htc-button-with-icon>
  </div>
</div>
