import { ImageData } from '../shared/interfaces';

export enum TEST_IMAGE_TYPES {
  CORRECT,
  INCORRECT,
  BG_CLOUD_LEFT,
  BG_CLOUD_RIGHT,
  FG_CAT,
  INSTRUCTION_1,
  INSTRUCTION_2,
  READING_ICON,
  MATH_ICON,
  LECTURA_ICON,
  CAT_UNDER_TABLE,
  CHARACTER_LEANOUT,
  CAT_BESIDE_TABLE,
}
export const images: { [key: number]: ImageData } = {
  [TEST_IMAGE_TYPES.CORRECT]: {
    id: 'correct',
    url: '/helix/app-assets/htec/activity-images/correct.png',
    altText: [
      { language: 'en', altText: 'Correct Image' },
      { language: 'es', altText: 'Imagen correcta' },
    ],
  },
  [TEST_IMAGE_TYPES.INCORRECT]: {
    id: 'incorrect',
    url: '/helix/app-assets/htec/activity-images/incorrect.png',
    altText: [
      { language: 'en', altText: 'Incorrect Image' },
      { language: 'es', altText: 'Imagen incorrecta' },
    ],
  },
  [TEST_IMAGE_TYPES.BG_CLOUD_LEFT]: {
    id: 'bg_cloudLeft',
    url: '/assets/start-screen-images/background-images/Prepositions_G3V1_Poster.jpg',
    altText: [
      {
        language: 'en',
        altText: 'A background image depicting the inside of a spaceship.',
      },
      {
        language: 'es',
        altText:
          'Una imagen de fondo que muestra el interior de una nave espacial.',
      },
    ],
  },
  [TEST_IMAGE_TYPES.BG_CLOUD_RIGHT]: {
    id: 'bg_cloudRight',
    url: '/assets/start-screen-images/background-images/nova-scouts-space-sky-1.jpg',
    altText: [
      { language: 'en', altText: 'A background image depicting space.' },
      {
        language: 'es',
        altText: 'Una imagen de fondo que representa el espacio.',
      },
    ],
  },
  [TEST_IMAGE_TYPES.FG_CAT]: {
    id: 'fg_cat',
    url: '/assets/start-screen-images/foreground-images/cat.svg',
    altText: [
      { language: 'en', altText: 'A cat image for foreground purposes.' },
      {
        language: 'es',
        altText: 'Una imagen de gato para fines de primer plano.',
      },
    ],
  },
  [TEST_IMAGE_TYPES.INSTRUCTION_1]: {
    id: 'instruction_1',
    url: '/helix/app-assets/htec/activity-images/instruction-1.svg',
    altText: [
      { language: 'en', altText: 'A cat.' },
      { language: 'es', altText: 'Un gato' },
    ],
  },
  [TEST_IMAGE_TYPES.INSTRUCTION_2]: {
    id: 'instruction_2',
    url: '/helix/app-assets/htec/activity-images/instruction-2.svg',
    altText: [
      { language: 'en', altText: 'A car.' },
      { language: 'es', altText: 'Un coche' },
    ],
  },
  [TEST_IMAGE_TYPES.READING_ICON]: {
    id: 'reading-icon',
    url: '/assets/early-access/home/icons/reading.svg',
    altText: [
      { language: 'en', altText: 'A reading icon.' },
      { language: 'es', altText: 'Un icono de lectura.' },
    ],
  },
  [TEST_IMAGE_TYPES.MATH_ICON]: {
    id: 'math-icon',
    url: '/assets/early-access/home/icons/math.svg',
    altText: [
      { language: 'en', altText: 'A math icon.' },
      { language: 'es', altText: 'Un ícono matemático.' },
    ],
  },
  [TEST_IMAGE_TYPES.LECTURA_ICON]: {
    id: 'lectura-icon',
    url: '/assets/early-access/home/icons/lectura.svg',
    altText: [
      { language: 'en', altText: 'A lectura icon.' },
      { language: 'es', altText: 'Un icono de lectura.' },
    ],
  },
  [TEST_IMAGE_TYPES.CAT_UNDER_TABLE]: {
    id: 'cat-under-table',
    url: '/helix/app-assets/htec/activity-images/catundertable.jpg',
    altText: [
      { language: 'en', altText: 'A cat under the table' },
      { language: 'es', altText: 'Un gato debajo de la mesa.' },
    ],
  },
  [TEST_IMAGE_TYPES.CHARACTER_LEANOUT]: {
    id: 'test-character-leanout',
    url: '/helix/app-assets/htec/activity-images/characterLeanout.svg',
    altText: [
      {
        language: 'en',
        altText: 'Estrella leans out from the side of the screen.',
      },
      {
        language: 'es',
        altText: 'Estrella se asoma por un lado de la pantalla.',
      },
    ],
  },
  [TEST_IMAGE_TYPES.CAT_BESIDE_TABLE]: {
    id: 'cat-beside-table',
    url: '/helix/app-assets/htec/activity-images/catbesidetable.png',
    altText: [
      { language: 'en', altText: 'Cat Beside Table' },
      { language: 'es', altText: 'Gato al lado de la mesa' },
    ],
  },
};
