<htc-dialog
  [showPopup]="(debugService.showDebugMenu | async) ?? false"
  [useBackground]="true"
  [useWideMode]="false"
  title="Debug Menu"
  (showPopupChange)="onPopupChange($event)">
  <div class="body">
    <div
      class="flex-container flex-row flex-justify-space-between debug-menu-tabs">
      <input
        type="radio"
        id="feature-flags"
        name="debug-tabs"
        [checked]="currentView === debugView.FEATURE_FLAGS"
        (click)="setView(debugView.FEATURE_FLAGS)" />
      <label for="feature-flags">
        <svg-icon
          class="debug-icon"
          [src]="
            'helix/app-assets/htec/button-icons/flag.svg' | cdnSrcBaseUrl: true
          "></svg-icon>
        <p class="debug-text">Feature Flags</p>
      </label>
      <input
        type="radio"
        id="video-settings"
        name="debug-tabs"
        [checked]="currentView === debugView.VIDEO_SETTINGS"
        (click)="setView(debugView.VIDEO_SETTINGS)" />
      <label for="video-settings">
        <svg-icon
          class="debug-icon"
          [src]="
            'helix/app-assets/htec/button-icons/skip-next.svg'
              | cdnSrcBaseUrl: true
          "></svg-icon>
        <p class="debug-text">Video Settings</p>
      </label>
      <input
        type="radio"
        id="kb-debug"
        name="debug-tabs"
        [checked]="currentView === debugView.KB_DEBUG"
        (click)="setView(debugView.KB_DEBUG)" />
      <label for="kb-debug">
        <svg-icon
          class="debug-icon"
          [src]="
            'helix/app-assets/htec/button-icons/knowledge-base.svg'
              | cdnSrcBaseUrl: true
          "></svg-icon>
        <p class="debug-text">KB Debug</p>
      </label>
      <input
        type="radio"
        id="clickstream-debug"
        name="Clickstream Debug"
        [checked]="currentView === debugView.CLICKSTREAM_DEBUG"
        (click)="setView(debugView.CLICKSTREAM_DEBUG)" />
      <label for="clickstream-debug">
        <svg-icon
          class="debug-icon"
          [src]="
            'helix/app-assets/htec/button-icons/knowledge-base.svg'
              | cdnSrcBaseUrl: true
          "></svg-icon>
        <p class="debug-text">Clickstream Debug</p>
      </label>

      <input
        type="radio"
        id="clickstream-debug"
        name="Clickstream Debug"
        [checked]="currentView === debugView.CLICKSTREAM_DEBUG"
        (click)="setView(debugView.CLICKSTREAM_DEBUG)" />
      <label for="clickstream-debug">
        <svg-icon
          class="debug-icon"
          [src]="
            'helix/app-assets/htec/button-icons/knowledge-base.svg'
              | cdnSrcBaseUrl: true
          "></svg-icon>
        <p class="debug-text">Clickstream Debug</p>
      </label>

      <input
        type="radio"
        id="save-data-importer"
        name="debug-tabs"
        [checked]="currentView === debugView.SAVE_DATA_IMPORT_EXPORT"
        (click)="setView(debugView.SAVE_DATA_IMPORT_EXPORT)" />
      <label for="save-data-importer">
        <svg-icon
          class="debug-icon"
          [src]="
            'helix/app-assets/htec/button-icons/import-export.svg'
              | cdnSrcBaseUrl: true
          "></svg-icon>
        <p class="debug-text">Save Data Importer</p>
      </label>
      <input
        type="radio"
        id="save-data-editor"
        name="debug-tabs"
        [checked]="currentView === debugView.SAVE_DATA_EDITOR"
        (click)="setView(debugView.SAVE_DATA_EDITOR)" />
      <label for="save-data-editor">
        <svg-icon
          class="debug-icon"
          [src]="
            'helix/app-assets/htec/button-icons/edit.svg' | cdnSrcBaseUrl: true
          "></svg-icon>
        <p class="debug-text">Save Data Editor</p>
      </label>

      <input
        type="radio"
        id="message-debug"
        name="debug-tabs"
        [checked]="currentView === debugView.MESSAGES_DEBUG"
        (click)="setView(debugView.MESSAGES_DEBUG)" />
      <label for="message-debug">
        <svg-icon
          class="debug-icon"
          [src]="
            'helix/app-assets/htec/button-icons/import-export.svg'
              | cdnSrcBaseUrl: true
          "></svg-icon>
        <p class="debug-text">Messages Debug</p>
      </label>
    </div>

    <div [ngSwitch]="currentView" class="debug-content">
      <ng-container *ngSwitchCase="debugView.VIDEO_SETTINGS">
        <div @fadeInOut>
          <htc-button-with-icon
            (click)="setVideoFinished()"
            classes="btn btn-watched-video btn-with-left-icon"
            [imagePath]="
              'helix/app-assets/htec/button-icons/skip-next.svg'
                | cdnSrcBaseUrl: true
            "
            btnText="Set Watched Video"></htc-button-with-icon>
        </div>
      </ng-container>
      <div @fadeInOut *ngSwitchCase="debugView.FEATURE_FLAGS">
        <htc-feature-flag-menu></htc-feature-flag-menu>
      </div>
      <div @fadeInOut *ngSwitchCase="debugView.KB_DEBUG">
        <htc-kb-debug-menu></htc-kb-debug-menu>
      </div>
      <div @fadeInOut *ngSwitchCase="debugView.CLICKSTREAM_DEBUG">
        <htc-clickstream-debug-menu></htc-clickstream-debug-menu>
      </div>
      <div @fadeInOut *ngSwitchCase="debugView.SAVE_DATA_IMPORT_EXPORT">
        <htc-incomplete-save-data-export-import></htc-incomplete-save-data-export-import>
      </div>
      <div @fadeInOut *ngSwitchCase="debugView.SAVE_DATA_EDITOR">
        <htc-incomplete-save-data-editor></htc-incomplete-save-data-editor>
      </div>
      <div @fadeInOut *ngSwitchCase="debugView.MESSAGES_DEBUG">
        <htc-messages-debug-menu></htc-messages-debug-menu>
      </div>
    </div>
  </div>
</htc-dialog>
