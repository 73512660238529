export enum PageTransitions {
  NONE = '',
  PANE_IN_UPWARDS = 'pane-in-upwards',
  PANE_OUT_DOWNWARDS = 'pane-out-downwards',
  SLIDE_IN_FROM_RIGHT = 'slide-in-from-right',
  SLIDE_OUT_TO_LEFT = 'slide-out-to-left',
  SLIDE_IN_TO_RIGHT = 'slide-in-to-right',
  FAST_FADE_IN = 'fast-fade-in',
  FADE_IN = 'fade-in',
  FADE_OUT = 'fade-out',
  HIDDEN_TO_UNSET = 'hidden-to-unset',
  HIDDEN = 'hidden',
}
