<video-js
  id="videoTarget"
  #videoTarget
  class="video-js vjs-theme-main"
  [ngClass]="{ 'video-popup': isPopup }"
  crossorigin="anonymous"
  [autofocus]="autofocus"
  data-testid="htc-video-player">
  <p class="vjs-no-js" data-testid="htc-video-player-no-js">
    To view this video please enable JavaScript, and consider upgrading to a web
    browser that
    <a href="https://videojs.com/html5-video-support/" target="_blank"
      >supports HTML5 video</a
    >
  </p>
</video-js>

<div class="vjs-menu-button-popup vjs-hidden">
  <div class="vjs-menu" #videoSettings>
    <ul class="vjs-menu-content" role="menu">
      <li class="transcript-group flexed-group">
        <img class="transcript-icon" [src]="iconPath + 'transcript.svg'" />
        <div class="transcript-title flexed-title"></div>
        <div class="transcript-button-group">
          <button
            class="toggle toggler-off"
            id="video-transcript-toggle"
            data-testid="htc-vp-transcript-toggle">
            <div class="label"></div>
            <div class="bg">
              <div class="toggler"></div>
            </div>
          </button>
        </div>
      </li>
      <li class="captions-group flexed-group">
        <img
          class="closed-captions-icon ignore-pointer-events"
          [src]="iconPath + 'closed-caption.svg'" />
        <div class="captions-title flexed-title"></div>
        <div class="captions-button-group">
          <button
            class="toggle toggler-off"
            id="video-captions-toggle"
            data-testid="htc-vp-captions-toggle">
            <div class="label ignore-pointer-events"></div>
            <div class="bg ignore-pointer-events">
              <div class="toggler"></div>
            </div>
          </button>
        </div>
      </li>
      <li class="font-size-group flexed-group">
        <div class="font-size-title flexed-title"></div>
        <div class="font-size-button-group">
          <button
            class="double-a small-as"
            id="small-font-size-toggle"
            type="button"
            aria-disabled="false"
            data-title="Small Font Size"
            data-testid="htc-vp-small-font-size-toggle"></button>
          <button
            class="double-a medium-as active"
            id="medium-font-size-toggle"
            type="button"
            aria-disabled="false"
            data-title="Medium Font Size"
            data-testid="htc-vp-medium-font-size-toggle"></button>
          <button
            class="double-a large-as"
            id="large-font-size-toggle"
            type="button"
            aria-disabled="false"
            data-title="Large Font Size"
            data-testid="htc-vp-large-font-size-toggle"></button>
        </div>
      </li>
    </ul>
  </div>
</div>
