/* eslint-disable @typescript-eslint/no-explicit-any */
import { KnowledgeBaseStoreResponse } from 'src/app/modules/olp-api/types';

export type queueItem = {
  action: () => Promise<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resolve: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reject: any;
};

class Queue {
  _items: queueItem[] = [];
  constructor() {
    this._items = [];
  }
  enqueue(item: queueItem): void {
    this._items.push(item);
  }
  dequeue(): queueItem | undefined {
    return this._items.shift();
  }
  get size(): number {
    return this._items.length;
  }

  get currentItem(): queueItem | undefined {
    return this._items[0];
  }
}

export default class AutoQueue extends Queue {
  _pendingPromise = false;

  constructor() {
    super();
    this._pendingPromise = false;
  }

  enqueueAQ(action: () => Promise<any>): Promise<KnowledgeBaseStoreResponse> {
    return new Promise((resolve, reject) => {
      super.enqueue({ action, resolve, reject });
      this.dequeueAQ();
    });
  }

  async dequeueAQ(): Promise<boolean> {
    if (this._pendingPromise) {
      return false;
    }

    const item = super.dequeue();

    if (!item) return false;

    this._pendingPromise = true;
    await item
      .action()
      .then(res => {
        this._pendingPromise = false;
        item.resolve(res);
      })
      .catch(e => {
        this._pendingPromise = false;
        item.reject(e);
      })
      .finally(() => {
        this.dequeueAQ();
      });
    return true;
  }
}
