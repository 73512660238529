import videojs from 'video.js';

const Menu = videojs.getComponent('Menu');

class CustomMenu extends Menu {
  constructor(player: videojs.Player, options: videojs.MenuOptions) {
    super(player, options);
  }

  handleBlur(event: videojs.EventTarget.Event): void {
    const relatedTarget = event.relatedTarget || document.activeElement;

    // Close menu popup when a user clicks outside the menu
    if (
      !this.children().some(element => {
        return element.el() === relatedTarget;
      })
    ) {
      const btn = this.menuButton_;

      if (
        btn &&
        !btn.el().contains(relatedTarget) &&
        btn.el() !== relatedTarget
      ) {
        btn.unpressButton();
      }
    }
  }

  // Overriding default behavior of unpressing menu button
  // on menu item click
  handleTapClick(event: videojs.EventTarget.Event): void {
    console.log(event);
  }
}

videojs.registerComponent('CustomMenu', CustomMenu);
export default CustomMenu;
