import { Activity } from 'src/app/shared/interfaces';

export const readingPrep4: Activity = {
  id: '018ea00f-3cff-70c0-b58d-8f071c649df4',
  product: 'Reading',
  language: 'en',
  skillName: 'Prepositions',
  thumbnail: {
    id: 'recjexZF7wJx6ze6T',
    url: '/helix/UX_Images/Lesson_Posters/Prepositions_G3V4_Poster.jpg',
    altText: [
      {
        language: 'en',
        altText: 'Axis and CAT look at a panel on top of the spaceship.',
      },
      {
        language: 'es',
        altText:
          'Axis y G.A.T.A. observan un panel encima de la nave espacial.',
      },
    ],
  },
  instructionsImage: {
    id: 'recShqii4tO2JCQyf',
    url: '/helix/UX_Images/Characters/Axis/Axis_Chibi_Head_Happy_Mouth_Open.svg',
    altText: [
      {
        language: 'en',
        altText: 'Axis is smiling.',
      },
      {
        language: 'es',
        altText: 'Axis está sonriendo.',
      },
    ],
  },
  characterLeanout: {
    id: 'recBBGySIgxHLnC6k',
    url: '/helix/TEI/Leanouts/Axis_Leanout_01.svg',
    altText: [
      {
        language: 'en',
        altText: 'Axis leans out from the side of the screen.',
      },
      {
        language: 'es',
        altText: 'Axis se asoma por el lado de la pantalla.',
      },
    ],
  },
  description:
    'Axis and C.A.T. found their missing ship pieces, but putting it all back together might be tricky. Their instruction manual was also damaged, so this dynamic duo will need to take their knowledge of prepositions and fill in the gaps.',
  domain: {
    text: 'Parts of Speech',
  },
  seriesName: "Axis's Big Break",
  episodeNumber: '4',
  episodeTitle: 'Glitch in the Manual',
  videos: [
    {
      id: 'recmopwfsHey8m6mH',
      url: '/helix/Reading/Grade_3/Prepositions/Episode_04/hls_streams/R_G3_Prepositions_EP04_VIdeo.m3u8',
      posterURL: '/helix/UX_Images/Lesson_Posters/Prepositions_G3V4_Poster.jpg',
      captions: [
        {
          src: '/helix/Reading/Grade_3/Prepositions/Episode_04/R_G3_Prepositions_EP04_VIdeo.vtt',
          srclang: 'en',
          mode: 'hidden',
          kind: 'captions',
        },
      ],
    },
  ],
  startScreenData: {
    learningTarget: [
      {
        id: '33744.D',
        learningTarget: {
          text: 'how to use prepositions to form prepositional phrases',
        },
        successCriteria: [
          {
            id: '33744.D.1',
            text: 'use prepositions to create a prepositional phrase that modifies nouns and pronouns',
          },
          {
            id: '33744.D.2',
            text: 'create sentences using prepositional phrases that modifiy nouns and pronouns',
          },
        ],
      },
    ],
    background_leftImage: {
      id: 'recjexZF7wJx6ze6T',
      url: '/helix/UX_Images/Lesson_Posters/Prepositions_G3V4_Poster.jpg',
      altText: [
        {
          language: 'en',
          altText: 'Axis and CAT look at a panel on top of the spaceship.',
        },
        {
          language: 'es',
          altText:
            'Axis y G.A.T.A. observan un panel encima de la nave espacial.',
        },
      ],
    },
    background_rightImage: {
      id: 'recxjC353VMav5ybu',
      url: '/helix/UX_Images/Start_Screen_Blobs/Nova-Scouts_space-sky.svg',
      altText: [
        {
          language: 'en',
          altText: 'Stars in space.',
        },
        {
          language: 'es',
          altText: 'Estrellas en el espacio.',
        },
      ],
    },
    foreground: {
      id: 'recJFZiQMxFozF9ks',
      url: '/helix/UX_Images/Start_Screen_Characters/Axis3.svg',
      altText: [
        {
          language: 'en',
          altText: 'Axis is pointing at something.',
        },
        {
          language: 'es',
          altText: 'Axis está señalando algo.',
        },
      ],
    },
  },
  populations: [
    {
      id: '33744.D.1.1',
      templateID: 0,
      complexity: 'Application',
      hint: [
        {
          id: '33744.D.1.1.0',
          type: 'BasicSlide',
          data: {
            title: 'Look closely at where objects are in the picture.\n',
          },
        },
        {
          id: '33744.D.1.1.1',
          type: 'BasicSlide',
          data: {
            title: 'Prepositions often give a specific location.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Look at the picture. Choose the correct preposition to complete the sentence.\n\nThe books are \\_\\_\\_ the shelf.\n',
        },
        answerChoices: [
          {
            id: '33744.D.1.1_1',
            choice: {
              texts: [
                {
                  text: 'on\n',
                },
              ],
            },
          },
          {
            id: '33744.D.1.1_2',
            choice: {
              texts: [
                {
                  text: 'under\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'The desk and computer are under the shelf, not the books.\n',
              },
            },
          },
          {
            id: '33744.D.1.1_3',
            choice: {
              texts: [
                {
                  text: 'in\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: '_In_ means something is enclosed.\n',
              },
            },
          },
        ],
        answers: ['33744.D.1.1_1'],
        associatedSuccessCriteria: ['33744.D.1'],
      },
      feedbackSecondIncorrect: {
        content:
          '_On_ can mean something is supported by a surface, like a shelf.\n',
      },
      image: {
        id: 'recpNzs8TZK2F2tCo',
        url: '/helix/TEI/SVGs/TEI_prepositions_bedroom_01.svg',
        altText: [
          {
            language: 'en',
            altText: 'A bedroom with a guitar, backpack, and rollerblades.',
          },
          {
            language: 'es',
            altText:
              'Una habitación con una guitarra, una mochila y unos patines.',
          },
        ],
      },
    },
    {
      id: '33744.D.1.2',
      templateID: 0,
      complexity: 'Application',
      hint: [
        {
          id: '33744.D.1.2.0',
          type: 'BasicSlide',
          data: {
            title: 'Look closely at where the object is in the picture.\n',
          },
        },
        {
          id: '33744.D.1.2.1',
          type: 'BasicSlide',
          data: {
            title: 'Prepositions often give a specific location.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Look at the picture. Choose the correct preposition to complete the sentence.\n\nThe guitar is leaning \\_\\_\\_ the wall.\n',
        },
        answerChoices: [
          {
            id: '33744.D.1.2_1',
            choice: {
              texts: [
                {
                  text: 'against\n',
                },
              ],
            },
          },
          {
            id: '33744.D.1.2_2',
            choice: {
              texts: [
                {
                  text: 'under\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: 'It would be hard to see something under a wall.\n',
              },
            },
          },
          {
            id: '33744.D.1.2_3',
            choice: {
              texts: [
                {
                  text: 'above\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: "_Above_ would mean it's on the ceiling.\n",
              },
            },
          },
        ],
        answers: ['33744.D.1.2_1'],
        associatedSuccessCriteria: ['33744.D.1'],
      },
      feedbackSecondIncorrect: {
        content: 'The guitar is not under or above anything in the room.\n',
      },
      image: {
        id: 'recpNzs8TZK2F2tCo',
        url: '/helix/TEI/SVGs/TEI_prepositions_bedroom_01.svg',
        altText: [
          {
            language: 'en',
            altText: 'A bedroom with a guitar, backpack, and rollerblades.',
          },
          {
            language: 'es',
            altText:
              'Una habitación con una guitarra, una mochila y unos patines.',
          },
        ],
      },
    },
    {
      id: '33744.D.1.3',
      templateID: 0,
      complexity: 'Application',
      hint: [
        {
          id: '33744.D.1.3.0',
          type: 'BasicSlide',
          data: {
            title: 'Look closely at where the object is in the picture.\n',
          },
        },
        {
          id: '33744.D.1.3.1',
          type: 'BasicSlide',
          data: {
            title: 'Prepositions often give a specific location.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Look at the picture. Choose the correct preposition to complete the sentence.\n\nA poster is taped \\_\\_\\_ the wall.\n',
        },
        answerChoices: [
          {
            id: '33744.D.1.3_1',
            choice: {
              texts: [
                {
                  text: 'on\n',
                },
              ],
            },
          },
          {
            id: '33744.D.1.3_2',
            choice: {
              texts: [
                {
                  text: 'behind\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  "If the poster was behind the wall, you wouldn't be able to see it.\n",
              },
            },
          },
          {
            id: '33744.D.1.3_3',
            choice: {
              texts: [
                {
                  text: 'through\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '_Through_ is a preposition that shows motion. We need one for location.\n',
              },
            },
          },
        ],
        answers: ['33744.D.1.3_1'],
        associatedSuccessCriteria: ['33744.D.1'],
      },
      feedbackSecondIncorrect: {
        content: '_On_ tells us where in the image the picture is hanging.\n',
      },
      image: {
        id: 'recpNzs8TZK2F2tCo',
        url: '/helix/TEI/SVGs/TEI_prepositions_bedroom_01.svg',
        altText: [
          {
            language: 'en',
            altText: 'A bedroom with a guitar, backpack, and rollerblades.',
          },
          {
            language: 'es',
            altText:
              'Una habitación con una guitarra, una mochila y unos patines.',
          },
        ],
      },
    },
    {
      id: '33744.D.2.4',
      templateID: 1,
      complexity: 'Application',
      hint: [
        {
          id: '33744.D.2.4.0',
          type: 'BasicSlide',
          data: {
            title: 'Look closely at where objects are in the picture.\n',
          },
        },
        {
          id: '33744.D.2.4.1',
          type: 'BasicSlide',
          data: {
            title:
              'Find the object first. Then compare to the answer choices.\n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Choose the best prepositional phrase to complete the sentence.\nThe skates are {CHOICE_TOKEN}.\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '33744.D.2.4_1',
                  text: 'on the floor\n',
                },
                {
                  id: '33744.D.2.4_2',
                  text: 'on the desk\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content: 'I see a computer on the desk but no skates.\n',
                    },
                  },
                },
                {
                  id: '33744.D.2.4_3',
                  text: 'on the shelf\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content: 'The shelf is a bit high for skates.\n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['33744.D.2.4_1'],
        associatedSuccessCriteria: ['33744.D.2'],
      },
      feedbackSecondIncorrect: {
        content: 'Books are on the shelf, and the computer is on the desk.\n',
      },
      image: {
        id: 'recpNzs8TZK2F2tCo',
        url: '/helix/TEI/SVGs/TEI_prepositions_bedroom_01.svg',
        altText: [
          {
            language: 'en',
            altText: 'A bedroom with a guitar, backpack, and rollerblades.',
          },
          {
            language: 'es',
            altText:
              'Una habitación con una guitarra, una mochila y unos patines.',
          },
        ],
      },
    },
    {
      id: '33744.D.2.5',
      templateID: 1,
      complexity: 'Application',
      hint: [
        {
          id: '33744.D.2.5.0',
          type: 'BasicSlide',
          data: {
            title: 'Look closely at where objects are in the picture.\n',
          },
        },
        {
          id: '33744.D.2.5.1',
          type: 'BasicSlide',
          data: {
            title:
              'Find the object first. Then compare to the answer choices.\n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Choose the best prepositional phrase to complete the sentence.\nThe shoes are {CHOICE_TOKEN}.\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '33744.D.2.5_1',
                  text: 'under the bed\n',
                },
                {
                  id: '33744.D.2.5_2',
                  text: 'under the desk\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content: "I don't see anything under the desk.\n",
                    },
                  },
                },
                {
                  id: '33744.D.2.5_3',
                  text: 'under the chair\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content: 'I see something on the chair but not under.\n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['33744.D.2.5_1'],
        associatedSuccessCriteria: ['33744.D.2'],
      },
      feedbackSecondIncorrect: {
        content: 'Nothing is under the desk or chair.\n',
      },
      image: {
        id: 'recpNzs8TZK2F2tCo',
        url: '/helix/TEI/SVGs/TEI_prepositions_bedroom_01.svg',
        altText: [
          {
            language: 'en',
            altText: 'A bedroom with a guitar, backpack, and rollerblades.',
          },
          {
            language: 'es',
            altText:
              'Una habitación con una guitarra, una mochila y unos patines.',
          },
        ],
      },
    },
    {
      id: '33744.D.2.6',
      templateID: 1,
      complexity: 'Application',
      hint: [
        {
          id: '33744.D.2.6.0',
          type: 'BasicSlide',
          data: {
            title: 'Look closely at where the object is in the picture.\n',
          },
        },
        {
          id: '33744.D.2.6.1',
          type: 'BasicSlide',
          data: {
            title:
              'Find the object first. Then compare to the answer choices.\n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Choose the best prepositional phrase to complete the sentence.\nThe shirt is {CHOICE_TOKEN}.\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '33744.D.2.6_1',
                  text: 'in the closet\n',
                },
                {
                  id: '33744.D.2.6_2',
                  text: 'in the drawers\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content: "We can't see what's in the drawers.\n",
                    },
                  },
                },
                {
                  id: '33744.D.2.6_3',
                  text: 'in the desk\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content: "We can't see what's in the desk.\n",
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['33744.D.2.6_1'],
        associatedSuccessCriteria: ['33744.D.2'],
      },
      feedbackSecondIncorrect: {
        content: 'Shirts are the only thing in the closet.\n',
      },
      image: {
        id: 'recpNzs8TZK2F2tCo',
        url: '/helix/TEI/SVGs/TEI_prepositions_bedroom_01.svg',
        altText: [
          {
            language: 'en',
            altText: 'A bedroom with a guitar, backpack, and rollerblades.',
          },
          {
            language: 'es',
            altText:
              'Una habitación con una guitarra, una mochila y unos patines.',
          },
        ],
      },
    },
    {
      id: '33744.D.2.7',
      templateID: 5,
      complexity: 'Analysis',
      hint: [
        {
          id: '33744.D.2.7.0',
          type: 'BasicSlide',
          data: {
            title:
              'Remember that prepositions usually show when, where, or how something happened.\n',
          },
        },
        {
          id: '33744.D.2.7.1',
          type: 'BasicSlide',
          data: {
            title:
              'The subject of the sentence gives you a clue about what type of preposition you need.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Choose which prepositional phrases could complete the sentence. (Pick 3)\n\nShe will play tennis \\_\\_\\_.\n',
        },
        answerChoices: [
          {
            id: '33744.D.2.7_1',
            choice: {
              texts: [
                {
                  text: "at 8 o'clock\n",
                },
              ],
            },
          },
          {
            id: '33744.D.2.7_2',
            choice: {
              texts: [
                {
                  text: 'on the tennis court\n',
                },
              ],
            },
          },
          {
            id: '33744.D.2.7_3',
            choice: {
              texts: [
                {
                  text: 'with her friends\n',
                },
              ],
            },
          },
          {
            id: '33744.D.2.7_4',
            choice: {
              texts: [
                {
                  text: 'inside your ear\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  "While this might be possible inside a giant's ear, I don't think it's possible in your ear!\n",
              },
            },
          },
          {
            id: '33744.D.2.7_5',
            choice: {
              texts: [
                {
                  text: 'at the bus\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  "You typically play tennis at a tennis court, not at the bus! Let's try again.\n",
              },
            },
          },
        ],
        answers: ['33744.D.2.7_1', '33744.D.2.7_2', '33744.D.2.7_3'],
        associatedSuccessCriteria: ['33744.D.2'],
      },
      feedbackSecondIncorrect: {
        content: 'These phrases show when, where, and how she plays tennis.\n',
      },
    },
    {
      id: '33744.D.2.8',
      templateID: 5,
      complexity: 'Analysis',
      hint: [
        {
          id: '33744.D.2.8.0',
          type: 'BasicSlide',
          data: {
            title:
              'Remember that prepositions usually show when, where, or how something happened.\n',
          },
        },
        {
          id: '33744.D.2.8.1',
          type: 'BasicSlide',
          data: {
            title:
              'The subject of the sentence gives you a clue about what type of preposition you need.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Choose which prepositional phrases could complete the sentence. (Pick 3)\n\nDinner will be served \\_\\_\\_.\n',
        },
        answerChoices: [
          {
            id: '33744.D.2.8_1',
            choice: {
              texts: [
                {
                  text: 'with mashed potatoes\n',
                },
              ],
            },
          },
          {
            id: '33744.D.2.8_2',
            choice: {
              texts: [
                {
                  text: 'after we get home\n',
                },
              ],
            },
          },
          {
            id: '33744.D.2.8_3',
            choice: {
              texts: [
                {
                  text: 'before dessert\n',
                },
              ],
            },
          },
          {
            id: '33744.D.2.8_4',
            choice: {
              texts: [
                {
                  text: 'for a walk\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  "You don't usually serve dinner at the same time you go for a walk.\n",
              },
            },
          },
          {
            id: '33744.D.2.8_5',
            choice: {
              texts: [
                {
                  text: 'through the radio\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: "You can't get dinner through a radio!\n",
              },
            },
          },
        ],
        answers: ['33744.D.2.8_1', '33744.D.2.8_2', '33744.D.2.8_3'],
        associatedSuccessCriteria: ['33744.D.2'],
      },
      feedbackSecondIncorrect: {
        content: 'These phrases make the most sense with dinner.\n',
      },
    },
    {
      id: '33744.D.1.9',
      templateID: 5,
      complexity: 'Application',
      hint: [
        {
          id: '33744.D.1.9.0',
          type: 'BasicSlide',
          data: {
            title: 'Look at how the preposition is used.\n',
          },
        },
        {
          id: '33744.D.1.9.1',
          type: 'BasicSlide',
          data: {
            title:
              '_In_ is usually used to show a specific time of day or specific place.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Identify the correct use of the preposition "in." (Pick 3)\n',
        },
        answerChoices: [
          {
            id: '33744.D.1.9_1',
            choice: {
              texts: [
                {
                  text: 'in the summer\n',
                },
              ],
            },
          },
          {
            id: '33744.D.1.9_2',
            choice: {
              texts: [
                {
                  text: 'in the afternoon\n',
                },
              ],
            },
          },
          {
            id: '33744.D.1.9_3',
            choice: {
              texts: [
                {
                  text: 'in the past\n',
                },
              ],
            },
          },
          {
            id: '33744.D.1.9_4',
            choice: {
              texts: [
                {
                  text: 'in Monday\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: 'Usually you would say _on_Monday instead of _in_.\n',
              },
            },
          },
          {
            id: '33744.D.1.9_5',
            choice: {
              texts: [
                {
                  text: 'in lunchtime\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '_At_ would be a better preposition to use because lunchtime is a specific time.\n',
              },
            },
          },
        ],
        answers: ['33744.D.1.9_1', '33744.D.1.9_2', '33744.D.1.9_3'],
        associatedSuccessCriteria: ['33744.D.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'These are correct because they use _in_ to show a general time or place with the appropriate articles in between.\n',
      },
    },
    {
      id: '33744.D.1.10',
      templateID: 5,
      complexity: 'Application',
      hint: [
        {
          id: '33744.D.1.10.0',
          type: 'BasicSlide',
          data: {
            title: 'Look at how the preposition is used.\n',
          },
        },
        {
          id: '33744.D.1.10.1',
          type: 'BasicSlide',
          data: {
            title:
              '_At_ can be used to show when or where something happens.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Identify the correct use of the preposition "at." (Pick 3)\n',
        },
        answerChoices: [
          {
            id: '33744.D.1.10_1',
            choice: {
              texts: [
                {
                  text: 'at midnight\n',
                },
              ],
            },
          },
          {
            id: '33744.D.1.10_2',
            choice: {
              texts: [
                {
                  text: 'at the airport\n',
                },
              ],
            },
          },
          {
            id: '33744.D.1.10_3',
            choice: {
              texts: [
                {
                  text: 'at work\n',
                },
              ],
            },
          },
          {
            id: '33744.D.1.10_4',
            choice: {
              texts: [
                {
                  text: 'at the future\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: '_At_ is used to show a more specific time.\n',
              },
            },
          },
          {
            id: '33744.D.1.10_5',
            choice: {
              texts: [
                {
                  text: 'at the morning\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'A prepositional phrase needs a preposition and an object.\n',
              },
            },
          },
          {
            id: '33744.D.1.10_6',
            choice: {
              texts: [
                {
                  text: 'at April\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'When referring to the months of the year, _in_ is the best preposition to use.\n',
              },
            },
          },
        ],
        answers: ['33744.D.1.10_1', '33744.D.1.10_2', '33744.D.1.10_3'],
        associatedSuccessCriteria: ['33744.D.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'These are correct because _at_ is used to show a specific time or place.\n',
      },
    },
  ],
  nextUp: {
    nextActivityId: '018ea00f-3d50-7475-be50-32746f35e97d',
    nextActivityName: 'Discovery is Ours',
    nextActivityThumbnail: {
      id: 'recrLsLbsbxSPF0xx',
      url: '/helix/UX_Images/Lesson_Posters/Prepositions_G3V5_Poster.jpg',
      altText: [
        {
          language: 'en',
          altText:
            'Axis, Estrella, and CAT do the Nova Scouts salute in the cockpit.',
        },
        {
          language: 'es',
          altText:
            'Axis, Estrella y G.A.T.A. presentan el saludo de los Exploradores Nova en la cabina de la nave.',
        },
      ],
    },
  },
  activityManifest: [
    {
      type: 'START_SCREEN',
    },
    {
      type: 'ENTRY_SURVEY',
    },
    {
      type: 'VIDEO',
      id: 'recmopwfsHey8m6mH',
    },
    {
      type: 'QUESTION',
      id: '33744.D.1.1',
    },
    {
      type: 'QUESTION',
      id: '33744.D.1.2',
    },
    {
      type: 'QUESTION',
      id: '33744.D.1.3',
    },
    {
      type: 'QUESTION',
      id: '33744.D.2.4',
    },
    {
      type: 'QUESTION',
      id: '33744.D.2.5',
    },
    {
      type: 'QUESTION',
      id: '33744.D.2.6',
    },
    {
      type: 'QUESTION',
      id: '33744.D.2.7',
    },
    {
      type: 'QUESTION',
      id: '33744.D.2.8',
    },
    {
      type: 'QUESTION',
      id: '33744.D.1.9',
    },
    {
      type: 'QUESTION',
      id: '33744.D.1.10',
    },
    {
      type: 'EXIT_SURVEY',
    },
    {
      type: 'RESULTS_SCREEN',
    },
  ],
  version: '1562962166',
};
