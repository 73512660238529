<div
  class="input-shield"
  *ngIf="showPopup"
  htcTrapFocus
  role="dialog"
  attr.aria-labelledby="{{ 'PROFILE_MENU.SUPPORT' | translate }}">
  <div class="popup" [attr.aria-hidden]="!showPopup">
    <div class="header">
      <svg-icon
        src="assets/button-icons/support.svg"
        attr.alt="{{ 'PROFILE_MENU.SUPPORT' | translate }}"></svg-icon>

      <span>{{ 'PROFILE_MENU.SUPPORT' | translate }}</span>

      <htc-button-with-icon
        data-testid="htc-contact-support-close-button"
        classes="btn btn-no-border btn-no-bkgd btn-contact-support-close interactive-cmp"
        imagePath="/assets/button-icons/cancel-x.svg"
        btnText=""
        attr.aria-label="{{
          'PROFILE_MENU.CLOSE_SUPPORT_ARIA_LABEL' | translate
        }}"
        (btnClicked)="closePopup()"></htc-button-with-icon>
    </div>

    <div class="content-container">
      <h3 class="flex-container flex-align-center">
        {{ 'PROFILE_MENU.CONTACT_SUPPORT' | translate }}
      </h3>
      <div class="contact-block">
        <p class="flex-container flex-align-center">
          {{ 'PROFILE_MENU.SUPPORT_DAYS' | translate }}
        </p>
        <p class="flex-container flex-align-center">
          {{ 'PROFILE_MENU.SUPPORT_TIME' | translate }}
        </p>
      </div>
      <div class="contact-block contact-block-icon">
        <svg-icon
          src="assets/button-icons/phone.svg"
          attr.alt="{{ 'PROFILE_MENU.PHONE_ALT' | translate }}"></svg-icon>
        <span>
          <p class="flex-container flex-align-center">
            {{ 'PROFILE_MENU.PHONE_NUMBER' | translate }}
          </p>
          <p class="flex-container flex-align-center">
            {{ 'PROFILE_MENU.TOLL_FREE' | translate }}
          </p>
        </span>
      </div>
      <div class="contact-block contact-block-icon contact-block-icon-action">
        <svg-icon
          src="assets/button-icons/mail.svg"
          attr.alt="{{ 'PROFILE_MENU.EMAIL_ALT' | translate }}"></svg-icon>
        <span>
          <a
            data-testid="htc-contact-support-email-link"
            class="interactive-cmp"
            href="mailto:support@istation.com"
            title="{{ 'PROFILE_MENU.EMAIL_TITLE' | translate }}"
            htcAudioClick
            [audioType]="clickType"
            [descriptor]="clickDescriptor">
            Support&#64;istation.com
          </a>
        </span>
      </div>
      <div class="contact-block-bottom">
        <span class="versions"
          >{{ 'PROFILE_MENU.APP_VERSION' | translate }} {{ version }}
        </span>
      </div>
    </div>
  </div>
</div>
