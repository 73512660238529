import {
  UrlTree,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { FeatureFlagService } from 'src/app/modules/feature-flags/services/feature-flag/feature-flag.service';
import { FeatureFlags } from '../enums/feature-flags';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OptionalLoginGuard {
  constructor(
    private featureFlagService: FeatureFlagService,
    private loginGuard: AutoLoginPartialRoutesGuard
  ) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.featureFlagService.isFlagEnabled(FeatureFlags.AUTHENTICATE)) {
      return this.loginGuard.canActivate(_route, _state);
    } else if (
      this.featureFlagService.isFlagEnabled(FeatureFlags.EARLY_ACCESS)
    ) {
      return true;
    } else {
      return true;
    }
  }
}
