import { Component } from '@angular/core';

@Component({
  selector: 'htc-status-perfect',
  templateUrl: './status-perfect.component.html',
  styleUrls: ['../progress-node/progress-node.component.scss'],
})
export class StatusPerfectComponent {
  readonly path = '/assets/progress-node-icons/';
  readonly imageUrl = 'diamond-base.svg';

  constructor() {}
}
