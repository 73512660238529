import videojs from 'video.js';
import {
  IChildAttribute,
  IChildInnerHTML,
  ICustomMenuButtonOptions,
} from '../../interfaces';
import { SettingsPopupsService } from '../../services/settings-popups.service';
import { UtilsService } from '../../services/utils.service';
import CustomMenu from '../custom-menu/custom-menu.component';

const MenuButton = videojs.getComponent('MenuButton');
const MenuItem = videojs.getComponent('MenuItem');

export class CustomMenuButton extends MenuButton {
  settingsService!: SettingsPopupsService;
  enabled_ = true;
  buttonPressed_ = false;
  childInnerHTMLs!: IChildInnerHTML[];
  childAttributes!: IChildAttribute[];

  constructor(player: videojs.Player, options: ICustomMenuButtonOptions) {
    super(player, options);

    if (options.classNames) {
      this.addClass(options.classNames);
    }

    if (options.title) {
      this.controlText(options.title);
    }

    if (options.dataTestId) {
      this.setAttribute('data-testid', options.dataTestId);
    }
  }

  setup(
    settingsService: SettingsPopupsService,
    utilsService: UtilsService
  ): void {
    this.settingsService = settingsService;
    utilsService.onText = this.localize('on');
    utilsService.offText = this.localize('off');
    const localSelect = this.localize('Selected');
    utilsService.selectedSpan = utilsService.selectedSpan.replace(
      utilsService.selectedText,
      localSelect
    );
    utilsService.selectedText = localSelect;
    this.settingsService.subMenuClickListeners(this.el());
  }

  initChildrenVars(): void {
    this.childInnerHTMLs = [
      { selectors: '.toggler-off .label', innerHTML: this.localize('off') },
      { selectors: '.toggler-on .label', innerHTML: this.localize('on') },
      {
        selectors: '.small-as',
        innerHTML: `Aa<span class='visually-hidden'>${this.localize(
          'Small font size'
        )}</span>`,
      },
      {
        selectors: '.medium-as',
        innerHTML: `Aa<span class='visually-hidden'>${this.localize(
          'Medium font size'
        )}</span><span class='visually-hidden'>(${this.localize(
          'Selected'
        )})</span>`,
      },
      {
        selectors: '.large-as',
        innerHTML: `Aa<span class='visually-hidden'>${this.localize(
          'Large font size'
        )}</span>`,
      },
    ];
    this.childAttributes = [
      {
        selectors: '.transcript-icon',
        attribute: 'alt',
        value: this.localize('Transcript icon'),
      },
      {
        selectors: '.closed-captions-icon',
        attribute: 'alt',
        value: this.localize('Closed captions icon'),
      },
      {
        selectors: '.small-as',
        attribute: 'Title',
        value: this.localize('Small font size'),
      },
      {
        selectors: '.medium-as',
        attribute: 'Title',
        value: `${this.localize('Medium font size')} (${this.localize(
          'Selected'
        )})`,
      },
      {
        selectors: '.large-as',
        attribute: 'Title',
        value: this.localize('Large font size'),
      },
    ];
  }

  createMenu(): videojs.Menu {
    this.initChildrenVars();
    const menu = new CustomMenu(this.player_, { menuButton: this });

    this.addItems(menu);

    return menu;
  }

  addItems(menu: CustomMenu): void {
    const items = this.createItems();
    for (let i = 0; i < items.length; i++) {
      menu.addItem(items[i]);
    }
  }

  createItems(): videojs.MenuItem[] {
    const options_: ICustomMenuButtonOptions = this.options_;
    const player = this.player();
    if (options_.myItems) {
      return options_.myItems.map(
        (i: {
          name: string;
          id: string;
          htmlClass: string;
          addClasses: string[];
        }) => {
          const item = new MenuItem(player, {
            label: this.localize(i.name),
            id: i.id,
          });

          item.controlText(i.name);

          // so have options for tab targeting as well
          if (i.addClasses.includes('radial-group')) {
            item.handleClick = (): void => {
              this.cycleRadialButtonClick(item.el());
            };
          } else {
            item.handleClick = (): void => {
              this.toggleButtonClick(item.el());
            };
          }

          const contentEl = item.contentEl();
          contentEl.id = i.id;
          i.addClasses.forEach((elClass: string) => {
            contentEl.classList.add(elClass);
          });
          contentEl.classList.remove('vjs-menu-item');

          const contentGrp = document.querySelector(i.htmlClass);
          if (contentGrp) {
            contentEl.innerHTML = contentGrp?.innerHTML;

            UtilsService.findChildEditInnerHTML(
              contentEl,
              '.flexed-title',
              this.localize(i.name)
            );

            this.childInnerHTMLs.forEach(child => {
              UtilsService.findChildEditInnerHTML(
                contentEl,
                child.selectors,
                child.innerHTML
              );
            });

            this.childAttributes.forEach(child => {
              UtilsService.findChildEditAttribute(
                contentEl,
                child.selectors,
                child.attribute,
                child.value
              );
            });
          }

          return item;
        }
      );
    }

    return [];
  }

  toggleButtonClick(el: Element): void {
    const toggleBtn = el.querySelector('.toggle');
    const clickEvent = new Event('click');
    toggleBtn?.dispatchEvent(clickEvent);
  }

  cycleRadialButtonClick(el: Element): void {
    const activeBtn = el.querySelector('.active');
    if (activeBtn) {
      const parentEl = activeBtn.parentElement;
      if (parentEl) {
        const radialButtons = parentEl.children;
        const index = Array.prototype.indexOf.call(radialButtons, activeBtn);
        const len = radialButtons.length;

        const clickEvent = new Event('click');
        if (index === len - 1) {
          parentEl?.children[0]?.dispatchEvent(clickEvent);
        } else {
          activeBtn?.nextSibling?.dispatchEvent(clickEvent);
        }
      }
    }
  }
}
