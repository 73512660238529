<div class="header-secondary" data-testid="htc-header-secondary">
  <span class="header-secondary-number" data-testid="htc-episode-number"
    >{{ 'SUB_HEADER.EPISODE' | translate }} {{ episode }}</span
  >
  <htc-progress-bar
    class="header-secondary-progress"
    *ngIf="showProgressBar"
    [nodes$]="pageProgressService.progressService.shownNodes$"
    [config]="pageProgressService.progressService.Config"
    (clickedEvent)="onProgressNodeClick($event)"
    [enabled]="progressBarEnabled"
    data-testid="htc-progress-bar">
  </htc-progress-bar>
  <htc-time-elapsed
    class="header-secondary-time"
    data-testid="htc-elapsed-time"
    *ngIf="showElapsedTime"></htc-time-elapsed>
</div>
