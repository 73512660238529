import {
  Component,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  ElementRef,
  Input,
  ComponentRef,
  ViewContainerRef,
  Type,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  OnInit,
} from '@angular/core';
import { SlideTypeEnum } from '../../enums/slide-type';
import { ISlideData } from '../../interfaces/slide-data';
import { SlideBaseComponent } from '../slide-base/slide-base.component';
import { SlideBasicComponent } from '../slide-basic/slide-basic.component';
import { ViewContainerDirective } from 'src/app/shared/directives/view-container/view-container.directive';
import { UseAnimationService } from 'src/app/modules/use-animation/use-animation.service';
import { Subscription } from 'rxjs';
import { AudioDescriptor } from 'src/app/shared/enums/audio-descriptor';
import { AudioType } from 'src/app/shared/enums/audio-type';
import { ReadAloudService } from 'src/app/modules/read-aloud/services';
import { SlideChartComponent } from '../slide-chart/slide-chart.component';

@Component({
  selector: 'htc-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit, AfterViewInit, OnDestroy {
  get content(): ISlideData[] {
    return this._content;
  }
  @Input() set content(value: ISlideData[]) {
    this._content = [...value];
    this.contentLength = value.length;
    this.onFirstOpen = true;
    this.currentSlide = Math.max(this.findFirstUnseenSlide(), 0);
    this.attemptLoadComponents();
  }
  @Input() wrappable = false;
  @ViewChild('slides', { static: true }) slides!: ElementRef;
  @ViewChildren(ViewContainerDirective)
  slideHosts!: QueryList<ViewContainerDirective>;
  @Output() newHintEvent = new EventEmitter<void>();
  @Output() allSlidesVisitedEvent = new EventEmitter<void>();
  _content: ISlideData[] = [];
  compRefs: ComponentRef<ISlideData>[] = [];
  useAnimation = true;
  currentSlide = 0;
  btnNextId = 'btn_next';
  btnPrevId = 'btn_prev';
  hasOneSlide = false;
  contentLength = 0;
  onFirstOpen = true;
  inTransition = false;
  isAllHintsSeen = false;
  newSlide = new EventEmitter();
  sub = new Subscription();
  clickDescriptor = AudioDescriptor.SELECT_MINOR;
  clickType = AudioType.audio_SfxClick;

  constructor(
    private ref: ChangeDetectorRef,
    private useAnimationService: UseAnimationService,
    private readAloudService: ReadAloudService
  ) {
    this.btnNextId = `${this.btnNextId}-${new Date().getTime()}`;
    this.btnPrevId = `${this.btnPrevId}-${new Date().getTime()}`;
  }

  ngOnInit(): void {
    this.sub.add(
      this.useAnimationService.useAnimation$.subscribe(useAnim => {
        this.useAnimation = useAnim;
      })
    );
  }

  ngAfterViewInit(): void {
    this.attemptLoadComponents();

    if (this.slideHosts.length <= 1) {
      this.hasOneSlide = true;
    } else {
      document.getElementById(this.btnNextId)?.focus();
    }

    const unseenSlideIndex = this.findFirstUnseenSlide();

    if (unseenSlideIndex === -1) {
      this.isAllHintsSeen = true;
      this.setSlides(0, false);
    } else {
      this.setSlides(unseenSlideIndex, false);
      this.setCurrentSlideWasViewed();
    }

    if (this.slides && this.useAnimation) {
      this.slides.nativeElement.addEventListener(
        'transitionend',
        this.animationEnded.bind(this)
      );
    }
    this.ref.detectChanges();
  }

  /* Dynamic Component Load */
  attemptLoadComponents(): void {
    if (this._content) {
      if (this.slideHosts && this.slideHosts.length > 0) {
        this.compRefs = [];

        for (let i = 0; i < this.slideHosts.length; i++) {
          const slideHost = this.slideHosts.get(i);
          if (slideHost?.viewContainerRef) {
            this.loadComponent(slideHost.viewContainerRef, i);
          }
        }
      }
    }
  }

  loadComponent(viewContainerRef: ViewContainerRef, index: number): void {
    viewContainerRef.clear();
    const componentRef = this.createDynamicComponent(
      viewContainerRef,
      this._content[index]
    );

    this.compRefs.push(componentRef);
  }

  createDynamicComponent(
    viewContainerRef: ViewContainerRef,
    slide?: ISlideData
  ): ComponentRef<ISlideData> {
    const compSlide = slide ? slide : { data: {}, type: '', id: '' };

    const componentRef = viewContainerRef.createComponent<ISlideData>(
      this.parseCompType(compSlide.type)
    );
    componentRef.instance.data = compSlide.data;
    componentRef.instance.wasViewed = compSlide.wasViewed;

    return componentRef;
  }

  parseCompType(type: string): Type<SlideBaseComponent> {
    switch (type) {
      case SlideTypeEnum.basicSlide:
        return SlideBasicComponent;
      case SlideTypeEnum.chartSlide:
        return SlideChartComponent;
      default:
        return SlideBaseComponent;
    }
  }

  /* Slide Controls */
  setSlides(newCurrent: number, isTransitionPrevious: boolean): void {
    const slides = this.slides.nativeElement.children;

    // wrap new current
    newCurrent = this.wrapIndex(newCurrent, slides.length - 1);

    if (newCurrent === slides.length - 1 && !this.isAllHintsSeen) {
      this.allSlidesVisitedEvent.emit();
      this.isAllHintsSeen = true;
    }

    // determine next and prev and wrap if needed
    const nextSlide = this.wrapIndex(newCurrent + 1, slides.length - 1);
    const prevSlide = this.wrapIndex(newCurrent - 1, slides.length - 1);

    // add classes to previous, next and current slides
    if (!this.onFirstOpen && this.useAnimation) {
      this.handleTransitions(
        slides,
        prevSlide,
        nextSlide,
        isTransitionPrevious
      );
    } else {
      this.onFirstOpen = false;
    }
    this.showCurrentSlide(slides[newCurrent]);

    this.currentSlide = newCurrent;

    this.newSlide.emit();
  }

  handleTransitions(
    slides: HTMLElement[],
    prevSlide: number,
    nextSlide: number,
    isTransitionPrevious: boolean
  ): void {
    this.inTransition = true;
    if (this.contentLength > 2) {
      this.transitionSlide(slides[nextSlide], 'next');
      this.transitionSlide(slides[prevSlide], 'prev');
    } else if (this.contentLength === 2) {
      if (
        (this.currentSlide === 0 && isTransitionPrevious) ||
        (this.currentSlide === 1 && !isTransitionPrevious)
      ) {
        this.transitionSlide(
          slides[nextSlide],
          this.currentSlide === 0 ? 'prev' : 'next'
        );
      } else if (isTransitionPrevious) {
        this.transitionSlide(slides[nextSlide], 'next');
      } else if (!isTransitionPrevious) {
        this.transitionSlide(slides[prevSlide], 'prev');
      }
    } else {
      this.inTransition = false;
    }
  }

  toNextSlide(): void {
    this.readAloudService.stopCurrent();
    this.setSlides(this.currentSlide + 1, false);
    this.setCurrentSlideWasViewed();
  }

  toPreviousSlide(): void {
    this.readAloudService.stopCurrent();
    this.setSlides(this.currentSlide - 1, true);
    this.setCurrentSlideWasViewed();
  }

  setCurrentSlideWasViewed(): void {
    if (!this._content[this.currentSlide].wasViewed) {
      this._content[this.currentSlide].wasViewed = true;
      this.newHintEvent.emit();
    }
  }

  keydownPress(event: KeyboardEvent): void {
    const { key, target } = event;
    let elementId = '';
    const keyMapping = {
      ArrowRight: this.btnNextId,
      ArrowLeft: this.btnPrevId,
      [this.btnNextId]: this.btnNextId,
      [this.btnPrevId]: this.btnPrevId,
    };
    if (key === 'Enter' || key === ' ') {
      elementId = keyMapping[(<HTMLElement>target).id];
    } else if (keyMapping[key]) {
      elementId = keyMapping[key];
    }
    if (elementId && elementId.length > 0) {
      event.preventDefault();
      // trigger click so SFX can be played
      document.getElementById(elementId)?.click();
    }
  }

  /* Class modifiers */
  animationEnded(): void {
    this.inTransition = false;
    this.newSlide.emit();
  }

  transitionSlide(slide: HTMLElement, classAppend: string): void {
    if (slide) {
      slide.className = `${classAppend} slide`;
      slide.setAttribute('aria-hidden', 'true');
    }
  }

  showCurrentSlide(slide: HTMLElement): void {
    if (slide) {
      slide.className = `current slide`;
      slide.removeAttribute('aria-hidden');
    }
  }

  isPreviousEnabled(): boolean {
    return this.wrappable || this.currentSlide !== 0;
  }

  isNextEnabled(): boolean {
    return this.wrappable || this.currentSlide !== this.contentLength - 1;
  }

  /* UTILS */
  wrapIndex(newIndex: number, lastIndex: number): number {
    if (this.wrappable) {
      if (newIndex > lastIndex) {
        newIndex = 0;
      } else if (newIndex < 0) {
        newIndex = lastIndex;
      }
    } else {
      if (newIndex < 0) {
        newIndex = 0;
      } else if (newIndex > lastIndex) {
        newIndex = lastIndex;
      }
    }

    return newIndex;
  }

  ngOnDestroy(): void {
    if (this.slides) {
      this.slides.nativeElement.removeEventListener(
        'transitionend',
        this.animationEnded.bind(this)
      );
    }

    this.sub.unsubscribe();
  }

  private findFirstUnseenSlide(): number {
    return this._content.findIndex(
      slide => slide.wasViewed === false || slide.wasViewed === undefined
    );
  }
}
