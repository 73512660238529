import { NgModule } from '@angular/core';
import { CarouselComponent } from './components/carousel/carousel.component';
import { SlideBaseComponent } from './components/slide-base/slide-base.component';
import { SlideBasicComponent } from './components/slide-basic/slide-basic.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MarkdownModule } from 'ngx-markdown';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SlideChartComponent } from './components/slide-chart/slide-chart.component';
import { ChartModule } from '../chart/chart.module';
import { ReadAloudModule } from '../read-aloud/read-aloud.module';

@NgModule({
  declarations: [
    CarouselComponent,
    SlideBaseComponent,
    SlideBasicComponent,
    SlideChartComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    MarkdownModule,
    NgxSkeletonLoaderModule,
    ChartModule,
    ReadAloudModule,
  ],
  exports: [
    CarouselComponent,
    SlideBaseComponent,
    SlideBasicComponent,
    SlideChartComponent,
  ],
})
export class CarouselModule {}
