import { ReferrerService } from './../../../../shared/services/referrer/referrer.service';
import { MessageService } from './../../../message/services/message/message.service';
import { ActivityService } from 'src/app/shared/services/activity/activity.service';
import { Component, Input } from '@angular/core';
import { UserService } from 'src/app/shared/services';
import { PageProgressService } from 'src/app/shared/services/page-progress-bar/page-progress.service';
import { MessageCodes, LanguageCodes } from 'src/app/shared/enums';
import { FeatureFlagService } from 'src/app/modules/feature-flags/services/feature-flag/feature-flag.service';
import { FeatureFlags } from 'src/app/shared/enums/feature-flags';
import { AudioDescriptor } from 'src/app/shared/enums/audio-descriptor';
import { AudioType } from 'src/app/shared/enums/audio-type';
import { ReadAloudService } from 'src/app/modules/read-aloud/services';

@Component({
  selector: 'htc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() exitButtonText = 'Exit';
  @Input() exitButtonIcon = '/assets/button-icons/arrow.svg';
  @Input() showExitButton = true;
  @Input() showRewards = true;

  userMenuVisible = false;
  showSupportMenu = false;
  showSettingsMenu = false;
  isEarlyAccess = false;
  isAuthenticationEnabled = false;
  clickDescriptor = AudioDescriptor.SELECT_MAJOR_LO;
  clickType = AudioType.audio_SfxClick;

  constructor(
    public userService: UserService,
    public pageProgressService: PageProgressService,
    public activityService: ActivityService,
    private featureFlagService: FeatureFlagService,
    private messageService: MessageService,
    private readAloudService: ReadAloudService,
    private referrerService: ReferrerService
  ) {
    this.isEarlyAccess = this.featureFlagService.isFlagEnabled(
      FeatureFlags.EARLY_ACCESS
    );

    this.isAuthenticationEnabled = this.featureFlagService.isFlagEnabled(
      FeatureFlags.AUTHENTICATE
    );
  }

  onSkipToContent(): void {
    const htmlMainElement = document.getElementById('main');
    const focusables = htmlMainElement?.querySelectorAll(
      'button, a[href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );

    if (focusables) {
      const arrayOfNodes = Array.from(focusables).filter(
        focusableElement =>
          !focusableElement.hasAttribute('disabled') &&
          !focusableElement.getAttribute('aria-hidden') &&
          !focusableElement.hasAttribute('hidden')
      );

      if (arrayOfNodes.length > 0) {
        (arrayOfNodes[0] as HTMLElement).focus();
      }
    }
  }

  onClick(): void {
    this.readAloudService.stopCurrent();
    if (!this.activityService.quickExit) {
      const messageToShow =
        this.activityService.currActivity.language === LanguageCodes.SPANISH
          ? MessageCodes.APP_EXIT_ACTIVITY_CONFIRM_ES
          : MessageCodes.APP_EXIT_ACTIVITY_CONFIRM;
      this.messageService.showMessage(messageToShow, () =>
        this.navigateOnExit()
      );
    } else {
      this.activityService.exitActivity();
    }
  }

  navigateOnExit(): void {
    if (
      this.featureFlagService.isFlagEnabled(FeatureFlags.RETURN_TO_REFERRER)
    ) {
      this.referrerService.navigateToReferrer();
    } else {
      this.activityService.exitActivity();
    }
  }

  toggleUserMenu(): void {
    this.userMenuVisible = !this.userMenuVisible;
  }
}
