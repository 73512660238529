import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ToolItem } from '../../classes/tool-item';
import { ITool } from '../../interfaces/tool.interface';
import { ToolsService } from '../../services/tools.service';

@Component({
  selector: 'htc-tools',
  template: ` <ng-template #tools htcViewContainerDirective></ng-template> `,
})
export class ToolComponent implements OnInit, OnDestroy {
  @ViewChild('tools', { static: true, read: ViewContainerRef })
  toolsViewContainer!: ViewContainerRef;
  toolItems: ToolItem[] = [];
  sub = new Subscription();

  constructor(private toolsService: ToolsService) {}

  ngOnInit(): void {
    this.toolItems = this.toolsService.getTools();

    this.sub.add(
      this.toolsService.getActivatedTools().subscribe(toolIds => {
        if (this.toolsViewContainer) {
          this.toolsViewContainer.clear();
          this.loadComponents(toolIds);
        }
      })
    );
  }

  loadComponents(toolIds: number[]): void {
    for (let toolId = 0; toolId < toolIds.length; toolId++) {
      const viewRef = this.toolsViewContainer.get(toolId);
      const matchedTool = this.toolItems.find(
        tool => tool.data.id === toolIds[toolId]
      );
      if (viewRef === null && matchedTool !== undefined) {
        const componentRef = this.toolsViewContainer.createComponent<ITool>(
          matchedTool.component
        );
        componentRef.instance.id = matchedTool.data.id;
        componentRef.instance.icon = matchedTool.data.icon;
        componentRef.instance.toggledIcon = matchedTool.data.toggledIcon;
      }
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
