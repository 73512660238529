export enum FeatureFlags {
  AUTHENTICATE = 'authenticate',
  AUTO_ENABLE_NEXT = 'autoEnableNext',
  FEATURE_FLAG_DEBUG = 'featureFlagDebug',
  USE_KB = 'useKB',
  USE_ASSIGNMENT_API = 'useAssignmentAPI',
  KB_DEBUG = 'kbDebug',
  SAVE_ACTIVITY_ANYWHERE = 'saveActivityAnywhere',
  COMPONENT_TEST_PAGE = 'componentTestPage',
  CONTENT_TESTING = 'contentTesting',
  SANDBOX = 'sandbox',
  EARLY_ACCESS = 'earlyAccess',
  RANDOMIZE_ANSWER_CHOICES = 'randomizeAnswerChoices',
  ENABLE_ELASTIC_AGENT = 'enableElasticAgent',
  AUTOSAVE = 'autosave',
  CDN_ACTIVITIES = 'cdnActivities',
  CDN_ASSETS = 'cdnAssets',
  EXPERIMENTAL_BROWSER = 'experimentalBrowser',
  SHOW_ACTIVITY_VERSION = 'showActivityVersion',
  REPLAY_BUTTONS = 'replayButton',
  DND_READ_ALOUD = 'dndReadAloud',
  TEACHER_MODE = 'teacherMode',
  DISABLE_ARBITRARY_LAUNCHER = 'disableArbitraryLaunch',
  SAVE_COMPLETED_OBJECT = 'saveCompletedObject',
  USE_CLICKSTREAM = 'useClickstream',
  RETURN_TO_REFERRER = 'returnToReferrer',
  LIVE_CONTENT = 'liveContent',
}
