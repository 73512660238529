import { Subscription } from 'rxjs';
import videojs from 'video.js';
import { FullscreenService } from '../../services/fullscreen.service';

const Button = videojs.getComponent('Button');

class FullscreenButtonComponent extends Button {
  fullscreenService!: FullscreenService;
  sub: Subscription = new Subscription();

  constructor(player: videojs.Player, options: videojs.ComponentOptions) {
    super(player, options);

    this.setAttribute('data-testid', 'htc-vp-fullscreen-button');
    this.controlText('Fullscreen');
  }

  setup(fullscreenService: FullscreenService): void {
    this.fullscreenService = fullscreenService;

    this.sub = this.fullscreenService.fullscreen$.subscribe(isFullscreen => {
      this.toggleFullscreen(isFullscreen);
    });
  }

  createEl(): HTMLButtonElement {
    const el = super.createEl(
      'button',
      {
        className: `vjs-control vjs-button vjs-fullscreen-control`,
        id: 'video-fullscreen-button',
      },
      {}
    );
    return el;
  }

  handleClick(): void {
    this.fullscreenService.toggleFullscreen();
  }

  toggleFullscreen(isFullscreen: boolean | undefined): void {
    if (isFullscreen) {
      this.addClass('vjs-exit-fullscreen');
      this.controlText('Exit Fullscreen');
    } else {
      this.removeClass('vjs-exit-fullscreen');
      this.controlText('Fullscreen');
    }
  }

  dispose(): void {
    this.sub.unsubscribe();
    super.dispose();
  }
}

videojs.registerComponent('customFullscreenToggle', FullscreenButtonComponent);
export default FullscreenButtonComponent;
