/**
 * These are the states a question's answer will evaluate to
 * @enum {number}
 */

export enum CorrectnessEnum {
  CORRECT = 0, //got it correct on the second attempt
  PERFECT = 1, //get it correct on the first attempt
  INCORRECT = 2, //got it wrong on any attempt before the final
  INCORRECT_FINAL = 3, //got it wrong on the final attempt
  COMPLETE = 4, //completed question but cannot assess at this time
}
