<htc-alert
  [(showAlert)]="showAlert"
  [title]="alertHeaderText"
  [type]="alertType"
  [classes]="alertClasses"
  data-testid="htc-feedback-alert">
  <div
    *ngIf="content.length === 0"
    class="alert-content flex-container flex-row flex-align-center">
    <div
      *ngIf="showDiamond"
      class="feedback-diamond"
      data-testid="htc-feedback-diamond-object">
      <svg-icon src="assets/feedback-icons/diamond.svg"></svg-icon>
    </div>
    <markdown
      class="feedback-text"
      data-testid="htc-feedback-text"
      [data]="feedbackText"
      *htcReadAloud="
        {
          id: 'feedback-body',
          text: feedbackText
        };
        type: 'NO_PAUSE';
        theme: 'LIGHT'
      "></markdown>
  </div>
  <ng-template #feedback htcViewContainerDirective></ng-template>
</htc-alert>
