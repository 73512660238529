import { Injectable } from '@angular/core';
import { FeatureFlagService } from 'src/app/modules/feature-flags/services/feature-flag/feature-flag.service';
import { FeatureFlags } from '../../enums/feature-flags';
import { ConfigurationService } from 'src/app/modules/configuration/services/configuration/configuration.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ReferrerService {
  readonly referrerKey = 'laksdjeinik';
  readonly targetKey = 'nkleeqwnjoo';
  private savedTarget = '';
  private savedReferrer = '';
  constructor(
    private featureFlagService: FeatureFlagService,
    private configService: ConfigurationService,
    private router: Router
  ) {}

  saveNavTarget(): void {
    if (
      this.urlContainsDomain(document.referrer, 'istation.com') &&
      !this.featureFlagService.isFlagEnabled(
        FeatureFlags.DISABLE_ARBITRARY_LAUNCHER
      )
    ) {
      // save only when the URL is valid and the feature flag is disabled for Arbitrary Launch
      localStorage.setItem(this.targetKey, window.location.href);
      localStorage.setItem(this.referrerKey, document.referrer);
    }
  }

  getReferrer(): string {
    return this.savedReferrer || localStorage.getItem(this.referrerKey) || '';
  }

  setReferrer(referrer: string): void {
    localStorage.setItem(this.referrerKey, referrer);
  }

  navigateToReferrer(): void {
    if (
      this.featureFlagService.isFlagEnabled(FeatureFlags.RETURN_TO_REFERRER)
    ) {
      const referrer = this.getReferrer();
      if (referrer) {
        window.location.href = referrer;
      } else {
        window.location.href = this.configService.config.seDomain;
      }
    } else {
      this.router.navigate(['/']);
    }
  }

  getNavTarget(): string {
    return localStorage.getItem(this.targetKey) || '';
  }

  removeNavTarget(): void {
    this.savedReferrer = this.getReferrer();
    this.savedTarget = this.getNavTarget();
    localStorage.removeItem(this.targetKey);
    localStorage.removeItem(this.referrerKey);
  }

  isIstationNavTarget(): boolean {
    return this.urlContainsDomain(
      this.getNavTarget() || this.savedTarget,
      'istation.com'
    );
  }

  isSEReferrer(): boolean {
    const referrer = this.getReferrer() || this.savedReferrer;
    //student experience
    return this.urlContainsDomain(
      referrer,
      'istation.com',
      this.getSubdomain(this.configService.config.seDomain)
    );
  }

  isJITReferrer(): boolean {
    const referrer = this.getReferrer() || this.savedReferrer;
    return this.urlContainsDomain(
      referrer,
      'istation.com',
      this.getSubdomain(this.configService.config.jitDomain)
    );
  }

  private getSubdomain(hostname: string): string {
    const hostnameSplit = hostname.split('.');
    return hostnameSplit.slice(0, hostnameSplit.length - 2).join('.');
  }

  private urlContainsDomain(
    url: string,
    domainToMatch: string,
    subdomainToMatch?: string
  ): boolean {
    if (
      url.length > 0 &&
      !url.includes('/auth/') &&
      !window.location.href.includes('/auth/logout')
    ) {
      const { hostname } = new URL(url);
      const periodCount = hostname
        .split('')
        .filter(char => char === '.').length;
      // if URL has a subdomain
      if (periodCount > 1) {
        if (subdomainToMatch) {
          const subdomain = this.getSubdomain(hostname);
          return (
            // subdomain === subdomainToMatch &&
            subdomainToMatch.includes(subdomain) &&
            hostname.includes(`.${domainToMatch}`)
          );
        }
        return hostname.includes(`.${domainToMatch}`);
      }

      // if URL doesn't have a subdomain
      if (periodCount <= 1) {
        return hostname === domainToMatch;
      }
    }
    return false;
  }
}
