import { Activity } from 'src/app/shared/interfaces';

export const lectura7: Activity = {
  id: '018ea019-027d-75a0-a911-243a6645f265',
  product: 'Lectura',
  language: 'es',
  skillName: 'Locuciones o frases preposicionales',
  thumbnail: {
    id: 'recMfeTDTjBq0OBwH',
    url: '/helix/UX_Images/Lesson_Posters/Prepositions_G5V1_Poster.jpg',
    altText: [
      {
        language: 'en',
        altText:
          'Axis and CAT talk in the cockpit after landing on a new planet.',
      },
      {
        language: 'es',
        altText:
          'Axis y G.A.T.A. hablan en la cabina de mando después de aterrizar en un nuevo planeta.',
      },
    ],
  },
  instructionsImage: {
    id: 'recShqii4tO2JCQyf',
    url: '/helix/UX_Images/Characters/Axis/Axis_Chibi_Head_Happy_Mouth_Open.svg',
    altText: [
      {
        language: 'en',
        altText: 'Axis is smiling.',
      },
      {
        language: 'es',
        altText: 'Axis está sonriendo.',
      },
    ],
  },
  characterLeanout: {
    id: 'recBBGySIgxHLnC6k',
    url: '/helix/TEI/Leanouts/Axis_Leanout_01.svg',
    altText: [
      {
        language: 'en',
        altText: 'Axis leans out from the side of the screen.',
      },
      {
        language: 'es',
        altText: 'Axis se asoma por el lado de la pantalla.',
      },
    ],
  },
  description:
    'Cuando la Corregalaxias se queda sin combustible, Axis y G.A.T.A. usan un mapa y preposiciones/locuciones preposicionales para encontrar las rocas galácticas después de atravesar una jungla espacial.',
  domain: {
    text: 'Categorías gramaticales',
  },
  seriesName: 'La isla de las preposiciones',
  episodeNumber: '1',
  episodeTitle: 'Las rocas de la jungla',
  videos: [
    {
      id: 'rec2ir0uKbvmpdxoO',
      url: '/helix/Lectura/Grade_5/Prepositions/Episode_01/hls_streams/L_G5_Prepositions_Ep01_Video.m3u8',
      posterURL: '/helix/UX_Images/Lesson_Posters/Prepositions_G5V1_Poster.jpg',
      captions: [
        {
          src: '/helix/Lectura/Grade_5/Prepositions/Episode_01/L_G5_Prepositions_Ep01_Video.vtt',
          srclang: 'es',
          mode: 'hidden',
          kind: 'captions',
        },
      ],
    },
  ],
  startScreenData: {
    learningTarget: [
      {
        id: '31051.A',
        learningTarget: {
          text: 'la función de las preposiciones/locuciones preposicionales en oraciones',
        },
        successCriteria: [
          {
            id: '31051.A.1',
            text: 'usar las preposiciones para indicar lugar, tiempo y causa',
          },
          {
            id: '31051.A.2',
            text: 'crear oraciones usando preposiciones/locuciones preposicionales',
          },
        ],
      },
    ],
    background_leftImage: {
      id: 'recMfeTDTjBq0OBwH',
      url: '/helix/UX_Images/Lesson_Posters/Prepositions_G5V1_Poster.jpg',
      altText: [
        {
          language: 'en',
          altText:
            'Axis and CAT talk in the cockpit after landing on a new planet.',
        },
        {
          language: 'es',
          altText:
            'Axis y G.A.T.A. hablan en la cabina de mando después de aterrizar en un nuevo planeta.',
        },
      ],
    },
    background_rightImage: {
      id: 'recxjC353VMav5ybu',
      url: '/helix/UX_Images/Start_Screen_Blobs/Nova-Scouts_space-sky.svg',
      altText: [
        {
          language: 'en',
          altText: 'Stars in space.',
        },
        {
          language: 'es',
          altText: 'Estrellas en el espacio.',
        },
      ],
    },
    foreground: {
      id: 'recJFZiQMxFozF9ks',
      url: '/helix/UX_Images/Start_Screen_Characters/Axis3.svg',
      altText: [
        {
          language: 'en',
          altText: 'Axis is pointing at something.',
        },
        {
          language: 'es',
          altText: 'Axis está señalando algo.',
        },
      ],
    },
  },
  populations: [
    {
      id: '31051.A.2.1',
      templateID: 1,
      complexity: 'Synthesis',
      hint: [
        {
          id: '31051.A.2.1.0',
          type: 'BasicSlide',
          data: {
            title:
              'Las preposiciones o locuciones preposicionales dan información sobre el tiempo, lugar y causa.\n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Utiliza la preposición o locución preposicional correcta para crear una oración que coincida con la imagen.\n\nGregorio se relaja {CHOICE_TOKEN} la palmera durante sus vacaciones en la playa.\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '31051.A.2.1_1',
                  text: 'enfrente de\n',
                },
                {
                  id: '31051.A.2.1_2',
                  text: 'con\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'Esta preposición significa en compañía de algo o alguien.\n',
                    },
                  },
                },
                {
                  id: '31051.A.2.1_3',
                  text: 'arena\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'Esta palabra es un sustantivo, no una preposición.\n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['31051.A.2.1_1'],
        associatedSuccessCriteria: ['31051.A.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'Esta es la locución preposicional correcta porque «enfrente de» indica el lugar donde se encuentra Gregorio.\n',
      },
      image: {
        id: 'recaG0kdzb6WWd1bl',
        url: '/helix/TEI/SVGs/TEI_prepositions_boyonbeach_01.svg',
        altText: [
          {
            language: 'en',
            altText: 'A man sits on a chair at the beach.',
          },
          {
            language: 'es',
            altText: 'Un hombre se sienta en una silla en la playa.',
          },
        ],
      },
    },
    {
      id: '31051.A.1.2',
      templateID: 0,
      complexity: 'Analysis',
      hint: [
        {
          id: '31051.A.1.2.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recRORCPGztEFmN5w',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_02.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y sus usos.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: 'Identifica el tipo de preposición o locución preposicional qué está en negrilla.\n\nGregorio se relaja **enfrente de** la palmera durante sus vacaciones en la playa.\n',
        },
        answerChoices: [
          {
            id: '31051.A.1.2_1',
            choice: {
              texts: [
                {
                  text: 'lugar\n',
                },
              ],
            },
          },
          {
            id: '31051.A.1.2_2',
            choice: {
              texts: [
                {
                  text: 'tiempo\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La locución preposicional «enfrente de» no da información sobre cuándo ocurre algo.\n',
              },
            },
          },
          {
            id: '31051.A.1.2_3',
            choice: {
              texts: [
                {
                  text: 'causa\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La locución preposicional «enfrente de» no da información sobre por qué ocurre algo.\n',
              },
            },
          },
        ],
        answers: ['31051.A.1.2_1'],
        associatedSuccessCriteria: ['31051.A.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La locución preposicional «enfrente de» indica el lugar en donde se encuentra Gregorio.\n',
      },
    },
    {
      id: '31051.A.2.3',
      templateID: 1,
      complexity: 'Synthesis',
      hint: [
        {
          id: '31051.A.2.3.0',
          type: 'BasicSlide',
          data: {
            title:
              'Las preposiciones o locuciones preposicionales dan información sobre el tiempo, lugar y causa.\n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Utiliza la preposición o locución preposicional correcta para crear una oración que coincida con la imagen.\n\nEl avión de papel voló por encima de la cabeza del maestro {CHOICE_TOKEN} la clase de matemáticas.\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '31051.A.2.3_1',
                  text: 'durante\n',
                },
                {
                  id: '31051.A.2.3_2',
                  text: 'entre\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'Esta preposición significa que algo está en medio de dos cosas.\n',
                    },
                  },
                },
                {
                  id: '31051.A.2.3_3',
                  text: 'matemáticas\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'La palabra «matemáticas» es un sustantivo y no una preposición.\n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['31051.A.2.3_1'],
        associatedSuccessCriteria: ['31051.A.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'La preposición «durante» es la correcta porque indica el tiempo en que voló el avión de papel.\n',
      },
      image: {
        id: 'recmHi6x41zocFXK8',
        url: '/helix/TEI/SVGs/TEI_prepositons_mathclassroom_01.svg',
        altText: [
          {
            language: 'en',
            altText:
              'A teacher stands at the whiteboard in front of students while a paper airplane flies toward them.',
          },
          {
            language: 'es',
            altText:
              'Un profesor está parado junto al pizarrón en frente de los estudiantes mientras un avioncito de papel vuela hacia ellos.',
          },
        ],
      },
    },
    {
      id: '31051.A.1.4',
      templateID: 0,
      complexity: 'Analysis',
      hint: [
        {
          id: '31051.A.1.4.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recRORCPGztEFmN5w',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_02.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y sus usos.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: 'Identifica el tipo de preposición o locución preposicional qué está en negrilla.\n\nEl avión de papel voló por encima de la cabeza del maestro **durante** la clase de matemáticas.\n\n\n',
        },
        answerChoices: [
          {
            id: '31051.A.1.4_1',
            choice: {
              texts: [
                {
                  text: 'tiempo\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: { content: '\n' },
            },
          },
          {
            id: '31051.A.1.4_2',
            choice: {
              texts: [
                {
                  text: 'causa\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La preposición «durante» no da información sobre por qué alguien hizo algo.\n',
              },
            },
          },
          {
            id: '31051.A.1.4_3',
            choice: {
              texts: [
                {
                  text: 'lugar\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La preposición «durante» no da información sobre dónde ocurrió algo.\n',
              },
            },
          },
        ],
        answers: ['31051.A.1.4_1'],
        associatedSuccessCriteria: ['31051.A.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La preposición «durante» indica el tiempo en que algo ocurrió.\n',
      },
    },
    {
      id: '31051.A.2.5',
      templateID: 1,
      complexity: 'Synthesis',
      hint: [
        {
          id: '31051.A.2.5.0',
          type: 'BasicSlide',
          data: {
            title:
              'Las preposiciones o locuciones preposicionales dan información sobre el tiempo, lugar y causa.\n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Utiliza la preposición o locución preposicional correcta para crear una oración que coincida con la imagen.\n\nAlex no limpió su habitación, {CHOICE_TOKEN} eso su mamá apagó la televisión.\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '31051.A.2.5_1',
                  text: 'por\n',
                },
                {
                  id: '31051.A.2.5_2',
                  text: 'entre\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'Esta preposición significa que algo está en medio de dos cosas.\n',
                    },
                  },
                },
                {
                  id: '31051.A.2.5_3',
                  text: 'bicicleta\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'La palabra «bicicleta» es un sustantivo y no una preposición.\n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['31051.A.2.5_1'],
        associatedSuccessCriteria: ['31051.A.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'La preposición «por» es la correcta porque indica la causa por la que la mamá apaga la televisión.\n',
      },
      image: {
        id: 'rec2kxij7dxFQl1cK',
        url: '/helix/TEI/SVGs/TEI_prepositons_turnofftv_01.svg',
        altText: [
          {
            language: 'en',
            altText:
              'An adult stands beside a child who is sitting on the couch watching TV.',
          },
          {
            language: 'es',
            altText:
              'Un adulto está parado al lado de un niño que mira televisión en un sofá.',
          },
        ],
      },
    },
    {
      id: '31051.A.1.6',
      templateID: 0,
      complexity: 'Analysis',
      hint: [
        {
          id: '31051.A.1.6.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recRORCPGztEFmN5w',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_02.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y sus usos.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: 'Identifica el tipo de preposición o locución preposicional qué está en negrilla.\n\nAlex no limpió su habitación,**por** eso su mamá apaga la televisión.\n',
        },
        answerChoices: [
          {
            id: '31051.A.1.6_1',
            choice: {
              texts: [
                {
                  text: 'causa\n',
                },
              ],
            },
          },
          {
            id: '31051.A.1.6_2',
            choice: {
              texts: [
                {
                  text: 'lugar\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La preposición «por» no da información del lugar donde ocurrió algo.\n',
              },
            },
          },
          {
            id: '31051.A.1.6_3',
            choice: {
              texts: [
                {
                  text: 'tiempo\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La preposición «por» no da información sobre cuándo ocurrió algo.\n',
              },
            },
          },
        ],
        answers: ['31051.A.1.6_1'],
        associatedSuccessCriteria: ['31051.A.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La locución preposicional «por» indica por qué ocurrió algo.\n',
      },
    },
    {
      id: '31051.A.2.7',
      templateID: 1,
      complexity: 'Synthesis',
      hint: [
        {
          id: '31051.A.2.7.0',
          type: 'BasicSlide',
          data: {
            title:
              'Las preposiciones o locuciones preposicionales dan información sobre el tiempo, lugar y causa.\n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Utiliza la preposición o locución preposicional correcta para crear una oración que coincida con la imagen.\n\nEl hombre sentado {CHOICE_TOKEN} las dos mujeres se quedó dormido durante la ópera.\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '31051.A.2.7_1',
                  text: 'en medio de\n',
                },
                {
                  id: '31051.A.2.7_2',
                  text: 'encima de\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'Esta locución preposicional da información sobre el lugar donde se encuentra algo o alguien.\n',
                    },
                  },
                },
                {
                  id: '31051.A.2.7_3',
                  text: 'hombre\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'La palabra «hombre» es un sustantivo y no una preposición.\n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['31051.A.2.7_1'],
        associatedSuccessCriteria: ['31051.A.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'La locución preposicional «en medio de» es la correcta porque indica cuándo el hombre se quedó dormido.\n',
      },
      image: {
        id: 'recUIx9uMATFmsszO',
        url: '/helix/TEI/SVGs/TEI_prepositions_asleepattheopera_01.svg',
        altText: [
          {
            language: 'en',
            altText:
              'An old woman, a young woman, and a sleeping man watch an opera singer on stage.',
          },
          {
            language: 'es',
            altText:
              'Una mujer mayor, una mujer joven y un hombre que duerme se encuentran en un teatro mientras una cantante de ópera está en el escenario.',
          },
        ],
      },
    },
    {
      id: '31051.A.1.8',
      templateID: 0,
      complexity: 'Analysis',
      hint: [
        {
          id: '31051.A.1.8.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recRORCPGztEFmN5w',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_02.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y sus usos.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: 'Identifica el tipo de preposición o locución preposicional qué está en negrilla.\n\nEl hombre sentado **en medio de** las dos mujeres se quedó dormido durante la ópera.\n',
        },
        answerChoices: [
          {
            id: '31051.A.1.8_1',
            choice: {
              texts: [
                {
                  text: 'lugar\n',
                },
              ],
            },
          },
          {
            id: '31051.A.1.8_2',
            choice: {
              texts: [
                {
                  text: 'tiempo\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La locución preposicional «en medio de» no da información del tiempo cuando ocurrió algo.\n',
              },
            },
          },
          {
            id: '31051.A.1.8_3',
            choice: {
              texts: [
                {
                  text: 'causa\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La locución preposicional «en medio de» no da información sobre por qué ocurrió algo.\n',
              },
            },
          },
        ],
        answers: ['31051.A.1.8_1'],
        associatedSuccessCriteria: ['31051.A.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La preposición «en medio de» indica el lugar donde estaba sentado el hombre.\n',
      },
    },
    {
      id: '31051.A.2.9',
      templateID: 1,
      complexity: 'Synthesis',
      hint: [
        {
          id: '31051.A.2.9.0',
          type: 'BasicSlide',
          data: {
            title:
              'Las preposiciones dan información sobre el tiempo, lugar y causa.\n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Utiliza la preposición o locución preposicional correcta para crear una oración que coincida con la imagen.\n\nLa niña hace un gran desorden {CHOICE_TOKEN} la barra de la cocina mientras prepara el desayuno.\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '31051.A.2.9_1',
                  text: 'sobre\n',
                },
                {
                  id: '31051.A.2.9_2',
                  text: 'antes del\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'Esta locución preposicional da información sobre cuándo ocurrió algo.\n',
                    },
                  },
                },
                {
                  id: '31051.A.2.9_3',
                  text: 'prepara\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'La palabra «prepara» es un verbo y no una locución preposicional.\n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['31051.A.2.9_1'],
        associatedSuccessCriteria: ['31051.A.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'La preposición «sobre» es la correcta porque indica dónde se encuentra el desorden.\n',
      },
      image: {
        id: 'rec2r0wrD1xJHfPh0',
        url: '/helix/TEI/SVGs/TEI_prepositions_kidinkitchen_01.svg',
        altText: [
          {
            language: 'en',
            altText: 'A child cooks in a messy kitchen.',
          },
          {
            language: 'es',
            altText: 'Un niño cocina en una cocina desordenada.',
          },
        ],
      },
    },
    {
      id: '31051.A.1.10',
      templateID: 0,
      complexity: 'Analysis',
      hint: [
        {
          id: '31051.A.1.10.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recRORCPGztEFmN5w',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_02.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y sus usos.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: 'Identifica el tipo de preposición o locución preposicional qué está en negrilla.\n\nEl niño hace un gran desorden **sobre** la barra de la cocina mientras prepara el desayuno.\n',
        },
        answerChoices: [
          {
            id: '31051.A.1.10_1',
            choice: {
              texts: [
                {
                  text: 'lugar\n',
                },
              ],
            },
          },
          {
            id: '31051.A.1.10_2',
            choice: {
              texts: [
                {
                  text: 'causa\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La preposición «sobre» no da información acerca de por qué ocurrió algo.\n',
              },
            },
          },
          {
            id: '31051.A.1.10_3',
            choice: {
              texts: [
                {
                  text: 'tiempo\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La preposición «sobre» no da información sobre cuándo ocurrió algo.\n',
              },
            },
          },
        ],
        answers: ['31051.A.1.10_1'],
        associatedSuccessCriteria: ['31051.A.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La preposición «sobre» indica el lugar en donde ocurrió algo.\n',
      },
    },
  ],
  nextUp: {
    nextActivityId: '018ea019-031c-78b2-b1c2-101528e43f3d',
    nextActivityName: 'Planeta Hamburguesa  ',
    nextActivityThumbnail: {
      id: 'rec2Wt325y04Dw11p',
      url: '/helix/UX_Images/Lesson_Posters/Prepositions_G5V2_Poster.jpg',
      altText: [
        {
          language: 'en',
          altText: 'Axis and CAT look at the Burger Planet sign.',
        },
        {
          language: 'es',
          altText: 'Axis y G.A.T.A. observan el letrero de Planeta Burger',
        },
      ],
    },
  },
  activityManifest: [
    {
      type: 'START_SCREEN',
    },
    {
      type: 'ENTRY_SURVEY',
    },
    {
      type: 'VIDEO',
      id: 'rec2ir0uKbvmpdxoO',
    },
    {
      type: 'QUESTION',
      id: '31051.A.2.1',
    },
    {
      type: 'QUESTION',
      id: '31051.A.1.2',
    },
    {
      type: 'QUESTION',
      id: '31051.A.2.3',
    },
    {
      type: 'QUESTION',
      id: '31051.A.1.4',
    },
    {
      type: 'QUESTION',
      id: '31051.A.2.5',
    },
    {
      type: 'QUESTION',
      id: '31051.A.1.6',
    },
    {
      type: 'QUESTION',
      id: '31051.A.2.7',
    },
    {
      type: 'QUESTION',
      id: '31051.A.1.8',
    },
    {
      type: 'QUESTION',
      id: '31051.A.2.9',
    },
    {
      type: 'QUESTION',
      id: '31051.A.1.10',
    },
    {
      type: 'EXIT_SURVEY',
    },
    {
      type: 'RESULTS_SCREEN',
    },
  ],
  version: '1562962166',
};
