import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { VideoData } from 'src/app/shared/interfaces';
import videojs, { VideoJsPlayerOptions } from 'video.js';
import { CustomMenuButton } from '../components/custom-menu-button/custom-menu-button.component';
import WatchTimeComponent from '../components/watch-time/watch-time.component';
import { OffsettersData, VideoDefaultConfig } from '../data';
import { InactivityService } from 'src/app/shared/services';
import { VideoSaveStateService } from './video-save-state.service';

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  player?: videojs.Player;
  private target?: string | Element;
  private videoConfig = new VideoDefaultConfig();
  private videoData: VideoData = { id: '', url: '' };
  watchTimeComp?: WatchTimeComponent;
  watchTime$ = new BehaviorSubject<number>(0);
  watchChangeThreshold = 1;
  playerReady$ = new BehaviorSubject<boolean | undefined>(undefined);
  timeChangeThreshold = 0.3;
  isPlaying$ = new BehaviorSubject<boolean>(false);
  captionMenu!: CustomMenuButton | null;
  settingsMenu!: CustomMenuButton | null;
  jumpBackBtn?: videojs.Button;
  jumpAheadBtn?: videojs.Button;
  sub = new Subscription();
  totalTimeWatchedSecs = 0;
  totalLastTimeCheckedMs = 0;
  videoDuration = 0;

  constructor(
    private videoSaveStateService: VideoSaveStateService,
    private inactivityService: InactivityService
  ) {}

  set VideoConfig(videoConfig: VideoDefaultConfig) {
    this.videoConfig = videoConfig;
  }

  get VideoConfig(): VideoDefaultConfig {
    return { ...this.videoConfig };
  }

  set VideoData(videoData: VideoData) {
    this.playerReady$.next(false);
    this.videoData = videoData;
    this.addInfoToConfig();
    if (this.target && !this.player) {
      this.initPlayer(this.target, this.videoConfig, () => {
        this.playerReady();
      });
    } else {
      this.playerReady();
    }
  }

  get VideoData(): VideoData {
    return { ...this.videoData };
  }

  set Target(target: string | Element) {
    this.target = target;
    this.initPlayer(this.target, this.videoConfig, () => {
      this.playerReady();
    });
  }

  get Target(): string | Element {
    if (this.target !== undefined) {
      return this.target;
    } else {
      return '';
    }
  }

  addInfoToConfig(): void {
    this.videoConfig.sources[0].src = this.videoData.url;
    if (this.videoData.posterURL) {
      this.videoConfig.poster = this.videoData.posterURL;
    }
    if (this.videoData.type) {
      this.videoConfig.sources[0].type = this.videoData.type;
    }
    if (this.videoData.lang) {
      this.videoConfig.language = this.videoData.lang;
    }

    if (this.player) {
      this.player.src(this.videoData.url);
      if (this.videoData.posterURL) {
        this.player.poster(this.videoData.posterURL);
      }
    }
  }

  initPlayer(
    target: string | Element,
    config?: VideoJsPlayerOptions,
    ready?: videojs.ReadyCallback
  ): void {
    if (target !== '') {
      this.player = videojs(target, config, ready);
    }
  }

  playerReady(): void {
    // allows setting watched full video to true before player ready
    if (!this.videoSaveStateService.watchedFullVideo$.getValue()) {
      this.videoSaveStateService.watchedFullVideo$.next(
        this.hasWatchedFullVideo()
      );
    }

    // auto set watch time to full vid duration if watched full video
    if (this.player) {
      this.player.one('loadedmetadata', () => {
        this.videoDuration = this.player?.duration() ?? 0;
      });
      this.player.one('play', () => {
        if (
          this.player &&
          this.watchTimeComp &&
          this.videoSaveStateService.watchedFullVideo$.getValue()
        ) {
          const vidDuration = this.player.duration();
          this.watchTimeComp.watchTime$.next(vidDuration);
          this.watchTime$.next(vidDuration);
          const curVideoState =
            this.videoSaveStateService.curVideoState$.getValue();
          if (vidDuration !== curVideoState.videoProgression) {
            this.videoSaveStateService.exportVideoSaveState({
              ...curVideoState,
              videoProgression: vidDuration,
            });
          }
        }
      });
    }

    const watchTime =
      this.videoSaveStateService.curVideoState$.getValue().videoProgression;
    this.watchTimeComp?.watchTime$.next(watchTime);
    this.watchTime$.next(watchTime);
    this.totalTimeWatchedSecs =
      this.videoSaveStateService.curVideoState$.getValue().videoTotalWatchtime;
    // in case devs skip or video reaches end normally
    this.sub.add(
      this.videoSaveStateService.watchedFullVideo$.subscribe(watched => {
        if (this.player && this.watchTimeComp && watched) {
          this.watchTimeComp.watchTime$.next(this.player.duration());
          this.watchTime$.next(this.player.duration());
        }
      })
    );

    // bind play events
    this.player?.on('play', () => {
      this.isPlaying$.next(true);
      this.totalLastTimeCheckedMs = Date.now();
      this.inactivityService.pauseInactivityTracking();
    });
    this.player?.on('pause', () => {
      this.isPlaying$.next(false);
      this.inactivityService.resumeInactivityTracking();
    });

    // Touch event toggle play on video
    this.player?.on('touchstart', (event: videojs.EventTarget.Event) => {
      if (
        event &&
        event.target &&
        (event.target as HTMLElement).id === 'videoTarget_html5_api'
      ) {
        this.togglePlay();
      }
    });

    this.setDataTestIds();

    this.playerReady$.next(true);
  }

  setJumpButtons(): void {
    const controlBar = this.player?.getChild('ControlBar');
    if (controlBar) {
      const jumpBackBtn = controlBar.getChildById('vjs-jump-back');
      const jumpAheadBtn = controlBar.getChildById('vjs-jump-ahead');

      if (jumpBackBtn) {
        this.jumpBackBtn = <videojs.Button>jumpBackBtn;
      }
      if (jumpAheadBtn) {
        this.jumpAheadBtn = <videojs.Button>jumpAheadBtn;
      }
    }
  }

  setWatchTimeComp(watchTimeComp: WatchTimeComponent): void {
    this.watchTimeComp = watchTimeComp;
    this.sub = new Subscription();
    this.sub.add(
      this.watchTimeComp.watchTime$.subscribe(watchTime => {
        this.watchTime$.next(watchTime);
      })
    );
  }

  hasWatchedFullVideo(): boolean {
    if (this.player) {
      if (this.player.duration() === 0) {
        return false;
      }

      const watchTime = this.watchTime$.getValue();

      return (
        Math.abs(this.player.duration() - watchTime) < this.watchChangeThreshold
      );
    }

    return false;
  }

  cleanup(): void {
    if (this.player) {
      this.player.dispose();
      this.player = undefined;
      this.playerReady$.next(false);
      this.videoSaveStateService.watchedFullVideo$.next(false);
      this.sub.unsubscribe();
    }
  }

  resetWatchTime(): void {
    this.videoSaveStateService.watchedFullVideo$.next(false);
    this.watchTime$.next(0);
    this.totalTimeWatchedSecs = 0;
  }

  cleanupTarget(): void {
    this.target = undefined;
  }

  /* Time Controls */
  togglePlay(): void {
    if (this.player?.paused()) {
      this.player?.play();
    } else {
      this.player?.pause();
    }
  }

  constrainTimeToWatched(proposedTime: number): number {
    const watchTime = this.watchTime$.getValue();
    return Math.max(0, proposedTime > watchTime ? watchTime : proposedTime);
  }

  shouldTimeChange(proposedTime: number): boolean {
    if (this.player) {
      const current = this.player.currentTime();
      return Math.abs(current - proposedTime) > this.timeChangeThreshold;
    }

    return false;
  }

  onTimeChange(): void {
    if (this.player) {
      this.setJumpBtnStates();
      this.videoSaveStateService.watchedFullVideo$.next(
        this.hasWatchedFullVideo()
      );

      const timeNow = Date.now();
      this.totalTimeWatchedSecs =
        this.totalTimeWatchedSecs +
        (timeNow - this.totalLastTimeCheckedMs) / 1000;
      this.totalLastTimeCheckedMs = timeNow;
    }
  }

  setJumpBtnStates(): void {
    if (this.jumpBackBtn) {
      this.setJumpBtnEnable(this.jumpBackBtn, OffsettersData.jumpBackward);
    }
    if (this.jumpAheadBtn) {
      this.setJumpBtnEnable(this.jumpAheadBtn, OffsettersData.jumpAhead);
    }
  }

  setJumpBtnEnable(btn: videojs.Button, seconds: number): void {
    const enable = this.isAllowedToJump(seconds);
    const currentState =
      btn.getAttribute('aria-disabled') === null ||
      btn.getAttribute('aria-disabled') === 'false';

    if (currentState !== enable) {
      if (enable) {
        btn.enable();
      } else {
        btn.disable();
      }
    }
  }

  isAllowedToJump(seconds: number): boolean {
    if (this.player) {
      const stepTime = this.player.currentTime() + seconds;
      const newTime = this.constrainTimeToWatched(stepTime);
      return this.shouldTimeChange(newTime);
    }

    return false;
  }

  jumpStep(seconds: number): void {
    if (this.player) {
      const stepTime = this.player.currentTime() + seconds;
      const newTime = this.constrainTimeToWatched(stepTime);
      if (this.shouldTimeChange(newTime)) {
        this.player.currentTime(newTime);
      }
    }
  }

  jumpStart(): void {
    this.player?.currentTime(0);
    if (this.player?.paused()) {
      this.player?.play();
    }
  }

  setDataTestIds(): void {
    this.player
      ?.getChild('errorDisplay')
      ?.setAttribute('data-testid', 'htc-vp-error-display');
    this.player
      ?.getChild('textTrackDisplay')
      ?.setAttribute('data-testid', 'htc-vp-text-track-display');
    this.player
      ?.getChild('loadingSpinner')
      ?.setAttribute('data-testid', 'htc-vp-loading-spinner');
    this.player
      ?.getChild('bigPlayButton')
      ?.setAttribute('data-testid', 'htc-vp-big-play-button');
    this.player
      ?.getChild('posterImage')
      ?.setAttribute('data-testid', 'htc-vp-poster-image');
    const controlBar = this.player?.getChild('ControlBar');
    controlBar?.setAttribute('data-testid', 'htc-vp-control-bar');

    if (controlBar) {
      controlBar
        .getChild('playToggle')
        ?.setAttribute('data-testid', 'htc-vp-play-toggle-button');
      const volumePanel = controlBar.getChild('volumePanel');
      volumePanel?.setAttribute('data-testid', 'htc-vp-volume-panel');
      volumePanel
        ?.getChild('muteToggle')
        ?.setAttribute('data-testid', 'htc-vp-mute-toggle');
      volumePanel
        ?.getChild('volumeControl')
        ?.setAttribute('data-testid', 'htc-vp-volume-control');
      controlBar
        .getChild('currentTimeDisplay')
        ?.setAttribute('data-testid', 'htc-vp-current-time-display');
      controlBar
        .getChild('durationDisplay')
        ?.setAttribute('data-testid', 'htc-vp-duration-display');
      const progControl = controlBar.getChild('progressControl');
      progControl?.setAttribute('data-testid', 'htc-vp-progress-control');
      const seekBar = progControl?.getChild('seekBar');
      seekBar?.setAttribute('data-testid', 'htc-vp-seek-bar');
      seekBar
        ?.getChild('loadProgressBar')
        ?.setAttribute('data-testid', 'htc-vp-load-progress-bar');
      seekBar
        ?.getChild('mouseTimeDisplay')
        ?.setAttribute('data-testid', 'htc-vp-mouse-time-display');
    }
  }
}
