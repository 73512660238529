import { TextData } from '../shared/interfaces';

export enum TEST_TEXTS_TYPES {
  WORD,
  SHORT,
  MEDIUM,
  LONG,
}

export enum TEST_TITLE_TEXTS_TYPES {
  WORD,
  SHORT,
  MEDIUM,
  LONG,
}

export const texts: { [key: number]: TextData } = {
  [TEST_TEXTS_TYPES.WORD]: {
    text: `Yes`,
  },
  [TEST_TEXTS_TYPES.SHORT]: {
    text: 'Duis ullamco proident consequat deserunt nostrud.',
  },
  [TEST_TEXTS_TYPES.MEDIUM]: {
    text: 'Enim nulla dolor culpa qui eu nisi dolor enim aute irure. Ipsum sit ipsum adipisicing non. Dolor proident eiusmod sunt irure excepteur consectetur duis elit cillum cupidatat.',
  },
  [TEST_TEXTS_TYPES.LONG]: {
    text: 'Dolor proident eiusmod sunt irure excepteur consectetur duis elit cillum cupidatat ad adipisicing qui aliqua. Dolor proident eiusmod sunt irure excepteur consectetur duis elit cillum cupidatat ad adipisicing qui aliqua. Dolor proident eiusmod sunt irure excepteur consectetur duis elit cillum cupidatat ad adipisicing qui aliqua. Dolor proident eiusmod sunt irure excepteur consectetur duis elit cillum cupidatat ad adipisicing qui aliqua. Dolor proident eiusmod sunt irure excepteur consectetur duis elit cillum cupidatat ad adipisicing qui aliqua. Dolor proident eiusmod sunt irure excepteur consectetur duis elit cillum cupidatat ad adipisicing qui aliqua. Dolor proident eiusmod sunt irure excepteur consectetur duis elit cillum cupidatat ad adipisicing qui aliqua. Dolor proident eiusmod sunt irure excepteur consectetur duis elit cillum cupidatat ad adipisicing qui aliqua. ',
  },
};

export const titles: { [key: number]: TextData } = {
  [TEST_TITLE_TEXTS_TYPES.WORD]: {
    text: `Dolor`,
  },
  [TEST_TITLE_TEXTS_TYPES.SHORT]: {
    text: 'Dolor proident',
  },
  [TEST_TITLE_TEXTS_TYPES.MEDIUM]: {
    text: 'Dolor proident eiusmod sunt irure',
  },
  [TEST_TITLE_TEXTS_TYPES.LONG]: {
    text: 'Dolor proident eiusmod sunt irure excepteur consectetur duis elit cillum cupidatat ad adipisicing qui aliqua',
  },
};
