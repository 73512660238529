import { BehaviorSubject } from 'rxjs';
import videojs from 'video.js';
import { KeyboardShortcutService } from '../../services/keyboard-shortcut.service';

const Slider = videojs.getComponent('Slider');
//https://github.com/videojs/video.js/blob/e49286a81cf30969f5dd8d739c6740bc07e3bc00/src/js/slider/slider.js
class WatchTimeComponent extends Slider {
  sliding = false;
  watchTime$ = new BehaviorSubject<number>(0);
  keyboardShortcutService!: KeyboardShortcutService;

  constructor(player: videojs.Player, options: videojs.SliderOptions) {
    super(player, options);
    this.setAttribute('data-testid', 'htc-vp-watch-time');
    this.on(player, 'timeupdate', () =>
      this.timeUpdate(player.currentTime(), player.duration())
    );
  }

  createEl(): Element {
    const el = super.createEl(
      'input',
      { className: 'vjs-watch-time' },
      {
        type: 'range',
        min: '0',
        max: '100',
        value: '100',
        'aria-label': this.localize('Progress Bar'),
        title: this.localize('Progress Bar'),
      }
    );
    return el;
  }

  timeUpdate(currentTime: number, duration: number): void {
    const el = <HTMLInputElement>this.el();
    if (!this.sliding) {
      if (this.watchTime$.getValue() < currentTime) {
        this.watchTime$.next(currentTime);
        el.value = '100';
      } else {
        el.value = `${(currentTime / this.watchTime$.getValue()) * 100}`;
      }

      const percentTime = (this.watchTime$.getValue() / duration) * 100;
      el.style.width = `${percentTime}%`;
    }

    el.setAttribute('aria-valuenow', el.value);
    el.setAttribute('aria-valuetext', this.formatTime(currentTime, 1000));
  }

  handleKeyDown(event: KeyboardEvent): void {
    if (event.key !== 'Tab') {
      event.preventDefault();
      event.stopPropagation();

      if (this.keyboardShortcutService) {
        this.keyboardShortcutService.keyboardShortcut(event.code);
      }
    }
  }

  handleMouseDown(): void {
    this.sliding = true;

    this.addClass('vjs-sliding');
    /**
     * Triggered when the slider is in an active state
     *
     * @event Slider#slideractive
     * @type {EventTarget~Event}
     */
    this.trigger('slideractive');

    this.on(this.el(), 'mousemove', this.handleMouseMove);
    this.on(this.el(), 'mouseup', this.handleMouseUp);
    this.on(this.el(), 'touchmove', this.handleMouseMove);
    this.on(this.el(), 'touchend', this.handleMouseUp);

    this.handleMouseMove();
  }

  handleMouseUp(): void {
    this.trigger('sliderinactive');
    this.removeClass('vjs-sliding');

    this.off(this.el(), 'mousemove', this.handleMouseMove);
    this.off(this.el(), 'mouseup', this.handleMouseUp);
    this.off(this.el(), 'touchmove', this.handleMouseMove);
    this.off(this.el(), 'touchend', this.handleMouseUp);

    this.setCurrentTime();
    this.sliding = false;
  }

  handleMouseMove(): void {
    this.setCurrentTime();
  }

  setCurrentTime(): void {
    const value = (<HTMLInputElement>this.el()).value;
    const val = parseInt(value, 10);
    this.player_.currentTime(this.calculatePercentageWatchTime(val));
  }

  calculatePercentageWatchTime(percent: number): number {
    return this.watchTime$.getValue() * (percent / 100);
  }

  formatTime(seconds: number, guide: number): string {
    seconds = seconds < 0 ? 0 : seconds;
    const s = Math.floor(seconds % 60);
    const m = Math.floor((seconds / 60) % 60);
    const h = Math.floor(seconds / 3600);
    const gm = Math.floor((guide / 60) % 60);
    const gh = Math.floor(guide / 3600);
    let hStr = '';
    let mStr = '';
    let sStr = '';

    // Check if we need to show hours
    hStr = h > 0 || gh > 0 ? h + ':' : '';

    // If hours are showing, we may need to add a leading zero.
    // Always show at least one digit of minutes.
    mStr = ((h || gm >= 10) && m < 10 ? '0' + m : m) + ':';

    // Check if leading zero is need for seconds
    sStr = s < 10 ? '0' + s : '' + s;

    return hStr + mStr + sStr;
  }
}

videojs.registerComponent('watchTime', WatchTimeComponent);
export default WatchTimeComponent;
