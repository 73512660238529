<div>
  <img class="center" [src]="path + 'diamond-glow.svg'" />

  <img class="center" [src]="path + 'diamond-shine.svg'" />

  <img
    class="center"
    [src]="path + imageUrl"
    [alt]="'PROGRESS_BAR.PERFECT_ICON' | translate"
    data-testid="node-img" />

  <img class="center" [src]="path + 'diamond-sparkle.svg'" />
</div>
