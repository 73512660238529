import { SurveyPrompt, TextData, ImageData } from '../shared/interfaces';

export const yesNoChoices: TextData[] = [
  {
    text: 'Yes',
  },
  {
    text: 'Not Yet',
  },
];

export const siNoChoices: TextData[] = [
  {
    text: 'Sí',
  },
  {
    text: 'Todavía no',
  },
];

export const yesNoicons: ImageData[] = [
  {
    id: 'thumbsup',
    url: '/assets/rating-box-icons/thumbs_up.svg',
    altText: [{ language: 'en', altText: 'Thumbs up' }],
  },
  {
    id: 'thumbsdown',
    url: '/assets/rating-box-icons/thumbs_down.svg',
    altText: [{ language: 'en', altText: 'Thumbs down' }],
  },
];

export const exitSurveyIcons: ImageData[] = [
  {
    id: 'post-activity-survey-awful-icon',
    url: '/assets/post-rewards-survey/awful.json',
  },
  {
    id: 'post-activity-survey-ok-icon',
    url: '/assets/post-rewards-survey/ok.json',
  },
  {
    id: 'post-activity-survey-awesome-icon',
    url: '/assets/post-rewards-survey/awesome.json',
  },
];

export enum TEST_SURVEY_TYPES {
  SHORT,
  MEDIUM,
  LONG,
  ES_SHORT,
  ES_MEDIUM,
  ES_LONG,
}

//create an array of survey objects to be used in the survey component
export const surveys: { [key: number]: SurveyPrompt } = {
  [TEST_SURVEY_TYPES.SHORT]: {
    //0
    prompt: {
      text: 'This is a short survey prompt',
    },
    choices: yesNoChoices,
    icons: yesNoicons,
  },
  [TEST_SURVEY_TYPES.MEDIUM]: {
    //1
    prompt: {
      text: 'This is a medium survey prompt with more text than the short but not longer than the long survey',
    },
    choices: yesNoChoices,
    icons: yesNoicons,
  },
  [TEST_SURVEY_TYPES.LONG]: {
    //2
    prompt: {
      text: 'This is a long survey prompt with more text than the short and a longer amount of text than the medium survey prompt. Another sentence just to fill space to be longer than medium.',
    },
    choices: yesNoChoices,
    icons: yesNoicons,
  },
  [TEST_SURVEY_TYPES.ES_SHORT]: {
    //0
    prompt: {
      text: 'Este es un breve mensaje de encuesta.',
    },
    choices: siNoChoices,
    icons: yesNoicons,
  },
  [TEST_SURVEY_TYPES.ES_MEDIUM]: {
    //1
    prompt: {
      text: 'Esta es una pregunta de encuesta mediana con más texto que la encuesta corta pero no más larga que la larga.',
    },
    choices: siNoChoices,
    icons: yesNoicons,
  },
  [TEST_SURVEY_TYPES.ES_LONG]: {
    //2
    prompt: {
      text: 'Esta es una pregunta de encuesta larga con más texto que la corta y una cantidad de texto más larga que la pregunta de encuesta mediana. Otra frase sólo para llenar el espacio para que sea más larga que la media.',
    },
    choices: siNoChoices,
    icons: yesNoicons,
  },
};

export enum EXIT_SURVEY_TYPES {
  ENGLISH,
  SPANISH,
}

export const exitSurveys: { [key: number]: SurveyPrompt } = {
  [EXIT_SURVEY_TYPES.ENGLISH]: {
    prompt: { text: 'What did you think of the activity?' },
    icons: exitSurveyIcons,
    choices: [
      {
        text: 'It was awful.',
      },
      {
        text: 'It was okay.',
      },
      {
        text: 'It was great.',
      },
    ],
    canSkip: true,
  },
  [EXIT_SURVEY_TYPES.SPANISH]: {
    prompt: { text: '¿Qué te pareció esta actividad?' },
    icons: exitSurveyIcons,
    choices: [
      {
        text: 'No me gustó',
      },
      {
        text: 'Bien',
      },
      {
        text: 'Muy buena',
      },
    ],
    canSkip: true,
  },
};
