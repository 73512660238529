import { Activity } from 'src/app/shared/interfaces';

export const readingNoun1: Activity = {
  id: '018ea019-05bb-7425-8615-5d865e215e22',
  product: 'Reading',
  language: 'en',
  skillName: 'Function of nouns',
  thumbnail: {
    id: 'rectB5sz2HtEQrJRy',
    url: '/helix/UX_Images/Lesson_Posters/Nouns_Function_G3V1_Poster.jpg',
    altText: [
      {
        language: 'en',
        altText: 'Axis and CAT talk in the lounge.',
      },
      {
        language: 'es',
        altText: '',
      },
    ],
  },
  instructionsImage: {
    id: 'recShqii4tO2JCQyf',
    url: '/helix/UX_Images/Characters/Axis/Axis_Chibi_Head_Happy_Mouth_Open.svg',
    altText: [
      {
        language: 'en',
        altText: 'Axis is smiling.',
      },
      {
        language: 'es',
        altText: 'Axis está sonriendo.',
      },
    ],
  },
  characterLeanout: {
    id: 'recBBGySIgxHLnC6k',
    url: '/helix/TEI/Leanouts/Axis_Leanout_01.svg',
    altText: [
      {
        language: 'en',
        altText: 'Axis leans out from the side of the screen.',
      },
      {
        language: 'es',
        altText: 'Axis se asoma por el lado de la pantalla.',
      },
    ],
  },
  description:
    'While going through the lost and found, Axis discovers a new gadget, the Grammar Goggles. He quickly learns that nouns are everywhere!',
  domain: {
    text: 'Nouns',
  },
  seriesName: 'Noun Navigator',
  episodeNumber: '1',
  episodeTitle: 'Grammar Goggles',
  videos: [
    {
      id: 'recVZsceeNZPQ8GnT',
      url: '/helix/Reading/Grade_3/Nouns_Function/Video_01/hls_streams/R_G3_NounsFunction_Video01.m3u8',
      posterURL:
        '/helix/UX_Images/Lesson_Posters/Nouns_Function_G3V1_Poster.jpg',
      captions: [
        {
          src: '/helix/Reading/Grade_3/Nouns_Function/Video_01/R_G3_NounsFunction_Video01.vtt',
          srclang: 'en',
          mode: 'hidden',
          kind: 'captions',
        },
      ],
    },
  ],
  startScreenData: {
    learningTarget: [
      {
        id: '22333.A',
        learningTarget: {
          text: 'what a noun is and how it functions (in isolation and in text)',
        },
        successCriteria: [
          {
            id: '22333.A.1',
            text: 'define a noun',
          },
          {
            id: '22333.A.2',
            text: 'identify nouns in isolation and in text',
          },
          {
            id: '22333.A.3',
            text: 'determine if a noun functions as a subject or an object in a sentence',
          },
        ],
      },
    ],
    background_leftImage: {
      id: 'rectB5sz2HtEQrJRy',
      url: '/helix/UX_Images/Lesson_Posters/Nouns_Function_G3V1_Poster.jpg',
      altText: [
        {
          language: 'en',
          altText: 'Axis and CAT talk in the lounge.',
        },
        {
          language: 'es',
          altText: '',
        },
      ],
    },
    background_rightImage: {
      id: 'recxjC353VMav5ybu',
      url: '/helix/UX_Images/Start_Screen_Blobs/Nova-Scouts_space-sky.svg',
      altText: [
        {
          language: 'en',
          altText: 'Stars in space.',
        },
        {
          language: 'es',
          altText: 'Estrellas en el espacio.',
        },
      ],
    },
    foreground: {
      id: 'recJFZiQMxFozF9ks',
      url: '/helix/UX_Images/Start_Screen_Characters/Axis3.svg',
      altText: [
        {
          language: 'en',
          altText: 'Axis is pointing at something.',
        },
        {
          language: 'es',
          altText: 'Axis está señalando algo.',
        },
      ],
    },
  },
  populations: [
    {
      id: '22333.A.1.1',
      templateID: 1,
      complexity: 'Knowledge',
      hint: [
        {
          id: '22333.A.1.1.0',
          type: 'BasicSlide',
          data: {
            title:
              'Think about words for what you can see, touch, hear, or feel.\n',
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'A noun is a person, place, {CHOICE_TOKEN}, or idea.\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '22333.A.1.1_1',
                  text: 'thing\n',
                },
                {
                  id: '22333.A.1.1_2',
                  text: 'description\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content: 'Adjectives are used to describe nouns.\n',
                    },
                  },
                },
                {
                  id: '22333.A.1.1_3',
                  text: 'action\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content: 'Actions are verbs, not nouns.\n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['22333.A.1.1_1'],
        associatedSuccessCriteria: ['22333.A.1'],
      },
      feedbackSecondIncorrect: {
        content: 'A noun is a person, place, thing, or idea.\n',
      },
    },
    {
      id: '22333.A.1.2',
      templateID: 0,
      complexity: 'Knowledge',
      hint: [
        {
          id: '22333.A.1.2.0',
          type: 'BasicSlide',
          data: {
            title: 'Nouns are words that do or receive an action.\n',
          },
        },
        {
          id: '22333.A.1.2.1',
          type: 'BasicSlide',
          data: {
            title:
              'Think about words for what you can see, touch, hear, or feel.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'What is the definition of a noun?\n',
        },
        answerChoices: [
          {
            id: '22333.A.1.2_1',
            choice: {
              texts: [
                {
                  text: 'A noun is a person, place, thing, or idea.\n',
                },
              ],
            },
          },
          {
            id: '22333.A.1.2_2',
            choice: {
              texts: [
                {
                  text: 'A noun is a person, place, action, or idea.\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: { content: 'Actions are verbs, not nouns.\n' },
            },
          },
          {
            id: '22333.A.1.2_3',
            choice: {
              texts: [
                {
                  text: 'A noun is a person, description, action, or place.\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Descriptions are adjectives, and actions are verbs. Neither one is a noun.\n',
              },
            },
          },
        ],
        answers: ['22333.A.1.2_1'],
        associatedSuccessCriteria: ['22333.A.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'A noun is a word referring to a person, place, thing, or idea.\n',
      },
    },
    {
      id: '22333.A.1.3',
      templateID: 0,
      complexity: 'Comprehension',
      hint: [
        {
          id: '22333.A.1.3.0',
          type: 'BasicSlide',
          data: {
            title:
              'Look at how the noun interacts with the verb in the sentence.\n',
          },
        },
        {
          id: '22333.A.1.3.1',
          type: 'BasicSlide',
          data: {
            title: 'Does the noun come before or after the verb?\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'How do you know when a noun functions as the subject?\n',
        },
        answerChoices: [
          {
            id: '22333.A.1.3_1',
            choice: {
              texts: [
                {
                  text: 'when it performs the action in a sentence\n',
                },
              ],
            },
          },
          {
            id: '22333.A.1.3_2',
            choice: {
              texts: [
                {
                  text: 'when it receives the action in a sentence\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'When a noun receives the action, it is the object of a sentence.\n',
              },
            },
          },
          {
            id: '22333.A.1.3_3',
            choice: {
              texts: [
                {
                  text: 'when it describes the action in a sentence\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'An adverb describes the action in a sentence. It is not the subject.\n',
              },
            },
          },
        ],
        answers: ['22333.A.1.3_1'],
        associatedSuccessCriteria: ['22333.A.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'When a noun functions as the subject, it performs the action.\n',
      },
    },
    {
      id: '22333.A.3.4',
      templateID: 0,
      complexity: 'Comprehension',
      hint: [
        {
          id: '22333.A.3.4.0',
          type: 'BasicSlide',
          data: {
            title:
              'Look at how the noun interacts with the verb in the sentence.\n',
          },
        },
        {
          id: '22333.A.3.4.1',
          type: 'BasicSlide',
          data: {
            title: 'Does the noun come before or after the verb?\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'How do you know when a noun functions as the object?\n',
        },
        answerChoices: [
          {
            id: '22333.A.3.4_1',
            choice: {
              texts: [
                {
                  text: 'when it receives the action in a sentence\n',
                },
              ],
            },
          },
          {
            id: '22333.A.3.4_2',
            choice: {
              texts: [
                {
                  text: 'when it is performs the action in a sentence\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'When a noun performs the action, it is the subject of a sentence.\n',
              },
            },
          },
          {
            id: '22333.A.3.4_3',
            choice: {
              texts: [
                {
                  text: 'when it describes the noun in a sentence\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'An adjective is what describes a noun. It is not an object.\n',
              },
            },
          },
        ],
        answers: ['22333.A.3.4_1'],
        associatedSuccessCriteria: ['22333.A.3'],
      },
      feedbackSecondIncorrect: {
        content:
          'When a noun functions as the object, it receives the action.\n',
      },
    },
    {
      id: '22333.A.3.5',
      templateID: 0,
      complexity: 'Comprehension',
      hint: [
        {
          id: '22333.A.3.5.0',
          type: 'BasicSlide',
          data: {
            title:
              'Look at how the nouns interact with the verb in the sentence.\n',
          },
        },
        {
          id: '22333.A.3.5.1',
          type: 'BasicSlide',
          data: {
            title: 'Does the subject come before or after the verb?\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Which noun is functioning as the **subject** in the sentence below?\n\nAxis baked a cake.\n',
        },
        answerChoices: [
          {
            id: '22333.A.3.5_1',
            choice: {
              texts: [
                {
                  text: 'Axis\n',
                },
              ],
            },
          },
          {
            id: '22333.A.3.5_2',
            choice: {
              texts: [
                {
                  text: 'baked\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Baked is the verb in the sentence, not the subject.\n',
              },
            },
          },
          {
            id: '22333.A.3.5_3',
            choice: {
              texts: [
                {
                  text: 'cake\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'The cake is receiving the action of the verb “baked,” so it is the object of the sentence.\n',
              },
            },
          },
        ],
        answers: ['22333.A.3.5_1'],
        associatedSuccessCriteria: ['22333.A.3'],
      },
      feedbackSecondIncorrect: {
        content:
          'Axis is performing the action in the sentence, so he is the subject.\n',
      },
    },
    {
      id: '22333.A.3.6',
      templateID: 0,
      complexity: 'Comprehension',
      hint: [
        {
          id: '22333.A.3.6.0',
          type: 'BasicSlide',
          data: {
            title:
              'Look at how the nouns interact with the verb in the sentence.\n',
          },
        },
        {
          id: '22333.A.3.6.1',
          type: 'BasicSlide',
          data: {
            title: 'Does the subject come before or after the verb?\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Which noun is functioning as the **subject** in the sentence below?\n\nGlyph bought new shoes.\n',
        },
        answerChoices: [
          {
            id: '22333.A.3.6_1',
            choice: {
              texts: [
                {
                  text: 'Glyph\n',
                },
              ],
            },
          },
          {
            id: '22333.A.3.6_2',
            choice: {
              texts: [
                {
                  text: 'bought\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Bought is the verb in the sentence, not the subject.\n',
              },
            },
          },
          {
            id: '22333.A.3.6_3',
            choice: {
              texts: [
                {
                  text: 'new\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'New is an adjective describing the shoes, not a noun.\n',
              },
            },
          },
        ],
        answers: ['22333.A.3.6_1'],
        associatedSuccessCriteria: ['22333.A.3'],
      },
      feedbackSecondIncorrect: {
        content:
          'Glyph is performing the action in the sentence, so she is the subject.\n',
      },
    },
    {
      id: '22333.A.3.7',
      templateID: 0,
      complexity: 'Comprehension',
      hint: [
        {
          id: '22333.A.3.7.0',
          type: 'BasicSlide',
          data: {
            title:
              'Look at how the nouns interact with the verb in the sentence.\n',
          },
        },
        {
          id: '22333.A.3.7.1',
          type: 'BasicSlide',
          data: {
            title: 'Does the object come before or after the verb?\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Which noun is functioning as the **object** in the sentence below?\n\nGlyph bought new shoes.\n',
        },
        answerChoices: [
          {
            id: '22333.A.3.7_1',
            choice: {
              texts: [
                {
                  text: 'shoes\n',
                },
              ],
            },
          },
          {
            id: '22333.A.3.7_2',
            choice: {
              texts: [
                {
                  text: 'bought\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Bought is the verb in the sentence, not the object.\n',
              },
            },
          },
          {
            id: '22333.A.3.7_3',
            choice: {
              texts: [
                {
                  text: 'Glyph\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Glyph is performing the action, so she is the subject of the sentence, not the object.\n',
              },
            },
          },
        ],
        answers: ['22333.A.3.7_1'],
        associatedSuccessCriteria: ['22333.A.3'],
      },
      feedbackSecondIncorrect: {
        content:
          'The shoes are receiving the action in the sentence so it is the object.\n',
      },
    },
    {
      id: '22333.A.3.8',
      templateID: 0,
      complexity: 'Comprehension',
      hint: [
        {
          id: '22333.A.3.8.0',
          type: 'BasicSlide',
          data: {
            title:
              'Look at how the nouns interact with the verb in the sentence.\n',
          },
        },
        {
          id: '22333.A.3.8.1',
          type: 'BasicSlide',
          data: {
            title: 'Does the object come before or after the verb?\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Which noun is functioning as the **object** in the sentence below?\n\nAxis baked a cake.\n\n\n',
        },
        answerChoices: [
          {
            id: '22333.A.3.8_1',
            choice: {
              texts: [
                {
                  text: 'cake\n',
                },
              ],
            },
          },
          {
            id: '22333.A.3.8_2',
            choice: {
              texts: [
                {
                  text: 'Axis\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Axis is performing the action, so he is the subject of the sentence, not the object.\n',
              },
            },
          },
          {
            id: '22333.A.3.8_3',
            choice: {
              texts: [
                {
                  text: 'baked\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Baked is the verb in the sentence, not the subject.\n',
              },
            },
          },
        ],
        answers: ['22333.A.3.8_1'],
        associatedSuccessCriteria: ['22333.A.3'],
      },
      feedbackSecondIncorrect: {
        content:
          'The cake is receiving the action in the sentence, so it is the object.\n',
      },
    },
    {
      id: '22333.A.2.9',
      templateID: 5,
      complexity: 'Knowledge',
      hint: [
        {
          id: '22333.A.2.9.0',
          type: 'BasicSlide',
          data: {
            title:
              'Think about words for what you can see, touch, hear, or feel.\n',
          },
        },
        {
          id: '22333.A.2.9.1',
          type: 'BasicSlide',
          data: {
            title: 'Nouns can be people, places, things, or ideas.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Which of these words are nouns?\n',
        },
        answerChoices: [
          {
            id: '22333.A.2.9_1',
            choice: {
              texts: [
                {
                  text: 'home\n',
                },
              ],
            },
          },
          {
            id: '22333.A.2.9_2',
            choice: {
              texts: [
                {
                  text: 'water\n',
                },
              ],
            },
          },
          {
            id: '22333.A.2.9_3',
            choice: {
              texts: [
                {
                  text: 'teacher\n',
                },
              ],
            },
          },
          {
            id: '22333.A.2.9_4',
            choice: {
              texts: [
                {
                  text: 'beautiful\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: 'Beautiful is an adjective, not a noun.\n',
              },
            },
          },
          {
            id: '22333.A.2.9_5',
            choice: {
              texts: [
                {
                  text: 'sing\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: 'Sing is an action verb, not a noun.\n',
              },
            },
          },
          {
            id: '22333.A.2.9_6',
            choice: {
              texts: [
                {
                  text: 'happy\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: 'Happy is an adjective, not a noun.\n',
              },
            },
          },
        ],
        answers: ['22333.A.2.9_1', '22333.A.2.9_2', '22333.A.2.9_3'],
        associatedSuccessCriteria: ['22333.A.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'Teacher, home, and water are nouns because they refer to a person, place, and thing.\n',
      },
    },
    {
      id: '22333.A.2.10',
      templateID: 5,
      complexity: 'Comprehension',
      hint: [
        {
          id: '22333.A.2.10.0',
          type: 'BasicSlide',
          data: {
            title:
              'Think about words for what you can see, touch, hear, or feel.\n',
          },
        },
        {
          id: '22333.A.2.10.1',
          type: 'BasicSlide',
          data: {
            title: 'Nouns can be people, places, things, or ideas.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Identify the nouns in the following sentence:\n\nThe class visited a museum.\n',
        },
        answerChoices: [
          {
            id: '22333.A.2.10_1',
            choice: {
              texts: [
                {
                  text: 'class\n',
                },
              ],
            },
          },
          {
            id: '22333.A.2.10_2',
            choice: {
              texts: [
                {
                  text: 'museum\n',
                },
              ],
            },
          },
          {
            id: '22333.A.2.10_3',
            choice: {
              texts: [
                {
                  text: 'visited\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: { content: 'Visited is a verb, not a noun.\n' },
            },
          },
          {
            id: '22333.A.2.10_4',
            choice: {
              texts: [
                {
                  text: 'a\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: { content: 'A is an article, not a noun.\n' },
            },
          },
        ],
        answers: ['22333.A.2.10_1', '22333.A.2.10_2'],
        associatedSuccessCriteria: ['22333.A.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'Class and museum are nouns because they refer to a thing and a place.\n',
      },
    },
  ],
  activityManifest: [
    {
      type: 'START_SCREEN',
    },
    {
      type: 'ENTRY_SURVEY',
    },
    {
      type: 'VIDEO',
      id: 'recVZsceeNZPQ8GnT',
    },
    {
      type: 'QUESTION',
      id: '22333.A.1.1',
    },
    {
      type: 'QUESTION',
      id: '22333.A.1.2',
    },
    {
      type: 'QUESTION',
      id: '22333.A.1.3',
    },
    {
      type: 'QUESTION',
      id: '22333.A.3.4',
    },
    {
      type: 'QUESTION',
      id: '22333.A.3.5',
    },
    {
      type: 'QUESTION',
      id: '22333.A.3.6',
    },
    {
      type: 'QUESTION',
      id: '22333.A.3.7',
    },
    {
      type: 'QUESTION',
      id: '22333.A.3.8',
    },
    {
      type: 'QUESTION',
      id: '22333.A.2.9',
    },
    {
      type: 'QUESTION',
      id: '22333.A.2.10',
    },
    {
      type: 'EXIT_SURVEY',
    },
    {
      type: 'RESULTS_SCREEN',
    },
  ],
  version: '',
};
