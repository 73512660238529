import {
  Component,
  ElementRef,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  LoadingScreenService,
  LoadingScreenState,
} from '../../services/loading-screen.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'htc-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  imports: [CommonModule, AngularSvgIconModule, TranslateModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent implements AfterViewInit, OnDestroy {
  constructor(public loadingScreenService: LoadingScreenService) {}

  @ViewChild('loadingBackground', { static: true })
  loadingBackground!: ElementRef;

  ngAfterViewInit(): void {
    this.loadingBackground.nativeElement.addEventListener(
      'animationend',
      () => {
        this.setLoadingStateToHidden();
      }
    );
  }

  ngOnDestroy(): void {
    this.loadingBackground.nativeElement.removeEventListener(
      'animationend',
      () => {
        this.setLoadingStateToHidden();
      }
    );
  }

  private setLoadingStateToHidden(): void {
    this.loadingScreenService.loadingScreenState.set(LoadingScreenState.hidden);
  }
}
