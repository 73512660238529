<dialog
  class="sys dialog {{ theme }}"
  #dialog
  [ngClass]="{
    'dialog-no-bkg': !useBackground,
    fullscreen: fullscreen
  }"
  (cancel)="close()">
  <section
    class="dialog-content"
    [ngClass]="{ 'dialog-content-wide': useWideMode }"
    data-testid="dialog-content">
    <header class="dialog-header">
      <svg-icon
        *ngIf="titleIcon"
        [src]="titleIcon"
        data-testid="dialog-icon"></svg-icon>
      <h2 *ngIf="title" data-testid="dialog-title">{{ title }}</h2>
      <button
        type="button"
        class="btn btn-dialog-close"
        [ngClass]="{ 'no-bkg': !useBackground }"
        *ngIf="showClose"
        (click)="close()"
        [attr.aria-label]="'DIALOG.CLOSE_ARIA_LABEL' | translate"
        data-testid="dialog-close"
        [attr.data-skip-focus-on-open]="
          skipCloseBtnFocus ? skipCloseBtnFocus : null
        "
        htcAudioClick
        [audioType]="clickType"
        [descriptor]="clickDescriptor">
        <svg-icon src="assets/button-icons/close.svg"></svg-icon>
      </button>
    </header>
    <article class="dialog-body">
      <ng-content> </ng-content>
    </article>
    <footer class="dialog-footer">
      <ng-content select="[footer]"></ng-content>
    </footer>
  </section>
</dialog>
<div class="dialog-backdrop"></div>
