import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';

@Directive({
  selector: '[htcTrapFocus]',
})
export class TrapFocusDirective implements AfterViewInit, OnDestroy {
  observer!: MutationObserver;
  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    this.observer = new MutationObserver(mutations => {
      for (let i = 0; i < mutations.length; i++) {
        if (mutations[i].attributeName === 'style') {
          // skip style attributes
          continue;
        }
        // remove event listeners to prevent having multiple references to the same element
        this.el.nativeElement.removeAllListeners();
        this.trapFocus();
      }
    });
    this.observer.observe(this.el.nativeElement, {
      attributes: true,
      childList: true,
      characterData: true,
    });
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }

  trapFocus(): void {
    const element: HTMLElement | null = this.el.nativeElement;
    const focusable = element?.querySelectorAll(
      'a[href]:not(:disabled), button:not(:disabled), textarea:not(:disabled), input[type="text"]:not(:disabled),' +
        'input[type="radio"]:not(:disabled), input[type="checkbox"]:not(:disabled),' +
        'select:not(:disabled), htc-dropdown:not(:disabled),' +
        'ol[tabindex="0"], section[tabindex="0"]'
    );
    if (element && focusable) {
      const focusableElements = Array.from(focusable).map(
        el => el as HTMLElement
      );
      const [firstFocusableElmt] = focusableElements;
      const [lastFocusableElmt] = focusableElements.slice(-1);
      // activate element will be available after first focus was set
      // for example, carousel cmp with at least 3 slides
      const activeElement = focusableElements.find(
        el => el === document.activeElement
      );
      const matchedNoneSkippedFocusable = activeElement
        ? activeElement
        : focusableElements.find(
            el => el.getAttribute('data-skip-focus-on-open') === null
          );
      let scrollTo = firstFocusableElmt;
      let focusOn = firstFocusableElmt;
      if (focusable.length > 1 && matchedNoneSkippedFocusable) {
        focusOn = matchedNoneSkippedFocusable;
        scrollTo = matchedNoneSkippedFocusable;
      }
      focusOn.focus();
      // need to scroll a parent element after focus is set, if parent is scrollable
      // ex.: profile menu is scrolled down on the narrow screen and avatar is invisible
      this.scrollParent(scrollTo);
      element.addEventListener('keydown', event => {
        const ev = event as KeyboardEvent;
        if (ev.key !== 'Tab') return;

        if (ev.shiftKey) {
          if (document.activeElement === firstFocusableElmt) {
            lastFocusableElmt.focus();
            ev.preventDefault();
          }
        } else {
          if (document.activeElement === lastFocusableElmt) {
            firstFocusableElmt.focus();
            ev.preventDefault();
          }
        }
      });
    }
  }

  scrollParent(node: HTMLElement): void {
    if (!node) {
      return;
    }

    while (node) {
      node = node.parentElement as HTMLElement;
      if (node && node.clientHeight < node.scrollHeight) {
        node.scrollTop = 0;
        return;
      }
    }
  }
}
