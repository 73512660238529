import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoPopupComponent } from './components/video-popup/video-popup.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { VideoModule } from '../video/video.module';
import { TranslateModule } from '@ngx-translate/core';
import { TestToggleComponent } from './components/test-toggle/test-toggle.component';
import { ToolComponent } from './components/tool/tools.component';
import { HintPopupComponent } from './components/hint-popup/hint-popup.component';
import { CarouselModule } from '../carousel/carousel.module';
import { RubricPopupComponent } from './components/rubric-popup/rubric-popup.component';
import { ChartModule } from '../chart/chart.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    VideoModule,
    TranslateModule,
    CarouselModule,
    ChartModule,
    NgxSkeletonLoaderModule,
  ],
  declarations: [
    ToolComponent,
    VideoPopupComponent,
    TestToggleComponent,
    HintPopupComponent,
    RubricPopupComponent,
  ],
  exports: [
    ToolComponent,
    VideoPopupComponent,
    TestToggleComponent,
    HintPopupComponent,
    RubricPopupComponent,
  ],
})
export class ToolsModule {}
