import { Subscription } from 'rxjs';
import videojs from 'video.js';
import { Settings } from '../../enums';
import { SettingsPopupsService } from '../../services/settings-popups.service';
import { TranscriptService } from '../../services/transcript.service';

const Button = videojs.getComponent('Button');

class TranscriptToggleComponent extends Button {
  transcriptService!: TranscriptService;
  settingsService!: SettingsPopupsService;
  sub: Subscription = new Subscription();

  constructor(player: videojs.Player, options: videojs.ComponentOptions) {
    super(player, options);

    this.controlText('Toggle Transcript');

    this.setAttribute('data-testid', 'htc-vp-transcript-toggle-button');
  }

  setup(
    transcriptService: TranscriptService,
    settingsService: SettingsPopupsService
  ): void {
    this.transcriptService = transcriptService;
    this.settingsService = settingsService;

    this.sub = this.transcriptService.showTranscript$.subscribe(show => {
      this.toggleTranscript(show);
    });
  }

  createEl(): HTMLButtonElement {
    const el = super.createEl(
      'button',
      {
        className: `vjs-control vjs-button vjs-transcript-toggle`,
        id: 'video-transcript-button',
      },
      { 'aria-pressed': 'false' }
    );
    return el;
  }

  handleClick(): void {
    this.transcriptService.toggleTranscript(
      this.settingsService.subMenuElements[Settings.TRANSCRIPT_TOGGLE]
    );
  }

  toggleTranscript(show: boolean): void {
    if (show) {
      this.addClass('vjs-toggle-on');
      this.setAttribute('aria-pressed', 'true');
    } else {
      this.removeClass('vjs-toggle-on');
      this.setAttribute('aria-pressed', 'false');
    }
  }

  dispose(): void {
    this.sub.unsubscribe();
    super.dispose();
  }
}

videojs.registerComponent('transcriptToggle', TranscriptToggleComponent);
export default TranscriptToggleComponent;
