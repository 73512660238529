import { SpeechSynthesisReadAloudService } from './speech-synthesis-read-aloud/speech-synthesis-read-aloud.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { AudioService } from '../../audio/services/audio/audio.service';
import { ReadAloudComponent } from '../components/read-aloud/read-aloud.component';
import { SettingsService } from 'src/app/shared/services/settings/settings.service';
import { FooterService } from '../../footer/services/footer.service';
import { TrackingService } from '../../tracking/services/tracking.service';

@Injectable({
  providedIn: 'root',
})
export class ReadAloudService implements OnDestroy {
  readAloudMode = new BehaviorSubject<boolean>(false);
  currentReadAloud: ReadAloudComponent | undefined = undefined;
  currentlySelectedElement: HTMLDivElement | undefined = undefined;
  onStartSubscription: Subscription = new Subscription();
  onEndSubscription: Subscription = new Subscription();
  settingsSubscription!: Subscription;
  inStopAndPlayDelay = false;
  stopAndPlayTimeout!: NodeJS.Timeout;

  constructor(
    private audioService: AudioService,
    private settingsService: SettingsService,
    private speechSynthesisReadAloudService: SpeechSynthesisReadAloudService,
    private footerService: FooterService,
    private trackingService: TrackingService
  ) {
    this.footerService.footerButtonClicked.subscribe(() => {
      this.stopCurrent();
    });
    this.settingsSubscription = this.settingsService.settings.subscribe(
      settings => {
        this.readAloudMode.next(settings.readAloud ?? false);
      }
    );
    this.readAloudMode.subscribe(mode => {
      if (!mode) {
        this.stopCurrent();
      } else {
        //When any audio starts, if current read aloud, change loading circle to playing waveform
        const showWaveformOnStart = (value: string): void => {
          if (this.currentReadAloud?.audioData.id === value) {
            this.showReadAloudPlaying(this.currentReadAloud);
          }
        };
        // TODO: audioService playbackStartId
        this.onStartSubscription.add(
          this.speechSynthesisReadAloudService.playbackStartId.subscribe(
            showWaveformOnStart
          )
        );

        //When any audio finishes, if current read aloud, unset current read aloud and hide its controls
        const unsetOnEnd = (value: string): void => {
          if (this.currentReadAloud?.audioData.id === value) {
            this.unsetCurrentReadAloud();
          }
        };
        this.onEndSubscription.add(
          this.audioService.playbackEndId.subscribe(unsetOnEnd)
        );
        this.onEndSubscription.add(
          this.speechSynthesisReadAloudService.playbackEndId.subscribe(
            unsetOnEnd
          )
        );
      }
    });
  }

  ngOnDestroy(): void {
    if (this.onStartSubscription != null)
      this.onStartSubscription.unsubscribe();
    if (this.onEndSubscription != null) this.onEndSubscription.unsubscribe();
    this.settingsSubscription.unsubscribe();
  }

  /**
   * If audio isn't loaded, load it first then play
   * Otherwise just play it
   * @param readAloud
   */
  public play(readAloud: ReadAloudComponent): void {
    const audio = readAloud.audioData;
    if (audio.url) {
      if (!this.audioService.isSoundLoaded(audio.id)) {
        this.loadAndPlayAudio(readAloud); //TODO: supposed to be async, but yolo do it
        //                                  If loading takes too long, audio will overlap
      } else {
        this.playAudioNow(readAloud);
      }
    } else if (audio.text) {
      this.playAudioNow(readAloud);
    }
  }

  /**
   * Stop current audio, load new audio if needed, and play (queue) new audio
   * (Has to queue because you can't play audio the same frame another stopped)
   * @param readAloud the associated read aloud component section thing
   */
  public stopAndPlay(readAloud: ReadAloudComponent): void {
    if (readAloud.audioData.url) {
      if (this.audioService.isCurrentSoundActive()) {
        //If audio isn't loaded, load it first then play
        //Otherwise just queue it (needed to play same frame you're stopping)
        if (!this.audioService.isSoundLoaded(readAloud.audioData.id)) {
          this.loadAndPlayAudio(readAloud); //TODO: Supposed to be async, but yolo just do it
          //                                  If loading takes too long, audio will overlap
        } else {
          this.audioService.queueSound(readAloud.audioData);
        }
        this.stopCurrent();
        this.showReadAloudPlaying(readAloud); //TODO: Audio service loading
      } else {
        //Current is none/invalid audio or already stopped
        //so just play the next audio
        this.playAudioNow(readAloud);
      }
      //select is called on click, no need to do here
    } else if (readAloud.audioData.text) {
      this.speechSynthesisReadAloudService.stop();
      this.inStopAndPlayDelay = true;
      if (this.stopAndPlayTimeout) clearTimeout(this.stopAndPlayTimeout);
      this.stopAndPlayTimeout = setTimeout(() => {
        this.inStopAndPlayDelay = false;
        this.playAudioNow(readAloud);
      }, 100);
    }
  }

  /**
   * Pause/unpause current ReadAloudComponent's audio and tell the component's controls to visually reflect that
   */
  public togglePauseCurrent(): void {
    if (this.currentReadAloud) {
      if (this.currentReadAloud.audioData.url) {
        this.audioService.toggleCurrentSoundPause();
      } else if (this.currentReadAloud.audioData.text) {
        this.speechSynthesisReadAloudService.togglePause();
      }
      this.currentReadAloud.isPlaying = !this.currentReadAloud.isPlaying;
    }
  }

  //Desselect current one, select new one
  public select(element: HTMLDivElement): void {
    this.deselectCurrent();
    element.classList.add('selected');
    this.currentlySelectedElement = element;
  }

  public releaseReadAloud(readAloud: ReadAloudComponent): void {
    if (readAloud.audioData && readAloud.audioData.url) {
      //This just decrements, doesn't actually unload anything
      this.audioService.release(readAloud.audioData.id);
    }
  }

  public stopCurrent(): void {
    this.deselectCurrent();
    if (this.currentReadAloud?.audioData.url) {
      if (this.audioService.isCurrentSoundActive()) {
        this.audioService.stopCurrentSound(); //TODO thinks you only ever play readaloud audio whoops
      }
    } else if (this.currentReadAloud?.audioData.text) {
      this.speechSynthesisReadAloudService.stop();
    }
    this.unsetCurrentReadAloud();
  }

  /**
   * @param readAloud
   * @returns true if loaded and played
   * false if couldn't load and play
   */
  private async loadAndPlayAudio(
    readAloud: ReadAloudComponent
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const audio = readAloud.audioData;
      if (audio.url) {
        return this.audioService
          .initSound(audio)
          .then(() => {
            this.playAudioNow(readAloud);
            return resolve(true);
          })
          .catch((error: Error) => {
            console.error(error);
            return reject(false);
            //throw error; //TODO
          });
      } else {
        this.playAudioNow(readAloud);
        return resolve(true);
      }
    });
  }

  /**
   * Play sound and visually update the controls
   * (Not "now" now if speech synthesis; will show loading until
   * it actually plays, since loading is handled in its play)
   * @param readAloud The ReadAloudComponent we're playing
   */
  private playAudioNow(readAloud: ReadAloudComponent): void {
    const audioData = readAloud.audioData;
    this.trackingService.push({ event: 'Read Aloud Triggered' });
    this.currentReadAloud = readAloud;
    if (audioData.url) {
      this.audioService.playSound(audioData, true);
      this.showReadAloudPlaying(readAloud);
    } else if (audioData.text) {
      this.showReadAloudLoading(readAloud);
      this.speechSynthesisReadAloudService.play(audioData);
    }
  }

  /**
   * Show controls overlay in a loading state
   * @param readAloud Component the audio is associated with
   */
  private showReadAloudLoading(readAloud: ReadAloudComponent): void {
    readAloud.isPaused = false; //Show wave and pause icon
    readAloud.isPlaying = true; //Shows controls overlay
    readAloud.isLoading = true; //Shows loading circle
  }

  /**
   * Show controls overlay in a playing state
   * @param readAloud Component the audio is associated with
   */
  private showReadAloudPlaying(readAloud: ReadAloudComponent): void {
    readAloud.isPaused = false; //Show wave and pause icon
    readAloud.isPlaying = true; //Shows controls overlay
    readAloud.isLoading = false; //Shows loading circle
  }

  private unsetCurrentReadAloud(): void {
    if (this.currentReadAloud) {
      this.currentReadAloud.isPlaying = false; //hide controls
      this.currentReadAloud.isPaused = false;
      this.currentReadAloud = undefined;
    }
  }

  private deselectCurrent(): void {
    if (this.currentlySelectedElement != undefined) {
      this.currentlySelectedElement.classList.remove('selected');
    }
  }
}
