import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { CarouselComponent } from 'src/app/modules/carousel/components/carousel/carousel.component';
import { PopupService } from '../../services/popup/popup.service';
import { AudioDescriptor } from '../../enums/audio-descriptor';
import { AudioType } from '../../enums/audio-type';
import { ReadAloudService } from 'src/app/modules/read-aloud/services';

@Component({
  selector: 'htc-alert',
  templateUrl: './alert.component.html',
})
export class AlertComponent implements OnDestroy {
  @Input() showAlert = false;
  @Input() type = 'default'; // Types: default and error
  @Input() title = '';
  @Input() role = 'alert';
  @Input() classes = '';
  @Output() showAlertChange = new EventEmitter<boolean>();
  @Input() imageSource = '';
  @ViewChild('alertSection', { static: true }) alertSection!: ElementRef;
  @ContentChild(CarouselComponent) carousel!: CarouselComponent;
  sub = new Subscription();
  clickDescriptor = AudioDescriptor.CLICK_GENERAL;
  clickType = AudioType.audio_SfxClick;

  constructor(
    private popupService: PopupService,
    public readAloudService: ReadAloudService
  ) {}

  @HostListener('window:keydown.esc', ['$event'])
  close(): void {
    this.readAloudService.stopCurrent();
    this.showAlert = false;
    this.popupService.focusActiveElement();
    this.showAlertChange.emit(false);
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
