import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import katex from 'katex';
import { CommonModule } from '@angular/common';
import { SpeechRuleEngineService } from '../../services/speech-rule-engine.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'htc-math-text',
  templateUrl: './math-text.component.html',
  styleUrls: ['./math-text.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
// Special math text formatted in TeX
export class MathTextComponent implements OnChanges {
  @Input() texFunction = '';
  @ViewChild('mathText', { static: true }) htmlElement!: ElementRef;

  constructor(
    private sreService: SpeechRuleEngineService,
    private translateService: TranslateService
  ) {}

  // In case we want to use directly and change contents
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.texFunction && this.htmlElement) {
      this.renderKatex();
    }
  }

  renderKatex(): void {
    if (this.htmlElement) {
      katex.render(
        this.texFunction,
        this.htmlElement.nativeElement as HTMLElement,
        {
          output: 'htmlAndMathml',
          // reference https://katex.org/docs/supported.html for display mode only supported functions
          displayMode: this.texFunction.includes('begin'),
        }
      );

      const mmlElement = this.htmlElement.nativeElement.querySelector(
        this.sreService.mmlTag
      );
      if (mmlElement) {
        const locale = this.translateService.currentLang;
        this.sreService.pushQueue({
          mml: mmlElement.outerHTML,
          tex: this.texFunction,
          config: { locale },
        });
      }
    }
  }
}
