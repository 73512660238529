import { FeatureFlagService } from 'src/app/modules/feature-flags/services/feature-flag/feature-flag.service';
import { ConfigDataService } from './modules/olp-api/services/config-data/config-data.service';
import { ConfigDataAPI } from './modules/olp-api/classes/config-data/config-data-api';
import { KnowledgeBaseAPI } from './modules/olp-api/classes/knowledge-base/knowledge-base-api';
import { KnowledgeBaseService } from './modules/olp-api/services/kb/kb.service';
import { HeaderModule } from './modules/header/header.module';
import { MessageModule } from './modules/message/message.module';
import { SharedModule } from './shared/shared.module';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { SimulacrumService } from './shared/services/simulacrum/simulacrum.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FeatureFlagModule } from './modules/feature-flags/feature-flag.module';
import { AuthConfigModule } from './modules/auth/modules/auth-config.module';
import { SubheaderModule } from './modules/subheader/subheader.module';
import { DebugModule } from './modules/debug/debug.module';
import { FooterModule } from './modules/footer/footer.module';
import { ToolsModule } from './modules/tools/tools.module';
import { ConfigurationService } from './modules/configuration/services/configuration/configuration.service';
import { HTTPInterceptorService } from './modules/http-interceptor/http-interceptor.service';
import { MarkdownModule } from 'ngx-markdown';
import { environment } from 'src/environments/environment';
import {
  ApmErrorHandler,
  ApmModule,
  ApmService,
} from '@elastic/apm-rum-angular';
import { FeatureFlags } from './shared/enums/feature-flags';
import { AccordionModule } from './modules/accordion/accordion.module';
import { AuthInterceptor } from 'angular-auth-oidc-client';
import { ClickstreamAPI } from './modules/olp-api/classes/clickstream-api/clickstream-api';
import { ClickstreamService } from './modules/olp-api/services/clickstream/clickstream.service';
import { ChartModule } from './modules/chart/chart.module';
import { GraphqlModule } from './modules/content-metadata-api/modules/graphql.module';
import { AssignmentAPI } from './modules/olp-api/classes/assignment-api/assignment-api';
import { AssignmentService } from './modules/olp-api/services/assignment/assignment.service';
import { LoadingComponent } from './modules/loading-screen/components/loading/loading.component';

function initializeApp(
  configService: ConfigurationService,
  flagService: FeatureFlagService,
  apmService: ApmService
): () => Promise<void> {
  return () =>
    new Promise(resolve => {
      configService.load().then(() => {
        flagService.populateFlags();
        apmService.init({
          // Elastic Agent API is exposed through this apm instance
          // https://www.elastic.co/guide/en/apm/agent/rum-js/current/configuration.html
          //    enableElasticAgent => feature flag must be enabled
          //    apmThreshold => config variable that determines threshold to limit monitoring agent (0.0 - 1.0)
          serviceName: 'Istation HTML Practice',
          serverUrl: configService.config.apmServerUrl,
          environment: configService.config.envShortName,
          serviceVersion: environment.version,
          active:
            flagService.isFlagEnabled(FeatureFlags.ENABLE_ELASTIC_AGENT) ===
              true && Math.random() < configService.config.apmThreshold,
        });
        resolve();
      });
    });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    ApmModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    CommonModule,
    AngularSvgIconModule.forRoot(),
    HttpClientModule,
    HttpClientInMemoryWebApiModule.forRoot(SimulacrumService, {
      dataEncapsulation: false,
      passThruUnknownUrl: true,
    }),
    BrowserAnimationsModule,
    MessageModule,
    HeaderModule,
    SubheaderModule,
    TranslateModule.forRoot({
      // example translation file location:   /assets/i18n/page-prompts/en.json
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) =>
          new TranslateHttpLoader(http, '/assets/i18n/page-prompts/', '.json'),
        deps: [HttpClient],
      },
    }),
    AuthConfigModule,
    FeatureFlagModule,
    DebugModule,
    FooterModule,
    ToolsModule,
    MarkdownModule.forRoot(),
    AccordionModule,
    ChartModule,
    GraphqlModule,
    LoadingComponent,
  ],
  exports: [TranslateModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigurationService, FeatureFlagService, ApmService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ApmErrorHandler,
    },
    ApmService,
    KnowledgeBaseService,
    KnowledgeBaseAPI,
    ConfigDataAPI,
    ConfigDataService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    ClickstreamAPI,
    ClickstreamService,
    AssignmentAPI,
    AssignmentService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
