import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IProgressBarConfig } from '../interfaces/progress-bar-config.interface';
import { IProgressNodeData } from '../interfaces/progress-node-data.interface';
import {
  ProgressNodeFocusEnum,
  ProgressNodeStatusEnum,
  ProgressBarOrientationEnum,
  ProgressNodeTypeEnum,
} from '../enums';

@Injectable({
  providedIn: 'root',
})
export class ProgressService {
  static DEFAULT_CONFIG: IProgressBarConfig = {
    orientation: ProgressBarOrientationEnum.horizontal,
    useDinkus: true,
    dinkusChildCount: 9,
    dinkusNode: {
      index: -1,
      type: ProgressNodeTypeEnum.dinkusNodeComp,
      data: {},
      focus: ProgressNodeFocusEnum.inactive,
      status: ProgressNodeStatusEnum.none,
    },
    alwaysShowBegNode: true,
    alwaysShowEndNode: true,
    autoDinkus: false,
    autoDinkusWindowDim: 800,
    autoDinkusNodeDim: 56,
    autoDinkusMinChildren: 5,
    autoDinkusMaxChildren: 9,
    autoDinkusIdealChildren: 11,
  };
  focusIndex = 0;
  avaNodes: IProgressNodeData[] = [];
  shownNodes$ = new BehaviorSubject<IProgressNodeData[]>([]);
  private _config: IProgressBarConfig = { ...ProgressService.DEFAULT_CONFIG };

  constructor() {}

  set Config(config: IProgressBarConfig) {
    this._config = { ...this._config, ...config };
  }

  get Config(): IProgressBarConfig {
    return { ...this._config };
  }

  /**
   * Sets shown nodes either by trimming using dinkus and end nodes,
   * or all available nodes
   * Sets focused index to 'active'
   * triggers Behavior Subject for subscribers
   * @param  {number} focusIndex the currently focused node index
   * @param  {IProgressNodeData[]} avaNodes all available nodes
   *                                                          know new shown nodes
   */
  activateShownNodes(focusIndex: number, avaNodes: IProgressNodeData[]): void {
    let shownNodes = [];
    const {
      useDinkus,
      dinkusChildCount,
      dinkusNode,
      alwaysShowBegNode,
      alwaysShowEndNode,
    } = this._config;
    this.focusIndex = focusIndex;
    this.avaNodes = [...avaNodes];

    if (
      useDinkus &&
      dinkusChildCount &&
      dinkusNode &&
      dinkusChildCount < avaNodes.length
    ) {
      shownNodes = this.getTrimmedShownNodes(
        focusIndex,
        avaNodes,
        dinkusChildCount,
        dinkusNode,
        alwaysShowBegNode,
        alwaysShowEndNode
      );
    } else {
      shownNodes = avaNodes;
    }

    this.shownNodes$.next(shownNodes);
  }

  refreshShownNodes(): void {
    this.activateShownNodes(this.focusIndex, this.avaNodes);
  }

  /**
   * Get trimmed shown nodes that incorporate dinkus nodes and
   * end nodes if flagged as always
   * @param  {number} focusIndex the currently focused node index
   * @param  {IProgressNodeData[]} avaNodes all available nodes
   * @param  {number} dinkusChildCount amount of nodes to show to user
   *                                    including dinkus and end nodes
   * @param  {IProgressNodeData} dinkusNode node used to represent dinkus (***)
   * @param  {boolean} alwaysShowBegNode? should first index available node always show
   * @param  {boolean} alwaysShowEndNode? should last index available node always show
   * @returns IProgressNodeData {IProgressNodeData[]} shown nodes
   */
  getTrimmedShownNodes(
    focusIndex: number,
    avaNodes: IProgressNodeData[],
    dinkusChildCount: number,
    dinkusNode: IProgressNodeData,
    alwaysShowBegNode?: boolean,
    alwaysShowEndNode?: boolean
  ): IProgressNodeData[] {
    let shownNodes = this.createShownNodes(
      focusIndex,
      avaNodes,
      dinkusChildCount
    );

    if (shownNodes.length >= 5 && avaNodes.length >= 5) {
      shownNodes = this.handleEndNodes(
        shownNodes,
        avaNodes,
        dinkusNode,
        alwaysShowBegNode,
        alwaysShowEndNode
      );
    }

    return shownNodes;
  }

  /**
   * Create shown nodes array from available nodes
   * @param  {number} focusIndex current active index
   * @param  {IProgressNodeData[]} avaNodes all available nodes
   * @param  {number} dinkusChildCount amount of nodes to show to user
   * @returns IProgressNodeData[] array of shown nodes
   */
  createShownNodes(
    focusIndex: number,
    avaNodes: IProgressNodeData[],
    dinkusChildCount: number
  ): IProgressNodeData[] {
    const shownNodes: IProgressNodeData[] = [];

    // determine initial start and end indexes of comps to add
    const halfDinkusChildCount = Math.floor(dinkusChildCount / 2);
    let endIndex = Math.min(
      avaNodes.length - 1,
      focusIndex + halfDinkusChildCount
    );
    let startIndex = endIndex - dinkusChildCount + 1;
    if (startIndex < 0) {
      startIndex = 0;
      endIndex = Math.min(
        avaNodes.length - 1,
        startIndex + dinkusChildCount - 1
      );
    }

    // add rest of components to hit dinkusChildCount
    for (let i = startIndex; i <= endIndex; i++) {
      const comp = avaNodes[i];
      shownNodes.push(comp);
    }

    return shownNodes;
  }

  /**
   * Handle replacing shown nodes with dinkus and
   * beg and end nodes if always is set
   * NOTE: shown nodes length and available nodes length need to be >= 5
   * @param  {IProgressNodeData[]} shownNodes current shown nodes
   * @param  {IProgressNodeData[]} avaNodes all available nodes
   * @param  {IProgressNodeData} dinkusNode node to represent dinkus (***)
   * @param  {boolean} alwaysShowBegNode? should always show first available node
   * @param  {boolean} alwaysShowEndNode? should always show last available node
   * @returns IProgressNodeData {IProgressNodeData[]} shown nodes
   */
  handleEndNodes(
    shownNodes: IProgressNodeData[],
    avaNodes: IProgressNodeData[],
    dinkusNode: IProgressNodeData,
    alwaysShowBegNode?: boolean,
    alwaysShowEndNode?: boolean
  ): IProgressNodeData[] {
    if (shownNodes.length >= 5 && avaNodes.length >= 5) {
      // show end comp if needed
      if (alwaysShowEndNode) {
        shownNodes[shownNodes.length - 1] = avaNodes[avaNodes.length - 1];
      }

      // show beg comp if needed
      if (alwaysShowBegNode) {
        shownNodes[0] = avaNodes[0];
      }

      // show end dinkus if needed
      let offset = alwaysShowEndNode ? -1 : 0;
      if (
        this.shouldShowDinkus(
          shownNodes[shownNodes.length - 2 + offset],
          avaNodes[avaNodes.length - 1 + offset]
        )
      ) {
        shownNodes[shownNodes.length - 1 + offset] = dinkusNode;
      }

      // show beg dinkus if needed
      offset = alwaysShowBegNode ? 1 : 0;
      if (this.shouldShowDinkus(avaNodes[offset], shownNodes[1 + offset])) {
        shownNodes[offset] = dinkusNode;
      }
    }

    return shownNodes;
  }

  /**
   * Should a dinkus node be added?
   * @param  {IProgressNodeData} aNode before index node
   * @param  {IProgressNodeData} bNode after index node
   * @returns boolean true should use dinkus else should not use dinkus
   */
  shouldShowDinkus(
    aNode: IProgressNodeData,
    bNode: IProgressNodeData
  ): boolean {
    return bNode.index - aNode.index > 1;
  }
}
