import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IncompleteActivityState } from 'src/app/modules/activity-save-state';

@Injectable({
  providedIn: 'root',
})
export class DebugService {
  showDebugMenu = new BehaviorSubject<boolean>(false);
  showFeatureFlagMenu = new BehaviorSubject<boolean>(false);
  showKbDebugMenu = new BehaviorSubject<boolean>(false);
  showSaveDataImportMenu = new BehaviorSubject<boolean>(false);
  showSaveDataEditorMenu = new BehaviorSubject<boolean>(false);

  constructor() {}

  exportActivityState(
    toClipboard: boolean,
    activityState: IncompleteActivityState
  ): void {
    const activityStateString = JSON.stringify(activityState);
    if (toClipboard) {
      navigator.clipboard.writeText(activityStateString).then(
        () => {
          alert('Activity State successfully copied to clipboard');
        },
        () => {
          alert('Error copying activity state to clipboard');
        }
      );
    } else {
      const fileName = `${activityState.id}_${this.createTimestamp()}`;
      const dataStr =
        'data:text/json;charset=utf-8,' +
        encodeURIComponent(activityStateString);
      const downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute('href', dataStr);
      downloadAnchorNode.setAttribute('download', fileName + '.json');
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    }
  }

  private createTimestamp(): string {
    const date = new Date();
    return `${this.padDateTime(date.getMonth() + 1)}-${this.padDateTime(
      date.getDate() + 1
    )}-${date.getFullYear()}-${this.padDateTime(
      date.getHours()
    )}-${this.padDateTime(date.getMinutes())}-${this.padDateTime(
      date.getSeconds()
    )}`;
  }

  private padDateTime(part: number, maxLength = 2, padWith = '0'): string {
    return String(part).padStart(maxLength, padWith);
  }
}
