import { ActivityTool } from 'src/app/shared/enums';
import { ActivityObjectTypes } from 'src/app/shared/enums/activity-object-types';
import { Activity } from 'src/app/shared/interfaces';
import { images, TEST_IMAGE_TYPES } from '../images';
import {
  learningTargets,
  TEST_LEARNING_TARGET_TYPES,
} from '../learning-targets';
import { TEST_POPULATION_TYPES } from '../population-types';
import {
  populationsSpanishMC,
  populationsSpanishMCI,
  populationsSpanishMS,
  populationsSpanishMSI,
  populationsSpanishIC,
  populationsSpanishTE,
  populationsSpanishSCR,
  populationsSpanishECR,
} from '../populations';
import { constActivityManifestEnd } from './activity-manifest-end';
import { constSpanishParams } from './spanish-params';

export const testSpanishActivities: { [key: string]: Activity } = {
  'TEST.S.MC': {
    id: `TEST.S.MC`,
    ...constSpanishParams,
    seriesName: `Serie de pruebas de opción múltiple`,
    episodeNumber: '1',
    episodeTitle: 'Opción multiple',
    description:
      'MC_SHORT, MC_MEDIUM, MC_LONG, MC_PASSAGE_SHORT, MC_PASSAGE_MEDIUM, MC_PASSAGE_LONG, MC_TRUE_FALSE, MC_PASSAGE_TRUE_FALSE, MCI_SHORT, MCI_MEDIUM, MCI_LONG, MCI_PASSAGE_SHORT, MCI_PASSAGE_MEDIUM, MCI_PASSAGE_LONG',
    startScreenData: {
      ...constSpanishParams.startScreenData,
      learningTarget: [
        learningTargets[TEST_LEARNING_TARGET_TYPES.ES_MULTI_CHOICE],
      ],
    },
    populations: [
      populationsSpanishMC[TEST_POPULATION_TYPES.TEST_S_MC_SHORT],
      populationsSpanishMC[TEST_POPULATION_TYPES.TEST_S_MC_MEDIUM],
      populationsSpanishMC[TEST_POPULATION_TYPES.TEST_S_MC_LONG],
      populationsSpanishMC[TEST_POPULATION_TYPES.TEST_S_MC_PASSAGE_SHORT],
      populationsSpanishMC[TEST_POPULATION_TYPES.TEST_S_MC_PASSAGE_MEDIUM],
      populationsSpanishMC[TEST_POPULATION_TYPES.TEST_S_MC_PASSAGE_LONG],
      populationsSpanishMC[TEST_POPULATION_TYPES.TEST_S_MC_TRUE_FALSE],
      populationsSpanishMC[TEST_POPULATION_TYPES.TEST_S_MC_PASSAGE_TRUE_FALSE],
      populationsSpanishMCI[TEST_POPULATION_TYPES.TEST_S_MCI_SHORT],
      populationsSpanishMCI[TEST_POPULATION_TYPES.TEST_S_MCI_MEDIUM],
      populationsSpanishMCI[TEST_POPULATION_TYPES.TEST_S_MCI_LONG],
      populationsSpanishMCI[TEST_POPULATION_TYPES.TEST_S_MCI_PASSAGE_SHORT],
      populationsSpanishMCI[TEST_POPULATION_TYPES.TEST_S_MCI_PASSAGE_MEDIUM],
      populationsSpanishMCI[TEST_POPULATION_TYPES.TEST_S_MCI_PASSAGE_LONG],
    ],
    nextUp: {
      nextActivityId: 'TEST.S.MS',
      nextActivityThumbnail: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
      nextActivityName: 'Episodio 2',
    },
    activityManifest: [
      ...constSpanishParams.activityManifest,
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MC_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MC_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MC_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MC_PASSAGE_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MC_PASSAGE_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MC_PASSAGE_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MC_TRUE_FALSE}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MC_PASSAGE_TRUE_FALSE}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MCI_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MCI_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MCI_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MCI_PASSAGE_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MCI_PASSAGE_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MCI_PASSAGE_LONG}`,
      },
      ...constActivityManifestEnd,
    ],
  },
  'TEST.S.MS': {
    id: `TEST.S.MS`,
    ...constSpanishParams,
    seriesName: `Prueba de series de selección múltiple`,
    episodeNumber: '2',
    episodeTitle: 'Selección múltiple',
    description:
      'MS_SHORT, MS_MEDIUM, MS_LONG, MS_PASSAGE_SHORT, MS_PASSAGE_MEDIUM, MS_PASSAGE_LONG, MSI_SHORT, MSI_MEDIUM, MSI_LONG, MSI_PASSAGE_SHORT, MSI_PASSAGE_MEDIUM, MSI_PASSAGE_LONG',
    startScreenData: {
      ...constSpanishParams.startScreenData,
      learningTarget: [
        learningTargets[TEST_LEARNING_TARGET_TYPES.ES_MULTI_SELECT],
      ],
    },
    populations: [
      populationsSpanishMS[TEST_POPULATION_TYPES.TEST_S_MS_SHORT],
      populationsSpanishMS[TEST_POPULATION_TYPES.TEST_S_MS_MEDIUM],
      populationsSpanishMS[TEST_POPULATION_TYPES.TEST_S_MS_LONG],
      populationsSpanishMS[TEST_POPULATION_TYPES.TEST_S_MS_PASSAGE_SHORT],
      populationsSpanishMS[TEST_POPULATION_TYPES.TEST_S_MS_PASSAGE_MEDIUM],
      populationsSpanishMS[TEST_POPULATION_TYPES.TEST_S_MS_PASSAGE_LONG],
      populationsSpanishMSI[TEST_POPULATION_TYPES.TEST_S_MSI_SHORT],
      populationsSpanishMSI[TEST_POPULATION_TYPES.TEST_S_MSI_MEDIUM],
      populationsSpanishMSI[TEST_POPULATION_TYPES.TEST_S_MSI_LONG],
      populationsSpanishMSI[TEST_POPULATION_TYPES.TEST_S_MSI_PASSAGE_SHORT],
      populationsSpanishMSI[TEST_POPULATION_TYPES.TEST_S_MSI_PASSAGE_MEDIUM],
      populationsSpanishMSI[TEST_POPULATION_TYPES.TEST_S_MSI_PASSAGE_LONG],
    ],
    nextUp: {
      nextActivityId: 'TEST.S.ICTE',
      nextActivityThumbnail: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
      nextActivityName: 'Episodio 3',
    },
    activityManifest: [
      ...constSpanishParams.activityManifest,
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MS_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MS_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MS_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MS_PASSAGE_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MS_PASSAGE_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MS_PASSAGE_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MSI_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MSI_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MSI_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MSI_PASSAGE_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MSI_PASSAGE_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MSI_PASSAGE_LONG}`,
      },
      ...constActivityManifestEnd,
    ],
  },
  'TEST.S.ICTE': {
    id: `TEST.S.ICTE`,
    ...constSpanishParams,
    seriesName: `Prueba de la serie de opciones en línea y entrada de texto`,
    episodeNumber: '3',
    episodeTitle: 'Elección en línea y entrada de texto',
    description: 'IC_SHORT, IC_MEDIUM, IC_LONG, TE_SHORT, TE_MEDIUM, TE_LONG',
    startScreenData: {
      ...constSpanishParams.startScreenData,
      learningTarget: [
        learningTargets[TEST_LEARNING_TARGET_TYPES.ES_INLINE_CHOICE],
        learningTargets[TEST_LEARNING_TARGET_TYPES.ES_TEXT_ENTRY],
      ],
    },
    nextUp: {
      nextActivityId: 'TEST.S.SCR',
      nextActivityThumbnail: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
      nextActivityName: 'Episodio 4',
    },
    populations: [
      populationsSpanishIC[TEST_POPULATION_TYPES.TEST_S_IC_SHORT],
      populationsSpanishIC[TEST_POPULATION_TYPES.TEST_S_IC_MEDIUM],
      populationsSpanishIC[TEST_POPULATION_TYPES.TEST_S_IC_LONG],
      populationsSpanishTE[TEST_POPULATION_TYPES.TEST_S_TE_SHORT],
      populationsSpanishTE[TEST_POPULATION_TYPES.TEST_S_TE_MEDIUM],
      populationsSpanishTE[TEST_POPULATION_TYPES.TEST_S_TE_LONG],
    ],
    activityManifest: [
      ...constSpanishParams.activityManifest,
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_IC_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_IC_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_IC_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_TE_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_TE_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_TE_LONG}`,
      },
      ...constActivityManifestEnd,
    ],
  },
  'TEST.S.SCR': {
    id: `TEST.S.SCR`,
    ...constSpanishParams,
    seriesName: `Prueba de respuesta breve construida sin serie de rúbricas`,
    episodeNumber: '4',
    episodeTitle: 'Respuesta breve construida',
    description:
      'SCR_SHORT, SCR_MEDIUM, SCR_LONG, SCR_PASSAGE_SHORT, SCR_PASSAGE_MEDIUM, SCR_PASSAGE_LONG, SCRR_SHORT, SCRR_MEDIUM, SCRR_LONG, SCRR_PASSAGE_SHORT, SCRR_PASSAGE_MEDIUM, SCRR_PASSAGE_LONG',
    startScreenData: {
      ...constSpanishParams.startScreenData,
      learningTarget: [
        learningTargets[
          TEST_LEARNING_TARGET_TYPES.ES_SHORT_CONSTRUCTED_RESPONSE
        ],
        learningTargets[
          TEST_LEARNING_TARGET_TYPES.ES_SHORT_CONSTRUCTED_RESPONSE_WITH_RUBRIC
        ],
      ],
    },
    characterLeanout: images[TEST_IMAGE_TYPES.CHARACTER_LEANOUT],
    nextUp: {
      nextActivityId: 'TEST.S.ECR',
      nextActivityThumbnail: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
      nextActivityName: 'Episode 5',
    },
    populations: [
      populationsSpanishSCR[TEST_POPULATION_TYPES.TEST_S_SCR_SHORT],
      populationsSpanishSCR[TEST_POPULATION_TYPES.TEST_S_SCR_MEDIUM],
      populationsSpanishSCR[TEST_POPULATION_TYPES.TEST_S_SCR_LONG],
      populationsSpanishSCR[TEST_POPULATION_TYPES.TEST_S_SCR_PASSAGE_SHORT],
      populationsSpanishSCR[TEST_POPULATION_TYPES.TEST_S_SCR_PASSAGE_MEDIUM],
      populationsSpanishSCR[TEST_POPULATION_TYPES.TEST_S_SCR_PASSAGE_LONG],
      populationsSpanishSCR[TEST_POPULATION_TYPES.TEST_S_SCRR_SHORT],
      populationsSpanishSCR[TEST_POPULATION_TYPES.TEST_S_SCRR_MEDIUM],
      populationsSpanishSCR[TEST_POPULATION_TYPES.TEST_S_SCRR_LONG],
      populationsSpanishSCR[TEST_POPULATION_TYPES.TEST_S_SCRR_PASSAGE_SHORT],
      populationsSpanishSCR[TEST_POPULATION_TYPES.TEST_S_SCRR_PASSAGE_MEDIUM],
      populationsSpanishSCR[TEST_POPULATION_TYPES.TEST_S_SCRR_PASSAGE_LONG],
    ],
    activityManifest: [
      ...constSpanishParams.activityManifest,
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_SCR_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_SCR_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_SCR_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_SCR_PASSAGE_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_SCR_PASSAGE_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_SCR_PASSAGE_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_SCRR_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_SCRR_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_SCRR_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_SCRR_PASSAGE_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_SCRR_PASSAGE_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_SCRR_PASSAGE_LONG}`,
      },
      ...constActivityManifestEnd,
    ],
  },
  'TEST.S.ECR': {
    id: `TEST.S.ECR`,
    ...constSpanishParams,
    seriesName: `Respuesta construida extendida con y sin rúbrica`,
    episodeNumber: '5',
    episodeTitle: 'Respuesta construida extendida',
    description:
      'ECR_SHORT, ECR_MEDIUM, ECR_LONG, ECR_PASSAGE_SHORT, ECR_PASSAGE_MEDIUM, ECR_PASSAGE_LONG, ECRR_SHORT, ECRR_MEDIUM, ECRR_LONG, ECRR_PASSAGE_SHORT, ECRR_PASSAGE_MEDIUM, ECRR_PASSAGE_LONG',
    startScreenData: {
      ...constSpanishParams.startScreenData,
      learningTarget: [
        learningTargets[
          TEST_LEARNING_TARGET_TYPES.ES_EXTENDED_CONSTRUCTED_RESPONSE
        ],
        learningTargets[
          TEST_LEARNING_TARGET_TYPES
            .ES_EXTENDED_CONSTRUCTED_RESPONSE_WITH_RUBRIC
        ],
      ],
    },
    characterLeanout: images[TEST_IMAGE_TYPES.CHARACTER_LEANOUT],
    nextUp: {
      nextActivityId: 'TEST.S.MIX.1',
      nextActivityThumbnail: images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE],
      nextActivityName: 'Episode 6',
    },
    populations: [
      populationsSpanishECR[TEST_POPULATION_TYPES.TEST_S_ECR_SHORT],
      populationsSpanishECR[TEST_POPULATION_TYPES.TEST_S_ECR_MEDIUM],
      populationsSpanishECR[TEST_POPULATION_TYPES.TEST_S_ECR_LONG],
      populationsSpanishECR[TEST_POPULATION_TYPES.TEST_S_ECR_PASSAGE_SHORT],
      populationsSpanishECR[TEST_POPULATION_TYPES.TEST_S_ECR_PASSAGE_MEDIUM],
      populationsSpanishECR[TEST_POPULATION_TYPES.TEST_S_ECR_PASSAGE_LONG],
      populationsSpanishECR[TEST_POPULATION_TYPES.TEST_S_ECRR_SHORT],
      populationsSpanishECR[TEST_POPULATION_TYPES.TEST_S_ECRR_MEDIUM],
      populationsSpanishECR[TEST_POPULATION_TYPES.TEST_S_ECRR_LONG],
      populationsSpanishECR[TEST_POPULATION_TYPES.TEST_S_ECRR_PASSAGE_SHORT],
      populationsSpanishECR[TEST_POPULATION_TYPES.TEST_S_ECRR_PASSAGE_MEDIUM],
      populationsSpanishECR[TEST_POPULATION_TYPES.TEST_S_ECRR_PASSAGE_LONG],
    ],
    activityManifest: [
      ...constSpanishParams.activityManifest,
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_ECR_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_ECR_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_ECR_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_ECR_PASSAGE_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_ECR_PASSAGE_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_ECR_PASSAGE_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_ECRR_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_ECRR_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_ECRR_LONG}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_ECRR_PASSAGE_SHORT}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_ECRR_PASSAGE_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_ECRR_PASSAGE_LONG}`,
      },
      ...constActivityManifestEnd,
    ],
  },
  'TEST.S.MIX.1': {
    id: `TEST.S.MIX.1`,
    ...constSpanishParams,
    seriesName: `Prueba de mezcla serie 1`,
    episodeNumber: '6',
    episodeTitle: 'Mezclar 1 entrada',
    description:
      'MC_MEDIUM, MC_TRUE_FALSE, MCI_MEDIUM, MS_MEDIUM, MSI_MEDIUM, IC_MEDIUM, TE_MEDIUM, MC_PASSAGE_MEDIUM, MS_PASSAGE_MEDIUM, MSI_PASSAGE_MEDIUM',
    startScreenData: {
      ...constSpanishParams.startScreenData,
      learningTarget: [learningTargets[TEST_LEARNING_TARGET_TYPES.ES_MIX_1]],
    },
    availableTools: [ActivityTool.VIDEO],
    populations: [
      populationsSpanishMC[TEST_POPULATION_TYPES.TEST_S_MC_MEDIUM],
      populationsSpanishMC[TEST_POPULATION_TYPES.TEST_S_MC_TRUE_FALSE],
      populationsSpanishMCI[TEST_POPULATION_TYPES.TEST_S_MCI_MEDIUM],
      populationsSpanishMS[TEST_POPULATION_TYPES.TEST_S_MS_MEDIUM],
      populationsSpanishMSI[TEST_POPULATION_TYPES.TEST_S_MSI_MEDIUM],
      populationsSpanishIC[TEST_POPULATION_TYPES.TEST_S_IC_MEDIUM],
      populationsSpanishTE[TEST_POPULATION_TYPES.TEST_S_TE_MEDIUM],
      populationsSpanishMC[TEST_POPULATION_TYPES.TEST_S_MC_PASSAGE_MEDIUM],
      populationsSpanishMS[TEST_POPULATION_TYPES.TEST_S_MS_PASSAGE_MEDIUM],
      populationsSpanishMSI[TEST_POPULATION_TYPES.TEST_S_MSI_PASSAGE_MEDIUM],
    ],
    activityManifest: [
      ...constSpanishParams.activityManifest,
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MC_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MC_TRUE_FALSE}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MCI_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MS_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MSI_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_IC_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_TE_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MC_PASSAGE_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MS_PASSAGE_MEDIUM}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_S_MSI_PASSAGE_MEDIUM}`,
      },
      ...constActivityManifestEnd,
    ],
  },
};
