export enum ClickstreamEventTypeName {
  SessionStart = 'SessionStart',
  ActivityBegin = 'ActivityBegin',
  ActivityComplete = 'ActivityComplete',
  AskQuestion = 'AskQuestion',
  AnswerCorrect = 'AnswerCorrect',
  AnswerIncorrect = 'AnswerIncorrect',
  ActivityInterrupt = 'ActivityInterrupt',
  Inactivity = 'Inactivity',
  Event = 'Event',
}

export enum ClickstreamEventTypeIndex {
  Error = 0x00,
  MouseMove,
  KeyUp,
  KeyDown,
  KeyPress,
  BrowseEnter,
  BrowseLeave,
  BrowseOver,
  Event,
  TimeLineBegin,
  TimeLineEnd,
  SceneRun,
  SceneFinished,
  ActivityEnter,
  ActivityExit,
  ActivityBegin,
  ActivityComplete,
  ActivityValue,
  ActivityParameter,
  AnswerCorrect,
  AnswerIncorrect,
  MasteryAchieved,
  DebugDataSet,
  ActivityInterrupt,
  AssessmentComplete,
  Inactivity,
  SkillAssessment,
  AskQuestion,
  SupervisorContext,
  SessionStart,
  RuntimePaused,
  RuntimeResumed,
  AssessmentResult,
  ClickStats,
  TransBegin,
  TransRollback,
  TransCommit,
  TransContinue,
  UserActivityEvent,
  AnalyticsEvent,
  ORFActivityEvent,
  Unknown = 0xff,
}
