import videojs from 'video.js';
import { IComponentOptionsExtended } from '../../interfaces/component-options-extended.interface';
import { JumpButtonComponent } from '../jump-button.component.ts/jump-button.component';

class JumpToStartComponent extends JumpButtonComponent {
  constructor(player: videojs.Player, options: IComponentOptionsExtended) {
    super(player, {
      ...options,
      classNames: 'vjs-jump-start',
    });

    this.setAttribute('data-testid', 'htc-vp-jump-to-start-button');

    this.controlText('Restart');
  }

  handleClick(): void {
    this.videoService.jumpStart();
  }
}

videojs.registerComponent('jumpToStart', JumpToStartComponent);
export default JumpToStartComponent;
