import { TEST_IMAGE_TYPES, images } from '../images';
import { Activity } from '../../shared/interfaces';

import { SubjectActivityList } from '../../shared/interfaces/subject-activity-list';
import {
  lectura1,
  lectura2,
  lectura3,
  lectura4,
  lectura5,
  lectura6,
  lectura7,
  lectura8,
  reading1,
  reading2,
  reading3,
  reading4,
  readingPrep4,
  readingPrep5,
  readingPrep6,
  readingPrep7,
} from '../jsons';

import { dragAndDropActivities } from '../sandbox/drag-and-drop';
import { testReadingActivities } from './test-reading-activities';
import { testMathActivities } from './test-math-activities';
import { testSpanishActivities } from './test-spanish-activities';
import { testVideoActivities } from './multiple-videos-activities';
import { readingNoun1 } from '../jsons/ReadingNouns1';
import { readingNoun2 } from '../jsons/ReadingNouns2';
import { testRandomVideoActivities } from './rand-multiple-videos-activities';

export const activities: Activity[] = [
  ...Object.entries(testReadingActivities).reduce(
    (acc: Activity[], [, value]) => {
      acc.push(value);
      return acc;
    },
    []
  ),
  ...Object.entries(testSpanishActivities).reduce(
    (acc: Activity[], [, value]) => {
      acc.push(value);
      return acc;
    },
    []
  ),
  ...Object.entries(testMathActivities).reduce((acc: Activity[], [, value]) => {
    acc.push(value);
    return acc;
  }, []),
  ...Object.entries(testVideoActivities).reduce(
    (acc: Activity[], [, value]) => {
      acc.push(value);
      return acc;
    },
    []
  ),
  ...Object.entries(testRandomVideoActivities).reduce(
    (acc: Activity[], [, value]) => {
      acc.push(value);
      return acc;
    },
    []
  ),
];

export const sandboxActivities: Activity[] = [
  ...Object.entries(dragAndDropActivities).reduce(
    (acc: Activity[], [, value]) => {
      acc.push(value);
      return acc;
    },
    []
  ),
  reading4,
];

export const readingActivityList: SubjectActivityList = {
  subjectName: 'Reading',
  icon: images[TEST_IMAGE_TYPES.READING_ICON],
  activities: [
    reading1,
    reading2,
    reading3,
    readingPrep4,
    readingPrep5,
    readingPrep6,
    readingPrep7,
    readingNoun1,
    readingNoun2,
  ],
};

export const lecturaActivityList: SubjectActivityList = {
  subjectName: 'Lectura',
  icon: images[TEST_IMAGE_TYPES.LECTURA_ICON],
  activities: [
    lectura1,
    lectura2,
    lectura3,
    lectura4,
    lectura5,
    lectura6,
    lectura7,
    lectura8,
  ],
};

export const mathActivityList: SubjectActivityList = {
  subjectName: 'Math',
  icon: images[TEST_IMAGE_TYPES.MATH_ICON],
  activities: [],
};

export const dragAndDropActivityList: SubjectActivityList = {
  subjectName: 'Drag and Drop',
  icon: images[TEST_IMAGE_TYPES.READING_ICON],
  activities: sandboxActivities,
};

export const releaseActivities: Activity[] = [
  ...readingActivityList.activities,
  ...lecturaActivityList.activities,
  ...sandboxActivities,
];
