import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProgressNodeFocusEnum, ProgressNodeTypeEnum } from '../../enums';
import { BaseNodeComponent } from '../base-node/base-node.component';

@Component({
  selector: 'htc-video-node',
  templateUrl: './video-node.component.html',
  styleUrls: ['./video-node.component.scss'],
})
export class VideoNodeComponent
  extends BaseNodeComponent
  implements OnInit, OnDestroy
{
  readonly path = '/assets/progress-node-icons/';
  readonly imageUrl = 'video-player.svg';

  type = ProgressNodeTypeEnum.videoNodeComp;
  focusClass = '';
  focusSub!: Subscription;

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.focus$) {
      this.focusSub = this.focus$.subscribe((value: number | undefined) => {
        if (value !== undefined) {
          this.onFocusChange(value);
        }
      });
    }
  }

  /**
   * On focus change set the url of video node image
   * @param  {ProgressNodeFocusEnum} focus state to set focus to
   */
  onFocusChange(focus: ProgressNodeFocusEnum): void {
    this.focusClass = '';
    switch (focus) {
      case ProgressNodeFocusEnum.active:
        this.focusClass = 'active';
        break;
      case ProgressNodeFocusEnum.seen:
        this.focusClass = 'seen';
        break;
      default:
        break;
    }
  }

  onNodeClicked(): void {
    if (this.clickedEmitter && !this.disabled) {
      this.clickedEmitter.emit({
        index: this.index,
        type: this.type,
        data: this.data,
        focus: this.focus$.getValue() ?? 0,
        status: this.status$.getValue() ?? 0,
      });
    }
  }

  ngOnDestroy(): void {
    if (this.focusSub) {
      this.focusSub.unsubscribe();
    }
  }
}
