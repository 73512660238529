import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProgressBarModule } from '../progress-bar/progress-bar.module';
import { SubheaderComponent } from './components/subheader/subheader.component';

@NgModule({
  imports: [CommonModule, SharedModule, ProgressBarModule, TranslateModule],
  exports: [SubheaderComponent],
  declarations: [SubheaderComponent],
})
export class SubheaderModule {}
