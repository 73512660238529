import { Message } from './../shared/interfaces/';
import {
  MessageCodes,
  MessageTypes,
  TriggerActionTypes,
} from '../../app/shared/enums';

export const Messages: { [key: number]: Message } = {
  [MessageCodes.HTTP_AUTH_REQUIRED]: {
    errorName: 'Authentication Required',
    headText: 'Hold up!',
    subheaderText: 'Please ask your teacher for help.',
    messageText:
      'It looks like you are not logged in. Close this window and reopen Istation.\n\n If this problem persists, <link>.',
    hyperLink: 'https://www.istation.com/Contact',
    hyperLinkText: 'contact Support',
    imagePath: 'fish',
    buttons: [
      {
        buttonLabel: 'OK',
        buttonTrigger: TriggerActionTypes.OK,
        buttonImage: 'check',
      },
    ],
    isFullScreen: false,
    type: MessageTypes.ERROR,
  },
  [MessageCodes.HTTP_FORBIDDEN]: {
    errorName: 'Not Authorized',
    headText: 'Wrong Way!',
    subheaderText: 'Please ask your teacher for help.',
    messageText: 'Oops! How did you get here?',
    hyperLink: '{REFERRER}',
    hyperLinkText: '',
    imagePath: 'diamond',
    buttons: [
      {
        buttonLabel: 'Go Home',
        buttonTrigger: TriggerActionTypes.LINK,
        buttonImage: 'arrow',
        showImageLeft: true,
      },
    ],
    isFullScreen: true,
    type: MessageTypes.ERROR,
  },
  [MessageCodes.HTTP_NOT_FOUND]: {
    errorName: 'Page Not Found',
    headText: 'Well, that’s unexpected!',
    subheaderText: 'Please ask your teacher for help.',
    messageText:
      'The page you are looking for doesn’t exist anymore or may have moved.',
    hyperLink: '{REFERRER}',
    hyperLinkText: '',
    imagePath: 'banana',
    buttons: [
      {
        buttonLabel: 'Go Home',
        buttonTrigger: TriggerActionTypes.LINK,
        buttonImage: 'arrow',
        showImageLeft: true,
      },
    ],
    isFullScreen: true,
    type: MessageTypes.ERROR,
  },
  [MessageCodes.HTTP_UNEXPECTED_NET_ERROR]: {
    errorName: 'Unexpected Network Error',
    headText: 'Sometimes life gives you lemons!',
    subheaderText: 'Please ask your teacher for help.',
    messageText:
      'There was a problem loading your content. Close this window and reopen Istation.',
    hyperLink: '{REFERRER}',
    hyperLinkText: '',
    imagePath: 'lemon',
    buttons: [
      {
        buttonLabel: 'Go Home',
        buttonTrigger: TriggerActionTypes.LINK,
        buttonImage: 'arrow',
        showImageLeft: true,
      },
    ],
    isFullScreen: false,
    type: MessageTypes.ERROR,
  },
  [MessageCodes.HTTP_NET_GENERIC]: {
    errorName: 'Unrecognized Network Error',
    headText: 'Yikes!',
    subheaderText: 'Please ask your teacher for help.',
    messageText:
      'Something went wrong with the connection to Istation. Close this window and reopen Istation.\n\n If this problem persists, <link>.',
    hyperLink: 'https://www.istation.com/Contact',
    hyperLinkText: 'contact Support',
    imagePath: 'potato',
    buttons: [
      {
        buttonLabel: 'OK',
        buttonTrigger: TriggerActionTypes.OK,
        buttonImage: 'check',
      },
    ],
    isFullScreen: false,
    type: MessageTypes.ERROR,
  },
  [MessageCodes.HTTP_SERVER_INTERNAL]: {
    errorName: 'Internal Server Error',
    headText: 'Uh-oh!',
    subheaderText: 'Please ask your teacher for help.',
    messageText:
      'Something is not working properly. Close this window and reopen Istation.\n\n If this problem persists, <link>.',
    hyperLink: 'https://www.istation.com/Contact',
    hyperLinkText: 'contact Support',
    imagePath: 'balloon',
    buttons: [],
    isFullScreen: true,
    type: MessageTypes.ERROR,
  },
  [MessageCodes.HTTP_SERVICE_UNAVAIL]: {
    errorName: 'Service Unavailable',
    headText: 'Out to lunch!',
    subheaderText: 'Please ask your teacher for help.',
    messageText:
      'Looks like this site is taking a lunch break. Try again later.\n\n If this problem persists, <link>.',
    hyperLink: 'https://www.istation.com/Contact',
    hyperLinkText: 'contact Support',
    imagePath: 'hamburger',
    buttons: [],
    isFullScreen: true,
    type: MessageTypes.ERROR,
  },
  [MessageCodes.APP_NETWORK]: {
    errorName: 'Network Error',
    headText: 'Whoops! We’re disconnected!',
    subheaderText: 'Please ask your teacher for help.',
    messageText:
      'Istation couldn’t reach its servers. Make sure you are connected to the internet and try again.',
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'beaver',
    buttons: [
      {
        buttonLabel: 'OK',
        buttonTrigger: TriggerActionTypes.OK,
        buttonImage: 'check',
      },
    ],
    isFullScreen: false,
    type: MessageTypes.ERROR,
  },
  [MessageCodes.APP_RES]: {
    errorName: 'Resolution Warning',
    headText: 'This doesn’t quite fit!',
    subheaderText: 'Please ask your teacher for help.',
    messageText:
      'Your device’s resolution does not meet our suggested requirements. While Istation will operate normally, we recommend a resolution of at least 1024 × 768 for the best experience.',
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'donut',
    buttons: [
      {
        buttonLabel: 'OK',
        buttonTrigger: TriggerActionTypes.OK,
        buttonImage: 'check',
      },
    ],
    isFullScreen: false,
    type: MessageTypes.WARNING,
  },
  [MessageCodes.APP_WARN_REFRESH]: {
    errorName: 'Refresh Warning',
    headText: 'Are you sure?',
    subheaderText: 'Please ask your teacher for help.',
    messageText:
      'If you reload the site, any changes you’ve made may be not be saved.',
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'lightning',
    buttons: [
      {
        buttonLabel: 'Cancel',
        buttonTrigger: TriggerActionTypes.CANCEL,
        buttonImage: 'cancel-x',
      },
      {
        buttonLabel: 'Reload',
        buttonTrigger: TriggerActionTypes.RELOAD,
        buttonImage: 'refresh',
      },
    ],
    isFullScreen: false,
    type: MessageTypes.WARNING,
  },
  [MessageCodes.APP_COMPAT]: {
    errorName: 'Compatibility Message',
    headText: 'This doesn’t look right!',
    subheaderText: 'Please ask your teacher for help.',
    messageText:
      'This platform isn’t currently supported. To view a list of supported platforms and devices, please visit our support page here: <link>.',
    hyperLink: 'https://www.istation.com/Support/IstationBrowser',
    hyperLinkText: 'Istation in the Browser',
    imagePath: 'dinosaur',
    buttons: [],
    isFullScreen: true,
    type: MessageTypes.ERROR,
  },
  [MessageCodes.APP_LOGOUT_CONFIRM]: {
    errorName: 'Confirm Logout',
    headText: 'See you later!',
    subheaderText: 'Are you sure you want to log out?',
    messageText: '',
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'gator',
    buttons: [
      {
        buttonLabel: 'No',
        buttonTrigger: TriggerActionTypes.CANCEL,
        buttonImage: 'cancel-x',
      },
      {
        buttonLabel: 'Yes',
        buttonTrigger: TriggerActionTypes.LOGOUT,
        buttonImage: 'check',
      },
    ],
    isFullScreen: false,
    type: MessageTypes.WARNING,
  },
  [MessageCodes.APP_CLOSE_CONFIRM]: {
    errorName: 'Confirm Closing Tab/Window While Logged In',
    headText: 'Fare-thee-well!',
    messageText: `Closing this window or tab will log you out of Istation. Any unsaved work may be lost!`,
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'crown',
    buttons: [
      {
        buttonLabel: 'Cancel',
        buttonTrigger: TriggerActionTypes.CANCEL,
        buttonImage: 'cancel-x',
      },
      {
        buttonLabel: 'OK',
        buttonTrigger: TriggerActionTypes.OK,
        buttonImage: 'check',
      },
    ],
    isFullScreen: false,
    type: MessageTypes.WARNING,
  },
  [MessageCodes.APP_IDLE]: {
    errorName: 'Inactivity Notification',
    headText: 'Are you still there?',
    messageText: `If you’re finished using Istation, you can sign out. If not, we’ll be here when you’re ready to continue!`,
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'popcorn',
    buttons: [
      {
        buttonLabel: "I'm here",
        buttonTrigger: TriggerActionTypes.CANCEL,
        buttonImage: 'check',
      },
    ],
    isFullScreen: false,
    type: MessageTypes.WARNING,
  },
  [MessageCodes.APP_EXIT_ACTIVITY_CONFIRM]: {
    errorName: 'Exit Activity Confirmation',
    headText: 'Leaving so soon?',
    messageText: `Are you sure you want to exit this activity? Next time you log in, you will be able to pick up where you left off.`,
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'octopus',
    buttons: [
      {
        buttonLabel: 'Stay Here',
        buttonTrigger: TriggerActionTypes.CANCEL,
        buttonImage: 'cancel-x',
        id: 'ExitPrompt-Stay',
      },
      {
        buttonLabel: 'Leave',
        buttonTrigger: TriggerActionTypes.OK,
        buttonImage: 'check',
        id: 'ExitPrompt-Leave',
      },
    ],
    isFullScreen: false,
    type: MessageTypes.WARNING,
  },
  [MessageCodes.APP_UNKNOWN_ERROR]: {
    errorName: 'Unknown Error',
    headText: 'Yikes!',
    subheaderText: 'Please ask your teacher for help.',
    messageText: `Something has gone wrong, but we aren’t sure what. Try relaunching Istation.`,
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'cat',
    buttons: [
      {
        buttonLabel: 'OK',
        buttonTrigger: TriggerActionTypes.OK,
        buttonImage: 'check',
      },
    ],
    isFullScreen: false,
    type: MessageTypes.ERROR,
  },
  [MessageCodes.HIDE_INTERNAL]: {
    errorName: 'Hide Internal Banner',
    headText: 'Are you sure you want to hide the banner?',
    messageText: `This practice project is still far from completion, so it shouldn't be shared outside of the company.\n\nHit OK to if you understand and wish to hide the banner.`,
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'fish',
    buttons: [
      {
        buttonLabel: 'Cancel',
        buttonTrigger: TriggerActionTypes.CANCEL,
        buttonImage: 'cancel-x',
      },
      {
        buttonLabel: 'OK',
        buttonTrigger: TriggerActionTypes.OK,
        buttonImage: 'check',
      },
    ],
    isFullScreen: false,
    type: MessageTypes.WARNING,
  },
  [MessageCodes.APP_EXIT_ACTIVITY_CONFIRM_ES]: {
    errorName: 'Exit Activity Confirmation',
    headText: '¿Ya te vas tan pronto?',
    messageText: `¿Estás seguro que quieres salir de esta actividad? Podrás continuar justo donde lo dejaste en el futuro.`,
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'octopus',
    buttons: [
      {
        buttonLabel: 'Permanece aquí',
        buttonTrigger: TriggerActionTypes.CANCEL,
        buttonImage: 'cancel-x',
      },
      {
        buttonLabel: 'Salida          ',
        buttonTrigger: TriggerActionTypes.OK,
        buttonImage: 'check',
        id: 'ExitPrompt-Leave',
      },
    ],
    isFullScreen: false,
    type: MessageTypes.WARNING,
  },
  [MessageCodes.SPEECH_SYNTHESIS_AUDIO_BUSY]: {
    errorName: 'Speech Synthesis Audio Busy',
    headText: `It's pretty quiet...`,
    subheaderText: 'Please ask your teacher for help.',
    messageText: `Read Aloud isn't working because something else is using Speech Synthesis\n\nIf this problem persists, <link>.`,
    hyperLink: 'https://www.istation.com/Contact',
    hyperLinkText: 'contact Support',
    imagePath: 'guitar',
    buttons: [
      {
        buttonLabel: 'OK',
        buttonTrigger: TriggerActionTypes.OK,
        buttonImage: 'check',
      },
    ],
    isFullScreen: true,
    type: MessageTypes.ERROR,
  },
  [MessageCodes.SPEECH_SYNTHESIS_AUDIO_HARDWARE]: {
    errorName: 'Speech Synthesis Audio Hardware',
    headText: `It's pretty quiet...`,
    subheaderText: 'Please ask your teacher for help.',
    messageText: `Read Aloud isn't working because speech synthesis couldn't find an audio output device. You may need to connect a speaker or configure system settings.`,
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'guitar',
    buttons: [
      {
        buttonLabel: 'OK',
        buttonTrigger: TriggerActionTypes.OK,
        buttonImage: 'check',
      },
    ],
    isFullScreen: true,
    type: MessageTypes.ERROR,
  },
  [MessageCodes.SPEECH_SYNTHESIS_NETWORK]: {
    errorName: 'Speech Synthesis Network',
    headText: `It's pretty quiet...`,
    subheaderText: 'Please ask your teacher for help.',
    messageText: `Read Aloud's speech synthesis isn't working due to a network error. Make sure you're connected to the internet.\n\nIf this problem persists, <link>.`,
    hyperLink: 'https://www.istation.com/Contact',
    hyperLinkText: 'contact Support',
    imagePath: 'guitar',
    buttons: [
      {
        buttonLabel: 'OK',
        buttonTrigger: TriggerActionTypes.OK,
        buttonImage: 'check',
      },
    ],
    isFullScreen: true,
    type: MessageTypes.ERROR,
  },
  [MessageCodes.SPEECH_SYNTHESIS_SYNTHESIS_UNAVAILABLE]: {
    errorName: 'Synthesis Unavailable',
    headText: `It's pretty quiet...`,
    subheaderText: 'Please ask your teacher for help.',
    messageText: `Read Aloud couldn't play because the speech synthesis engine was unavailable. You may need to install or configure a speech synthesis engine if you need to use Read Aloud.`,
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'guitar',
    buttons: [
      {
        buttonLabel: 'OK',
        buttonTrigger: TriggerActionTypes.OK,
        buttonImage: 'check',
      },
    ],
    isFullScreen: false,
    type: MessageTypes.ERROR,
  },
  [MessageCodes.SPEECH_SYNTHESIS_SYNTHESIS_FAILED]: {
    errorName: 'Synthesis Failed',
    headText: `It's pretty quiet...`,
    subheaderText: 'Please ask your teacher for help.',
    messageText: `Read Aloud failed because the speech synthesis engine raised an error. Make sure you have a working sound device enabled if you need to use Read Aloud.`,
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'guitar',
    buttons: [
      {
        buttonLabel: 'OK',
        buttonTrigger: TriggerActionTypes.OK,
        buttonImage: 'check',
      },
    ],
    isFullScreen: false,
    type: MessageTypes.ERROR,
  },
  [MessageCodes.SPEECH_SYNTHESIS_LANGUAGE_UNAVAILABLE]: {
    errorName: 'Speech Synthesis Language Unavailable',
    headText: `It's pretty quiet...`,
    subheaderText: 'Please ask your teacher for help.',
    messageText: `Read Aloud isn't working because speech synthesis can't find an available voice for the selected language.`,
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'guitar',
    buttons: [
      {
        buttonLabel: 'OK',
        buttonTrigger: TriggerActionTypes.OK,
        buttonImage: 'check',
      },
    ],
    isFullScreen: false,
    type: MessageTypes.ERROR,
  },
  [MessageCodes.SPEECH_SYNTHESIS_VOICE_UNAVAILABLE]: {
    errorName: 'Speech Synthesis Voice Unavailable',
    headText: `It's pretty quiet...`,
    subheaderText: 'Please ask your teacher for help.',
    messageText: `Read Aloud isn't working because the selected speech synthesis voice is unavailable.`,
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'guitar',
    buttons: [
      {
        buttonLabel: 'OK',
        buttonTrigger: TriggerActionTypes.OK,
        buttonImage: 'check',
      },
    ],
    isFullScreen: false,
    type: MessageTypes.ERROR,
  },
  [MessageCodes.SPEECH_SYNTHESIS_TEXT_TOO_LONG]: {
    errorName: 'Speech Synthesis Text Too Long',
    headText: `It's pretty quiet...`,
    subheaderText: 'Please ask your teacher for help.',
    messageText: `Read Aloud isn't working because the text is too long! That's not supposed to happen...\n\nPlease <link>.`,
    hyperLink: 'https://www.istation.com/Contact',
    hyperLinkText: 'contact Support',
    imagePath: 'guitar',
    buttons: [
      {
        buttonLabel: 'OK',
        buttonTrigger: TriggerActionTypes.OK,
        buttonImage: 'check',
      },
    ],
    isFullScreen: false,
    type: MessageTypes.ERROR,
  },
  [MessageCodes.SPEECH_SYNTHESIS_INVALID_ARGUMENT]: {
    errorName: 'Speech Synthesis Invalid Argument',
    headText: `It's pretty quiet...`,
    subheaderText: 'Please ask your teacher for help.',
    messageText: `Read Aloud ran into a bug: "speech synthesis invalid argument" We're not sure what happened.\n\nIf this problem persists, <link>.`,
    hyperLink: 'https://www.istation.com/Contact',
    hyperLinkText: 'contact Support',
    imagePath: 'guitar',
    buttons: [
      {
        buttonLabel: 'OK',
        buttonTrigger: TriggerActionTypes.OK,
        buttonImage: 'check',
      },
    ],
    isFullScreen: false,
    type: MessageTypes.ERROR,
  },
  [MessageCodes.SPEECH_SYNTHESIS_NOT_ALLOWED]: {
    errorName: 'Speech Synthesis Not Allowed',
    headText: `It's pretty quiet...`,
    subheaderText: 'Please ask your teacher for help.',
    messageText: `Read Aloud ran into a bug: "speech synthesis not-allowed" We're not sure what happened.\n\nIf this problem persists, <link>.`,
    hyperLink: 'https://www.istation.com/Contact',
    hyperLinkText: 'contact Support',
    imagePath: 'guitar',
    buttons: [
      {
        buttonLabel: 'OK',
        buttonTrigger: TriggerActionTypes.OK,
        buttonImage: 'check',
      },
    ],
    isFullScreen: false,
    type: MessageTypes.ERROR,
  },
  [MessageCodes.SPEECH_SYNTHESIS_TAB_MUTED]: {
    errorName: 'Speech Synthesis Tab Muted',
    headText: `It's pretty quiet...`,
    subheaderText: 'Is the tab muted?',
    messageText: `Read Aloud stopped without playing, probably because the tab or site is muted.\n\nTo unmute your tab, in your browser, right click on your tab (or press and hold if you have a touchscreen) and choose "unmute" from the menu. If that doesn't work, please ask your teacher for help.`,
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'guitar',
    buttons: [
      {
        buttonLabel: 'OK',
        buttonTrigger: TriggerActionTypes.OK,
        buttonImage: 'check',
      },
    ],
    isFullScreen: false,
    type: MessageTypes.ERROR,
  },
};
