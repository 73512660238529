<htc-dialog
  [showPopup]="show"
  [useBackground]="false"
  [useWideMode]="true"
  [fullscreen]="messagePopup && messagePopup.isFullScreen"
  [showClose]="false"
  [allowEscClose]="false">
  <div
    class="sys"
    [ngClass]="{
      fullscreen: messagePopup.isFullScreen,
      dialog: !messagePopup.isFullScreen
    }"
    *ngIf="messagePopup && show"
    htcTrapFocus
    data-testid="htc-message-popup">
    <section
      class="message-content"
      [ngClass]="{
        'message-content-warning':
          messagePopup.type === MessageTypes.WARNING &&
          !messagePopup.isFullScreen,
        'message-content-error':
          messagePopup.type === MessageTypes.ERROR && !messagePopup.isFullScreen
      }">
      <img
        src="{{ getErrorImagePath() }}"
        class="message-error-image"
        [alt]="'MESSAGE.' + messagePopup.imagePath.toUpperCase() | translate"
        data-testid="htc-message-error-image" />

      <header class="message-header">
        <h2 class="message-head-text" data-testid="htc-message-head-text">
          {{ messagePopup.headText }}
        </h2>
      </header>

      <div
        class="message-body"
        [ngClass]="{ 'message-body-with-sub': messagePopup.subheaderText }">
        <h3
          class="message-sub-head-text"
          data-testid="htc-message-sub-head-text"
          *ngIf="messagePopup.subheaderText">
          {{ messagePopup.subheaderText }}
        </h3>

        <p class="message-text" *ngIf="!hasLink" data-testid="htc-message-text">
          {{ messagePopup.messageText }}
        </p>

        <p
          class="message-text"
          *ngIf="hasLink"
          data-testid="htc-message-text-link">
          {{ splitMessageFirst }}
          <a href="{{ messagePopup.hyperLink }}">{{
            messagePopup.hyperLinkText
          }}</a
          >{{ splitMessageSecond }}
        </p>
      </div>

      <footer class="message-footer" data-testid="htc-message-btns">
        <htc-button-with-icon
          class="btn"
          *ngFor="let button of messagePopup.buttons; let i = index"
          [id]="button.id ?? ''"
          [ngClass]="{
            'btn-primary':
              (i === 0 && messagePopup.buttons.length === 1) ||
              (i >= 1 && messagePopup.buttons.length > 1),
            'btn-secondary': i === 0 && messagePopup.buttons.length > 1,
            'btn-with-right-icon': button.buttonImage && !button.showImageLeft,
            'btn-with-left-icon': button.buttonImage && button.showImageLeft
          }"
          [btnText]="button.buttonLabel"
          [imagePath]="getButtonImagePath(button.buttonImage)"
          (click)="clickButton(button.buttonTrigger)"
          htcAudioClick
          [audioType]="clickType"
          [descriptor]="clickDescriptor"></htc-button-with-icon>
      </footer>
    </section>
  </div>
</htc-dialog>
