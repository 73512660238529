<div class="flex-container flex-col base-gap-3r">
  <div class="flex-container flex-col menu">
    <htc-accordion [accordionSections]="sectionKeys">
      <ng-template #sect let-section>
        <p *ngFor="let item of flagSectionsMap.get(section)">
          <label
            [for]="'item_' + section + '_' + item"
            class="flex-container flex-align-center base-gap-1r">
            <input
              [id]="'item_' + section + '_' + item"
              type="checkbox"
              [name]="'item_' + section + '_' + item"
              value=""
              [ngModel]="flags.get(item)"
              (click)="onCheckboxClicked(item)" />{{ item }}
            @if (flagLabels[item]) {
              <i>{{ '(' + flagLabels[item] + ')' }}</i>
            }
          </label>
        </p>
      </ng-template>
    </htc-accordion>
  </div>
  <div class="flex-container base-gap-1r">
    <button
      *ngIf="(featureFlagService.hasOverride$ | async) ?? false"
      class="btn btn-danger"
      (click)="clearOverrides()">
      Clear Overrides
    </button>
  </div>
</div>
