import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { SettingsService } from 'src/app/shared/services/settings/settings.service';
import { Settings } from '../enums';
import { CaptionsService, TranscriptService, VideoService } from '.';
@Injectable({
  providedIn: 'root',
})
export class SettingsPopupsService {
  subMenuElements: Element[][] = [[], [], [], [], [], []];
  menuContents: Element[] = [];
  menuContentClickFunc = this.menuContentClick.bind(this);
  sub = new Subscription();
  settingsSubbed = false;

  constructor(
    private transcriptService: TranscriptService,
    private captionsService: CaptionsService,
    private videoService: VideoService,
    private settingsService: SettingsService
  ) {}

  subMenuClickListeners(comp: Element): void {
    this.addTranscriptGroup(comp);
    this.addCaptionsGroup(comp);
    this.addFontSizeGroup(comp);
    this.addMenuClickListener(comp);
    this.sub = new Subscription();

    this.sub.add(
      this.videoService.isPlaying$.subscribe(playing => {
        if (playing && !this.settingsSubbed) {
          this.sub.add(
            this.settingsService.settings.subscribe(settings => {
              const { subtitlesOn, subtitlesFontSize } = settings;

              const newFontSize =
                subtitlesFontSize === undefined
                  ? Settings.FONT_SIZE_MEDIUM
                  : subtitlesFontSize;
              const { captionFontSize } = this.captionsService;

              this.onCaptionLooksChange(newFontSize);
              this.onCaptionStateChange(
                subtitlesOn ?? false,
                newFontSize === captionFontSize
              );
            })
          );
          this.settingsSubbed = true;
        }
      })
    );
  }

  onCaptionLooksChange(fontSize: number): void {
    this.captionsService.onFontSizeSelect(fontSize, this.subMenuElements);
  }

  onCaptionStateChange(on: boolean, onlyCaptionChange: boolean): void {
    // only change captions on/off save state if init or
    // no other subtitle settings have changed
    if (!this.settingsSubbed || onlyCaptionChange) {
      this.captionsService.showCaptions(
        on,
        this.subMenuElements[Settings.CAPTION_TOGGLE]
      );
    }
  }

  addMenuClickListener(comp: Element): Element | null {
    const menuContent = comp.querySelector('.vjs-menu-content');
    if (menuContent) {
      menuContent.addEventListener('click', this.menuContentClickFunc);

      (<HTMLElement>menuContent).tabIndex = 0;
      this.menuContents.push(menuContent);
    }

    return menuContent;
  }

  menuContentClick(): void {
    if (this.videoService.captionMenu?.buttonPressed_) {
      this.videoService.captionMenu?.pressButton();
    }

    if (this.videoService.settingsMenu?.buttonPressed_) {
      this.videoService.settingsMenu?.pressButton();
    }
  }

  addTranscriptGroup(comp: Element): Element | null {
    const tranGroup = comp.querySelector('.transcript-button-group');
    if (tranGroup) {
      const btn = tranGroup.querySelector('.toggle');
      if (btn) {
        this.subMenuElements[Settings.TRANSCRIPT_TOGGLE].push(btn);
        btn.addEventListener('click', (event: Event) => {
          this.transcriptService.toggleTranscript(
            this.subMenuElements[Settings.TRANSCRIPT_TOGGLE]
          );
          this.preventClickThrough(event);
        });
      }
    }

    return tranGroup;
  }

  addCaptionsGroup(comp: Element): Element | null {
    const capGroup = comp.querySelector('.captions-button-group');
    if (capGroup) {
      const btn = capGroup.querySelector('.toggle');
      if (btn) {
        this.subMenuElements[Settings.CAPTION_TOGGLE].push(btn);
        btn.addEventListener('click', (event: Event) => {
          this.settingsService.saveSettings({
            subtitlesOn: !this.captionsService.showCaptions$.getValue(),
          });
          this.preventClickThrough(event);
        });
        this.captionsService.captionToggles =
          this.subMenuElements[Settings.CAPTION_TOGGLE];
      }
    }

    return capGroup;
  }

  addFontSizeGroup(comp: Element): Element | null {
    const fontSizeGroup = comp.querySelector('.font-size-button-group');
    if (fontSizeGroup) {
      this.addFontSizeBtn(fontSizeGroup, '.small-as', Settings.FONT_SIZE_SMALL);
      this.addFontSizeBtn(
        fontSizeGroup,
        '.medium-as',
        Settings.FONT_SIZE_MEDIUM
      );
      this.addFontSizeBtn(fontSizeGroup, '.large-as', Settings.FONT_SIZE_LARGE);
    }

    return fontSizeGroup;
  }

  addFontSizeBtn(
    fontSizeGroup: Element,
    childQuery: string,
    size: Settings
  ): Element | null {
    const btn = fontSizeGroup.querySelector(childQuery);
    if (btn) {
      this.subMenuElements[size].push(btn);
      btn.addEventListener('click', (event: Event) => {
        this.settingsService.saveSettings({ subtitlesFontSize: size });
        this.preventClickThrough(event);
      });
    }

    return btn;
  }

  preventClickThrough(event: Event): void {
    event.stopPropagation();
    event.cancelBubble = true;
  }

  removeEventListeners(): void {
    this.menuContents.forEach(menuContent => {
      menuContent.removeEventListener('click', this.menuContentClickFunc);
    });

    if (this.sub) {
      this.sub.unsubscribe();
    }

    this.settingsSubbed = false;
  }
}
