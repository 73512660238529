import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  ActivityService,
  TimeElapsedService,
  UrlService,
} from 'src/app/shared/services';
import { MessageService } from '../../message/services/message/message.service';
import { MessageCodes } from 'src/app/shared/enums';
import { activityIdToUuidMap } from 'src/app/data/activities/activity-id-to-uuid-map';
import { ReferrerService } from 'src/app/shared/services/referrer/referrer.service';

@Component({
  templateUrl: './activity-page.component.html',
  styleUrls: ['./activity-page.component.scss'],
})
export class ActivityPageComponent implements OnInit {
  constructor(
    private activityService: ActivityService,
    private timeElapsedService: TimeElapsedService,
    private location: Location,
    private urlService: UrlService,
    private messageService: MessageService,
    private referrerService: ReferrerService
  ) {}

  ngOnInit(): void {
    this.urlService.getParams().subscribe(params => {
      if (!params.activityId) {
        return;
      } else {
        const uuidRegex = new RegExp(
          '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$'
        );
        let activityUuid: string | undefined = params.activityId;
        if (!uuidRegex.test(params.activityId)) {
          activityUuid = activityIdToUuidMap[params.activityId];
        }
        if (!activityUuid) {
          this.showLoadError();
          return;
        }
        // regular assignment else self-selected assignment
        if (params.assignmentId) {
          this.activityService.assignmentId = params.assignmentId;
          this.activityService.setIsAssignment(true);
        }

        //TODO: set activity OID here
        this.activityService.currentActivityOid = Math.floor(
          Math.random() * (10000 - 1000) + 1000
        );

        this.activityService.fetchActivity(activityUuid).subscribe({
          next: () => {
            const restart =
              params.restart?.toString().toLowerCase() === 'restart';
            this.timeElapsedService.startTimer();
            if (
              this.activityService.initActivity(
                restart ? undefined : activityUuid
              )
            ) {
              this.location.replaceState('/');
            }
          },
          error: err => {
            console.error(`we had a load error!`, err);
            this.showLoadError();
          },
        });
      }
    });
  }

  showLoadError(): void {
    this.messageService.showMessage(MessageCodes.APP_UNKNOWN_ERROR, () => {
      this.referrerService.navigateToReferrer();
    });
  }
}
