import { Injectable } from '@angular/core';
import { VideoState } from '../../../shared/interfaces/video-state';
import { KnowledgeBaseService } from 'src/app/modules/olp-api/services/kb/kb.service';
import { FeatureFlagService } from 'src/app/modules/feature-flags/services/feature-flag/feature-flag.service';
import { FeatureFlags } from '../../../shared/enums/feature-flags';
import { BehaviorSubject } from 'rxjs';
import { AuthSessionService } from '../../auth/services/auth-session/auth-session.service';
import { MessageService } from '../../message/services/message/message.service';
import { MessageCodes } from 'src/app/shared/enums';

@Injectable({
  providedIn: 'root',
})
export class VideoSaveStateService {
  readonly activityKBStorage = 'MicroLessonVideoStates';
  readonly videoStorage = 'Video';
  readonly DEFAULT_VIDEO_STATE: VideoState = {
    id: '-1',
    videoProgression: 0,
    videoTotalWatchtime: 0,
    videoWatched: false,
  };

  curVideoState$ = new BehaviorSubject<VideoState>({
    ...this.DEFAULT_VIDEO_STATE,
  });
  watchedFullVideo$ = new BehaviorSubject<boolean>(false);

  constructor(
    private kbService: KnowledgeBaseService,
    private featureFlagService: FeatureFlagService,
    private authSessionService: AuthSessionService,
    private messageService: MessageService
  ) {}

  resetVideoSaveState(): void {
    this.watchedFullVideo$.next(false);
    this.curVideoState$.next({
      ...this.DEFAULT_VIDEO_STATE,
    });
  }

  setupVideoFromState(state: VideoState): void {
    this.curVideoState$.next({
      ...state,
    });
    if (!this.authSessionService.isTeacher) {
      if (this.watchedFullVideo$.getValue() !== state.videoWatched) {
        this.watchedFullVideo$.next(state.videoWatched);
      }
    } else {
      if (!this.watchedFullVideo$.getValue()) {
        this.watchedFullVideo$.next(true);
      }
    }
  }

  exportVideoSaveState(state: VideoState): void {
    this.setupVideoFromState(state);

    if (this.featureFlagService.isFlagEnabled(FeatureFlags.USE_KB)) {
      this.addVideoStateAndSync(this.curVideoState$.getValue());
    }
  }

  async getVideoSaveStateWithId(videoId: string): Promise<void> {
    this.resetVideoSaveState();
    await this.getVideoStateFromKB(videoId)
      .then(videoState => {
        if (videoState) {
          this.resetVideoSaveState();
          this.setupVideoFromState(videoState);
        } else {
          this.curVideoState$.next({
            ...this.DEFAULT_VIDEO_STATE,
            id: videoId,
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.resetVideoSaveState();
        this.setupVideoFromState({
          id: videoId,
          videoWatched: false,
          videoProgression: 0,
          videoTotalWatchtime: 0,
        } as VideoState);
      });
  }

  private async addVideoStateAndSync(videoState: VideoState): Promise<void> {
    return await this.kbService
      .setAndSync(
        this.activityKBStorage,
        `${this.videoStorage}_${videoState.id}`,
        { ...videoState }
      )
      .catch((error: Response) => {
        if (error.status === 409) {
          this.messageService.showMessage(
            MessageCodes.HTTP_UNEXPECTED_NET_ERROR
          );
        }
      });
  }

  private async getVideoStateFromKB(
    videoId: string
  ): Promise<VideoState | undefined> {
    return new Promise((resolve, reject) => {
      if (this.featureFlagService.isFlagEnabled(FeatureFlags.USE_KB)) {
        this.kbService
          .get(this.activityKBStorage, `${this.videoStorage}_${videoId}`)
          .then(response => {
            if (response) {
              const videoState = response as VideoState;
              resolve(videoState);
            } else {
              resolve(undefined);
            }
          });
      } else {
        reject('useKb feature Flag Disabled');
      }
    });
  }
}
