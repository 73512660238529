import { NgModule } from '@angular/core';
import {
  AuthModule,
  StsConfigHttpLoader,
  StsConfigLoader,
} from 'angular-auth-oidc-client';
import { from } from 'rxjs';
import { ConfigurationService } from '../../configuration/services/configuration/configuration.service';

const SUPPORTED_SCOPES = [
  'openid',
  'profile',
  'offline_access',
  'knowledge-base',
  'clickstream',
  'api.auth',
  'api.content.read',
  'api.assignment.write',
];
SUPPORTED_SCOPES.toString = function (): string {
  return this.join(' ');
};

const authConfigFactory = (c: ConfigurationService): StsConfigHttpLoader => {
  return new StsConfigHttpLoader(
    from(
      c.getAuthConfig().then(c => ({
        ...c,
        scope: SUPPORTED_SCOPES.toString(),
      }))
    )
  );
};

@NgModule({
  imports: [
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: authConfigFactory,
        deps: [ConfigurationService],
      },
    }),
  ],
  exports: [AuthModule],
})
export class AuthConfigModule {}
