import { Pipe, type PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  KATEX_END_DELIM,
  KATEX_START_DELIM,
  parseMathWrapperFieldsToSpeech,
} from '../utils/math-text-utils';
import { SpeechRuleEngineService } from '../services/speech-rule-engine.service';

@Pipe({
  name: 'htcMathToSpeech',
  standalone: true,
})
export class MathToSpeechPipe implements PipeTransform {
  constructor(
    private translateService: TranslateService,
    private sreService: SpeechRuleEngineService
  ) {}

  // need lastAddedMapKey for the pipe to recall transform on SRE behavior subject changes
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  transform(value: string, lastAddedMapKey: string | null): string {
    return parseMathWrapperFieldsToSpeech(
      value,
      KATEX_START_DELIM,
      KATEX_END_DELIM,
      this.sreService.speechMap,
      this.translateService.currentLang,
      this.sreService.mapKey,
      this.translateService.instant('MATH_TEXT.LOADING')
    );
  }
}
