<div
  class="menu"
  id="clickstream-debug-menu"
  data-testid="clickstream-debug-menu">
  <div class="feature-flags" id="feature-flag-container">
    <h2>Feature Flags</h2>
    <htc-toggle
      [(state)]="useAuth"
      (stateChange)="updateFlag(flags.AUTHENTICATE, $event)"
      label="Use Authentication">
      Authenticate
    </htc-toggle>
    <htc-toggle
      [(state)]="useClickstream"
      (stateChange)="updateFlag(flags.USE_CLICKSTREAM, $event)"
      label="Use Clickstream">
      Clickstream
    </htc-toggle>
  </div>
  <div class="controls" id="clickstream-controls">
    <h2>Clickstream Interaction</h2>
    <p class="float-right">
      Current Session id: {{ clickstreamService.clickstream.sessionId }}
    </p>
    <div class="sections">
      <div class="section state">
        <h3 class="float">State</h3>

        <div class="events">
          @for (
            event of clickstreamService.clickstream.clickstreamCacheData
              ?.events ?? [];
            track event
          ) {
            <p>
              {{ event.eventType }} | {{ event.key }} | {{ event.offset }} |
              {{ json.stringify(event.data) }}
            </p>
          }
        </div>
      </div>
      <div class="section">
        <h3>Session</h3>
        <button (click)="startSession(true)" [disabled]="!useClickstream">
          Start a new session
        </button>
        <button (click)="startSession(false)" [disabled]="!useClickstream">
          Resume the previous session
        </button>
        <button (click)="sendClickstream()" [disabled]="!useClickstream">
          Send Clickstream
        </button>
        <button
          class="danger"
          (click)="clearEvents()"
          [disabled]="!useClickstream">
          Clear Clickstream Events
        </button>
      </div>
      <div class="section">
        <h3>Activity</h3>
        <button (click)="startActivity()" [disabled]="!useClickstream">
          Start Activity
        </button>
        <button (click)="completeActivity()" [disabled]="!useClickstream">
          Complete Activity
        </button>
        <button (click)="interruptActivity()" [disabled]="!useClickstream">
          Interrupt Activity
        </button>
        <button (click)="reportInactivity()" [disabled]="!useClickstream">
          Report Inactivity
        </button>
      </div>
      <div class="section">
        <h3>Question</h3>
        <button (click)="askQuestion()" [disabled]="!useClickstream">
          Ask Question
        </button>
        <button (click)="randomizeQuestion()" [disabled]="!useClickstream">
          Randomize Question
        </button>
        <button (click)="answerCorrect()" [disabled]="!useClickstream">
          Answer Correct
        </button>
        <button (click)="answerIncorrect()" [disabled]="!useClickstream">
          Answer Incorrect
        </button>
      </div>
    </div>
  </div>
</div>
