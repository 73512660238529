<div
  class="alert {{ classes }}"
  [ngClass]="{
    visible: showAlert,
    default: type === 'default',
    error: type === 'error',
    'playing-read-aloud': this.readAloudService.currentReadAloud?.isPlaying
  }"
  [attr.role]="role"
  [attr.aria-hidden]="!showAlert"
  title=""
  htcFocus
  [isFocused]="showAlert">
  <header>
    <div class="flex-container flex-row flex-center">
      <svg-icon
        data-testid="htc-alert-header-icon"
        class="alert-header-icon"
        [src]="imageSource"
        *ngIf="imageSource !== ''"></svg-icon>
      <span title="{{ title }}" data-testid="htc-alert-header-title">{{
        title
      }}</span>
    </div>

    <button
      class="btn btn-close flex-container flex-center"
      data-testid="htc-alert-close-button"
      (click)="close()"
      [attr.aria-label]="'ALERT.CLOSE_ARIA_LABEL' | translate"
      data-skip-focus-on-open="true"
      htcAudioClick
      [audioType]="clickType"
      [descriptor]="clickDescriptor">
      <svg-icon src="assets/button-icons/close.svg"></svg-icon>
    </button>
  </header>

  <section #alertSection class="alert-section">
    <ng-content></ng-content>
  </section>
</div>
