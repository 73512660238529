import { MathTextType } from '../enums/math-text-type';
import { MathTextFields } from '../interfaces/math-text-fields';

export const KATEX_START_DELIM = 'kxs';
export const KATEX_END_DELIM = 'kxe';

export function parseMathWrapperFieldsToSpeech(
  katex: string,
  startDelim: string,
  endDelim: string,
  speechMap: { [key: string]: string | Promise<string> },
  locale: string,
  mapKeyFunc: (locale: string, tex: string) => string,
  loadingPlaceholder: string
): string {
  let speech = '';
  const withoutMarkdownKatex = removeBasicMarkdown(katex);
  const fields = parseMathTextFields(
    withoutMarkdownKatex,
    startDelim,
    endDelim
  );
  fields.forEach(field => {
    if (field.isMathText) {
      const key = mapKeyFunc(locale, field.textInput);
      if (Object.prototype.hasOwnProperty.call(speechMap, key)) {
        // adding math speech to the sentence
        speech += speechMap[key];
      } else {
        // adding math speech placeholder to sentence as it loads
        speech += ` ${loadingPlaceholder} `;
      }
    } else {
      // adding non math speech to sentence
      speech += field.textInput.replaceAll('&nbsp;', ' ');
    }
  });

  return speech;
}

function removeBasicMarkdown(text: string): string {
  return text
    .replaceAll('<b>', '')
    .replaceAll('</b>', '')
    .replaceAll('<i>', '')
    .replaceAll('</i>', '');
}

export function parseFunctionFromKatex(katex: string): MathTextType {
  if (katex.includes('\\frac')) {
    return MathTextType.FRACTION;
  }
  return MathTextType.OTHER;
}

export function parseMathTextFields(
  textInput: string,
  startDelim: string,
  endDelim: string
): MathTextFields[] {
  let fieldIndex = -2;
  let fields: MathTextFields[] = [];

  // handle escape characters
  const textStringfy = stringifyText(textInput);

  for (let i = 0; i < textStringfy.length; i++) {
    const c = textStringfy.charAt(i);

    const isStartDelim =
      textStringfy.substring(i, i + startDelim.length) === startDelim;
    const isEndDelim =
      textStringfy.substring(i, i + endDelim.length) === endDelim;

    // push a new field
    if (
      fieldIndex !== fields.length - 1 ||
      ((isStartDelim || isEndDelim) &&
        i + endDelim.length < textStringfy.length)
    ) {
      fields.push({ textInput: '', isMathText: isStartDelim });
      fieldIndex = fields.length - 1;
    }

    // continue past delim to not include in fields
    if (isStartDelim) {
      i += startDelim.length - 1;

      continue;
    } else if (isEndDelim) {
      i += endDelim.length - 1;

      // in case `{endDelim}{startDelim}`
      const isNextStartDelim =
        textStringfy.substring(i + 1, i + 1 + startDelim.length) === startDelim;
      if (isNextStartDelim) {
        fields[fields.length - 1].isMathText = true;
        i += startDelim.length;
      }

      continue;
    }

    // add character to current field or add individual new line character as field
    if (textStringfy.substring(i, i + 2) !== '\\n') {
      fields[fieldIndex].textInput += c;
    } else {
      handleNewLineCharacter(fields, fieldIndex);
      fieldIndex = -1;
      i += 1;
      continue;
    }

    // skip double backslashes if input has them
    i += doubleBackslashOffset(c, textStringfy.charAt(i + 1));
  }

  fields = preserveEndingSpaces(fields);

  return fields;
}

// preserve trim spaces in markdown
function preserveEndingSpaces(fields: MathTextFields[]): MathTextFields[] {
  fields.forEach(field => {
    if (!field.isMathText) {
      if (field.textInput.length > 0 && field.textInput[0] === ' ') {
        field.textInput =
          '&nbsp;' + field.textInput.substring(1, field.textInput.length);
      }

      if (
        field.textInput.length > 1 &&
        field.textInput[field.textInput.length - 1] === ' '
      ) {
        field.textInput =
          field.textInput.substring(0, field.textInput.length - 1) + '&nbsp;';
      }
    }
  });

  return fields;
}

function stringifyText(text: string): string {
  const textStringfy = JSON.stringify(text);
  return textStringfy.substring(1, textStringfy.length - 1);
}

function handleNewLineCharacter(
  fields: MathTextFields[],
  fieldIndex: number
): void {
  if (fields[fieldIndex].textInput.length === 0) {
    fields[fieldIndex].textInput = '\n';
    fields[fieldIndex].isMathText = false;
  } else {
    fields.push({ textInput: '\n', isMathText: false });
  }
}

function doubleBackslashOffset(c: string, nextC: string): number {
  if (c === '\\' && nextC === '\\') {
    return 1;
  }

  return 0;
}

export function trimNewLines(text: string): string {
  return text.replace(/^\n+|\n+$/g, '');
}

export function hasInvalidKatexDelims(
  textInput: string,
  startDelim: string,
  endDelim: string
): number {
  if (hasInvalidMathEndDelimFirst(textInput, startDelim, endDelim)) {
    return 1;
  } else if (hasInvalidMathStartDelimLast(textInput, startDelim, endDelim)) {
    return 2;
  } else if (hasUnequalDelimCount(textInput, startDelim, endDelim)) {
    return 3;
  }

  return 0;
}

function hasInvalidMathEndDelimFirst(
  textInput: string,
  startDelim: string,
  endDelim: string
): boolean {
  const startIndex = textInput.indexOf(startDelim);
  const endIndex = textInput.indexOf(endDelim);

  if (startIndex !== -1 && endIndex !== -1 && endIndex <= startIndex) {
    return true;
  }

  return false;
}

function hasInvalidMathStartDelimLast(
  textInput: string,
  startDelim: string,
  endDelim: string
): boolean {
  const startIndex = textInput.lastIndexOf(startDelim);
  const endIndex = textInput.lastIndexOf(endDelim);

  if (startIndex !== -1 && endIndex !== -1 && endIndex <= startIndex) {
    return true;
  }
  return false;
}

function getDelimCount(textInput: string, delim: string): number {
  const regex = new RegExp(delim, 'g');
  return (textInput.match(regex) || []).length;
}

function hasUnequalDelimCount(
  textInput: string,
  startDelim: string,
  endDelim: string
): boolean {
  const startCount = getDelimCount(textInput, startDelim);
  const endCount = getDelimCount(textInput, endDelim);

  if (startCount !== endCount) {
    return true;
  }

  return false;
}
