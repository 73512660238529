import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LexicalTableComponent } from './components/lexical-table/lexical-table.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [LexicalTableComponent],
  imports: [CommonModule, AngularSvgIconModule, TranslateModule],
  exports: [LexicalTableComponent],
})
export class LexicalTableModule {}
