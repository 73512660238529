export enum QuestionState {
  initial,
  askQuestion,
  answerQuestion,
  reAskQuestion,
  reAnswerQuestion,
}

export enum QuestionType {
  multiChoice,
  writtenResponse,
  textMatch,
}
