import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UrlService } from 'src/app/shared/services';

@Injectable({
  providedIn: 'root',
})
export class SaveUrlParamsGuard {
  constructor(private urlService: UrlService) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.urlService.setParamsInStorage({
      ...route.queryParams,
      ...route.params,
    });
    return true;
  }
}
