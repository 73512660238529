import { KnowledgeBaseService } from 'src/app/modules/olp-api/services/kb/kb.service';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FeatureFlagService } from 'src/app/modules/feature-flags/services/feature-flag/feature-flag.service';
import { Settings } from 'src/app/shared/interfaces';
import { UserService } from 'src/app/shared/services';
import { SettingsService } from 'src/app/shared/services/settings/settings.service';
import { FeatureFlags } from 'src/app/shared/enums/feature-flags';
import {
  IncompleteActivityStateService,
  CompleteActivityStateService,
} from 'src/app/modules/activity-save-state';
import { MessageService } from 'src/app/modules/message/services/message/message.service';
import { MessageCodes } from 'src/app/shared/enums';

@Component({
  selector: 'htc-kb-debug-menu',
  templateUrl: './kb-debug-menu.component.html',
  styleUrls: ['./kb-debug-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KBDebugMenuComponent implements OnInit {
  settings: Settings = {
    subtitlesOn: false,
    readAloud: false,
    subtitlesFontSize: 0,
  };
  Object = Object;
  FeatureFlags = FeatureFlags;
  isAuthorized = true;
  constructor(
    public settingsService: SettingsService,
    public featureFlagService: FeatureFlagService,
    private kbService: KnowledgeBaseService,
    private userService: UserService,
    private messageService: MessageService,
    private incompleteService: IncompleteActivityStateService,
    private completeService: CompleteActivityStateService
  ) {}

  ngOnInit(): void {
    this.isAuthorized =
      this.featureFlagService.isFlagEnabled(FeatureFlags.KB_DEBUG) &&
      this.featureFlagService.isFlagEnabled(FeatureFlags.USE_KB);
  }

  onCheckboxClicked(key: string, e: Event): void {
    let val;
    if (e.target) {
      const target = <HTMLInputElement>e.target;
      if (key === 'subtitlesFontSize') {
        val = +target.value;
      } else {
        val = target.checked;
      }
    }

    this.settings = { ...this.settings, [key]: val };
    this.settingsService.settings.next(this.settings);
  }

  onSaveSettings(): void {
    this.settingsService.settings.next(this.settings);
    this.settingsService.saveSettings();
    window.alert('Settings saved!');
  }

  onDeleteSettings(): void {
    this.settingsService.settings.next({});
    this.settingsService.saveSettings();

    setTimeout(() => {
      this.settingsService.settings.next({
        ...this.settingsService.STARTER_SETTINGS,
        readAloud:
          this.userService.grade$.getValue() <=
          this.settingsService.MAX_READ_ALOUD_ENABLE_GRADE,
      });
      window.alert('Settings deleted!');
    }, this.settingsService.SAVE_TIMEOUT + 500);

    // TODO eventually use this but not working atm
    // this.kbService.delete(
    //   this.settingsService.KB_SECTION,
    //   this.settingsService.KB_KEY
    // );
    // await this.kbService.sync();
  }

  printKb(): void {
    this.kbService
      .getAll()
      .then(kb => {
        console.log('printKb', kb);
        window.alert('KB printed, check your console!');
      })
      .catch(err => {
        window.alert(
          'Error printing KB [' +
            err.message +
            '], please ensure you are on VPN.'
        );
      });
  }

  clearKb(): void {
    this.kbService.deleteAll();
    this.kbService
      .sync()
      .then(() => {
        window.alert('KB Cleared!');
      })
      .catch(err => {
        window.alert(
          'Error clearing KB [' +
            err.message +
            '], please ensure you are on VPN.'
        );
      });
  }

  printIncomplete(): void {
    Promise.all([
      this.incompleteService.getSavedActivityIds(),
      this.incompleteService.getSavedActivityStates(),
    ]).then(([ids, states]) => {
      console.log('printIncomplete', ids, states);
      window.alert('Incomplete printed, check your console!');
    });
  }

  printComplete(): void {
    this.completeService.getCompletedActivities().then(completed => {
      console.log('printComplete', completed);
      window.alert('Complete printed, check your console!');
    });
  }

  invalidateCco(): void {
    this.kbService.setCcoVersion(9001);
  }

  sendUnauthRequest(): void {
    const KB_SECTION = 'HTMLActivityStorage';
    const KB_KEY = 'Settings';
    this.kbService.get(KB_SECTION, KB_KEY).catch(() => {
      this.messageService.showMessage(MessageCodes.HTTP_AUTH_REQUIRED);
    });
  }
}
