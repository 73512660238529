import { Injectable } from '@angular/core';
import { HowlerResource } from '../../classes/howler-resource/howler-resource';
import { Howl, Howler } from 'howler';
import { AudioData } from '../../../../shared/interfaces';
import { Subject } from 'rxjs';
import { AudioType } from 'src/app/shared/enums/audio-type';
import { Themes } from 'src/app/shared/enums/themes';
import { AudioInitResult } from 'src/app/shared/interfaces/audio-types';
import { audioMappings, audioResources } from 'src/app/data/audio-resources';

@Injectable({
  providedIn: 'root',
})
export class AudioService {
  public loadedAudioId = new Subject<string>();
  public playbackEndId = new Subject<string>();
  public failedAudioId = new Subject<string>();
  private sounds = new Map<string, HowlerResource>();
  private currentSound?: HowlerResource;
  private queuedSound?: HowlerResource;
  private readonly maxSounds: number = 20; // Max that should be held in the collection at a given time. May need to be tweaked later...

  audioPlaying = false;

  constructor() {
    Howler.autoSuspend = false;
  }

  /**
   * Checks if a sound with a given id is loaded
   * @param id id of audio to check if it's loaded
   * @returns true if the audio is loaded in memory, false if not
   */
  isSoundLoaded(id: string): boolean {
    return this.sounds.get(id)?.audioLoaded ?? false;
  }

  /**
   * Check if the sound of a given id is loaded, and is currently in a playing or paused state
   * @param id id of audio to check if it's loaded
   * @returns true if sound is loaded and playing or paused, false if not
   */
  isSoundActive(id: string): boolean {
    const sound = this.sounds.get(id);
    console.log('sound', sound);
    return sound !== undefined && (sound.howl.playing() || sound.paused);
  }

  /**
   * Is current sound playing or paused?
   * @returns true if sound is loaded and playing OR paused, false otherwise
   */
  isCurrentSoundActive(): boolean {
    return (
      this.currentSound !== undefined &&
      this.isSoundActive(this.currentSound.id)
    );
  }

  /**
   * Loads the audio resource in memory and prepares it to play
   * @param audioData audio data to initialize
   * @returns true if the resource creation was successful and hasn't been created before
   */
  initSound(audioData: AudioData): Promise<boolean> {
    const sound = this.sounds.get(audioData.id);
    if (!sound) {
      return this.initHowlerResource(audioData);
    } else {
      sound.inc();
      return Promise.resolve(false);
    }
  }

  /**
   * Queues up a sound to play when the current one stops
   * @param audioData audio data to add to the play queue
   */
  queueSound(audioData: AudioData): void {
    this.queuedSound = this.sounds.get(audioData.id);
  }

  /**
   * Attempts to play the audio with the given audioData's id
   * @param audioData Audio data to play
   * @param preemptCurrent true to cut off currently playing audio, false to not play if other audio is currently playing
   * @param silent true to start the audio muted, false to start it at the current volume
   * @returns true if it is able to play, false if not
   *
   * TODO: Should be able to play more than one audio at a time...
   */
  playSound(
    audioData: AudioData,
    preemptCurrent: boolean = false,
    silent: boolean = false
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (preemptCurrent && this.audioPlaying) {
        if (this.currentSound) {
          if (this.currentSound.howl.playing()) {
            this.queuedSound = this.sounds.get(audioData.id);
            this.stopSound(this.currentSound.id);
            return resolve(false);
          }
        } else {
          return reject('audio.service - No current sound to stop');
        }
      }

      if (!this.audioPlaying) {
        if (audioData.id) {
          const sound = this.sounds.get(audioData.id);
          if (sound && sound.audioLoaded) {
            this.play(sound, silent).then(result => {
              resolve(result);
            });
          } else {
            reject(
              'audio.service - Error locating sound for: ' +
                audioData.id +
                ' or sound not loaded'
            );
          }
        }
      } else {
        reject(
          'audio.service - Playback already in progress. Rejected: ' +
            audioData.id
        );
      }
    });
  }

  /**
   * Fades the volume between start and end within duration time
   * @param id Id of the sound to fade
   * @param start volume to start the fade from
   * @param end volume to end the fade at
   * @param duration time to fade from start to end
   * @returns if fade was successful
   */
  fadeSound(id: string, start: number, end: number, duration: number): boolean {
    const sound = this.sounds.get(id);
    if (sound && sound.howl.playing()) {
      sound.howl.fade(start, end, duration);
      return true;
    }
    return false;
  }

  /**
   * if a sound is currently playing, pause it, if it's currently paused, play it
   * @param id id of sound to toggle pause
   */
  toggleSoundPause(id: string): void {
    const sound = this.sounds.get(id);
    if (!sound) return;

    if (sound.howl.playing()) {
      sound.howl.pause();
      sound.paused = true;
    } else {
      sound.howl.play();
      sound.paused = false;
    }
  }

  /**
   * If a sound is currently playing, pause it.
   */
  toggleCurrentSoundPause(): void {
    if (!this.currentSound) return;
    this.toggleSoundPause(this.currentSound.id);
  }

  /**
   * Stops a sound based on id provided
   * @param id id of audio to stop
   */
  stopSound(id: string): void {
    const sound = this.sounds.get(id);
    if (sound && (sound.howl.playing() || sound.paused)) {
      sound.howl.stop();
    }
  }

  /**
   * If there is audio currently playing, this method stops it
   */
  stopCurrentSound(): void {
    if (!this.currentSound) return;
    this.stopSound(this.currentSound.id);
  }

  /**
   * Stops any currently playing audio
   */
  stopAllSounds(): void {
    this.sounds.forEach(sound => {
      if (sound.howl.playing()) {
        sound.howl.stop();
      }
    });
  }

  /**
   * remove reference from sounds ref count
   * @param id id of audio to remove reference from
   * @returns current reference count for the audio with the id
   */
  release(id: string): number {
    const sound = this.sounds.get(id);
    if (sound) {
      return sound.dec();
    }
    return -1;
  }

  /**
   * Gets the reference count for the audio with the given id
   * @param id id of audio to get reference count for
   * @returns number of references for the sound with the given id
   */
  getRefCount(id: string): number {
    const sound = this.sounds.get(id);
    if (sound) {
      return sound.getRefCount();
    }
    return -1;
  }

  /**
   * Create a HowlerResource from the audio data, and adds it to the sounds map after loading
   * @param audioData Audio data to create a resource for
   * @returns true if the resource creation was successful, false if it was not
   */
  private initHowlerResource(audioData: AudioData): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (audioData) {
        if (!audioData.url) reject('audio.service - No url provided');
        const sound = new HowlerResource(audioData);
        const soundfile = sound.url as string;

        sound.howl = new Howl({
          src: [soundfile],
          autoplay: false,
          mute: true,
          onload: (): void => {
            sound.audioLoaded = true;
            sound.inc();
            this.loadedAudioId.next(sound.id);
            this.sounds.set(sound.id, sound);
            resolve(true);
          },
          onplay: (): void => {
            this.audioPlaying = true;
            this.currentSound = sound;
          },
          onend: (): void => {
            sound.howl.mute(true);
            sound.howl.volume(0.0);
            this.audioPlaying = false;
            this.currentSound = undefined;
            this.playbackEndId.next(sound.id);
          },
          onstop: (): void => {
            this.audioPlaying = false;
            this.currentSound = undefined;
            if (this.queuedSound) {
              this.playSound(this.queuedSound as AudioData, true);
              this.queuedSound = undefined;
            }
          },
          onloaderror: (error): void => {
            this.failedAudioId.next(sound.id);
            reject(error);
          },
          onplayerror: (error): void => {
            console.error('audio play error', error);
          },
        });
      } else {
        reject('addAudioResource failed');
      }
    });
  }

  /**
   * plays a sound if audio engine isn't locked
   * @param sound HowlerResource to play
   * @param silent true if it should be played silently, false if it should be played normally
   * @returns resolves as true if the audio was able to play, false if it was unable to play
   */
  private async play(
    sound: HowlerResource,
    silent: boolean = false
  ): Promise<boolean> {
    return new Promise(resolve => {
      this.isAudioEngineLocked().then(result => {
        if (result) {
          resolve(false);
        } else {
          sound.howl.play();
          if (!silent) {
            sound.howl.mute(false);
            sound.howl.volume(1.0);
          }
          resolve(true);
        }
      });
    });
  }

  /**
   * Checks if HTML5Audio is locked
   * @returns resolves as false if audio is able to play, true if it's locked and unable to play
   */
  async checkHTML5AudioIsLocked(
    resolve: (value: boolean | PromiseLike<boolean>) => void
  ): Promise<void> {
    const audio = new Audio();
    try {
      await audio.play();
      resolve(false);
    } catch (err) {
      resolve(true);
    }
  }

  /**
   * Checks if Howler is locked, if so, checkHTML5AudioIsLocked
   * @returns resolves as false if audio is able to play, true if it's locked and unable to play
   */
  isAudioEngineLocked(): Promise<boolean> {
    return new Promise(resolve => {
      try {
        resolve(Howler.ctx.state === 'suspended');
      } catch (e) {
        console.log('Howler is locked, trying HTML5Audio');
        this.checkHTML5AudioIsLocked(resolve);
      }
    });
  }

  isAudioPlaying(): boolean {
    return this.audioPlaying;
  }

  initSFXAudio(
    type: AudioType,
    descriptor: string,
    theme: Themes = Themes.DEFAULT
  ): AudioInitResult {
    let result = { id: '', loaded: false };

    if (type !== AudioType.audio_Undefined) {
      const id = this.getResourceId(type, descriptor, theme);
      if (id !== '') {
        result = this.requestStockResource(id);
      }
    }

    return result;
  }

  playSFXAudio(
    type: AudioType,
    descriptor: string,
    theme: Themes = Themes.DEFAULT
  ): Promise<boolean> {
    const soundId = this.getResourceId(type, descriptor, theme);
    return this.playSound({ id: soundId }, true);
  }

  private requestStockResource(id: string): AudioInitResult {
    const result = { id: '', loaded: false };

    for (const audio of audioResources) {
      if (audio.id === id) {
        result.id = id;
        // Now make sure it hasn't been requested ...
        const sound = this.sounds.get(id) || null;
        if (sound === null) {
          this.initHowlerResource(audio);
        } else {
          result.loaded = sound.audioLoaded;
          sound.inc();
        }
        break;
      }
    }

    return result;
  }

  private getResourceId(
    type: AudioType,
    descriptor: string,
    theme: Themes = Themes.DEFAULT
  ): string {
    let result = '';

    if (type !== AudioType.audio_Undefined) {
      for (const audioMap of audioMappings) {
        if (
          audioMap.type === type &&
          audioMap.descriptor === descriptor &&
          audioMap.theme === theme
        ) {
          result = audioMap.id;
          break;
        }
      }
    }

    return result;
  }
}
