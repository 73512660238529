import videojs from 'video.js';
import { IComponentOptionsExtended } from '../../interfaces/component-options-extended.interface';
import { VideoService } from '../../services/video.service';

const Button = videojs.getComponent('Button');

export class JumpButtonComponent extends Button {
  videoService!: VideoService;

  constructor(player: videojs.Player, options: IComponentOptionsExtended) {
    super(player, options);
  }

  createEl(): HTMLButtonElement {
    const classNames = (<IComponentOptionsExtended>this.options_).classNames;
    const el = super.createEl(
      'button',
      {
        className: `vjs-control vjs-button ${classNames}`,
        id: 'video-jump-to-start-button',
      },
      {}
    );
    return el;
  }
}
