<button
  id="read-aloud-toggle"
  class="read-aloud-button"
  type="button"
  role="switch"
  [attr.aria-checked]="isChecked"
  [attr.aria-label]="'TOOL_BAR.READ_ALOUD_TOGGLE' | translate"
  (click)="onClick()"
  [attr.data-testid]="testId"
  htcAudioClick
  [audioType]="clickType"
  [descriptor]="clickDescriptor">
  <div
    class="toggle-switch ignore-pointer-events"
    [ngClass]="{ 'toggle-switch-on': isChecked }">
    <div class="toggle-switch-thumb">
      <div class="toggle-switch-thumb-shadow"></div>
      <div class="toggle-switch-thumb-circle"></div>
      <img
        class="toggle-switch-thumb-icon"
        [src]="path + currentIcon"
        [alt]="'TOOL_BAR.READ_ALOUD_TOGGLE' | translate"
        [@toggle-icon]="isChecked"
        (@toggle-icon.done)="switchIcon()" />
    </div>
  </div>
</button>
