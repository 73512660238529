import {
  AnswerChoice,
  PopulationInlineChoice,
  TargetedFeedback,
} from './../shared/interfaces/';

export class InlineChoiceEvaluator {
  static isCorrect(
    population: PopulationInlineChoice,
    userAnswers: string[]
  ): boolean {
    return (
      userAnswers.length === population.answers.length &&
      userAnswers.every((answerId, i) => population.answers[i] === answerId)
    );
  }

  static getWrongAnswers(
    population: PopulationInlineChoice,
    userAnswers: string[]
  ): string[][] {
    const wrong: string[][] = [];
    for (let i = 0; i < population.answers.length; i += 1) {
      if (userAnswers[i] !== population.answers[i]) {
        wrong[i] = [userAnswers[i]];
      } else {
        wrong[i] = [];
      }
    }
    return wrong;
  }

  static getSelectedWrongTargetedFeedback(
    selectedWrongAnswers: (number | string)[][],
    answerChoices: AnswerChoice[]
  ): TargetedFeedback[] {
    const result: TargetedFeedback[] = [];
    selectedWrongAnswers.forEach((option, index) => {
      if (option.length > 0) {
        if (answerChoices[index] && answerChoices[index].choice.texts) {
          answerChoices[index].choice.texts?.forEach(text => {
            if (option[0] && text.id === option[0].toString()) {
              if (text.targetedFeedback) {
                result.push(text.targetedFeedback);
              }
            }
          });
        } else if (answerChoices[index] && answerChoices[index].choice.images) {
          answerChoices[index].choice.images?.forEach(image => {
            if (image.id === option[0].toString()) {
              if (image.targetedFeedback) {
                result.push(image.targetedFeedback);
              }
            }
          });
        } else if (answerChoices[index] && answerChoices[index].choice.audios) {
          answerChoices[index].choice.audios?.forEach(audio => {
            if (audio.id === option[0].toString()) {
              if (audio.targetedFeedback) {
                result.push(audio.targetedFeedback);
              }
            }
          });
        } else if (answerChoices[index] && answerChoices[index].choice.videos) {
          answerChoices[index].choice.videos?.forEach(video => {
            if (video.id === option[0].toString()) {
              if (video.targetedFeedback) {
                result.push(video.targetedFeedback);
              }
            }
          });
        }
      }
    });

    return result;
  }

  static removeWrongAnswers(
    wrong: string[][],
    userAnswers: string[]
  ): string[] {
    return userAnswers.map((answerId, i) => {
      if (wrong.length > i) {
        return wrong[i].includes(answerId) ? '' : answerId;
      }
      return '';
    });
  }
}
