import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Messages } from 'src/app/data';
import { MessageService } from 'src/app/modules/message/services/message/message.service';
import { ReferrerService } from 'src/app/shared/services/referrer/referrer.service';

@Component({
  selector: 'htc-messages-debug-menu',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './messages-debug-menu.component.html',
  styleUrl: './messages-debug-menu.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagesDebugMenuComponent {
  readonly messages = Messages;
  readonly Object = Object;

  messageCode = 0;

  referrer = '';

  constructor(
    private messageService: MessageService,
    private referrerService: ReferrerService
  ) {
    this.referrer = this.referrerService.getReferrer();
  }

  showMessage(): void {
    this.messageService.showMessage(this.messageCode);
  }

  setReferrer(): void {
    this.referrerService.setReferrer(this.referrer);
  }
}
