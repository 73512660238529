import { PopulationDragAndDrop } from '../shared/interfaces';

export const dynamicHorizontalSequencing: {
  [key: string]: PopulationDragAndDrop;
} = {
  test1: {
    question: {
      text: 'Finish this sentence with a prepositional phrase.',
    },
    labels: [{ text: 'The squirrel tried to climb ____.' }],
    answerChoices: [
      {
        id: 'abc123',
        choice: {
          texts: [
            {
              text: 'apple',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "apple"' },
          secondIncorrect: { content: 'This is second feedback for "apple"' },
        },
      },
      {
        id: 'abc456',
        choice: {
          texts: [
            {
              text: 'up',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "up"' },
          secondIncorrect: { content: 'This is second feedback for "up"' },
        },
      },
      {
        id: 'abc789',
        choice: {
          texts: [
            {
              text: 'running',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "running"' },
          secondIncorrect: { content: 'This is second feedback for "running"' },
        },
      },
      {
        id: 'abc10',
        choice: {
          texts: [
            {
              text: 'the',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "the"' },
          secondIncorrect: { content: 'This is second feedback for "the"' },
        },
      },
      {
        id: 'abc11',
        choice: {
          texts: [
            {
              text: 'school',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "school"' },
          secondIncorrect: { content: 'This is second feedback for "school"' },
        },
      },
      {
        id: 'abc12',
        choice: {
          texts: [
            {
              text: 'tree',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "tree"' },
          secondIncorrect: { content: 'This is second feedback for "tree"' },
        },
      },
    ],
    answers: ['abc456', 'abc10', 'abc12'],
    associatedSuccessCriteria: ['0.0', '0.1'],
  },
};
