<div
  class="nav-stars"
  attr.aria-label="{{ 'HEADER.REWARDS' | translate }}: {{ numStars }} {{
    'HEADER.STARS' | translate
  }}">
  <img
    class="nav-stars-icon"
    src="/assets/button-icons/icon-star.svg"
    [alt]="'HEADER.STAR_ICON_ALT' | translate" />
  <htc-number-counter
    #navStarContainer
    class="nav-stars-text"
    [numberToDisplay]="numStars"
    [timeAnimDuration]="starCountDuration"
    [hasLeadingZeroes]="false"
    [displayedCountLength]="4"
    data-testid="nav-stars-component"></htc-number-counter>
</div>
