/**
 * These are the states the activity can be in
 * @enum {number}
 */

export enum ActivityStateEnum {
  INVALID = -1,
  START = 0,
  SELF_RATE = 1,
  VIDEO = 2,
  QUESTION = 3,
  RESULTS = 4,
  EXIT_SURVEY = 5,
}
