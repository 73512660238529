export enum KeyboardMapEnum {
  SPACE_KEY = 32,
  LEFT_ARROW_KEY = 37,
  UP_ARROW_KEY = 38,
  RIGHT_ARROW_KEY = 39,
  DOWN_ARROW_KEY = 40,
  C_KEY = 67,
  F_KEY = 70,
  M_KEY = 77,
  T_KEY = 84,
  ESC_KEY = 27,
  SPACE_KEY_STR = 'Space',
  LEFT_ARROW_KEY_STR = 'ArrowLeft',
  RIGHT_ARROW_KEY_STR = 'ArrowRight',
  UP_ARROW_KEY_STR = 'ArrowUp',
  DOWN_ARROW_KEY_STR = 'ArrowDown',
  C_KEY_STR = 'KeyC',
  F_KEY_STR = 'KeyF',
  M_KEY_STR = 'KeyM',
  T_KEY_STR = 'KeyT',
  ESC_KEY_STR = 'Escape',
}
