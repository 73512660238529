<div class="table-wrapper">
  <div
    class="table-container"
    data-testid="htc-table-container"
    #lexicalComposer
    [attr.contenteditable]="config.editable"
    role="presentation"></div>

  <!-- shows magnifying glass icon to indicate the chart can be opened in the Chart Modal -->
  @if (fitToParentElement) {
    <svg-icon
      class="some-class"
      [attr.aria-label]="'HINTS.TABLE_EXPAND_ICON' | translate"
      src="assets/button-icons/big.svg"></svg-icon>
  }
</div>
