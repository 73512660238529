import { Activity } from 'src/app/shared/interfaces';

export const readingPrep5: Activity = {
  id: '018ea00f-3d50-7475-be50-32746f35e97d',
  product: 'Reading',
  language: 'en',
  skillName: 'Prepositions',
  thumbnail: {
    id: 'recrLsLbsbxSPF0xx',
    url: '/helix/UX_Images/Lesson_Posters/Prepositions_G3V5_Poster.jpg',
    altText: [
      {
        language: 'en',
        altText:
          'Axis, Estrella, and CAT do the Nova Scouts salute in the cockpit.',
      },
      {
        language: 'es',
        altText:
          'Axis, Estrella y G.A.T.A. presentan el saludo de los Exploradores Nova en la cabina de la nave.',
      },
    ],
  },
  instructionsImage: {
    id: 'recqnzfDjMQK7N7kr',
    url: '/helix/UX_Images/Characters/CAT/CAT_Chibi_Happy_Eyes_Open_Mouth_Open.svg',
    altText: [
      {
        language: 'en',
        altText: 'CAT is smiling.',
      },
      {
        language: 'es',
        altText: 'G.A.T.A. está sonriendo.',
      },
    ],
  },
  characterLeanout: {
    id: 'rec5Rylxn2J2DNIHO',
    url: '/helix/TEI/Leanouts/CAT_Leanout_01.svg',
    altText: [
      {
        language: 'en',
        altText: 'CAT leans out from the side of the screen.',
      },
      {
        language: 'es',
        altText: 'G.A.T.A. se asoma por el lado de la pantalla.',
      },
    ],
  },
  description:
    'With the ship put back together, Axis needs to send a report to Scout Leader Estrella. C.A.T. is there to make sure he slows down and edits properly before sending it off.',
  domain: {
    text: 'Parts of Speech',
  },
  seriesName: "Axis's Big Break",
  episodeNumber: '5',
  episodeTitle: 'Discovery Is Ours',
  videos: [
    {
      id: 'recmqip36ZqrudVr7',
      url: '/helix/Reading/Grade_3/Prepositions/Episode_05/hls_streams/R_G3_Prepositions_EP05_Video.m3u8',
      posterURL: '/helix/UX_Images/Lesson_Posters/Prepositions_G3V5_Poster.jpg',
      captions: [
        {
          src: '/helix/Reading/Grade_3/Prepositions/Episode_05/R_G3_Prepositions_EP05_Video.vtt',
          srclang: 'en',
          mode: 'hidden',
          kind: 'captions',
        },
      ],
    },
  ],
  startScreenData: {
    learningTarget: [
      {
        id: '33744.E',
        learningTarget: {
          text: 'how to edit drafts for prepositions and prepositional phrases',
        },
        successCriteria: [
          {
            id: '33744.E.1',
            text: 'identify and correct prepositions and prepositional phrases in my own writing or the writing of others',
          },
          {
            id: '33744.E.2',
            text: 'use prepositional phrases to enhance my writing',
          },
        ],
      },
    ],
    background_leftImage: {
      id: 'recrLsLbsbxSPF0xx',
      url: '/helix/UX_Images/Lesson_Posters/Prepositions_G3V5_Poster.jpg',
      altText: [
        {
          language: 'en',
          altText:
            'Axis, Estrella, and CAT do the Nova Scouts salute in the cockpit.',
        },
        {
          language: 'es',
          altText:
            'Axis, Estrella y G.A.T.A. presentan el saludo de los Exploradores Nova en la cabina de la nave.',
        },
      ],
    },
    background_rightImage: {
      id: 'recxjC353VMav5ybu',
      url: '/helix/UX_Images/Start_Screen_Blobs/Nova-Scouts_space-sky.svg',
      altText: [
        {
          language: 'en',
          altText: 'Stars in space.',
        },
        {
          language: 'es',
          altText: 'Estrellas en el espacio.',
        },
      ],
    },
    foreground: {
      id: 'recOn2Luhc6XMnbRL',
      url: '/helix/UX_Images/Start_Screen_Characters/Cat5.svg',
      altText: [
        {
          language: 'en',
          altText: 'CAT is pouncing.',
        },
        {
          language: 'es',
          altText: 'G.A.T.A. está saltando.',
        },
      ],
    },
  },
  populations: [
    {
      id: '33744.E.1.1',
      templateID: 0,
      complexity: 'Application',
      hint: [
        {
          id: '33744.E.1.1.0',
          type: 'BasicSlide',
          data: {
            title: 'Prepositions link parts of a sentence together. \n',
          },
        },
        {
          id: '33744.E.1.1.1',
          type: 'BasicSlide',
          data: {
            title: 'Think about what is happening in the sentence.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Edit the sentence by correcting the preposition.\nArt classes begin toward two weeks.\n',
        },
        answerChoices: [
          {
            id: '33744.E.1.1_1',
            choice: {
              texts: [
                {
                  text: 'in\n',
                },
              ],
            },
          },
          {
            id: '33744.E.1.1_2',
            choice: {
              texts: [
                {
                  text: 'beside\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: { content: '_Beside_ gives a location.\n' },
            },
          },
          {
            id: '33744.E.1.1_3',
            choice: {
              texts: [
                {
                  text: 'behind\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '_Behind_ shows a location. We need a preposition that tells us about time.\n',
              },
            },
          },
        ],
        answers: ['33744.E.1.1_1'],
        associatedSuccessCriteria: ['33744.E.1'],
      },
      feedbackSecondIncorrect: { content: '_In_ is used to show time.\n' },
    },
    {
      id: '33744.E.1.2',
      templateID: 0,
      complexity: 'Application',
      hint: [
        {
          id: '33744.E.1.2.0',
          type: 'BasicSlide',
          data: {
            title: 'Prepositions link parts of a sentence together. \n',
          },
        },
        {
          id: '33744.E.1.2.1',
          type: 'BasicSlide',
          data: {
            title: 'Think about what is happening in the sentence.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Edit the sentence by correcting the preposition. \nThe squirrels raced than the tree branches.\n',
        },
        answerChoices: [
          {
            id: '33744.E.1.2_1',
            choice: {
              texts: [
                {
                  text: 'across\n',
                },
              ],
            },
          },
          {
            id: '33744.E.1.2_2',
            choice: {
              texts: [
                {
                  text: 'until\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '_Until_ is a preposition that shows time, but we need a preposition that shows direction.\n',
              },
            },
          },
          {
            id: '33744.E.1.2_3',
            choice: {
              texts: [
                {
                  text: 'during\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '_During_ is a preposition that shows time, but we need a preposition that shows direction.\n',
              },
            },
          },
        ],
        answers: ['33744.E.1.2_1'],
        associatedSuccessCriteria: ['33744.E.1'],
      },
      feedbackSecondIncorrect: {
        content: '_Across_ is used to show direction.\n',
      },
    },
    {
      id: '33744.E.1.3',
      templateID: 0,
      complexity: 'Application',
      hint: [
        {
          id: '33744.E.1.3.0',
          type: 'BasicSlide',
          data: {
            title: 'Prepositions link parts of a sentence together. \n',
          },
        },
        {
          id: '33744.E.1.3.1',
          type: 'BasicSlide',
          data: {
            title: 'Think about what is happening in the sentence.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Edit the sentence by correcting the preposition.\nThe dog dug a hole over the fence.\n',
        },
        answerChoices: [
          {
            id: '33744.E.1.3_1',
            choice: {
              texts: [
                {
                  text: 'under\n',
                },
              ],
            },
          },
          {
            id: '33744.E.1.3_2',
            choice: {
              texts: [
                {
                  text: 'with\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'This sounds like the fence helped him dig the hole.\n',
              },
            },
          },
          {
            id: '33744.E.1.3_3',
            choice: {
              texts: [
                {
                  text: 'after\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  "This refers to time, which doesn't work with this sentence.\n",
              },
            },
          },
        ],
        answers: ['33744.E.1.3_1'],
        associatedSuccessCriteria: ['33744.E.1'],
      },
      feedbackSecondIncorrect: {
        content: '_Under_ is the only place a dog could dig a hole.\n',
      },
    },
    {
      id: '33744.E.1.4',
      templateID: 0,
      complexity: 'Application',
      hint: [
        {
          id: '33744.E.1.4.0',
          type: 'BasicSlide',
          data: {
            title: 'Prepositions link parts of a sentence together. \n',
          },
        },
        {
          id: '33744.E.1.4.1',
          type: 'BasicSlide',
          data: {
            title: 'Think about what is happening in the sentence.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Edit the sentence by correcting the preposition.\nI want to join the baseball team with the fall.\n',
        },
        answerChoices: [
          {
            id: '33744.E.1.4_1',
            choice: {
              texts: [
                {
                  text: 'in\n',
                },
              ],
            },
          },
          {
            id: '33744.E.1.4_2',
            choice: {
              texts: [
                {
                  text: 'behind\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'This is a location, and for this question, you need a preposition that shows time.\n',
              },
            },
          },
          {
            id: '33744.E.1.4_3',
            choice: {
              texts: [
                {
                  text: 'below\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '_Below_ gives me a location. We need a preposition that tells us about a time of year.\n',
              },
            },
          },
        ],
        answers: ['33744.E.1.4_1'],
        associatedSuccessCriteria: ['33744.E.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'The correct answer is _in_ because we need to know when they want to join the baseball team.\n',
      },
    },
    {
      id: '33744.E.1.5',
      templateID: 0,
      complexity: 'Application',
      hint: [
        {
          id: '33744.E.1.5.0',
          type: 'BasicSlide',
          data: {
            title: 'Think about what is happening in the sentence.\n',
          },
        },
        {
          id: '33744.E.1.5.1',
          type: 'BasicSlide',
          data: {
            title:
              'Figure out if you need a preposition that gives a time, location or direction.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Edit the sentence with the correct prepositional phrase.\nWe spent our vacation of the beach.\n',
        },
        answerChoices: [
          {
            id: '33744.E.1.5_1',
            choice: {
              texts: [
                {
                  text: 'at the beach\n',
                },
              ],
            },
          },
          {
            id: '33744.E.1.5_2',
            choice: {
              texts: [
                {
                  text: 'beside the beach\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '_Toward_ refers to direction or movement, but we\'re looking for a preposition to use with "beach," which is a location.\n',
              },
            },
          },
          {
            id: '33744.E.1.5_3',
            choice: {
              texts: [
                {
                  text: 'after the beach\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'The beach is a location, but _after_ refers to a time.\n',
              },
            },
          },
        ],
        answers: ['33744.E.1.5_1'],
        associatedSuccessCriteria: ['33744.E.1'],
      },
      feedbackSecondIncorrect: {
        content: '_At the beach_ gives a specific location.\n',
      },
    },
    {
      id: '33744.E.1.6',
      templateID: 0,
      complexity: 'Application',
      hint: [
        {
          id: '33744.E.1.6.0',
          type: 'BasicSlide',
          data: {
            title: 'Think about what is happening in the sentence.\n',
          },
        },
        {
          id: '33744.E.1.6.1',
          type: 'BasicSlide',
          data: {
            title:
              'Figure out if you need a preposition that gives a time, location or direction.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Edit the sentence with the correct prepositional phrase.\nOur class walked quietly during the library.\n',
        },
        answerChoices: [
          {
            id: '33744.E.1.6_1',
            choice: {
              texts: [
                {
                  text: 'into the library\n',
                },
              ],
            },
          },
          {
            id: '33744.E.1.6_2',
            choice: {
              texts: [
                {
                  text: 'against the library\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '_Against_ shows a location, but the class is in motion. We need a preposition for direction.\n',
              },
            },
          },
          {
            id: '33744.E.1.6_3',
            choice: {
              texts: [
                {
                  text: 'on the hall\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'I think we need a better preposition for this to make sense.\n',
              },
            },
          },
        ],
        answers: ['33744.E.1.6_1'],
        associatedSuccessCriteria: ['33744.E.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'The correct answer is _into the library_ because you need to be quiet when you enter a library.\n',
      },
    },
    {
      id: '33744.E.1.7',
      templateID: 0,
      complexity: 'Application',
      hint: [
        {
          id: '33744.E.1.7.0',
          type: 'BasicSlide',
          data: {
            title: 'Think about what is happening in the sentence.\n',
          },
        },
        {
          id: '33744.E.1.7.1',
          type: 'BasicSlide',
          data: {
            title:
              'Figure out if you need a preposition that gives a time, location or direction.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Edit the sentence with the correct prepositional phrase.\nI saw a spaceship fly under the sky.\n',
        },
        answerChoices: [
          {
            id: '33744.E.1.7_1',
            choice: {
              texts: [
                {
                  text: 'across the sky\n',
                },
              ],
            },
          },
          {
            id: '33744.E.1.7_2',
            choice: {
              texts: [
                {
                  text: 'between the sky\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '_Between_ gives us a location, but the spaceship is in motion. We need a preposition for direction.\n',
              },
            },
          },
          {
            id: '33744.E.1.7_3',
            choice: {
              texts: [
                {
                  text: 'with the sky\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: "_With_ doesn't show us any motion.\n",
              },
            },
          },
        ],
        answers: ['33744.E.1.7_1'],
        associatedSuccessCriteria: ['33744.E.1'],
      },
      feedbackSecondIncorrect: {
        content:
          '_Across the sky_ has a preposition that shows motion or direction.\n',
      },
    },
    {
      id: '33744.E.1.8',
      templateID: 0,
      complexity: 'Application',
      hint: [
        {
          id: '33744.E.1.8.0',
          type: 'BasicSlide',
          data: {
            title: 'Think about what is happening in the sentence.\n',
          },
        },
        {
          id: '33744.E.1.8.1',
          type: 'BasicSlide',
          data: {
            title:
              'Figure out if you need a preposition that gives a time, location or direction.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Edit the sentence with the correct prepositional phrase.\nMy frisbee got stuck for the roof.\n',
        },
        answerChoices: [
          {
            id: '33744.E.1.8_1',
            choice: {
              texts: [
                {
                  text: 'on the roof\n',
                },
              ],
            },
          },
          {
            id: '33744.E.1.8_2',
            choice: {
              texts: [
                {
                  text: 'since the roof\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '_Since_ is a preposition for time. We need a preposition for location.\n',
              },
            },
          },
          {
            id: '33744.E.1.8_3',
            choice: {
              texts: [
                {
                  text: 'until the roof\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: '_Until_ shows time, and we need a location.\n',
              },
            },
          },
        ],
        answers: ['33744.E.1.8_1'],
        associatedSuccessCriteria: ['33744.E.1'],
      },
      feedbackSecondIncorrect: {
        content: '_On_ gives a specific location.\n',
      },
    },
    {
      id: '33744.E.2.9',
      templateID: 5,
      complexity: 'Analysis',
      hint: [
        {
          id: '33744.E.2.9.0',
          type: 'BasicSlide',
          data: {
            title:
              "Find all of the prepositions first. Then narrow down which ones don't quite make sense.\n",
          },
        },
        {
          id: '33744.E.2.9.1',
          type: 'BasicSlide',
          data: {
            title:
              "Check and see if the prepositional phrases match what's happening in the rest of the sentence.\n",
          },
        },
      ],
      populationData: {
        question: {
          text: 'Identify the incorrect prepositional phrases in the passage.\nChoose 3.\n',
        },
        answerChoices: [
          {
            id: '33744.E.2.9_1',
            choice: {
              texts: [
                {
                  text: 'for the zoo\n',
                },
              ],
            },
          },
          {
            id: '33744.E.2.9_2',
            choice: {
              texts: [
                {
                  text: 'after the water\n',
                },
              ],
            },
          },
          {
            id: '33744.E.2.9_3',
            choice: {
              texts: [
                {
                  text: 'in the people\n',
                },
              ],
            },
          },
          {
            id: '33744.E.2.9_4',
            choice: {
              texts: [
                {
                  text: 'on a field trip\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: { content: 'There is nothing wrong here!\n' },
            },
          },
          {
            id: '33744.E.2.9_5',
            choice: {
              texts: [
                {
                  text: 'to see the penguins\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: '_To_ is the best preposition for this phrase.\n',
              },
            },
          },
          {
            id: '33744.E.2.9_6',
            choice: {
              texts: [
                {
                  text: 'get to see\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: "This isn't a prepositional phrase.\n",
              },
            },
          },
        ],
        answers: ['33744.E.2.9_1', '33744.E.2.9_2', '33744.E.2.9_3'],
        associatedSuccessCriteria: ['33744.E.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'These prepositional phrases do not have the correct prepositions.\n',
      },
    },
    {
      id: '33744.E.2.10',
      templateID: 5,
      complexity: 'Analysis',
      hint: [
        {
          id: '33744.E.2.10.0',
          type: 'BasicSlide',
          data: {
            title:
              "Find all of the prepositions first. Then narrow down which ones don't quite make sense.\n",
          },
        },
        {
          id: '33744.E.2.10.1',
          type: 'BasicSlide',
          data: {
            title:
              "Check and see if the prepositional phrases match what's happening in the rest of the sentence.\n",
          },
        },
      ],
      populationData: {
        question: {
          text: 'Identify the incorrect prepositional phrases in the passage.\nChoose 3.\n',
        },
        answerChoices: [
          {
            id: '33744.E.2.10_1',
            choice: {
              texts: [
                {
                  text: 'on 30 minutes\n',
                },
              ],
            },
          },
          {
            id: '33744.E.2.10_2',
            choice: {
              texts: [
                {
                  text: 'about a bridge\n',
                },
              ],
            },
          },
          {
            id: '33744.E.2.10_3',
            choice: {
              texts: [
                {
                  text: 'for the bleachers\n',
                },
              ],
            },
          },
          {
            id: '33744.E.2.10_4',
            choice: {
              texts: [
                {
                  text: 'from the parking lot\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'This shows where they walked from, which is correct.\n',
              },
            },
          },
          {
            id: '33744.E.2.10_5',
            choice: {
              texts: [
                {
                  text: 'to reach the field\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  '_To_ is usually a preposition, but not in this phrase.\n',
              },
            },
          },
          {
            id: '33744.E.2.10_6',
            choice: {
              texts: [
                {
                  text: 'ready to run\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content: '_Ready_ is an adjective, not a preposition.\n',
              },
            },
          },
        ],
        answers: ['33744.E.2.10_1', '33744.E.2.10_2', '33744.E.2.10_3'],
        associatedSuccessCriteria: ['33744.E.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'These prepositional phrases do not have the correct prepositions.\n',
      },
    },
  ],
  activityManifest: [
    {
      type: 'START_SCREEN',
    },
    {
      type: 'ENTRY_SURVEY',
    },
    {
      type: 'VIDEO',
      id: 'recmqip36ZqrudVr7',
    },
    {
      type: 'QUESTION',
      id: '33744.E.1.1',
    },
    {
      type: 'QUESTION',
      id: '33744.E.1.2',
    },
    {
      type: 'QUESTION',
      id: '33744.E.1.3',
    },
    {
      type: 'QUESTION',
      id: '33744.E.1.4',
    },
    {
      type: 'QUESTION',
      id: '33744.E.1.5',
    },
    {
      type: 'QUESTION',
      id: '33744.E.1.6',
    },
    {
      type: 'QUESTION',
      id: '33744.E.1.7',
    },
    {
      type: 'QUESTION',
      id: '33744.E.1.8',
    },
    {
      type: 'QUESTION',
      id: '33744.E.2.9',
    },
    {
      type: 'QUESTION',
      id: '33744.E.2.10',
    },
    {
      type: 'EXIT_SURVEY',
    },
    {
      type: 'RESULTS_SCREEN',
    },
  ],
  version: '1562962166',
};
