import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReadAloudComponent } from './components/read-aloud/read-aloud.component';
import { ReadAloudDirective } from 'src/app/modules/read-aloud/directives/read-aloud-directive.directive';
import { ReadAloudControlsComponent } from './components/read-aloud-controls/read-aloud-controls.component';
import { LottiePlayerModule } from '../lottie-player/lottie-player.module';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  declarations: [
    ReadAloudComponent,
    ReadAloudDirective,
    ReadAloudControlsComponent,
  ],
  exports: [ReadAloudComponent, ReadAloudDirective],
  imports: [
    CommonModule,
    TranslateModule,
    LottiePlayerModule,
    AngularSvgIconModule,
  ],
})
export class ReadAloudModule {}
