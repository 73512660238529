import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { NumberCounterComponent } from './components/number-counter/number-counter.component';
import { NavStarsComponent } from './components/nav-stars/nav-stars.component';
import { HeaderComponent } from './components/header/header.component';
import { ProfilePopupComponent } from './components/profile-popup/profile-popup.component';
import { ProgressBarModule } from '../progress-bar/progress-bar.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { ContactSupportComponent } from './components/contact-support/contact-support.component';
import { InitialPipe } from 'src/app/shared/pipes/initial.pipe';
import { SettingsComponent } from './components/settings/settings.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ProgressBarModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    TranslateModule,
  ],
  exports: [NumberCounterComponent, NavStarsComponent, HeaderComponent],
  declarations: [
    NumberCounterComponent,
    NavStarsComponent,
    HeaderComponent,
    ProfilePopupComponent,
    ContactSupportComponent,
    InitialPipe,
    SettingsComponent,
  ],
  providers: [InitialPipe],
})
export class HeaderModule {}
