import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ITranscriptLine } from '../../interfaces';
import { TranscriptService } from '../../services/transcript.service';
import { VideoService } from '../../services/video.service';
import { SpeechRuleEngineService } from 'src/app/modules/math-text/services/speech-rule-engine.service';

@Component({
  selector: 'htc-auto-scroll-transcript',
  templateUrl: './auto-scroll-transcript.component.html',
  styleUrls: ['./auto-scroll-transcript.component.scss'],
})
export class AutoScrollTranscriptComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('transcriptText', { static: true }) transcriptText?: ElementRef;
  transcriptPrompt = 'Transcript';
  showTranscript = false;
  transcript: ITranscriptLine[] = [{ startTimeMS: 0, endTimeMS: 0, texts: [] }];
  focusedTranscriptIndex = 0;
  maxTranscriptIndex = 0;
  selectedTranscriptIndex = 0;
  isVideoPlaying = false;
  autoScrollEnable = true;
  autoScrollEnableTimeout!: ReturnType<typeof setTimeout>;
  autoScrollDelayMs = 3000;
  sub = new Subscription();

  constructor(
    private transcriptService: TranscriptService,
    private videoService: VideoService,
    public sres: SpeechRuleEngineService
  ) {}

  ngOnInit(): void {
    this.sub = new Subscription();
    this.sub.add(
      this.transcriptService.activeTranscript$
        .asObservable()
        .subscribe((transcript: ITranscriptLine[] | undefined) => {
          if (transcript !== undefined) {
            this.transcript = transcript;
          }
        })
    );

    this.sub.add(
      this.transcriptService.focusedTranscriptIndex$
        .asObservable()
        .subscribe((index: number) => {
          this.handleFocusedTranscript(index);
        })
    );

    this.sub.add(
      this.transcriptService.maxTranscriptIndex$
        .asObservable()
        .subscribe((index: number) => {
          this.maxTranscriptIndex = index;
        })
    );

    this.sub.add(
      this.videoService.isPlaying$.subscribe(isPlaying => {
        this.isVideoPlaying = isPlaying;

        this.autoScrollEnable = this.isVideoPlaying;
        if (this.isVideoPlaying) {
          this.autoScroll();
        } else {
          this.clearAutoScrollTimeout();
        }
      })
    );

    if (this.videoService.player) {
      this.transcriptPrompt = this.videoService.player.localize('Transcript');
    }
  }

  ngAfterViewInit(): void {
    this.resetTranscriptLineTabIndexes();
    this.addKeyListener();
  }

  addKeyListener(): void {
    if (this.transcriptText) {
      this.transcriptText.nativeElement.addEventListener(
        'keydown',
        this.transcriptKeyDownEvent.bind(this)
      );
    }
  }

  transcriptKeyDownEvent(event: KeyboardEvent): void {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      this.newSelectedTranscriptLine(this.selectedTranscriptIndex + 1);
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      this.newSelectedTranscriptLine(this.selectedTranscriptIndex - 1);
    } else if (event.key === 'Enter') {
      if (this.maxTranscriptIndex >= this.selectedTranscriptIndex) {
        this.onTranLine(this.transcript[this.selectedTranscriptIndex]);
      }
    }
  }

  newSelectedTranscriptLine(newIndex: number): void {
    if (this.transcriptText) {
      this.setTranscriptLineTabIndex(this.selectedTranscriptIndex, -1);

      const max = this.transcriptText.nativeElement.children.length - 1;
      const cappedIndex = Math.max(Math.min(newIndex, max), 0);
      this.selectedTranscriptIndex = cappedIndex;
      this.setTranscriptLineTabIndex(this.selectedTranscriptIndex, 0, true);
    }
  }

  resetTranscriptLineTabIndexes(): void {
    if (this.transcriptText) {
      this.selectedTranscriptIndex = 0;
      const children = this.transcriptText.nativeElement.children;
      this.setTranscriptLineTabIndex(0, 0);

      const childLength = children.length;
      for (let i = 1; i < childLength; i++) {
        this.setTranscriptLineTabIndex(i, -1);
      }
    }
  }

  setTranscriptLineTabIndex(
    elIndex: number,
    tabIndex: number,
    focus?: boolean
  ): void {
    if (this.transcriptText) {
      const children = this.transcriptText.nativeElement.children;

      if (children.length > elIndex && elIndex >= 0) {
        children[elIndex].tabIndex = tabIndex;
        if (focus) {
          children[elIndex].focus();
        }
      }
    }
  }

  /**
   * Once transcript line is pressed this method will be called
   * which should set the video time to that transcript line start ms
   * @param tranLine ITranscriptLine that was pressed
   */
  onTranLine(tranLine: ITranscriptLine): void {
    this.transcriptService.onTranLine(tranLine);
  }

  handleFocusedTranscript(index: number): void {
    // make sure to auto scroll transcript line into view
    if (this.transcriptText) {
      // constrain to bounds of transcript indexes
      this.focusedTranscriptIndex = Math.max(
        0,
        Math.min(index, this.transcript.length - 1)
      );

      this.autoScroll();
    }
  }

  autoScroll(): boolean {
    if (this.autoScrollEnable) {
      const target = this.getFocusTarget();
      if (target && target.parentElement) {
        target.parentElement.scrollTop = this.getAutoScrollTop(target);
        return true;
      }
    }

    return false;
  }

  getFocusTarget(): HTMLElement | undefined {
    if (
      this.transcriptText &&
      this.transcriptText.nativeElement.children.length >
        this.focusedTranscriptIndex
    ) {
      return this.transcriptText.nativeElement.children[
        this.focusedTranscriptIndex
      ];
    }

    return undefined;
  }

  getAutoScrollTop(target: HTMLElement): number {
    if (target.parentElement) {
      return target.offsetTop - target.parentElement.offsetTop;
    }

    return 0;
  }

  onScroll(): void {
    if (this.autoScrollEnable) {
      this.autoScrollEnable = false;

      this.clearAutoScrollTimeout();
      this.autoScrollEnableTimeout = setTimeout(() => {
        this.engageAutoScroll();
      }, this.autoScrollDelayMs);
    }
  }

  clearAutoScrollTimeout(): void {
    if (this.autoScrollEnableTimeout) {
      clearTimeout(this.autoScrollEnableTimeout);
    }
  }

  engageAutoScroll(): void {
    this.autoScrollEnable = true;
    this.autoScroll();
  }

  removeKeyListener(): void {
    if (this.transcriptText) {
      this.transcriptText.nativeElement.removeEventListener(
        'keydown',
        this.transcriptKeyDownEvent.bind(this)
      );
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();

    if (this.autoScrollEnableTimeout) {
      clearTimeout(this.autoScrollEnableTimeout);
    }

    this.removeKeyListener();
  }
}
