import { MessageCodes } from 'src/app/shared/enums/message-codes';

export const speechSynthesisErrorMessages: Record<string, MessageCodes> = {
  'audio-busy': MessageCodes.SPEECH_SYNTHESIS_AUDIO_BUSY,
  'audio-hardware': MessageCodes.SPEECH_SYNTHESIS_AUDIO_HARDWARE,
  network: MessageCodes.SPEECH_SYNTHESIS_NETWORK,
  'synthesis-unavailable': MessageCodes.SPEECH_SYNTHESIS_SYNTHESIS_UNAVAILABLE,
  'synthesis-failed': MessageCodes.SPEECH_SYNTHESIS_SYNTHESIS_FAILED,
  'language-unavailable': MessageCodes.SPEECH_SYNTHESIS_LANGUAGE_UNAVAILABLE,
  'voice-unavailable': MessageCodes.SPEECH_SYNTHESIS_VOICE_UNAVAILABLE,
  'text-too-long': MessageCodes.SPEECH_SYNTHESIS_TEXT_TOO_LONG,
  'invalid-argument': MessageCodes.SPEECH_SYNTHESIS_INVALID_ARGUMENT,
  'not-allowed': MessageCodes.SPEECH_SYNTHESIS_NOT_ALLOWED,
  'tab-muted': MessageCodes.SPEECH_SYNTHESIS_TAB_MUTED,
};
