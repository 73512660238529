import {
  Directive,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AudioService } from 'src/app/modules/audio/services/audio/audio.service';
import { UserService } from '../../services';
import { AudioType } from '../../enums/audio-type';
import { Themes } from '../../enums/themes';
import { SettingsService } from '../../services/settings/settings.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[htcAudioClick]',
})
export class AudioClickDirective implements OnInit, OnDestroy {
  type!: AudioType;
  desc!: string;
  theme!: Themes;
  private idSound = '';
  private retryCount = 0;
  sub = new Subscription();
  muteSfx!: boolean;

  @Input()
  set audioType(val: AudioType) {
    if (val) {
      this.type = val;
    }
  }
  @Input()
  set descriptor(val: string) {
    if (val) {
      this.desc = val;
    }
  }

  constructor(
    private audioService: AudioService,
    private userService: UserService,
    private settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    this.userService.theme.subscribe(str => {
      const theme = str as Themes;
      if (theme !== this.theme) {
        if (this.idSound !== '') {
          this.audioService.release(this.idSound);
          this.idSound = '';
        }
        this.theme = theme;
        this.idSound = this.audioService.initSFXAudio(
          this.type,
          this.desc,
          this.theme
        ).id;
      }
    });

    this.sub.add(
      this.settingsService.settings.subscribe(settings => {
        this.muteSfx = settings.muteSfx ?? false;
      })
    );
  }

  ngOnDestroy(): void {
    this.audioService.release(this.idSound);
    this.sub.unsubscribe();
  }

  @HostListener('click')
  onClick(): void {
    const { state } = Howler.ctx;
    if (state === 'suspended' && this.retryCount === 0) {
      this.retryCount += 1;
      // on initial load of the page Howler has state "suspended".
      // to change that state, a user needs to make an interaction with the web page
      // (click on any element or use any keypress) before we can
      // use Howler. So if user clicks on an element with SFX, an audio won't be played
      // we need to retrigger click event if a user clicks on the element with SFX
      // to play the sound
      Howler.ctx.resume().then(() => {
        console.log('Retrying audio click event');
        this.onClick();
      });
    } else {
      this.retryCount = 0;
    }
    if (
      this.idSound != undefined &&
      this.audioService.isSoundLoaded(this.idSound) &&
      !this.muteSfx
    ) {
      this.audioService.playSound({ id: this.idSound }, true);
    } else {
      console.log('Click audio is not loaded: ' + this.type + ', ' + this.desc);
    }
  }
}
