import { BehaviorSubject } from 'rxjs';
import videojs from 'video.js';

export class UserActionsDefaultConfig implements videojs.UserActions {
  event$ = new BehaviorSubject<number | undefined>(undefined);
  doubleClick = false;
  hotkeys = (event: videojs.EventTarget.Event): void => {
    this.event$.next(event.which);
  };
}
