<a
  class="skip-to-content"
  href="javascript:void(0)"
  (click)="onSkipToContent()"
  id="skip-to-content"
  data-testid="htc-skip-to-content"
  >{{ 'SKIP_TO_CONTENT.TEXT' | translate }}</a
>
<header
  class="header flex-container flex-full-row flex-align-center flex-justify-space-between">
  <htc-button-with-icon
    class="header-left flex-align-center"
    classes="btn btn-with-left-icon btn-exit"
    *ngIf="showExitButton"
    (btnClicked)="onClick()"
    [imagePath]="exitButtonIcon"
    [btnText]="exitButtonText"
    data-testid="htc-exit-btn"
    [clickType]="clickType"
    [clickDescriptor]="clickDescriptor">
  </htc-button-with-icon>

  <div
    class="header-middle flex-container flex-col flex-align-center flex-auto">
    <h1
      *ngIf="activityService.seriesName | async as seriesName"
      class="header-middle-title block-center text-overflow-ellipsis"
      data-testid="htc-header-series-name"
      [title]="seriesName">
      {{ seriesName }}
    </h1>
    <h1
      *ngIf="(activityService.seriesName | async) === ''"
      class="header-middle-title block-center text-overflow-ellipsis"
      data-testid="htc-header-episode-title"
      [title]="activityService.episodeTitle | async">
      {{ activityService.episodeTitle | async }}
    </h1>
    <div class="header-middle-subtitle block-center">
      <h2 class="block-center" data-testid="htc-header-skill-name">
        {{ activityService.skillName$ | async }}
      </h2>
    </div>
  </div>

  <div
    class="header-right flex-container flex-full-row flex-align-center flex-justify-end">
    <htc-nav-stars
      class="header-right-rewards"
      *ngIf="showRewards"
      [numStars]="(userService.stars$ | async) ?? 0"
      [starCountDuration]="1000"
      data-testid="htc-header-rewards">
    </htc-nav-stars>

    <div class="header-right-avatar">
      <button
        id="open-profile-menu"
        class="nav-avatar focus-outline hover-special"
        type="button"
        [attr.aria-label]="'HEADER.PROFILE_SETTINGS_MENU' | translate"
        aria-controls="profilePopup"
        aria-haspopup="true"
        [attr.aria-expanded]="userMenuVisible"
        (click)="toggleUserMenu()"
        [disabled]="isEarlyAccess || !isAuthenticationEnabled"
        htcAudioClick
        [audioType]="clickType"
        [descriptor]="clickDescriptor"
        data-testid="htc-header-open-profile-button">
        <htc-avatar
          class="avatar"
          *ngIf="userService.avatar$ | async as avatar; else hamburger"
          [avatar]="avatar"
          [flair]="'circle'"
          maskStyle="masked"></htc-avatar>
        <ng-template #hamburger>
          <div class="nav-hamburger"></div>
        </ng-template>
      </button>
      <htc-profile-popup
        *ngIf="userMenuVisible"
        id="profilePopup"
        role="menu"
        [attr.aria-label]="'HEADER.PROFILE_SETTINGS' | translate"
        [name]="
          (userService.firstName$ | async) +
          ' ' +
          (userService.lastName$ | async | initial)
        "
        [avatar]="userService.avatar$ | async"
        [showProfile]="userMenuVisible"
        (showProfileChange)="toggleUserMenu()"
        [(supportMenu)]="showSupportMenu"
        [(settingsMenu)]="showSettingsMenu">
      </htc-profile-popup>
    </div>
  </div>

  <htc-contact-support [(showPopup)]="showSupportMenu"></htc-contact-support>
  <htc-settings [(showPopup)]="showSettingsMenu"></htc-settings>
</header>
