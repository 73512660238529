import { ToolItem } from '../classes/tool-item';
import { HintPopupComponent } from '../components/hint-popup/hint-popup.component';
import { RubricPopupComponent } from '../components/rubric-popup/rubric-popup.component';
import { VideoPopupComponent } from '../components/video-popup/video-popup.component';
import { tools } from './tools';

export const toolItems: ToolItem[] = [
  new ToolItem(VideoPopupComponent, tools.VIDEO_POPUP),
  new ToolItem(HintPopupComponent, tools.ON_DEMAND_HINT),
  new ToolItem(RubricPopupComponent, tools.RUBRIC_POPUP),
];
