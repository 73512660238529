import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { footerData } from 'src/app/data/footer-data';
import { AuthSessionService } from '../../auth/services/auth-session/auth-session.service';
import { FeatureFlagService } from '../../feature-flags/services/feature-flag/feature-flag.service';
import { UseAnimationService } from '../../use-animation/use-animation.service';
import { FeatureFlags } from 'src/app/shared/enums/feature-flags';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  show = false;
  footerData = footerData.START_NEXT_WITH_TOOLS;
  footerButtonClicked = new EventEmitter<Event>();
  isDebounced = false;
  debounceTimeout!: NodeJS.Timeout;
  readonly debounceDelay = 300;
  private footerButtonEnabled = new BehaviorSubject<boolean>(false);

  constructor(
    private authSessionService: AuthSessionService,
    private featureFlagService: FeatureFlagService,
    private useAnimation: UseAnimationService
  ) {}

  debounceClickDelay(event: Event): void {
    if (!this.isDebounced) {
      this.isDebounced = true;
      this.footerButtonClicked.emit(event);

      this.debounceTimeout = setTimeout(() => {
        this.isDebounced = false;
      }, this.debounceDelay);
    }
  }

  debounceClickPageAnimating(event: Event, isPageAnimating: boolean): void {
    this.isDebounced = isPageAnimating;
    if (!this.isDebounced) {
      this.footerButtonClicked.emit(event);
    }
  }

  cleanup(): void {
    this.isDebounced = false;
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
  }

  onFooterButtonClicked(event: Event, isPageAnimating?: boolean): void {
    if (
      isPageAnimating !== undefined &&
      this.isEnableOverride() &&
      this.useAnimation.useAnimation$.getValue()
    ) {
      this.debounceClickPageAnimating(event, isPageAnimating);
    } else {
      this.debounceClickDelay(event);
    }
  }

  getFooterButtonEnabled(): BehaviorSubject<boolean> {
    if (this.isEnableOverride()) {
      return new BehaviorSubject<boolean>(true);
    }
    return this.footerButtonEnabled;
  }

  enableFooterButton(): void {
    this.footerButtonEnabled.next(true);
  }

  disableFooterButton(): void {
    this.footerButtonEnabled.next(false);
  }

  private isEnableOverride(): boolean {
    return (
      this.featureFlagService.isFlagEnabled(FeatureFlags.AUTO_ENABLE_NEXT) ||
      this.authSessionService.isTeacher
    );
  }
}
