import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MathTextFields } from '../../interfaces/math-text-fields';
import {
  KATEX_END_DELIM,
  KATEX_START_DELIM,
  parseMathTextFields,
  trimNewLines,
} from '../../utils/math-text-utils';
import { MathTextComponent } from '../math-text/math-text.component';
import { MarkdownModule } from 'ngx-markdown';

@Component({
  selector: 'htc-math-text-wrapper',
  standalone: true,
  imports: [MathTextComponent, MarkdownModule],
  templateUrl: './math-text-wrapper.component.html',
})
export class MathTextWrapperComponent implements OnChanges {
  @Input() textInput = '';
  fields: MathTextFields[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.textInput) {
      const newTextInput = changes.textInput.currentValue as string;
      const trimmedInput = trimNewLines(newTextInput);

      this.fields = parseMathTextFields(
        trimmedInput,
        KATEX_START_DELIM,
        KATEX_END_DELIM
      );
    }
  }
}
