<div class="header">
  <h1 *ngIf="activityState">{{ activityId }}</h1>
  <h1 *ngIf="!activityState">Enter an activity ID</h1>
</div>
<div class="container">
  <div *ngIf="!activity">
    <form onsubmit="findActivity()">
      <label for="activityId">Activity ID</label>
      <input
        type="text"
        name="activityId"
        id="activityId"
        [(ngModel)]="activityId" />
      <button class="button-primary" (click)="findActivity()">Find</button>
    </form>
  </div>
  <div *ngIf="activity">
    <div *ngIf="activityState">
      <div class="flex-container flex-col">
        <label for="currStepSelect">
          Current Step
          <select
            name="currStepSelect"
            id="currStepSelect"
            [(ngModel)]="activityState.currentStep"
            (change)="currStepChanged()">
            <option *ngFor="let step of steps; let i = index" [value]="i">
              {{ step }}
            </option>
          </select>
        </label>

        <label for="videoProgression">
          Video Progression
          <input
            type="text"
            name="videoProgression"
            id="videoProgression"
            [(ngModel)]="videoProgression" />
        </label>

        <label for="videoTotalWatchTime">
          Video Total Watch Time
          <input
            type="text"
            name="videoTotalWatchTime"
            id="videoTotalWatchTime"
            [(ngModel)]="videoTotalWatchedTime" />
        </label>

        <label for="videoWatchedFully">
          Video Watched Fully?
          <input
            type="checkbox"
            name="videoWachedFully"
            id="videoWatchedFully"
            [(ngModel)]="videoWatchedFully" />
        </label>

        <label for="elapsedTime">
          Elapsed Time
          <input
            type="text"
            name="elapsedTime"
            id="elapsedTime"
            [(ngModel)]="activityState.elapsedTime" />
        </label>
      </div>

      <div>
        <h2>Populations</h2>
        <div
          *ngFor="
            let pop of activity.populations.slice(0, maxPopsAvailable);
            let i = index
          ">
          <h2>{{ i + 1 }}</h2>
          <h4>
            {{ getPopQuestion(pop) }}
          </h4>
          <div class="answers">
            <div
              *ngIf="
                pop.templateID === templateIdEnum.MULTI_SELECT ||
                pop.templateID === templateIdEnum.MULTI_SELECT_IMAGE
              "
              class="answers">
              <label *ngFor="let answer of getMultiChoiceAnswerChoices(pop)">
                <input
                  type="checkbox"
                  [name]="pop.id"
                  [id]="pop.id"
                  (change)="answerChanged(i, [answer.id ?? ''], $event)" />
                <p *ngIf="pop.templateID === templateIdEnum.MULTI_SELECT">
                  {{ answer.choice.texts?.at(0)?.text }}
                </p>
                <img
                  *ngIf="pop.templateID === templateIdEnum.MULTI_SELECT_IMAGE"
                  [src]="answer.choice.images?.at(0)?.url ?? '' | cdnSrcBaseUrl"
                  alt="" />
              </label>
            </div>
            <div
              *ngIf="
                pop.templateID === templateIdEnum.MULTIPLE_CHOICE ||
                pop.templateID === templateIdEnum.MULTIPLE_CHOICE_IMAGE ||
                pop.templateID === templateIdEnum.TRUE_FALSE_CHOICE
              "
              class="answers">
              <label *ngFor="let answer of getMultiChoiceAnswerChoices(pop)">
                <input
                  type="radio"
                  [name]="pop.id"
                  [id]="pop.id"
                  (change)="answerChanged(i, [answer.id ?? ''], $event)" />
                <p
                  *ngIf="
                    pop.templateID === templateIdEnum.MULTIPLE_CHOICE ||
                    pop.templateID === templateIdEnum.TRUE_FALSE_CHOICE
                  ">
                  {{ answer.choice.texts?.at(0)?.text }}
                </p>
                <img
                  *ngIf="
                    pop.templateID === templateIdEnum.MULTIPLE_CHOICE_IMAGE
                  "
                  [src]="answer.choice.images?.at(0)?.url ?? '' | cdnSrcBaseUrl"
                  alt="" />
              </label>
            </div>
            <div
              *ngIf="pop.templateID === templateIdEnum.INLINE_CHOICE"
              class="answers">
              <div
                *ngFor="
                  let answer of getInlineAnswerChoices(pop);
                  let f = index
                ">
                <div *ngIf="answer.choice.texts as texts">
                  <label>
                    <select
                      [name]="pop.id + answer.id"
                      [id]="pop.id + answer.id"
                      (change)="textAnswerChanged($event, i, f)">
                      <option disabled selected></option>
                      <option
                        *ngFor="let choiceText of texts"
                        [value]="choiceText.id"
                        [id]="pop.id + choiceText.id">
                        {{ choiceText.text }}
                      </option>
                    </select>
                    <p></p>
                  </label>
                </div>
              </div>
            </div>
            <div
              *ngIf="pop.templateID === templateIdEnum.TEXT_ENTRY"
              class="answers">
              <div
                *ngFor="let answer of getTextEntryAnswer(pop); let f = index">
                <div *ngIf="answer as texts">
                  <label>
                    <input
                      type="text"
                      value=""
                      (input)="textAnswerChanged($event, i, f)" />
                    <p>Entry {{ f }}</p>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="thin-separator"></div>
          <div>
            <h4>Attempts:</h4>
            <p
              *ngFor="
                let response of activityState.populationResponses[i].responses;
                let j = index
              ">
              Attempt {{ j + 1 }}: {{ response }}
            </p>
            <p>
              Elapsed Time:
              {{ activityState.populationResponses[i].elapsedTime }}
            </p>
          </div>
          <div class="thin-separator"></div>
          <label>
            Elapsed Time:
            <input
              type="text"
              name="elapsedTime"
              id="elapsedTime"
              (change)="elapsedTimeChange($event, i)" />
          </label>
          <button (click)="addAttempt(i)">Add Attempt</button>
          <div class="separator"></div>
        </div>
      </div>
      <div>
        <label for="exportToClipboard">Export to Clipboard</label>
        <input
          type="checkbox"
          name="exportToClipboard"
          id="exportToClipboard"
          [(ngModel)]="exportToClipboard" />
        <button class="button-secondary" (click)="exportSaveState()">
          Export
        </button>
      </div>
      <button class="button-primary" (click)="launchSaveState()">Launch</button>
      <button class="button-accent" (click)="resetActivity()">
        Start Over
      </button>
    </div>
  </div>
</div>
