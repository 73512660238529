import { TemplateID } from 'src/app/shared/enums';
export const TeiTryDefaults: { [key in TemplateID]: number } = {
  [TemplateID.NONE]: 0,
  [TemplateID.MULTIPLE_CHOICE]: 2,
  [TemplateID.MULTIPLE_CHOICE_IMAGE]: 2,
  [TemplateID.MULTI_SELECT]: 2,
  [TemplateID.MULTI_SELECT_IMAGE]: 2,
  [TemplateID.INLINE_CHOICE]: 2,
  [TemplateID.TEXT_ENTRY]: 2,
  [TemplateID.TRUE_FALSE_CHOICE]: 1,
  [TemplateID.STATIC_HORIZONTAL_SEQUENCING]: 2,
  [TemplateID.DYNAMIC_HORIZONTAL_SEQUENCING]: 2,
  [TemplateID.STATIC_VERTICAL_SEQUENCING]: 2,
  [TemplateID.FILL_IN_THE_BLANK_TILE_TEXT]: 2,
  [TemplateID.CONSTRUCTED_RESPONSE]: 1,
  [TemplateID.CONSTRUCTED_RESPONSE_WITH_RUBRIC]: 1,
  [TemplateID.EXTENDED_CONSTRUCTED_RESPONSE]: 1,
  [TemplateID.EXTENDED_CONSTRUCTED_RESPONSE_WITH_RUBRIC]: 1,
  [TemplateID.SORTING_BUCKETS]: 2,
  [TemplateID.SORTING_BUCKETS_IMAGES]: 2,
};
