import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageService } from '../../services/message/message.service';
import { MessageTypes, TriggerActionTypes } from '../../../../shared/enums';
import { Message } from '../../../../shared/interfaces';
import { TimeElapsedService } from 'src/app/shared/services/time-elapsed/time-elapsed.service';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { AudioDescriptor } from 'src/app/shared/enums/audio-descriptor';
import { AudioType } from 'src/app/shared/enums/audio-type';
import { ReferrerService } from 'src/app/shared/services/referrer/referrer.service';
import { ConfigurationService } from 'src/app/modules/configuration/services/configuration/configuration.service';

@Component({
  selector: 'htc-message-popup',
  templateUrl: './message-popup.component.html',
  styleUrls: ['./message-popup.component.scss'],
})
export class MessagePopupComponent implements OnDestroy {
  readonly messageImagePath = 'assets/error-images/';
  readonly buttonImagePath = 'assets/button-icons/';
  readonly MessageTypes = MessageTypes;

  @ViewChild(DialogComponent, { static: true }) dialog!: DialogComponent;

  show = false;
  hasLink = false;
  splitMessageFirst = '';
  splitMessageSecond = '';
  timerActive = false;
  messagePopup!: Message;
  clickDescriptor = AudioDescriptor.CLICK_GENERAL;
  clickType = AudioType.audio_SfxClick;

  private subscriptions = new Subscription();

  constructor(
    public messageService: MessageService,
    private timeElapsedService: TimeElapsedService,
    private referrerService: ReferrerService,
    private configurationService: ConfigurationService
  ) {
    this.subscriptions.add(
      this.messageService.messageData.subscribe((data: Message | undefined) => {
        if (data) {
          this.messagePopup = data;
          this.formatText();
          this.setPopupVisible(true);
        } else {
          this.setPopupVisible(false);
        }
      })
    );

    this.subscriptions.add(
      this.messageService.triggerData.subscribe(
        (trigger: TriggerActionTypes | undefined) => {
          switch (trigger) {
            case TriggerActionTypes.LOGOUT:
              this.setPopupVisible(false);
              break;
            default:
              break;
          }
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  clickButton(trigger: TriggerActionTypes): void {
    switch (trigger) {
      case TriggerActionTypes.OK:
        this.messageService.onOkClicked();
        break;
      case TriggerActionTypes.LINK: {
        let navTarget = this.messagePopup.hyperLink;
        if (this.messagePopup.hyperLink === '{REFERRER}') {
          if (this.referrerService.getReferrer().length > 0) {
            navTarget = this.referrerService.getReferrer();
          } else {
            navTarget = this.configurationService.config.seDomain;
          }
        }
        window.open(navTarget, '_self');
        break;
      }
      case TriggerActionTypes.LOGOUT:
        this.sendTrigger(trigger);
        break;
      default:
        break;
    }

    this.setPopupVisible(false);
  }

  setPopupVisible(show: boolean): void {
    if (show) {
      this.timerActive = this.timeElapsedService.isTimerActive();
      this.timeElapsedService.pauseTimer();
      this.dialog.open();
    } else {
      if (this.timerActive) {
        this.timeElapsedService.startTimer();
      }
      this.dialog.close();
    }
    this.show = show;
  }

  getErrorImagePath(): string {
    return `${this.messageImagePath}${this.messagePopup.imagePath}.png`;
  }

  getButtonImagePath(buttonImgPath: string | undefined): string {
    if (buttonImgPath) {
      return `${this.buttonImagePath}${buttonImgPath}.svg`;
    }
    return '';
  }

  formatText(): void {
    this.hasLink = this.messagePopup.messageText.includes('<link>');
    if (this.hasLink) {
      this.hasLink = true;
      this.splitMessageFirst = this.messagePopup.messageText.split('<link>')[0];
      this.splitMessageSecond =
        this.messagePopup.messageText.split('<link>')[1];
    }
  }

  sendTrigger(trigger: TriggerActionTypes): void {
    this.messageService.sendTrigger(trigger);
    this.clearTriggers();
  }

  clearTriggers(): void {
    this.messageService.clearTriggers();
  }
}
