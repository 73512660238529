import { Activity } from 'src/app/shared/interfaces';

export const lectura6: Activity = {
  id: '018ea019-067c-7e31-b84e-b5813d55334d',
  product: 'Lectura',
  language: 'es',
  skillName: 'Preposiciones',
  thumbnail: {
    id: 'recSxQSwwiHAqJH7m',
    url: '/helix/UX_Images/Lesson_Posters/Prepositions_G4V4_Poster.jpg',
    altText: [
      {
        language: 'en',
        altText: 'Axis works on his jetpack while CAT watches.',
      },
      {
        language: 'es',
        altText:
          'Axis trabaja en su mochila propulsora mientras que G.A.T.A. observa.',
      },
    ],
  },
  instructionsImage: {
    id: 'recqnzfDjMQK7N7kr',
    url: '/helix/UX_Images/Characters/CAT/CAT_Chibi_Happy_Eyes_Open_Mouth_Open.svg',
    altText: [
      { language: 'en', altText: 'CAT is smiling.' },
      { language: 'es', altText: 'G.A.T.A. está sonriendo.' },
    ],
  },
  characterLeanout: {
    id: 'rec5Rylxn2J2DNIHO',
    url: '/helix/TEI/Leanouts/CAT_Leanout_01.svg',
    altText: [
      { language: 'en', altText: 'CAT leans out from the side of the screen.' },
      {
        language: 'es',
        altText: 'G.A.T.A. se asoma por el lado de la pantalla.',
      },
    ],
  },
  description:
    'Axis consulta el manual de usuario y evalúa los problemas que tiene su mochila propulsora.',
  domain: { text: 'Categorías gramaticales' },
  seriesName: 'Planeta preposicional',
  episodeNumber: '4',
  episodeTitle: 'Intento de vuelo',
  videos: [
    {
      id: 'rectSRmXxFSUP1OYW',
      url: '/helix/Lectura/Grade_4/Prepositions/Episode_04/hls_streams/L_G4_Prepositions_Ep04_Video.m3u8',
      posterURL: '/helix/UX_Images/Lesson_Posters/Prepositions_G4V4_Poster.jpg',
      captions: [
        {
          src: '/helix/Lectura/Grade_4/Prepositions/Episode_04/L_G4_Prepositions_Ep04_Video.vtt',
          srclang: 'es',
          mode: 'hidden',
          kind: 'captions',
        },
      ],
    },
  ],
  startScreenData: {
    learningTarget: [
      {
        id: '32684.D',
        learningTarget: {
          text: 'editar borradores para las preposiciones y locuciones preposicionales',
        },
        successCriteria: [
          {
            id: '32684.D.1',
            text: 'identificar y corregir las preposiciones y locuciones preposicionales en mi propia escritura o la escritura de otros',
          },
          {
            id: '32684.D.2',
            text: 'usar locuciones preposicionales para mejorar mi escritura',
          },
        ],
      },
    ],
    background_leftImage: {
      id: 'recSxQSwwiHAqJH7m',
      url: '/helix/UX_Images/Lesson_Posters/Prepositions_G4V4_Poster.jpg',
      altText: [
        {
          language: 'en',
          altText: 'Axis works on his jetpack while CAT watches.',
        },
        {
          language: 'es',
          altText:
            'Axis trabaja en su mochila propulsora mientras que G.A.T.A. observa.',
        },
      ],
    },
    background_rightImage: {
      id: 'recxjC353VMav5ybu',
      url: '/helix/UX_Images/Start_Screen_Blobs/Nova-Scouts_space-sky.svg',
      altText: [
        { language: 'en', altText: 'Stars in space.' },
        { language: 'es', altText: 'Estrellas en el espacio.' },
      ],
    },
    foreground: {
      id: 'recOn2Luhc6XMnbRL',
      url: '/helix/UX_Images/Start_Screen_Characters/Cat5.svg',
      altText: [
        { language: 'en', altText: 'CAT is pouncing.' },
        { language: 'es', altText: 'G.A.T.A. está saltando.' },
      ],
    },
  },
  populations: [
    {
      id: '32684.D.1.1',
      templateID: 0,
      complexity: 'Evaluation',
      hint: [
        {
          id: '32684.D.1.1.0',
          type: 'BasicSlide',
          data: {
            title:
              'Una preposición es una palabra utilizada para unir palabras o grupos de palabras que se relacionan entre sí. También sirve para indicar lugar, tiempo o causa.',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Repasa el párrafo y determina qué preposición es incorrecta. \n\nMi amigo Martín y yo vamos a nadar **contra** las tres **de** la tarde. A los dos nos gusta nadar. A Martín le gusta tirarse del trampolín más alto. A mí me gusta flotar **en** la parte menos profunda.',
        },
        answerChoices: [
          { id: '32684.D.1.1_1', choice: { texts: [{ text: 'contra' }] } },
          {
            id: '32684.D.1.1_2',
            choice: { texts: [{ text: 'de' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La preposición «del» es correcta en la oración: «A Martín le gusta tirarse _del_ trampolín más alto».',
              },
            },
          },
          {
            id: '32684.D.1.1_3',
            choice: { texts: [{ text: 'en' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La preposición «en» es correcta en la oración: «A mí me gusta flotar _en_ la parte menos profunda».',
              },
            },
          },
        ],
        answers: ['32684.D.1.1_1'],
        associatedSuccessCriteria: ['32684.D.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La preposición «contra» es la incorrecta porque da información sobre el lugar y no del tiempo.',
      },
      passage: {
        text: 'Mi amigo Martín y yo vamos a nadar **contra** las tres **de** la tarde. A los dos nos gusta nadar. A Martín le gusta tirarse del trampolín más alto. A mí me gusta flotar **en** la parte menos profunda.',
      },
      passageTitle: { text: 'Vamos a nadar' },
    },
    {
      id: '32684.D.2.2',
      templateID: 1,
      complexity: 'Evaluation',
      hint: [
        {
          id: '32684.D.2.2.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recRORCPGztEFmN5w',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_02.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y sus usos.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Revisa el párrafo de nuevo y elige la preposición correcta. \nMi amigo Martín y yo vamos a nadar {CHOICE_TOKEN} las tres de la tarde. A los dos nos gusta nadar. A Martín le gusta tirarse del trampolín más alto. A mí me gusta flotar en la parte menos profunda.',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                { id: '32684.D.2.2_1', text: 'a' },
                {
                  id: '32684.D.2.2_2',
                  text: 'bajo',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        '«Bajo» es una preposición que da información sobre el lugar.',
                    },
                  },
                },
                {
                  id: '32684.D.2.2_3',
                  text: 'rápido',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        '«Rápido» es un adjetivo; no es una preposición.',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['32684.D.2.2_1'],
        associatedSuccessCriteria: ['32684.D.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'La preposición «a» es correcta porque da información sobre la hora a la que van a nadar.',
      },
    },
    {
      id: '32684.D.1.3',
      templateID: 0,
      complexity: 'Evaluation',
      hint: [
        {
          id: '32684.D.1.3.0',
          type: 'BasicSlide',
          data: {
            title:
              'Las locuciones preposicionales son expresiones formadas por dos o más palabras que funcionan como una preposición. También dan más información en cuanto al espacio, el tiempo y el modo.',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Repasa el párrafo y determina qué locución preposicional es incorrecta.\n\n¿Qué locución o frase preposicional es incorrecta?',
        },
        answerChoices: [
          { id: '32684.D.1.3_1', choice: { texts: [{ text: 'antes de' }] } },
          {
            id: '32684.D.1.3_2',
            choice: { texts: [{ text: 'después de' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La locución o frase preposicional «después de» es correcta en la oración: «Mi papá me compró un globo y palomitas de maíz _después de_ nuestro paseo en la rueda de la fortuna».',
              },
            },
          },
          {
            id: '32684.D.1.3_3',
            choice: { texts: [{ text: 'detrás de' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La locución o frase preposicional «detrás de» es correcta en la oración: «Está _detrás del_ puesto de comida».',
              },
            },
          },
        ],
        answers: ['32684.D.1.3_1'],
        associatedSuccessCriteria: ['32684.D.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La locución o frase preposicional «por encima de» es correcta porque da información sobre el lugar por donde voló el globo.',
      },
      passage: {
        text: 'Me encanta ir a la feria con mi familia. Tan pronto como llegamos, montamos la rueda de la fortuna. Está **detrás del** puesto de comida. Mi papá me compró un globo y palomitas de maíz **después de** nuestro paseo en la rueda de la fortuna. Desafortunadamente, solté el globo mientras comía. Voló **antes de** mi cabeza.',
      },
      passageTitle: { text: 'La feria' },
    },
    {
      id: '32684.D.2.4',
      templateID: 1,
      complexity: 'Evaluation',
      hint: [
        {
          id: '32684.D.2.4.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recF9Y2P0hywEJSem',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_07.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y sus usos.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Revisa el párrafo de nuevo y elige la locución o frase preposicional correcta. \n\nMe encanta ir a la feria con mi familia. Tan pronto como llegamos, montamos la rueda de la fortuna. Está detrás del puesto de comida. Mi papá me compró un globo y palomitas de maíz después de nuestro paseo en la rueda de la fortuna. Desafortunadamente, solté el globo mientras comía. Voló {CHOICE_TOKEN} mi cabeza.',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                { id: '32684.D.2.4_1', text: 'por encima de' },
                {
                  id: '32684.D.2.4_2',
                  text: 'antes de',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        '«Antes de» es una locución o frase preposicional que da información del tiempo.',
                    },
                  },
                },
                {
                  id: '32684.D.2.4_3',
                  text: 'entonces',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        '«Entonces» es un adverbio; no es una preposición.',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['32684.D.2.4_1'],
        associatedSuccessCriteria: ['32684.D.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'La locución o frase preposicional «por encima de» es correcta porque da información sobre el lugar por donde voló el globo.',
      },
    },
    {
      id: '32684.D.1.5',
      templateID: 0,
      complexity: 'Evaluation',
      hint: [
        {
          id: '32684.D.1.5.0',
          type: 'BasicSlide',
          data: {
            title:
              'Una preposición es una palabra utilizada para unir palabras o grupos de palabras que se relacionan entre sí. También sirve para indicar lugar, tiempo o causa.',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Repasa el párrafo y determina qué preposición es incorrecta. \n\n¿Qué preposición es incorrecta?',
        },
        answerChoices: [
          { id: '32684.D.1.5_1', choice: { texts: [{ text: 'durante' }] } },
          {
            id: '32684.D.1.5_2',
            choice: { texts: [{ text: 'sobre' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La preposición _«_sobre» es correcta en la oración: «La lechuga y los tomates crecen _sobre_ la tierra, pero las zanahorias crecen debajo».',
              },
            },
          },
          {
            id: '32684.D.1.5_3',
            choice: { texts: [{ text: 'en' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La preposición _«_en» es correcta en la oración: «Mi abuela y yo sembramos semillas _en_ el jardín del patio trasero».',
              },
            },
          },
        ],
        answers: ['32684.D.1.5_1'],
        associatedSuccessCriteria: ['32684.D.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La preposición «durante» da información sobre el tiempo y no del lugar.',
      },
      passage: {
        text: 'Mi abuela y yo sembramos semillas **en** el jardín del patio trasero. Compramos semillas de zanahoria, lechuga y tomate. La lechuga y los tomates crecen **sobre** la tierra, pero las zanahorias crecen debajo. Por esa razón, sembramos las zanahorias **durante** la lechuga y los tomates para que no se enreden. ¡Estoy muy emocionada al ver nuestro jardín crecer!',
      },
      passageTitle: { text: 'El jardín' },
    },
    {
      id: '32684.D.2.6',
      templateID: 1,
      complexity: 'Evaluation',
      hint: [
        {
          id: '32684.D.2.6.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recRORCPGztEFmN5w',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_02.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y sus usos.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Revisa el párrafo de nuevo y elige la preposición correcta. \n\nMi abuela y yo sembramos semillas en el jardín del patio trasero. Compramos semillas de zanahoria, lechuga y tomate. La lechuga y los tomates crecen sobre la tierra, pero las zanahorias crecen debajo. Por esa razón, sembramos las zanahorias {CHOICE_TOKEN} la lechuga y los tomates para que no se enreden. ¡Estoy muy emocionada al ver nuestro jardín crecer!',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                { id: '32684.D.2.6_1', text: 'entre' },
                {
                  id: '32684.D.2.6_2',
                  text: 'a',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        '«A» es una preposición que da informacion del tiempo.',
                    },
                  },
                },
                {
                  id: '32684.D.2.6_3',
                  text: 'tierra',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        '«Tierra» es un sustantivo; no es una preposición.',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['32684.D.2.6_1'],
        associatedSuccessCriteria: ['32684.D.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'La preposición «entre» es correcta porque da información sobre el lugar donde se sembraron las zanahorias.',
      },
    },
    {
      id: '32684.D.1.7',
      templateID: 0,
      complexity: 'Evaluation',
      hint: [
        {
          id: '32684.D.1.7.0',
          type: 'BasicSlide',
          data: {
            title:
              'Las locuciones preposicionales son expresiones formadas por dos o más palabras que funcionan como una preposición. También dan más información en cuanto al espacio, el tiempo y el modo.',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Repasa el párrafo y determina qué locución preposicional es la incorrecta.\n¿Qué locución preposicional es incorrecta?',
        },
        answerChoices: [
          { id: '32684.D.1.7_1', choice: { texts: [{ text: 'a causa de' }] } },
          {
            id: '32684.D.1.7_2',
            choice: { texts: [{ text: 'cerca de' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La locución o frase preposicional «cerca de» es correcta en la oración: «A veces el equipo contrario patea el balón _cerca de_ la portería».',
              },
            },
          },
          {
            id: '32684.D.1.7_3',
            choice: { texts: [{ text: 'después de' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La locución o frase preposicional «después de» es correcta en la oración: «Me encanta jugar fútbol con mis amigos todos los días _después de_ la escuela».',
              },
            },
          },
        ],
        answers: ['32684.D.1.7_1'],
        associatedSuccessCriteria: ['32684.D.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La locución o frase preposicional «a causa de» da información sobre la causa y no del lugar.',
      },
      passage: {
        text: 'Me encanta jugar fútbol con mis amigos todos los días **después de** la escuela. Todos tenemos diferentes posiciones y yo soy el portero. A veces el equipo contrario patea el balón **cerca de** la portería. Me tiro **a causa de** balón para bloquearlo. Desafortunadamente, el otro equipo anota.',
      },
      passageTitle: { text: 'El fútbol' },
    },
    {
      id: '32684.D.2.8',
      templateID: 1,
      complexity: 'Evaluation',
      hint: [
        {
          id: '32684.D.2.8.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recF9Y2P0hywEJSem',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_07.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y sus usos.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Revisa el párrafo de nuevo y elige la locución o frase preposicional correcta. \n\nMe encanta jugar fútbol con mis amigos todos los días después de la escuela. Todos tenemos diferentes posiciones y yo soy el portero. A veces el equipo contrario patea el balón cerca de la portería. Me tiro {CHOICE_TOKEN} balón para bloquearlo. Desafortunadamente, el otro equipo anota.',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                { id: '32684.D.2.8_1', text: 'enfrente del' },
                {
                  id: '32684.D.2.8_2',
                  text: 'antes del',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        '«Antes del» es una locución o frase preposicional que da información del tiempo.',
                    },
                  },
                },
                {
                  id: '32684.D.2.8_3',
                  text: 'corriendo',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        '«Corriendo» es un verbo; no es una preposición.',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['32684.D.2.8_1'],
        associatedSuccessCriteria: ['32684.D.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'La locución o frase preposicional «enfrente del» es correcta porque da información sobre el lugar donde se tira para bloquear el balón.',
      },
    },
    {
      id: '32684.D.1.9',
      templateID: 0,
      complexity: 'Evaluation',
      hint: [
        {
          id: '32684.D.1.9.0',
          type: 'BasicSlide',
          data: {
            title:
              'Una preposición es una palabra utilizada para unir palabras o grupos de palabras que se relacionan entre sí. También sirve para indicar lugar, tiempo o causa.',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Repasa el párrafo y determina qué preposición es incorrecta. \n\n¿Qué preposición es incorrecta?',
        },
        answerChoices: [
          { id: '32684.D.1.9_1', choice: { texts: [{ text: 'hacia' }] } },
          {
            id: '32684.D.1.9_2',
            choice: { texts: [{ text: 'de' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La preposición «de» es correcta en la oración: «La maestra nos indicó que sacáramos un pedazo de papel _de_ nuestro escritorio para numerar del uno al diez».',
              },
            },
          },
          {
            id: '32684.D.1.9_3',
            choice: { texts: [{ text: 'por' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La preposición «por» es correcta en la oración: «¡Me alegré mucho _por_ los resultados del examen!».',
              },
            },
          },
        ],
        answers: ['32684.D.1.9_1'],
        associatedSuccessCriteria: ['32684.D.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La preposición «hacia» da información sobre el lugar y no del tiempo.',
      },
      passage: {
        text: 'Tuvimos un examen **hacia** la clase de matemáticas. La maestra nos indicó que sacáramos un pedazo **de** papel de nuestro escritorio para numerar del uno al diez. Ella leyó los números en voz alta y tuvimos que escribirlos en letra. Obtuve todas las respuestas correctas sin ninguna ayuda. ¡Me alegré mucho **por** los resultados del examen!',
      },
      passageTitle: { text: 'El examen de matemáticas' },
    },
    {
      id: '32684.D.2.10',
      templateID: 1,
      complexity: 'Evaluation',
      hint: [
        {
          id: '32684.D.2.10.1',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recRORCPGztEFmN5w',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_02.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y sus usos.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Revisa el párrafo de nuevo y elige la preposición correcta. \n\nTuvimos un examen {CHOICE_TOKEN} la clase de matemáticas. La maestra nos indicó que sacáramos un pedazo de papel de nuestro escritorio para numerar del uno al diez. Ella leyó los números en voz alta y tuvimos que escribirlos en letra. Obtuve todas las respuestas correctas sin ninguna ayuda. ¡Me alegré mucho por los resultados del examen!',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                { id: '32684.D.2.10_1', text: 'durante' },
                {
                  id: '32684.D.2.10_2',
                  text: 'bajo',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        '«Bajo» es una preposición que da información sobre el lugar.',
                    },
                  },
                },
                {
                  id: '32684.D.2.10_3',
                  text: 'rojo',
                  targetedFeedback: {
                    firstIncorrect: {
                      content: '«Rojo» es un adjetivo; no es una preposición.',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['32684.D.2.10_1'],
        associatedSuccessCriteria: ['32684.D.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'La preposición «durante» es correcta porque da información sobre la hora a la que tomaron el examen.',
      },
    },
  ],
  activityManifest: [
    { type: 'START_SCREEN' },
    { type: 'ENTRY_SURVEY' },
    { type: 'VIDEO', id: 'rectSRmXxFSUP1OYW' },
    { type: 'QUESTION', id: '32684.D.1.1' },
    { type: 'QUESTION', id: '32684.D.2.2' },
    { type: 'QUESTION', id: '32684.D.1.3' },
    { type: 'QUESTION', id: '32684.D.2.4' },
    { type: 'QUESTION', id: '32684.D.1.5' },
    { type: 'QUESTION', id: '32684.D.2.6' },
    { type: 'QUESTION', id: '32684.D.1.7' },
    { type: 'QUESTION', id: '32684.D.2.8' },
    { type: 'QUESTION', id: '32684.D.1.9' },
    { type: 'QUESTION', id: '32684.D.2.10' },
    { type: 'EXIT_SURVEY' },
    { type: 'RESULTS_SCREEN' },
  ],
  version: '1984019016',
};
