import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { UseAnimationService } from 'src/app/modules/use-animation/use-animation.service';

@Component({
  selector: 'htc-number-counter',
  template: `
    <div class="num-counter" data-testid="htc-counter-text">
      {{ displayedCountStr }}
    </div>
  `,
})
export class NumberCounterComponent implements OnInit, OnChanges, OnDestroy {
  readonly minInterval = 10;

  @Input() numberToDisplay = 0;
  @Input() timeAnimDuration = 1500; // animation duration in milliseconds
  @Input() hasLeadingZeroes = false; // see [displayedCountLength]
  @Input() displayedCountLength!: number; //the amount of digits to display

  // The string value to display.
  displayedCountStr = '';
  lastNumberDisplayed = 0;
  maxCount = 0;
  initedNumberToDisplay = false;
  useAnimation = true;
  sub = new Subscription();

  constructor(private useAnimationService: UseAnimationService) {}

  ngOnInit(): void {
    if (this.hasLeadingZeroes && !this.displayedCountLength) {
      this.displayedCountLength = this.numberToDisplay.toString().length;
    }
    this.maxCount = Math.pow(10, this.displayedCountLength) - 1;
    this.displayedCountStr = this.getAdjustedNumberString(this.numberToDisplay);

    this.sub.add(
      this.useAnimationService.useAnimation$.subscribe(useAnim => {
        this.useAnimation = useAnim;
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.maxCount === 0) {
      if (
        changes.numberToDisplay &&
        changes.numberToDisplay.firstChange &&
        !changes.displayedCountLength
      ) {
        this.displayedCountLength = this.numberToDisplay.toString().length;
      }
      this.maxCount = Math.pow(10, this.displayedCountLength) - 1;
    }

    if (!this.initedNumberToDisplay) {
      this.initedNumberToDisplay = true;
      this.lastNumberDisplayed = this.numberToDisplay;
      return;
    }

    if (this.numberToDisplay > this.maxCount) {
      this.numberToDisplay = this.maxCount;
    }

    if (this.numberToDisplay !== this.lastNumberDisplayed) {
      if (this.useAnimation) {
        this.animateCounter();
      } else {
        this.displayedCountStr = this.getAdjustedNumberString(
          this.numberToDisplay
        );
      }
    }
  }

  /**
   * Triggers the count up/down animation.
   */
  animateCounter(): void {
    const direction = this.numberToDisplay >= this.lastNumberDisplayed ? 1 : -1;
    const diff = Math.abs(this.numberToDisplay - this.lastNumberDisplayed);
    let value = this.lastNumberDisplayed;

    let time: number;
    let incrementValue: number;
    time = this.timeAnimDuration / diff;
    if (time > this.minInterval) {
      incrementValue = direction; // incrementing(+) or decrementing(-) by 1
    } else {
      // we will need to increment/decrement by more than 1
      time = this.minInterval; // this needs to be more than 1, so let's make it "10"
      incrementValue =
        Math.ceil((diff / this.timeAnimDuration) * time) * direction;
    }

    const intervalID = setInterval(() => {
      value += incrementValue; // increment the value to display

      if (
        (direction > 0 && value < this.numberToDisplay) ||
        (direction < 0 && value > this.numberToDisplay)
      ) {
        this.displayedCountStr = this.getAdjustedNumberString(value);
      } else {
        // need to make sure this displays the endValue, especially
        // since incrementValue could be more than "1"
        this.displayedCountStr = this.getAdjustedNumberString(
          this.numberToDisplay
        );

        if (intervalID) {
          clearInterval(intervalID);
        }
        this.lastNumberDisplayed = this.numberToDisplay;
      }
    }, time);
  }

  /**
   * Returns a string value of the given number, adjusted for leading zeroes.
   * @param value	the given number
   * @returns what the description says
   */
  getAdjustedNumberString(value: number): string {
    let str = value.toString();
    if (this.hasLeadingZeroes) {
      str = str.padStart(
        this.displayedCountLength,
        value >= 0 ? '0' : '\u2003'
      );
      // NOTE: "\u2003" = big (and noticeable) space
      // (https://www.compart.com/en/unicode/category/Zs)
    }
    return str;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
