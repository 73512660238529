import videojs from 'video.js';
import { OffsettersData } from '../../data';
import { IComponentOptionsExtended } from '../../interfaces/component-options-extended.interface';
import { JumpButtonComponent } from '../jump-button.component.ts/jump-button.component';

class JumpAheadComponent extends JumpButtonComponent {
  constructor(player: videojs.Player, options: IComponentOptionsExtended) {
    super(player, {
      ...options,
      classNames: 'vjs-jump-ahead',
    });

    this.controlText('Skip Forward');

    this.setAttribute('data-testid', 'htc-vp-jump-ahead-button');

    player.on('timeupdate', () => {
      this.handleEnableState();
    });
  }

  handleClick(): void {
    this.videoService.jumpStep(OffsettersData.jumpAhead);
  }

  handleEnableState(): void {
    this.videoService.setJumpBtnEnable(this, OffsettersData.jumpAhead);
  }
}

videojs.registerComponent('jumpAhead', JumpAheadComponent);
export default JumpAheadComponent;
