<div
  class="dropdown read-aloud-excluded-element"
  [ngStyle]="{ 'width.px': elementWidth }"
  [ngClass]="{
    opened: isDropdownMenuOpen,
    closed: !isDropdownMenuOpen,
    wrong: isWrongAnswer,
    correct: isCorrectAnswer
  }"
  (click)="onClickEvent($event)">
  <button
    [id]="uniqueId"
    [attr.data-testid]="uniqueId"
    aria-haspopup="listbox"
    [tabindex]="isDropdownMenuOpen ? -1 : 0"
    (click)="toggleDropdown($event)"
    (keydown)="handleKeyboardEvents($event)"
    [disabled]="!isWrongAnswer && isCorrectAnswer">
    <span>
      <htc-math-text-wrapper
        data-testid="htc-dropdown-current-selection"
        [attr.aria-label]="
          (currentSelection.name
            | htcMathToSpeech: (sres.lastAddedMapKey | async)) ||
          (placeholder ?? '')
        "
        [textInput]="
          currentSelection.name || (placeholder ?? '')
        "></htc-math-text-wrapper>
    </span>
    <svg-icon
      *ngIf="iconName.length > 0"
      [@fadeIn]="isCorrectAnswer"
      src="{{ '/assets/button-icons/' + iconName + '.svg' }}"></svg-icon>
  </button>
  <div
    #optionsRef
    class="options-container"
    [attr.aria-labelledby]="'DROPDOWN.DROPDOWN_MENU' | translate"
    role="listbox"
    [attr.aria-expanded]="isDropdownMenuOpen">
    <ul class="options-wrapper" data-testid="htc-inline-choice-options">
      <li
        class="option"
        [tabindex]="isDropdownMenuOpen ? 0 : -1"
        [ngClass]="{
          selected: option === currentSelection,
          disabled: option.disabled,
          preview: isWrongAnswer && isCorrectAnswer
        }"
        role="option"
        *ngFor="let option of options"
        (click)="setCurrentSelection(option)"
        (keydown)="handleKeyboardEvents($event)"
        [attr.data-testid]="option.id"
        htcAudioClick
        [audioType]="clickType"
        [descriptor]="clickDescriptor">
        <span>
          <htc-math-text-wrapper
            [attr.data-testid]="'htc-dropdown-option-' + option.id"
            [attr.aria-label]="
              option.name | htcMathToSpeech: (sres.lastAddedMapKey | async)
            "
            [textInput]="option.name"></htc-math-text-wrapper>
        </span>
      </li>
      <div class="option-none" *ngIf="options.length === 0">
        --- No Options ---
      </div>
    </ul>
  </div>

  <htc-inline-correct-answer
    [showAnswer]="showCorrectAnswer"
    value="{{ correctAnswer?.name }}"></htc-inline-correct-answer>
</div>
