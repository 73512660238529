import { Injectable } from '@angular/core';
import videojs from 'video.js';
import { Settings } from '../enums';
import { CaptionsService, VideoService, SettingsPopupsService } from '.';

@Injectable({
  providedIn: 'root',
})
export class VolumeService {
  constructor(
    private videoService: VideoService,
    private captionsService: CaptionsService,
    private settingsService: SettingsPopupsService
  ) {}

  onVolumeChange(): void {
    if (this.videoService.player) {
      const volume = this.videoService.player.volume();
      if (this.videoService.player.muted() || volume === 0) {
        this.captionsService.showCaptions(
          true,
          this.settingsService.subMenuElements[Settings.CAPTION_TOGGLE]
        );
      }
    }
  }

  volumeChange(percentChange: number): videojs.TimeRange | undefined {
    return this.videoService.player?.volume(
      this.videoService.player?.volume() + percentChange
    );
  }

  toggleMute(): void {
    this.videoService.player?.muted(!this.videoService.player?.muted());
  }
}
