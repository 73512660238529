<button
  class="node-container circle"
  [ngClass]="!disabled ? focusClass + ' enabled' : focusClass"
  id="progress-bar-node-container"
  data-testid="htc-progress-node-container"
  [tabIndex]="!disabled ? 0 : -1"
  (click)="onNodeClicked()"
  [disabled]="disabled">
  <div
    class="node-bg circle center ignore-pointer-events"
    data-testid="htc-progress-node-bg"></div>
  <div
    class="node-text center ignore-pointer-events"
    data-testid="htc-progress-node-text">
    {{ data.text }}
  </div>

  <div [ngSwitch]="statusClass" class="status-container">
    <htc-status-wrong
      *ngSwitchCase="'wrong'"
      data-testid="htc-wrong-progress-node"
      class="node-img circle center ignore-pointer-events"
      [ngClass]="statusClass"></htc-status-wrong>

    <htc-status-correct
      *ngSwitchCase="'correct'"
      data-testid="htc-correct-progress-node"
      class="node-img circle center ignore-pointer-events"
      [ngClass]="statusClass"></htc-status-correct>

    <htc-status-perfect
      *ngSwitchCase="'perfect'"
      data-testid="htc-perfect-progress-node"
      class="node-img circle center ignore-pointer-events"
      [ngClass]="statusClass"></htc-status-perfect>

    <htc-status-complete
      *ngSwitchCase="'complete'"
      data-testid="htc-complete-progress-node"
      class="node-img circle center ignore-pointer-events"
      [ngClass]="statusClass"></htc-status-complete>
  </div>
</button>
