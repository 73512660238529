import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { PopupToolbarButtonComponent } from './components/popup-toolbar-button/popup-toolbar-button.component';
import { FooterComponent } from './components/footer/footer.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { VideoModule } from '../video/video.module';
import { TranslateModule } from '@ngx-translate/core';
import { ToolsModule } from '../tools/tools.module';
import { ReadAloudToggleComponent } from './components/read-aloud-toggle/read-aloud-toggle.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    VideoModule,
    TranslateModule,
    ToolsModule,
  ],
  exports: [
    ReadAloudToggleComponent,
    PopupToolbarButtonComponent,
    ToolbarComponent,
    FooterComponent,
  ],
  declarations: [
    ReadAloudToggleComponent,
    PopupToolbarButtonComponent,
    ToolbarComponent,
    FooterComponent,
  ],
})
export class FooterModule {}
