import { UserData } from './../../interfaces/UserData';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { MessageService } from '../../../message/services/message/message.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TriggerActionTypes } from 'src/app/shared/enums';
import { FeatureFlagService } from 'src/app/modules/feature-flags/services/feature-flag/feature-flag.service';
import { FeatureFlags } from 'src/app/shared/enums/feature-flags';

@Injectable({
  providedIn: 'root',
})
export class AuthSessionService {
  userRoles: string[] = [];
  private _isTeacher = false;

  get isTeacher(): boolean {
    return this._isTeacher;
  }

  constructor(
    private messageService: MessageService,
    private oidcSecurityService: OidcSecurityService,
    private featureFlagService: FeatureFlagService
  ) {
    this.messageService.triggerData.subscribe(message => {
      //listen for logout trigger, then execute logoff.
      if (message === TriggerActionTypes.LOGOUT) {
        this.logout().subscribe(l => {
          console.log('logged off', l);
        });
      }
    });
  }

  logout(): Observable<unknown> {
    return this.oidcSecurityService.logoffAndRevokeTokens();
  }

  checkAuth(): Observable<LoginResponse> {
    return this.oidcSecurityService.checkAuth();
  }

  saveRoles(userData: UserData): void {
    this.userRoles = userData.role ?? [];

    this.setIsTeacher();
    this.featureFlagService.flagsChanged.subscribe(() => this.setIsTeacher());
  }

  hasRole(role: string): boolean {
    return this.userRoles.includes(role);
  }

  private setIsTeacher(): void {
    this._isTeacher =
      this.hasRole('teacher') &&
      this.featureFlagService.isFlagEnabled(FeatureFlags.TEACHER_MODE);
  }
}
