import { FeatureFlags } from 'src/app/shared/enums/feature-flags';
import { KnowledgeBaseService } from '../../../olp-api/services/kb/kb.service';
import { Injectable } from '@angular/core';
import { FeatureFlagService } from 'src/app/modules/feature-flags/services/feature-flag/feature-flag.service';
import { IncompleteActivityState } from '../../interfaces/incomplete-activity-state';
import { ActivityStateManager } from '../../classes/activity-state-manager/activity-state-manager';
import { IProgressNodeData } from 'src/app/modules/progress-bar/interfaces/progress-node-data.interface';
import { ActivityManifestObject } from 'src/app/shared/interfaces/activity-manifest-object';
import { ActivityObjectTypes } from 'src/app/shared/enums/activity-object-types';
import { Activity } from 'src/app/shared/interfaces';
import {
  ProgressNodeFocusEnum,
  ProgressNodeStatusEnum,
  ProgressNodeTypeEnum,
} from 'src/app/modules/progress-bar/enums';

@Injectable({
  providedIn: 'root',
})
export class IncompleteActivityStateService {
  activityStateManager!: ActivityStateManager;
  activityCreationIndex = 0;
  activityCreationQuestionIndex = 0;

  constructor(
    private kbService: KnowledgeBaseService,
    private featureFlagService: FeatureFlagService
  ) {
    this.activityStateManager = new ActivityStateManager(
      featureFlagService.isFlagEnabled(FeatureFlags.USE_KB)
        ? this.kbService.kb
        : undefined
    );
  }

  setStoragePrefix(): void {
    if (this.featureFlagService.isFlagEnabled(FeatureFlags.CONTENT_TESTING)) {
      this.activityStateManager.storagePrefix = 'CT_';
    } else if (
      this.featureFlagService.isFlagEnabled(FeatureFlags.EARLY_ACCESS)
    ) {
      this.activityStateManager.storagePrefix = 'EA_';
    } else {
      this.activityStateManager.storagePrefix = 'DEV_';
    }
  }

  async saveActivity(activityState: IncompleteActivityState): Promise<void> {
    return this.activityStateManager.saveIncompleteActivity(activityState);
  }

  async removeActivity(activityId: string): Promise<void> {
    return this.activityStateManager.removeIncompleteActivity(activityId);
  }

  /**
   * Saves the given activity state to knowledge base if useKB flag is enabled, otherwise saves to local storage
   * @param activityState activity state to save
   */
  saveActivityState(activityState: IncompleteActivityState): void {
    this.activityStateManager.saveIncompleteActivityState(activityState);
  }

  removeActivityState(activityId: string): void {
    this.activityStateManager.removeIncompleteActivityState(activityId);
  }

  /**
   * Add activityId to the saved id list if it's not there already
   * @param activityId ID of the activity we're saving
   */
  async addSavedActivityId(activityId: string): Promise<void> {
    return this.activityStateManager.addSavedActivityId(activityId);
  }

  async removeSavedActivityId(activityId: string): Promise<void> {
    return this.activityStateManager.removeSavedActivityId(activityId);
  }

  setSavedActivityIds(newIds: string[]): void {
    this.activityStateManager.setSavedActivityIds(newIds);
  }

  appendSavedActivityId(activityId: string, existingIds: string[]): string[] {
    return this.activityStateManager.appendSavedActivityId(
      activityId,
      existingIds
    );
  }

  removeActivityId(activityId: string, existingIds: string[]): string[] {
    return this.activityStateManager.removeActivityId(activityId, existingIds);
  }

  /**
   * Export the current activity's state
   * @returns IncompleteActivityState representing current activity state
   */
  /**
   * Get string array of activity id's we have activity states saved for.
   * @returns String array representing all id's we have activity states saved for
   */
  async getSavedActivityIds(): Promise<string[]> {
    return this.activityStateManager.getSavedActivityIds();
  }

  /**
   * Gets the saved state data from the passed in activity Id if it exists
   * @param activityId The id of the activity we want to get the saved state for
   * @returns IncompleteActivityState if it exists, undefined if it doesn't
   */
  async getSavedActivityState(
    activityId: string
  ): Promise<IncompleteActivityState> {
    return this.activityStateManager.getSavedActivityState(activityId);
  }

  /**
   * Gets an array of all saved activity states
   * @returns Array of IncompleteActivityState objects for all saved states
   */
  async getSavedActivityStates(): Promise<IncompleteActivityState[]> {
    return this.activityStateManager.getSavedActivityStates();
  }

  /**
   * Creates an activity state on the first step based on the activity provided
   * @param activity The activity to create the default activity state for
   * @returns An Activity state on the first step
   */
  createDefaultActivityState(activity: Activity): IncompleteActivityState {
    return {
      id: activity.id,
      currentStep: -1,
      numPerfect: 0,
      numCorrect: 0,
      numIncorrect: 0,
      questionNumber: 0,
      answerStates: [],
      populationResponses: [],
      progressNodes: this.createProgressNodesFromManifest(
        activity.activityManifest
      ),
      elapsedTime: 0,
      isAssignment: false,
      currentActivityOid: -1,
    };
  }

  private createProgressNodesFromManifest(
    manifest: ActivityManifestObject[]
  ): IProgressNodeData[] {
    const nodes: IProgressNodeData[] = [];
    this.activityCreationIndex = 0;
    this.activityCreationQuestionIndex = 0;
    for (let i = 0; i < manifest.length; i++) {
      const node = this.getNodeFromManifestObject(
        manifest[i],
        i === 0 ? ProgressNodeFocusEnum.active : ProgressNodeFocusEnum.inactive,
        ProgressNodeStatusEnum.none
      );
      if (node) {
        nodes.push(node);
      }
    }
    return nodes;
  }

  private getNodeFromManifestObject(
    manifestObject: ActivityManifestObject,
    index: number,
    focus?: number,
    status?: number
  ): IProgressNodeData | undefined {
    switch (manifestObject.type) {
      case ActivityObjectTypes.VIDEO:
        return {
          index: this.activityCreationIndex++,
          type: ProgressNodeTypeEnum.videoNodeComp,
          data: {},
          focus: focus || ProgressNodeFocusEnum.inactive,
          status: 0,
        };
      case ActivityObjectTypes.QUESTION: {
        const currIndex = this.activityCreationQuestionIndex + 1;
        this.activityCreationQuestionIndex++;
        return {
          index: this.activityCreationIndex++,
          type: ProgressNodeTypeEnum.progressNodeComp,
          data: { text: currIndex.toString() },
          focus: focus || ProgressNodeFocusEnum.inactive,
          status: status || ProgressNodeStatusEnum.none,
        };
      }
      default:
        return undefined;
    }
  }
}
