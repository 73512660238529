import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FeatureFlagService } from 'src/app/modules/feature-flags/services/feature-flag/feature-flag.service';
import { KnowledgeBaseService } from 'src/app/modules/olp-api/services/kb/kb.service';
import { FontSize, MessageCodes } from '../../enums';
import { Settings } from '../../interfaces';
import { UserService } from '../user/user.service';
import { MessageService } from 'src/app/modules/message/services/message/message.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService implements OnDestroy {
  readonly KB_SECTION = 'HTMLActivityStorage';
  readonly KB_KEY = 'Settings';
  readonly STARTER_SETTINGS: Settings = {
    subtitlesOn: false,
    subtitlesFontSize: FontSize.MEDIUM,
    readAloud: false,
    muteSfx: false,
  };
  readonly MAX_READ_ALOUD_ENABLE_GRADE = 2;
  SAVE_TIMEOUT = 500;
  settings = new BehaviorSubject<Settings>({
    ...this.STARTER_SETTINGS,
  });
  sub!: Subscription;
  postTimeOut!: NodeJS.Timeout;

  constructor(
    private kbService: KnowledgeBaseService,
    private featureFlagService: FeatureFlagService,
    private userService: UserService,
    private messageService: MessageService
  ) {
    this.sub = new Subscription();
    this.sub.add(
      this.userService.grade$.subscribe(grade => {
        if (!this.settings.getValue().readAloud) {
          this.settings.next({
            ...this.settings.getValue(),
            readAloud: grade <= this.MAX_READ_ALOUD_ENABLE_GRADE,
          });
        }
      })
    );
  }

  // Reference: https://github.com/istation-hydra/olp-student-experience/blob/develop/projects/stage/src/app/app.module.ts
  async initSettings(): Promise<void> {
    if (this.featureFlagService.isFlagEnabled('useKB')) {
      console.log(`we're getting settings from kb!`);
      const kbSettings = (await this.kbService.get(
        this.KB_SECTION,
        this.KB_KEY
      )) as Settings;

      if (kbSettings) {
        this.settings.next({ ...this.settings.getValue(), ...kbSettings });
      }
    }

    console.log('INIT - Settings: ', this.settings.getValue());
  }

  saveSettings(settings?: Settings): void {
    if (settings) {
      this.settings.next({ ...this.settings.getValue(), ...settings });
    }

    // save settings
    if (this.featureFlagService.isFlagEnabled('useKB')) {
      // delay saving so can't spam toggle saving
      clearTimeout(this.postTimeOut);
      this.postTimeOut = setTimeout(() => {
        this.kbService
          .setAndSync(this.KB_SECTION, this.KB_KEY, this.settings.getValue())
          .then(() => {
            console.log(
              'SUCCESS - Saved Settings to kb:',
              this.settings.getValue()
            );
          })
          .catch((error: Response) => {
            if (error.status === 409) {
              this.messageService.showMessage(
                MessageCodes.HTTP_UNEXPECTED_NET_ERROR
              );
            }
            console.log(
              'ERROR - Failed to save kb settings:',
              this.settings.getValue()
            );
          });
      }, this.SAVE_TIMEOUT);
    }
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
