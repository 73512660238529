import { Params } from '@angular/router';
import { Injectable } from '@angular/core';
import { ActivityService } from '..';
import { BehaviorSubject, Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  private params = new BehaviorSubject<Params>({});
  constructor(private activityService: ActivityService) {
    this.setParamsFromStorage();

    this.activityService.advanceActivityEvent.pipe(take(1)).subscribe(() => {
      const activityKeys = ['activityId', 'resume', 'isAssignment'];
      this.clearStorageParams(activityKeys); //when we've launched an activity we no longer need the info for the activity launch.
    });
  }

  /**
   * This function will .next the url service params state as well as setting the params into local storage
   * @param params params from a url
   */
  setParamsInStorage(params: Params): void {
    this.params.next(params);
    localStorage.setItem('urlParams', JSON.stringify(params));
  }

  getParams(): Observable<Params> {
    return this.params.asObservable();
  }

  /**
   * This function will clear the local storage of the given keys,
   * or if no keys are given, it will clear the entire urlParams object from local storage.
   *
   * @param {string[]} [keysToRemove] - The keys to remove from the local storage
   * */
  clearStorageParams(keysToRemove?: string[]): void {
    const params = localStorage.getItem('urlParams');
    if (params) {
      if (keysToRemove) {
        const parsedParams = JSON.parse(params);
        keysToRemove.forEach(key => delete parsedParams[key]);
        if (Object.keys(parsedParams).length > 0) {
          //if there is anything left in the parsed params
          localStorage.setItem('urlParams', JSON.stringify(parsedParams));
          return;
        }
      }
      localStorage.removeItem('urlParams');
    }
  }

  private setParamsFromStorage(): void {
    const params = JSON.parse(localStorage.getItem('urlParams') ?? '{}');
    if (params) {
      this.params.next({ ...this.params.value, ...params });
    }
  }
}
