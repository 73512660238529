import { IProgressNodeData } from 'src/app/modules/progress-bar/interfaces/progress-node-data.interface';
import { PopulationResponse } from '../../../shared/interfaces';
import { AnswerStateEnum } from '../../../shared/enums';
import { ISlideData } from 'src/app/modules/carousel/interfaces';

/**
 * This object is meant to represent the current state of the user in the application.
 *
 * The lifetime of this in the knowledge base should start once the user is at the video page,
 * and should be removed from the kb once the user has submitted the final data (CompleteActivityState)
 * after the final population is submitted.
 */

export interface IncompleteActivityState {
  id: string;
  currentStep: number;
  numPerfect: number;
  numCorrect: number;
  numIncorrect: number;
  questionNumber: number;
  answerStates: AnswerStateEnum[];
  populationResponses: PopulationResponse[];
  progressNodes: IProgressNodeData[];
  elapsedTime: number; // in seconds
  currTry?: number;
  filledAnswers?: (string | number)[][];
  selectedAnswers?: (string | number)[][];
  disabledAnswers?: (string | number)[][];
  curHintsRevealed?: ISlideData[];
  isAssignment: boolean;
  currentActivityOid: number;
  lastOpened?: string;
  activitySeed?: string;
}
