import { Pipe, PipeTransform } from '@angular/core';
import { ConfigurationService } from 'src/app/modules/configuration/services/configuration/configuration.service';
import { FeatureFlagService } from 'src/app/modules/feature-flags/services/feature-flag/feature-flag.service';
import { FeatureFlags } from '../../enums/feature-flags';

@Pipe({
  name: 'cdnSrcBaseUrl',
})
export class CdnSrcBaseUrlPipe implements PipeTransform {
  constructor(
    private featureFlagService: FeatureFlagService,
    private configService: ConfigurationService
  ) {}
  transform(value: string | undefined, force = false): string {
    if (!value) return '';
    if (
      this.featureFlagService.isFlagEnabled(FeatureFlags.CDN_ASSETS) ||
      force
    ) {
      return new URL(
        value,
        this.configService.config.assetCdnDomain
      ).toString();
    } else {
      return value;
    }
  }
}
