import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { FooterService } from '../../services/footer.service';
import { AudioDescriptor } from 'src/app/shared/enums/audio-descriptor';
import { AudioType } from 'src/app/shared/enums/audio-type';
import { PageTransitionService } from 'src/app/shared/services/page-transitions/page-transitions.service';

@Component({
  selector: 'htc-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  buttonEnabled = false;
  toolbarSubscriptions = new Subscription();
  clickDescriptor = AudioDescriptor.SELECT_MAJOR_LO;
  clickType = AudioType.audio_SfxClick;

  constructor(
    public footerService: FooterService,
    public pageTransitionsService: PageTransitionService
  ) {}

  ngOnInit(): void {
    this.toolbarSubscriptions.add(
      this.footerService.getFooterButtonEnabled().subscribe(isEnabled => {
        this.buttonEnabled = isEnabled;
      })
    );
  }

  ngOnDestroy(): void {
    this.toolbarSubscriptions.unsubscribe();
    this.footerService.cleanup();
  }
}
