import { ConfigurationService } from '../../../configuration/services/configuration/configuration.service';
import {
  KnowledgeBase,
  KnowledgeBaseModules,
  KnowledgeBaseStore,
  KnowledgeBaseStoreResponse,
  KnowledgeBaseValue,
} from '../../types/knowledge-base.interface';
import { APIHandler } from '../api-handler/api-handler';
import { Injectable } from '@angular/core';
import AutoQueue from 'src/app/shared/classes/auto-queue/auto-queue';
import jiff from 'jiff';

@Injectable()
export class KnowledgeBaseAPI implements KnowledgeBase {
  user_id = 25651;

  authToken = '';
  private pathHydrate = `sync`;
  private pathBase = `knowledge-base`;
  private kbCache?: KnowledgeBaseStore | null;
  private applicationState?: KnowledgeBaseStore | null; //the last known good state
  private apiHandler: APIHandler;
  private autoQueue = new AutoQueue();
  private inFlightRetrieve: Promise<KnowledgeBaseStoreResponse | null> | null =
    null;

  constructor(private configService: ConfigurationService) {
    this.apiHandler = new APIHandler({
      baseUrl: this.configService.config.olpApiDomain,
    });
  }

  setCcoVersion(version: number): void {
    if (this.kbCache) {
      this.kbCache.data.ccoVersion = version;
    }
  }

  setAuthToken(authToken: string): void {
    this.authToken = authToken;
    this.configureHandler();
  }

  configureHandler(): void {
    this.apiHandler.configure({
      baseUrl: this.configService.config.olpApiDomain,
      defaultHeaders: {
        Authorization: 'Bearer ' + this.authToken,
        'Content-Type': 'application/json',
      },
    });
  }

  async getAll(): Promise<KnowledgeBaseModules | null | undefined> {
    let returnValue = null;

    if (!this.kbCache) {
      await this.autoQueue
        .enqueueAQ(() => this.retrieve())
        .catch(error => {
          if (error.status === 404) {
            return this.createCleanKbAndSync();
          } else {
            return Promise.reject(error);
          }
        });
    }
    returnValue = this.kbCache?.data.modules;
    return returnValue;
  }

  async get(section: string, key: string): Promise<KnowledgeBaseValue> {
    let returnValue = null;

    if (!this.kbCache) {
      await this.autoQueue.enqueueAQ(() => this.retrieve());
    }

    const modules = this.getModules();
    if (modules && modules[section] && modules[section][key]) {
      returnValue = modules[section][key];
    }
    return returnValue;
  }

  set(section: string, key: string, value: KnowledgeBaseValue): void {
    const modules = this.getModules() || {};
    if (Object.prototype.hasOwnProperty.call(modules, section)) {
      modules[section][key] = value;
    } else {
      modules[section] = { [key]: value };
    }
    const ccoVersion = this.kbCache?.data.ccoVersion ?? 0;

    this.kbCache = {
      data: {
        ccoVersion,
        modules,
        removeSet: [],
      },
    };
  }

  createCleanKbAndSync(): Promise<unknown> {
    this.kbCache = {
      data: {
        ccoVersion: 0,
        modules: {},
        removeSet: [],
      },
    };
    const path = this.buildPath();
    return this.autoQueue.enqueueAQ(() =>
      this.apiHandler
        .put(path, {
          data: this.kbCache as KnowledgeBaseStore,
        })
        .then(res => {
          if (this.kbCache && res) {
            this.kbCache.data.ccoVersion = res.data.ccoVersion;
          }
        })
        .catch(async (err: Response) => {
          console.error(`couldn't create a clean kb`, err);
          throw err;
        })
    );
  }

  /**
   * DANGER ZONE
   * This will delete your entire kb if you sync after calling this, use with caution
   */
  deleteAll(): void {
    if (!this.kbCache) return;
    const keys = Object.keys(this.kbCache?.data.modules);
    const ccoVersion = this.kbCache?.data.ccoVersion ?? 0;
    this.kbCache = {
      data: {
        ccoVersion,
        modules: {},
        removeSet: keys,
      },
    };
  }

  delete(section: string, key: string): void {
    const modules = this.getModules() || {};
    if (Object.prototype.hasOwnProperty.call(modules, section)) {
      delete modules[section][key];
    }
    const ccoVersion = this.kbCache?.data.ccoVersion ?? 0;
    this.kbCache = {
      data: {
        ccoVersion,
        modules,
        removeSet: [],
      },
    };
  }

  async sync(): Promise<void> {
    if (!this.kbCache) {
      return Promise.reject('no kb cache to sync');
    }

    const path = this.buildPath();
    await this.autoQueue
      .enqueueAQ(() =>
        this.apiHandler.patch<KnowledgeBaseStoreResponse>(path, {
          data: this.kbCache as KnowledgeBaseStore,
        })
      )
      .then(res => {
        //res is actually of type PatchResponse
        if (this.kbCache && res) {
          this.kbCache.data.ccoVersion = res.data.ccoVersion;
          this.applicationState = structuredClone(this.kbCache);
        }
      })
      .catch(async (err: Response) => {
        if (err.status === 409) {
          const resolved = await this.resolveCcoConflict();
          if (!resolved) {
            throw err;
          }
        } else {
          throw err;
        }
      });
  }

  async resolveCcoConflict(): Promise<boolean> {
    const checkpointState = structuredClone(this.kbCache);
    const conflictedState = await this.autoQueue.enqueueAQ(() =>
      this.retrieve(true)
    );
    const applicatonState = structuredClone(this.applicationState);

    if (!conflictedState || !applicatonState || !checkpointState) {
      return false;
    }

    const remotePatch = jiff.diff(conflictedState, checkpointState);
    const localPatch = jiff.diff(applicatonState, checkpointState);
    let areSame = false;

    if (remotePatch.length > 0) {
      areSame = remotePatch.every((patch, i) => {
        return JSON.stringify(patch) === JSON.stringify(localPatch[i]);
      });
    } else {
      areSame = true;
    }

    if (!areSame) {
      return false;
    }

    const mergeState = jiff.patch(
      localPatch,
      conflictedState
    ) as KnowledgeBaseStore;
    if (mergeState && mergeState.data) {
      this.kbCache = mergeState;
      this.kbCache.data.ccoVersion = conflictedState.data.ccoVersion;
      const path = this.buildPath();
      if (this.kbCache) {
        await this.autoQueue
          .enqueueAQ(() =>
            this.apiHandler.put(path, {
              data: this.kbCache as KnowledgeBaseStore,
            })
          )
          .then(res => {
            if (this.kbCache && res) {
              this.kbCache.data.ccoVersion = res.data.ccoVersion;
              this.applicationState = structuredClone(this.kbCache);
              console.log('successfully updated after merge', res);
            }
          })
          .catch((res: Response) => {
            console.error('failed to patch after merge', res);
          });
      }
    } else {
      console.log('we dont have merge state and mergeState data', mergeState);
    }
    return true;
  }

  async setAndSync(
    section: string,
    key: string,
    value: KnowledgeBaseValue
  ): Promise<void> {
    this.set(section, key, value);
    return this.sync();
  }

  async retrieve(
    forceRefresh?: boolean
  ): Promise<KnowledgeBaseStoreResponse | null> {
    if (this.inFlightRetrieve === null) {
      if (!forceRefresh && this.kbCache) {
        return null;
      }
      const path = this.buildPath(false);
      this.inFlightRetrieve =
        this.apiHandler.get<KnowledgeBaseStoreResponse>(path);
      this.inFlightRetrieve.then(kb => {
        this.kbCache = kb;
        this.applicationState = structuredClone(kb);
        this.inFlightRetrieve = null;
      });
    }

    return this.inFlightRetrieve;
  }

  private getModules(): KnowledgeBaseModules | undefined {
    return this.kbCache?.data.modules;
  }

  private buildPath(hydrate: boolean = false): string {
    return `${this.pathBase}${hydrate ? '/' + this.pathHydrate : ''}/${
      this.user_id
    }`;
  }
}
