import videojs, { VideoJsPlayerOptions } from 'video.js';
import { ControlBarDefaultConfig, UserActionsDefaultConfig } from '.';

export class VideoDefaultConfig implements VideoJsPlayerOptions {
  bigPlayButton = true;
  controls = true;
  language = 'en';
  languages = {
    en: {},
    es: {
      Play: 'Iniciar',
      Pause: 'Pausa',
      'Current Time': 'Tiempo transcurrido',
      Duration: 'Duración',
      Replay: 'Reiniciar',
      Restart: 'Reiniciar',
      Mute: 'Desactivar sonido',
      Unmute: 'Activar sonido',
      'Skip Backward': 'Retroceder',
      'Skip Forward': 'Avanzar',
      'Closed Captions Menu': 'Menú de subtítulos cerrados',
      'Toggle Transcript': 'Ver transcripción',
      Fullscreen: 'Pantalla completa',
      'Exit Fullscreen': 'Salir de la pantalla completa',
      Settings: 'Configuración',
      Transcript: 'Transcripción',
      'Closed captions': 'Subtítulos cerrados',
      'Font size': 'Tamaño de la letra',
      'Black transparent box': 'Caja transparente negra',
      on: 'Encender',
      off: 'Apagar',
      'Small font size': 'Tamaño de letra pequeño',
      'Medium font size': 'Tamaño de letra mediano',
      'Large font size': 'Tamaño de letra grande',
      'Progress Bar': 'Barra de progreso',
      Selected: 'Seleccionado',
      'Closed captions icon': 'Ícono de subtítulos cerrados',
      'Transcript icon': 'Ícono de transcripción',
    },
  };
  autoplay = false;
  preload: videojs.Preload = 'auto';
  sources = [
    {
      src: '',
      type: 'video/mp4',
    },
  ];
  poster = '';
  controlBar = new ControlBarDefaultConfig();
  userActions = new UserActionsDefaultConfig();
  muted = false;
  techOrder = ['html5'];
  disablePictureInPicture = true;
}
