export enum TestAssociatedSuccessCriteria {
  SHORT_CONST_RESPONSE = 'shortconstructedresponse',
  SHORT_CONST_RESPONSE_RUBRIC = 'shortconstructedresponserubric',
  EXTENDED_CONST_RESPONSE = 'extendedconstructedresponse',
  EXTENDED_CONST_RESPONSE_RUBRIC = 'extendedconstructedresponserubric',
  DRAG_AND_DROP = 'draganddrop',
  INLINE_CHOICE = 'inlinechoice',
  MULTI_CHOICE_IMAGE = 'multichoice-image',
  MULTI_CHOICE = 'multichoice',
  MULTI_SELECT_IMAGE = 'multiselect-image',
  MULTI_SELECT = 'multiselect',
  TEXT_ENTRY = 'textentry',
  MATH_SHORT_CONSTRUCTED_RESPONSE = 'mathshortconstructedresponse',
  MATH_SHORT_CONSTRUCTED_RESPONSE_WITH_RUBRIC = 'mathshortconstructedresponserubric',
  MATH_EXTENDED_CONSTRUCTED_RESPONSE = 'mathextendedconstructedresponse',
  MATH_EXTENDED_CONSTRUCTED_RESPONSE_WITH_RUBRIC = 'mathextendedconstructedresponserubric',
  MULTIPLE_VIDEOS = 'multiplevideos',
  MULTIPLE_TRIES = 'multipletries',
}
