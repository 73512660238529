<button
  class="node-container video-node-container circle"
  [ngClass]="!disabled ? focusClass + ' enabled' : focusClass"
  data-testid="htc-video-node-container"
  [tabIndex]="!disabled ? 0 : -1"
  (click)="onNodeClicked()"
  [disabled]="disabled">
  <img
    class="center"
    [src]="path + imageUrl"
    [alt]="'PROGRESS_BAR.VIDEO_ICON' | translate"
    data-testid="node-img" />
</button>
