import { Activity } from 'src/app/shared/interfaces';

export const lectura5: Activity = {
  id: '018ea019-061e-7889-81a8-ca79b4c10235',
  product: 'Lectura',
  language: 'es',
  skillName: 'Preposiciones',
  thumbnail: {
    id: 'recHVWG9D2HGASZbH',
    url: '/helix/UX_Images/Lesson_Posters/Prepositions_G4V2_Poster.jpg',
    altText: [
      {
        language: 'en',
        altText: 'Axis and CAT look at some geodes on the cockpit screen.',
      },
      {
        language: 'es',
        altText:
          'Axis y G.A.T.A. observan las mismas rocas galácticas en la pantalla de la cabina de mando.',
      },
    ],
  },
  instructionsImage: {
    id: 'recqnzfDjMQK7N7kr',
    url: '/helix/UX_Images/Characters/CAT/CAT_Chibi_Happy_Eyes_Open_Mouth_Open.svg',
    altText: [
      { language: 'en', altText: 'CAT is smiling.' },
      { language: 'es', altText: 'G.A.T.A. está sonriendo.' },
    ],
  },
  characterLeanout: {
    id: 'rec5Rylxn2J2DNIHO',
    url: '/helix/TEI/Leanouts/CAT_Leanout_01.svg',
    altText: [
      { language: 'en', altText: 'CAT leans out from the side of the screen.' },
      {
        language: 'es',
        altText: 'G.A.T.A. se asoma por el lado de la pantalla.',
      },
    ],
  },
  description:
    'Axis aprende sobre las contracciones mientras usa las preposiciones y locuciones preposicionales.',
  domain: { text: 'Categorías gramaticales' },
  seriesName: 'Planeta preposicional',
  episodeNumber: '2',
  episodeTitle: 'Contracciones cósmicas',
  videos: [
    {
      id: 'rectvtaUzJ0KO1Ht4',
      url: '/helix/Lectura/Grade_4/Prepositions/Episode_02/hls_streams/L_G4_Prepositions_Ep02_Video.m3u8',
      posterURL: '/helix/UX_Images/Lesson_Posters/Prepositions_G4V2_Poster.jpg',
      captions: [
        {
          src: '/helix/Lectura/Grade_4/Prepositions/Episode_02/L_G4_Prepositions_Ep02_Video.vtt',
          srclang: 'es',
          mode: 'hidden',
          kind: 'captions',
        },
      ],
    },
  ],
  startScreenData: {
    learningTarget: [
      {
        id: '32684.B',
        learningTarget: {
          text: 'usar las preposiciones y formar contracciones preposicionales',
        },
        successCriteria: [
          {
            id: '32684.B.1',
            text: 'usar las preposiciones para indicar lugar, tiempo y causa',
          },
          { id: '32684.B.2', text: 'formar contracciones preposicionales' },
        ],
      },
    ],
    background_leftImage: {
      id: 'recHVWG9D2HGASZbH',
      url: '/helix/UX_Images/Lesson_Posters/Prepositions_G4V2_Poster.jpg',
      altText: [
        {
          language: 'en',
          altText: 'Axis and CAT look at some geodes on the cockpit screen.',
        },
        {
          language: 'es',
          altText:
            'Axis y G.A.T.A. observan las mismas rocas galácticas en la pantalla de la cabina de mando.',
        },
      ],
    },
    background_rightImage: {
      id: 'recxjC353VMav5ybu',
      url: '/helix/UX_Images/Start_Screen_Blobs/Nova-Scouts_space-sky.svg',
      altText: [
        { language: 'en', altText: 'Stars in space.' },
        { language: 'es', altText: 'Estrellas en el espacio.' },
      ],
    },
    foreground: {
      id: 'recOn2Luhc6XMnbRL',
      url: '/helix/UX_Images/Start_Screen_Characters/Cat5.svg',
      altText: [
        { language: 'en', altText: 'CAT is pouncing.' },
        { language: 'es', altText: 'G.A.T.A. está saltando.' },
      ],
    },
  },
  populations: [
    {
      id: '32684.B.1.1',
      templateID: 0,
      complexity: 'Comprehension',
      hint: [
        {
          id: '32684.B.1.1.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recRORCPGztEFmN5w',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_02.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y sus usos.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: '¿Qué tipo de preposición se usa en la oración?\nComo mi merienda a las tres de la tarde.',
        },
        answerChoices: [
          { id: '32684.B.1.1_1', choice: { texts: [{ text: 'tiempo' }] } },
          {
            id: '32684.B.1.1_2',
            choice: { texts: [{ text: 'lugar' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'En esta oración, la preposición «a» no da información sobre el lugar.',
              },
            },
          },
          {
            id: '32684.B.1.1_3',
            choice: { texts: [{ text: 'causa' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'En esta oración, la preposición «a» no da información sobre la causa.',
              },
            },
          },
        ],
        answers: ['32684.B.1.1_1'],
        associatedSuccessCriteria: ['32684.B.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La preposición «a» indica la hora del día en la que se come la merienda.',
      },
    },
    {
      id: '32684.B.1.2',
      templateID: 0,
      complexity: 'Comprehension',
      hint: [
        {
          id: '32684.B.1.2.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recRORCPGztEFmN5w',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_02.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y sus usos.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: '¿Qué tipo de preposición se usa en la oración?\nEl libro está en la mesa.',
        },
        answerChoices: [
          { id: '32684.B.1.2_1', choice: { texts: [{ text: 'lugar' }] } },
          {
            id: '32684.B.1.2_2',
            choice: { texts: [{ text: 'causa' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'En esta oración, la preposición «en» no da información sobre la causa.',
              },
            },
          },
          {
            id: '32684.B.1.2_3',
            choice: { texts: [{ text: 'tiempo' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'En esta oración, la preposición «en» no da información sobre el tiempo.',
              },
            },
          },
        ],
        answers: ['32684.B.1.2_1'],
        associatedSuccessCriteria: ['32684.B.1'],
      },
      feedbackSecondIncorrect: {
        content: 'La preposición «en» indica donde está el libro.',
      },
    },
    {
      id: '32684.B.1.3',
      templateID: 0,
      complexity: 'Comprehension',
      hint: [
        {
          id: '32684.B.1.3.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recRORCPGztEFmN5w',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_02.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y sus usos.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: '¿Qué tipo de preposición se usa en la oración?\nLos patos marchan hacia el lago.',
        },
        answerChoices: [
          { id: '32684.B.1.3_1', choice: { texts: [{ text: 'lugar' }] } },
          {
            id: '32684.B.1.3_2',
            choice: { texts: [{ text: 'tiempo' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'En esta oración, la preposición «hacia» no da información sobre el tiempo.',
              },
            },
          },
          {
            id: '32684.B.1.3_3',
            choice: { texts: [{ text: 'causa' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'En esta oración, la preposición «hacia» no da información sobre la causa.',
              },
            },
          },
        ],
        answers: ['32684.B.1.3_1'],
        associatedSuccessCriteria: ['32684.B.1'],
      },
      feedbackSecondIncorrect: {
        content: 'La preposición «hacia» indica adonde marchan los patos.',
      },
    },
    {
      id: '32684.B.1.4',
      templateID: 0,
      complexity: 'Comprehension',
      hint: [
        {
          id: '32684.B.1.4.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recRORCPGztEFmN5w',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_02.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y sus usos.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: {
          text: '¿Qué tipo de preposición se usa en la oración?\nSiempre me duermo a las nueve de la noche.',
        },
        answerChoices: [
          { id: '32684.B.1.4_1', choice: { texts: [{ text: 'tiempo' }] } },
          {
            id: '32684.B.1.4_2',
            choice: { texts: [{ text: 'causa' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'En esta oración, la preposición «a» no da información sobre la causa.',
              },
            },
          },
          {
            id: '32684.B.1.4_3',
            choice: { texts: [{ text: 'lugar' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'En esta oración, la preposición «a» no da información sobre el lugar.',
              },
            },
          },
        ],
        answers: ['32684.B.1.4_1'],
        associatedSuccessCriteria: ['32684.B.1'],
      },
      feedbackSecondIncorrect: {
        content: 'La preposición «a» indica la hora en que se duerme.',
      },
    },
    {
      id: '32684.B.1.5',
      templateID: 5,
      complexity: 'Application',
      hint: [
        {
          id: '32684.B.1.5.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recRORCPGztEFmN5w',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_02.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y sus usos.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: { text: '¿Qué preposiciones indican lugar?' },
        answerChoices: [
          { id: '32684.B.1.5_1', choice: { texts: [{ text: 'de' }] } },
          { id: '32684.B.1.5_2', choice: { texts: [{ text: 'entre' }] } },
          { id: '32684.B.1.5_3', choice: { texts: [{ text: 'hacia' }] } },
          {
            id: '32684.B.1.5_4',
            choice: { texts: [{ text: 'por' }] },
            targetedFeedback: {
              firstIncorrect: {
                content: '«Por» es una preposición de causa; no de lugar.',
              },
            },
          },
          {
            id: '32684.B.1.5_5',
            choice: { texts: [{ text: 'con' }] },
            targetedFeedback: {
              firstIncorrect: {
                content: '«Con» es una preposición de tiempo; no de lugar.',
              },
            },
          },
          {
            id: '32684.B.1.5_6',
            choice: { texts: [{ text: 'después' }] },
            targetedFeedback: {
              firstIncorrect: {
                content: '«Después» es una preposición de tiempo; no de lugar.',
              },
            },
          },
        ],
        answers: ['32684.B.1.5_1', '32684.B.1.5_2', '32684.B.1.5_3'],
        associatedSuccessCriteria: ['32684.B.1'],
      },
      feedbackSecondIncorrect: {
        content:
          '«De», «entre», y «hacia» son preposiciones que indican dónde está algo.',
      },
    },
    {
      id: '32684.B.1.6',
      templateID: 5,
      complexity: 'Application',
      hint: [
        {
          id: '32684.B.1.6.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recRORCPGztEFmN5w',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_02.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y sus usos.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        question: { text: '¿Qué preposiciones indican tiempo?' },
        answerChoices: [
          { id: '32684.B.1.6_1', choice: { texts: [{ text: 'con' }] } },
          { id: '32684.B.1.6_2', choice: { texts: [{ text: 'desde' }] } },
          { id: '32684.B.1.6_3', choice: { texts: [{ text: 'sobre' }] } },
          {
            id: '32684.B.1.6_4',
            choice: { texts: [{ text: 'entre' }] },
            targetedFeedback: {
              firstIncorrect: {
                content: '«Entre» es una preposición de lugar; no de tiempo.',
              },
            },
          },
          {
            id: '32684.B.1.6_5',
            choice: { texts: [{ text: 'por' }] },
            targetedFeedback: {
              firstIncorrect: {
                content: '«Por» es una preposición de causa; no de tiempo.',
              },
            },
          },
          {
            id: '32684.B.1.6_6',
            choice: { texts: [{ text: 'hacia' }] },
            targetedFeedback: {
              firstIncorrect: {
                content: '«Hacia» es una preposición de lugar; no de tiempo.',
              },
            },
          },
        ],
        answers: ['32684.B.1.6_1', '32684.B.1.6_2', '32684.B.1.6_3'],
        associatedSuccessCriteria: ['32684.B.1'],
      },
      feedbackSecondIncorrect: {
        content:
          '«Con», «desde» y «sobre» son preposiciones de tiempo que indican cuándo ocurre algo.',
      },
    },
    {
      id: '32684.B.1.7',
      templateID: 1,
      complexity: 'Application',
      hint: [
        {
          id: '32684.B.1.7.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recRORCPGztEFmN5w',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_02.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y sus usos.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Elige la MEJOR locución preposicional que indique tiempo:\nSiempre cenamos en familia {CHOICE_TOKEN} las 7:30.',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                { id: '32684.B.1.7_1', text: 'antes de' },
                {
                  id: '32684.B.1.7_2',
                  text: 'al lado de',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'La locución preposicional «al lado de» no da información sobre el tiempo.',
                    },
                  },
                },
                {
                  id: '32684.B.1.7_3',
                  text: 'debajo de',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'La locución preposicional «debajo de» no da información sobre el tiempo.',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['32684.B.1.7_1'],
        associatedSuccessCriteria: ['32684.B.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La locución preposicional «antes de» da información sobre la hora en que cenan.',
      },
    },
    {
      id: '32684.B.1.8',
      templateID: 1,
      complexity: 'Application',
      hint: [
        {
          id: '32684.B.1.8.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recRORCPGztEFmN5w',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_02.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y sus usos.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Elige la MEJOR locución preposicional que indique lugar:\nMi mamá nos dejó {CHOICE_TOKEN} la escuela.',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                { id: '32684.B.1.8_1', text: 'enfrente de' },
                {
                  id: '32684.B.1.8_2',
                  text: 'acerca de',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'La locución preposicional «acerca de» no da información sobre el lugar.',
                    },
                  },
                },
                {
                  id: '32684.B.1.8_3',
                  text: 'después de',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'La locución preposicional «después de» no da información sobre el lugar.',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['32684.B.1.8_1'],
        associatedSuccessCriteria: ['32684.B.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La locución preposicional «enfrente de» da información sobre dónde los dejó mamá.',
      },
    },
    {
      id: '32684.B.2.9',
      templateID: 3,
      complexity: 'Synthesis',
      hint: [
        {
          id: '32684.B.2.9.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recPYFL3XW5DEIYTH',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_05.svg',
              altText: [
                {
                  language: 'en',
                  altText: 'The contractions al and del are explained.',
                },
                {
                  language: 'es',
                  altText: 'Se explican las contracciones al y del.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Forma la contracción correcta con las palabras «a el». \nLos niños van {WORD_TOKEN} parque.',
          },
        ],
        answers: [['al']],
      },
      feedbackSecondIncorrect: {
        content:
          '«Al» es la contracción correcta de la preposición «a» y el artículo «el».',
      },
    },
    {
      id: '32684.B.2.10',
      templateID: 3,
      complexity: 'Synthesis',
      hint: [
        {
          id: '32684.B.2.10.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recPYFL3XW5DEIYTH',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_05.svg',
              altText: [
                {
                  language: 'en',
                  altText: 'The contractions al and del are explained.',
                },
                {
                  language: 'es',
                  altText: 'Se explican las contracciones al y del.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Forma la contracción correcta con las palabras «de el».  \nMi mamá regresó {WORD_TOKEN} supermercado.',
          },
        ],
        answers: [['del']],
      },
      feedbackSecondIncorrect: {
        content:
          '«Del» es la contracción correcta de la preposición «de» y el artículo «el».',
      },
    },
  ],
  nextUp: {
    nextActivityId: '018ea019-0b4f-76b9-bf59-c2634d04107f',
    nextActivityName: 'Mochila propulsora',
    nextActivityThumbnail: {
      id: 'recQxSYyb8gpq3Amg',
      url: '/helix/UX_Images/Lesson_Posters/Prepositions_G4V3_Poster.jpg',
      altText: [
        {
          language: 'en',
          altText: 'Axis holds up moldy pizza in the Space Lounge.',
        },
        {
          language: 'es',
          altText: 'Axis levanta una pizza podrida de la sala espacial.',
        },
      ],
    },
  },
  activityManifest: [
    { type: 'START_SCREEN' },
    { type: 'ENTRY_SURVEY' },
    { type: 'VIDEO', id: 'rectvtaUzJ0KO1Ht4' },
    { type: 'QUESTION', id: '32684.B.1.1' },
    { type: 'QUESTION', id: '32684.B.1.2' },
    { type: 'QUESTION', id: '32684.B.1.3' },
    { type: 'QUESTION', id: '32684.B.1.4' },
    { type: 'QUESTION', id: '32684.B.1.5' },
    { type: 'QUESTION', id: '32684.B.1.6' },
    { type: 'QUESTION', id: '32684.B.1.7' },
    { type: 'QUESTION', id: '32684.B.1.8' },
    { type: 'QUESTION', id: '32684.B.2.9' },
    { type: 'QUESTION', id: '32684.B.2.10' },
    { type: 'EXIT_SURVEY' },
    { type: 'RESULTS_SCREEN' },
  ],
  version: '1984019016',
};
