import { Activity } from 'src/app/shared/interfaces';

export const lectura2: Activity = {
  id: '018ea019-04a4-72ba-bdbf-64a85e6f9bd5',
  product: 'Lectura',
  language: 'es',
  skillName: 'Preposiciones',
  thumbnail: {
    id: 'recygZeNHb67g46Je',
    url: '/helix/UX_Images/Start_Screen_Blobs/Prepositions_G3V2_Poster.jpg',
    altText: [
      {
        language: 'en',
        altText: 'Axis floats in space outside his spaceship.',
      },
      {
        language: 'es',
        altText: 'Axis flota en el espacio afuera de su nave espacial.',
      },
    ],
  },
  instructionsImage: {
    id: 'recShqii4tO2JCQyf',
    url: '/helix/UX_Images/Characters/Axis/Axis_Chibi_Head_Happy_Mouth_Open.svg',
    altText: [
      { language: 'en', altText: 'Axis is smiling.' },
      { language: 'es', altText: 'Axis está sonriendo.' },
    ],
  },
  characterLeanout: {
    id: 'recBBGySIgxHLnC6k',
    url: '/helix/TEI/Leanouts/Axis_Leanout_01.svg',
    altText: [
      {
        language: 'en',
        altText: 'Axis leans out from the side of the screen.',
      },
      { language: 'es', altText: 'Axis se asoma por el lado de la pantalla.' },
    ],
  },
  description:
    'Luego de la caída de los escudos de la nave, Axis y G.A.T.A  deben correr en contra de las agujas del reloj para repararlos. Saber cómo usar las preposiciones en contexto les ayudará a evitar chocar contra la cola de un cometa.',
  domain: { text: 'Parts of Speech' },
  seriesName: 'Misión terrestre',
  episodeNumber: '2',
  episodeTitle: '¡Cometa a la vista!',
  videos: [
    {
      id: 'rec51cnmyN2qdaPHY',
      url: '/helix/Lectura/Grade_3/Prepositions/Episode_02/hls_streams/L_G3_Prepositions_Ep02_Video01.m3u8',
      posterURL:
        '/helix/UX_Images/Start_Screen_Blobs/Prepositions_G3V2_Poster.jpg',
      captions: [
        {
          src: '/helix/Lectura/Grade_3/Prepositions/Episode_02/L_G3_Prepositions_Ep02_Video01.vtt',
          srclang: 'es',
          mode: 'hidden',
          kind: 'captions',
        },
      ],
    },
  ],
  startScreenData: {
    learningTarget: [
      {
        id: '32597.B',
        learningTarget: {
          text: 'a identificar una preposposición y una locución o frase preposicional',
        },
        successCriteria: [
          {
            id: '32597.B.1',
            text: 'encontrar preposiciones y locuciones o frases preposicionales por sí solas',
          },
          {
            id: '32597.B.2',
            text: 'encontrar preposiciones y locuciones o frases preposicionales en una oración',
          },
        ],
      },
    ],
    background_leftImage: {
      id: 'recygZeNHb67g46Je',
      url: '/helix/UX_Images/Start_Screen_Blobs/Prepositions_G3V2_Poster.jpg',
      altText: [
        {
          language: 'en',
          altText: 'Axis floats in space outside his spaceship.',
        },
        {
          language: 'es',
          altText: 'Axis flota en el espacio afuera de su nave espacial.',
        },
      ],
    },
    background_rightImage: {
      id: 'recxjC353VMav5ybu',
      url: '/helix/UX_Images/Start_Screen_Blobs/Nova-Scouts_space-sky.svg',
      altText: [
        { language: 'en', altText: 'Stars in space.' },
        { language: 'es', altText: 'Estrellas en el espacio.' },
      ],
    },
    foreground: {
      id: 'recJFZiQMxFozF9ks',
      url: '/helix/UX_Images/Start_Screen_Characters/Axis3.svg',
      altText: [
        { language: 'en', altText: 'Axis is pointing at something.' },
        { language: 'es', altText: 'Axis está señalando algo.' },
      ],
    },
  },
  populations: [
    {
      id: '32597.B.2.2',
      templateID: 0,
      complexity: 'Knowledge',
      hint: [
        {
          id: '32597.B.2.2.0',
          type: 'BasicSlide',
          data: {
            title:
              'Recuerda que las preposiciones son palabras que no tienen ni género ni número.',
          },
        },
        {
          id: '32597.B.2.2.1',
          type: 'BasicSlide',
          data: {
            title: 'Las preposiciones están formadas por una sola palabra.',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Identifica la preposición en la oración «Nosotros navegamos en un barco este verano».',
        },
        answerChoices: [
          { id: '32597.B.2.2_1', choice: { texts: [{ text: 'en' }] } },
          {
            id: '32597.B.2.2_2',
            choice: { texts: [{ text: 'un' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Esto es un artículo. Una preposicion te indica el lugar, tiempo o causa.',
              },
            },
          },
          {
            id: '32597.B.2.2_3',
            choice: { texts: [{ text: 'nosotros navegamos' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Recuerda que las preposiciones están formadas por una sola palabra.',
              },
            },
          },
        ],
        answers: ['32597.B.2.2_1'],
        associatedSuccessCriteria: ['32597.B.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'La respuesta correcta es «en» porque es la única palabra que indica lugar en la oración.',
      },
    },
    {
      id: '32597.B.2.1',
      templateID: 0,
      complexity: 'Knowledge',
      hint: [
        {
          id: '32597.B.2.1.0',
          type: 'BasicSlide',
          data: {
            title:
              'Recuerda que las preposiciones son palabras que no tienen ni género ni número.',
          },
        },
        {
          id: '32597.B.2.1.1',
          type: 'BasicSlide',
          data: {
            title: 'Las preposiciones están formadas por una sola palabra.',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Identifica la preposición en la oración «Tomás tomó un sorbo de su bebida».',
        },
        answerChoices: [
          { id: '32597.B.2.1_1', choice: { texts: [{ text: 'de' }] } },
          {
            id: '32597.B.2.1_2',
            choice: { texts: [{ text: 'un' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Esto es un artículo. Una preposición te indica el lugar, tiempo o causa.',
              },
            },
          },
          {
            id: '32597.B.2.1_3',
            choice: { texts: [{ text: 'Tomas tomó' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Recuerda que las preposiciones estan formadas por una sola palabra.',
              },
            },
          },
        ],
        answers: ['32597.B.2.1_1'],
        associatedSuccessCriteria: ['32597.B.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'La respuesta correcta es «de» porque es la única palabra que forma parte de las preposiciones en la oración.',
      },
    },
    {
      id: '32597.B.2.3',
      templateID: 0,
      complexity: 'Knowledge',
      hint: [
        {
          id: '32597.B.2.3.0',
          type: 'BasicSlide',
          data: {
            title:
              'Recuerda que las preposiciones son palabras que no tienen ni género ni número.',
          },
        },
        {
          id: '32597.B.2.3.1',
          type: 'BasicSlide',
          data: {
            title:
              'Una preposición de lugar nos indica el lugar en dónde se encuentra un objeto.',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Identifica la preposición en la oración «Mi perro estaba escondido bajo la mesa».',
        },
        answerChoices: [
          { id: '32597.B.2.3_1', choice: { texts: [{ text: 'bajo' }] } },
          {
            id: '32597.B.2.3_2',
            choice: { texts: [{ text: 'mi' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Esto es un pronombre. Una preposicion te indica el lugar, tiempo o causa.',
              },
            },
          },
          {
            id: '32597.B.2.3_3',
            choice: { texts: [{ text: 'la' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Esto es un artículo. Recuerda una preposición de lugar te dice donde está el sujeto.',
              },
            },
          },
        ],
        answers: ['32597.B.2.3_1'],
        associatedSuccessCriteria: ['32597.B.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'La respuesta correcta es «bajo» porque en la oración es la única palabra que indica el lugar en donde se ecuentra el sujeto.',
      },
    },
    {
      id: '32597.B.2.4',
      templateID: 0,
      complexity: 'Knowledge',
      hint: [
        {
          id: '32597.B.2.4.0',
          type: 'BasicSlide',
          data: {
            title:
              'Las locuciones preposicionales están formadas por dos o más palabras que funcionan como una preposición.',
          },
        },
        {
          id: '32597.B.2.4.1',
          type: 'BasicSlide',
          data: {
            title:
              'Busca la expresión de dos o más palabras con la misma función de una preposición.',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Identifica la locución o frase preposicional en la oración \n«Me gusta caminar a la escuela junto con mi mejor amigo».',
        },
        answerChoices: [
          { id: '32597.B.2.4_1', choice: { texts: [{ text: 'junto con' }] } },
          {
            id: '32597.B.2.4_2',
            choice: { texts: [{ text: 'me gusta' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Recuerda, las locuciones preposicionales no tienen verbos.',
              },
            },
          },
          {
            id: '32597.B.2.4_3',
            choice: { texts: [{ text: 'amigo' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Recuerda que las locuciones preposicionales están formadas por dos o más palabras.',
              },
            },
          },
        ],
        answers: ['32597.B.2.4_1'],
        associatedSuccessCriteria: ['32597.B.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'La respuesta correcta es «junto con» porque es la única expresión formada por dos palabras que funcionan como una preposición en la oración.',
      },
    },
    {
      id: '32597.B.2.5',
      templateID: 0,
      complexity: 'Knowledge',
      hint: [
        {
          id: '32597.B.2.5.0',
          type: 'BasicSlide',
          data: {
            title:
              'Las locuciones preposicionales están formadas por dos o más palabras que funcionan como una preposición.',
          },
        },
        {
          id: '32597.B.2.5.1',
          type: 'BasicSlide',
          data: {
            title:
              'Busca la expresión de dos o más palabras con la misma función de una preposición.',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Identifica la locución o frase preposicional en la oración \n«El autobús escolar pasa frente a mi casa».',
        },
        answerChoices: [
          { id: '32597.B.2.5_1', choice: { texts: [{ text: 'frente a' }] } },
          {
            id: '32597.B.2.5_2',
            choice: { texts: [{ text: 'mi casa' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Las locuciones preposicionales no tienen sustantivos.',
              },
            },
          },
          {
            id: '32597.B.2.5_3',
            choice: { texts: [{ text: 'el autobús' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Este es el sujeto de la oración. Recuerda una locucion preposicional da informacion sobre el lugar tiempo o causa.',
              },
            },
          },
        ],
        answers: ['32597.B.2.5_1'],
        associatedSuccessCriteria: ['32597.B.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'La respuesta correcta es «frente a» porque una locución preposicional nos da más información sobre dónde se encuentra un objeto.',
      },
    },
    {
      id: '32597.B.2.6',
      templateID: 0,
      complexity: 'Knowledge',
      hint: [
        {
          id: '32597.B.2.6.0',
          type: 'BasicSlide',
          data: {
            title:
              'Las locuciones preposicionales están formadas por dos o más palabras que funcionan como una preposición.',
          },
        },
        {
          id: '32597.B.2.6.1',
          type: 'BasicSlide',
          data: {
            title: 'Busca la expresión de tres palabras que indica el motivo.',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Identifica la locución o frase preposicional en la oración \n«A causa de la lluvia no podremos ir al parque».',
        },
        answerChoices: [
          { id: '32597.B.2.6_1', choice: { texts: [{ text: 'a causa de' }] } },
          {
            id: '32597.B.2.6_2',
            choice: { texts: [{ text: 'lluvia' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Recuerda que las locuciones preposicionales están formadas por dos o más palabras.',
              },
            },
          },
          {
            id: '32597.B.2.6_3',
            choice: { texts: [{ text: 'al parque' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Las locuciones preposicionales no tienen sustantivos.',
              },
            },
          },
        ],
        answers: ['32597.B.2.6_1'],
        associatedSuccessCriteria: ['32597.B.2'],
      },
      feedbackSecondIncorrect: {
        content:
          'La respuesta correcta es «a causa de», ya que esta locución preposicional indica el motivo de lo que pasó.',
      },
    },
    {
      id: '32597.B.1.7',
      templateID: 5,
      complexity: 'Knowledge',
      hint: [
        {
          id: '32597.B.1.7.0',
          type: 'BasicSlide',
          data: {
            title:
              'Las preposiciones son palabras que indican lugar, tiempo o causa.',
          },
        },
        {
          id: '32597.B.1.7.1',
          type: 'BasicSlide',
          data: {
            title:
              'Recuerda que las preposiciones son palabras invariables, no tienen ni género ni número.',
          },
        },
      ],
      populationData: {
        question: {
          text: '¿Cuáles de estas palabras son preposiciones?\n\nElige sólo tres respuestas.',
        },
        answerChoices: [
          { id: '32597.B.1.7_1', choice: { texts: [{ text: 'sobre' }] } },
          { id: '32597.B.1.7_2', choice: { texts: [{ text: 'por' }] } },
          { id: '32597.B.1.7_3', choice: { texts: [{ text: 'en' }] } },
          {
            id: '32597.B.1.7_4',
            choice: { texts: [{ text: 'los libros' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Recuerda que las preposiciones están formadas por una sola palabra.',
              },
            },
          },
          {
            id: '32597.B.1.7_5',
            choice: { texts: [{ text: 'libro' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Esto es un sustantivo. Las preposiciones sirven para unir palabras o grupos de palabras relacionadas entre sí.',
              },
            },
          },
          {
            id: '32597.B.1.7_6',
            choice: { texts: [{ text: 'un' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Esto es un artículo. Las preposiciones indican lugar, tiempo o causa.',
              },
            },
          },
        ],
        answers: ['32597.B.1.7_1', '32597.B.1.7_2', '32597.B.1.7_3'],
        associatedSuccessCriteria: ['32597.B.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'Todas estas palabras son preposiciones ya que son palabras invariables que indican lugar, tiempo o causa.',
      },
    },
    {
      id: '32597.B.1.8',
      templateID: 5,
      complexity: 'Knowledge',
      hint: [
        {
          id: '32597.B.1.8.0',
          type: 'BasicSlide',
          data: {
            title:
              'Las preposiciones son palabras invariables que indican lugar, tiempo o causa.',
          },
        },
        {
          id: '32597.B.1.8.1',
          type: 'BasicSlide',
          data: {
            title:
              'Recuerda que las preposiciones son palabras que no tienen ni género ni número.',
          },
        },
      ],
      populationData: {
        question: {
          text: '¿Cuáles de estas palabras son preposiciones?\n\nElige sólo tres respuestas.',
        },
        answerChoices: [
          { id: '32597.B.1.8_1', choice: { texts: [{ text: 'en' }] } },
          { id: '32597.B.1.8_2', choice: { texts: [{ text: 'para' }] } },
          { id: '32597.B.1.8_3', choice: { texts: [{ text: 'ante' }] } },
          {
            id: '32597.B.1.8_4',
            choice: { texts: [{ text: 'las' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Esto es un artículo. Las preposiciones sirven para unir palabras o grupos de palabras relacionadas entre sí.',
              },
            },
          },
          {
            id: '32597.B.1.8_5',
            choice: { texts: [{ text: 'la mesa' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Recuerda que las preposiciones están formadas por una sola palabra.',
              },
            },
          },
          {
            id: '32597.B.1.8_6',
            choice: { texts: [{ text: 'pasar' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Las preposiciones no son verbos. Son palabras que indican lugar, tiempo o causa.',
              },
            },
          },
        ],
        answers: ['32597.B.1.8_1', '32597.B.1.8_2', '32597.B.1.8_3'],
        associatedSuccessCriteria: ['32597.B.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'Todas estas palabras son preposiciones ya que son palabras invariables que indican lugar, tiempo o causa.',
      },
    },
    {
      id: '32597.B.1.9',
      templateID: 5,
      complexity: 'Knowledge',
      hint: [
        {
          id: '32597.B.1.9.0',
          type: 'BasicSlide',
          data: {
            title:
              'Las locuciones preposicionales son expresiones formadas por dos o más palabras que funcionan como una preposición.',
          },
        },
        {
          id: '32597.B.1.9.1',
          type: 'BasicSlide',
          data: {
            title:
              'Las locuciones preposicionales tienen la misma función que una preposición.',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Elige solamente las locuciones o frases preposicionales.',
        },
        answerChoices: [
          { id: '32597.B.1.9_1', choice: { texts: [{ text: 'a través de' }] } },
          { id: '32597.B.1.9_2', choice: { texts: [{ text: 'junto a' }] } },
          { id: '32597.B.1.9_3', choice: { texts: [{ text: 'dentro de' }] } },
          {
            id: '32597.B.1.9_4',
            choice: { texts: [{ text: 'mi casa' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Las locuciones preposicionales no tienen un sustantivo. Son expresiones de dos o más palabras que nos dan más información sobre el lugar, tiempo o causa.',
              },
            },
          },
          {
            id: '32597.B.1.9_5',
            choice: { texts: [{ text: 'escribir' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Las locuciones preposicionales están formadas por dos o más palabras.',
              },
            },
          },
          {
            id: '32597.B.1.9_6',
            choice: { texts: [{ text: 'dulce y salado' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Esto no es una locución preposicional ya qué no contiene preposiciones.',
              },
            },
          },
        ],
        answers: ['32597.B.1.9_1', '32597.B.1.9_2', '32597.B.1.9_3'],
        associatedSuccessCriteria: ['32597.B.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'Todas estas son frases preposicionales ya que son expresiones formadas por dos o más palabras que funcionan como una preposición.',
      },
    },
    {
      id: '32597.B.1.10',
      templateID: 5,
      complexity: 'Knowledge',
      hint: [
        {
          id: '32597.B.1.10.0',
          type: 'BasicSlide',
          data: {
            title:
              'Las locuciones preposicionales son expresiones formadas por dos o más palabras que funcionan como una preposición.',
          },
        },
        {
          id: '32597.B.1.10.1',
          type: 'BasicSlide',
          data: {
            title:
              'Las locuciones preposicionales tienen la misma función que una preposición.',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Elige solamente las locuciones o frases preposicionales.',
        },
        answerChoices: [
          { id: '32597.B.1.10_1', choice: { texts: [{ text: 'debajo de' }] } },
          { id: '32597.B.1.10_2', choice: { texts: [{ text: 'frente a' }] } },
          { id: '32597.B.1.10_3', choice: { texts: [{ text: 'encima de' }] } },
          {
            id: '32597.B.1.10_4',
            choice: { texts: [{ text: 'trabaja duro' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Las locuciones preposicionales no tienen verbo. Son expresiones de dos o más palabras que nos dan más información sobre el lugar, tiempo o causa.',
              },
            },
          },
          {
            id: '32597.B.1.10_5',
            choice: { texts: [{ text: 'ejercicio' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Las locuciones preposicionales dan información sobre el lugar, teimpo o causa.',
              },
            },
          },
          {
            id: '32597.B.1.10_6',
            choice: { texts: [{ text: 'el árbol' }] },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'Las locuciones preposicionales no tienen un sustantivo, son expresiones de dos o más palabras que nos dan información sobre el lugar, tiempo o causa.',
              },
            },
          },
        ],
        answers: ['32597.B.1.10_1', '32597.B.1.10_2', '32597.B.1.10_3'],
        associatedSuccessCriteria: ['32597.B.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'Todas estas son frases preposicionales ya que son expresiones formadas por dos o más palabras que funcionan como una preposición.',
      },
    },
  ],
  nextUp: {
    nextActivityId: '018ea019-0507-7c4e-b17d-305cd29b0c34',
    nextActivityName: '¡Playa a la vista!',
    nextActivityThumbnail: {
      id: 'recxcEc5etdqMvvWy',
      url: '/helix/UX_Images/Start_Screen_Blobs/Prepositions_G3V3_Poster.jpg',
      altText: [
        { language: 'en', altText: 'Axis and CAT stand on a beach.' },
        { language: 'es', altText: 'Axis y G.A.T.A. están en una playa.' },
      ],
    },
  },
  activityManifest: [
    { type: 'START_SCREEN' },
    { type: 'ENTRY_SURVEY' },
    { type: 'VIDEO', id: 'rec51cnmyN2qdaPHY' },
    { type: 'QUESTION', id: '32597.B.2.2' },
    { type: 'QUESTION', id: '32597.B.2.1' },
    { type: 'QUESTION', id: '32597.B.2.3' },
    { type: 'QUESTION', id: '32597.B.2.4' },
    { type: 'QUESTION', id: '32597.B.2.5' },
    { type: 'QUESTION', id: '32597.B.2.6' },
    { type: 'QUESTION', id: '32597.B.1.7' },
    { type: 'QUESTION', id: '32597.B.1.8' },
    { type: 'QUESTION', id: '32597.B.1.9' },
    { type: 'QUESTION', id: '32597.B.1.10' },
    { type: 'EXIT_SURVEY' },
    { type: 'RESULTS_SCREEN' },
  ],
  version: '1984019016',
};
