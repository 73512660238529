import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SettingsService } from 'src/app/shared/services/settings/settings.service';

@Injectable({
  providedIn: 'root',
})
export class UseAnimationService {
  readonly mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
  useAnimation$ = new BehaviorSubject<boolean>(true);
  sub = new Subscription();

  constructor(private settingsService: SettingsService) {
    this.sub.add(
      this.settingsService.settings.subscribe(settings => {
        if (settings.prefersReducedMotion !== undefined) {
          this.determineUseAnimation();
        }
      })
    );
  }

  init(): void {
    this.determineUseAnimation();
  }

  determineUseAnimation(): void {
    const appSetting =
      this.settingsService.settings.getValue().prefersReducedMotion;

    if (appSetting === undefined) {
      const osSetting = !this.mediaQuery || !this.mediaQuery.matches;
      this.useAnimation$.next(osSetting);

      // Ads an event listener to check for changes in the media query's value.
      if (this.mediaQuery) {
        this.mediaQuery.addEventListener('change', (): void => {
          if (
            this.settingsService.settings.getValue().prefersReducedMotion ===
            undefined
          ) {
            this.useAnimation$.next(!this.mediaQuery.matches);
            if (!this.useAnimation$.getValue()) {
              console.warn('Animation is disabled due to user preference');
            }
          }
        });
      }
    } else {
      this.useAnimation$.next(!appSetting);
    }

    if (!this.useAnimation$.getValue()) {
      console.warn('Animation is disabled due to user preference');
    }
  }

  animationTimeout(callback: () => void, duration: number): void {
    if (!this.useAnimation$.getValue()) {
      callback();
    } else {
      setTimeout(callback, duration);
    }
  }

  onDestroy(): void {
    this.sub.unsubscribe();
  }
}
