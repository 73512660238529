import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  ProgressNodeFocusEnum,
  ProgressNodeStatusEnum,
  ProgressNodeTypeEnum,
} from '../../enums';
import { BaseNodeComponent } from '../base-node/base-node.component';

@Component({
  selector: 'htc-progress-node',
  templateUrl: './progress-node.component.html',
  styleUrls: ['./progress-node.component.scss'],
})
export class ProgressNodeComponent
  extends BaseNodeComponent
  implements OnInit, OnDestroy
{
  readonly path = '/assets/progress-node-icons/';
  type = ProgressNodeTypeEnum.progressNodeComp;
  focusClass = '';
  statusClass = '';
  sub!: Subscription;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.sub = new Subscription();
    if (this.focus$) {
      this.sub.add(
        this.focus$.subscribe((value: number | undefined) => {
          if (value !== undefined) {
            this.onFocusChange(value);
          }
        })
      );
    }

    if (this.status$) {
      this.sub.add(
        this.status$.subscribe((value: number | undefined) => {
          if (value !== undefined) {
            this.onStatusChange(value);
          }
        })
      );
    }
  }

  /**
   * On focus change set the class of progress node element
   * @param  {ProgressNodeFocusEnum} focus state to set focus to
   */
  onFocusChange(focus: ProgressNodeFocusEnum): void {
    this.focusClass = '';
    switch (focus) {
      case ProgressNodeFocusEnum.active:
        this.focusClass = 'active';
        break;
      case ProgressNodeFocusEnum.seen:
        this.focusClass = 'seen';
        break;
      default:
        break;
    }
  }

  /**
   * On status change set image url of element
   * @param  {ProgressNodeStatusEnum} status state to set status to
   */
  onStatusChange(status: ProgressNodeStatusEnum): void {
    this.statusClass = '';
    switch (status) {
      case ProgressNodeStatusEnum.wrong:
        this.statusClass = 'wrong';
        break;
      case ProgressNodeStatusEnum.correct:
        this.statusClass = 'correct';
        break;
      case ProgressNodeStatusEnum.perfect:
        this.statusClass = 'perfect';
        break;
      case ProgressNodeStatusEnum.complete:
        this.statusClass = 'complete';
        break;
      default:
        break;
    }
  }

  onNodeClicked(): void {
    if (this.clickedEmitter && !this.disabled) {
      this.clickedEmitter.emit({
        index: this.index,
        type: this.type,
        data: this.data,
        focus: this.focus$.getValue() ?? 0,
        status: this.status$.getValue() ?? 0,
      });
    }
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
