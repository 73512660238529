import { Injectable } from '@angular/core';
import {
  ProgressNodeFocusEnum,
  ProgressNodeStatusEnum,
  ProgressNodeTypeEnum,
} from '../../../modules/progress-bar/enums';
import { IProgressNodeData } from '../../../modules/progress-bar/interfaces/progress-node-data.interface';
import { ProgressService } from '../../../modules/progress-bar/services/progress.service';
import { AnswerStateEnum } from '../../enums';

@Injectable({
  providedIn: 'root',
})
export class PageProgressService {
  focusIndex = 0;
  private availableProgressNodes: IProgressNodeData[] = [];

  constructor(public progressService: ProgressService) {}

  /**
   * Set Available progress nodes and then sets focus index to 0
   * then activates shown nodes
   * @param  {IProgressNodeData[]} availableProgressNodes
   */
  set AvailableProgressNodes(availableProgressNodes: IProgressNodeData[]) {
    this.availableProgressNodes = availableProgressNodes;
    this.focusIndex = 0;
    this.newFocusIndex(0);
  }

  /**
   * Returns copy of available progress nodes
   * @returns IProgressNodeData[]
   */
  get AvailableProgressNodes(): IProgressNodeData[] {
    return this.availableProgressNodes.slice();
  }

  /**
   * Sets or progress focus by 1 and then
   * sets old to seen and new to active focus state
   * then shows nodes
   * @param  {number} index? set focus to index else progress 1
   */
  newFocusIndex(index?: number): void {
    if (
      this.focusIndex < 0 ||
      this.availableProgressNodes.length <= this.focusIndex
    ) {
      return;
    }

    const focus = this.newIndex(index);

    this.availableProgressNodes[this.focusIndex].focus =
      ProgressNodeFocusEnum.seen;
    this.focusIndex = focus;
    this.availableProgressNodes[this.focusIndex].focus =
      ProgressNodeFocusEnum.active;

    this.progressService.activateShownNodes(focus, this.availableProgressNodes);
  }

  newIndex(index?: number): number {
    let retIndex = Math.min(
      this.focusIndex + 1,
      this.availableProgressNodes.length - 1
    );
    if (index !== undefined) {
      retIndex = Math.min(
        Math.max(0, index),
        this.availableProgressNodes.length - 1
      );
    }

    return retIndex;
  }

  findNextUnseenIndex(): number {
    const { length } = this.availableProgressNodes;
    for (let i = 0; i < length; i++) {
      const n = this.availableProgressNodes[i];
      if (
        (n.type === ProgressNodeTypeEnum.videoNodeComp &&
          this.focusIndex < i) ||
        (n.type === ProgressNodeTypeEnum.progressNodeComp &&
          n.status === ProgressNodeStatusEnum.none)
      ) {
        this.newFocusIndex(i);
        return this.focusIndex;
      }
    }

    return -1;
  }

  wrongAnswer(): void {
    this.setFocusedStatus(ProgressNodeStatusEnum.wrong);
  }

  correctAnswer(): void {
    this.setFocusedStatus(ProgressNodeStatusEnum.correct);
  }

  perfectAnswer(): void {
    this.setFocusedStatus(ProgressNodeStatusEnum.perfect);
  }

  completeAnswer(): void {
    this.setFocusedStatus(ProgressNodeStatusEnum.complete);
  }

  /**
   * Set status on current focus index
   * @param  {number} status state of status to set else just progress index
   */
  setFocusedStatus(status: number): void {
    const node = this.availableProgressNodes[this.focusIndex];
    node.status = status;

    this.newFocusIndex(this.focusIndex);
  }

  updateProgressFromAnswerStates(answerStates: AnswerStateEnum[]): void {
    let i = 0;

    this.availableProgressNodes.forEach(node => {
      if (node.type === ProgressNodeTypeEnum.progressNodeComp) {
        node.status = this.parseProgressStatus(answerStates[i++]);
      }
    });

    this.progressService.activateShownNodes(
      this.focusIndex,
      this.availableProgressNodes
    );
  }

  parseProgressStatus(answerState: AnswerStateEnum): number {
    switch (answerState) {
      case AnswerStateEnum.COMPLETE:
        return ProgressNodeStatusEnum.complete;
      case AnswerStateEnum.INCORRECT:
        return ProgressNodeStatusEnum.wrong;
      case AnswerStateEnum.CORRECT:
        return ProgressNodeStatusEnum.correct;
      case AnswerStateEnum.PERFECT:
        return ProgressNodeStatusEnum.perfect;
      default:
        return ProgressNodeStatusEnum.none;
    }
  }

  setPreviousToSeen(): void {
    for (let i = this.focusIndex - 1; i >= 0; i--) {
      this.availableProgressNodes[i].focus = ProgressNodeFocusEnum.seen;
    }
  }

  isFocusedLastProgNode(): boolean {
    return this.availableProgressNodes.length - 1 <= this.focusIndex;
  }

  getQuestionNode(questionNumber: number): IProgressNodeData | null {
    const nodes = this.availableProgressNodes.filter(
      a => a.type === ProgressNodeTypeEnum.progressNodeComp
    );
    return nodes.length > questionNumber && questionNumber >= 0
      ? nodes[questionNumber]
      : null;
  }
}
