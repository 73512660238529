import { ClickstreamActivityData } from './../../types/clickstream.interface';
import { Injectable, ProviderToken, inject } from '@angular/core';
import { ClickstreamEventData } from '../../types';
import { ClickstreamAPI } from '../../classes/clickstream-api/clickstream-api';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { MessageService } from 'src/app/modules/message/services/message/message.service';
import { MessageCodes } from 'src/app/shared/enums';
import { FeatureFlagService } from 'src/app/modules/feature-flags/services/feature-flag/feature-flag.service';
import { FeatureFlags } from 'src/app/shared/enums/feature-flags';
import { ClickstreamEventTypeName } from '../../enums/clickstream-events';

const getClickstreamFacade = (): ClickstreamAPI => {
  const injectionPoint: ProviderToken<ClickstreamAPI> = ClickstreamAPI;
  const facade = inject(injectionPoint);
  return facade;
};

@Injectable({
  providedIn: 'root',
})
export class ClickstreamService {
  public clickstream = getClickstreamFacade();

  private preventClickstream = false;

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private messageService: MessageService,
    private featureFlagService: FeatureFlagService
  ) {
    this.preventClickstream = !this.featureFlagService.isFlagEnabled(
      FeatureFlags.USE_CLICKSTREAM
    );
    this.featureFlagService.flagsChanged.subscribe(() => {
      this.preventClickstream = !this.featureFlagService.isFlagEnabled(
        FeatureFlags.USE_CLICKSTREAM
      );
    });
  }

  async checkForAccessToken(): Promise<boolean> {
    if (this.clickstream.authToken) {
      return true;
    }
    this.oidcSecurityService.getAccessToken().subscribe({
      next: token => {
        if (token) {
          this.clickstream.setAuthToken(token);
        }
      },
      error: err => {
        console.error('we had issues getting an access token', err);
        this.messageService.showMessage(MessageCodes.HTTP_AUTH_REQUIRED);
      },
    });
    return true;
  }

  startActivity(
    activityName: string,
    category?: string,
    scoreCriteria?: string,
    difficulty?: number
  ): void {
    if (this.preventClickstream) {
      return;
    }
    const activityData: ClickstreamActivityData = {
      activityName: activityName,
      category: category ?? 'n/a',
      scoreCriteria: scoreCriteria ?? 'n/a',
      difficulty: difficulty ?? 0,
    };

    this.clickstream.initializeActivity(activityData, true);
  }

  completeActivity(score = 0, numStars = 0): void {
    if (this.preventClickstream) {
      return;
    }
    this.clickstream.completeActivity(score, numStars);
  }

  interruptActivity(): void {
    if (this.preventClickstream) {
      return;
    }
    this.addEventFromProperties(
      ClickstreamEventTypeName.ActivityInterrupt,
      'Exit',
      'Exit Button'
    );
  }

  addEventFromProperties(
    eventType: string,
    key: string,
    clientData: unknown
  ): void {
    if (this.preventClickstream) {
      return;
    }
    const event = {
      eventType: eventType,
      key: key,
      offset: this.clickstream.getOffset(),
      data: clientData,
    };
    this.clickstream.addEvent(event);
  }

  addEvent(event: ClickstreamEventData): void {
    if (this.preventClickstream) {
      return;
    }
    this.clickstream.addEvent(event);
  }

  saveClickstreamData(): void {
    if (this.preventClickstream) {
      return;
    }
    this.clickstream.saveClickstreamData();
  }

  clearCache(): void {
    if (this.preventClickstream) {
      return;
    }
    this.clickstream.clearCache();
  }

  clearEvents(): void {
    if (this.preventClickstream) {
      return;
    }
    this.clickstream.clearEvents();
  }
}
