import {
  AnswerChoice,
  PopulationMultipleChoice,
  TargetedFeedback,
} from './../shared/interfaces/';

export class MultiChoiceEvaluator {
  static isCorrect(
    population: PopulationMultipleChoice,
    userAnswers: string[]
  ): boolean {
    return (
      userAnswers.length === population.answers.length &&
      userAnswers.every(answerId => population.answers.includes(answerId))
    );
  }

  static getWrongAnswers(
    population: PopulationMultipleChoice,
    userAnswers: string[]
  ): string[] {
    return userAnswers.filter(
      answerId => !population.answers.includes(answerId)
    );
  }

  static getSelectedWrongTargetedFeedback(
    selectedWrongAnswers: (number | string)[][],
    answerChoices: AnswerChoice[]
  ): TargetedFeedback[] {
    const result: TargetedFeedback[] = [];
    selectedWrongAnswers.forEach(option => {
      option.forEach(answerId => {
        const wrongAnswerChoice = answerChoices.filter(
          answerChoice => answerChoice.id === answerId.toString()
        );

        if (wrongAnswerChoice[0] && wrongAnswerChoice[0].targetedFeedback) {
          result.push(wrongAnswerChoice[0].targetedFeedback);
        }
      });
    });

    return result;
  }

  static removeWrongAnswers(wrong: string[], userAnswers: string[]): string[] {
    return userAnswers.filter(answerId => !wrong.includes(answerId));
  }
}
