<footer
  data-testid="htc-footer"
  class="footer"
  [ngClass]="{
    'footer-with-toolbar': footerService.footerData.showTools,
    'footer-without-toolbar': !footerService.footerData.showTools,
    'footer-without-button': !footerService.footerData.showButton
  }">
  <htc-button-with-icon
    *ngIf="footerService.footerData.showButton"
    data-testid="htc-footer-next-button"
    [classes]="footerService.footerData.buttonIconClasses"
    [btnText]="footerService.footerData.buttonText | translate"
    [imagePath]="footerService.footerData.buttonIconPath"
    [enable]="buttonEnabled"
    (btnClicked)="
      footerService.onFooterButtonClicked(
        $event,
        pageTransitionsService.isAnimating()
      )
    "
    [clickType]="clickType"
    [clickDescriptor]="clickDescriptor">
  </htc-button-with-icon>
  <htc-toolbar
    *ngIf="footerService.footerData.showTools"
    data-testid="htc-footer-toolbar"></htc-toolbar>
</footer>
