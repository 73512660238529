import { PopulationDragAndDrop } from '../shared/interfaces';
import { TEST_IMAGE_TYPES, images } from './images';
import { TestAssociatedSuccessCriteria } from './test-associated-success-criteria.enum';

export const sortingBucketsImages: {
  [key: string]: PopulationDragAndDrop;
} = {
  test1: {
    question: {
      text: 'Sort the images',
    },
    labels: [{ text: 'Green' }, { text: 'Red' }],
    answerChoices: [
      {
        id: 'abc123',
        choice: {
          images: [images[TEST_IMAGE_TYPES.CORRECT]],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "image 1"' },
          secondIncorrect: { content: 'This is second feedback for "image 1"' },
        },
      },
      {
        id: 'abc456',
        choice: {
          images: [images[TEST_IMAGE_TYPES.INCORRECT]],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "2"' },
          secondIncorrect: { content: 'This is second feedback for "2"' },
        },
      },
      {
        id: 'abc789',
        choice: {
          images: [images[TEST_IMAGE_TYPES.CORRECT]],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "image 3"' },
          secondIncorrect: { content: 'This is second feedback for "image 3"' },
        },
      },
      {
        id: 'abc17',
        choice: {
          images: [images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE]],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "4"' },
          secondIncorrect: { content: 'This is second feedback for "4"' },
        },
      },
      {
        id: 'abc18',
        choice: {
          images: [images[TEST_IMAGE_TYPES.INCORRECT]],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "5"' },
          secondIncorrect: { content: 'This is second feedback for "5"' },
        },
      },
    ],
    answers: [
      ['abc123', 'abc789'],
      ['abc456', 'abc18'],
    ],
    associatedSuccessCriteria: [TestAssociatedSuccessCriteria.DRAG_AND_DROP],
  },

  test2: {
    question: {
      text: 'Sort the images',
    },
    labels: [{ text: 'Green' }, { text: 'Red' }, { text: 'Cats' }],
    answerChoices: [
      {
        id: 'abc123',
        choice: {
          images: [images[TEST_IMAGE_TYPES.CORRECT]],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "image 1"' },
          secondIncorrect: { content: 'This is second feedback for "image 1"' },
        },
      },
      {
        id: 'abc456',
        choice: {
          images: [images[TEST_IMAGE_TYPES.INCORRECT]],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "2"' },
          secondIncorrect: { content: 'This is second feedback for "2"' },
        },
      },
      {
        id: 'abc789',
        choice: {
          images: [images[TEST_IMAGE_TYPES.CORRECT]],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "image 3"' },
          secondIncorrect: { content: 'This is second feedback for "image 3"' },
        },
      },
      {
        id: 'abc17',
        choice: {
          images: [images[TEST_IMAGE_TYPES.CAT_UNDER_TABLE]],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "4"' },
          secondIncorrect: { content: 'This is second feedback for "4"' },
        },
      },
      {
        id: 'abc18',
        choice: {
          images: [images[TEST_IMAGE_TYPES.INCORRECT]],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "5"' },
          secondIncorrect: { content: 'This is second feedback for "5"' },
        },
      },
      {
        id: 'abc19',
        choice: {
          images: [images[TEST_IMAGE_TYPES.CAT_BESIDE_TABLE]],
        },
        targetedFeedback: {
          firstIncorrect: { content: 'This is first feedback for "5"' },
          secondIncorrect: { content: 'This is second feedback for "5"' },
        },
      },
    ],
    answers: [
      ['abc123', 'abc789'],
      ['abc456', 'abc18'],
      ['abc17', 'abc19'],
    ],
    associatedSuccessCriteria: [TestAssociatedSuccessCriteria.DRAG_AND_DROP],
  },
};
