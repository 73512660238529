import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'htc-toggle',
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './toggle.component.html',
  styleUrl: './toggle.component.scss',
})
export class ToggleComponent {
  @Input() animate = true;
  @Input() label = '';
  @Input() state = false;
  @Output() stateChange = new EventEmitter<boolean>();

  toggle(): void {
    this.state = !this.state;
    this.stateChange.emit(this.state);
  }
}
