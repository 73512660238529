import { PopulationTextEntry, TargetedFeedback } from './../shared/interfaces/';

export class TextEntryEvaluator {
  static isCorrect(
    population: PopulationTextEntry,
    answers: (string | number)[][]
  ): boolean {
    return (
      answers.length === population.answers.length &&
      answers.every((answer, index) =>
        population.answers[index]
          .map(a => a.toString().trim())
          .includes(answer.toString().trim())
      )
    );
  }

  static getWrongAnswers(
    population: PopulationTextEntry,
    answers: (string | number)[][]
  ): (string | number)[][] {
    const wrong: (string | number)[][] = [];
    for (let i = 0; i < population.answers.length; i += 1) {
      if (
        answers[i] !== undefined &&
        !population.answers[i]
          .map(a => a.toString().trim())
          .includes(answers[i].toString().trim())
      ) {
        wrong[i] = answers[i];
      } else {
        wrong[i] = [];
      }
    }

    return wrong;
  }

  static getSelectedWrongTargetedFeedback(
    selectedWrongAnswers: (number | string)[][],
    population: PopulationTextEntry
  ): TargetedFeedback[] {
    const result: TargetedFeedback[] = [];

    selectedWrongAnswers.forEach((option, index) => {
      if (option[0] !== undefined && option[0] !== '') {
        if (
          !population.answers[index]
            .map(a => a.toString().trim())
            .includes(option[0].toString().trim())
        ) {
          if (
            population.targetedFeedback &&
            population.targetedFeedback[index]
          ) {
            result.push(population.targetedFeedback[index]);
          }
        }
      }
    });

    return result;
  }

  static removeWrongAnswers(
    wrong: (string | number)[][],
    answers: (string | number)[][]
  ): (string | number)[][] {
    return answers.map((answer, i) =>
      wrong[i].find(w => w.toString().trim() === answer.toString().trim()) !==
      undefined
        ? ['']
        : answer
    );
  }
}
