import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagePopupComponent } from './components/message-popup/message-popup.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [MessagePopupComponent],
  imports: [CommonModule, SharedModule, TranslateModule],
  exports: [MessagePopupComponent],
})
export class MessageModule {}
