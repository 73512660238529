import { Component, HostListener } from '@angular/core';
import { DebugService } from '../../services/debug/debug.service';
import { AuthSessionService } from 'src/app/modules/auth/services/auth-session/auth-session.service';
import { environment } from 'src/environments/environment';
import { VideoSaveStateService } from 'src/app/modules/video/services/video-save-state.service';
import { DebugView } from '../enums/debug-view';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'htc-debug-menu',
  templateUrl: './debug-menu.component.html',
  styleUrls: ['./debug-menu.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms ease', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class DebugMenuComponent {
  readonly debugView = DebugView;
  currentView = DebugView.FEATURE_FLAGS;

  constructor(
    public debugService: DebugService,
    private authSessionService: AuthSessionService,
    private videoSaveStateService: VideoSaveStateService
  ) {}

  onPopupChange(open: boolean): void {
    if (!open) {
      this.debugService.showDebugMenu.next(false);
    }
  }

  @HostListener('document:touchstart', ['$event'])
  @HostListener('document:keydown.control.shift.d', ['$event'])
  @HostListener('document:keydown.control.alt.shift.d', ['$event'])
  @HostListener('document:keydown.control.shift.f', ['$event'])
  @HostListener('document:keydown.control.alt.shift.f', ['$event'])
  @HostListener('document:keydown.control.shift.k', ['$event'])
  @HostListener('document:keydown.control.alt.shift.k', ['$event'])
  @HostListener('document:keydown.control.shift.m', ['$event'])
  @HostListener('document:keydown.control.alt.shift.m', ['$event'])
  @HostListener('document:keydown.control.shift.c', ['$event'])
  @HostListener('document:keydown.control.alt.shift.c', ['$event'])
  // eslint-disable-next-line complexity
  openKBDebugMenu(event: TouchEvent | KeyboardEvent): void {
    if (
      ('TouchEvent' in window &&
        event instanceof TouchEvent &&
        event.touches.length >= 3) ||
      event instanceof KeyboardEvent
    ) {
      if (
        !this.authSessionService.hasRole('jumpScene') &&
        environment.production
      ) {
        //if we're on prod and the user doesn't have jumpScene acl, we don't want them to open any debug menus so early return.
        return;
      }
      if (event instanceof KeyboardEvent) {
        const key = (event as KeyboardEvent).key.toLowerCase();
        if (key === 'f') {
          this.setView(DebugView.FEATURE_FLAGS);
        } else if (key === 'k') {
          this.setView(DebugView.KB_DEBUG);
        } else if (key === 'c') {
          this.setView(DebugView.CLICKSTREAM_DEBUG);
        }
      }
      this.debugService.showDebugMenu.next(true);
    }
  }

  setVideoFinished(): void {
    this.videoSaveStateService.watchedFullVideo$.next(true);
  }

  setView(value: number): void {
    this.currentView = Object.values(DebugView).includes(value)
      ? value
      : DebugView.FEATURE_FLAGS;
  }
}
