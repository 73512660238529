export enum TEST_CHOICE_TYPES {
  TEST_R_IC_SHORT,
  TEST_R_IC_MEDIUM,
  TEST_R_IC_LONG,
  TEST_R_IC_KATEX,
  TEST_R_MCI_SHORT,
  TEST_R_MCI_MEDIUM,
  TEST_R_MCI_LONG,
  TEST_R_MC_SHORT,
  TEST_R_MC_MEDIUM,
  TEST_R_MC_LONG,
  TEST_R_MC_KATEX,
  TEST_R_MS_SHORT,
  TEST_R_MS_MEDIUM,
  TEST_R_MS_LONG,
  TEST_R_MS_KATEX,
  TEST_R_MSI_SHORT,
  TEST_R_MSI_MEDIUM,
  TEST_R_MSI_LONG,
  TEST_R_TE_SHORT,
  TEST_R_TE_MEDIUM,
  TEST_R_TE_LONG,
  TEST_R_MC_TRUE_FALSE,
  TEST_R_SCR_SHORT,
  TEST_R_SCR_MEDIUM,
  TEST_R_SCR_LONG,
  TEST_R_SCRR_SHORT,
  TEST_R_SCRR_MEDIUM,
  TEST_R_SCRR_LONG,
  TEST_R_ECR_SHORT,
  TEST_R_ECR_MEDIUM,
  TEST_R_ECR_LONG,
  TEST_R_ECRR_SHORT,
  TEST_R_ECRR_MEDIUM,
  TEST_R_ECRR_LONG,
  TEST_R_DND_BLANK_SHORT,
  TEST_R_DND_BLANK_LONG,
  TEST_R_DND_BLANK_KATEX,
  TEST_R_DND_HORZ_SEQ_SHORT,
  TEST_R_DND_HORZ_SEQ_LONG,
  TEST_R_DND_SEQ_STATIC_HORZ_KATEX,
  TEST_R_DND_VERT_SEQ_SHORT,
  TEST_R_DND_VERT_SEQ_LONG,
  TEST_R_DND_SEQ_STATIC_VERT_KATEX,
  TEST_R_DND_SORT_BUCKETS_SHORT,
  TEST_R_DND_SORT_BUCKETS_LONG,
  TEST_R_DND_SORT_BUCKETS_KATEX,
  TEST_S_IC_SHORT,
  TEST_S_IC_MEDIUM,
  TEST_S_IC_LONG,
  TEST_S_MCI_SHORT,
  TEST_S_MCI_MEDIUM,
  TEST_S_MCI_LONG,
  TEST_S_MC_SHORT,
  TEST_S_MC_MEDIUM,
  TEST_S_MC_LONG,
  TEST_S_MS_SHORT,
  TEST_S_MS_MEDIUM,
  TEST_S_MS_LONG,
  TEST_S_MSI_SHORT,
  TEST_S_MSI_MEDIUM,
  TEST_S_MSI_LONG,
  TEST_S_TE_SHORT,
  TEST_S_TE_MEDIUM,
  TEST_S_TE_LONG,
  TEST_S_MC_TRUE_FALSE,
  TEST_S_SCR_SHORT,
  TEST_S_SCR_MEDIUM,
  TEST_S_SCR_LONG,
  TEST_S_SCRR_SHORT,
  TEST_S_SCRR_MEDIUM,
  TEST_S_SCRR_LONG,
  TEST_S_ECR_SHORT,
  TEST_S_ECR_MEDIUM,
  TEST_S_ECR_LONG,
  TEST_S_ECRR_SHORT,
  TEST_S_ECRR_MEDIUM,
  TEST_S_ECRR_LONG,
  TEST_M_DND_SHORT,
  TEST_M_DND_LONG,
  TEST_M_DND_HORZ_SEQ_SHORT,
  TEST_M_DND_HORZ_SEQ_LONG,
  TEST_M_DND_VERT_SEQ_SHORT,
  TEST_M_DND_VERT_SEQ_LONG,
  TEST_M_DND_SORT_BUCKETS_SHORT,
  TEST_M_DND_SORT_BUCKETS_LONG,
}
