import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KBDebugMenuComponent } from './components/kb-debug-menu/kb-debug-menu.component';
import { DebugMenuComponent } from './components/debug-menu/debug-menu.component';
import { IncompleteSaveDataExportImportComponent } from './components/incomplete-save-data-export-import/incomplete-save-data-export-import.component';
import { IncompleteSaveDataEditorComponent } from './components/incomplete-save-data-editor/incomplete-save-data-editor.component';
import { ActivityModule } from '../pages/activity/activity.module';
import { FeatureFlagModule } from '../feature-flags/feature-flag.module';
import { ClickstreamDebugMenuComponent } from './components/clickstream-debug-menu/clickstream-debug-menu.component';
import { MessagesDebugMenuComponent } from './components/messages-debug-menu/messages-debug-menu.component';

@NgModule({
  declarations: [
    KBDebugMenuComponent,
    DebugMenuComponent,
    IncompleteSaveDataExportImportComponent,
    IncompleteSaveDataEditorComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ActivityModule,
    FeatureFlagModule,
    ClickstreamDebugMenuComponent,
    MessagesDebugMenuComponent,
  ],
  exports: [
    KBDebugMenuComponent,
    DebugMenuComponent,
    IncompleteSaveDataExportImportComponent,
    IncompleteSaveDataEditorComponent,
  ],
})
export class DebugModule {}
