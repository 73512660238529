import { Component, Input } from '@angular/core';
import { TextData } from '../../interfaces';
import { SpeechRuleEngineService } from 'src/app/modules/math-text/services/speech-rule-engine.service';
@Component({
  selector: 'htc-bulleted-list',
  templateUrl: './bulleted-list.component.html',
  styleUrls: ['./bulleted-list.component.scss'],
})
export class BulletedListComponent {
  @Input() items: TextData[] = [];
  @Input() bulletImage = ''; //ex. "url(/assets/learning-target/check.svg)"
  @Input() bulletSize = ''; //ex. "1em"
  @Input() bulletPaddingLeft = ''; //ex. "1em"
  @Input() gridGap = ''; //ex. "1em";
  @Input() gridColumns = ''; //ex. "100%" or "repeat(2, 1fr)";
  @Input() bulletPosition = ''; //ex. "1rem";
  @Input() listItemColor = ''; //ex. "#000000";
  @Input() listBorderRadius = ''; //ex. "1em"
  @Input() bulletPadding = ''; //ex. "1em 1em 1em 1em"

  constructor(public sres: SpeechRuleEngineService) {}

  itemToString(item: string | TextData): string {
    if (typeof item === 'string') {
      return item;
    } else {
      return (<TextData>item).text;
    }
  }
}
