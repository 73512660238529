<div
  #readAloudContainer
  class="read-aloud-container"
  [ngClass]="{
    'theme-light': theme !== 'DARK',
    'theme-dark': theme === 'DARK',
    'playing-or-paused': isPlaying || isPaused,
    show: show && !forceHide,
    'use-custom-color': customTextColor !== undefined
  }"
  [id]="'read-aloud-component-' + uuid"
  [ngStyle]="{ '--read-aloud-custom-text-color': customTextColor }"
  (click)="
    show && !forceHide && type !== 'NO_PAUSE_OUTER_CLICK'
      ? handleClick($event)
      : ''
  "
  (keydown.space)="show && !forceHide ? handleClick($event) : ''"
  (keydown.enter)="show && !forceHide ? handleClick($event) : ''"
  data-testid="read-aloud-component-container"
  [tabindex]="!show || forceHide || preventTabTargeting ? -1 : 0">
  <div class="read-aloud-center" data-testid="read-aloud-component-center">
    <ng-container *ngTemplateOutlet="template"></ng-container>
  </div>
  <div data-testid="read-aloud-component-top" class="read-aloud-top">
    <div
      class="read-aloud-controls"
      [ngClass]="{
        'playing-or-paused': isPlaying || isPaused
      }">
      <htc-read-aloud-controls
        [showPlayPause]="showPlayPause"
        [type]="type"
        [isPlaying]="isPlaying"
        [isPaused]="isPaused"
        [isLoading]="isLoading">
      </htc-read-aloud-controls>
    </div>
  </div>
</div>
