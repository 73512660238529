import { ActivityObjectTypes } from 'src/app/shared/enums/activity-object-types';
import { Activity } from 'src/app/shared/interfaces/activity';
import { TEST_POPULATION_TYPES } from '../population-types';
import { LanguageCodes } from 'src/app/shared/enums';
import {
  populationsReadingDNDSandbox,
  populationsDragAndDropSortingBuckets,
  populationsDragAndDropFillInTheBlank,
  populationsDragAndDropSortingBucketsImages,
} from '../populations';
import { videos } from '../videos';
import { TEST_IMAGE_TYPES, images } from '../images';
import { learningTargets } from '../learning-targets';
import { surveys } from '../surveys';
import { constActivityManifestEnd } from '../activities/activity-manifest-end';

const commonDragAndDrop = {
  instructionsImage: {
    id: 'recUzD6BEnXxRd8Wh',
    url: '/helix/UX_Images/Characters/Estrella/Estrella_Chibi_Head_Happy_Eyes_Open_Mouth_Open.svg',
    altText: [
      { language: 'en', altText: 'Estrella is smiling.' },
      { language: 'es', altText: 'Estrella está sonriendo.' },
    ],
  },
};
export const dragAndDropActivities: { [key: string]: Activity } = {
  'TEST.DND.SEQ': {
    id: `TEST.DND.SEQ`,
    product: `Drag and Drop`,
    language: LanguageCodes.ENGLISH,
    skillName: 'DND',
    episodeTitle: 'DND Sequencing',
    episodeNumber: '1',
    thumbnail: {
      id: 'recygZeNHb67g46Je',
      url: '/helix/Reading/Grade_3/Prepositions/Episode_02/R_G3_Prepositions_EP02_Poster.png',
      altText: [
        {
          language: 'en',
          altText: 'Axis floats in space outside his spaceship.',
        },
        {
          language: 'es',
          altText: 'Axis flota en el espacio afuera de su nave espacial.',
        },
      ],
    },
    domain: { text: `Triangles` },
    startScreenData: {
      learningTarget: [learningTargets[2]],
      background_leftImage: images[TEST_IMAGE_TYPES.BG_CLOUD_LEFT],
      background_rightImage: images[TEST_IMAGE_TYPES.BG_CLOUD_RIGHT],
      foreground: images[TEST_IMAGE_TYPES.FG_CAT],
    },
    entrySurvey: [surveys[6], surveys[7], surveys[8]],
    exitSurvey: [surveys[12]],
    videos: [videos[0]],
    populations: [
      populationsReadingDNDSandbox[
        TEST_POPULATION_TYPES.TEST_M_DND_STATIC_HORIZ_SEQ
      ],
      populationsReadingDNDSandbox[
        TEST_POPULATION_TYPES.TEST_M_DND_DYNAMIC_HORIZ_SEQ
      ],
      populationsReadingDNDSandbox[
        TEST_POPULATION_TYPES.TEST_M_DND_STATIC_VERT_SEQ
      ],
    ],
    instructionsImage: commonDragAndDrop.instructionsImage,
    availableTools: [0, 1, 2],
    activityManifest: [
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_DND_STATIC_HORIZ_SEQ}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_DND_DYNAMIC_HORIZ_SEQ}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_DND_STATIC_VERT_SEQ}`,
      },
      ...constActivityManifestEnd,
    ],
  },
  'TEST.DND.BLANK': {
    id: `TEST.DND.BLANK`,
    product: `Drag and Drop`,
    language: LanguageCodes.ENGLISH,
    skillName: 'DND',
    episodeTitle: 'DND Fill in the Blank',
    episodeNumber: '1',
    globalTryOverride: 2,
    thumbnail: {
      id: 'recygZeNHb67g46Je',
      url: '/helix/Reading/Grade_3/Prepositions/Episode_02/R_G3_Prepositions_EP02_Poster.png',
      altText: [
        {
          language: 'en',
          altText: 'Axis floats in space outside his spaceship.',
        },
        {
          language: 'es',
          altText: 'Axis flota en el espacio afuera de su nave espacial.',
        },
      ],
    },
    domain: {
      text: `Drag and Drop`,
    },
    startScreenData: {
      learningTarget: [learningTargets[2]],
      background_leftImage: images[TEST_IMAGE_TYPES.BG_CLOUD_LEFT],
      background_rightImage: images[TEST_IMAGE_TYPES.BG_CLOUD_RIGHT],
      foreground: images[TEST_IMAGE_TYPES.FG_CAT],
    },
    entrySurvey: [surveys[6], surveys[7], surveys[8]],
    exitSurvey: [surveys[12]],
    videos: [videos[0]],
    populations: [
      populationsDragAndDropFillInTheBlank[
        TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_1
      ],
      populationsDragAndDropFillInTheBlank[
        TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_2
      ],
      populationsDragAndDropFillInTheBlank[
        TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_3
      ],
      populationsDragAndDropFillInTheBlank[
        TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_4
      ],
      populationsDragAndDropFillInTheBlank[
        TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_5
      ],
    ],
    instructionsImage: commonDragAndDrop.instructionsImage,
    availableTools: [0, 1, 2],
    activityManifest: [
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_1}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_2}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_3}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_4}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_DND_FILL_BLANK_5}`,
      },
      ...constActivityManifestEnd,
    ],
  },
  'TEST.DND.SORTING.BUCKETS': {
    id: `TEST.DND.SORTING.BUCKETS`,
    product: `Drag and Drop`,
    language: LanguageCodes.ENGLISH,
    skillName: 'DND',
    episodeTitle: 'DND Sorting Buckets',
    episodeNumber: '1',
    thumbnail: {
      id: 'recygZeNHb67g46Je',
      url: '/helix/Reading/Grade_3/Prepositions/Episode_02/R_G3_Prepositions_EP02_Poster.png',
      altText: [
        {
          language: 'en',
          altText: 'Axis floats in space outside his spaceship.',
        },
        {
          language: 'es',
          altText: 'Axis flota en el espacio afuera de su nave espacial.',
        },
      ],
    },
    domain: {
      text: `Drag and Drop`,
    },
    startScreenData: {
      learningTarget: [learningTargets[2]],
      background_leftImage: images[TEST_IMAGE_TYPES.BG_CLOUD_LEFT],
      background_rightImage: images[TEST_IMAGE_TYPES.BG_CLOUD_RIGHT],
      foreground: images[TEST_IMAGE_TYPES.FG_CAT],
    },
    entrySurvey: [surveys[6], surveys[7], surveys[8]],
    exitSurvey: [surveys[12]],
    videos: [videos[0]],
    populations: [
      populationsDragAndDropSortingBuckets[
        TEST_POPULATION_TYPES.TEST_M_DND_SORTING_BUCKETS_1
      ],
      populationsDragAndDropSortingBuckets[
        TEST_POPULATION_TYPES.TEST_M_DND_SORTING_BUCKETS_2
      ],
      populationsDragAndDropSortingBuckets[
        TEST_POPULATION_TYPES.TEST_M_DND_SORTING_BUCKETS_3
      ],
    ],
    instructionsImage: commonDragAndDrop.instructionsImage,
    availableTools: [0, 1, 2],
    activityManifest: [
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_DND_SORTING_BUCKETS_1}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_DND_SORTING_BUCKETS_2}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_M_DND_SORTING_BUCKETS_3}`,
      },
      ...constActivityManifestEnd,
    ],
  },
  'TEST.DND.SORTING.BUCKETS.IMAGES': {
    id: `TEST.DND.SORTING.BUCKETS.IMAGES`,
    product: `Drag and Drop`,
    language: LanguageCodes.ENGLISH,
    skillName: 'DND',
    episodeTitle: 'DND Sorting Buckets Images',
    episodeNumber: '1',
    thumbnail: {
      id: 'recygZeNHb67g46Je',
      url: '/helix/Reading/Grade_3/Prepositions/Episode_02/R_G3_Prepositions_EP02_Poster.png',
      altText: [
        {
          language: 'en',
          altText: 'Axis floats in space outside his spaceship.',
        },
        {
          language: 'es',
          altText: 'Axis flota en el espacio afuera de su nave espacial.',
        },
      ],
    },
    domain: {
      text: `Drag and Drop`,
    },
    startScreenData: {
      learningTarget: [learningTargets[2]],
      background_leftImage: images[TEST_IMAGE_TYPES.BG_CLOUD_LEFT],
      background_rightImage: images[TEST_IMAGE_TYPES.BG_CLOUD_RIGHT],
      foreground: images[TEST_IMAGE_TYPES.FG_CAT],
    },
    entrySurvey: [surveys[6], surveys[7], surveys[8]],
    exitSurvey: [surveys[12]],
    videos: [videos[0]],
    populations: [
      populationsDragAndDropSortingBucketsImages[
        TEST_POPULATION_TYPES.TEST_R_DND_SORTING_BUCKETS_IMAGES_1
      ],
      populationsDragAndDropSortingBucketsImages[
        TEST_POPULATION_TYPES.TEST_R_DND_SORTING_BUCKETS_IMAGES_2
      ],
    ],
    instructionsImage: commonDragAndDrop.instructionsImage,
    availableTools: [0, 1, 2],
    activityManifest: [
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_DND_SORTING_BUCKETS_IMAGES_1}`,
      },
      {
        type: ActivityObjectTypes.QUESTION,
        id: `${TEST_POPULATION_TYPES.TEST_R_DND_SORTING_BUCKETS_IMAGES_2}`,
      },
      ...constActivityManifestEnd,
    ],
  },
};
