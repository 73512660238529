import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TimeElapsedService {
  private timeElapsed = 0;
  private interval = -1;
  private timerActive = false;
  private timeItemSource = new BehaviorSubject<string>('00:00');
  time: Observable<string> = this.timeItemSource.asObservable();

  constructor() {}

  startTimer(): void {
    if (this.interval > 0) {
      return;
    }

    this.interval = window.setInterval(() => {
      this.timeElapsed += 1;
      this.getTime();
    }, 1000);

    this.timerActive = true;
  }

  pauseTimer(): void {
    if (this.interval > 0) {
      clearInterval(this.interval);
      this.interval = -1;
      this.timerActive = false;
    }
  }

  resetTimer(): void {
    this.timeElapsed = 0;
    this.timeItemSource.next('00:00');
  }

  stopTimer(): void {
    if (this.interval > 0) {
      this.pauseTimer();
      this.resetTimer();
    }
  }

  private timeToString(time: number): string {
    if (time < 10) {
      return `0${time}`;
    }
    return `${time}`;
  }

  getTime(): void {
    const minutes: string = this.timeToString(
      Math.floor(this.timeElapsed / 60)
    );
    const seconds: string = this.timeToString(this.timeElapsed % 60);
    this.timeItemSource.next(`${minutes}:${seconds}`);
  }

  getTimeAsSeconds(): number {
    return this.timeElapsed;
  }

  setTime(time: number): void {
    this.timeElapsed = time;
    this.getTime();
  }

  isTimerActive(): boolean {
    return this.timerActive;
  }
}
