import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ActivityService } from '../services/activity/activity.service';
import { Injectable } from '@angular/core';
import { ReferrerService } from '../services/referrer/referrer.service';

@Injectable({
  providedIn: 'root',
})
export class ActivityValidGuard {
  constructor(
    private activityService: ActivityService,
    private referrerService: ReferrerService
  ) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    //are we logged in and a path is in the queue? (Normal path to activities)
    if (this.activityService.currActivity) {
      return true;
    } else {
      this.referrerService.navigateToReferrer();
      return false;
    }
  }
}
