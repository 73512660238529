import { Activity } from 'src/app/shared/interfaces';

export const lectura8: Activity = {
  id: '018ea019-031c-78b2-b1c2-101528e43f3d',
  product: 'Lectura',
  language: 'es',
  skillName: 'Locuciones o frases preposicionales',
  thumbnail: {
    id: 'rec2Wt325y04Dw11p',
    url: '/helix/UX_Images/Lesson_Posters/Prepositions_G5V2_Poster.jpg',
    altText: [
      {
        language: 'en',
        altText: 'Axis and CAT look at the Burger Planet sign.',
      },
      {
        language: 'es',
        altText: 'Axis y G.A.T.A. observan el letrero de Planeta Burger',
      },
    ],
  },
  instructionsImage: {
    id: 'recqnzfDjMQK7N7kr',
    url: '/helix/UX_Images/Characters/CAT/CAT_Chibi_Happy_Eyes_Open_Mouth_Open.svg',
    altText: [
      {
        language: 'en',
        altText: 'CAT is smiling.',
      },
      {
        language: 'es',
        altText: 'G.A.T.A. está sonriendo.',
      },
    ],
  },
  characterLeanout: {
    id: 'rec5Rylxn2J2DNIHO',
    url: '/helix/TEI/Leanouts/CAT_Leanout_01.svg',
    altText: [
      {
        language: 'en',
        altText: 'CAT leans out from the side of the screen.',
      },
      {
        language: 'es',
        altText: 'G.A.T.A. se asoma por el lado de la pantalla.',
      },
    ],
  },
  description:
    'Después de encontrar las rocas galácticas, Axis y G.A.T.A. tienen mucha hambre y deciden leer varias reseñas para decidir dónde comer. Una vez que corrigen las locuciones preposicionales de las opiniones, celebran su arduo trabajo con deliciosos manjares del espacio.',
  domain: {
    text: 'Categorías gramaticales',
  },
  seriesName: 'La isla de las preposiciones',
  episodeNumber: '2',
  episodeTitle: 'Planeta Burger',
  videos: [
    {
      id: 'recNLCuKB8a1Swl3w',
      url: '/helix/Lectura/Grade_5/Prepositions/Episode_02/hls_streams/L_G5_Prepositions_Ep02_Video.m3u8',
      posterURL: '/helix/UX_Images/Lesson_Posters/Prepositions_G5V2_Poster.jpg',
      captions: [
        {
          src: '/helix/Lectura/Grade_5/Prepositions/Episode_02/L_G5_Prepositions_Ep02_Video.vtt',
          srclang: 'es',
          mode: 'hidden',
          kind: 'captions',
        },
      ],
    },
  ],
  startScreenData: {
    learningTarget: [
      {
        id: '31051.B',
        learningTarget: {
          text: 'a editar mi escritura usando las preposiciones y locuciones preposicionales',
        },
        successCriteria: [
          {
            id: '31051.B.1',
            text: 'identificar y corregir las preposiciones y locuciones preposicionales en mi escritura y la de otros',
          },
        ],
      },
    ],
    background_leftImage: {
      id: 'rec2Wt325y04Dw11p',
      url: '/helix/UX_Images/Lesson_Posters/Prepositions_G5V2_Poster.jpg',
      altText: [
        {
          language: 'en',
          altText: 'Axis and CAT look at the Burger Planet sign.',
        },
        {
          language: 'es',
          altText: 'Axis y G.A.T.A. observan el letrero de Planeta Burger',
        },
      ],
    },
    background_rightImage: {
      id: 'recxjC353VMav5ybu',
      url: '/helix/UX_Images/Start_Screen_Blobs/Nova-Scouts_space-sky.svg',
      altText: [
        {
          language: 'en',
          altText: 'Stars in space.',
        },
        {
          language: 'es',
          altText: 'Estrellas en el espacio.',
        },
      ],
    },
    foreground: {
      id: 'recOn2Luhc6XMnbRL',
      url: '/helix/UX_Images/Start_Screen_Characters/Cat5.svg',
      altText: [
        {
          language: 'en',
          altText: 'CAT is pouncing.',
        },
        {
          language: 'es',
          altText: 'G.A.T.A. está saltando.',
        },
      ],
    },
  },
  populations: [
    {
      id: '31051.B.1.1',
      templateID: 0,
      complexity: 'Evaluation',
      hint: [
        {
          id: '31051.B.1.1.0',
          type: 'BasicSlide',
          data: {
            title:
              'Lee las preposiciones o locuciones preposicionales en negrilla en voz alta para determinar cual suena incorrecto.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Lee el párrafo y determina qué preposición o locución preposicional en negrilla es incorrecta.\n',
        },
        answerChoices: [
          {
            id: '31051.B.1.1_1',
            choice: {
              texts: [
                {
                  text: 'bajo\n',
                },
              ],
            },
          },
          {
            id: '31051.B.1.1_2',
            choice: {
              texts: [
                {
                  text: 'junto al\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La locución preposicional «junto al» es correcta porque da información sobre por dónde corrían los niños.\n',
              },
            },
          },
          {
            id: '31051.B.1.1_3',
            choice: {
              texts: [
                {
                  text: 'detrás de\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La locución preposicional «detrás de» es correcta porque da información sobre el lugar donde están sentadas las familias.\n',
              },
            },
          },
        ],
        answers: ['31051.B.1.1_1'],
        associatedSuccessCriteria: ['31051.B.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La preposición «bajo» es incorrecta porque indica dónde y no cuándo ocurrió algo.\n',
      },
    },
    {
      id: '31051.B.1.2',
      templateID: 1,
      complexity: 'Evaluation',
      hint: [
        {
          id: '31051.B.1.2.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recF9Y2P0hywEJSem',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_07.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y sus usos.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Revisa la preposición o locución preposicional incorrecta y elige la correcta.\n\nHubo bailarines, bandas de música y artistas {CHOICE_TOKEN} el desfile.\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '31051.B.1.2_1',
                  text: 'durante\n',
                  targetedFeedback: {
                    firstIncorrect: { content: '\n' },
                  },
                },
                {
                  id: '31051.B.1.2_2',
                  text: 'entre\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'La preposición «entre» da información sobre dónde se encuentra algo o alguien. Esta oración necesita una preposición que indique tiempo.\n',
                    },
                  },
                },
                {
                  id: '31051.B.1.2_3',
                  text: 'por\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'La preposición «por» da información sobre por qué algo ocurrió. Esta oración necesita una preposición que indique tiempo.\n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['31051.B.1.2_1'],
        associatedSuccessCriteria: ['31051.B.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La preposición «durante» es la correcta porque indica cuándo los bailarines, bandas y artistas participaron en el desfile.\n',
      },
    },
    {
      id: '31051.B.1.3',
      templateID: 0,
      complexity: 'Evaluation',
      hint: [
        {
          id: '31051.B.1.3.0',
          type: 'BasicSlide',
          data: {
            title:
              'Lee las preposiciones o locuciones preposicionales en negrilla en voz alta para determinar cual suena incorrecto.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Lee el párrafo y determina qué preposición o locución preposicional en negrilla es incorrecta.\n',
        },
        answerChoices: [
          {
            id: '31051.B.1.3_1',
            choice: {
              texts: [
                {
                  text: 'dentro\n',
                },
              ],
            },
          },
          {
            id: '31051.B.1.3_2',
            choice: {
              texts: [
                {
                  text: 'a través de\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La locución preposicional «a través de» es correcta porque indica el lugar por donde Emilia quería salir corriendo.\n',
              },
            },
          },
          {
            id: '31051.B.1.3_3',
            choice: {
              texts: [
                {
                  text: 'frente a\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La locución preposicional «frente a» es correcta porque indica el lugar donde se encontraba Emilia durante su presentación.\n',
              },
            },
          },
        ],
        answers: ['31051.B.1.3_1'],
        associatedSuccessCriteria: ['31051.B.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La preposición «dentro» es incorrecta porque indica el lugar y no el tiempo en que ocurrió algo.\n',
      },
    },
    {
      id: '31051.B.1.4',
      templateID: 1,
      complexity: 'Evaluation',
      hint: [
        {
          id: '31051.B.1.4.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recF9Y2P0hywEJSem',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_07.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y sus usos.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Revisa la preposición o locución preposicional incorrecta y elige la correcta.\n\nHubo un silencio incómodo en el auditorio {CHOICE_TOKEN} que terminó de cantar la última nota.\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '31051.B.1.4_1',
                  text: 'después de\n',
                },
                {
                  id: '31051.B.1.4_2',
                  text: 'encima de\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'La locución preposicional «encima de» da información sobre dónde ocurrió algo. Esta oración necesita una locución preposicional que indique tiempo.\n',
                    },
                  },
                },
                {
                  id: '31051.B.1.4_3',
                  text: 'por causa de\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'La locución preposicional «por causa de» da información sobre por qué algo ocurrió. Esta oración necesita una locución preposicional que indique tiempo.\n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['31051.B.1.4_1'],
        associatedSuccessCriteria: ['31051.B.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La locución preposicional «después de» es correcta porque indica cuándo hubo un silencio incómodo.\n',
      },
    },
    {
      id: '31051.B.1.5',
      templateID: 0,
      complexity: 'Evaluation',
      hint: [
        {
          id: '31051.B.1.5.0',
          type: 'BasicSlide',
          data: {
            title:
              'Lee las preposiciones o locuciones preposicionales en negrilla en voz alta para determinar cual suena incorrecto.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Lee el párrafo y determina qué preposición o locución preposicional en negrilla es incorrecta.\n',
        },
        answerChoices: [
          {
            id: '31051.B.1.5_1',
            choice: {
              texts: [
                {
                  text: 'en medio de\n',
                },
              ],
            },
          },
          {
            id: '31051.B.1.5_2',
            choice: {
              texts: [
                {
                  text: 'enfrente de\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La locución preposicional «enfrente de» es correcta porque indica el lugar donde se encuentra Miguel al deletrear la última palabra.\n',
              },
            },
          },
          {
            id: '31051.B.1.5_3',
            choice: {
              texts: [
                {
                  text: 'sobre\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La preposición «sobre» es correcta porque indica el lugar donde Miguel tomó asiento.\n',
              },
            },
          },
        ],
        answers: ['31051.B.1.5_1'],
        associatedSuccessCriteria: ['31051.B.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La locución preposicional «en medio de» es incorrecta porque indica dónde y no la causa por la que ocurrió algo.\n',
      },
    },
    {
      id: '31051.B.1.6',
      templateID: 1,
      complexity: 'Evaluation',
      hint: [
        {
          id: '31051.B.1.6.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recF9Y2P0hywEJSem',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_07.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y sus usos.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Revisa la preposición o locución preposicional incorrecta y elige la correcta.\n\nMiguel había ganado la competencia de ortografía {CHOICE_TOKEN} todo su trabajo y preparación.\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '31051.B.1.6_1',
                  text: 'por\n',
                },
                {
                  id: '31051.B.1.6_2',
                  text: 'hasta\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'La preposición «hasta» da información sobre el tiempo en que algo ocurrió. Esta oración necesita una locución preposicional que indique causa.\n',
                    },
                  },
                },
                {
                  id: '31051.B.1.6_3',
                  text: 'sobre\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'La preposición «sobre» da información sobre el lugar donde se encuentra algo o alguien. Esta oración necesita una locución preposicional que indique causa.\n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['31051.B.1.6_1'],
        associatedSuccessCriteria: ['31051.B.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La preposición «por» es correcta porque indica la razón por la cual Miguel ganó la competencia.\n',
      },
    },
    {
      id: '31051.B.1.7',
      templateID: 0,
      complexity: 'Evaluation',
      hint: [
        {
          id: '31051.B.1.7.0',
          type: 'BasicSlide',
          data: {
            title:
              'Lee las preposiciones o locuciones preposicionales en negrilla en voz alta para determinar cual suena incorrecto.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Lee el párrafo y determina qué preposición o locución preposicional en negrilla es incorrecta.\n',
        },
        answerChoices: [
          {
            id: '31051.B.1.7_1',
            choice: {
              texts: [
                {
                  text: 'en contra de\n',
                },
              ],
            },
          },
          {
            id: '31051.B.1.7_2',
            choice: {
              texts: [
                {
                  text: 'durante\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La preposición «durante» es correcta porque indica cuándo mostró Sara su proyecto.\n',
              },
            },
          },
          {
            id: '31051.B.1.7_3',
            choice: {
              texts: [
                {
                  text: 'sobre\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La preposición «sobre» es correcta porque indica el tema.\n',
              },
            },
          },
        ],
        answers: ['31051.B.1.7_1'],
        associatedSuccessCriteria: ['31051.B.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La locución preposicional «en contra de» es incorrecta porque indica la causa y no el lugar donde ocurrió algo.\n',
      },
    },
    {
      id: '31051.B.1.8',
      templateID: 1,
      complexity: 'Evaluation',
      hint: [
        {
          id: '31051.B.1.8.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recF9Y2P0hywEJSem',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_07.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y sus usos.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Revisa la preposición o locución preposicional incorrecta y elige la correcta.\n\nDe repente, el volcán entró en erupción, arrojando un líquido rojo {CHOICE_TOKEN} toda la mesa.\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '31051.B.1.8_1',
                  text: 'sobre\n',
                },
                {
                  id: '31051.B.1.8_2',
                  text: 'hasta\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'La preposición «hasta» da información sobre cuándo ocurrió algo. Esta oración necesita una locución preposicional que indique lugar.\n',
                    },
                  },
                },
                {
                  id: '31051.B.1.8_3',
                  text: 'por causa de\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'La locución preposicional «por causa de» da información sobre por qué algo ocurrió. Esta oración necesita una locución preposicional que indique lugar.\n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['31051.B.1.8_1'],
        associatedSuccessCriteria: ['31051.B.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La locución preposicional «sobre» es correcta porque indica el lugar donde está el líquido rojo.\n',
      },
    },
    {
      id: '31051.B.1.9',
      templateID: 0,
      complexity: 'Evaluation',
      hint: [
        {
          id: '31051.B.1.9.0',
          type: 'BasicSlide',
          data: {
            title:
              'Lee las preposiciones o locuciones preposicionales en negrilla en voz alta para determinar cual suena incorrecto.\n',
          },
        },
      ],
      populationData: {
        question: {
          text: 'Lee el párrafo y determina qué preposición o locución preposicional en negrilla es incorrecta.\n',
        },
        answerChoices: [
          {
            id: '31051.B.1.9_1',
            choice: {
              texts: [
                {
                  text: 'hacia\n',
                },
              ],
            },
          },
          {
            id: '31051.B.1.9_2',
            choice: {
              texts: [
                {
                  text: 'entre\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La preposición «entre» es correcta porque indica por dónde corrían Pablo y Bruno.\n',
              },
            },
          },
          {
            id: '31051.B.1.9_3',
            choice: {
              texts: [
                {
                  text: 'encima de\n',
                },
              ],
            },
            targetedFeedback: {
              firstIncorrect: {
                content:
                  'La locución preposicional «encima de» es correcta porque indica el lugar en donde Bruno terminaba sus días.\n',
              },
            },
          },
        ],
        answers: ['31051.B.1.9_1'],
        associatedSuccessCriteria: ['31051.B.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La preposición «hacia» es incorrecta porque indica dónde y no cuándo ocurrió algo.\n',
      },
    },
    {
      id: '31051.B.1.10',
      templateID: 1,
      complexity: 'Evaluation',
      hint: [
        {
          id: '31051.B.1.10.0',
          type: 'BasicSlide',
          data: {
            imgData: {
              id: 'recF9Y2P0hywEJSem',
              url: '/helix/TEI/SVGs/TEI_prepositions_texttable_07.svg',
              altText: [
                {
                  language: 'en',
                  altText:
                    'A table displaying types of prepositions and their purpose.',
                },
                {
                  language: 'es',
                  altText:
                    'Una tabla que muestra los tipos de preposiciones y sus usos.',
                },
              ],
            },
          },
        },
      ],
      populationData: {
        text: [
          {
            text: 'Revisa la preposición o locución preposicional incorrecta y elige la correcta.\n\nPablo finalmente convenció a sus padres de que adoptaran un perro {CHOICE_TOKEN} meses de rogar y suplicar.\n',
          },
        ],
        answerChoices: [
          {
            choice: {
              texts: [
                {
                  id: '31051.B.1.10_1',
                  text: 'después de\n',
                },
                {
                  id: '31051.B.1.10_2',
                  text: 'por causa de\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'La locución preposicional «por causa de» da información sobre por qué algo ocurrió. Esta oración necesita una locución preposicional que indique tiempo.\n',
                    },
                  },
                },
                {
                  id: '31051.B.1.10_3',
                  text: 'detrás de\n',
                  targetedFeedback: {
                    firstIncorrect: {
                      content:
                        'La locución preposicional «detrás de» da información sobre por qué algo ocurrió. Esta oración necesita una locución preposicional que indique tiempo.\n',
                    },
                  },
                },
              ],
            },
          },
        ],
        answers: ['31051.B.1.10_1'],
        associatedSuccessCriteria: ['31051.B.1'],
      },
      feedbackSecondIncorrect: {
        content:
          'La locución preposicional «después de» es correcta porque indica cuándo Pablo rogó y suplicó por un perro.\n',
      },
    },
  ],
  activityManifest: [
    {
      type: 'START_SCREEN',
    },
    {
      type: 'ENTRY_SURVEY',
    },
    {
      type: 'VIDEO',
      id: 'recNLCuKB8a1Swl3w',
    },
    {
      type: 'QUESTION',
      id: '31051.B.1.1',
    },
    {
      type: 'QUESTION',
      id: '31051.B.1.2',
    },
    {
      type: 'QUESTION',
      id: '31051.B.1.3',
    },
    {
      type: 'QUESTION',
      id: '31051.B.1.4',
    },
    {
      type: 'QUESTION',
      id: '31051.B.1.5',
    },
    {
      type: 'QUESTION',
      id: '31051.B.1.6',
    },
    {
      type: 'QUESTION',
      id: '31051.B.1.7',
    },
    {
      type: 'QUESTION',
      id: '31051.B.1.8',
    },
    {
      type: 'QUESTION',
      id: '31051.B.1.9',
    },
    {
      type: 'QUESTION',
      id: '31051.B.1.10',
    },
    {
      type: 'EXIT_SURVEY',
    },
    {
      type: 'RESULTS_SCREEN',
    },
  ],
  version: '1562962166',
};
