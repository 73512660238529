import { Injectable } from '@angular/core';
import { SettingsService } from 'src/app/shared/services/settings/settings.service';
import {
  VideoService,
  VolumeService,
  FullscreenService,
  TranscriptService,
  SettingsPopupsService,
  CaptionsService,
} from '.';
import { OffsettersData } from '../data';
import { KeyboardMapEnum, Settings } from '../enums';

@Injectable({
  providedIn: 'root',
})
export class KeyboardShortcutService {
  constructor(
    private volumeService: VolumeService,
    private videoService: VideoService,
    private fullscreenService: FullscreenService,
    private transcriptService: TranscriptService,
    private settingsPopupService: SettingsPopupsService,
    private settingsService: SettingsService,
    private captionsService: CaptionsService
  ) {}

  keyboardShortcut(eventNum: number | string): void {
    this.handleVideoServiceShortcuts(eventNum);
    this.handleVolumeServiceShortcuts(eventNum);
    this.handleFullscreenServiceShortcuts(eventNum);
    this.handleTranscriptServiceShortcuts(eventNum);
    this.handleSettingsServiceShortcuts(eventNum);
  }

  private handleVideoServiceShortcuts(eventNum: number | string): void {
    switch (eventNum) {
      case KeyboardMapEnum.SPACE_KEY:
      case KeyboardMapEnum.SPACE_KEY_STR:
        this.videoService.togglePlay();
        break;
      case KeyboardMapEnum.RIGHT_ARROW_KEY:
      case KeyboardMapEnum.RIGHT_ARROW_KEY_STR:
        this.videoService.jumpStep(OffsettersData.jumpAhead);
        break;
      case KeyboardMapEnum.LEFT_ARROW_KEY:
      case KeyboardMapEnum.LEFT_ARROW_KEY_STR:
        this.videoService.jumpStep(OffsettersData.jumpBackward);
        break;
      default:
        break;
    }
  }

  private handleVolumeServiceShortcuts(eventNum: number | string): void {
    switch (eventNum) {
      case KeyboardMapEnum.UP_ARROW_KEY:
      case KeyboardMapEnum.UP_ARROW_KEY_STR:
        this.volumeService.volumeChange(OffsettersData.volumeUp);
        break;
      case KeyboardMapEnum.DOWN_ARROW_KEY:
      case KeyboardMapEnum.DOWN_ARROW_KEY_STR:
        this.volumeService.volumeChange(OffsettersData.volumeDown);
        break;

      case KeyboardMapEnum.M_KEY:
      case KeyboardMapEnum.M_KEY_STR:
        this.volumeService.toggleMute();
        break;
      default:
        break;
    }
  }

  private handleFullscreenServiceShortcuts(eventNum: number | string): void {
    switch (eventNum) {
      case KeyboardMapEnum.F_KEY:
      case KeyboardMapEnum.F_KEY_STR:
        this.fullscreenService.toggleFullscreen();
        break;
      case KeyboardMapEnum.ESC_KEY:
      case KeyboardMapEnum.ESC_KEY_STR:
        this.fullscreenService.fullscreen$.next(false);
        break;
      default:
        break;
    }
  }

  private handleTranscriptServiceShortcuts(eventNum: number | string): void {
    switch (eventNum) {
      case KeyboardMapEnum.T_KEY:
      case KeyboardMapEnum.T_KEY_STR:
        this.transcriptService.toggleTranscript(
          this.settingsPopupService.subMenuElements[Settings.TRANSCRIPT_TOGGLE]
        );
        break;
      default:
        break;
    }
  }

  private handleSettingsServiceShortcuts(eventNum: number | string): void {
    switch (eventNum) {
      case KeyboardMapEnum.C_KEY:
      case KeyboardMapEnum.C_KEY_STR:
        this.settingsService.saveSettings({
          subtitlesOn: !this.captionsService.showCaptions$.getValue(),
        });
        break;
      default:
        break;
    }
  }
}
