import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AltText } from '../../interfaces';

@Pipe({
  name: 'altTextLang',
})
export class AltTextLangPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}
  transform(value: AltText[] | undefined): string {
    if (value === undefined) {
      return '';
    } else if (value.length === 1) {
      //something is better than not matching language
      return value[0].altText;
    }
    const foundText = value.find(value => {
      return value.language == this.translateService.currentLang;
    });
    return foundText?.altText ?? value[0].altText ?? '';
  }
}
