import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ReadAloudService } from 'src/app/modules/read-aloud/services';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { Chart } from 'src/app/shared/interfaces';
import { PopulationService } from 'src/app/shared/services/population/population.service';
import { PopupService } from 'src/app/shared/services/popup/popup.service';
import { ChartService } from '../../services/chart.service';

@Component({
  selector: 'htc-chart-modal',
  templateUrl: './chart-modal.component.html',
  styleUrl: './chart-modal.component.scss',
})
export class ChartModalComponent implements AfterViewInit, OnDestroy {
  @Input() chart: Chart = { data: '', isUrl: false };
  @Output() chartClosed = new EventEmitter();
  @ViewChild(DialogComponent, { static: true }) dialog!: DialogComponent;

  loaded = false;
  sub = new Subscription();

  constructor(
    public popupService: PopupService,
    public readAloudService: ReadAloudService,
    public populationService: PopulationService,
    public chartService: ChartService,
    public changeDetectorRef: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();

    this.sub.add(
      this.chartService.getChart().subscribe(chart => {
        this.chart = chart;
      })
    );

    this.sub.add(
      this.chartService.getIsChartOpen().subscribe(isChartOpen => {
        if (isChartOpen) {
          this.openChartModal();
        }
      })
    );
  }

  openChartModal(): void {
    if (this.dialog) {
      this.dialog.open();
      this.readAloudService.stopCurrent();
    }
  }

  onPopupChange(): void {
    if (!this.dialog.isOpen) {
      this.chartService.closeChart();
      this.popupService.focusActiveElement();
    }
  }

  onChartLoaded(): void {
    this.loaded = true;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
