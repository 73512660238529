import { ITool } from 'src/app/modules/tools/interfaces/tool.interface';
import { ToolID } from '../enums/tool-id';

export const tools: { [key: string]: ITool } = {
  VIDEO_POPUP: {
    id: ToolID.VIDEO_POPUP,
    toolName: 'Video',
    icon: 'video_icon.svg',
    toggledIcon: 'video_icon.svg',
    testId: 'htc-video-popup-toolbar-button',
  },
  ON_DEMAND_HINT: {
    id: ToolID.ON_DEMAND_HINT,
    toolName: 'Hints',
    icon: 'hint_unread.svg',
    testId: 'htc-on-demand-hint-toolbar-button',
  },
  RUBRIC_POPUP: {
    id: ToolID.RUBRIC_POPUP,
    toolName: 'Rubric',
    icon: 'rubric.svg',
    testId: 'htc-rubric-toolbar-button',
  },
};
