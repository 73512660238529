import {
  KATEX_END_DELIM,
  KATEX_START_DELIM,
} from '../modules/math-text/utils/math-text-utils';
import { PopulationMultipleChoice } from '../shared/interfaces';
import { TEST_CHOICE_TYPES } from './choice-types';
import { TestAssociatedSuccessCriteria } from './test-associated-success-criteria.enum';

export const multiSelectQuestions: {
  [key: number]: PopulationMultipleChoice;
} = {
  [TEST_CHOICE_TYPES.TEST_R_MS_SHORT]: {
    question: {
      text: 'This is the reading multi select short question?',
    },
    answerChoices: [
      {
        id: '0',
        choice: {
          texts: [
            {
              text: 'correct 1',
            },
          ],
        },
      },
      {
        id: '1',
        choice: {
          texts: [
            {
              text: 'incorrect 1',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: {
            content: 'This is incorrect 1 - first incorrect targeted feedback',
          },
          secondIncorrect: {
            content: 'This is incorrect 1 - second incorrect targeted feedback',
          },
        },
      },
      {
        id: '2',
        choice: {
          texts: [
            {
              text: 'correct 2',
            },
          ],
        },
      },
      {
        id: '3',
        choice: {
          texts: [
            {
              text: 'incorrect 2',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: {
            content: 'This is incorrect 2 - first incorrect targeted feedback',
          },
          secondIncorrect: {
            content: 'This is incorrect 2 - second incorrect targeted feedback',
          },
        },
      },
    ],
    answers: ['0', '2'],
    associatedSuccessCriteria: [TestAssociatedSuccessCriteria.MULTI_SELECT],
  },
  [TEST_CHOICE_TYPES.TEST_R_MS_MEDIUM]: {
    question: {
      text: 'This is the reading multi select medium question? Added question text for medium length.',
    },
    answerChoices: [
      {
        id: '0',
        choice: {
          texts: [
            {
              text: 'This is a correct 1 answer medium length sentence.',
            },
          ],
        },
      },
      {
        id: '1',
        choice: {
          texts: [
            {
              text: 'This is a correct 2 answer medium length sentence.',
            },
          ],
        },
      },
      {
        id: '2',
        choice: {
          texts: [
            {
              text: 'incorrect 1',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: {
            content: 'This is incorrect 1 - first incorrect targeted feedback',
          },
          secondIncorrect: {
            content: 'This is incorrect 1 - second incorrect targeted feedback',
          },
        },
      },
      {
        id: '3',
        choice: {
          texts: [
            {
              text: 'incorrect 2',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: {
            content: 'This is incorrect 2 - first incorrect targeted feedback',
          },
          secondIncorrect: {
            content: 'This is incorrect 2 - second incorrect targeted feedback',
          },
        },
      },
    ],
    answers: ['0', '1'],
    associatedSuccessCriteria: [TestAssociatedSuccessCriteria.MULTI_SELECT],
  },
  [TEST_CHOICE_TYPES.TEST_R_MS_LONG]: {
    question: {
      text: 'This is the reading multi select long question? Added text to the question in order to add longer question. More question text added here for length.',
    },
    answerChoices: [
      {
        id: '0',
        choice: {
          texts: [
            {
              text: 'This is a correct 1 answer long length sentence. This is a correct answer long length sentence.',
            },
          ],
        },
      },
      {
        id: '1',
        choice: {
          texts: [
            {
              text: 'This is a correct 2 answer long length sentence. This is a correct answer long length sentence.',
            },
          ],
        },
      },
      {
        id: '2',
        choice: {
          texts: [
            {
              text: 'incorrect 1',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: {
            content: 'This is incorrect 1 - first incorrect targeted feedback',
          },
          secondIncorrect: {
            content: 'This is incorrect 1 - second incorrect targeted feedback',
          },
        },
      },
      {
        id: '3',
        choice: {
          texts: [
            {
              text: 'incorrect 2',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: {
            content: 'This is incorrect 2 - first incorrect targeted feedback',
          },
          secondIncorrect: {
            content: 'This is incorrect 2 - second incorrect targeted feedback',
          },
        },
      },
      {
        id: '4',
        choice: {
          texts: [
            {
              text: 'This is a correct 3 answer long length sentence. This is a correct answer long length sentence.',
            },
          ],
        },
      },
      {
        id: '5',
        choice: {
          texts: [
            {
              text: 'incorrect 3',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: {
            content: 'This is incorrect 3 - first incorrect targeted feedback',
          },
          secondIncorrect: {
            content: 'This is incorrect 3 - second incorrect targeted feedback',
          },
        },
      },
    ],
    answers: ['0', '1', '4'],
    associatedSuccessCriteria: [TestAssociatedSuccessCriteria.MULTI_SELECT],
  },
  [TEST_CHOICE_TYPES.TEST_S_MS_SHORT]: {
    question: {
      text: '¿Esta es la pregunta corta de selección múltiple de lectura?',
    },
    answerChoices: [
      {
        id: '0',
        choice: {
          texts: [
            {
              text: 'Correcta 1',
            },
          ],
        },
      },
      {
        id: '1',
        choice: {
          texts: [
            {
              text: 'Incorrecta 1',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: {
            content:
              'Esto es incorrecto 1: primer comentario dirigido incorrecto',
          },
          secondIncorrect: {
            content:
              'Esto es incorrecto 1 segundo de comentario dirigido incorrecto',
          },
        },
      },
      {
        id: '2',
        choice: {
          texts: [
            {
              text: 'Correcta 2',
            },
          ],
        },
      },
      {
        id: '3',
        choice: {
          texts: [
            {
              text: 'Incorrecta 2',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: {
            content:
              'Esto es incorrecto 2: primer comentario dirigido incorrecto',
          },
          secondIncorrect: {
            content:
              'Esto es incorrecto 2 segundos de comentarios dirigidos incorrectos',
          },
        },
      },
    ],
    answers: ['0', '2'],
    associatedSuccessCriteria: [TestAssociatedSuccessCriteria.MULTI_SELECT],
  },
  [TEST_CHOICE_TYPES.TEST_S_MS_MEDIUM]: {
    question: {
      text: '¿Esta es la pregunta del medio de selección múltiple de lectura? Se agregó texto de pregunta para longitud media.',
    },
    answerChoices: [
      {
        id: '0',
        choice: {
          texts: [
            {
              text: 'Esta es una oración de longitud media de 1 respuesta correcta.',
            },
          ],
        },
      },
      {
        id: '1',
        choice: {
          texts: [
            {
              text: 'Esta es una oración de longitud media de 2 respuestas correctas.',
            },
          ],
        },
      },
      {
        id: '2',
        choice: {
          texts: [
            {
              text: 'Incorrecta 1',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: {
            content:
              'Esto es incorrecto 1: primer comentario dirigido incorrecto',
          },
          secondIncorrect: {
            content:
              'Esto es incorrecto 1 segundo de comentario dirigido incorrecto',
          },
        },
      },
      {
        id: '3',
        choice: {
          texts: [
            {
              text: 'Incorrecta 2',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: {
            content:
              'Esto es incorrecto 2: primer comentario dirigido incorrecto',
          },
          secondIncorrect: {
            content:
              'Esto es incorrecto 2 segundos de comentarios dirigidos incorrectos',
          },
        },
      },
    ],
    answers: ['0', '1'],
    associatedSuccessCriteria: [TestAssociatedSuccessCriteria.MULTI_SELECT],
  },
  [TEST_CHOICE_TYPES.TEST_S_MS_LONG]: {
    question: {
      text: '¿Esta es la pregunta larga de selección múltiple de lectura? Se agregó texto a la pregunta para agregar una pregunta más larga. Se agregó más texto de pregunta aquí para mayor extensión.',
    },
    answerChoices: [
      {
        id: '0',
        choice: {
          texts: [
            {
              text: 'Esta es una oración larga y correcta de 1 respuesta. Esta es una oración larga y de respuesta correcta.',
            },
          ],
        },
      },
      {
        id: '1',
        choice: {
          texts: [
            {
              text: 'Esta es una oración larga y correcta de 2 respuestas. Esta es una oración larga y de respuesta correcta.',
            },
          ],
        },
      },
      {
        id: '2',
        choice: {
          texts: [
            {
              text: 'Incorrecta 1',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: {
            content:
              'Esto es incorrecto 1: primer comentario dirigido incorrecto',
          },
          secondIncorrect: {
            content:
              'Esto es incorrecto 1 segundo de comentario dirigido incorrecto',
          },
        },
      },
      {
        id: '3',
        choice: {
          texts: [
            {
              text: 'Incorrecta 2',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: {
            content:
              'Esto es incorrecto 2: primer comentario dirigido incorrecto',
          },
          secondIncorrect: {
            content:
              'Esto es incorrecto 2 segundos de comentarios dirigidos incorrectos',
          },
        },
      },
      {
        id: '4',
        choice: {
          texts: [
            {
              text: 'Esta es una oración larga y correcta de 3 respuestas. Esta es una oración larga y de respuesta correcta.',
            },
          ],
        },
      },
      {
        id: '5',
        choice: {
          texts: [
            {
              text: 'Incorrecta 3',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: {
            content:
              'Esto es incorrecto 3: primer comentario dirigido incorrecto',
          },
          secondIncorrect: {
            content:
              'Esto es incorrecto. Comentarios dirigidos incorrectos de 3 segundos.',
          },
        },
      },
    ],
    answers: ['0', '1', '4'],
    associatedSuccessCriteria: [TestAssociatedSuccessCriteria.MULTI_SELECT],
  },
  [TEST_CHOICE_TYPES.TEST_R_MS_KATEX]: {
    question: {
      text: `What if I asked you about ${KATEX_START_DELIM}\frac{a}{b}${KATEX_END_DELIM}?`,
    },
    answerChoices: [
      {
        id: '0',
        choice: {
          texts: [
            {
              text: `correct 1 ${KATEX_START_DELIM}\frac{a}{b}${KATEX_END_DELIM}`,
            },
          ],
        },
      },
      {
        id: '1',
        choice: {
          texts: [
            {
              text: `incorrect 1 ${KATEX_START_DELIM}\frac{a}{b}${KATEX_END_DELIM}`,
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: {
            content: 'This is incorrect 1 - first incorrect targeted feedback',
          },
          secondIncorrect: {
            content: 'This is incorrect 1 - second incorrect targeted feedback',
          },
        },
      },
      {
        id: '2',
        choice: {
          texts: [
            {
              text: 'correct 2',
            },
          ],
        },
      },
      {
        id: '3',
        choice: {
          texts: [
            {
              text: 'incorrect 2',
            },
          ],
        },
        targetedFeedback: {
          firstIncorrect: {
            content: 'This is incorrect 2 - first incorrect targeted feedback',
          },
          secondIncorrect: {
            content: 'This is incorrect 2 - second incorrect targeted feedback',
          },
        },
      },
    ],
    answers: ['0', '2'],
    associatedSuccessCriteria: [TestAssociatedSuccessCriteria.MULTI_SELECT],
  },
};
