import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AudioDescriptor } from '../../enums/audio-descriptor';
import { AudioType } from '../../enums/audio-type';

@Component({
  selector: 'htc-button-with-icon',
  templateUrl: './button-with-icon.component.html',
})
export class ButtonWithIconComponent {
  @Input() id = '';
  @Input() btnText = 'Default';
  @Input() imagePath = '';
  @Input() classes = ''; //Globally accessible class to apply to the button
  @Input() enable = true;
  @Input() clickDescriptor = AudioDescriptor.CLICK_GENERAL;
  @Input() clickType = AudioType.audio_SfxClick;
  isHovering = false;

  // for when the button is clicked...
  @Output() btnClicked = new EventEmitter<Event>();

  readonly path = '/assets/button-icons/';

  constructor() {}

  buttonClicked(event: Event): void {
    this.btnClicked.emit(event);
  }

  mouseEnter(): void {
    this.isHovering = true;
  }

  mouseLeave(): void {
    this.isHovering = false;
  }
}
