import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ITool } from '../../interfaces/tool.interface';
import { ToolsService } from '../../services/tools.service';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { PopulationConstructedResponse } from 'src/app/shared/interfaces';
import { Subscription } from 'rxjs';
import { PopulationService } from 'src/app/shared/services/population/population.service';
import { ChartService } from 'src/app/modules/chart/services/chart.service';

@Component({
  selector: 'htc-rubric-popup',
  template: '',
})
export class RubricPopupComponent implements OnInit, OnDestroy, ITool {
  @Input() id!: number;
  @Input() toolName!: string;
  @Input() icon!: string;
  @Input() toggledIcon?: string | undefined;
  @Input() testId = '';

  loaded = false;
  sub = new Subscription();

  @ViewChild(DialogComponent, { static: true }) dialog!: DialogComponent;

  constructor(
    private toolsService: ToolsService,
    private populationService: PopulationService,
    private chartService: ChartService
  ) {}

  ngOnInit(): void {
    this.sub.add(
      this.populationService.currentPopulation.subscribe(() => {
        const popData =
          this.populationService.getCurrentPopulationData() as PopulationConstructedResponse;

        if (popData.chart) {
          this.chartService.openChart(popData.chart);
        }
      })
    );

    this.sub.add(
      this.chartService.getIsChartOpen().subscribe(isChartOpen => {
        if (!isChartOpen) {
          this.toolsService.deactivateTool(this.id);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
