import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'htc-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnChanges, OnInit, OnDestroy {
  @Input() uniqueId: string | number = 'input';
  @Input() index = -1;
  @Input() required = false;
  @Input() type = 'text';
  @Input() isWrongAnswer? = false;
  @Input() isCorrectAnswer? = false;
  @Input() correctAnswer?: string;
  @Input() value = '';
  @Input() enteredAnswer?: string;
  @Input() displayedAnswer = '';
  @Output() inputChange = new EventEmitter<string>();

  @ViewChild('invalidInputDiv', { static: false }) invalidInputDiv!: ElementRef;

  showAnswer = false;
  showWrongAnswer = false;
  isValueValid = true;
  pattern = '(.*?)';
  inputMode = 'text';
  reportValidityCalled = false;
  inputTouched = false;
  inputErrorMessage = '';
  resizeObservable$!: Observable<Event>;
  resizeSubscription$!: Subscription;

  constructor(
    private translateService: TranslateService,
    private renderer: Renderer2 // service provides a way to interact with the DOM in a safe and efficient way
  ) {}

  ngOnInit(): void {
    if (this.type === 'number') {
      /**
         ^                  # Match the beginning of the string
        (?:                 # Non-capturing group for sign (optional)
        -                   # Match a minus sign for negative numbers
        |                   # OR
          \b                # Match a word boundary for positive numbers (empty string)
        )
        (?:                 # Non-capturing group for number formats (mandatory)
        \d+                 # Match one or more digits (integer part)
        (?:                 # Optional thousand separator with comma (repeated)
        ,\d{3}              # Match a comma followed by three digits
        )*                  # Repeat the comma and digits group zero or more times
        (?:                 # Optional decimal part (with digits)
        \.                  # Match a decimal point
        \d+                 # Match one or more digits (fractional part)
        )?                  # Repeat the decimal and digits group zero or one time
        |                   # OR
        \d+\.?\d*           # Match digits (with optional decimal and digits)
        (?:[eE][+-]?\d+)?   # Optional scientific notation (e or E, optional sign, digits)
        |                   # OR
        (\d+/\d+)           # Capturing group for fractions (digits, slash, digits)
        )
        $                   # Match the end of the string
      */
      this.pattern =
        '^(?:-|\\b)(\\d+(?:,\\d{3})*(?:\\.\\d+)?|\\d+\\.?\\d*(?:[eE][\\+\\-]?\\d+)|(\\d+\\/\\d+))$';
      this.inputMode = 'decimal';

      // handles window resize and calls repositionInvalidInput to position
      // invalid input properly. Without this section, the invalid input box can be cutoff
      let timeout: NodeJS.Timeout;
      this.resizeObservable$ = fromEvent(window, 'resize');
      this.resizeSubscription$ = this.resizeObservable$.subscribe(() => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          this.repositionInvalidInput();
        }, 300);
      });
    }
  }

  ngOnChanges(): void {
    if (this.index !== -1) {
      this.isCorrectAnswer = true;
      this.isWrongAnswer = true;
      this.showAnswer = false;
      setTimeout(() => {
        this.showAnswer = true;
        this.showWrongAnswer = false;
        this.displayedAnswer = this.correctAnswer || '';
      }, this.index * 150);
    } else if (this.correctAnswer?.length === 0 && this.isWrongAnswer) {
      this.showWrongAnswer = true;
      this.showAnswer = true;
      this.value = '';
      if (this.enteredAnswer) {
        this.value = this.enteredAnswer;
      }
    } else if (this.isCorrectAnswer) {
      this.showAnswer = false;
    }
  }

  handleChange(event: Event): void {
    const element = event.target as HTMLInputElement;
    const value = element.value.trim();
    this.inputErrorMessage = '';
    this.reportValidityCalled = false;
    this.isValueValid = true;
    element.setAttribute('aria-invalid', `${!element.validity.valid}`);

    this.value = value;
    this.inputChange.emit(value);
  }

  handleInvalidInput(event: Event): void {
    if (!this.reportValidityCalled) {
      const target = event.target as HTMLInputElement;
      this.reportValidityCalled = true;

      if (this.type === 'number') {
        this.inputErrorMessage = this.translateService.instant(
          'TEI_VALIDATION_MSG.INPUT_NUMBER'
        );
      }
      this.isValueValid = false;
      target.setAttribute('aria-invalid', `${!target.validity.valid}`);

      setTimeout(() => {
        this.repositionInvalidInput();
      }, 0);
    }
  }

  /**
   * handles the input error message position change.
   * sets default value to 50% then offsets left based on the dimensions
   */
  repositionInvalidInput(): void {
    if (this.invalidInputDiv) {
      this.renderer.setStyle(this.invalidInputDiv.nativeElement, 'left', '50%');
      const box = this.invalidInputDiv.nativeElement.getBoundingClientRect();
      if (box.left < 0) {
        this.renderer.setStyle(
          this.invalidInputDiv.nativeElement,
          'left',
          '70%'
        );
      }

      if (box.left + box.width > document.body.clientWidth) {
        this.renderer.setStyle(
          this.invalidInputDiv.nativeElement,
          'left',
          '30%'
        );
      }
    }
  }

  onKeyDownEvent(event: KeyboardEvent): void {
    // don't allow space
    if (event.code.toLowerCase() === 'space') {
      event.preventDefault();
    }
  }

  onBlurEvent(): void {
    this.inputTouched = true;
  }

  onPaste(event: Event): void {
    event.preventDefault();
  }

  onClickEvent(event: Event): void {
    event.stopPropagation();
  }

  ngOnDestroy(): void {
    if (this.resizeSubscription$) {
      this.resizeSubscription$.unsubscribe();
    }
  }
}
