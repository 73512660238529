import { CompletedActivity } from './../../interfaces/completed-activity';
import { Injectable } from '@angular/core';
import { CompleteActivityState } from '../../interfaces/complete-activity-state';
import { KnowledgeBaseService } from 'src/app/modules/olp-api/services/kb/kb.service';
import { FeatureFlagService } from 'src/app/modules/feature-flags/services/feature-flag/feature-flag.service';
import { FeatureFlags } from 'src/app/shared/enums/feature-flags';
import { MessageService } from 'src/app/modules/message/services/message/message.service';
import { MessageCodes } from 'src/app/shared/enums';

@Injectable({
  providedIn: 'root',
})
export class CompleteActivityStateService {
  readonly activityKBStorage = 'HTMLActivityStorage';
  readonly completeActivityStorage = 'CompleteActivities';

  completedActivities = new Array<CompletedActivity>();

  constructor(
    private kbService: KnowledgeBaseService,
    private featureFlagService: FeatureFlagService,
    private messageService: MessageService
  ) {}

  cacheActivities(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getCompletedActivities()
        .then(activities => {
          if (activities) {
            this.completedActivities = activities;
            resolve();
          }
        })
        .catch(err => {
          console.error(`We couldn't get the completed activities,`, err);
          reject(err);
        });
    });
  }

  async addActivityStateAndSync(
    activityState: CompleteActivityState
  ): Promise<void> {
    await this.addActivityState(activityState);

    const indexedActivities: Record<string, string | CompletedActivity> = {};
    if (
      !this.featureFlagService.isFlagEnabled(FeatureFlags.SAVE_COMPLETED_OBJECT)
    ) {
      this.completedActivities.forEach(activity => {
        indexedActivities[activity.activityId] = JSON.stringify(activity);
      });
    } else {
      this.completedActivities.forEach(activity => {
        indexedActivities[activity.activityId] = activity;
      });
    }

    return this.kbService
      .setAndSync(
        this.activityKBStorage,
        this.completeActivityStorage,
        indexedActivities
      )
      .catch((error: Response) => {
        if (error.status === 409) {
          this.messageService.showMessage(
            MessageCodes.HTTP_UNEXPECTED_NET_ERROR
          );
        }
      });
  }

  async getCompletedActivity(
    activityId: string
  ): Promise<CompletedActivity | undefined> {
    if (this.completedActivities.length === 0) {
      await this.getCompletedActivities();
    }

    return this.getActivityWithId(activityId);
  }

  async getCompletedActivities(): Promise<CompletedActivity[] | undefined> {
    return new Promise((resolve, reject) => {
      if (this.completedActivities.length > 0) {
        resolve(this.completedActivities);
      } else {
        if (this.featureFlagService.isFlagEnabled(FeatureFlags.USE_KB)) {
          if (
            !this.featureFlagService.isFlagEnabled(
              FeatureFlags.SAVE_COMPLETED_OBJECT
            )
          ) {
            this.kbService
              .get(this.activityKBStorage, this.completeActivityStorage)
              .then(activities => {
                if (activities) {
                  Object.keys(activities as Record<string, string>).forEach(
                    key => {
                      const activity = JSON.parse(
                        (activities as Record<string, string>)[key]
                      ) as CompletedActivity;
                      this.completedActivities.push(activity);
                    }
                  );
                }
                resolve(this.completedActivities);
              });
          } else {
            this.kbService
              .get(this.activityKBStorage, this.completeActivityStorage)
              .then(activities => {
                if (activities) {
                  Object.keys(
                    activities as Record<string, CompletedActivity>
                  ).forEach(key => {
                    const activity = (
                      activities as Record<string, CompletedActivity>
                    )[key];
                    this.completedActivities.push(activity);
                  });
                }
                resolve(this.completedActivities);
              });
          }
        } else {
          reject('useKb feature Flag Disabled');
        }
      }
    });
  }

  async getActivityRuns(
    activityId: string
  ): Promise<CompleteActivityState[] | undefined> {
    if (this.completedActivities.length === 0) {
      await this.getCompletedActivities();
    }

    const activityIndex = this.getActivityIndex(activityId);
    if (activityIndex >= 0) {
      return this.completedActivities[activityIndex].completions;
    }
    return undefined;
  }

  async addActivityState(activityState: CompleteActivityState): Promise<void> {
    if (!this.completedActivities.length) {
      await this.cacheActivities().catch(err => {
        console.error(`We have an issue getting cached activities`, err);
      });
    }
    let activity = this.getActivityWithId(activityState.activityId);
    if (!activity) {
      const newActivityIndex = this.addNewActivity(activityState.activityId);
      activity = this.completedActivities[newActivityIndex];
    }

    if (activity) {
      activity.completions.push(activityState);
      activity.timesCompleted++;
      activity.fastestTimeInSecs = Math.min(
        activity.fastestTimeInSecs,
        activityState.timeToCompleteSecs
      );
      activity.maxStars = Math.max(
        activity.maxStars,
        activityState.starsEarned
      );
    } else {
      console.error('we still dont have activity, send help');
    }
  }

  addNewActivity(activityId: string): number {
    this.completedActivities.push({
      activityId: activityId,
      timesCompleted: 0,
      maxStars: 0,
      fastestTimeInSecs: Infinity,
      completions: [],
    });
    return this.completedActivities.length - 1;
  }

  private getActivityIndex(activityId: string): number {
    return this.completedActivities.findIndex(
      activity => activity.activityId === activityId
    );
  }

  private getActivityWithId(activityId: string): CompletedActivity | undefined {
    return this.completedActivities.find(
      activity => activity.activityId === activityId
    );
  }
}
