<div
  data-testid="htc-settings-input-shield"
  class="input-shield"
  [ngClass]="{
    saveable: checkChanges()
  }"
  *ngIf="showPopup"
  htcTrapFocus
  role="dialog"
  attr.aria-labelledby="{{ 'PROFILE_MENU.SUPPORT' | translate }}">
  <div class="popup" [attr.aria-hidden]="!showPopup">
    <div class="header">
      <svg-icon
        src="assets/button-icons/settings.svg"
        attr.alt="{{ 'PROFILE_MENU.SETTINGS_MENU' | translate }}"></svg-icon>

      <span>{{ 'PROFILE_MENU.SETTINGS_MENU' | translate }}</span>

      <htc-button-with-icon
        data-testid="htc-settings-close-btn"
        classes="btn btn-no-border btn-no-bkgd btn-settings-close interactive-cmp"
        imagePath="/assets/button-icons/cancel-x.svg"
        btnText=""
        (btnClicked)="closePopup()"
        attr.aria-label="{{
          'PROFILE_MENU.CLOSE_SETTINGS_ARIA_LABEL' | translate
        }}"></htc-button-with-icon>
    </div>

    <div class="content-container">
      <div class="entry">
        <label class="switch" for="reduced-motion-checkbox">
          <span class="label-text">{{
            'PROFILE_MENU.REDUCE_MOTION' | translate
          }}</span>
          <input
            data-testid="htc-reduced-motion-checkbox"
            type="checkbox"
            id="reduced-motion-checkbox"
            [(ngModel)]="settings.prefersReducedMotion"
            htcAudioClick
            [audioType]="clickType"
            [descriptor]="clickDescriptor" />
          <span class="slider round"></span>
        </label>
      </div>
      <div class="entry">
        <label class="switch" for="mute-sfx">
          <span class="label-text">{{
            'PROFILE_MENU.MUTE_SOUND_EFFECTS' | translate
          }}</span>
          <input
            data-testid="htc-mute-sfx"
            type="checkbox"
            id="mute-sfx"
            [(ngModel)]="settings.muteSfx"
            htcAudioClick
            [audioType]="clickType"
            [descriptor]="clickDescriptor" />
          <span class="slider round"></span>
        </label>
      </div>
      <htc-button-with-icon
        data-testid="htc-settings-save-btn"
        classes="btn btn-primary btn-profile btn-theme btn-save-settings"
        imagePath="/assets/button-icons/check.svg"
        btnText="{{ 'PROFILE_MENU.SAVE' | translate }}"
        (btnClicked)="saveSettings()"
        attr.aria-label="{{ 'PROFILE_MENU.SAVE' | translate }}"
        [enable]="checkChanges()"></htc-button-with-icon>
    </div>
  </div>
</div>
