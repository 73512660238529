import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import videojs from 'video.js';
import { Settings } from '../enums';
import { UtilsService, VideoService } from '.';

@Injectable({
  providedIn: 'root',
})
export class CaptionsService {
  showCaptions$ = new BehaviorSubject<boolean>(false);
  captionFontSize = Settings.FONT_SIZE_MEDIUM;
  captionToggles: Element[] = [];

  constructor(
    private videoService: VideoService,
    private utilsService: UtilsService
  ) {}

  setTextTracks(textTrackOptions: videojs.TextTrackOptions[]): void {
    const player = this.videoService.player;
    if (player) {
      textTrackOptions.forEach(caption => {
        player.addRemoteTextTrack(caption, false);
      });
    }
  }

  showTrack(lang: string, show: boolean): void {
    if (this.videoService.player) {
      const tracks = this.videoService.player.remoteTextTracks();

      for (let i = 0; i < tracks.length; i++) {
        const track = tracks[i];
        if (track.language === lang) {
          track.mode = show ? 'showing' : 'hidden';
          break;
        }
      }
    }
  }

  showCaptions(show: boolean, elsToToggle?: Element[], lang?: string): boolean {
    const language = lang ?? this.videoService.player?.language() ?? 'en';
    this.setCaptionClass(show);
    this.showTrack(language, show);
    this.updateCaptionUI(show, elsToToggle);
    this.showCaptions$.next(show);

    return show;
  }

  toggleCaptions(elsToToggle?: Element[]): boolean {
    if (this.videoService.player) {
      const tracks = this.videoService.player.textTracks();
      const language = this.videoService.player?.language() ?? 'en';

      for (let i = 0; i < tracks.length; i++) {
        const track = tracks[i];
        if (track.language === language) {
          const show = !(track.mode === 'showing');
          return this.showCaptions(show, elsToToggle);
        }
      }
    }
    return false;
  }

  updateCaptionUI(show: boolean, elsToToggle?: Element[]): void {
    if (elsToToggle) {
      this.utilsService.changeTogglesClassList(elsToToggle, show);
    }

    // toggle on the menu button so users know captions are on
    const captionsMenuBtn = document.querySelector('.vjs-captions-menu-button');
    if (captionsMenuBtn) {
      this.utilsService.editElementClassFromParent(
        captionsMenuBtn,
        'vjs-menu-button',
        'vjs-toggle-on',
        show
      );
    }
  }

  onFontSizeSelect(size: Settings, subMenuElements?: Element[][]): void {
    this.captionFontSize = size;
    this.updateFontSizeValue(size);

    if (subMenuElements) {
      this.updateFontSizeUI(size, subMenuElements);
    }

    this.refreshCaptions();
  }

  updateFontSizeValue(size: Settings): string {
    const fontSizeEl = document.getElementsByClassName(
      'vjs-font-percent vjs-track-setting'
    )[0];
    if (fontSizeEl) {
      const fontSizeSelect = <HTMLInputElement>fontSizeEl.children[1];
      if (fontSizeSelect) {
        switch (size) {
          case Settings.FONT_SIZE_SMALL:
            fontSizeSelect.value = '0.50';
            break;
          case Settings.FONT_SIZE_LARGE:
            fontSizeSelect.value = '1.50';
            break;
          default:
            fontSizeSelect.value = '1.00';
            break;
        }
        return fontSizeSelect.value;
      }
    }

    return '';
  }

  updateFontSizeUI(size: Settings, subMenuElements: Element[][]): void {
    for (let i = Settings.FONT_SIZE_SMALL; i <= Settings.FONT_SIZE_LARGE; i++) {
      const menuItems = subMenuElements[i];
      const active = i === size;
      this.utilsService.changeRadiosClassList(menuItems, active);
    }
  }

  refreshCaptions(): void {
    this.showCaptions(false);
    this.showCaptions(true, this.captionToggles);
  }

  setCaptionClass(show: boolean): void {
    const el = this.videoService.player?.el();
    if (el) {
      el.classList.remove('small-font');
      el.classList.remove('medium-font');
      el.classList.remove('large-font');

      if (show) {
        let fontClass;
        switch (this.captionFontSize) {
          case Settings.FONT_SIZE_SMALL:
            fontClass = 'small-font';
            break;
          case Settings.FONT_SIZE_LARGE:
            fontClass = 'large-font';
            break;
          default:
            fontClass = 'medium-font';
            break;
        }
        el.classList.add(fontClass);
      }
    }
  }
}
