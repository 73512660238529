import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ResultsService {
  numQuestions = 0;
  numCorrect = 0;
  numPerfect = 0;
  numIncorrect = 0;
  earnedStars = 0;

  getPercentage(): number {
    const numChecks = this.numCorrect - this.numPerfect;
    // checks (second tries) are worth half as diamonds (first tries)
    const percentage =
      ((numChecks / 2 + this.numPerfect) / this.numQuestions) * 100;
    return percentage;
  }

  getCurrentNumEarnedStars(): number {
    return this.getNumEarnedStars(this.getPercentage());
  }

  getNumEarnedStars(percentage: number): number {
    if (this.numQuestions === this.numPerfect) {
      return 4;
    } else if (percentage >= 90) {
      return 3;
    } else if (percentage >= 70) {
      return 2;
    }

    return 1;
  }
}
