import { Type } from '@angular/core';
import { ITool } from '../interfaces/tool.interface';

export class ToolItem {
  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public component: Type<any>,
    public data: ITool
  ) {}
}
