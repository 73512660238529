import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { VideoData } from 'src/app/shared/interfaces';
import { VideoDefaultConfig } from '../../data';
import { CaptionsService } from '../../services/captions.service';
import { FullscreenService } from '../../services/fullscreen.service';
import { KeyboardShortcutService } from '../../services/keyboard-shortcut.service';
import { PlayerReadyCallbackService } from '../../services/player-ready-callback.service';
import { SettingsPopupsService } from '../../services/settings-popups.service';
import { TranscriptService } from '../../services/transcript.service';
import { VideoService } from '../../services/video.service';

@Component({
  selector: 'htc-video-container',
  templateUrl: './video-container.component.html',
  styleUrls: ['./video-container.component.scss'],
})
export class VideoContainerComponent implements OnInit, OnDestroy {
  @ViewChild('container', { static: true }) container?: ElementRef;
  @Input() videoConfig$ = new BehaviorSubject<VideoDefaultConfig | undefined>(
    undefined
  );
  @Input() videoData$ = new BehaviorSubject<VideoData | undefined>(undefined);
  @Input() autofocus = true;
  @Input() isPopup = false;
  showTranscript = false;
  showCaptions = false;
  sub = new Subscription();
  readonly fullscreenHandlerFunc = this.fullscreenHandler.bind(this);

  constructor(
    private videoService: VideoService,
    private playerReadyCallbackService: PlayerReadyCallbackService,
    private transcriptService: TranscriptService,
    private captionService: CaptionsService,
    private fullscreenService: FullscreenService,
    private keyboardShortcutService: KeyboardShortcutService,
    private settingsPopupService: SettingsPopupsService
  ) {}

  ngOnInit(): void {
    this.videoConfigAndDataSubs();
    this.playerReadySub();
    this.showTranscriptSub();
    this.showCaptionsSub();
    this.fullscreenSub();
  }

  videoConfigAndDataSubs(): void {
    this.sub.add(
      this.videoConfig$.subscribe(
        (videoConfig: VideoDefaultConfig | undefined) => {
          if (videoConfig) {
            this.videoService.VideoConfig = videoConfig;
          }
        }
      )
    );

    this.sub.add(
      this.videoData$.subscribe((videoData: VideoData | undefined) => {
        if (videoData) {
          this.videoService.VideoData = videoData;
        }
      })
    );
  }

  playerReadySub(): void {
    this.sub.add(
      this.videoService.playerReady$
        .asObservable()
        .subscribe((playerReady: boolean | undefined) => {
          if (playerReady) {
            this.onPlayerReady();
          }
        })
    );
  }

  showTranscriptSub(): void {
    this.sub.add(
      this.transcriptService.showTranscript$
        .asObservable()
        .subscribe((show: boolean) => {
          this.showTranscript = show;
        })
    );
  }

  showCaptionsSub(): void {
    this.sub.add(
      this.captionService.showCaptions$
        .asObservable()
        .subscribe((show: boolean) => {
          this.showCaptions = show;
        })
    );
  }

  fullscreenSub(): void {
    document.addEventListener(
      'fullscreenchange',
      this.fullscreenHandlerFunc,
      false
    );
    document.addEventListener(
      'mozfullscreenchange',
      this.fullscreenHandlerFunc,
      false
    );
    document.addEventListener(
      'MSFullscreenChange',
      this.fullscreenHandlerFunc,
      false
    );
    document.addEventListener(
      'webkitfullscreenchange',
      this.fullscreenHandlerFunc,
      false
    );

    this.sub.add(
      this.fullscreenService.fullscreen$
        .asObservable()
        .subscribe((requestFullscreen: boolean | undefined) => {
          if (requestFullscreen !== undefined) {
            this.requestFullscreen(requestFullscreen);
          }
        })
    );
  }

  keyboardShortcutsSub(): void {
    this.sub.add(
      this.videoService.VideoConfig.userActions.event$.subscribe(
        (eventVal: number | undefined) => {
          if (eventVal !== undefined) {
            this.keyboardShortcutService.keyboardShortcut(eventVal);
          }
        }
      )
    );
  }

  onPlayerReady(): void {
    if (this.videoService.player) {
      this.playerReadyCallbackService.defaultReadyCallback(
        this.videoService.player,
        this.videoService.VideoData
      );

      this.videoService.setJumpButtons();
    }

    this.keyboardShortcutsSub();
  }

  requestFullscreen(requestFullscreen: boolean): void {
    if (this.container) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const doc: any = document;
      if (requestFullscreen) {
        this.handleEnterFullscreen();
        // are we already in fullscreen mode then attempt to exit
      } else if (doc.fullscreenElement || doc.webkitCurrentFullScreenElement) {
        this.handleExitFullscreen(doc);
      }
    }
  }

  fullscreenHandler(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const doc: any = document;
    const isFullscreen =
      doc.fullscreenElement || doc.webkitCurrentFullScreenElement
        ? true
        : false;
    if (isFullscreen !== this.fullscreenService.fullscreen$.getValue()) {
      this.fullscreenService.fullscreen$.next(isFullscreen);
    }
  }

  overrideRightClick(): boolean {
    return false;
  }

  removeFullscreenEventListeners(): void {
    /* Standard syntax */
    document.removeEventListener(
      'fullscreenchange',
      this.fullscreenHandlerFunc,
      false
    );
    /* Firefox */
    document.removeEventListener(
      'mozfullscreenchange',
      this.fullscreenHandlerFunc,
      false
    );
    /* Chrome, Safari and Opera */
    document.removeEventListener(
      'MSFullscreenChange',
      this.fullscreenHandlerFunc,
      false
    );
    /* IE / Edge */
    document.removeEventListener(
      'webkitfullscreenchange',
      this.fullscreenHandlerFunc,
      false
    );
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }

    this.videoService.cleanup();
    this.videoService.cleanupTarget();
    this.transcriptService.cleanup();
    this.removeFullscreenEventListeners();
    this.settingsPopupService.removeEventListeners();
  }

  private handleEnterFullscreen(): void {
    if (this.container) {
      if (this.container.nativeElement.requestFullscreen) {
        this.container.nativeElement.requestFullscreen();
      } else if (this.container.nativeElement.mozRequestFullScreen) {
        /* Firefox */
        this.container.nativeElement.mozRequestFullScreen();
      } else if (this.container.nativeElement.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.container.nativeElement.webkitRequestFullscreen();
      } else if (this.container.nativeElement.msRequestFullscreen) {
        /* IE/Edge */
        this.container.nativeElement.msRequestFullscreen();
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleExitFullscreen(doc: any): void {
    if (this.container) {
      if (doc.exitFullscreen) {
        doc.exitFullscreen();
      } else if (doc.mozCancelFullScreen) {
        /* Firefox */
        doc.mozCancelFullScreen();
      } else if (doc.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        doc.webkitExitFullscreen();
      } else if (doc.msExitFullscreen) {
        /* IE/Edge */
        doc.msExitFullscreen();
      }
    }
  }
}
