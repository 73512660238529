import { DialogComponent } from './components/dialog/dialog.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AvatarComponent } from './components/avatar/avatar.component';
import { ButtonWithIconComponent } from './components/button-with-icon/button-with-icon.component';
import { TimeElapsedComponent } from './components/time-elapsed/time-elapsed.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BulletedListComponent } from './components/bulleted-list/bulleted-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownComponent } from './components/dropdown/dropdown/dropdown.component';
import { ViewContainerDirective } from './directives/view-container/view-container.directive';
import { InputComponent } from './components/input/input.component';
import { InlineCorrectAnswerComponent } from './components/inline-correct-answer/inline-correct-answer.component';
import { AlertComponent } from './components/alert/alert.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { TrapFocusDirective } from './directives/trap-focus/trap-focus.directive';
import { FeatureFlagDirective } from '../modules/feature-flags/directives/feature-flag/feature-flag.directive';
import { TableComponent } from './components/table/table.component';
import { MarkdownModule } from 'ngx-markdown';
import { CriteriaTextDataPipe } from './pipes/criteria-text-data/criteria-text-data.pipe';
import { CdnSrcBaseUrlPipe } from './pipes/cdn-src-base-url/cdn-src-base-url.pipe';
import { ReadAloudModule } from '../modules/read-aloud/read-aloud.module';
import { AudioClickDirective } from './directives/audio-click/audio-click.directive';
import { AltTextLangPipe } from './pipes/alt-text-lang/alt-text-lang.pipe';
import { AudioDragDirective } from './directives/audio-drag/audio-drag.directive';
import { MathTextModule } from '../modules/math-text/math-text.module';
import { FocusDirective } from './directives/focus/focus.directive';

@NgModule({
  declarations: [
    AvatarComponent,
    ButtonWithIconComponent,
    TimeElapsedComponent,
    DialogComponent,
    BulletedListComponent,
    DropdownComponent,
    InputComponent,
    InlineCorrectAnswerComponent,
    ViewContainerDirective,
    AlertComponent,
    FeedbackComponent,
    TrapFocusDirective,
    FeatureFlagDirective,
    TableComponent,
    CriteriaTextDataPipe,
    CdnSrcBaseUrlPipe,
    AudioClickDirective,
    AltTextLangPipe,
    AudioDragDirective,
    FocusDirective,
  ],
  imports: [
    CommonModule,
    AngularSvgIconModule,
    TranslateModule,
    MarkdownModule,
    ReadAloudModule,
    MathTextModule,
  ],
  exports: [
    AvatarComponent,
    ButtonWithIconComponent,
    TimeElapsedComponent,
    AngularSvgIconModule,
    DialogComponent,
    BulletedListComponent,
    DropdownComponent,
    InputComponent,
    ViewContainerDirective,
    AlertComponent,
    FeedbackComponent,
    TrapFocusDirective,
    FeatureFlagDirective,
    TableComponent,
    CriteriaTextDataPipe,
    CdnSrcBaseUrlPipe,
    AudioClickDirective,
    InlineCorrectAnswerComponent,
    AltTextLangPipe,
    AudioDragDirective,
    MathTextModule,
    FocusDirective,
  ],
})
export class SharedModule {}
